import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataService } from '../../services/data.service';
import * as _ from "lodash";
import { CommonJs } from '../../common/common-js';
import { AppConfigs } from '../../common/app-configs';
import { Router } from '@angular/router';

@Component({
	selector: 'app-headcount',
	templateUrl: './headcount.component.html',
	styleUrls: ['./headcount.component.css']
})
export class HeadcountComponent implements OnInit {

	entityName = 'Headcount';
	settings: any = JSON.parse(localStorage.getItem('settings'));
	modes: { [index: string]: any } = {};
	where: { [index: string]: any } = {};
	//prodDeptHeadcount = [];
	supDeptHeadcount = [];

	view: any[] = [, 400];
	viewprodDept: any[] = [, 1500];
	viewsupDept: any[] = [, 800];
	viewMode: any[] = [, 200];

	showXAxis = true;
	showYAxis = true;
	gradient = false;
	showLegend = false;
	showXAxisLabel = true;
	showYAxisLabel = true;

	//Gender
	viewGender: any[] = [500, 400];
	showLabels = true;
	explodeSlices = false;
	doughnut = true;
	gender = [];
	data: { [index: string]: any } = {};
	colorScheme = {
		domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA', '#A98B30', '#acacac']
	};


	//Bar
	public empTypeLabels: string[] = ['Payroll', 'Contract', 'Intern', 'Trainee'];
	public empTypeChartType: string = 'horizontalBar';
	public empTypeLegend: boolean = true;
	//public empTypeData:any[] = [{data: []}];

	//EmpType Pie
	public empTypeChartTypePie: string = 'pie';
	public empTypeChartTypeDataPie: number[] = [350, 450, 100, 500];
	public empTypeOptionsPie: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		legend: {
			position: 'right',
			labels: {
				fontFamily: "Nunito",
				fontSize: 10
			},
		},
	};
	public empTypeColors: any[] = [
		{
			backgroundColor: ["#FF9800", "#009900", "#6666ff", "#009999", "#EEE"]
		}];

	//Gender Pie
	public genderLabels: string[] = ['Male', 'Female', 'Other'];
	public genderTypeChart: string = 'pie';
	public genderDataPie: number[] = [350, 450, 100];
	public genderOptionsPie: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		legend: {
			position: 'right',
			labels: {
				fontFamily: "Nunito",
				fontSize: 10
			},
		},

	};
	public genderLegend: boolean = true;
	public genderColors: any[] = [
		{
			backgroundColor: ["#8ec4ed", "#f7a3b5", "#f7dda0", "#EEE"]
		}];

	//Vertical Pie
	public verticalLabels: string[] = ['2D', '3D', 'Shared'];
	public verticalTypeChart: string = 'pie';
	public verticalDataPie: number[] = [350, 450, 100];
	public verticalOptionsPie: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		legend: {
			position: 'right',
			labels: {
				fontFamily: "Nunito",
				fontSize: 10
			},
		},

	};
	public verticalLegend: boolean = true;
	public verticalColors: any[] = [
		{
			backgroundColor: ["#FF9800", "#009900", "#6666ff", "#EEE"]
		}];

	//Attrition-Type Pie
	public attrLabels: string[] = ['Voluntary', 'Involuntary', 'Undefined'];
	public attrTypeChart: string = 'pie';
	public attrDataPie: number[] = [350, 450];
	public attrOptionsPie: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		legend: {
			position: 'right',
			labels: {
				fontFamily: "Nunito",
				fontSize: 10
			},
		},

	};
	public attrColors: any[] = [
		{
			backgroundColor: ["#FF9800", "#009900", "#EEE"]
		}];
	public attrLegend: boolean = true;
	public attrYear: string;

	//Attr Rate
	public attrRateLabels: string[] = ['Attrition', 'Hiring'];
	public attrRateTypeChart: string = 'pie';
	public attrRateDataPie: number[] = [];
	public attrRateOptionsPie: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		legend: {
			position: 'right',
			labels: {
				fontFamily: "Nunito",
				fontSize: 10
			},
		},

	};
	public attrRateLegend: boolean = true;
	public attrRateYear: string;
	public attrRateColors: any[] = [
		{
			backgroundColor: ["#F44336", "#4CAF50", "#EEE"]
		}];

	//Location
	public locationLabels: string[] = ['Mumbai'];
	public locationTypeChart: string = 'pie';
	public locationDataPie: number[] = [];
	public locationOptionsPie: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		legend: {
			position: 'right',
			labels: {
				fontFamily: "Nunito",
				fontSize: 10
			},
		},

	};
	public locationLegend: boolean = true;
	public locationColors: any[] = [
		{
			backgroundColor: ["#FF9800", "#009900", "#6666ff", "#009999", "#EEE"]
		}];

	public doughnutChartColors: any[] = [{ backgroundColor: ["#b8436d", "#00d9f9", "#a4c73c", "#a4add3"] }];



	phaseDeptHC: { [index: string]: any } = [];
	phaseSupDeptHC: { [index: string]: any } = [];



	//CG/VFX
	public cgVfxProdOptions: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
					barPercentage: 0.5, //horizontal chart height,
					fontFamily: "Nunito",
					fontSize: "10",
					//stepSize: 5,
				},
				categoryPercentage: 0.7 //horizontal chart height
			}],
			xAxes: [{
				ticks: {
					beginAtZero: true,
					barPercentage: 0.5,
					stepSize: 2,
					fontFamily: "Nunito",
					fontSize: "10",
				},
				categoryPercentage: 1,
			}]
		},
	};
	public cgVfxProdLabels: any[] = [];
	public cgVfxChartType: string = 'horizontalBar';
	public cgVfxLegend: boolean = false;
	public cgVfxProdHc: any[] = [{ data: [] }];
	public cgvfxProdTotal: number;
	public cgVfxProdColors: any[] = [
		{
			backgroundColor: ["#FF9800"]
		}];

	//CG/VFX
	public cgVfxsupOptions: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
					barPercentage: 0.5, //horizontal chart height,
					//stepSize: 5,
					fontFamily: "Nunito",
					fontSize: "10",
				},
				categoryPercentage: 0.5 //horizontal chart height
			}],
			xAxes: [{
				ticks: {
					beginAtZero: true,
					barPercentage: 0.5,
					stepSize: 2,
					fontFamily: "Nunito",
					fontSize: "10",
				},
				categoryPercentage: 0.5,
			}]
		},
	};
	public cgVfxsupLabels: any[] = [];
	public cgVfxsupChartType: string = 'horizontalBar';
	public cgVfxsupLegend: boolean = false;
	public cgVfxsupHc: any[] = [{ data: [] }];
	public cgvfxsupTotal: number;
	public cgVfxsupColors: any[] = [
		{
			backgroundColor: ["#FF9800"]
		}];

	//Age Ratio
	public ageRatioOptions: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
					barPercentage: 0.6, //horizontal chart height,
					//stepSize: 5,
					fontFamily: "Nunito",
					fontSize: "10",
				},
				scaleLabel: {
					display: true,
					fontSize: 12,
					labelString: 'Age (Years)',
					fontFamily: "Nunito",
					fontStyle: 'bold'
				},
				categoryPercentage: 0.6 //horizontal chart height
			}],
			xAxes: [{
				ticks: {
					beginAtZero: true,
					barPercentage: 0.5,
					fontFamily: "Nunito",
					fontSize: "10",
					stepSize: 5,
					//max:100
				},
				scaleLabel: {
					display: true,
					fontSize: 12,
					labelString: 'Employee Count',
					fontFamily: "Nunito",
					fontStyle: 'bold'
				},
				categoryPercentage: 0.5,
			}]
		},
	};
	public ageRatioLabels: any[] = [];
	public ageRatioChartType: string = 'horizontalBar';
	public ageRatioLegend: boolean = false;
	public ageRatio: any[] = [{ data: [] }];
	public ageRatioTotal: number;
	public ageRatioColors: any[] = [
		{
			backgroundColor: ['#6fad47', '#4472c4', '#ffc002', '#a5a5a5', '#ed7d32', '#5b9bd5']
		}];

	//Tenure Ratio
	public tenureOptions: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
					barPercentage: 0.6, //horizontal chart height,
					fontFamily: "Nunito",
					fontSize: "10",
					//stepSize: 5,
				},
				scaleLabel: {
					display: true,
					fontSize: 12,
					labelString: 'Tenure (Years)',
					fontFamily: "Nunito",
					fontStyle: 'bold'
				},
				categoryPercentage: 0.6 //horizontal chart height
			}],
			xAxes: [{
				ticks: {
					beginAtZero: true,
					barPercentage: 0.5,
					fontFamily: "Nunito",
					fontSize: "10",
					//stepSize: 5,
				},
				scaleLabel: {
					display: true,
					fontSize: 12,
					labelString: 'Employee Count',
					fontFamily: "Nunito",
					fontStyle: 'bold'
				},
				categoryPercentage: 0.5,
			}]
		},
	};
	public tenureLabels: any[] = [];
	public tenureChartType: string = 'horizontalBar';
	public tenureLegend: boolean = false;
	public tenure: any[] = [{ data: [] }];
	public tenureData: { [index: string]: any } = [];
	public tenureTotal: number;

	//Reason for Exit
	public reasonExitOptions: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true,
					barPercentage: 0.5, //horizontal chart height,
					fontFamily: "Nunito",
					fontSize: "10",
				},
				scaleLabel: {
					display: true,
					fontSize: 12,
					labelString: 'Reason',
					fontFamily: "Nunito",
					fontStyle: 'bold'
				},
				categoryPercentage: 0.5 //horizontal chart height
			}],
			xAxes: [{
				ticks: {
					beginAtZero: true,
					barPercentage: 0.5,
					stepSize: 2,
					fontFamily: "Nunito",
					fontSize: "10",
				},
				scaleLabel: {
					display: true,
					fontSize: 12,
					labelString: 'Employee Count',
					fontFamily: "Nunito",
					fontStyle: 'bold'
				},
				categoryPercentage: 0.5,
			}]
		},
	};
	public reasonExitLabels: any[] = [];
	public reasonExitChartType: string = 'horizontalBar';
	public reasonExitLegend: boolean = false;
	public reasonExitHc: any[] = [{ data: [] }];
	public reasonExitTotal: number;
	public reasonExitColors: any[] = [
		{
			backgroundColor: ["#009688"]
		}];

	//Monthly trend
	public lineChartLabels: Array<any> = [];
	public lineChartOptions: any = {
		responsive: true,
		scales: {
			yAxes: [{
				ticks: {
					fontSize: 10,
					stepSize: 10,
				},
			}],
			xAxes: [{
				ticks: {
					fontSize: 10,
					stepSize: 5,
				},
			}]
		},
	};
	public lineChartColors: Array<any> = [
		{ // grey
			backgroundColor: 'rgba(148,159,177,0.2)',
			borderColor: '#4bc0c0',
			pointBackgroundColor: 'rgba(148,159,177,1)',
			pointBorderColor: '#fff',
			pointHoverBackgroundColor: '#fff',
			pointHoverBorderColor: 'rgba(148,159,177,0.8)'
		}
	];
	public lineChartLegend: boolean = false;
	public lineChartType: string = 'line';
	monthlyHC: { [index: string]: any } = [];
	hcData = [];
	public lineChartData: any[] = [{ data: [] }];

	yearTrend: boolean = false;
	yearlyHC: { [index: string]: any } = [];
	public yearTrendData: any[] = [
		{
			label: "Hiring",
			data: [],
			type: "bar"
		},
		{
			label: "Attrition",
			//data: [5, 10, 13, 8, 11, 9],
			data: [],
			type: "bar",
			//fill: "false"
		},
		{
			label: "Closing Headcount",
			data: [],
			//data: [12, 8, 3, 4, 5, 9],
			type: "line",
			//fill: "false"
		}
	];

	//TotalLiveHC
	public TotalHC: { [index: string]: any } = [];

	//public labelsFake = ["Apr-2017", "May-2017", "Jun-2017", "Jul-2017", "Aug-2017", "Sep-2017", "Oct-2017", "Nov-2017", "Dec-2017", "Jan-2018", "Feb-2018", "Mar-2018", "Apr-2018"];
	public labels = [];
	public comboOptions: any = {
		responsive: true,
		legend: {
			labels: {
				fontFamily: "Nunito",
				fontSize: 10
			},
			position: "bottom"
		},
		scales: {
			yAxes: [{
				ticks: {
					fontSize: 10
				},
				scaleLabel: {
					display: true,
					fontSize: 12,
					labelString: 'Employee Count',
					fontFamily: "Nunito",
					fontStyle: 'bold'
				}
			}],
			xAxes: [{
				ticks: {
					fontSize: 10
				},
				scaleLabel: {
					display: true,
					fontSize: 12,
					labelString: 'Month',
					fontFamily: "Nunito",
					fontStyle: 'bold'
				}
			}]
		},
		layout: {
			padding: {
				left: 0,
				right: 0,
				top: 20,
				bottom: 0
			}
		}
	};
	public comboColors: Array<any> = [
		{ // red
			backgroundColor: '#4CAF50',
			borderColor: '#4CAF50',
			HoverBackgroundColor: '#4CAF50',
			HoverBorderColor: '#4CAF50'
		},
		{ // green
			backgroundColor: '#F44336',
			borderColor: '#F44336',
			pointBackgroundColor: '#F44336',
			pointBorderColor: '#F44336',
			pointHoverBackgroundColor: '#F44336',
			pointHoverBorderColor: '#F44336'
		},
		{
			borderColor: '#009688'
		}
	];


	//ShowCharts
	// isEmpMode:boolean = true;
	// isGender:boolean = true;
	// isVertical:boolean = true;
	isAttritionRate: boolean = false;
	isLocation: boolean = false;
	isAgeRatio: boolean = false;
	isTenure: boolean = false;
	loadHC: boolean = false;
	loadAttrition: boolean = false;
	isAttr: boolean = false;
	isReasonExit: boolean = false;

	isLiveHC: boolean = true;
	isHC: boolean = false;
	isAttritionSec: boolean = false;
	isOther: boolean = false;
	liveHC: any;
	noDataLabel: string = "No data available";
	attrRateData: { [index: string]: any } = [];
	attrTypeData: { [index: string]: any } = [];
	empTypeData: { [index: string]: any } = [];
	verticalData: { [index: string]: any } = [];
	locationData: { [index: string]: any } = [];
	ageRatioData: { [index: string]: any } = [];
	genderData: { [index: string]: any } = [];
	reasonExitData: { [index: string]: any } = [];

	projectlistofuser = [];
	slideConfig = { "slidesToShow": 10, "slidesToScroll": 10 };
	verticalOptions = this.appConfigs.user_vertical;
	//verticalOptions:Array<any>;

	constructor(
		private titleService: Title,
		private dataService: DataService,
		public commonJs: CommonJs,
		public appConfigs: AppConfigs,
		private router: Router) {

		this.cgVfxProdOptions['animation'] = {
			onComplete: function () {
				var ctx = this.chart.ctx;
				ctx.textAlign = "center";
				ctx.textBaseline = "middle";
				var chart = this;
				var datasets = this.config.data.datasets;
				datasets.forEach(function (dataset: Array<any>, i: number) {
					ctx.font = "10px Nunito";
					//var fontSize =5; 
					ctx.fillStyle = "Black";
					ctx.textAlign = "center";
					ctx.textBaseline = "bottom";
					chart.getDatasetMeta(i).data.forEach(function (p: any, j: any) {
						var padding = 0;
						//var position = p.tooltipPosition(); 
						//ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
						if (datasets[i].data[j] != 0) {
							ctx.fillText(datasets[i].data[j], p._model.x, p._model.y);
						}
					});
				});
			}
		};

		this.cgVfxsupOptions['animation'] = {
			onComplete: function () {
				var ctx = this.chart.ctx;
				ctx.textAlign = "center";
				ctx.textBaseline = "middle";
				var chart = this;
				var datasets = this.config.data.datasets;
				datasets.forEach(function (dataset: Array<any>, i: number) {
					ctx.font = "10px Nunito";
					//var fontSize =5; 
					ctx.fillStyle = "Black";
					ctx.textAlign = "center";
					ctx.textBaseline = "bottom";
					//ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
					chart.getDatasetMeta(i).data.forEach(function (p: any, j: any) {
						var padding = 0;
						if (datasets[i].data[j] != 0) {
							ctx.fillText(datasets[i].data[j], p._model.x, p._model.y - (5 / 2) - padding);
						}
					});
				});
			}
		};



		this.lineChartOptions['animation'] = {
			onComplete: function () {
				var ctx = this.chart.ctx;
				ctx.textAlign = "center";
				ctx.textBaseline = "middle";
				var chart = this;
				var datasets = this.config.data.datasets;
				datasets.forEach(function (dataset: Array<any>, i: number) {
					ctx.font = "10px Nunito";
					ctx.fillStyle = "Black";
					ctx.textAlign = "center";
					ctx.textBaseline = "bottom";
					chart.getDatasetMeta(i).data.forEach(function (p: any, j: any) {
						if (datasets[i].data[j] != 0) {
							ctx.fillText(datasets[i].data[j], p._model.x, p._model.y);
						}
					});
				});
			}
		};

		this.ageRatioOptions['animation'] = {
			onComplete: function () {
				var ctx = this.chart.ctx;
				ctx.textAlign = "center";
				ctx.textBaseline = "middle";
				var chart = this;
				var datasets = this.config.data.datasets;
				datasets.forEach(function (dataset: Array<any>, i: number) {
					ctx.font = "10px Nunito";
					var fontSize = 5;
					ctx.fillStyle = "Black";
					ctx.textAlign = "center";
					ctx.textBaseline = "bottom";
					//ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
					chart.getDatasetMeta(i).data.forEach(function (p: any, j: any) {
						var padding = 0;
						//var position = p.tooltipPosition(); 
						//ctx.fillText(datasets[i].data[j], p._model.x, p._model.y - (fontSize / 2) - padding);
						if (datasets[i].data[j] != 0) {
							ctx.fillText(datasets[i].data[j], p._model.x, p._model.y - 10);
						}
					});
				});
			}
		};

		this.tenureOptions['animation'] = {
			onComplete: function () {
				var ctx = this.chart.ctx;
				ctx.textAlign = "center";
				ctx.textBaseline = "middle";
				var chart = this;
				var datasets = this.config.data.datasets;
				datasets.forEach(function (dataset: Array<any>, i: number) {
					ctx.font = "10px Nunito";
					var fontSize = 5;
					ctx.fillStyle = "Black";
					ctx.textAlign = "center";
					ctx.textBaseline = "bottom";
					//ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
					chart.getDatasetMeta(i).data.forEach(function (p: any, j: any) {
						if (datasets[i].data[j] != 0) {
							ctx.fillText(datasets[i].data[j], p._model.x, p._model.y - 10);
						}
					});
				});
			}
		};

		this.comboOptions['animation'] = {
			onComplete: function () {
				var ctx = this.chart.ctx;
				ctx.textAlign = "center";
				ctx.textBaseline = "middle";
				var chart = this;
				var datasets = this.config.data.datasets;
				datasets.forEach(function (dataset: Array<any>, i: number) {
					ctx.font = "10px Nunito";
					var fontSize = 5;
					ctx.fillStyle = "Black";
					ctx.textAlign = "center";
					ctx.textBaseline = "bottom";
					//ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
					chart.getDatasetMeta(i).data.forEach(function (p: any, j: any) {
						var padding = 0;
						//var position = p.tooltipPosition(); 
						//ctx.fillText(datasets[i].data[j], p._model.x, p._model.y - (fontSize / 2) - padding);
						if (datasets[i].data[j] != 0) {
							ctx.fillText(datasets[i].data[j], p._model.x, p._model.y - 10);
						}
					});
				});
			}
		};


		this.reasonExitOptions['animation'] = {
			onComplete: function () {
				var ctx = this.chart.ctx;
				ctx.textAlign = "center";
				ctx.textBaseline = "middle";
				var chart = this;
				var datasets = this.config.data.datasets;
				datasets.forEach(function (dataset: Array<any>, i: number) {
					ctx.font = "10px Nunito";
					//var fontSize =5; 
					ctx.fillStyle = "Black";
					ctx.textAlign = "center";
					ctx.textBaseline = "bottom";
					//ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
					chart.getDatasetMeta(i).data.forEach(function (p: any, j: any) {
						var padding = 10;
						if (datasets[i].data[j] != 0) {
							ctx.fillText(datasets[i].data[j], p._model.x, p._model.y);
						}
					});
				});
			}
		};
	}

	ngOnInit() {
		//this.verticalOptions = [{ "id":'0',"name": 'All' }];
		//this.verticalOptions.unshift({ 'id':'0','name': 'All' });
		//this.projectListByUser();
		this.titleService.setTitle(this.settings.html_prefix + this.entityName);
		this.getDeptHeadCount();
		this.getPhaseDeptHeadCount();
		this.empTypeOptionsPie['pieceLabel'] = {
			fontColor: '#000',
			fontSize: 10,
			fontFamily: 'Nunito',
			render: function (args) {
				return args.value + '%';
			}
		};

		this.genderOptionsPie['pieceLabel'] = {
			fontColor: '#000',
			fontSize: 10,
			fontFamily: 'Nunito',
			render: function (args) {
				return args.value + '%';
			}
		};

		this.verticalOptionsPie['pieceLabel'] = {
			fontColor: '#000',
			fontSize: 10,
			fontFamily: 'Nunito',
			render: function (args) {
				return args.value + '%';
			}
		};

		this.attrOptionsPie['pieceLabel'] = {
			fontColor: '#000',
			fontSize: 10,
			fontFamily: 'Nunito',
			render: function (args) {
				return args.value + '%';
			}
		};

		this.attrRateOptionsPie['pieceLabel'] = {
			fontColor: '#000',
			fontSize: 10,
			fontFamily: 'Nunito',
			render: function (args) {
				return args.value + '%';
			}
		};

		this.locationOptionsPie['pieceLabel'] = {
			fontColor: '#000',
			fontSize: 10,
			fontFamily: 'Nunito',
			render: function (args) {
				return args.value + '%';
			}
		};

		this.search('', 'vertical');
		this.data.vertical = { "id": '0', "name": 'All' };
		//this.verticalOptions.unshift({ 'id':'0','name': 'All' });
	}

	search(event, column) {
		if (column == 'vertical') {
			//if (index !== -1) {
			this.verticalOptions = this.verticalOptions.map(o => {
				return o;
			});
			const index = this.verticalOptions.findIndex((col) => {
				return col.name == 'All';
			});
			if (index == -1) {
				this.verticalOptions.unshift({ 'id': '0', 'name': 'All' });
			}
			// if(this.verticalOptions['id']){
			// }
			//}
		}
	}

	selectedOptionChange(event, column) {
		this.where.vertical = column;
		this.getPhaseDeptHeadCount(column);

	}


	getModeChart() {
		this.dataService.getListData('getModeChart', '')
			.subscribe((success) => {
				this.empTypeData = success.data;
				this.empTypeChartTypeDataPie = this.empTypeData.modepie;
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getVerticalChart() {
		this.dataService.getListData('getVerticalChart', '')
			.subscribe((success) => {
				this.verticalData = success.data;
				this.verticalDataPie = this.verticalData.vertical;
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getAttritionType() {
		this.dataService.getListData('getAttritionType', '')
			.subscribe((success) => {
				this.attrYear = success.year;
				this.attrTypeData = success.data;
				this.attrDataPie = this.attrTypeData.attrition;
				setTimeout(() => {
					this.isAttr = true;
				});
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getLocation() {
		this.dataService.getListData('getLocation', '')
			.subscribe((success) => {
				this.locationData = success.data;
				this.locationDataPie = this.locationData.locationHC.locationHC;
				setTimeout(() => {
					//this.doughnutChartColors = [{ backgroundColor: ["#b8436d", "#00d9f9", "#a4c73c", "#a4add3"] }];
					this.locationLabels = this.locationData.locationHC.location
					this.isLocation = true;
				});
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getDeptHeadCount() {
		this.dataService.getListData('getDeptHeadCount', { "type": 'production' })
			.subscribe((success) => {
				this.liveHC = success.liveHC;
				this.cgVfxProdLabels = success.cgVfxHCLabel;
				this.cgvfxProdTotal = success.total;
				this.cgVfxProdHc[0]['data'] = [];
				let cgvfx = success.data;
				let that = this;
				_.forEach(cgvfx, function (value, key) {
					that.cgVfxProdColors[0]['backgroundColor'] = '#FF9800';
					that.cgVfxProdHc[0]['data'].push(value['value']);
				});
			}, (rejected) => {
				//console.log(rejected);
			});

		this.dataService.getListData('getDeptHeadCount', { "type": 'support' })
			.subscribe((success) => {
				this.cgVfxsupLabels = success.cgVfxHCLabel;
				this.cgvfxsupTotal = success.total;
				this.cgVfxsupHc[0]['data'] = [];
				let cgvfxsup = success.data;
				let that = this;
				_.forEach(cgvfxsup, function (value, key) {
					that.cgVfxsupColors[0]['backgroundColor'] = '#4CAF50';
					that.cgVfxsupHc[0]['data'].push(value['value']);
				});
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getGenderChart() {
		this.dataService.getListData('getGenderChart', '')
			.subscribe((success) => {
				this.genderData = success.data;
				this.genderDataPie = this.genderData.gender;
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getPhaseDeptHeadCount(vertical = { "id": "0", "name": "All" }) {
		this.dataService.getListData('getPhaseDeptHeadCount', { vertical: vertical })
			.subscribe((success) => {
				this.phaseDeptHC = success.data;
				this.phaseSupDeptHC = success.support;
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getAgeRatio() {
		this.dataService.getListData('getAgeRatio', '')
			.subscribe((success) => {
				this.ageRatioData = success.data;
				this.ageRatioLabels = ["> 45", "36-45", "31-35", "26-30", "21-25", "< 21"];;
				this.ageRatio[0]['data'] = [];
				let that = this;
				_.forEach(this.ageRatioData.Age, function (value, key) {
					that.ageRatio[0]['data'].push(value);
				});
				setTimeout(() => {
					this.isAgeRatio = true;
				});
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getTenure() {
		this.dataService.getListData('getTenure', '')
			.subscribe((success) => {
				this.tenureData = success.data;
				this.tenureLabels = ["> 10", "7-10", "5-7",
					"3-5", "1-3", "< 1"];
				let that = this;
				_.forEach(this.tenureData.Tenure, function (value, key) {
					that.tenure[0]['data'].push(value);
				});
				setTimeout(() => {
					this.isTenure = true;
				});
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getAttritionRate() {
		this.dataService.getListData('getAttritionRate', '')
			.subscribe((success) => {
				this.attrRateData = success.data;
				this.attrRateDataPie = this.attrRateData[0].attrRate;
				this.attrRateYear = success.year;
				setTimeout(() => {
					this.attrRateLabels = ['Attrition', 'Hiring'];
					this.isAttritionRate = true;
				});
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getMonthlyHC() {
		this.dataService.getListData('getMonthlyHC', '')
			.subscribe((success) => {
				this.monthlyHC = success.data;
				this.liveHC = success.data.liveHCounts;
				this.lineChartLabels = success.data.monthLabel;
				let that = this;
				_.forEach(this.monthlyHC['hcData'], function (value, key) {
					that.lineChartData[0]['data'].push(value);
				});
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getYearlyTrend() {
		this.dataService.getListData('getMonthlyHC', { 'yearly': 'yearly' })
			.subscribe((success) => {
				this.yearTrend = false;
				this.yearlyHC = success.data;
				this.liveHC = success.data.liveHCounts;
				this.labels = success.data.monthLabel;
				this.yearTrendData[0]['data'] = [];
				this.yearTrendData[1]['data'] = [];
				this.yearTrendData[2]['data'] = [];
				let that = this;

				_.forEach(this.yearlyHC['yearlyHC']['hiring'], function (value, key) {
					setTimeout(() => {
						that.yearTrendData[0]['data'].push(value);
					});
				});

				_.forEach(this.yearlyHC['yearlyHC']['attrition'], function (value, key) {
					setTimeout(() => {
						that.yearTrendData[1]['data'].push(value);
					});
				});

				_.forEach(this.yearlyHC['hcData'], function (value, key) {
					setTimeout(() => {
						that.yearTrendData[2]['data'].push(value);
					});
				});

				setTimeout(() => {
					this.yearTrend = true;
					this.loadHC = true;
				}, 1000);
			}, (rejected) => {
				//console.log(rejected);
			});
		//this.yearTrendData[0]['data'] = t;
	}

	getTotalHC() {
		this.dataService.getListData('getTotalHC', '')
			.subscribe((success) => {
				this.TotalHC = success.data.hcData;
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	getReasonForExit() {
		this.dataService.getListData('getReasonForExit', '')
			.subscribe((success) => {
				this.reasonExitData = success.data;
				let that = this;

				setTimeout(() => {
					this.reasonExitHc[0]['data'] = this.reasonExitData.reasonExitHC.reasonExitHC;
					this.reasonExitColors[0]['backgroundColor'] = '#009688';
					this.reasonExitLabels = this.reasonExitData.reasonExitHC.reasonExit;
					this.isReasonExit = true;
				});
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	showSection(section) {
		if (section == 'HC') {
			this.isHC = true;
			this.isLiveHC = false;
			this.isAttritionSec = false;
			this.isOther = false;
			this.isLocation = false;
			this.isAgeRatio = false;
			this.isTenure = false;
			this.isReasonExit = false;
			this.isAttr = false;
			this.isAttritionRate = false;
			//this.yearTrend = true;
			this.getYearlyTrend();
			this.getTotalHC();

		} else if (section == 'ATTRITION') {
			this.isHC = false;
			this.loadHC = false;
			this.isLiveHC = false;
			this.isAttritionSec = true;
			this.isOther = false;
			this.isLocation = false;
			this.isAgeRatio = false;
			this.getTenure();
			this.getAttritionType();
			this.getAttritionRate();
			this.getReasonForExit();
		} else if (section == 'OTHER') {
			this.isHC = false;
			this.loadHC = false;
			this.isLiveHC = false;
			this.isAttritionSec = false;
			this.isOther = true;
			this.isTenure = false;
			this.isReasonExit = false;
			this.isAttr = false;
			this.isAttritionRate = false;
			this.getModeChart();
			this.getVerticalChart();
			this.getLocation();
			this.getAgeRatio();
			this.getGenderChart();
		} else if (section == 'liveHC') {
			//console.log(section);
			this.isHC = false;
			this.loadHC = false;
			this.isLiveHC = true;
			this.isAttritionSec = false;
			this.isOther = false;
			this.isLocation = false;
			this.isAgeRatio = false;
			this.isTenure = false;
			this.isReasonExit = false;
			this.isAttr = false;
			this.isAttritionRate = false;
			this.data.vertical = this.verticalOptions[0];
			this.getDeptHeadCount();
			this.getPhaseDeptHeadCount();
		}
	}

	getSum(role = '', prod: any = '', chartType = '') {
		let sum: any = 0;
		if (chartType == 'tableHC') {
			if (prod == 0) {
				for (let i = 0; i < this.phaseDeptHC.length; i++) {
					for (let j = 0; j < this.phaseDeptHC[i].department.length; j++) {
						if (this.phaseDeptHC[i]['is_default'] == prod && this.phaseDeptHC[i]['department'][j][role]) {
							sum += this.phaseDeptHC[i]['department'][j][role];
						}
					}
				}
				return sum;
			}
			else if (prod == 1) {
				for (let i = 0; i < this.phaseSupDeptHC.length; i++) {
					for (let j = 0; j < this.phaseSupDeptHC[i].department.length; j++) {
						if (this.phaseSupDeptHC[i]['is_default'] == prod && this.phaseSupDeptHC[i]['department'][j][role]) {
							sum += this.phaseSupDeptHC[i]['department'][j][role];
						}
					}
				}
				return sum;
			}
		}
	}


	exportReport(report) {
		this.dataService.getListData('headCountExport', { 'where': this.where })
			.subscribe((success) => {
				window.location.href = success.data;
			}, (rejected) => {
				//console.log(rejected);
			});
	}

}
