import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfigs } from 'src/app/common/app-configs';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';
import { DatatableService } from 'src/app/services/datatable/datatable.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExcelmapimportComponent } from '../../../common/excelmapimport/excelmapimport.component';
import { Subscription } from 'rxjs';
import { DatatableComponent, SelectionType, TableColumn } from '@swimlane/ngx-datatable';
import swal from 'sweetalert2';
import { columns } from '../utility/column-generator';
import { GridService } from 'src/app/services/grid.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThumbnailModalServiceService } from 'src/app/services/thumbnail-modal-service.service';
import { ConfirmationPopupComponent } from 'src/app/common/confirmation-popup/confirmation-popup.component';
declare var $: any;

@Component({
  selector: 'app-sequences-datatable',
  templateUrl: './sequences-datatable.component.html',
  styleUrls: ['./sequences-datatable.component.css']
})
export class SequencesDatatableComponent implements OnInit {

  public dataTableColumns: TableColumn[] = [];
  isListView: boolean = true;
  isThumbnailView: boolean = false;
  shot_opened: boolean = false;
  showDeptWiseSummary: boolean = false;
  public columnsOriginal: Array<any> = [];
  isEpisodicSeries: boolean = false;
  clientCode = localStorage.getItem('client_code');
  projectId = ''//this.commonJs.getLocalStorage('projectId');
  deptList = [];
  phaseId = 0;
  loadingCount: number = 0;
  currentSequence = [];
  sequenceSummary = [];
  parentId = '';
  sequenceSummaryTotal = [];
  phaseList = [];
  currentPhase = { name: 'All' };
  fromNotify: any = '';
  rowDetail = {};
  isSequenceAddPermission: boolean = false;
  isSequenceViewPermission: boolean = false;
  isSequenceEditPermission: boolean = false;
  shotSideWindow: boolean = false;
  isSequenceDeletePermission: boolean = false;
  isShotAddPermission: boolean = false;
  isShotViewPermission: boolean = false;
  busy: Subscription;

  bsModalRef: BsModalRef;
  settings: any = JSON.parse(localStorage.getItem('settings'));
  pagePayload: any = {
    page: 0,
    itemsPerPage: 100,
  };
  sequenceDatatableObjects: any = {};
  isLoadingFirstTime: boolean = true;
  isClearFilter: boolean = false;
  scrollTimeout: any;
  public FilterColumns: Array<any> = ['name'];
  suppressPagining: boolean = false;
  public config: any = {
    sort: {
      "title": "#ID",
      "name": "id",
      "sort": "desc",
      "className": "hide-this"
    },
    sortNew: { id: 'desc' },
    start: 0,
    sorting: { columns: '' },
    FilterByColumns: {
      "episode_id": "",
      "number": "",
      "name": "",
      "lead": "",
      "producer": "",
      "complexity": "",
      "projected_shots": "",
      "projected_seconds": "",
      "status": ""
    },
    FilterColumns: this.FilterColumns,
    filtering: { filterString: '' },
    // className: ['table-striped', 'table-bordered'],
    where: { 'project_id': this.projectId, 'phaseId': this.phaseId },
    isLoadingFirstTime: true,
    itemsPerPage: this.pagePayload.itemsPerPage,
    isClearFilter: false,
  };
  headerDropdowns = {
    status: '',
    complexity: ''
  };
  checkFilters = { 'status': [], 'complexity': [] };
  complexityArray: Array<any>;
  statusOptions = [
    { id: 1, text: "Active" },
    { id: 0, text: "Inactive" },
  ];
  apiRoutePrefix = 'sequences';
  apiRoutePrefixColumn = 'task';
  rows: any = [];
  currentPage: number = 0;
  pageSize: number = 100;
  totalItems: number = 0;
  totalCount: number = 0;
  isLoading: boolean = true;

  public readonly pageLimitOptions = [
    { value: 50 },
    { value: 100 },
    { value: 200 },
    { value: 300 },
  ];
  readonly headerHeight = 90;
  readonly rowHeight = 40;
  readonly footerHeight = 50;
  statusArr = [];


  selected = [];
  SelectionType: SelectionType[] = [];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  @ViewChild('image', { static: false }) public image: TemplateRef<any>;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;
  @ViewChild('duration', { static: false }) public duration: TemplateRef<any>;
  @ViewChild('stats', { static: false }) public stats: TemplateRef<any>;
  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;
  @ViewChild("departmentStatus", { static: true }) departmentStatus: TemplateRef<any>;
  @ViewChild('container', { static: true }) containerRef: ElementRef;


  isSequencesPermissions = [];
  _ng_sidebar_data = {};
  _opened: boolean = false;
  _ng_import_type: string;
  _loadImportSideWindow: boolean = false;
  _import_opened: boolean = false;
  _loadSideWindow: boolean = false;
  _manage_col_opened: boolean = false;
  _ng_sidebar_manage_col_data = {};
  sequenceInformation: any;
  _ng_sidebar_detail_data = {};
  _detail_opened: boolean = false;
  isFilterApplied: boolean = false;
  _tasksAssignAndCommentsOpened: boolean = false;
  _ngTasksAssignCommentsData = {};
  _ng_sidebar_shot_data = {};
  projectroles = {};
  currentRole = '';
  deleteAllId = [];
  openedPage: number = 0;


  checboxColum = {
    prop: 'selected',
    name: '',
    sortable: false,
    frozenLeft: true,
    canAutoResize: false,
    draggable: true,
    resizable: false,
    headerCheckboxable: true,
    checkboxable: true,
    width: 40,
  }
  clientCodePrefix = this.commonJs.getClientCodePrefix();

  isFullScreen:boolean = false;
  previousOffsetY:number = 0;

  constructor(
    public appConfigs: AppConfigs,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private dataService: DataService,
    private router: Router,
    private datatableService: DatatableService,
    public commonJs: CommonJs,
    private modalService: BsModalService,
    private gridService: GridService,
    private spinner: NgxSpinnerService,
    private thumbnailModalService: ThumbnailModalServiceService,
  ) {
    this.rows = [];
    // this.datatableService.setParam(this.dataTableColumns, 'columns');
    this.datatableService.setParam(this.config, 'config');
    this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
    this.datatableService.setParam(this.apiRoutePrefix + 'List', 'serverUrl');
  }

  ngOnInit() {
    // Set HTML Prefic
    this.sequenceDatatableObjects.seasonsId = '';
    this.titleService.setTitle(this.settings.html_prefix + ' Sequences');
    this.complexityArray = this.appConfigs.sht_ast_complexity.map(v => { return v });

    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
      this.sequenceDatatableObjects.projectId = params['id'];

      this.config.where.project_id = this.sequenceDatatableObjects.projectId;
      if (params['episode'] != undefined) {
        this.parentId = params['episode'];
        this.sequenceDatatableObjects.episodeId = params['episode'];
        this.config.where['episode_id'] = params['episode'];
        this.dataService.getDataById('episodes/' + this.sequenceDatatableObjects.episodeId + '/edit')
          .subscribe((success2) => {
            this.sequenceDatatableObjects.episode_name = success2.data.name;
          });
      }
      if (params['seasons'] != undefined) {
        this.sequenceDatatableObjects.seasonsId = params['seasons'];
        this.dataService.getDataById('seasons/' + this.sequenceDatatableObjects.seasonsId + '/edit')
          .subscribe((success2) => {
            this.sequenceDatatableObjects.season_name = success2.data.name;
          });
      }
      setTimeout(() => {
        localStorage.setItem('projectId', params['id']);
      }, 500);
    });

    this.isSequenceAddPermission = this.commonJs.checkAccess('sequences', 'add', { 'baiPass': ['admin'] }, this.projectId);
    this.isSequenceEditPermission = this.commonJs.checkAccess('sequences', 'edit', { 'baiPass': ['admin'] }, this.projectId);
    this.isSequenceViewPermission = this.commonJs.checkAccess('sequences', 'view', { 'baiPass': ['admin'] }, this.projectId);
    this.isSequenceDeletePermission = this.commonJs.checkAccess('sequences', 'delete', { 'baiPass': ['admin'] }, this.projectId);
    this.isShotAddPermission = this.commonJs.checkAccess('shots', 'add', { 'baiPass': ['admin'] }, this.projectId);
    this.isShotViewPermission = this.commonJs.checkAccess('shots', 'view', { 'baiPass': ['admin'] }, this.projectId);
    this.projectroles = (function () {
      var result;
      try {
        let result = JSON.parse(localStorage.getItem('projectRoles'))
        return result;
      } catch (exception) {
      }
    }());
    if (this.projectroles) {
      this.currentRole = this.projectroles['project_' + this.sequenceDatatableObjects.projectId];
    }
    this.dataService.getDataById('projects/' + this.projectId + '/edit')
      .subscribe((success) => {
        this.isEpisodicSeries = success.data.episodic_series;
        this.createColumns([]);
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.toastrService.notify('warn', '', err.message);
          this.router.navigate(['/dashboard']);
        }
      });
    this.getPhases();
    /** Help modal show when you open from the  import modal window*/
    this.dataService.notifyObservable$.subscribe(res => {
      if (res.refresh) {
        this._import_opened = true;
        this._ng_import_type = 'sequence';
        this.fromNotify = 'modalImport'
      }
    });
  }

  // open glbal image thumbnail viewer
  openImageModal(imageUrl: string): void {
    this.thumbnailModalService.openThumbnailModal(imageUrl);
  }

  departMentList: any = [];
  projectDepartmentList() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getListData('projectDepartmentList', { 'project_id': this.projectId, 'type': 'episode', 'phaseId': this.phaseId, 'fromAssetShot': false, query: '' })
        .subscribe((success) => {
          this.departMentList = success.data;
          const modifiedArray = success.data.map(obj => ({
            prop: obj.department_name,
            task_dept_id: obj.department_id,
            name: obj.department_name,
            sortable: false,
            draggable: false,
            width: 150,
            mimWidth: 150,
            maxWidth: 150,
            canAutoResize: true,
            dynamicColumn: true,
            cellTemplate: this.departmentStatus
          }));
          this.sequenceDatatableObjects.modifiedArray = modifiedArray;
          this.createColumns(modifiedArray);
          resolve();
        }, (rejected) => {

        });
    });
  }

  showSequenceDeptSummary(showSummary: TemplateRef<any>, row) {
    this.gridService.openProgressModal();
    let sequenceId = row.id
    let episodeId = row.episodeId
    this.currentSequence = row;
    let query = { query: '' };
    this.dataService.getListData('getSequencesDeptWiseSummary', { 'project_id': this.projectId, 'isEpisodicSeries': this.isEpisodicSeries, 'episode_id': episodeId, sequence_id: sequenceId, 'phaseId': this.phaseId, type: 'episode', 'query': query['query'] })
      .subscribe((success) => {
        this.sequenceSummary = success.data.deptCounts;
        this.sequenceSummaryTotal = success.data.finalCounts;
        if (this.deptList.length <= 0) {
          this.deptList = success.data.depts;
        }
        this.bsModalRef = this.modalService.show(showSummary, {
          animated: true,
          keyboard: true,
          backdrop: true,
          ignoreBackdropClick: false, class: 'episodes-dept-summary-modal'
        });
        this.gridService.myEventEmitterForProgressModal.emit(true);
      }, (rejected) => {
        this.gridService.myEventEmitterForProgressModal.emit(true);
      });
    this.gridService.progressFunction();
    this.commonJs.initiateJS();
  }


  private createColumns(modifiedArray): void {
    let cellTemplate: any = {};
    setTimeout(() => {
      cellTemplate = {
        ids: this.idsT,
        image: this.image,
        status: this.status,
        stats: this.stats,
        duration: this.duration,
        hdrTpl: this.hdrTpl,
        departmentStatus: this.departmentStatus,
      };
      this.dataTableColumns = [];
      this.dataTableColumns = columns('sequence', cellTemplate, modifiedArray);
      console.log(this.sequenceDatatableObjects)
      if (!this.isEpisodicSeries) {
        this.dataTableColumns = this.dataTableColumns.filter(obj => obj.prop !== 'seasonEpisode');
      } else {
        this.dataTableColumns = this.dataTableColumns.filter(obj => obj.name !== 'Stats');
      }
      if (this.showDeptWiseSummary) {
        this.dataService.sequenceDepartmentViewData(this.config).subscribe((department: any) => {
          this.seqDeptList = department.data;
          // Iterate through the original array
          this.rows.forEach(originalObj => {
            // Find the matching dept object
            let matchingDeptObj = this.seqDeptList.find(deptObj => deptObj.id === originalObj.id);

            // If a matching dept object is found, merge the dept values into the original object
            if (matchingDeptObj) {
              Object.assign(originalObj, matchingDeptObj.dept);
            }
          });
          this.rows = [...this.rows];
        });
      }
    });
  }

  public actionClick(action: string, rowId: any): void {
    let id: number = rowId;
    switch (action) {
      case ("actionDelete"):
        this.confirm(id);
        break;
      case ("actionEditSideWindow"):
        this.openSideWindow('edit', id);
        break;
      case ("actionViewSideWindow"):
        this.openSideWindow('view', id);
        break;
      case ("actionAddShot"):
        this.openSideWindow('create', id, true);
        break;
      default:
        break;
    }
  }

  /** Action Button */
  confirm(id) {
    const config = {
      initialState: {
        title: 'Delete Sequence',
        message: "Are you sure you want to delete this  Sequence(s)?",
      },
      class: 'modal-md custom-modal-css',
      centered: true,
      animated: false
    };
    this.bsModalRef = this.modalService.show(ConfirmationPopupComponent, config);
    // Subscribe to the onConfirmAction event
    this.bsModalRef.content.onConfirmAction.subscribe(() => {
      // Handle the confirmation logic here
      this.dataService.deleteData(this.apiRoutePrefix + 'Destroy', { id })
        .subscribe((success) => {
          if (success.status == 'error') {
            this.toastrService.notify('error', '', success.message);
          } else {
            this.toastrService.notify('success', '', success.message);
            this.isLoading = true;
            this.getSequenceList();
          }
        }, (rejected) => {

        });
    });

    // let that = this;
    // swal.fire({
    //   title: 'Delete Sequence(s)',
    //   text: "Are you sure you want to delete this  Sequence(s)?",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#DD6B55",
    //   confirmButtonText: "Delete",
    //   cancelButtonText: "Cancel",
    // }).then((result) => {
    //   if (result.value) {
    //     that.dataService.deleteData(that.apiRoutePrefix + 'Destroy', { id })
    //       .subscribe((success) => {
    //         if (success.status == 'error') {
    //           //that.toastrService.notify('error','', success.message);
    //         } else {
    //           that.rows = [];
    //           // that.onChangeTable(that.config);
    //           this.getSequenceList();
    //         }
    //         that.toastrService.notify(success.status, '', success.message);
    //       }, (rejected) => {

    //       });
    //   } else {
    //     //console.log('cancelled');
    //   }
    // }
    // )

  }

  /**
   * Description: Page change event
   * @param e pager object
   */
  firstTimeLoad: boolean = false;
  onPageChange(e): void {

    // this.currentPage = e.offset;
    this.openedPage = e.offset;
    this.isLoading = true;
    this.pagePayload.page = e.offset * this.pageSize;
    this.config.start = this.pagePayload.page;
    if (this.firstTimeLoad) {
      this.table.offset = this.openedPage;
      this.dataService.pagePreferancePost({
        pageSize: this.pageSize,
        currentPage: this.config.start,
        pageIndex: this.table.offset,
        user_id: JSON.parse(localStorage.getItem('user')).id,
        project_id: this.projectId
      }, 'sequence_list_page');
      this.getSequenceList();
    } else {
      this.dataService.pagePreferanceGet('sequence_list_page').then((res: any) => {
        if (res.data.length == undefined) {
          this.pageSize = res.data.pageSize;
          this.config.itemsPerPage = res.data.pageSize;
          this.config.start = res.data.currentPage;
          this.table.offset = res.data.pageIndex;
          this.getSequenceList();
        } else {
          this.getSequenceList();
        }
      }, (err: any) => {
        console.warn("Error while fetching page set")
      });
    }
    this.firstTimeLoad = true;
  }

  handleScroll() {
    this.suppressPagining = true;
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPagining = false;
    }, 100)
  }


  // TODO[Dmitry Teplov] wrap dynamic limit in a separate component.
  public onLimitChange(limit: any): void {
    this.changePageLimit(limit);
    this.table.limit = this.pageSize;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor((this.table.rowCount - 1) / this.table.limit);
      }
    });
  }

  private changePageLimit(limit: any): void {
    this.pageSize = parseInt(limit, 10);
    this.dataService.pagePreferancePost({
      pageSize: this.pageSize,
      currentPage: this.config.start,
      pageIndex: this.table.offset,
      user_id: JSON.parse(localStorage.getItem('user')).id,
      project_id: this.projectId
    }, 'sequence_list_page');
    this.pagePayload.itemsPerPage = limit;
    // this.onChangeTable(this.config);

    this.getSequenceList();
  }

  onRowBasic(row) {
    this.rowDetail = row;
  }


  // on input column filter applied
  onColumnFilter(e: any, id: string): void {
    this.isLoading = true;
    this.config.start = 0;
    this.getSequenceList();
  }


  /** Search Auto complete */
  search(event: any, type: string) {

    if (type == 'complexity') {
      this.complexityArray = [...this.complexityArray];
    }
    else {
      this.statusOptions = [...this.statusOptions];
    }
  }

  /**********    Side Window    **********/

  openSideWindow(mode, id, is_shot?) {
    if (is_shot) {
      this._ng_sidebar_shot_data['mode'] = mode;
      this._ng_sidebar_shot_data['projectId'] = this.projectId;
      this._ng_sidebar_shot_data['sequence'] = true;
      this._ng_sidebar_shot_data['sequence_id'] = id;
      this.shot_opened = true;
    }
    else {
      if (id != undefined && id != '') {
        this._ng_sidebar_data['sequenceid'] = id;
      }

      if (this.parentId != '') {
        this._ng_sidebar_data['episode'] = true;
        this._ng_sidebar_data['episode_id'] = this.parentId;
      }
      this._ng_sidebar_data['projectId'] = this.projectId;
      this._ng_sidebar_data['mode'] = mode;
      this._ng_sidebar_data['isActive'] = true;
      this._opened = true;
    }
  }

  // Open create sequence side window
  onOpened(type) {
    if (type == 'shot') {
      this.shotSideWindow = true;
    }
    else if (type == 'import') {
      this._loadImportSideWindow = true;
    } else {
      this._loadSideWindow = true;
    }
  }

  // Close side window
  onClosed(type) {
    if (type == 'shot') {
      this.shotSideWindow = false;
    }
    else if (type == 'import') {
      this._loadImportSideWindow = false;
    }
    else {
      this._loadSideWindow = false;
      // this.onChangeTable(this.config);
      this.getSequenceList();
    }
  }

  // Opend create sequence side window when close
  closeSideWindow() {
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.getSequenceList();
  }

  // Export data
  exportData() {
    this.config.export = true;
    this.getSequenceList();
  }

  // Import data
  public openModalWithComponent() {
    this.dataService.setProjectId(this.sequenceDatatableObjects.projectId);

    let data: any = {
      from: 'SequenceComponent',
      header: 'Sequence'
    }

    this.dataService.setImportFrom(data);
    let list = [this.sequenceDatatableObjects.projectId];
    this.bsModalRef = this.modalService.show(ExcelmapimportComponent, {
      backdrop: 'static',
      keyboard: false
    });
    this.bsModalRef.content.title = 'Modal with component';
    this.bsModalRef.content.list = list;
    this.bsModalRef.content.event.subscribe((res: any) => {
      this._import_opened = false;
      this._loadImportSideWindow = false;
      // this.onChangeTable(this.config);
      this.getSequenceList();
    });
  }

  /**close modeal event */
  // closeManageCol(value) {
  //   if (value.reload) {
  //     this.columnSet().then((response: any) => {
  //       if (response.status == 'success') {
  //         this.createColumns(this.sequenceDatatableObjects.modifiedArray);
  //       }
  //     }, (err: any) => {
  //       console.warn("Error while fetching column set")
  //     });
  //   }
  // }

  /** Get column preferance  */
  // columnSet() {
  //   return new Promise((resolve, rejected) => {
  //     this.dataService.getListData(this.apiRoutePrefixColumn + '/' + 'sequence_list_column' + '/preferences', '')
  //       .subscribe((success) => {
  //         if (success.data != false) {
  //           this.datatableService.setParam(success.data, 'columns');
  //           resolve(success);
  //         } else {
  //           resolve(success);
  //         }
  //       }, (rjt) => {
  //         rejected(rjt);
  //       });
  //   });
  // }

  /** Delete All sequences */
  deleteAllSequences() {
    let that = this;
    swal.fire({
      title: 'Delete all ' + 'Sequences',
      html: "Are you sure you want to delete this " + "Sequences (s)? <br> <span style='color: #f8bb86;'>Note: All data associated with sequence(s) will be deleted, this action can not be reverted.</span>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        that.dataService.deleteData('destroyAllSequences', that.sequenceDatatableObjects.projectId)
          .subscribe((success) => {
            // that.onChangeTable(that.config);
            this.getSequenceList();
          }, (rejected) => {
          });
      } else {
      }
    });
  }

  getPhases() {
    let query = { query: '' };
    this.dataService.getListData('realtimegetProjectPhases', { 'project_id': this.projectId, 'query': query['query'] })
      .subscribe((success) => {
        let phaseTempArray = success.data;
        this.phaseList = [{ name: 'All', id: 0 }].concat(phaseTempArray);
      }, (rejected) => {

      });
  }

  // For phase filter dropdown
  onPhasesClicked(event) {
    this.phaseList = [...this.phaseList];
  }

  /** On Select Option */
  onselectOption(event: any, type: string) {
    this.config.where[type] = [event];
    setTimeout(() => {
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.config.start = 0;
      // this.onChangeTable(this.config);
      this.getSequenceList();
    }, 200);
  }

  /** On clear selection */
  onselectionClear(event: any, type: string) {
    this.config.where[type] = event.isTrusted == true ? [] : event;
    setTimeout(() => {
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      // this.onChangeTable(this.config);
      this.getSequenceList();
    }, 200);
  }


  // When user click somewhere inside datatable
  onUserEvent(event) {
    if (event.type == "click" && event.column.name == "Sequence") {
      // console.log(event);
      const row = event.row;
      const column = event.column;
      this.router.navigate([this.commonJs.getClientCodePrefix() + '/projects/shots/' + this.projectId + '/' + row.id + '/' + this.parentId + '/' + this.sequenceDatatableObjects.seasonsId]);

      let sequenceData = {
        'type': 'sequence',
        'task_dept_column': 'department_id',
        'task_dept_id': column.task_dept_id,
        'department': column.name,
        'data': row,
        'status': row.sequenceStatus,
        'actualStatus': row.sequenceStatus,
      }
      // if (column.dynamicColumn && row[column.prop] != "") {
      //   if (this.currentRole != 'client') {
      //     this.openTaskAssignAndCommentsSideWindow(sequenceData);
      //   }
      // }
    }
  }

  //Tasks Assignment and  Comments
  // openTaskAssignAndCommentsSideWindow(shotData) {
  //   this._ngTasksAssignCommentsData['type'] = shotData.type;
  //   this._ngTasksAssignCommentsData['department'] = shotData.department;
  //   this._ngTasksAssignCommentsData['data'] = shotData.data;
  //   this._ngTasksAssignCommentsData['task_dept_column'] = shotData.task_dept_column;
  //   this._ngTasksAssignCommentsData['task_dept_id'] = shotData.task_dept_id;
  //   this._ngTasksAssignCommentsData['status'] = shotData.status;
  //   this._ngTasksAssignCommentsData['actualStatus'] = shotData.actualStatus;
  //   this._ngTasksAssignCommentsData['allow_status'] = true;
  //   this._ngTasksAssignCommentsData['department_name'] = shotData.department;
  //   this._ngTasksAssignCommentsData['info'] = shotData.data.seasonEpisode + '-' + shotData.data.sequence_number + '-' + shotData.data.shot_number;
  //   this._tasksAssignAndCommentsOpened = true;
  // }

  /** On select the row */
  onSelect(row) {
    this.deleteAllId = row.selected.map(obj => obj.id);
  }

  closeCreateShot() {
    this.closeCreateSequence();
  }

  closeCreateSequence() {

    this.rows = [];
    // this.onChangeTable(this.config);
    this.getSequenceList();
  }

  /** Delete Sequences */
  deleteSequences() {
    const config = {
      initialState: {
        title: 'Delete ' + this.deleteAllId.length + '  Sequences(s)',
        message: "Are you sure you want to delete this  Sequence(s)? Note: All data associated with sequence(s) will be deleted, this action can not be reverted",
      },
      class: 'modal-md custom-modal-css',
      centered: true,
      animated: false
    };
    this.bsModalRef = this.modalService.show(ConfirmationPopupComponent, config);
    // Subscribe to the onConfirmAction event
    this.bsModalRef.content.onConfirmAction.subscribe(() => {
      // Handle the confirmation logic here
      this.dataService.deleteData(this.apiRoutePrefix + 'Destroy', this.deleteAllId)
        .subscribe((success) => {
          if (success.status == 'error') {
            this.toastrService.notify('error', '', success.message);
          } else {
            this.toastrService.notify('success', '', success.message);
            this.isLoading = true;
            this.getSequenceList();
          }
        }, (rejected) => {

        });
    });

    // let that = this;
    // swal.fire({
    //   title: 'Delete ' + this.deleteAllId.length + '  Sequences(s)',
    //   html: "Are you sure you want to delete this  Sequence(s)? <br> <span style='color: #f8bb86;'>Note: All data associated with sequence(s) will be deleted, this action can not be reverted.</span>",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#DD6B55",
    //   confirmButtonText: "Delete",
    //   cancelButtonText: "Cancel",
    // }).then((result) => {
    //   if (result.value) {
    //     that.dataService.deleteData(that.apiRoutePrefix + 'Destroy', that.deleteAllId)
    //       .subscribe((success) => {
    //         that.toastrService.notify(success.status, '', success.message);
    //         that.deleteAllId = [];
    //         this.pagePayload.page = 0;
    //         this.table.offset = 0;
    //         // this.onChangeTable(this.config);
    //         this.getSequenceList();
    //       }, (rejected) => {
    //       });
    //   } else {
    //   }
    // })
  }

  // To change display view
  changeView(view) {
    if (view == 'list') {
      this.isListView = true;
      this.isThumbnailView = false;
      this.showDeptWiseSummary = false;
    } else if (view == 'thumbnail') {
      this.isThumbnailView = true;
      this.isListView = false;
      this.showDeptWiseSummary = false;
    } else {
      this.showDeptWiseSummary = true;
      this.isThumbnailView = false;
      this.isListView = false;
      this.projectDepartmentList();
    }
  }

  closeImport(event) {
    this._import_opened = false;
    this._loadImportSideWindow = false;
    if (this.fromNotify != 'modalImport') {
      // this.onChangeTable(this.config);
      this.getSequenceList();
    }
  }

  onPhaseSelect(phase) {
    this.currentPhase = { name: 'All' };
    this.phaseId = 0;
    if (phase.name != 'All') {
      this.currentPhase = phase;
      this.phaseId = phase.id;
    }
    this.rows = [];
    this.dataTableColumns.splice(8, (Object.keys(this.dataTableColumns).length - 1));
    this.projectDepartmentList().then((res => {
      // this.onChangeTable(this.config);
      this.getSequenceList();
    }));
  }

  selectedOptionChange(column, selectedObj, isMain, isTypeAhead) {
    let onlyIds;
    if (isTypeAhead) {
      if (column == 'complexity') {
        this.config = {
          "start": 1,
          "itemsPerPage": 100,
          "sort": {
            "title": "#ID",
            "name": "id",
            "sort": "desc",
            "className": "hide-this"
          },
          "sortNew": {
            "id": "desc"
          },
          "FilterColumns": [
            "name"
          ],
          "filtering": {
            "filterString": ""
          },
          "FilterByColumns": {
            "episode_id": "",
            "number": "",
            "name": "",
            "lead": "",
            "producer": "",
            "complexity": "",
            "projected_shots": "",
            "projected_seconds": "",
            "status": ""
          },
          "where": {
            "project_id": "146",
            "phaseId": 0,
            "complexity": [
              {
                "id": selectedObj.id,
                "name": selectedObj.name
              }
            ]
          },
          "export": false,
          "isLoadingFirstTime": false,
          "isClearFilter": false
        };
        this.config.start = 0;
        this.getSequenceList();
      }
      else if (column == 'status') {
        onlyIds = (selectedObj != '' && selectedObj != 'All') ? [selectedObj.text] : [];
        this.config.where['status'] = [{ id: selectedObj.id, name: selectedObj.name }];
        this.config.start = 0;
        this.getSequenceList();
      }
    } else {
      this.checkFilters[column] = (selectedObj != '' && selectedObj != 'All') ? [selectedObj] : [];
      onlyIds = (selectedObj != '' && selectedObj != 'All') ? [selectedObj] : [];
    }
    if (!isMain) {
      setTimeout(() => {
        //this.columns.push({title: 'new', name: 'name', width: '10%' });
        this.config.where[column] = onlyIds;
        this.config.start = 0;
        this.getSequenceList();
      }, 2);
    }

  }


  seqDeptList: any = [];
  // To get sequences
  getSequenceList() {
    this.spinner.hide();
    this.gridService.postData('sequencesList', this.config).subscribe((res: any) => {
      if (!this.isLoading) {
        this.isLoading = true;
      }

      this.statusArr = res.customStatusArr;
      this.totalItems = res.totalElements;
      this.totalCount = res.totalElements;
      // this.temp = [...res.data];
      this.rows = [...res.data];
      this.config.export = false;
      this.isLoading = false;
      this.isFilterApplied = res.isFilterApplied;
      if (Array.isArray(res.filterArr) && res.filterArr.length > 0) {
        this.config.FilterByColumns.end_date = res.filterArr.FilterByColumns.end_date;
        this.config.FilterByColumns.name = res.filterArr.FilterByColumns.name;
        this.config.FilterByColumns.number = res.filterArr.FilterByColumns.number;
        this.config.FilterByColumns.projected_seconds = res.filterArr.FilterByColumns.projected_seconds;
        this.config.FilterByColumns.projected_shots = res.filterArr.FilterByColumns.projected_shots;
        this.config.FilterByColumns.season_id = res.filterArr.FilterByColumns.season_id;
        this.config.FilterByColumns.start_date = res.filterArr.FilterByColumns.start_date;
        this.config.FilterByColumns.status = res.filterArr.FilterByColumns.status;
        this.headerDropdowns.status = res.where.status
      }
    });
  }

  refreshFilter() {
    this.getSequenceList();
  }

  clearFilter() {
    this.isLoading = true;
    this.config.where = {};
    this.config.where.project_id = this.sequenceDatatableObjects.projectId;
    this.config.where.phaseId = this.phaseId;
    this.config.FilterByColumns = {};
    this.config.isLoadingFirstTime = false;
    this.config.isClearFilter = true;
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.getSequenceList();
  }

  /** Close modal */
  closeModal() {
    this.bsModalRef.hide();
  }

   // On datatable body scrolled
   onScrolled(event){

    // console.log(event);
    if(event['offsetY'] && (event['offsetY'] != this.previousOffsetY)){
      this.isFullScreen = event['offsetY']>this.previousOffsetY ? true : false;
      this.dataService.fullScreenView.next(this.isFullScreen);

      this.previousOffsetY = event['offsetY'];
    }
  }

}
