import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatatableComponent, SelectionType, TableColumn } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppConfigs } from 'src/app/common/app-configs';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';
import { DatatableService } from 'src/app/services/datatable/datatable.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { columns } from '../utility/column-generator';
import { GridService } from 'src/app/services/grid.service';
import swal from 'sweetalert2';
import { ExcelmapimportComponent } from 'src/app/common/excelmapimport/excelmapimport.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThumbnailModalServiceService } from 'src/app/services/thumbnail-modal-service.service';
import { AddMissionTaskModalComponent } from '../add-mission-task-modal/add-mission-task-modal.component';
import { ConfirmationPopupComponent } from 'src/app/common/confirmation-popup/confirmation-popup.component';
import { environment } from 'src/environments/environment';
import { PusherService } from 'src/app/services/pusher.service';

@Component({
  selector: 'app-assets-list',
  templateUrl: './assets-list.component.html',
  styleUrls: ['./assets-list.component.css']
})
export class AssetsListComponent implements OnInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
  @ViewChild('content', { static: false }) content!: ElementRef;

  assetsColumnData = {};
  assetDepartmentColumnData = [];

  isMangeColumnsOpened: boolean = false;
  _loadManageColumnsSideWindow: boolean = false;
  apiRoutePrefixColumn = 'task';

  bsModalRef: BsModalRef;
  //Assets var
  assetsObj: any = {};
  // Datatable var

  rows: any = [];
  replicateRows: any = [];
  temp = [];
  currentPage: number = 0;
  pageSize: number = 100;
  totalItems: number = 0;
  isLoading: boolean = true;
  pagePayload: any = {
    page: 0,
    itemsPerPage: 100,
  }
  public readonly pageLimitOptions = [
    { value: 50 },
    { value: 100 },
    { value: 200 },
    { value: 300 },
  ];
  public dataTableColumns: TableColumn[] = [];
  public copyDataTableColumns: TableColumn[] = [];

  selected = [];
  SelectionType: SelectionType[] = [];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('image', { static: false }) public image: TemplateRef<any>;
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;
  @ViewChild('cameras', { static: false }) public cameras: TemplateRef<any>;
  @ViewChild('assetDetails', { static: false }) public assetDetails: TemplateRef<any>;
  @ViewChild('duration', { static: false }) public duration: TemplateRef<any>;
  @ViewChild('departmentStatus', { static: false }) public departmentStatus: TemplateRef<any>;
  @ViewChild('departmentStatusHeader', { static: false }) public departmentStatusHeader: TemplateRef<any>;
  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;
  @ViewChild('container', { static: true }) containerRef: ElementRef;
  @ViewChild('taskInfoHeader', { static: false }) public taskInfoHeader: TemplateRef<any>;

  deleteAllId = [];
  checkboxColum = {
    prop: 'selected',
    name: '',
    sortable: false,
    frozenLeft: true,
    canAutoResize: false,
    draggable: true,
    resizable: false,
    headerCheckboxable: true,
    checkboxable: true,
    maxWidth: 45,
    width: 45,
    minWidth: 45,
  }

  //Config var
  public FilterColumns: Array<any> = ['name', 'code'];
  public config: any = {
    paging: true,
    FilterByColumns: {
      "sub_type": "",
      "name": "",
      "code": "",
      "episodeSequence": "",
      "complexity": ""
    },
    sortNew: {},
    itemsPerPage: this.pagePayload.itemsPerPage,
    start: this.pagePayload.page,
    sorting: { columns: this.dataTableColumns },
    filtering: { filterString: '' },
    where: { 'project_id': this.assetsObj.projectId, 'isEpisodicSeries': false },
    export: false,
    isLoadingFirstTime: true,
    isClearFilter: false,
  };

  isEpisodicSeries: boolean = false;
  _opened: boolean = false;
  _loadSideWindow: boolean = false;
  _ng_sidebar_data = {};
  _loadImportSideWindow: boolean = false;
  assetType: Array<any> = [];
  assetSubType: string = 'All';
  settings: any = JSON.parse(localStorage.getItem('settings'));
  projectRoles = JSON.parse(localStorage.getItem('projectRoles'));
  currentRole = '';
  clientCodePrefix = this.commonJs.getClientCodePrefix();
  checkFilters = { 'type': [], 'status': [], 'shots': [] };
  selectedValue = { 'type': [], 'status': [], 'shots': [] };
  _ng_sidebar_detail_data = {};
  _detail_opened: boolean = false;
  _loadDetailSideWindow: boolean = false;
  _tasksAssignAndCommentsOpened: boolean = false;
  _ngTasksAssignCommentsData = {};
  _loadTaskAssignAndComments: boolean = false;
  statusArrayFromDepartmentList: any = [];
  isFilterApplied: boolean = false;
  _import_opened: boolean = false;

  openedPage: number = 0;
  globalSelect: Array<any> = [];
  _ng_import_type: string = '';
  fromNotify: string = '';

  visiblePhases: any[] = [];
  phasesExist = {};
  // isUserView: boolean = false;
  display: boolean = false;
  userViewData: any = {};
  userViewList: any = [];
  isLoadingArtistTaskListTable: boolean = true;

  isFullScreen:boolean = false;
  previousOffsetY:number = 0;

  assetStates: any = [];
  assetDataViewTab = { id:0, title:'statusView', name:'Status View' };
  assetDataViewTabSelected = { id:0, title:'statusView', name:'Status View' };
  viewList:any[] = [ { id:0, title:'statusView', name:'Status View' }, { id:1, title:'userView', name:'User View' } ];

  toggleDependencies:boolean = false;
  selectedDependencyFilter = '';

  deptStatusList = [];
  _dependencyOpened: boolean = false;
  _loadDependencySideWindow: boolean = false;
  dependencyDetails = [];
  openDependencyDetails = false;
  assetColDeptFilters = [];
  deptColumnHeaderFilters = {};

  constructor(
    private dataService: DataService,
    private gridService: GridService,
    private datatableService: DatatableService,
    private router: Router,
    public commonJs: CommonJs,
    public appConfigs: AppConfigs,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private titleService: Title,
    private modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private cd: ChangeDetectorRef,
    private thumbnailModalService: ThumbnailModalServiceService,
    private elementRef: ElementRef,
    private pusherService: PusherService
  ) {
    this.assetsObj.isAssetPermissions = {};
    this.assetsObj.subType = [];
    this.assetsObj.subTypeSelected = [];
    this.assetsObj.episodeSequence = [];
    this.assetsObj.modifiedArray = [];
    this.assetsObj.complexity = this.appConfigs.sht_ast_complexity;
    this.datatableService.setParam(this.dataTableColumns, 'columns');
    this.datatableService.setParam(this.config, 'config');
    this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
    this.datatableService.setParam(this.assetsObj.apiRoutePrefix + 'List', 'serverUrl');
  }

  ngOnInit() {
    this.titleService.setTitle(this.settings.html_prefix + ' Assets');

    if (localStorage.getItem('phasesExist')) {
      this.phasesExist = JSON.parse(localStorage.getItem('phasesExist'));
      // console.log(this.phasesExist);
    } else if (this.commonJs.phasesExist) {
      this.phasesExist = this.commonJs.phasesExist;
    } else {
      this.phasesExist = { pre: 1, character: 1, set: 1, prop: 1, shot: 1, post: 1 };
    }

    this.assetsObj.isAssetPermissions['assets_add'] = this.commonJs.checkAccess('assets', 'add', { 'baiPass': ['admin'] }, this.assetsObj.projectId);
    this.assetsObj.isAssetPermissions['assets_edit'] = this.commonJs.checkAccess('assets', 'edit', { 'baiPass': ['admin'] }, this.assetsObj.projectId);
    this.assetsObj.isAssetPermissions['assets_view'] = this.commonJs.checkAccess('assets', 'view', { 'baiPass': ['admin'] }, this.assetsObj.projectId);
    this.assetsObj.isAssetPermissions['assets_delete'] = this.commonJs.checkAccess('assets', 'delete', { 'baiPass': ['admin'] }, this.assetsObj.projectId);
    this.activatedRoute.params.subscribe((params: Params) => {
      this.assetsObj.projectId = params['id'];
      this.config.where.project_id = this.assetsObj.projectId;

      if (params['type'] != undefined) {
        const type = params['type'];
        const typeValueMap = {
          'Character': 3,
          'Set': 4,
          'Prop': 5
        }
        this.getShotsStates(typeValueMap[type]);
        this.dataTableColumns = [];
        this.assetSubType = params['type'];
        this.checkFilters.type = [params['type']];
        this.selectedValue.type = [params['type']];
        this.assetType = this.config.where['type'] = [params['type']];
        if (this.assetSubType != undefined && this.assetSubType != '') {
          this.getProjectsById(this.assetsObj.projectId).then((res) => {
            this.config.where['episodeSequence'] = [];
            this.projectDepartmentList().then((res => {
            }));
          }, (error) => {
          });
        }
      } else {
        this.currentRole = this.projectRoles['project_' + this.assetsObj.projectId];
        this.getProjectsById(this.assetsObj.projectId).then((res) => {
          this.createColumns([]);
        });
      }
    });

    this.dataService.notifyObservable$.subscribe(res => {
      if (res.refresh) {
        this._import_opened = true;
        this._ng_import_type = 'asset';
        this.fromNotify = 'modalImport';
      }
    });

    /** Pusher js live update data without reload page */
    if (environment.production) {
      const channel = this.pusherService.subscribeToChannel(`assets`);
      // const channel = pusher.subscribe(`${'assets.'}${this.assetsObj.projectId}`);
      channel.bind(`assets`, (data) => {
        if(data.projectId == this.assetsObj.projectId){
          this.isLoading = true;
          this.getAssetList();
        }
      });
    }
  }

  ngOnDestroy() {
    this.pusherService.unsubscribeFromChannel(`assets`);
  }

  /** Get Shot Stats */
  getShotsStates(type) {
    this.dataService.getListData('dashboardgetProjectPhaseDepts', { 'project_id': this.assetsObj.projectId, 'phase_id': type, 'tab': 'assets', 'filteredData': { 'seasonEpisode':this.assetsObj.seasonEpisode ,'sequence_number': this.assetsObj.sequence_number } })
      .subscribe((success) => {
        // console.log(success)
        this.assetStates = success.data.approvedDepts;
      }, (rejected) => {

      });
  }

  // open glbal image thumbnail viewer
  openImageModal(imageUrl: string): void {
    this.thumbnailModalService.openThumbnailModal(imageUrl);
  }

  // Manage col sidebar open
  openManageColWindow() {
    let assetDeptCols: any = this.assetsObj.modifiedArray;
    assetDeptCols = assetDeptCols.map((ele) => {
      let tempObj = {
        name: ele.name,
        prop: ele.prop,
        draggable: true,
        idDepartment: true,
        // canAutoResize : false,
        // cellClass: "",
        // cellTemplate: "",
        // headerTemplate: "",
        // searchPlaceholder: "",
        // sortable: true
      }
      return tempObj
    });
    // this.assetsColumnData['column'] = columns('assets').concat(assetDeptCols); // To concat Asset columns & Asset-Department columns
    this.assetsColumnData['column'] = [...columns('assets'), ...assetDeptCols]; // To concat Asset columns & Asset-Department columns
    this.assetsColumnData['key'] = this.assetSubType;
    this.assetsColumnData['projectId'] = this.assetsObj.projectId;
    this.isMangeColumnsOpened = true;
  }

  /**close modeal event */
  closeManageCol(value) {
    if (value.reload) {
      this.columnSet().then((response: any) => {
        if (response.status == 'success') {
          this.createColumns(this.assetsObj.modifiedArray);
          this.assetDepartmentColumnData = this.assetsObj.modifiedArray;
        }
      }, (err: any) => {
        console.warn("Error while fetching column set")
      });
    }
  }

  /** Get column preferance  */
  columnSet() {
    return new Promise((resolve, rejected) => {
      this.dataService.getListData(this.apiRoutePrefixColumn + '/' + this.assetSubType + '/preferences', { projectId: this.assetsObj.projectId })
        .subscribe((success) => {
          if (success.data != false) {
            this.datatableService.setParam(success.data, 'columns');
            resolve(success);
          } else {
            resolve(success);
          }
        }, (rjt) => {
          rejected(rjt);
        });
    });
  }

  /** Create Column */
  private createColumns(modifiedArray): void {
    let cellTemplate: any = {};
    let tempColumn: any;
    setTimeout(() => {
      cellTemplate = {
        ids: this.idsT,
        image: this.image,
        status: this.status,
        hdrTpl: this.hdrTpl,
        duration: this.duration,
        assetDetails: this.assetDetails
      };
      this.dataTableColumns = [];

      this.assetDepartmentColumnData = modifiedArray;
      // this.dataTableColumns = columns('assets', cellTemplate, modifiedArray);
      tempColumn = columns('assets', cellTemplate, modifiedArray);
      tempColumn.unshift(this.checkboxColum);
      if (this.isEpisodicSeries) {
        tempColumn = tempColumn.filter(obj => obj.name !== 'Sequence');
      }
      if (!this.isEpisodicSeries) {
        tempColumn = tempColumn.filter(obj => obj.name !== 'Episode');
      }
      // this.getAssetList();
    });

    this.columnSet().then((response: any) => {
      if (response.status == 'success' && response.data.length != 0) {

        // console.log(response.data.map((ele)=>{if(ele.prop=='id' && ele.name=='Actions'){ele.show=true;} return ele;}));

        const rearrangedColumns = [];
        for (const item of response.data) {
          const matchingColumn = tempColumn.find(obj => obj.prop === item.prop && item.show);
          if (matchingColumn) {
            rearrangedColumns.push(matchingColumn);
          }
        }
        tempColumn = rearrangedColumns;
        this.dataTableColumns = tempColumn.filter((obj: any) => {
          if (!obj.dynamicColumn) {
            return response.data.some(item => item.prop === obj.prop && item.show);
          } else {
            return obj
          }
        });

        // To hide info of all the opened columns
        this.dataTableColumns.forEach((ele:any,index)=>{
          if(ele.prop.includes('_task_status') || ele.prop.includes('t_task_status')){
            ele.isExpanded = false;
            ele.maxWidth = 190;
            ele.minWidth = 190;
            ele.headerClass = 'deptsHeaderCell';
          }
        });

      } else {
        let columnsToShow = [];
        if (this.assetSubType == 'All') {
          columnsToShow = ['image', 'code', 'name', 'duration_days', 'episodeSequence', 'assetStatus', 'id'];
        } else {
          columnsToShow = ['image', 'code', 'name', 'duration_days', 'id'];
        }
        this.dataTableColumns = tempColumn.map((column: any) => {
          if (columnsToShow.includes(column.prop) || column.idDepartment) {
            return column;
          } else {
            return null;
          }
        }).filter(column => column !== null);
      }
      this.dataTableColumns.unshift(this.checkboxColum);
      this.dataTableColumns = this.removeDuplicates(this.dataTableColumns);
      this.dataTableColumns.forEach((ele:any,index)=>{
        if(ele.prop.includes('_task_status') || ele.prop.includes('t_task_status')){
          ele.isExpanded = false;
        }
      });
      this.copyDataTableColumns = this.dataTableColumns;
    }, (err: any) => {
      this.copyDataTableColumns = this.dataTableColumns;
      console.warn("Error while fetching column set")
    });
  }

  // Function to remove duplicates based on 'prop' and 'name' keys
  removeDuplicates(arr: any): any {
    const uniqueColumns: any = [];
    arr.forEach(column => {
      const index = uniqueColumns.findIndex(item => item.prop === column.prop && item.name === column.name);
      if (index === -1) {
        uniqueColumns.push(column);
      }
    });
    return uniqueColumns;
  }


  projectDepartmentList() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getListData('projectDepartmentList', { 'project_id': this.assetsObj.projectId, 'type': this.assetSubType, 'fromAssetShot': true })
        .subscribe((success) => {
          this.isLoading = true;
          this.statusArrayFromDepartmentList = success.customStatusArr;
          const modifiedArray = success.data.map((obj: any) => ({
            prop: obj.task_status_id + "_task_status",
            task_dept_id: obj.task_status_id,
            name: obj.department_name,
            sortable: true,
            draggable: false,
            // width: 120,
            width: 166,
            minWidth: 166,
            canAutoResize: true,
            dynamicColumn: true,
            show: true,
            cellTemplate: this.departmentStatus,
            headerTemplate: this.departmentStatusHeader,
            headerClass:'deptsHeaderCell',
            idDepartment: true
            // Spread the properties from the original object
          }));

          modifiedArray.forEach((ele) => {
            this.deptColumnHeaderFilters[ele.prop+'_filter'] = '';
            return ele;
          });

          this.assetsObj.modifiedArray = modifiedArray;
          this.createColumns(modifiedArray);
          this.getAssetList();
          resolve();
        }, (rejected) => {

        });
    });
  }

  // on Open Dependency Details
  openDependencyDetailsDrawer(eventRow, eventColumn, type){
    this.openDependencyDetails = type;
    if(type){
            if(eventRow[eventColumn.prop]['parentApprovalState']!='no-parent'){
              this._dependencyOpened = true;
              // console.log(event);
              this.getTaskDependencyDetails(eventRow, eventRow[eventColumn.prop]['task_id']);
            }
    }
  }

  // on comment/assign drawer opened
  onStatusChipClicked(eventRow, eventColumn){
    const row = eventRow;
    const column = eventColumn;

    if (column.task_dept_id) {
      let check = column.task_dept_id.indexOf("t") !== -1;

      let task_dept_column = check ? 'department_tasktype_id' : 'department_id';
      let shotData = {
        'type': 'asset',
        'task_dept_column': task_dept_column,
        'task_dept_id': column.task_dept_id,
        'department': column.name,
        'data': row,
        'status': row.shotStatus,
        'actualStatus': row.shotStatus,
      }

      // console.log(this.openDependencyDetails);
      if (column.dynamicColumn && row[column.prop] != "" && row[column.prop] != "NA" && !this.openDependencyDetails) {
        // console.log(event);

              if (row[column.prop].users.length > 0 && this.assetDataViewTab['title']=='userView') {
                this.userViewData = this.userClickFromTooltipData ? this.userClickFromTooltipData : row[column.prop];
                console.log(this.userViewData)
                this.isLoadingArtistTaskListTable = true;
                this.userViewList = [];
                this.userTaskListProject(shotData, row.id, this.userViewData.id ? this.userViewData.id : this.userViewData.artist_id);
                this.display = true;
              } else {
                if (this.currentRole != 'client') {
                  this.openTaskAssignAndCommentsSideWindow(shotData);
                }
              }


      }
    }
  }

  // On user clicks row cell
  onUserEvent(event) {
    if (event.type == "click") {
      // const row = event.row;
      // const column = event.column;

      // if (column.task_dept_id) {
      //   let check = column.task_dept_id.indexOf("t") !== -1;

      //   let task_dept_column = check ? 'department_tasktype_id' : 'department_id';
      //   let shotData = {
      //     'type': 'asset',
      //     'task_dept_column': task_dept_column,
      //     'task_dept_id': column.task_dept_id,
      //     'department': column.name,
      //     'data': row,
      //     'status': row.shotStatus,
      //     'actualStatus': row.shotStatus,
      //   }

      //   console.log(this.openDependencyDetails);
      //   if (column.dynamicColumn && row[column.prop] != "" && row[column.prop] != "NA" && !this.openDependencyDetails) {

      //           if (row[column.prop].users.length > 0 && this.assetDataViewTab['title']=='userView') {
      //             this.userViewData = this.userClickFromTooltipData ? this.userClickFromTooltipData : row[column.prop];
      //             console.log(this.userViewData)
      //             this.isLoadingArtistTaskListTable = true;
      //             this.userViewList = [];
      //             this.userTaskListProject(shotData, event.row.id, this.userViewData.id ? this.userViewData.id : this.userViewData.artist_id);
      //             this.display = true;
      //           } else {
      //             if (this.currentRole != 'client') {
      //               this.openTaskAssignAndCommentsSideWindow(shotData);
      //             }
      //           }


      //   }
      // }
    }
  }

  /** User View Data */
  userTaskListProject(shotData, shotId, id) {
    const payload = {
      type: 'asset',
      project_id: this.assetsObj.projectId,
      task_dept_column: shotData.task_dept_column,
      task_dept_id: shotData.task_dept_id,
      asset_id: shotId,
      artist_id: id
    }
    this.dataService.getListData('getAllTaskByArtist', payload).subscribe((success) => {
      if (success) {
        if (success.data.message == 'success') {
          this.userViewList = success.data.task;
        }
      }
      this.isLoadingArtistTaskListTable = false;
      this.userClickFromTooltipData = null;
    }, (rejected) => {
      this.isLoadingArtistTaskListTable = false;
      this.userClickFromTooltipData = null;
    });
  }

  /** Get User task lists */
  userClickFromTooltipData: any;
  getUsetTaskLists(user) {
    this.userClickFromTooltipData = user;
  }

  //Tasks Assignment and  Comments
  openTaskAssignAndCommentsSideWindow(assetData) {
    this._ngTasksAssignCommentsData['type'] = assetData.type;
    this._ngTasksAssignCommentsData['department'] = assetData.department;
    this._ngTasksAssignCommentsData['data'] = assetData.data;
    this._ngTasksAssignCommentsData['task_dept_column'] = assetData.task_dept_column;
    this._ngTasksAssignCommentsData['task_dept_id'] = assetData.task_dept_id;
    this._ngTasksAssignCommentsData['status'] = assetData.status;
    this._ngTasksAssignCommentsData['actualStatus'] = assetData.actualStatus;
    this._tasksAssignAndCommentsOpened = true;
  }


  // TODO[Dmitry Teplov] wrap dynamic limit in a separate component.
  public onLimitChange(limit: any): void {
    this.changePageLimit(limit);
    this.table.limit = this.pageSize;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor((this.table.rowCount - 1) / this.table.limit);
      }
    });
  }

  private changePageLimit(limit: any): void {
    this.isLoading = true;
    this.pageSize = parseInt(limit, 10);
    this.dataService.pagePreferancePost({
      pageSize: this.pageSize,
      currentPage: this.config.start,
      pageIndex: this.table.offset,
      user_id: JSON.parse(localStorage.getItem('user')).id,
      project_id: this.assetsObj.projectId
    }, 'asset_list_page');
    this.config.itemsPerPage = this.pageSize;
    this.getAssetList();
  }

  /**
  * Description: Page change event
  * @param e pager object
  */
  firstTimeLoad: boolean = false;
  onPageChange(e): void {
    // this.currentPage = e.offset;
    this.openedPage = e.offset;
    this.selected = this.globalSelect[this.openedPage] != undefined ? this.globalSelect[this.openedPage] : [];

    this.isLoading = true;
    this.pagePayload.page = e.offset * this.pageSize;
    this.config.start = this.pagePayload.page;
    if (this.firstTimeLoad) {
      this.table.offset = this.openedPage;
      this.dataService.pagePreferancePost({
        pageSize: this.pageSize,
        currentPage: this.config.start,
        pageIndex: this.table.offset,
        user_id: JSON.parse(localStorage.getItem('user')).id,
        project_id: this.assetsObj.projectId
      }, 'asset_list_page');
      this.getAssetList();
    } else {
      this.dataService.pagePreferanceGet('asset_list_page').then((res: any) => {
        if (res.data.length == undefined) {
          this.pageSize = res.data.pageSize;
          this.config.itemsPerPage = res.data.pageSize;
          this.config.start = res.data.currentPage;
          this.table.offset = res.data.pageIndex;
          this.getAssetList();
        } else {
          this.getAssetList();
        }
      }, (err: any) => {
        console.warn("Error while fetching page set")
      });
    }
    this.firstTimeLoad = true;
  }

  /** Re order event */
  reordered(event: any) {
  }

  /** Get Asset list */

  getAssetList() {
    // this.rows = this.replicateRows = [];
    this.isLoading = true;
    this.spinner.hide();
    this.config.where['isUserView'] = this.assetDataViewTab['title']=='userView' ? true : false;
    this.gridService.postData('assetsList', this.config).subscribe((res: any) => {
      if (!this.isLoading) {
        this.isLoading = true;
      }
      if (this.config.export || this.config.where.isBulkAssetsUpdate) {
        window.location.href = res.data;
        this.isLoading = false;
        setTimeout(() => {

          if (this.config.export) {
            this.config.export = false;
          }

          if (this.config.where.isBulkAssetsUpdate) {
            // this.toastrService.notify('success', '', 'Data updated successfully');
            this.config.where.isBulkAssetsUpdate = false;
          }
          this.gridService.myEventEmitterForProgressModal.emit(true);
        }, 200);
      } else {
        this.isFilterApplied = res.isFilterApplied;
        this.totalItems = res.totalElements;
        this.temp = [...res.data];

        this.temp.map((ele)=>{
          for (const key in ele) {
            if (ele.hasOwnProperty(key)) {
              if (key.includes('_task_status') && ele[key]) {
                if(ele[key]['status']=='OOP'){
                  ele['assetStatus'] = 'OOP';
                }
              }
            }
          }
          return ele;
        });

        this.rows = this.replicateRows = this.temp.filter((ele) => {

          if (ele.start_date !== null && ele.end_date != null) {
            const date1 = new Date(ele.start_date.split(" ")[0]);
            const date2 = new Date(ele.end_date.split(" ")[0]);

            if (!isNaN(date1.getTime()) && !isNaN(date2.getTime())) {
              // Calculate the time difference in milliseconds
              const timeDifference = date2.getTime() - date1.getTime();

              // Convert milliseconds to days
              ele.duration_days = Math.floor(timeDifference / (1000 * 3600 * 24));
            }
            else {
              ele.duration_days = '-'; // Invalid input, result is null
            }
          }
          else {
            ele.duration_days = '-'; // Invalid input, result is null
          }
          return ele;
        });
        this.config.export = false;
        this.isLoading = false;

        // To show changed number of selected items
        const element = this.elementRef.nativeElement.querySelector('.page-count span');
        if (element) {
          element.innerHTML = 0 + ' selected / ';
        }
        this.selected = [];
        // this.deleteAllId = [];
        if (res.filterArr && res.filterArr.length > 0) {
          this.config.FilterByColumns.code = res.filterArr.FilterByColumns.code;
          this.config.FilterByColumns.complexity = res.filterArr.FilterByColumns.complexity;
          this.config.FilterByColumns.episodeSequence = res.filterArr.FilterByColumns.episodeSequence;
          this.config.FilterByColumns.name = res.filterArr.FilterByColumns.name;
          this.config.FilterByColumns.sub_type = res.filterArr.FilterByColumns.sub_type;
        }
      }
      this.config.export = false;
      this.gridService.progressFunction();
    });

    // To show changed number of selected items
    const element = this.elementRef.nativeElement.querySelector('.page-count span');
    if (element) {
      element.innerHTML = 0 + ' selected / ';
    }
  }

  /** Open asset details */
  openAssetDetails(data) {
    this._ng_sidebar_detail_data['projectId'] = this.assetsObj.projectId;
    this._ng_sidebar_detail_data['type'] = 'asset';
    this._ng_sidebar_detail_data['asset'] = { 'id': data.id };
    this._ng_sidebar_detail_data['isEpisodicSeries'] = this.isEpisodicSeries;
    this._detail_opened = true;
  }

  onDetailOpenStart() { }

  onDetailOpened() {
    this._loadDetailSideWindow = true;
  }

  onDetailClosed() {
    this._loadDetailSideWindow = false;
  }

  onTaskAssignAndCommentsOpenStart() { }

  onTaskAssignAndCommentsOpened() {
    this._loadTaskAssignAndComments = true;
  }

  onTaskAssignAndCommentsClosed() {
    this._loadTaskAssignAndComments = false;
  }


  /** Action Button */
  actionClick(type: string, row: any) {
    let id: any = row.id
    if (type == 'delete') {

      const config = {
        initialState: {
          title: 'Delete asset',
          message: "Are you sure you want to delete this asset(s)?",
        },
        class: 'modal-md custom-modal-css',
        centered: true,
        animated: false
      };
      this.bsModalRef = this.modalService.show(ConfirmationPopupComponent, config);
      // Subscribe to the onConfirmAction event
      this.bsModalRef.content.onConfirmAction.subscribe(() => {
        // Handle the confirmation logic here
        this.dataService.deleteData('assets' + 'Destroy', { id })
          .subscribe((success) => {
            if (success.status == 'error') {
              this.toastrService.notify('error', '', success.message);
            } else {
              this.toastrService.notify('success', '', success.message);
              this.isLoading = true;
              this.getAssetList();
            }
          }, (rejected) => {

          });
      });

      // let that = this;
      // swal.fire({
      //   title: 'Delete  shot(s)',
      //   text: "Are you sure you want to delete this shot(s)?",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#DD6B55",
      //   confirmButtonText: "Delete",
      //   cancelButtonText: "Cancel",
      // }).then((result) => {
      //   if (result.value) {
      //     that.dataService.deleteData('assets' + 'Destroy', { id })
      //       .subscribe((success) => {
      //         if (success.status == 'error') {
      //           //that.toastrService.notify('error','', success.message);
      //         } else {
      //           this.selected = [];
      //           this.isLoading = true;
      //           this.getAssetList();
      //         }
      //         that.toastrService.notify(success.status, '', success.message);

      //       }, (rejected) => {

      //       });
      //   } else {
      //     //console.log('cancelled');
      //   }
      // });
    } else {
      if (id != undefined && id != '') {
        this._ng_sidebar_data['assetid'] = id;
      }
      this._ng_sidebar_data['projectId'] = this.assetsObj.projectId;
      this._ng_sidebar_data['mode'] = 'edit';
      this._opened = true;
    }

  }

  /** Search Auto complete */
  search(event: any, type: string) {
    if (type == 'complexity') {
      this.assetsObj.complexity = [...this.appConfigs.sht_ast_complexity]
    } else if (type == 'episodeSequence') {
      if (this.isEpisodicSeries) {
        this.dataService.getOtherData('episodesItems', { 'project_id': this.assetsObj.projectId, 'fromCreateTask': true, 'query': event['query'] })
          .subscribe((success) => {
            this.assetsObj.episodeSequence = success.data;
          }, (rejected) => {
          });
      } else {
        this.dataService.getOtherData('sequencesItems', { 'project_id': this.assetsObj.projectId, 'fromCreateTask': true, 'query': event['query'] })
          .subscribe((success) => {
            this.assetsObj.episodeSequence = success.data;
          }, (rejected) => {
          });
      }
    }
    else if (type == 'view') {
      this.viewList = [...this.viewList];
    }
    else if (type == 'sub_type') {
      this.dataService.getOtherData('searchItemList', { list_name: 'asset_subtypes', 'query': event['query'] })
        .subscribe((success) => {
          this.assetsObj.subType = success.data;
        }, (rejected) => {
        });
    }
    else if(type == 'deptStatus'){
      this.deptStatusList = [...this.dataService.allStatusList];
    }
    else {
    }
  }

  // For row identity by Id
  getId(row) {
    return row.id;
  }

  /** On select the row */
  onSelect({ selected }) {
    // this.deleteAllId = row.selected.map(obj => obj.id);

    this.globalSelect[this.openedPage] = selected;
    if (selected && selected.length > 0) {
      this.selected = selected;
    }

    // this.deleteAllId = this.selected.map(obj => obj.id);
    this.deleteAllId = [];
    this.globalSelect.forEach((element) => {
      if(element){
        element.map((obj) => {
          // if(!this.deleteAllId.includes(obj.id)){
          this.deleteAllId.push(obj.id);
          // }
        });
      }
    });
    // console.log(this.globalSelect);
    let totalSelected = 0;
    this.globalSelect.forEach((ele) => {
      if(ele){
        totalSelected += ele.length;
      }
    });

    // To show changed number of selected items
    const element = this.elementRef.nativeElement.querySelector('.page-count span');
    if (element) {
      element.innerHTML = totalSelected + ' selected / ';
      // element.innerHTML = selectedRows.length + ' selected / ' + this.totalCount + ' total';
    }
  }

  /** On Select Option */
  onselectOption(event: any, type: string, deptColumnProp?, dependencyData?) {

    if(type=='deptStatus' || type=='dependencyType'){
      this.selectedDependencyFilter =  (dependencyData == this.selectedDependencyFilter) ? '' :dependencyData;

      // console.log(event, deptColumnProp);

      if(deptColumnProp){
        let deptFilterObj = this.assetColDeptFilters.find(ele=>ele.col==deptColumnProp);
        // console.log(deptFilterObj);

        if(deptFilterObj){

          if(type=='deptStatus'){
              deptFilterObj['statusValue'] = event.name;
          }else if(type=='dependencyType'){
                if(deptFilterObj['dependency']==dependencyData){
                  deptFilterObj['dependency'] = undefined;
                }else{
                  deptFilterObj['dependency'] = dependencyData;
                }
          }

        }else{
          let tempObj = { 'col':deptColumnProp };
          if(type=='deptStatus'){
            tempObj['statusValue'] = event.name;
          }else if(type=='dependencyType'){
            tempObj['dependency'] = dependencyData;
          }
          this.assetColDeptFilters.push(tempObj);
        }
      }

      this.config.where['assetColDeptFilters'] = this.assetColDeptFilters.filter(ele => (ele['dependency'] || ele['statusValue']) );
    }else{
      this.config.where[type] = [event];
    }
    setTimeout(() => {
      this.isLoading = true;
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.config.start = 0;
      this.getAssetList();
    }, 200);
  }

  /** On clear selection */
  onselectionClear(event: any, type: string, deptColumnProp?:any) {
    if(type=='deptStatus'){
      let deptFilterObj = this.assetColDeptFilters.find(ele=>ele.col==deptColumnProp);
      if(deptFilterObj){
        deptFilterObj['statusValue'] = undefined;
      }
      this.config.where['assetColDeptFilters'] = this.assetColDeptFilters.filter(ele => (ele['dependency'] || ele['statusValue']) );
    }else {
      this.config.where[type] = event.isTrusted == true ? [] : event;
    }
    setTimeout(() => {
      this.isLoading = true;
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.getAssetList();
    }, 200);
  }

  // Get project detail
  getProjectsById(id) {
    return new Promise<void>((resolve, reject) => {
      this.assetsObj.projectId = id;
      this.dataService.getDataById('projects/' + id + '/edit')
        .subscribe((success) => {
          this.isEpisodicSeries = success.data.episodic_series;
          this.config.where.isEpisodicSeries = this.isEpisodicSeries;
          resolve();
        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.toastrService.notify('warn', '', err.message);
            this.router.navigate(['/dashboard']);
          }
        });
    });
  }

  // Get column filter
  onColumnFilter(e: any, id: string): void {
    setTimeout(() => {
      this.isLoading = true;
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isClearFilter = false;
      this.config.isLoadingFirstTime = false;
      this.config.start = 0;
      this.getAssetList();
    }, 200);
  }

  // Create Asset Sidebar open
  openCreateAsset() {
    this._ng_sidebar_data['projectId'] = this.assetsObj.projectId;
    this._ng_sidebar_data['mode'] = 'create';
    this._opened = true;
  }


  // Open sidebar function
  onOpened(type) {
    if (type == 'import') {
      this._loadImportSideWindow = true;
    } else if (type == 'manage_col') {
      this._loadManageColumnsSideWindow = true;
     }else if (type == 'dependency') {
      this._loadDependencySideWindow = true;
    } else {
      this._loadSideWindow = true;
    }
  }


  // On close sidebar function
  onClosed(type) {
    if (type == 'import') {
      this._loadImportSideWindow = false;
    }else if (type == 'manage_col') {
      this._loadManageColumnsSideWindow = false;
    }else if (type == 'dependency') {
      this._loadDependencySideWindow = false;
    }else {
      this._loadSideWindow = false;
    }
  }

  closeImport(event) {
    this._import_opened = false;
    this._loadImportSideWindow = false;

    if (this.fromNotify != 'modalImport') {
      this.getAssetList();
    }
  }

  closeCreateTask($event) {
    if ($event.id == 3) {
    } else {
      this.closeSideWindow();
      if ($event.id == 1) {
        this._tasksAssignAndCommentsOpened = false;
        this._detail_opened = false;
      }
    }

  }


  // On close sidebar function
  closeSideWindow() {
    this.isLoading = true;
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.config.isLoadingFirstTime = false;
    this.getAssetList();
  }

  // Export asset list
  exportData() {
    this.config.export = true;
    this.isLoading = true;
    this.gridService.openProgressModal();
    this.getAssetList();
  }

  // Export data for bulk update
  exportBulkUpdateData() {
    this.config.export = false;
    this.config.where.isBulkAssetsUpdate = true;
    this.isLoading = true;
    this.gridService.openProgressModal();
    this.getAssetList();
  }

  public openModalWithComponent() {
    this.dataService.setProjectId(this.assetsObj.projectId);
    let data: any = {
      from: 'AssetComponent',
      header: 'Asset'
    }
    this.dataService.setImportFrom(data);
    this.bsModalRef = this.modalService.show(ExcelmapimportComponent, {
      backdrop: 'static',
      keyboard: false
    });
    this.bsModalRef.content.event.subscribe((res: any) => {
      this.isLoading = true;
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.getAssetList();

    });
  }

  // Import data for bulk update
  importBulkUpdateData() {
    this._import_opened = true;
    this._ng_import_type = 'Bulk Asset Update';
  }

  // Link Department


  linkDepartments() {
    console.log(this.deleteAllId)
    if (this.deleteAllId.length > 0) {
      this.dataService.setProjectId(this.assetsObj.projectId);
      let list: any = [this.assetsObj.projectId, this.deleteAllId, this.assetType[0] ? this.assetType[0] : '', 'linkAssetsDepartments', this.deleteAllId];
      this.dataService.setMissingTaskData(list)
      this.bsModalRef = this.modalService.show(AddMissionTaskModalComponent, {
        backdrop: 'static',
        keyboard: false
      });
      this.bsModalRef.content.event.subscribe((res: any) => {
        this.currentPage = 0;
        this.rows = this.replicateRows = [];
        this.deleteAllId = [];
        this.getAssetList();
      });
    } else {
      this.toastrService.notify('error', '', "Please select an asset");
    }

    // let that = this;
    // if (that.deleteAllId.length > 0) {

    //   swal.fire({
    //     title: 'Link ' + this.deleteAllId.length + ' department tasks to Assets',
    //     text: "Are you sure you want to create tasks for added departments?",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#DD6B55",
    //     confirmButtonText: "Yes",
    //     cancelButtonText: "Cancel",
    //   }).then((result) => {
    //     if (result.value) {
    //       that.dataService.getOtherData('linkAssetsDepartments', { 'projectId': that.assetsObj.projectId, 'ids': that.deleteAllId, 'type': that.assetSubType })
    //         .subscribe((success) => {
    //           that.config.where.project_id = that.assetsObj.projectId;
    //           that.config.FilterByColumns = {};
    //           that.config.where['type'] = that.assetType;
    //           that.config.where.isEpisodicSeries = that.isEpisodicSeries;
    //           that.deleteAllId = [];
    //           if (success.status == 'success') {

    //           }
    //           if (success.status != '') {
    //             that.toastrService.notify(success.status, '', success.message);
    //           }
    //         }, (rejected) => {

    //         });
    //     } else {
    //       //console.log('cancelled');
    //     }
    //   }
    //   )

    // } else {
    //   that.toastrService.notify('warn', '', 'Please select assets');
    // }
  }

  // Delete assets
  deleteAssets() {
    if (this.deleteAllId.length > 0) {
      let that = this;
      swal.fire({
        title: 'Delete ' + this.deleteAllId.length + ' ' + 'Asset(s)',
        html: "Are you sure you want to delete this " + "Asset(s)? <br> <span style='color: #f8bb86;'>Note: All data associated with Asset(s) will be deleted, this action can not be reverted.</span>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          that.dataService.deleteData('assets' + 'Destroy', that.deleteAllId)
            .subscribe((success) => {
              // To show changed number of selected items
              const element = this.elementRef.nativeElement.querySelector('.page-count span');
              if (element) {
                element.innerHTML = 0 + ' selected / ';
              }
              this.selected = [];
              this.toastrService.notify(success.status, '', success.message);
              this.deleteAllId = [];
              this.getAssetList();
              this.cd.markForCheck();
            }, (rejected) => {
              // // To show changed number of selected items
              // const element = this.elementRef.nativeElement.querySelector('.page-count span');
              // if (element) {
              //   element.innerHTML = 0 + ' selected / ';
              // }
              // this.selected = [];
            });
        } else {
          //console.log('cancelled');
        }
      }
      )
    } else {
      swal.fire(
        'Oops...',
        'Please select at least one ' + 'Assets' + '.',
        'error'
      );
    }
  }

  /** Set bg color */
  /** Set bg color */
  setBgColor(data: any) {
    if (data != 'dependency' && data) {
      const statusObject = this.statusArrayFromDepartmentList.find(status => status.name === data);
      if (statusObject) {
        return statusObject.color;
      }
      return "rgb(169, 169, 169)"
    } else if (data == 'dependency' && data) {
      return '#ccb9a1'
    }
  }

  refreshFilter() {
    this.getAssetList();
  }

  clearFilter() {
    this.assetColDeptFilters = [];
    this.router.navigate(["/" + this.clientCodePrefix + "/projects/assets/" + this.assetsObj.projectId]);
    this.assetSubType = 'All';

    this.isLoading = true;
    this.config.FilterByColumns = {
      "sub_type": "",
      "name": "",
      "code": "",
      "episodeSequence": "",
      "complexity": ""
    }
    this.config.sortNew = {};
    this.config.start = 0;
    this.config.sorting.columns = [];
    this.config.filtering.filterString = "";
    this.config.where = { 'project_id': this.assetsObj.projectId, 'isEpisodicSeries': false },
      this.config.isLoadingFirstTime = false;
    this.config.isClearFilter = true;
    this.assetsObj.subTypeSelected = [];
    this.assetsObj.episodeSequence = [];
    this.assetsObj.complexity = [];
    this.config.isAdvanceSearchApplied = false;
    // Any additional reset logic you might have
    this.pagePayload.page = 0;
    this.table.offset = 0;

    Object.keys(this.deptColumnHeaderFilters).forEach(key => {
      this.deptColumnHeaderFilters[key] = '';
    });

    this.getAssetList();
  }

  getRowClass = (row) => {

  }

   // For getting first two letters of name
   getInitials(inputString: string): string {
    const words = inputString.trim().split(' ');
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      const secondInitial = words[1].charAt(0).toUpperCase();
      return `${firstInitial}${secondInitial}`;
    } else if (words.length === 1) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      return `${firstInitial}`;
    } else {
      return '';
    }
  }

  // on sorting a column
  onSort(event) {
    let sortedData = {
      sortingType: event.newValue,
      columnName: event.column.prop
    }
    if(sortedData.columnName.includes('_task_status')){
      this.table.externalSorting =true;

      if(sortedData.sortingType=='asc'){
              this.rows.sort((a, b) => {

                   if(!a[sortedData.columnName] || !b[sortedData.columnName]){
                     return -1;
                   }

                   if ((a[sortedData.columnName]['status']=='Assign'?'Inventory':a[sortedData.columnName]['status']) < (b[sortedData.columnName]['status']=='Assign'?'Inventory':b[sortedData.columnName]['status'])) {
                       return 1;
                   }
                   if ((a[sortedData.columnName]['status']=='Assign'?'Inventory':a[sortedData.columnName]['status']) > (b[sortedData.columnName]['status']=='Assign'?'Inventory':b[sortedData.columnName]['status'])) {
                       return -1;
                   }
                   return 0;
              });

            }else{
              this.rows.sort((a, b) => {

                    if(!a[sortedData.columnName] || !b[sortedData.columnName]){
                      return -1;
                    }

                    if ((a[sortedData.columnName]['status']=='Assign'?'Inventory':a[sortedData.columnName]['status']) > (b[sortedData.columnName]['status']=='Assign'?'Inventory':b[sortedData.columnName]['status'])) {
                        return 1;
                    }
                    if ((a[sortedData.columnName]['status']=='Assign'?'Inventory':a[sortedData.columnName]['status']) < (b[sortedData.columnName]['status']=='Assign'?'Inventory':b[sortedData.columnName]['status'])) {
                        return -1;
                    }
                    return 0;
            });
            }
      this.replicateRows = this.rows;

    }else{
      this.table.externalSorting = false;
    }
  }

  // On datatable body scrolled
  onScrolled(event){

    // console.log(event);
    if(event['offsetY'] && (event['offsetY'] != this.previousOffsetY)){
      this.isFullScreen = event['offsetY']>this.previousOffsetY ? true : false;
      this.dataService.fullScreenView.next(this.isFullScreen);

      this.previousOffsetY = event['offsetY'];
    }
  }

  // To change tabs between asset's views
  changeAssetView(viewName){
    // console.log(this.assetDataViewTab);
    this.assetDataViewTab = viewName;
  }

  // To hide/show tasks date info(start, end, bid)
  toggleDateInfo(event){
    let ele:any = this.copyDataTableColumns.find((item:any) => (item.prop === event.prop));

    if(ele){
      ele.isExpanded = !ele.isExpanded;
        ele.headerClass= ele.isExpanded ? 'deptsHeaderExpandedCell':(this.toggleDependencies? 'deptsHeaderCell deptstWidth-254':'deptsHeaderCell');
        ele.width = ele.isExpanded ? (this.toggleDependencies? 566:502):190
        ele.minWidth = ele.isExpanded ? (this.toggleDependencies? 566:502):190
        ele.maxWidth = ele.isExpanded ? (this.toggleDependencies? 566:502):190
        ele.cellClass = ele.isExpanded? 'user-view expanded-view-container':(this.toggleDependencies?'user-view deptstWidth-254':'user-view');
        ele.resizable = ele.isExpanded? false:true;
        // ele.cellTemplate = this.departmentExpandedStatus;
    }

    this.dataTableColumns = this.copyDataTableColumns.filter((item:any) => { return !item.hasOwnProperty('deptName') || item.show===true; });

    this.cd.detectChanges();
  }

  // To toggle dependency
  toggleDependency(){
    this.toggleDependencies=!this.toggleDependencies;
    this.dataTableColumns = [];
    this.isLoading = true;
    setTimeout(()=>{
      this.dataTableColumns = this.copyDataTableColumns;
      this.dataTableColumns.forEach((ele:any,index)=>{
        if(ele.prop.includes('_task_status') || ele.prop.includes('t_task_status')){

          // console.log(this.toggleDependencies,ele.isExpanded);
            if(this.toggleDependencies){
              ele.headerClass = ele.isExpanded ? 'deptsHeaderExpandedCell ' : 'deptsHeaderCell deptstWidth-254';
              ele.cellClass = ele.isExpanded ? 'user-view expanded-view-container ' : 'user-view deptstWidth-254';
              ele.width = ele.isExpanded ? 566:190;
              ele.minWidth = ele.isExpanded ? 566:190;
              ele.maxWidth = ele.isExpanded ? 566:190;
            }else{
              ele.headerClass = ele.isExpanded ? 'deptsHeaderExpandedCell ' : 'deptsHeaderCell';
              ele.cellClass = ele.isExpanded ? 'user-view expanded-view-container ' : 'user-view ';
              ele.width = ele.isExpanded ? 502:190;
              ele.minWidth = ele.isExpanded ? 502:190;
              ele.maxWidth = ele.isExpanded ? 502:190;
            }

        }
      });
      this.isLoading = false;
    },600);

    this.cd.detectChanges();
  }

  // To set bg color according to parent dependency
  setDependencyData(parentDependenyType, type?){
    if(type=='tooltip'){
      if(parentDependenyType){
        switch (parentDependenyType) {
          case ("no-parent"):
            return 'No Dependency' ;
          case ("approval-pending"):
            return 'Approval Pending';
          case ("partially-approved"):
            return 'Partially Approved';
          case ("fully-approved"):
            return 'Fully Approved';

          default:
            return 'No Dependency' ;
        }
      }
    }else{

      if(parentDependenyType){
        switch (parentDependenyType) {
          case ("no-parent"):
            return type=='bgcolor'?"#a9a9a9":'noDepend';
          case ("approval-pending"):
            return type=='bgcolor'?"#FF6666":'approvalPend';
          case ("partially-approved"):
            return type=='bgcolor'?"#ff9800":'partiallyAppr';
          case ("fully-approved"):
            return type=='bgcolor'?"#2b982b":'fullyAppr';

          default:
            return type=='bgcolor'?"#a9a9a9":'noDepend';
        }
      }

    }
  }

  closeDependencySideWindow(){
    this._dependencyOpened = false;
    this._loadDependencySideWindow = false;
    this.dependencyDetails = [];
  }

  // For Dependency view side drawer
  getTaskDependencyDetails(assetData, assetTaskId) {
    // console.log(assetData);
    this.dependencyDetails = [];
    // console.log(this.data);
    // this.dataService.getOtherData('getTaskDependencyDetails', { 'project_id': this.data.project_id, 'task_id': this.data.task_id, 'shotAssets': this.data.assetAssets, 'e_episode_id': this.data.e_episode_id, 'sq_sequence_id': this.data.sq_sequence_id })
    this.dataService.getOtherData('getTaskDependencyDetails', { 'project_id': assetData.project_id, 'task_id': assetTaskId, 'e_episode_id': (assetData.hasOwnProperty('e_episode_id') ? assetData.e_episode_id:assetData.episode_id), 'sq_sequence_id': (assetData.hasOwnProperty('sq_sequence_id') ? assetData.sq_sequence_id:assetData.sequence_id) })
      .subscribe((success) => {
        this.dependencyDetails = success.data;
        // console.log(this.dependencyDetails);
      }, (rejected) => {

      });

    this.openDependencyDetails = false;
  }

  // to check if is Dept Filter Active
  isDeptFilterActive(column, type){
    // this.selectedDependencyFilter
    // console.log()
    let checkDeptFilterActive = this.assetColDeptFilters.find(ele=>ele.col==column.prop);
    // console.log(checkDeptFilterActive);
    if(checkDeptFilterActive){
      if(checkDeptFilterActive.hasOwnProperty('dependency')){
        if(checkDeptFilterActive['dependency']==type){
          return true;
        }
      }
    }else{
      return false;
    }
  }

  // to check tooltip row position
  checkTooltipRowPosition(rowIndex){
    if(this.rows.length>=12){
      if(this.rows.length-5<rowIndex){
        return 'vi1-tooltip_02_up';
      }
    }
  }

}
