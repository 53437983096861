import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
import * as moment from 'moment';
import * as _ from "lodash";
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
declare var $: any;

@Component({
	selector: 'create-asset',
	templateUrl: './create-asset.component.html',
	styleUrls: ['./create-asset.component.css']
})
export class CreateAssetComponent implements OnInit {

	entityName = 'Assets';
	entityNameSingular = 'Asset';
	apiRoutePrefix = 'assets';
	dirName = 'assets';

	@Input('attr.data') dataHtml: any;
	@ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
	@ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
	@Output() closeSideWindow = new EventEmitter();

	public singlePicker = {
		singleDatePicker: true,
		autoUpdateInput: true,
		showDropdowns: true,
		autoApply: true,
		drops: 'down',
		locale: {
			format: 'DD MMM YY'
		}
	}

	data: any;
	myform: any;
	onClose: any;
	assets: { [index: string]: any } = {};

	url: any;
	preview_src: string = '';
	image_name: string;
	image_type: string;
	image_old: string;

	errors: { [index: string]: any } = {};
	focused: string = '';
	SaveButtonText: string = 'SAVE';
	isMessgaeMode: any;
	settingsAll: any = JSON.parse(localStorage.getItem('settings'));

	projectId = '';

	assetType: Array<any>;
	assetSubType: Array<any>;
	complexityOptions: Array<any>;
	shotDepartmets: Array<any>;

	status = [{ "id": 1, "name": "Active" }, { "id": 0, "name": "OOP" }];
	shots: Array<any>;
	uploadUrl = this.commonJs.getUploadUrl();
	isStatusDisabled: boolean = true;
	isSaveDisabled: boolean = false;
	public files: NgxFileDropEntry[] = [];
	dropdownSettingsSingle: any = {};
	dropdownSettingsDepartment: any = {};

	visiblePhases: any[] = [];
	phasesExist = {};

	constructor(private dataService: DataService, private toastrService: ToastrService, public commonJs: CommonJs, public appConfigs: AppConfigs) { }

	ngOnInit() {

		if (localStorage.getItem('phasesExist')) {
			this.phasesExist = JSON.parse(localStorage.getItem('phasesExist'));
			// console.log(this.phasesExist);
		} else if (this.commonJs.phasesExist) {
			this.phasesExist = this.commonJs.phasesExist;
		} else {
			this.phasesExist = { pre: 1, character: 1, set: 1, prop: 1, shot: 1, post: 1 };
		}

		if (this.dataHtml != undefined) {
			this.data = this.dataHtml;
		}
		this.projectId = this.data.projectId;
		$.AdminBSB.input.activate();
		this.subngOnInit();

		if (this.data.mode == 'create') {
			//this.haveDepartments('assets', this.projectId);
		}

		this.dropdownSettingsSingle = {
			singleSelection: true,
			idField: "department_id",
			textField: "department_name",
			selectAllText: "Select All",
			unSelectAllText: "UnSelect All",
			itemsShowLimit: 10,
			allowSearchFilter: true,
			noDataAvailablePlaceholderText: 'No data available',
			searchPlaceholderText: 'Search final department',
			maxHeight: 90,
			autoPosition: false,
		};

		this.dropdownSettingsDepartment = {
			singleSelection: false,
			idField: "department_id",
			textField: "department_name",
			selectAllText: "Select All",
			unSelectAllText: "UnSelect All",
			// itemsShowLimit: 2,
			allowSearchFilter: true,
			noDataAvailablePlaceholderText: 'No data available',
			searchPlaceholderText: 'Search department',
			maxHeight: 90,
			autoPosition: false,
		};
	}

	subngOnInit() {
		this.assets = {
			project_id: this.projectId,
			name: '',
			code: '',
			description: '',
			status: 1,
			start_date: '',
			due_date: '',
			complexity: '',
			bid_days: 0,
			scheduled_days: 0,
			image: '',
			image_name: '',
			image_old: '',
			image_display_name: '',
			departments: '',
			final_department: ''
		}


		// this.dataService.getListData('shotsItems',{project_id:this.projectId})
		//   .subscribe((success) => {
		//     this.shots = success.data;
		//   }, (rejected) => {
		//
		//   });

		if (this.data.assetid && (this.data.mode == 'edit' || this.data.mode == 'view')) {
			this.focused = "focused";
			this.dataService.getDataById(this.apiRoutePrefix + '/' + this.data.assetid + '/edit')
				.subscribe((success) => {
					this.assets = success.data;
					this.getShotDepartments(this.assets.type).then((res: any) => {
						const departments: any = [];
						if (this.assets.departments) {
							this.shotDepartmets.filter((res: any) => {
								this.assets.departments.filter((re: any) => {
									if (res.department_id == re) {
										departments.push(res);
									}
									if (res.department_id == this.assets.final_department) {
										this.assets.final_department = [];
										this.assets.final_department.push(res);

									}
								});
							});

              if(!this.assets.final_department){
                this.errors.final_department = 'Final Department must be Set.';
              }

							this.assets.departments = departments;
						}
					});
					// if ( this.assets.start_date ) {
					//     this.assets.start_date = moment(this.assets.start_date).format(this.settingsAll.date_format);
					// }

					// if ( this.assets.due_date ) {
					//     this.assets.due_date = moment(this.assets.due_date).format(this.settingsAll.date_format);
					// }

					if (this.assets.image) {
						this.image_old = this.assets.image;
						let fileExtension = this.assets.image.split('.').pop();
						if (fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg' && fileExtension != 'gif') {
							this.preview_src = './assets/images/default/attechment.png';
						} else {
							this.preview_src = this.uploadUrl + this.dirName + '/' + this.assets.image;
						}
					}
					//	this.isStatusDisabled = this.assets.status;

					if (this.assets.image_display_name) {
						this.image_name = this.assets.image_display_name;
					}

				}, (rejected) => {

				});
		}
	}

	search(query, column) {
		if (column == 'type') {
			// this.assetType = this.appConfigs.assets_subtype.map(v => { return v });
			this.assetType = this.appConfigs.assets_subtype.map(v => {
				if (this.phasesExist[v.name.toLowerCase()]) {
					return v;
				} else {
					return;
				}
			}).filter((item) => (item != undefined || item != null));

		} else if (column == 'complexity') {
			this.complexityOptions = this.appConfigs.sht_ast_complexity.map(v => { return v });
		} else if (column == 'sub_type') {
			this.getAssetSubTypes(query);
		}
	}

	onSubmit(form: NgForm) {
		this.SaveButtonText = 'SAVING';
		this.isSaveDisabled = true;
		if (form.value.type) {
			form.value.type = this.assets.type.id;
		}

		if (form.value.final_department.length > 0) {
			var fl: any;
			fl = form.value.final_department[0].department_id
			form.value.final_department = fl;
		} else {
			form.value.final_department = '';
		}

		if (form.value.departments) {
			var dp: any = [];
			_.forEach(this.assets.departments, function (value: any) {
				dp.push(value.department_id)
			});

			form.value.departments = dp;
		}


		if (form.value.sub_type && this.assets.sub_type != undefined) {
			form.value.sub_type = this.assets.sub_type.id;
		}

		if (form.value.complexity) {
			form.value.complexity = this.assets.complexity.id;
		}
		if (this.url != undefined) {
			form.value.image = this.url;
		}
		form.value.image_name = this.image_name;
		form.value.project_id = this.projectId;
		if (this.data.assetid && this.data.mode == 'edit') {
			form.value.id = this.data.assetid;
			form.value.image_old = this.image_old;
			form.value.type = this.assets.type.id;
			this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.assetid, form.value)
				.subscribe((success) => {

					// Save effect within button
					this.SaveButtonText = 'SAVED';
					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.isSaveDisabled = false;
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
					}
					this.SaveButtonText = 'SAVE';
					this.isSaveDisabled = false;
				});
		} else {
			this.dataService.storeData(this.apiRoutePrefix, form.value)
				.subscribe((success) => {

					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.isSaveDisabled = false;
				}, (error) => {
					//this.alertMessage = 'Something went wrong, Please try again!';
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						if (err.message == 'No Departments') {
							this.toastrService.notify('warn', '', err.error);
							setTimeout(() => {
								this.SaveButtonText = 'SAVE';
								this.CloseOtherWindow.nativeElement.click();
							}, 2);
							this.close();
						} else {
							this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
						}
						this.SaveButtonText = 'SAVE';
						this.isSaveDisabled = false;
					}
				});
		}
	}

	close() {
		this.closeSideWindow.emit();
	}

	public dropped(files: NgxFileDropEntry[]) {
		this.files = files;
		for (const droppedFile of files) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {

					// Here you can access the real file
					//console.log(droppedFile.relativePath, file);

					var reader = new FileReader();
					this.image_name = file.name;
					this.image_type = file.type;

					reader.onload = (event: any) => {
						this.url = event.target.result;
						if (this.image_type != 'image/png' && this.image_type != 'image/jpg' && this.image_type != 'image/jpeg' && this.image_type != 'image/gif') {
							this.preview_src = './assets/images/default/attechment.png';
						} else {
							this.preview_src = this.url;
						}
					}
					reader.readAsDataURL(file);
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
				//console.log(droppedFile.relativePath, fileEntry);
			}
		}
	}

	public fileOver(event) {
		//console.log(event);
	}

	public fileLeave(event) {
		//console.log(event);
	}

	unlinkImage(event) {
		event.stopPropagation();
		this.preview_src = '';
		this.image_name = 'unset';
		this.url = '';
	}

	getAssetSubTypes(query) {
		this.dataService.getOtherData('searchItemList', { list_name: 'asset_subtypes', 'query': query['query'] })
			.subscribe((success) => {
				this.assetSubType = success.data;
			}, (rejected) => {

			});
	}

	/** On deselect final department */
	onItemDeSelect($event) {
		if (this.assets.final_department.length > 0) {
			if ($event.department_id == this.assets.final_department[0].department_id) {
				this.assets.final_department = [];
			}
		}
	}

	/** Get shot departments */
	getShotDepartments(data) {
		return new Promise((resolve, reject) => {
			this.dataService.getListData('projectDepartmentList', { 'project_id': this.projectId, 'type': data.id, 'fromAssetShot': true })
				.subscribe((success) => {
					this.shotDepartmets = success.data;
					console.log("this.shotDepartmets????????????", this.shotDepartmets)
					resolve("Document uploaded!")
				});
		});
	}

	/** On Select Asset Type */
	onSelectAssetType(data: any) {
		this.getShotDepartments(data);
		this.assets.departments = [];
		this.assets.final_department = [];
	}
}
interface Assets {
	project_id: string,
	code: string,
	name: string,
	type: string,
	description: string,
	status: number,
	start_date: string,
	due_date: string,
	complexity: string,
	image: string,
	image_name: string,
	image_old: string,
	image_display_name: string
}
