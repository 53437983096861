import { ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { CommonJs } from '../../../common/common-js';
import { Subscription, interval } from 'rxjs';
declare var $: any;
import * as _ from "lodash";
import { environment } from '../../../../environments/environment';
import { DatatableService } from '../../../services/datatable/datatable.service';
import { AppConfigs } from '../../../common/app-configs';
import { NotificationsService } from '../../../services/notifications/notifications.service';
import { GridService } from 'src/app/services/grid.service';
import Pusher from 'pusher-js/worker';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { columns } from './track-columns';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { PusherService } from 'src/app/services/pusher.service';

@Component({
  selector: 'app-task-track-datatable',
  templateUrl: './task-track-datatable.component.html',
  styleUrls: ['./task-track-datatable.component.css']
})
export class TaskTrackDatatableComponent implements OnInit {

  runningTime: any = "00:00:00";
  private intervalIdsArray: number[] = [];
  private subscription: Subscription;

  entityName = ' Task Track';
  entityNameSingular = ' Task Track';

  userTaskListWIP = [];
  userTaskListActive = [];
  userTaskListPipeline = [];
  userTaskListUnderReview = [];
  userTaskListCompleted = [];
  userTaskListRejectedInventory = [];

  userNotiCountActive = '';
  userNotiCountUnderReview = '';
  userNotiCountCompleted = '';

  projectId = '';

  _task_comment_opened: boolean = false;
  _loadCommentSideWindow: boolean = false;
  _ng_sidebar_comment_data = {};

  taskDetail: { [index: string]: any } = {};
  errors: { [index: string]: any } = {};
  leadList: Array<any>;

  currentActiveSection = 'wip';

  projectroletype = {};
  is_external_login: boolean = false;
  is_external_review: boolean = false;

  projectRoles = {};
  currentRole = '';

  _task_checklist_opened: boolean = false;
  _loadChecklistSideWindow: boolean = false;
  _ng_sidebar_checklist_data = {};

  is_open_detail = false;
  selectedTask: { [index: string]: any } = {};

  reRender: { [index: string]: any } = {};

  _detail_opened: boolean = false;

  _loadSideWindow: boolean = false;
  _loadDetailSideWindow: boolean = false;

  _ng_sidebar_detail_data = {};
  isNotify: boolean = false;
  private timer;
  settings: any = JSON.parse(localStorage.getItem('settings'));

  //Filters
  taskReview: { [index: string]: any } = {};
  where: { [index: string]: any } = {};
  episodes: { [index: string]: any } = {};
  sequences: { [index: string]: any } = {};
  assets: { [index: string]: any } = {};
  shots: { [index: string]: any } = {};
  departments: { [index: string]: any } = {};
  manualTasks: { [index: string]: any } = {};
  //statusArr=this.appConfigs.sht_ast_status;
  statusArr = [];
  filterIds = [];
  filterArr = [];

  isEpisodic: boolean = false;
  clientCode = localStorage.getItem('access_code');
  userId = localStorage.getItem('userId');
  subscribeisUserTasksList;
  isGeneralActivityTaskActive: Subscription;
  isTaskSentForReview: any;
  isTaskStarted: boolean = false;
  studioType: any;
  isLoading: boolean = true;

  //Pagination
  busy: Subscription;
  public rows = this.datatableService.rows;
  public FilterColumns: Array<any> = ['list_name'];

  public maxSize = this.datatableService.maxSize;
  public page: number = this.datatableService.page;
  public itemsPerPage: number = this.datatableService.itemsPerPage;
  //public itemsPerPage:number = 3;
  public length = this.datatableService.length;
  public numPages: number = this.datatableService.numPages;

  noRecordFound: boolean = false;
  noRecordFoundMsg: any = 'Sorry, no records found!';
  totalElements: number;
  isFilterApplied: boolean;

  versionsArr = [
    { "id": 1, "name": "V001" },
    { "id": 2, "name": "V002" },
    { "id": 3, "name": "V003" },
    { "id": 4, "name": "V004" },
    { "id": 5, "name": "V005 and Greater" }
  ];

  public istaskTrack: boolean = false;
  public istaskReview: boolean = false;

  isFirstTime: boolean = true;
  multipleFiles: Array<any> = [];
  TempMultipleFiles: Array<any> = [];
  fileSizeError: boolean = false;

  isFilterSubmitted: boolean = false;
  isFilterReset: boolean = false;


  //for unsubscribing
  pauseProjectTaskSub: Subscription;

  currentRunningTaskSub;

  uploadUrl = this.commonJs.getUploadUrl();

  tasksAllId = [];

  getAllSettingResponse: any;

  isAdvanceSearchEnabled: boolean = false;

  tab = 'wip';
  activeTab: string = 'wip';

  public config: any = {
    paging: true,
    sorting: { columns: [] },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered'],
    where: { 'project_id': this.projectId, tab: this.activeTab, filterArr: this.filterArr },
  };

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  @ViewChild('running', { static: false }) public running: TemplateRef<any>;
  @ViewChild('timeLog', { static: false }) public timeLog: TemplateRef<any>;

  @ViewChild('imageIcon', { static: false }) public imageIcon: TemplateRef<any>;
  @ViewChild('taskThumbnail', { static: false }) public taskThumbnail: TemplateRef<any>;
  @ViewChild('info', { static: false }) public info: TemplateRef<any>;
  @ViewChild('wbdays', { static: false }) public wbdays: TemplateRef<any>;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;

  datatableRows: any[] = [];
  dataTableColumns: TableColumn[] = [];
  pageSize: number = 100;
  selected = [];
  totalItems: number = 0;
  currentPage: number = 0;
  openedPage: number = 1;
  pagePayload: any = { page: 0, itemsPerPage: 30 };

  bsModalRef: BsModalRef;

  selectedList = [];

  checkboxColumn = {
    prop: 'selected',
    name: '',
    sortable: false,
    frozenLeft: true,
    draggable: true,
    resizable: false,
    headerCheckboxable: true,
    checkboxable: true,
    width: 45,
    maxWidth: 45,
  }

  constructor(
    public router: Router,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    public commonJs: CommonJs,
    private toastrService: ToastrService,
    //private _hotkeysService: HotkeysService,
    private datatableService: DatatableService,
    public appConfigs: AppConfigs,
    private titleService: Title,
    private notificationsService: NotificationsService,
    public gridService: GridService,
    public cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private pusherService: PusherService
  ) {
    this.config.where.userRole = JSON.parse(localStorage.getItem('user')).slug;
    this.config.where.userId = JSON.parse(localStorage.getItem('user')).id;
    this.datatableService.setParam([], 'columns');
    this.datatableService.setParam(this.config, 'config');
    this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
    this.datatableService.setParam('getUserTaskList', 'serverUrl');
  }

  ngOnInit() {

    this.setDatatableColumns();

    this.subscription = this.dataService.notifyObservable$.subscribe((res) => {
      if (res.value === 'success') {
        this.onChangeTable(this.config);
      }
    });

    this.getAllSettingResponse = this.commonJs.getAllSettingResponse ? this.commonJs.getAllSettingResponse : JSON.parse(localStorage.getItem('settings'));

    this.titleService.setTitle(this.settings.html_prefix + this.entityName);
    this.istaskTrack = this.router.url.indexOf('tasks-track') !== -1;
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
      if (params['notify']) {
        this.isNotify = true;
        this.activeTab = this.config.where.tab = 'ready';
      }
      this.config.where.project_id = this.projectId;
      this.config.where.tab = this.activeTab;
      this.onChangeTable(this.config);
    });

    var projectR = (function () {
      var result;
      try {
        let result = JSON.parse(localStorage.getItem('projectRoles'));
        return result;
      } catch (exception) {
      }
    }());

    var projectRT = (function () {
      var result;
      try {
        let result = JSON.parse(localStorage.getItem('projectRoleType'));
        return result;
      } catch (exception) {
      }
    }());

    this.studioType = (function () {
      var result;
      try {
        let result = localStorage.getItem('studio_type');
        return result;
      } catch (exception) {
      }
    }());

    if (projectR) {
      this.projectRoles = projectR;
      this.currentRole = this.projectRoles['project_' + this.projectId];
    }

    if (projectRT) {
      this.projectroletype = projectRT;
      if (this.projectroletype['project_' + this.projectId] == 'external') {
        this.is_external_login = true;
      }
    }

    this.getProjectSettings();

    this.getArtistReviewFilters(this.activeTab);
    if (environment.prodType == 'offline') {
      this.timer = interval(10000).subscribe(x => {
        this.getUserTaskList();
      });
    }

    if (environment.prodType != 'offline') {
      this.subscribeisUserTasksList = this.dataService.isUserTasksList$.subscribe(notification => {
        let notifyTo = notification['notification_to'];
        if (notifyTo == 'artist' || notifyTo == '0') {
          this.getUserTaskList('notification artist');
        } else if (notification['type'] == 'task_transfer' || notification['type'] == 'task_on_hold') {
          this.getUserTaskList('notification transfer');
          localStorage.removeItem('currentTask');
          this.currentRunningTaskSub = this.dataService.getCurrentRunningTask([]);
        }
      });
    }

    this.isGeneralActivityTaskActive = this.dataService.isGeneralActivityTaskActive.subscribe(isActive => {
      setTimeout(() => isActive, 0)
      this.getUserTaskList('general activity');
    });
    this.dataService.pauseProjectTask$.observers.length = 0;
    this.pauseProjectTaskSub = this.dataService.pauseProjectTask$.subscribe(taskData => {
      if (taskData) {
        this.changesTaskStatus('', 'pause', taskData);
      }
    });

    //get custom task list
    this.getStatusList();


    /** Pusher js live update data without reload page */
    if (environment.production) {
      const channel = this.pusherService.subscribeToChannel(`tasks`);
      // const channel = pusher.subscribe(`${'tasks.'}${this.projectId}`);
      channel.bind(`tasks`, (data) => {
        if(data.projectId == this.projectId){
          this.isLoading = true;
          this.getUserTaskList();
        }
      });
    }
    /** *************************************************** END ***************************************************/
  }

  ngOnDestroy() {

    // this.clearAllIntervals();
    for (const intervalId of this.intervalIdsArray) {
      clearInterval(intervalId);
    }

    if (environment.prodType != 'offline') {
      this.subscribeisUserTasksList.unsubscribe();
    } else {
      this.timer.unsubscribe();
    }

    if (this.isGeneralActivityTaskActive) {
      this.isGeneralActivityTaskActive.unsubscribe();
    }

    if (this.pauseProjectTaskSub) {
      //this.pauseProjectTaskSub.unsubscribe();
    }
    if (this.currentRunningTaskSub) {
      this.currentRunningTaskSub.unsubscribe();
    }

    this.pusherService.unsubscribeFromChannel(`tasks`);
  }

  sendPushNotification(userId, notificationId) {
    this.dataService.getOtherData('getNotificationById', { 'userId': userId, 'notification_id': notificationId })
      .subscribe((success) => {
        if (success.notify.length > 0) {
          let notify = success.notify[0];
          this.notificationsService.sendPushMessage(notify.description, notify.messge, 'tasks-track');
        }
      }, (rejected) => {

      });
  }

  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    console.log('here>>>>>>', this.config);
    this.busy = this.datatableService.onChangeTable(this.config, page)
      .subscribe((success) => {

        this.userTaskListWIP = success.wip;
        this.userTaskListWIP.forEach(task => {
          task.livetime = null;

          if (task.is_running) {
            this.updateTimeDuration(task, true);
          }

        });
        this.userTaskListActive = success.ready;
        this.userTaskListPipeline = success.pipeline;
        this.userTaskListUnderReview = success.under_review;
        this.userTaskListCompleted = success.completed;
        this.userTaskListRejectedInventory = success.rejectInventory;

        console.log('testt',this.activeTab);
        switch (this.activeTab) {
          case 'pipeline':
            this.datatableRows = success.pipeline;
            break;

          case 'ready':
            this.datatableRows = success.ready;
            break;

          case 'wip':
            this.datatableRows = success.wip;
            this.datatableRows.forEach(task => {
              task.livetime = null;

              if (task.is_running) {
                this.updateTimeDuration(task, true);
              }

            });
            break;

          case 'under_review':
            this.datatableRows = success.under_review;
            break;

          case 'completed':
            this.datatableRows = success.completed;
            break;

          case 'rejected_inventory':
            this.datatableRows = success.rejectInventory;
            break;

          default:
            // this.datatableRows = success.ready.length>0 ? success.ready.forEach((ele) => { ele.showMore = true; }) : success.ready;
            this.datatableRows = [];
            break;

        }
        console.log(this.datatableRows);

        this.length = success.total;
        this.noRecordFound = true;
        this.totalElements = success.totalElements;
        this.totalItems = success.totalElements;
        this.isLoading = false;
      },
        (error) => {
          this.datatableRows = [];
          this.totalItems = 0;
          this.isLoading = false;
        });
  }

  displayDetailView(val, taskDetail: any = [], section = '', selectedTab = 'task_info', tab = this.activeTab) {

    this.reRender[section] = false;

    if (this.selectedTask.id != taskDetail.id) {
      this.is_open_detail = val;
      this.selectedTask = taskDetail;
      this.selectedTask['task'] = taskDetail;
      this.selectedTask['open_from'] = section;
      this.selectedTask['section'] = section;
      this.selectedTask['allow_status'] = false;
      this.selectedTask['with_comment'] = true;
      this.selectedTask['is_artist'] = true;
      this.selectedTask['isReview'] = false;
      this.selectedTask['selectedTab'] = selectedTab;
      this.selectedTask['page'] = 'tasks-track';
      this.selectedTask['is_external_review'] = this.is_external_review;

      $('.task-li').removeClass('active');
      $('.task-' + taskDetail.id).addClass('active');

      setTimeout(() => {
        this.reRender[section] = true;
      }, 1);
    } else {
      this.selectedTask = [];
      this.is_open_detail = false;
      $('.task-' + taskDetail.id).removeClass('active');
    }

  }

  getStatusList() {
    // return new Promise((resolve, rejected) => {
    //   this.dataService.getOtherData('getStatusList', {})
    //     .subscribe((success) => {
    this.statusArr = [...this.dataService.allStatusList];
    //       resolve(200);
    //     }, (rejected) => {

    //     });
    // });
  }

  makeActive(section, id = '') {
    this.currentActiveSection = section;
    $('.task-list').removeClass('collapsed');
    $('.task-' + id).addClass('collapsed');
  }

  selectTab(tab) {
    this.isAdvanceSearchEnabled = false;
    this.isLoading = true;
    this.clearAllIntervals();
    this.isFilterApplied = false;
    // this.length = 0;
    // this.tab = tab;
    this.activeTab = tab;
    this.setDatatableColumns();

    // this.config.where.userRole = JSON.parse(localStorage.getItem('user')).slug;

    this.config.where.tab = this.activeTab;
    this.page = 0;
    this.config.where.filterArr = [];
    console.log(JSON.parse(localStorage.getItem('user')));
    this.config.where.userRole = JSON.parse(localStorage.getItem('user')).slug;
    this.config.where.userId = JSON.parse(localStorage.getItem('user')).id;
    this.taskReview = [];
    let markAsReadType = [];
    this.onChangeTable(this.config);
    this.displayDetailView(false);
    if (tab == 'ready') {
      markAsReadType = ['task_unapproved', 'task_cbb', 'task_retake'];
    } else if (tab == 'under_review') {
      markAsReadType = ['task_sent_for_review', 'task_done', 'task_sent_to_client'];
    } else if (tab == 'completed') {
      markAsReadType = ['task_approved'];
    }

    this.filterArr = [];
    this.filterIds = [];
    // this.tasksAllId = [];
    this.selectedList = [];

    $('.check-sub').prop('checked', false);
    this.getArtistReviewFilters(tab);

    if (Object.keys(markAsReadType).length > 0) {
      // this.dataService.getOtherData('markAsRead', {'project_id': this.projectId, 'type': markAsReadType})
      //   .subscribe((success) => {
      //   }, (rejected) => {
      //
      //   });
    }
  }

  getTabNotiCount() {
    this.dataService.getOtherData('getProjectNotiCountDetail', { 'project_id': this.projectId })
      .subscribe((success) => {
        this.userNotiCountActive = '';
        this.userNotiCountUnderReview = '';
        this.userNotiCountCompleted = '';
        let that = this;
        _.forEach(success.other, function (value) {
          if (value['type'] == 'assign_task' || value['type'] == 'task_unapproved' || value['type'] == 'task_cbb' || value['type'] == 'task_retake') {
            that.userNotiCountActive = that.userNotiCountActive + value['noti_count'];
          } else if (value['type'] == 'task_sent_for_review' || value['type'] == 'task_done' || value['type'] == 'task_sent_to_client') {
            that.userNotiCountUnderReview = that.userNotiCountUnderReview + value['noti_count'];
          } else if (value['type'] == 'task_approved') {
            that.userNotiCountCompleted = that.userNotiCountCompleted + value['noti_count'];
          }
        });

      }, (rejected) => {

      });
  }

  getUserTaskList(calledFrom = 'OnInit') {
    this.config.where.filterArr = this.filterArr;
    this.onChangeTable(this.config, { page: this.page, itemsPerPage: this.itemsPerPage });
  }

  getFilterData(query, column) {
    if (column == 'episodes') {
      this.getEpisodes(query);
    } else if (column == 'sequences') {
      this.getSequences(query);
    } else if (column == 'assets') {
      this.getAssets(query);
    } else if (column == 'shots') {
      this.getShots(query);
    } else if (column == 'departments') {
      this.getDepartments(query);
    } else if (column == 'status') {
      this.statusArr = this.statusArr.map(o => {
        return o;
      });
    }
  }

  /**Filters**/
  getArtistReviewFilters(tab) {
    this.where.tab = tab;
    this.where.projectId = this.projectId;
    this.where.is_external_review = this.is_external_review;
    this.where.currentRole = this.currentRole;
    this.dataService.getListData('getArtistReviewFilters', { 'where': this.where })
      .subscribe((success) => {
        this.episodes = success.data.episodes;
        this.sequences = success.data.sequences;
        this.assets = success.data.assets;
        this.manualTasks = success.data.manualTasks;
        this.isEpisodic = success.data.isEpisodic;
      }, (rejected) => {

      });
  }

  filterAction(IS_ALL, value, row, entity) {
    if (IS_ALL) {
      let checkboxChecked = $('#checkAll').is(':checked');
      let i;
      this.filterIds = [];
      for (i = 0; i < value.length; i++) {
        this.filterIds.push(value[i].id);
      }
      if (checkboxChecked) {
        $('#checkAll').prop('checked', false);
        $('.check-sub').prop('checked', false);
        this.filterIds = [];
      } else {
        $('#checkAll').prop('checked', true);
        $('.check-sub').prop('checked', true);
      }

    } else {
      if (!value) {
        const index: number = this.filterIds.indexOf(row);
        if (index !== -1) {
          this.filterIds.splice(index, 1);
        }
        $('#check-' + value.id).prop('checked', false);
      } else {
        this.filterIds.push(row);
        $('#check-' + value.id).prop('checked', true);
      }
      $('#check-' + value.id).focus();
    }
    this.filterArr = [{ 'entity': entity, 'filterIds': this.filterIds }];
  }

  applyFilters() {
    this.isFilterSubmitted = true;
    this.page = 1;
    this.getUserTaskList('filter applied');
    this.isFilterApplied = true;

    setTimeout(() => {
      this.isFilterSubmitted = false;
    }, 2000);
  }

  clearFilter(entity = '') {
    this.isFilterReset = true;

    this.filterArr = [];
    this.filterIds = [];
    $('.check-sub').prop('checked', false);
    //this.getUserTaskList();
    this.page = 1;
    this.onClearFilters(entity);
    this.isFilterApplied = false;

    setTimeout(() => {
      this.isFilterReset = false;
    }, 2000);
  }


  changesTaskStatus(event, status, taskData, tab = '') {

    // this.clearAllIntervals();
    for (const intervalId of this.intervalIdsArray) {
      clearInterval(intervalId);
    }
    // status === 'pause' ? clearInterval(this.intervalId) : '';
    status === 'pause' ? this.clearAllIntervals() : '';

    // status === 'resume' ? this.updateDuration(taskData) : '';
    status === 'resume' ? this.updateTimeDuration(taskData, false) : '';

    //To close track button
    // this.toastrService.closeTrackButton.next('');

    this.isTaskStarted = true;
    this.istaskTrack = this.router.url.indexOf('tasks-track') !== -1;
    if (tab == 'pipeline' && taskData.parentApproval == 'pending') {
      this.checkParentTaskStatus(taskData);
      //return false;
    }

    if ((tab == 'pipeline' || tab == 'ready') && (taskData.revision == 'R001' && taskData.version == 'V001') && (taskData.parentApproval == 'pending' || taskData.parentApproval == 'partially') && (taskData.status != 'Unapproved' && taskData.status != 'CBB')) {
      // this.toastrService.notify('error', '', 'Parent department(s) are not approved yet.');
    }

    if (event != '') {
      event.stopPropagation();
    }
    // return;
    this.dataService.getOtherData('changeTaskStatus',
      {
        'project_id': this.projectId,
        'task_id': taskData.task_id,
        'task_assignment_id': taskData.id,
        'task_version_id': taskData.task_version_id,
        'status': status,
        'current_role': this.currentRole
      })
      .subscribe((success) => {
        if (success.status == 'success') {
          if (status == 'start' && this.currentRole != 'vendor') {
            this.config.where.tab = this.activeTab = 'wip';
            $('.tab-title').removeClass('active');
            $('.tab-wip').addClass('active');
            $('.tab-pane').removeClass('active').removeClass('in');
            $('#wip').addClass('active').addClass('in');
            // this.setDatatableColumns()
            if (this.activeTab == 'wip') {
              let
                cellTemplate = {
                  idsT: this.idsT,
                  running: this.running,
                  timeLog: this.timeLog,
                  imageIcon: this.imageIcon,
                  taskThumbnail: this.taskThumbnail,
                  info: this.info,
                  wbdays: this.wbdays,
                  status: this.status,
                };
              this.dataTableColumns = columns(this.currentRole, this.activeTab, cellTemplate);

            }
            // For artage offline build task no need to change tabs
            // this.config.where.tab = this.tab = 'ready';
            // $('.tab-title').removeClass('active');
            // $('.tab-ready').addClass('active');
            // $('.tab-pane').removeClass('active').removeClass('in');
            // $('#ready').addClass('active').addClass('in');

            this.page = 0;
            this.isNotify = false;
            this.clearFilter();
          }
          if (status == 'resume') {
            this.page = 0;
          }
          taskData.activity = 'stop';
          taskData.tasktypeName = 'project';
          if (status == 'start' || status == 'resume') {
            this.dataService.changeGeneralTaskActivityStatus('general', false);
            this.dataService.changeArtistProjectTaskStatus(true);
            taskData.activity = 'active';
            if (this.currentRole != 'vendor') {
              taskData.isexternalReview = this.is_external_review;
              localStorage.setItem('currentTask', JSON.stringify(taskData));
              this.currentRunningTaskSub = this.dataService.getCurrentRunningTask(taskData);
            }
          } else {
            if (this.istaskTrack) {
              this.getUserTaskList('changeTaskStatus');
            }
            localStorage.removeItem('currentTask');
            this.currentRunningTaskSub = this.dataService.getCurrentRunningTask([]);
          }
          if (this.currentRole == 'vendor' && success.data.notifications.length > 0) {
            let that = this;
            _.forEach(success.data.notifications, function (notification, key) {
              that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
            });
          }
          // this.tasksAllId = [];
          this.selectedList = [];
          $('#checkAll-' + tab).prop('checked', false);
          $('.check-sub-' + tab).prop('checked', false);
          setTimeout(() => {
            this.isTaskStarted = false;
          }, 1000);
        }
        else {
          this.toastrService.notify('error', '', 'Something went wrong please try again');
        }

      }, (rejected) => {
        this.isTaskStarted = false;

      });
  }

  checkParentTaskStatus(taskData) {
    let shotAsset = taskData.asset_id != null ? 'asset_id' : 'shot_id';
    let shotAssetId = taskData.asset_id != null ? taskData.asset_id : taskData.shot_id;
    this.dataService.getOtherData('checkParentTaskStatus',
      {
        'project_id': this.projectId, 'task_id': taskData.task_id, 'task_assignment_id': taskData.id,
        'task_version_id': taskData.task_version_id, 'department_id': taskData.department_id, 'current_role': this.currentRole
      })
      .subscribe((success) => {
        if (!success.data.isParentApproved) {
          this.toastrService.notify('error', '', success.data.message);
        } else {
          this.toastrService.notify('error', '', 'You are starting task from Awaiting Inventory.');
        }
      }, (rejected) => {

      });
  }

  changeToWIP(tab) {
    // this.busy = this.dataService.getOtherData('changeMultipleTaskStatus', this.tasksAllId)
    this.busy = this.dataService.getOtherData('changeMultipleTaskStatus', this.selectedList)
      .subscribe((success) => {
        if (status == 'start') {
          this.dataService.changeGeneralTaskActivityStatus('general', false);
          this.dataService.changeArtistProjectTaskStatus(true);
        }

        if (this.currentRole == 'vendor' && success.data.notifications.length > 0) {
          let that = this;
          _.forEach(success.data.notifications, function (notification, key) {
            that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
          });
        }

        this.toastrService.notify('success', '', success.message);
        setTimeout(() => {
          this.isTaskStarted = false;
        }, 2000);

        this.getUserTaskList('changeTaskStatus');

        // this.tasksAllId = [];
        this.selectedList = [];
        $('#checkAll-' + tab).prop('checked', false);
        $('.check-sub-' + tab).prop('checked', false);

      }, (rejected) => {
        this.isTaskStarted = false;

      });
  }

  sentForReview(tab) {
    this.isTaskStarted = true;
    // this.dataService.getOtherData('sendMultipleTasksForReview', this.tasksAllId)
    this.dataService.getOtherData('sendMultipleTasksForReview', this.selectedList)
      .subscribe((success) => {
        this.dataService.changeGeneralTaskActivityStatus('general', false);
        this.dataService.changeArtistProjectTaskStatus(true);
        this.toastrService.notify('success', '', success.message);
        if (this.istaskTrack) {
          this.getUserTaskList('changeTaskStatus');
        }


        if (this.currentRole == 'vendor' && success.data.notifications.length > 0) {
          let that = this;
          _.forEach(success.data.notifications, function (notification, key) {
            that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
          });
        }
        // this.tasksAllId = [];
        this.selectedList = [];
        $('#checkAll-' + tab).prop('checked', false);
        $('.check-sub-' + tab).prop('checked', false);

        setTimeout(() => {
          this.isTaskStarted = false;
        }, 2000);

      }, (rejected) => {
        this.isTaskStarted = false;

      });
  }

  onShownLead(task) {
    for (const intervalId of this.intervalIdsArray) {
      clearInterval(intervalId);
    }
    this.isLoading = true;
    this.commonJs.getAllSettings(true);
    this.gridService.taskPlayPuase.subscribe((res: any) => {
      this.gridService.resetEventEmitter();
      if (res) {
        this.settings = JSON.parse(localStorage.getItem('settings'));
        this.taskDetail = {};
        this.taskDetail = task;
        if (this.settings.task_timer_lock != 0) {
          this.isTaskSentForReview = this.checkTaskDuration();
        } else {
          this.isTaskSentForReview = true;
        }
        this.isLoading = false;
        this.cd.markForCheck();
        this.dataService.getListData('getReviewLeadList', { 'project_id': this.projectId, 'department_id': this.taskDetail.department_id })
          .subscribe((success) => {
            this.leadList = success.data;
            //console.log("this.leadList", this.leadList)
            const objToMove = this.leadList.find(obj => obj.department === task.department_name); // find the index of the object
            const index = this.leadList.findIndex(obj => obj.department === task.department_name); // find the index of the object
            if (index !== -1) { // if the object is found in the array
              this.leadList.splice(index, 1); // remove it from its current position
              this.leadList.unshift(objToMove); // add it to the beginning of the array
            }
            this.taskDetail.count = success.count;
            if (success.count == 1) {
              this.taskDetail.lead = success.data[0];
            } else if (success.count == 0) {
              this.taskDetail.lead = [];
              this.taskDetail.supervisor = success.supervisor;
            } else if (success.count > 1) {
              this.taskDetail.lead = success.data[0];
            }
          }, (rejected) => {
          });
        this.errors = {};
      }
    });

  }

  search(query, column) {
    if (column == 'lead') {
      //this.getSecondaryDept(this.users.primary_department['id'],query['query']);
      this.leadList = this.leadList.map(o => {
        return o;
      });
    }
  }

  dropdownClick(e) {
    e.originalEvent.stopPropagation();
  }


  convertToSeconds(timeStr: string): number {
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  convertToMinutes(timeStr: string): number {
    const [hours, minutes, _] = timeStr.split(":").map(Number);
    return hours * 60 + minutes;
  }

  convertToHHMMSS(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  /** Check task duration */
  checkTaskDuration() {
    const startDate = new Date(this.taskDetail.review_date);
    const currentDate = new Date();
    const elapsedTime = currentDate.getTime() - startDate.getTime();
    const elapsedHours = Math.floor(elapsedTime / (1000 * 60 * 60));
    const elapsedMinutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
    const elapsedSeconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);
    const elapsed_time = `${elapsedHours}:${elapsedMinutes}:${elapsedSeconds}`;
    const totalDurationInSeconds = this.convertToSeconds(this.taskDetail.total_duration);
    const timeSpendInSeconds = this.convertToSeconds(elapsed_time);
    const totalTimeInSeconds = totalDurationInSeconds + timeSpendInSeconds;
    const totalDuration = this.convertToHHMMSS(totalTimeInSeconds);
    const totalDurationInMinutes = this.convertToMinutes(totalDuration);
    if (totalDurationInMinutes > this.settings.task_timer_lock) {
      return true
      // You can take appropriate actions here.
    } else {
      return false
    }
  }


  onSubmit(form: NgForm) {
    let comment = "";
    if (form.value.comment) {
      comment = form.value.comment;
      comment = comment.replace(/\r?\n/g, '<br />');
    }
    let path = "";
    if (form.value.path) {
      path = form.value.path;
    }

    let image = [];
    if (this.multipleFiles.length > 0) {
      image = form.value.images = this.multipleFiles;
    }
    //this.changesTaskStatus('', 'done', this.taskDetail);
    this.dataService.getOtherData('changeTaskStatus', {
      'project_id': this.projectId,
      'lead_user_id': this.taskDetail.lead,
      'task_id': this.taskDetail.task_id,
      'task_assignment_id': this.taskDetail.id,
      'task_version_id': this.taskDetail.task_version_id,
      'status': 'done',
      'current_role': this.currentRole,
      'comment': comment,
      'path': path,
      'images': image
    })
      .subscribe((success) => {
        this.multipleFiles = [];
        localStorage.removeItem('currentTask');
        this.currentRunningTaskSub = this.dataService.getCurrentRunningTask([]);
        if (success.status == 'waiting') {
          this.toastrService.notify('success', '', success.message);
        }
        this.getUserTaskList('task send for reviewe!');
        if (success.data.notifications.length > 0) {
          let that = this;
          _.forEach(success.data.notifications, function (notification, key) {
            that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
          });
        }
        this.bsModalRef.hide();
      }, (rejected) => {
        this.bsModalRef.hide();
      });
  }

  /**********    Side Window    **********/

  openCommentSideWindow(data) {
    this._ng_sidebar_comment_data['projectId'] = this.projectId;
    this._ng_sidebar_comment_data['task'] = data;
    this._ng_sidebar_comment_data['allow_status'] = false;
    this._ng_sidebar_comment_data['with_comment'] = true;
    this._task_comment_opened = true;
  }

  onCommentOpenStart() { }

  onCommentOpened() {
    this._loadCommentSideWindow = true;
  }

  onCommentClosed() {
    this._loadCommentSideWindow = false;
  }

  closeSideWindow(data) {
    this.selectedTask = [];
    this.is_open_detail = false;
    $('.task-' + data.id).removeClass('active');
    this.getUserTaskList('close window');
  }


  /**********    Checklist Side Window    **********/

  openChecklistSideWindow(data) {
    this._ng_sidebar_checklist_data['projectId'] = this.projectId;
    this._ng_sidebar_checklist_data['task'] = data;
    this._ng_sidebar_checklist_data['allow_status'] = true;
    this._ng_sidebar_checklist_data['is_artist'] = true;
    this._task_checklist_opened = true;
  }

  onChecklistOpenStart() { }

  onChecklistOpened() {
    this._loadChecklistSideWindow = true;
  }

  onChecklistClosed() {
    this._loadChecklistSideWindow = false;
  }

  closeChecklistSideWindow() {
    //this.getReviewTaskList();
  }

  /**********    Side Window    **********/
  displayDetail() {
  }

  openDetailSideWindow(type, data) {
    this._ng_sidebar_detail_data['projectId'] = this.projectId;
    this._ng_sidebar_detail_data['type'] = type;
    this._ng_sidebar_detail_data['data'] = { 'id': data.id };
    this._detail_opened = true;
  }

  onDetailOpenStart() {

  }

  onDetailOpened(taskDetails?) {
    taskDetails ? this._loadSideWindow = true : this._loadDetailSideWindow = true;
  }

  onDetailClosed(taskDetails?) {
    taskDetails ? this._loadSideWindow = false : this._loadDetailSideWindow = false;
    // console.log(this.activeTab, this.isNotify)
    // this.activeTab = this.activeTab;
  }


  onFilterSelect(column, $event) {
    if (column == 'episodes') {
      this.filterArr = [{ 'entity': column, 'filterIds': this.taskReview.episodes }];
    } else if (column == 'sequences') {
      this.filterArr = [{ 'entity': column, 'filterIds': this.taskReview.sequences }];
    } else if (column == 'assets') {
      this.filterArr = [{ 'entity': column, 'filterIds': this.taskReview.assets }];
    } else if (column == 'shots') {
      this.filterArr = [{ 'entity': column, 'filterIds': this.taskReview.shots }];
    } else if (column == 'departments') {
      this.filterArr = [{ 'entity': column, 'filterIds': [this.taskReview.departments] }];
    } else if (column == 'artists') {
      this.filterArr = [{ 'entity': column, 'filterIds': this.taskReview.artists }];
    } else if (column == 'status') {
      this.filterArr = [{ 'entity': column, 'filterIds': [this.taskReview.status] }];
    }
    //this.onChangeTable(this.config);
  }

  onFilterUnselect(column, $event) {
    let filtered = this.filterArr[0]['filterIds'].filter(function (value, index, arr) {
      return value.id != $event.id;
    });
    this.filterArr = [{ 'entity': column, 'filterIds': filtered }];
  }

  onClearFilters(column) {
    if (column == 'episodes') {
      this.taskReview.episodes = [];
      this.filterArr = [];
    } else if (column == 'sequences') {
      this.taskReview.sequences = [];
      this.filterArr = [];
    } else if (column == 'assets') {
      this.taskReview.assets = [];
      this.filterArr = [];
    } else if (column == 'shots') {
      this.taskReview.shots = [];
      this.filterArr = [];
    } else if (column == 'departments') {
      this.taskReview.departments = [];
      this.filterArr = [];
    } else if (column == 'artists') {
      this.taskReview.artists = [];
      this.filterArr = [];
    } else if (column == 'status') {
      this.taskReview.status = [];
      this.filterArr = [];
    } else {
      this.taskReview = [];
      this.filterArr = [];
    }
    this.page = 1;
    this.getUserTaskList('filter cleared');
  }

  public checkedAction(IS_ALL, row: any, column: any): void {
    if (IS_ALL) {
      let checkboxChecked = $('#checkAll-' + column).is(':checked');
      if (checkboxChecked) {
        // this.tasksAllId = [];
        this.selectedList = [];
        $('#checkAll-' + column).prop('checked', false);
        $('.check-sub-' + column).prop('checked', false);

        $('.tasks-ul .active').removeClass('active');

      } else {
        let i;
        // this.tasksAllId = [];
        this.selectedList = [];
        if (column == 'ready') {
          for (i = 0; i < this.userTaskListActive.length; i++) {
            let activeArr = {
              'project_id': this.projectId,
              'task_id': this.userTaskListActive[i].task_id,
              'task_assignment_id': this.userTaskListActive[i].id,
              'task_version_id': this.userTaskListActive[i].task_version_id,
              'status': 'start',
              'current_role': this.currentRole,
            };
            $('.task-' + this.userTaskListActive[i].id).addClass('active');
            // this.tasksAllId.push(activeArr);
            this.selectedList.push(activeArr);
          }
        }

        if (column == 'wip') {
          for (i = 0; i < this.userTaskListWIP.length; i++) {
            let activeArr = {
              'project_id': this.projectId,
              'task_id': this.userTaskListWIP[i].task_id,
              'task_assignment_id': this.userTaskListWIP[i].id,
              'task_version_id': this.userTaskListWIP[i].task_version_id,
              'status': 'done',
              'current_role': this.currentRole,
            };
            // this.tasksAllId.push(activeArr);
            this.selectedList.push(activeArr);
          }
        }

        $('#checkAll-' + column).prop('checked', true);
        $('.check-sub-' + column).prop('checked', true);
      }

    } else {
      let checkboxChecked = $('#check-' + row.id).is(':checked');
      if (checkboxChecked) {
        // const index: number = this.tasksAllId.findIndex(x => {
        //   return x.task_assignment_id == row.id;
        // });
        const index: number = this.selectedList.findIndex(x => {
          return x.task_assignment_id == row.id;
        });
        if (index !== -1) {
          // this.tasksAllId.splice(index, 1);
          this.selectedList.splice(index, 1);
        }
        $('#check-' + row.id).prop('checked', false);

        $('.task-' + row.id).removeClass('active');

      } else {
        $('.task-' + row.id).addClass('active');

        let taskAction = 'start';

        if (column == 'wip') {
          taskAction = 'done';
        }

        let activeArr = {
          'project_id': this.projectId,
          'task_id': row.task_id,
          'task_assignment_id': row.id,
          'task_version_id': row.task_version_id,
          'status': taskAction,
          'current_role': this.currentRole,
        };

        // this.tasksAllId.push(activeArr);
        this.selectedList.push(activeArr);

        $('#check-' + row.id).prop('checked', true);
      }
      $('#check-' + row.id).focus();
    }
  }

  getEpisodes(query) {
    this.dataService.getListData('episodesItems', { project_id: this.projectId, 'fromCreateTask': true, 'fromTaskTrack': true, 'query': query['query'], 'role': this.currentRole })
      .subscribe((success) => {
        this.episodes = success.data;
      }, (rejected) => {

      });
  }

  getSequences(query) {
    this.dataService.getListData('sequencesItems', { project_id: this.projectId, 'fromTaskTrack': true, 'query': query['query'], 'role': this.currentRole })
      .subscribe((success) => {
        this.sequences = success.data;
      }, (rejected) => {

      });
  }

  getAssets(query) {
    this.dataService.getListData('assetsItems', { project_id: this.projectId, 'fromTaskTrack': true, 'query': query['query'], 'role': this.currentRole })
      .subscribe((success) => {
        this.assets = success.data;
      }, (rejected) => {

      });
  }

  getShots(query) {
    this.dataService.getListData('shotsItems', { project_id: this.projectId, 'fromTasks': true, 'fromTaskTrack': true, 'query': query['query'], 'role': this.currentRole })
      .subscribe((success) => {
        this.shots = success.data;
      }, (rejected) => {

      });
  }

  getDepartments(query) {
    this.dataService.getListData('getDepartments', { projectId: this.projectId, 'query': query['query'], fromTaskTrack: true })
      .subscribe((success) => {
        this.departments = success.data;
      }, (rejected) => {

      });
  }

  getProjectSettings() {
    this.dataService.getDataById('getProjectSettings/' + this.projectId)
      .subscribe((success) => {
        this.is_external_review = success.data.is_external_review;
        localStorage.setItem('isexternalReview', JSON.stringify(this.is_external_review));
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.toastrService.notify('warn', '', err.message);
          this.router.navigate(['/dashboard']);
        }
      });
  }

  multipleFilesUpdated(files) {
    let i;
    let multipleFiles_temp = [];
    for (i = 0; i < files.length; i++) {

      let file = files[i];
      let temp_file = {};

      if (file) {
        var reader = new FileReader();

        temp_file['image_name'] = file.name;
        temp_file['image_display_name'] = file.name;
        temp_file['image_type'] = file.type;

        reader.onload = (event: any) => {

          if (this.getAllSettingResponse.is_aws_s3_enable == 0) {
            let size = (event.total / 1024 / 1024).toFixed(2);
            let fileSize = Number(size);
            if (fileSize > 3) {
              this.fileSizeError = true;
            }
          }
          temp_file['url'] = event.target.result;
          if (temp_file['image_type'] != 'image/png' && temp_file['image_type'] != 'image/jpg' && temp_file['image_type'] != 'image/jpeg' && temp_file['image_type'] != 'image/gif') {
            temp_file['preview_src'] = './assets/images/default/attechment.png';
          } else {
            temp_file['preview_src'] = temp_file['url'];
          }
        }
        reader.readAsDataURL(file);

        setTimeout(() => {
          multipleFiles_temp.push(temp_file);
        }, 0);
      }
    }

    setTimeout(() => {
      if (this.isFirstTime) {
        this.multipleFiles = this.multipleFiles.concat(multipleFiles_temp);
        this.isFirstTime = false;
      } else {
        this.multipleFiles = this.TempMultipleFiles.concat(multipleFiles_temp);
      }
    }, 0);
  }

  // on task played
  onTaskPlayed() {
    console.log("played");
  }

  unlinkMultiImage(index, event) {
    event.stopPropagation();
    this.multipleFiles.splice(index, 1);
    this.fileSizeError = false;
  }

  trackByuserTaskListPipeline(index, item) {
    return item.id;
  }

  trackByuserTaskListActive(index, item) {
    return item.id;
  }

  trackByuserTaskListWIP(index, item) {
    return item.id;
  }

  trackByuserTaskListUnderReview(index, item) {
    return item.id;
  }

  trackByuserTaskListCompleted(index, item) {
    return item.id;
  }

  private intervalId: any; // Stores the interval ID for updating duration

  // To clear all intervals
  clearAllIntervals() {
    // Uncomment the below code if start/pause/send for review buttons not clearing intervals

    // for (const intervalId of this.intervalIdsArray) {
    //   clearInterval(intervalId);
    // }

  }

  // To run an active task
  updateTimeDuration(task, onChangeTable?) {

    if (task.is_running == '1') {

      this.runningTime = task.total_duration !== "" ? task.total_duration : '00:00:00';

      if (onChangeTable) {
        const intervalId: any = setInterval(() => {
          // console.log(task.resume)
          const reviewDate = task.resume != null ? (new Date(task.resume)) : (new Date(task.start));
          const currentTime = new Date();
          // const timeDiff = currentTime.getTime() - reviewDate.getTime();
          let time_diff: any;
          if (!isNaN(currentTime.getTime()) && !isNaN(reviewDate.getTime())) {
            // Calculate the time difference in milliseconds
            const timeDifference = currentTime.getTime() - reviewDate.getTime();

            // Convert milliseconds to days
            time_diff = Math.floor(timeDifference / (1000 * 3600 * 24));
            let seconds = Math.floor((timeDifference / 1000) % 60);
            let minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
            let hours = Math.floor(timeDifference / (1000 * 60 * 60));
            // console.log({hr: hours, mn: minutes, sec: seconds});

            const totalPreviousTimeParts = task.total_duration !== "" ? (task.total_duration.split(':')) : ['00', '00', '00'];
            let totalHours = parseInt(totalPreviousTimeParts[0]);
            let totalMinutes = parseInt(totalPreviousTimeParts[1]);
            let totalSeconds = parseInt(totalPreviousTimeParts[2]);


            let rt_hrs = hours - 5 + totalHours;  // ( 5Hr ) difference because of the UTC time
            let rt_mins = minutes < 30 ? (minutes + 30 + totalMinutes) : (minutes - 30 + totalMinutes); // ( 30min ) difference because of the UTC time
            // ( 5Hr, 30min ) difference because of the UTC time
            let rt_secs = seconds + totalSeconds;

            if (rt_secs >= 60) {
              rt_mins += 1;
              rt_secs %= 60;
            }
            if (rt_mins >= 60) {
              rt_hrs += 1;
              rt_mins %= 60;
            }

            // this.updateTimeDuration(task,true);

            this.runningTime = `${rt_hrs.toString().padStart(2, '0')}:${rt_mins.toString().padStart(2, '0')}:${rt_secs.toString().padStart(2, '0')}`;
          } else {
            this.runningTime = task.total_duration != "" ? task.total_duration : "00:00:00";
            time_diff = null; // Invalid input, result is null
          }

        }, 1000);
        this.intervalIdsArray.push(intervalId);
        // code to calculate difference from review date & current date and start timer
        // this.updateDuration(task);
        return;
      }

      const intervalId: any = setInterval(() => {
        // Your interval logic here
        // console.log(this.runningTime);

        let live_time = this.runningTime.split(':');
        let hr_live = parseInt(live_time[0]);
        let min_live = parseInt(live_time[1]);
        let sec_live = parseInt(live_time[2]);

        sec_live++;

        if (sec_live >= 60) {
          min_live += 1;
          sec_live = 0;
        }

        if (min_live >= 60) {
          hr_live += 1;
          min_live = 0;
        }

        this.runningTime = `${hr_live.toString().padStart(2, '0')}:${min_live.toString().padStart(2, '0')}:${sec_live.toString().padStart(2, '0')}`;

        // console.log({hour: hr_live, minute: min_live, second:sec_live});

      }, 1000); // Change the interval time as needed

      this.intervalIdsArray.push(intervalId);
    }
    else {
      this.runningTime = task.total_duration;
    }


  }

  updateDuration(task) {
    this.intervalId = setInterval(() => {
      const reviewDate = new Date(task.resume);
      const currentTime = new Date();
      const timeDiff = currentTime.getTime() - reviewDate.getTime();
      const seconds = Math.floor(timeDiff / 1000);
      const formattedDuration = this.secondsToHms(seconds);
      // Parse the current live time (00:04:51) into hours, minutes, and seconds
      const liveTimeParts = formattedDuration.split(':');
      const liveHours = parseInt(liveTimeParts[0]);
      const liveMinutes = parseInt(liveTimeParts[1]);
      const liveSeconds = parseInt(liveTimeParts[2]);
      // Parse the previous total time (00:17:50) into hours, minutes, and seconds
      const totalPreviousTimeParts = task.total_duration.split(':');
      const totalHours = parseInt(totalPreviousTimeParts[0]);
      const totalMinutes = parseInt(totalPreviousTimeParts[1]);
      const totalSeconds = parseInt(totalPreviousTimeParts[2]);

      // Calculate the new total time
      var newTotalHours = liveHours + totalHours;
      var newTotalMinutes = liveMinutes + totalMinutes;
      var newTotalSeconds = liveSeconds + totalSeconds;

      // Adjust for minutes and hours if necessary
      if (newTotalSeconds >= 60) {
        newTotalMinutes += Math.floor(newTotalSeconds / 60);
        newTotalSeconds %= 60;
      }
      if (newTotalMinutes >= 60) {
        newTotalHours += Math.floor(newTotalMinutes / 60);
        newTotalMinutes %= 60;
      }
      // Format the new total time as HH:mm:ss
      this.runningTime = `${newTotalHours.toString().padStart(2, '0')}:${newTotalMinutes.toString().padStart(2, '0')}:${newTotalSeconds.toString().padStart(2, '0')}`;
      task.livetime = `${newTotalHours.toString().padStart(2, '0')}:${newTotalMinutes.toString().padStart(2, '0')}:${newTotalSeconds.toString().padStart(2, '0')}`;
    }, 1000); // Update duration every second
    this.cd.markForCheck();
    return task.livetime;
  }

  private secondsToHms(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  // To show default thumbnail icon
  onImageError(event) {
    event.target.src = './assets/images/moviethumnail.png';
  }

  // to show advanved search
  showAdvanceSearch() {
    this.isAdvanceSearchEnabled = !this.isAdvanceSearchEnabled;
  }


  // On sorting
  onSort(event) {

  }

  // For user events over datatable cells
  onUserEvent(event) {
    if (event.type == "click") {
      const row = event.row;
      const column = event.column;

    }
  }

  async onPageChange(event) {
    // console.log('page changed');
    this.isLoading = true;
    this.openedPage = event.offset;
    this.pagePayload.page = event.offset * this.pageSize;
    this.config.start = this.pagePayload.page;

    this.page = event.offset + 1;
    // console.log(this.page);
    await this.onChangeTable(this.config, { page: this.page, itemsPerPage: this.itemsPerPage });
    // this.isLoading = false;
  }

  // for setting datatable columns
  setDatatableColumns() {

    let cellTemplate: any = {};
    setTimeout(() => {
      cellTemplate = {
        idsT: this.idsT,
        running: this.running,
        timeLog: this.timeLog,
        imageIcon: this.imageIcon,
        taskThumbnail: this.taskThumbnail,
        info: this.info,
        wbdays: this.wbdays,
        status: this.status,
      };

      this.dataTableColumns = [];
      // console.log(cellTemplate);
      this.dataTableColumns = columns(this.currentRole, this.activeTab, cellTemplate);
      // console.log(this.activeTab);
      (this.currentRole=='vendor' && (this.activeTab=='ready' || this.activeTab=='wip')) ? this.dataTableColumns.unshift(this.checkboxColumn) :'';

      // console.log(this.dataTableColumns);
    });
  }

  // on side window opened
  onOpened(event?) {

  }

  // on side window closed
  onClosed(event?) {
    this.selectedList = [];
  }

  // To show modal
  openModal(template: TemplateRef<any>, data) {

    this.onShownLead(data);

    this.bsModalRef = this.modalService.show(template);
  }

  // For row identity by Id
  getId(row) {
    // return row.id;
    return row.task_id;
  }

  // datatable displaycheck property
  displayCheck(row) {
    if (row) {
      return true;
    }
  }

  // On select event (Only in bucket)
  onSelect({ selected }) {

    // console.log(selected);
    const tempSelected = selected.map(obj => ({ 'project_id': obj.project_id, 'task_id':obj.task_id, 'task_assignment_id': (obj.taskAssignmentIds || obj.task_assignment_id ), 'task_version_id':obj.task_version_id, 'status':this.activeTab=='ready'?'start':'done', 'current_role':this.currentRole }));
    this.selectedList = tempSelected;
    // this.selectedList = selected;
    // console.log(tempSelected );
    console.log(selected, this.selectedList );
  }

  // For row identity by Id
  getRowClass(row) {
    if(row.is_rej_parent){
      return 'rejected-parent';
    }
  }

}
