import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-popup',
  templateUrl: './confirmation-popup.component.html',
  styleUrls: ['./confirmation-popup.component.css'],
})
export class ConfirmationPopupComponent implements OnInit {
  title: string;
  message: string;
  onConfirmAction: EventEmitter<any> = new EventEmitter<any>();


  constructor(
    public modalRef: BsModalRef
  ) { }

  ngOnInit() {
  }


  onCancel() {
    this.modalRef.hide();
    // You can perform additional actions on cancel if needed
  }

  onConfirm() {
    // Emit an event when the confirmation button is clicked
    this.onConfirmAction.emit();
    this.modalRef.hide();
  }

}
