import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import * as moment from 'moment';
import * as _ from "lodash";
declare var $: any;
import { CommonJs } from '../../../common/common-js';

import { DatatableService } from '../../../services/datatable/datatable.service';
import { ProfileComponent } from '../../settings/profile/profile.component';
import { ChangePasswordComponent } from '../../settings/change-password/change-password.component';
//import { ListsComponent } from '../../settings/lists/lists.component';
// import { BrandingComponent } from '../../settings/branding/branding.component';

@Component({
	selector: 'app-superadmin-settings',
	templateUrl: './superadmin-settings.component.html',
	styleUrls: ['./superadmin-settings.component.css']
})
export class SuperadminSettingsComponent implements OnInit {
	entityName = 'Settings';
	entityNameSingular = 'Setting';
	apiRoutePrefix = 'superadmin-settings';

	lists: Lists;
	focused_list: string = '';
	ListSaveButtonText: string = 'SAVE';

	data: any;
	myform: any;
	settings: Settings;
	errors: any;
	focused: string = 'focused';
	SaveButtonText: string = 'SAVE';
	settingsAll: any = JSON.parse(localStorage.getItem('settings'));
	/*loginTypes:any;*/
	formattedDate: string;

	loginTypes = [
		{ id: 'e', name: 'Email' },
		{ id: 'u', name: 'Username' },
		{ id: 'eu', name: 'Email / Username' },
	];

	_list_opened: boolean = false;
	_loadListSideWindow: boolean = false;
	_ng_sidebar_data = {};

	public ActionButtons: any;
	public rows = this.datatableService.rows;
	public columns: Array<any> = [
		{ title: '#ID', name: 'id', className: 'hide-this' },
		{ title: 'List Name', name: 'list_name_html', width: '85%' },
		{ title: 'Items Count', name: 'items_count', className: 'item-count', width: '12%' },
		{ title: 'Type', name: 'type', width: '3%' },
	];

	busy: Subscription;
	public FilterColumns: Array<any> = ['list_name'];
	public config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: '' },
		className: ['table-striped', 'table-bordered']
	};
	public maxSize = this.datatableService.maxSize;
	public page: number = this.datatableService.page;
	public itemsPerPage: number = this.datatableService.itemsPerPage;
	public length = this.datatableService.length;

	noRecordFound: boolean = false;
	noRecordFoundMsg: any = 'Sorry, no records found!';


	//settings-links
	changeComponent: any;
	constructor(private titleService: Title,
		private dataService: DataService,
		public commonJs: CommonJs,
		private datatableService: DatatableService) {
		this.datatableService.setParam(this.columns, 'columns');
		this.datatableService.setParam(this.config, 'config');
		this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
		this.datatableService.setParam('listList', 'serverUrl');
	}

	ngOnInit() {
		// this.noRecordFoundMsg = (this.settingsAll.no_records != '') ? this.settingsAll.no_records : this.noRecordFoundMsg;
		// this.datatableService.setParam(this.settingsAll.default_pagination, 'itemsPerPage');
		this.onChangeTable(this.config);

		this.settings = {
			title: this.settingsAll.title,
			login_with: this.settingsAll.login_with,
			remember_me: this.settingsAll.remember_me,
			forgot_password: this.settingsAll.forgot_password,
		};

		$.AdminBSB.input.activate();
		this.lists = { list_name: '', old_list_name: '' };
		this.titleService.setTitle(this.entityName);
	}

	public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
		$('#checkAll').prop('checked', false);
		this.busy = this.datatableService.onChangeTable(this.config, page)
			.subscribe((success) => {
				this.rows = success.data;
				this.length = success.totalElements;
				this.noRecordFound = true;
			});
	}

	editList(old_list_name) {
		this.focused_list = 'focused';
		this.lists = { 'list_name': old_list_name, 'old_list_name': old_list_name };
	}

	changePage(page) {
		this.changeComponent = page;
		if (page == 'lists') {
			this.titleService.setTitle('Lists');
		}
	}

	closeSettings() {
		this.changeComponent = '';
	}

	conform(old_list_name) {
		let that = this;
		swal.fire({
			title: 'Delete List',
			text: "Are you sure you want to delete this List?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Delete",
			cancelButtonText: "Cancel",
		}).then((result) => {
			if (result.value) {
				that.dataService.deleteData('listDestroy', { old_list_name })
					.subscribe((success) => {
						that.onChangeTable(that.config);
					}, (rejected) => {

					});
			} else {
				//console.log('cancelled');
			}
		}
		)
	}

	onSubmit(form: NgForm) {
		this.SaveButtonText = 'SAVING';
		this.dataService.updateData(this.apiRoutePrefix, form.value)
			.subscribe((success) => {
				this.SaveButtonText = 'SAVED';
				setTimeout(() => {
					this.SaveButtonText = 'SAVE';
					let getSettings = localStorage.getItem("settings");
					if (!getSettings) {
						this.commonJs.getAllSettings();
					}
				}, 2);

			}, (error) => {
				if (error.error) {
					var err = error.error;
					this.errors = err.error;
				}
			});
	}

	onSubmitList(formList: NgForm) {
		this.ListSaveButtonText = 'SAVING';
		if (formList.value.old_list_name != '') {
			this.dataService.updateData('list/' + formList.value.old_list_name, formList.value)
				.subscribe((success) => {
					this.ListSaveButtonText = 'SAVED';
					setTimeout(() => {
						this.ListSaveButtonText = 'SAVE';
						this.lists = { list_name: '', old_list_name: '' };
						this.focused_list = 'focuse';
						this.onChangeTable(this.config);
					}, 2);

				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
					}
				});

		} else {
			this.dataService.storeData('list', formList.value)
				.subscribe((success) => {
					this.ListSaveButtonText = 'SAVED';
					setTimeout(() => {
						this.ListSaveButtonText = 'SAVE';
						this.lists = { list_name: '', old_list_name: '' };
						this.focused_list = 'focuse';
						this.onChangeTable(this.config);
					}, 2);
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
					}
				});
		}
	}

	onSubmitAdminSettings(ads: NgForm) {
	}

	openSideWindow(mode, id, type, editable) {
		//this._list_opened = true;
		if (id != undefined && id != '') {
			this._ng_sidebar_data['list_name'] = id;
		}
		this._ng_sidebar_data['mode'] = mode;
		this._ng_sidebar_data['type'] = type;
		this._ng_sidebar_data['editable'] = editable;
		this._list_opened = true;
	}

	onOpenStart() { }

	onOpened() {
		this._loadListSideWindow = true;
	}

	onClosed() {
		this._loadListSideWindow = false;
	}

	closeCreateListItemAdmins($event) {
		this.onChangeTable(this.config);
		if ($event.id == 2) {
			this._loadListSideWindow = false;
			this._list_opened = false;
		}
	}

}
interface Settings {
	title: string,
	login_with: string,
	remember_me: number,
	forgot_password: number
}

interface Lists {
	list_name: string,
	old_list_name: string
}
