import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy, Location, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { AuthService } from './services/auth/auth.service';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { DataService } from './services/data.service';
import { CommonJs } from './common/common-js';
import { AppConfigs } from './common/app-configs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DatatableService } from './services/datatable/datatable.service';
import { ToastrService } from './services/toastr.service';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { SidebarModule } from 'ng-sidebar';
import { LoginComponent } from './components/login/login.component';
import { SharedModule } from './components/shared/shared.module';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { DateHelpModalComponent } from './date-help-modal/date-help-modal.component';
import { DragulaModule } from 'ng2-dragula';
import { UnauthorizedComponent } from './common/unauthorized/unauthorized.component';
import { ProjectHeaderComponent } from './common/project-header/project-header.component';

import { ColorPickerModule } from 'ngx-color-picker';
import { Daterangepicker } from 'ng2-daterangepicker';
import { AutoCompleteModule, EditorModule, GrowlModule, MessageService } from 'primeng/primeng';
import { SuperadminDashboardComponent } from './components/superadmin-dashboard/superadmin-dashboard.component';
import { ChartsModule } from 'ng2-charts';
import 'chart.piecelabel.js';
import { SlickModule } from 'ngx-slick';
import { HeadcountComponent } from './components/headcount/headcount.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { environment } from '../environments/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuth, AngularFireAuthModule } from '@angular/fire/auth';
let production = true;
const isFirebaseEnabled = environment.isFirebaseEnabled;
if (environment.prodType == 'offline') {
  production = false;
}
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSpinnerModule } from "ngx-spinner";

//Push notification
import { NotificationsService } from './services/notifications/notifications.service';
import { ThumbnailDirective } from './directives/thumbnail.directive';
import { TaskDetailDashboardComponent } from './task-detail-dashboard/task-detail-dashboard.component';
import { VideoService } from './services/video-annotation/video.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { WINDOW_PROVIDERS } from './services/window.service';
import { ToastModule } from 'primeng/toast';
import { CustomInterceptor } from './interceptor/custom-interceptor';
import { TasksModule } from './components/projects/tasks/tasks.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { UsersModule } from './components/users/users.module';
import { ProjectsModule } from './components/projects/projects.module';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MentionModule } from 'angular-mentions';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { ConfirmationPopupComponent } from './common/confirmation-popup/confirmation-popup.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

// import { HideDataDirective } from './directives/hide-data.directive';;


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    DateHelpModalComponent,
    LoginComponent,
    PageNotFoundComponent,
    ForgotPasswordComponent,
    UnauthorizedComponent,
    ProjectHeaderComponent,
    SuperadminDashboardComponent,
    HeadcountComponent,
    ThumbnailDirective,
    TaskDetailDashboardComponent,
    ConfirmationPopupComponent,
    // HideDataDirective,

  ],
  imports: [
    TasksModule,
    UsersModule,
    ProjectsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ModalModule.forRoot(),
    FormsModule,
    Ng2TableModule,
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    ToastModule,
    SidebarModule.forRoot(),
    Daterangepicker,
    AutoCompleteModule,
    GrowlModule,
    ChartsModule,
    DragulaModule,
    SlickModule.forRoot(),
    TimepickerModule.forRoot(),
    (isFirebaseEnabled) ? AngularFireModule.initializeApp(environment.firebase) : [],
    (isFirebaseEnabled) ? AngularFireDatabaseModule : [],
    LazyLoadImageModule,
    NgxSpinnerModule,
    AngularFireAuthModule,
    AccordionModule.forRoot(),
    ColorPickerModule,
    InfiniteScrollModule,
    NgxDatatableModule,
    ProgressbarModule.forRoot(),
    ProgressSpinnerModule,
    MentionModule,
    EditorModule,
    DialogModule,
    ButtonModule,
    NgxDocViewerModule
  ],

  entryComponents: [
    DateHelpModalComponent,
    ConfirmationPopupComponent,
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MessageService,
    AngularFireAuth,
    NotificationsService,
    DataService,
    VideoService,
    AuthService,
    AuthGuardService,
    CommonJs,
    AppConfigs,
           /*Activitylog*/ DatatableService,
    ToastrService,
    DatePipe,
    //InfiniteScrollService,
    Location, { provide: LocationStrategy, useClass: HashLocationStrategy },
    AppComponent,
    WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // constructor(@Optional() module: AngularFireModule){
  //     //@Optional() module: AngularFireModule.initializeApp(environment.firebase)
  //     //AngularFireModule.initializeApp(environment.firebase);
  // }
}
