import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
import * as moment from 'moment';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as _ from "lodash";
declare var $: any;

@Component({
	selector: 'create-sequence',
	templateUrl: './create-sequence.component.html',
	styleUrls: ['./create-sequence.component.css']
})
export class CreateSequenceComponent implements OnInit {
	entityName = 'Sequences';
	entityNameSingular = 'Sequence';
	apiRoutePrefix = 'sequences';
	dirName = 'sequences';
	apiRoutePrefixListItem = 'listItem';

	clientCode = localStorage.getItem('client_code');

	@Input('attr.data') dataHtml: any;
	@ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
	@ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
	@Output() closeCreateSequence = new EventEmitter();

	data: any;
	myform: any;
	onClose: any;
	sequences: { [index: string]: any } = {};

	errors: { [index: string]: any } = {};
	focused: string = '';
	SaveButtonText: string = 'SAVE';
	isMessgaeMode: any;
	settingsAll: any = JSON.parse(localStorage.getItem('settings'));
	projectId = '';//this.commonJs.getLocalStorage('projectId');
	isDeleteMode: boolean = false;
	deleteId: number;
	deleteName: string = '';
	isDisplyFrm: boolean = false;
	alertMessage: string = '';
	DeleteButtonText: string = 'DELETE';
	DateFormat: any;
	sequencenumber: any;
	url: any;
	thumbnail_preview_src: string = '';
	thumbnail: string;
	thumbnail_name: string;
	thumbnail_type: string;
	thumbnail_old: string;
	status = [{ "id": 1, "name": "Active" }, { "id": 0, "name": "Inactive" }];
	episodes: Array<any>;
	complexity: Array<any>;
	lead: Array<any>;
	producer: Array<any>;
	isEpisodicSeries: boolean = false;

	uploadUrl = this.commonJs.getUploadUrl();

	reuseAssets: Array<any>;
	freshAssets: Array<any>;

	freshAssetsArr = [];
	reuseAssetsArr = [];

	assets: Array<any>;

	isSaveDisabled: boolean = false;

	dropdownSettings: any = {};
	dropdownSettingsFressAndReuse: any = {};
	public files: NgxFileDropEntry[] = [];

	constructor(
		private dataService: DataService,
		private toastrService: ToastrService,
		public commonJs: CommonJs,
		public appConfigs: AppConfigs,
	) { }

	ngOnInit() {
		if (this.dataHtml != undefined) {
			this.data = this.dataHtml;
		}
		this.projectId = this.data.projectId;
		$.AdminBSB.input.activate();
		this.subngOnInit();
		this.DateFormat = this.settingsAll.date_format;
		this.getAssetsList();


		this.dropdownSettings = {
			singleSelection: false,
			idField: "id",
			textField: "code",
			selectAllText: "Select All",
			unSelectAllText: "UnSelect All",
			itemsShowLimit: 2,
			allowSearchFilter: true,
			noDataAvailablePlaceholderText: 'No data available',
			searchPlaceholderText: 'Search assets',
			maxHeight: 90,
			autoPosition: false,
		};

		// ! Multi Dropdown Setting
		this.dropdownSettingsFressAndReuse = {
			singleSelection: false,
			idField: "asset_id",
			textField: "code",
			selectAllText: "Select All",
			unSelectAllText: "UnSelect All",
			itemsShowLimit: 2,
			allowSearchFilter: true,
			noDataAvailablePlaceholderText: 'No data available',
			searchPlaceholderText: 'Search assets',
			maxHeight: 90,
			autoPosition: false,
			groupBy: "asset_id"
		};
	}

	// ! Get FreshAssets
	getFressAssets() {
		this.dataService.getListData('assetsItems', { project_id: this.projectId, episode_id: this.data.episodeid, fromSequence: true, isFresh: true, 'reuseArr': this.reuseAssetsArr })
			.subscribe((success) => {
				this.freshAssets = success.data;
			}, (rejected) => {

			});
	}

	//! Get ReuseAssets
	getReuseAssets() {
		this.dataService.getListData('assetsItems', { project_id: this.projectId, episode_id: this.data.episodeid, fromSequence: true, isFresh: false, 'freshArr': this.freshAssetsArr })
			.subscribe((success) => {
				this.reuseAssets = success.data;
			}, (rejected) => {

			});
	}

	getAssetsList() {
		this.dataService.getListData('assetsItems', { project_id: this.projectId })
			.subscribe((success) => {
				this.assets = success.data;
			}, (rejected) => {

			});
	}

	subngOnInit() {
		this.sequences = {
			project_id: this.projectId,
			episode_id: 0,
			name: '',
			input_path: '',
			folder_path: '',
			number: '',
			lead: '',
			producer: '',
			description: '',
			assets: '',
			status: 1,
			complexity: '',
			projected_shots: '',
			projected_seconds: '',
			thumbnail: '',
			thumbnail_name: '',
			thumbnail_type: '',
			thumbnail_old: '',
			thumbnail_display_name: '',
		};
		this.getProjectsById(this.projectId);
		if (this.data.mode == 'create') {
			this.getFressAssets();
			this.getReuseAssets();
			this.dataService.getListData('getLastRecordProject', { 'table': 'episodes', 'project_id': this.projectId, name: 'addSequence', 'isActive': this.data.isActive })
				.subscribe((success) => {
					if (success.data != null) {
						let eId;
						if (this.data.episode) {
							eId = this.data.episode_id;
						} else {
							eId = success.data.id;
						}
						this.dataService.getListData('getLastRecordProjectEpisode', { 'table': this.apiRoutePrefix, 'project_id': this.projectId, 'episode_id': eId })
							.subscribe((success1) => {
								if (this.data.episode) {
									if (success1.data != null) {
										this.dataService.getDataById('getEpisodeById/' + eId)
											.subscribe((success2) => {
												this.sequences.episode_id = { "id": success2.data.id, "name": success2.data.name };
											});
									} else {
										this.dataService.getDataById('getEpisodeById/' + eId)
											.subscribe((success2) => {
												this.sequences.episode_id = { "id": success2.data.id, "name": success2.data.name };
											});
									}
								} else {
									this.sequences.episode_id = { "id": success.data.id, "name": success.data.name };
								}
								if (success1.data != null) {
									this.sequences.number = this.appConfigs.autoincrementStr(success1.data.number);
								} else {

									this.sequences.number = "sequence1";
								}
							}, (rejected) => {

							});

					} else {
						if (this.isEpisodicSeries == false) {
							this.dataService.getListData('getLastRecordProject', { table: 'sequences', project_id: this.projectId, name: 'nonEpisodic' })
								.subscribe((success) => {
									if (success.data != null) {
										this.sequences.number = this.appConfigs.autoincrementStr(success.data.number);
									} else {

										this.sequences.number = "sequence1";
									}
								}, (rejected) => {

								});
						}
						else {
							this.sequences.number = "sequence1";
						}
					}

				}, (rejected) => {

				});

		}
		if (this.data.sequenceid && (this.data.mode == 'edit' || this.data.mode == 'view')) {
			this.focused = "focused";
			this.dataService.getDataById(this.apiRoutePrefix + '/' + this.data.sequenceid + '/edit')
				.subscribe((success) => {
					//console.log(success.data);
					this.sequences = success.data;
					if (this.sequences.thumbnail) {
						this.thumbnail_old = this.sequences.thumbnail;
						let fileExtension = this.sequences.thumbnail.split('.').pop();
						if (fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg' && fileExtension != 'gif') {
							this.thumbnail_preview_src = './assets/images/default/attechment.png';
						} else {
							this.thumbnail_preview_src = this.uploadUrl + this.dirName + '/' + this.sequences.thumbnail;
						}
					}
					if (this.sequences.thumbnail_display_name) {
						this.thumbnail_name = this.sequences.thumbnail_display_name;
					}

					let that = this;
					_.forEach(this.sequences.freshAssets, function (value, key) {
						// that.freshAssetsArr.push(value['asset_id']);
						that.freshAssetsArr.push(value['asset_id']);
					});
					_.forEach(this.sequences.reuseAssets, function (value, key) {
						// that.freshAssetsArr.push(value['asset_id']);
						that.reuseAssetsArr.push(value['asset_id']);
					});
					this.getFressAssets();
					this.getReuseAssets();

				}, (rejected) => {

					this.SaveButtonText = "SAVE";
				});
		}

	}

	search(query, column) {
		if (column == 'episodes') {
			this.dataService.getListData('episodesItems', { project_id: this.projectId, 'query': query['query'] })
				.subscribe((success) => {
					this.episodes = success.data;
				}, (rejected) => {

				});
		} else if (column == 'complexity') {
			this.complexity = this.appConfigs.sht_ast_complexity.map(v => { return v });
		} else if (column == 'lead') {
			this.getProjectLead("0", query);
		} else if (column == 'producer') {
			this.getProjectProducer("0", query);
		} else if (column == 'reuseAssets') {
			this.dataService.getListData('assetsItems', { project_id: this.projectId, episode_id: this.data.episodeid, fromSequence: true, isFresh: false, 'query': query['query'], 'freshArr': this.freshAssetsArr })
				.subscribe((success) => {
					this.reuseAssets = success.data;
				}, (rejected) => {

				});
		} else if (column == 'freshAssets') {
			this.dataService.getListData('assetsItems', { project_id: this.projectId, episode_id: this.data.episodeid, fromSequence: true, isFresh: true, 'query': query['query'], 'reuseArr': this.reuseAssetsArr })
				.subscribe((success) => {
					this.freshAssets = success.data;
				}, (rejected) => {

				});
		} else if (column == 'assets') {
			this.dataService.getListData('assetsItems', { project_id: this.projectId, 'query': query['query'] })
				.subscribe((success) => {
					this.assets = success.data;
				}, (rejected) => {

				});
		}

	}


	onSubmit(form: NgForm) {
		this.isSaveDisabled = true;
		form.value.project_id = this.projectId;
		if (this.url != undefined) {
			form.value.thumbnail = this.url;
		}
		form.value.thumbnail_name = this.thumbnail_name;
		form.value.thumbnail_old = this.thumbnail_old;
		if (form.value.episode_id) {
			form.value.episode_id = this.sequences.episode_id.id;
		}
		if (form.value.lead) {
			form.value.lead = this.sequences.lead.id;
		}
		if (form.value.producer) {
			form.value.producer = this.sequences.producer.id;
		}
		if (form.value.complexity) {
			form.value.complexity = this.sequences.complexity.id;
		}
		// if(form.value.status){
		// 	form.value.status= form.value.status.id;
		// }

		if (form.value.reuseAssets && form.value.reuseAssets != undefined) {
			var arr = "";
			_.forEach(this.sequences.reuseAssets, function (value) {
				//arr += "|";
				arr += value['asset_id'];
				// arr += value['id'];
				//arr += "|";
				arr += ",";
			});
			form.value.reuseAssets = arr.slice(0, -1);
		}

		if (form.value.freshAssets && form.value.freshAssets != undefined) {
			var arr = "";
			_.forEach(this.sequences.freshAssets, function (value) {
				//arr += "|";
				arr += value['asset_id'];
				// arr += value['id'];
				//arr += "|";
				arr += ",";
			});
			form.value.freshAssets = arr.slice(0, -1);
		}

		if (form.value.assets && form.value.assets != undefined) {
			var arr = "";
			_.forEach(this.sequences.assets, function (value) {
				arr += "|";
				arr += value['id'];
				arr += "|";
				arr += ",";
			});
			form.value.assets = arr.slice(0, -1);
		}

		form.value.isEpisodicSeries = this.isEpisodicSeries;
		this.SaveButtonText = 'SAVING';
		if (this.data.sequenceid && this.data.mode == 'edit') {
			form.value.id = this.data.sequenceid;
			this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.sequenceid, form.value)
				.subscribe((success) => {
					// Save effect within button
					this.SaveButtonText = 'SAVED';
					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.isSaveDisabled = false;
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
						this.SaveButtonText = 'SAVE';
					}
					this.isSaveDisabled = false;
				});
		} else {
			this.dataService.storeData(this.apiRoutePrefix, form.value)
				.subscribe((success) => {
					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.isSaveDisabled = false;
				}, (error) => {
					//this.alertMessage = 'Something went wrong, Please try again!';
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
						this.SaveButtonText = 'SAVE';
					}
					this.isSaveDisabled = false;
				});
		}
	}
	getProjectsById(id) {
		// this.projectId = id;
		// this.dataService.getDataById('getProjectById/' + id)
		// 	.subscribe((success) => {
		this.isEpisodicSeries = JSON.parse(localStorage.getItem('localStorageProjectData')).episodic_series;
		// }, (error) => {
		// 	//console.log(error);
		// });
	}
	getUsers(id = "0", query = '') {
		this.dataService.getOtherData('getUsers', { id: id, 'query': query })
			.subscribe((success) => {
				this.lead = success.data;
				this.producer = success.data;
			}, (rejected) => {

			});
	}

	getProjectLead(id = "0", query = '') {
		this.dataService.getOtherData('getProjectLead', { id: id, project_id: this.projectId, 'query': query['query'] })
			.subscribe((success) => {
				this.lead = success.data;
			}, (rejected) => {

			});
	}

	getProjectProducer(id = "0", query = '') {
		this.dataService.getOtherData('getProjectProducer', { id: id, project_id: this.projectId, 'query': query['query'] })
			.subscribe((success) => {
				this.producer = success.data;
			}, (rejected) => {

			});
	}

	close() {
		this.closeCreateSequence.emit();
	}

	public dropped(files: NgxFileDropEntry[]) {
		this.files = files;
		for (const droppedFile of files) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {

					// Here you can access the real file
					//console.log(droppedFile.relativePath, file);

					var reader = new FileReader();
					this.thumbnail_name = file.name;
					this.thumbnail_type = file.type;

					reader.onload = (event: any) => {
						this.url = event.target.result;
						if (this.thumbnail_type != 'image/png' && this.thumbnail_type != 'image/jpg' && this.thumbnail_type != 'image/jpeg' && this.thumbnail_type != 'image/gif') {
							this.thumbnail_preview_src = './assets/images/default/attechment.png';
						} else {
							this.thumbnail_preview_src = this.url;
						}
					}
					reader.readAsDataURL(file);
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
				//console.log(droppedFile.relativePath, fileEntry);
			}
		}
	}

	public fileOver(event) {
		//console.log(event);
	}

	public fileLeave(event) {
		//console.log(event);
	}

	onSelectedFresh(event, type) {
		if (type == 'freshAssets') {
			if (this.freshAssetsArr != undefined) {
				this.freshAssetsArr.push(event.asset_id);
			} else {
				this.freshAssetsArr = event.asset_id;
			}
			this.getReuseAssets();
		} else {
			if (this.reuseAssetsArr != undefined) {
				this.reuseAssetsArr.push(event.asset_id);
			} else {
				this.reuseAssetsArr = event.asset_id;
			}
			this.getFressAssets();
		}

	}

	// ! On clear Fresh Assets
	onClearFresh(event, type) {
		if (type == 'freshAssets') {
			let index = this.freshAssetsArr.indexOf(event.asset_id);
			this.freshAssetsArr.splice(index, 1);
			this.getReuseAssets();
		} else {
			let index = this.reuseAssetsArr.indexOf(event.asset_id);
			this.reuseAssetsArr.splice(index, 1);
			this.getFressAssets()
		}
	}

	// ! On Select All Assets

	onSelectAll(event: any, type: string) {
		if (type == 'freshAssets') {
			this.freshAssetsArr = [];
			if (this.freshAssetsArr != undefined) {
				for (let data in event) {
					this.freshAssetsArr.push(event[data].asset_id);
				}
			} else {
				this.freshAssetsArr = event.asset_id;
			}
			this.getReuseAssets();
		} else {
			if (this.reuseAssetsArr != undefined) {
				this.reuseAssetsArr = [];
				for (let data in event) {
					this.reuseAssetsArr.push(event[data].asset_id);
				}
			} else {
				this.reuseAssetsArr = event.asset_id;
			}
			this.getFressAssets();
		}
	}

	// ! On DeSelect All Selected Assets
	onDeSelectAll(event: any, type: string) {
		if (type == 'freshAssets') {
			this.freshAssetsArr = event;
			this.getReuseAssets();
		} else {
			this.reuseAssetsArr = [];
			this.getFressAssets()
		}
	}

	unlinkImage(event) {
		event.stopPropagation();
		this.thumbnail_preview_src = '';
		this.thumbnail = '';
		this.thumbnail_name = 'unset';
		this.url = '';
	}
}
