import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { columns } from '../table-column';
import { TableColumn } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.css']
})
export class VersionComponent implements OnInit {
  @Input() taskVersionsData: any;
  public dataTableColumns: TableColumn[] = [];
  @Input() is_external_review: any;
  @Input() isLoading: boolean = true;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;
  @ViewChild('version', { static: false }) public version: TemplateRef<any>;
  @ViewChild('revisionVersion', { static: false }) public revisionVersion: TemplateRef<any>;
  @ViewChild('revisionVersionHeader', { static: false }) public revisionVersionHeader: TemplateRef<any>;
  @ViewChild('plannedStartTime', { static: false }) public plannedStartTime: TemplateRef<any>;
  @ViewChild('plannedEndTime', { static: false }) public plannedEndTime: TemplateRef<any>;

  constructor(
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    console.log(this.taskVersionsData)
    this.createColumns();
  }

  /** Create Column */
  private createColumns(): void {
    let cellTemplate: any = {
      status: this.status,
      version: this.version,
      revisionVersion: this.revisionVersion,
      revisionVersionHeader: this.revisionVersionHeader,
      plannedStartTime: this.plannedStartTime,
      plannedEndTime: this.plannedEndTime,
    };
    setTimeout(() => {
      this.dataTableColumns = columns('versionTable', cellTemplate);
      this.cd.markForCheck();
    });
  }

}
