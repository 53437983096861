import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { columns } from '../task-details/datatables/table-column';

@Component({
  selector: 'app-all-artist-task-list',
  templateUrl: './all-artist-task-list.component.html',
  styleUrls: ['./all-artist-task-list.component.css']
})
export class AllArtistTaskListComponent implements OnInit {
  @Input() type: any;
  @Input() data: any;
  @Input() userDetail: any;
  @Input() statusArrayFromDepartmentList: any;
  @Input() isLoading: boolean = true;
  public dataTableColumns: TableColumn[] = [];
  @ViewChild('info', { static: false }) public info: TemplateRef<any>;
  @ViewChild('startDate', { static: false }) public startDate: TemplateRef<any>;
  @ViewChild('endDate', { static: false }) public endDate: TemplateRef<any>;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;
  @ViewChild('department', { static: false }) public department: TemplateRef<any>;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.createColumns();
    // console.log(this.userDetail)
  }


  /** Create Column */
  private createColumns(): void {
    let cellTemplate: any = {
      info: this.info,
      startDate: this.startDate,
      endDate: this.endDate,
      status: this.status,
      department: this.department
    };
    setTimeout(() => {
      this.dataTableColumns = columns('user-task-list', cellTemplate);
    });
  }

  setBgColor(data: any) {
    const statusObject = this.statusArrayFromDepartmentList.find(status => status.name === data);
    if (statusObject) {
      return statusObject.color;
    }
    return "rgb(169, 169, 169)"
  }
}
