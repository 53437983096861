import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { NgForm } from "@angular/forms"; // , FormGroup, FormControl, Validators
import { DataService } from "../../services/data.service";
import { CommonJs } from "../../common/common-js";
import { AppConfigs } from "../../common/app-configs";
declare var $: any;
import * as _ from "lodash";

import { Renderer2 } from '@angular/core';
import { ToastrService } from "src/app/services/toastr.service";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public isSuperAdmin: boolean;
  public isStandAlone: boolean = false;
  public myform: any;
  isLoading = false;

  //public Settings:any;

  login: Login;
  errors: any;
  focused: string = "";

  loginImages: LoginImages;
  clienCode: any;
  uploadUrl = this.appConfigs.getUploadUrl();

  projectPer = {};
  projectrole = {};
  projectrolestype = {};

  constructor(
    private titleService: Title,
    public router: Router,
    public commonJs: CommonJs,
    public appConfigs: AppConfigs,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private renderer: Renderer2
  ) {
    //localStorage.setItem('token','');
    var standA = false;
    let role = localStorage.getItem("role");

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isSuperAdmin = event.url.indexOf("superadmin") !== -1;
      }
    });
    if (localStorage.getItem("token")) {
      this.router.navigate(["/dashboard"]);
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((val) => {
      this.login = {
        email: "",
        password: "",
        client_code: this.activatedRoute.snapshot.paramMap.get("access_code"),
      };
      if (this.isSuperAdmin) {
        this.clienCode = "superadmin";
      } else {
        this.clienCode =
          "ac/" + this.activatedRoute.snapshot.paramMap.get("access_code");
      }
      this.loginImages = {
        logo: "",
        logo_image: "",
        banner: "",
        banner_image: "",
      };

      this.dataService
        .getDataById("checkLoginImages/" + this.login.client_code)
        .subscribe(
          (success) => {
            this.uploadUrl = this.uploadUrl.replace("null/", "");
            if (success.data.logo_url != "") {
              this.loginImages.logo = success.data.logo_url;
              this.loginImages.logo_image = success.data.logo_image;
            }

            if (success.data.banner_url != "") {
              this.loginImages.banner = success.data.banner_url;
              this.loginImages.banner_image = success.data.banner_image;
              const element = document.querySelector('.login-wrapper');
              this.renderer.setStyle(element, 'background-image', `url(${this.bannerImage(success.data.banner_url)})`);
            }

            localStorage.setItem(
              "currentAccountImages",
              JSON.stringify(this.loginImages)
            );
          },
          (rejected) => {
            //console.log(rejected);
          }
        );
    });
    this.titleService.setTitle("Login");
    this.focused = "focused";
    this.isStandAlone = JSON.parse(localStorage.getItem("config"));
  }

  bannerImage(imageUrl: any) {
    return imageUrl;
  }

  onSubmit(form: NgForm) {
    this.isLoading = true;
    localStorage.removeItem("currentTask");
    localStorage.removeItem("projectRoles");
    localStorage.removeItem("projectRoleType");
    localStorage.removeItem("projectPermissions");
    let that = this;
    if (form.value.client_code == undefined) {
      form.value.client_code = "";
      form.value.isStandAlone = this.isStandAlone;
    }
    form.value.client_code =
      this.activatedRoute.snapshot.paramMap.get("access_code");
    form.value.isSuperAdmin = this.isSuperAdmin;

    // only offline user login
    // form.value.is_offline = false;
    // form.value.offline_key = "L8PyRXCDT5";
    //
    this.dataService.login("login", form.value).subscribe((success) => {
      if (success.success) {
        let role = success.results.user.slug;
        localStorage.setItem("token", success.results.user.Token);
        localStorage.setItem("userId", success.results.user.id);
        localStorage.setItem("user", JSON.stringify(success.results.user));
        localStorage.setItem("role", role);
        localStorage.setItem("client_code", success.results.user.client_code);
        localStorage.setItem(
          "permissions",
          JSON.stringify(success.permissions)
        );

        let st = localStorage.getItem("standalone");
        if (role != "superadmin" && st != "1") {
          localStorage.setItem(
            "access_code",
            success.results.user.client_code
          );
        } else if (role == "superadmin" || (this.isSuperAdmin && st == "1")) {
          // localStorage.setItem("access_code", "sa");
        } else {
          localStorage.setItem("access_code", "standalone");
        }

        // this.dataService.setHeaders();
        this.dataService.setHeadersForFile();
        this.commonJs.getPermissions();
        this.commonJs.getAllSettings(true);
        //commented 19-8-20
        // this.getProjectRole();

        /*if(role == 'superadmin' || role == 'admin'){
        that.router.navigate(['/'+this.commonJs.getClientCodePrefix()+'/dashboard']);
      }else{
        that.router.navigate(['/'+this.commonJs.getClientCodePrefix()+'/projects/overview']);
      }*/

      if(role != "superadmin"){
        this.projectListByUser();
        this.getStatusList();
      }

        that.router.navigate([
          "/" + this.commonJs.getClientCodePrefix() + "/dashboard",
        ]);
        this.isLoading = false;
      } else {
        if (success.code == 422) {
          this.toastrService.notify('error', '', success.error.isOnlineAccess);
        }
      }
    },
      (error) => {
        if (error.error) {
          this.isLoading = false;
          var err = error.error;
          //console.log("error", err)
          this.errors = err.error;
        }
      }
    );
  }


  /** Get project list by user */
  projectListByUser() {
    this.dataService.getListTotal('projectListByUser')
      .subscribe((success) => {
        this.dataService.getProjectListByUser = success.data;
        this.dataService.getAllProjectList();
      }, (rejected) => {
        // //console.log(rejected);
      });
  }

  getStatusList() {
    return new Promise((resolve, rejected) => {
      this.dataService.getOtherData('getStatusList', {})
        .subscribe((success) => {
          this.dataService.allStatusList = success.data;
          resolve(200);
        }, (rejected) => {

        });
    });
  }
}

interface Login {
  email: string;
  password: string;
  client_code: string;
}

interface LoginImages {
  logo: string;
  logo_image: string;
  banner: string;
  banner_image: string;
}
