import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonJs } from '../../../common/common-js';
import { ToastrService } from '../../../services/toastr.service';
import * as _ from "lodash";

@Component({
	selector: 'app-client-stagged-review',
	templateUrl: './client-stagged-review.component.html',
	styleUrls: ['./client-stagged-review.component.css']
})
export class ClientStaggedReviewComponent implements OnInit {

	projectId = "";
	changePhaseList: string = '';
	phaseName: string = '';
	phaseList: Array<any>;
	departments: Array<any>;
	staggedDepartments: Array<any>;
	staggedReview: { [index: string]: any } = {};
	isDepartmentSubmitted: boolean = true;

	isProjectSettingsAdd: boolean = false;
	isProjectSettingsView: boolean = false;
	isProjectSettingsDelete: boolean = false;

	focused: string = '';
	constructor(public commonJs: CommonJs,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private dataService: DataService,
		private toastrService: ToastrService) {
		this.activatedRoute.params.subscribe((params: Params) => {
			this.projectId = params['id'];
		});
	}

	ngOnInit() {
		this.isProjectSettingsView = this.commonJs.checkAccess('project_settings', 'view', { 'baiPass': ['admin'] }, this.projectId);
		this.isProjectSettingsAdd = this.commonJs.checkAccess('project_settings', 'add', { 'baiPass': ['admin'] }, this.projectId);
		this.isProjectSettingsDelete = this.commonJs.checkAccess('project_settings', 'delete', { 'baiPass': ['admin'] }, this.projectId);
		this.getProjectPhases();
	}

	getProjectPhases() {
		this.dataService.getListData('getProjectPhase', { 'project_id': this.projectId, 'query': '' })
			.subscribe((success) => {
				this.phaseList = success.data;
			}, (rejected) => {

			});
	}

	changePhase(phaseName, phaseId, type) {
		this.staggedReview.departments = [];
		this.changePhaseList = phaseId;
		this.phaseName = phaseName;
		this.getStaggedReviewDepartments(phaseId, type);
	}

	search(query, column) {
		if (column == 'departments') {
			this.getDepartments(query);
		}
	}

	/** On select Depatment */
	onSelectDepartment() {
		this.isDepartmentSubmitted = false;
	}

	/** On clear p-autocomplate */
	onClear() {
		this.isDepartmentSubmitted = true;
	}

	getDepartments(query) {
		this.dataService.getListData('getProjectDepartmentsAndTaskTypes', { projectId: this.projectId, 'phaseId': this.changePhaseList, 'query': query['query'] })
			.subscribe((success) => {
				this.departments = success.data;
			}, (rejected) => {

			});
	}

	onAddDepartments(form: NgForm) {
		let department = form.value.department;
		this.dataService.getListData('onAddDepartments', { projectId: this.projectId, 'department': department })
			.subscribe((success) => {
				this.departments = success.data;
				this.getStaggedReviewDepartments(this.changePhaseList);
				this.focused = '';
				this.isDepartmentSubmitted = true;
				this.staggedReview.departments = [];
			}, (rejected) => {

			});

	}

	getStaggedReviewDepartments(phaseId, type = '') {
		this.dataService.getListData('getStaggedReviewDepartments', { 'project_id': this.projectId, 'phaseId': phaseId, 'type': type })
			.subscribe((success) => {
				this.staggedDepartments = success.data;
			}, (rejected) => {

			});
	}

	removeStaggedDepartment(index, dept_id, dtt_id) {
		let department = {};
		department['dept_id'] = dept_id;
		department['dtt_id'] = dtt_id;
		this.dataService.getListData('removeStaggedDepartment', { 'project_id': this.projectId, 'department': department })
			.subscribe((success) => {
				//this.staggedDepartments = success.data;
				this.getStaggedReviewDepartments(this.changePhaseList);
			}, (rejected) => {

			});
	}

	trackByDepts(index, item) {
		return item.id;
	}

}
