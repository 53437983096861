import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { columns } from '../table-column';
import { CommonJs } from 'src/app/common/common-js';

@Component({
  selector: 'app-asset-shot-linked',
  templateUrl: './asset-shot-linked.component.html',
  styleUrls: ['./asset-shot-linked.component.css']
})
export class AssetShotLinkedComponent implements OnInit, AfterViewInit {
  @Input() assetShotData: any;
  @Input() isLoading: boolean = true;
  @Input() type: any;
  public dataTableColumns: TableColumn[] = [];
  @ViewChild('image', { static: false }) public image: TemplateRef<any>;
  uploadUrl = this.commonJs.getUploadUrl();

  constructor(public commonJs: CommonJs) {
    if(!this.assetShotData){
      this.assetShotData = [];
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.createColumns();
  }

  /** Create Column */
  private createColumns(): void {
    let cellTemplate: any = {
      image: this.image
    };
    setTimeout(() => {
      if (this.type == 'shots') {
        this.dataTableColumns = columns('assetLinked', cellTemplate);
      } else {
        this.dataTableColumns = columns('shotLinked', cellTemplate);
      }
    });
  }
}
