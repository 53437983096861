import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'custom-later-chip',
  template: `
    <div class="chip">
      {{value}}
      <span *ngIf="from != 'task_list' && (departmentId == '34' || departmentId == '123' || department_name == 'Lighting')"  class="closebtn" (click)="edit()">
      <i class="fa fa-edit"></i>
      </span>
      <span *ngIf="from != 'task_list' && (departmentId == '34' || departmentId == '123' || department_name == 'Lighting' )"  class="closebtn" (click)="remove()">×</span>
    </div>
  `,
  styles: [`
    .chip {
      display: inline-block;
      padding: 0 10px;
      height: 30px;
      font-size: 13px;
      width: auto;
      line-height: 25px;
      border-radius: 16px;
      background-color: #f1f1f1;
      margin-right: 3px;
      margin-bottom: 5px;
      color: #999 !important;
    }

    .closebtn {
      margin-left: 10px;
      font-weight: bold;
      font-size: 20px;
      cursor: pointer;
    }
    .fa-edit {
      font-size:14px;
    }
  `]
})
export class CustomChipComponent {
  @Input() value: string;
  @Input() index: string;
  @Input() removeChip: any;
  @Output() myEvent = new EventEmitter<string>();
  @Input() departmentId: any;
  @Input() department_name: any;
  @Input() from: any;


  remove() {
    const payload: any = {
      type: 'remove',
      value: this.value,
      index: this.index
    }
    this.myEvent.emit(payload);
  }

  edit() {
    const payload: any = {
      type: 'edit',
      value: this.value,
      index: this.index
    }
    this.myEvent.emit(payload);
  }
}
