function nullable() {
    return { transform: (value) => value ? value : 'NA' };
}

function nullableZero() {
    return { transform: (value) => value ? value : '0' };
}

function dashLabel() {
    return { transform: (value) => value ? value : '-' };
}

export function columns(key: string, cellTemplate: any = {}, modifiedArray: any = []) {
    if (key === 'assets') {
        const assetColumns = [
            {
                prop: 'id',
                name: 'Actions',
                sortable: false,
                draggable: false,
                width: 80,
                minWidth: 80,
                maxWidth: 80,
                frozenRight: true,
                show:true,
                cellClass: 'action-button',
                headerClass: 'action-btn-datatable',
                cellTemplate: cellTemplate.ids,
            },
            {
                prop: 'image',
                name: 'Image',
                sortable: false,
                draggable: true,
                width: 60,
                minWidth: 60,
                maxWidth: 60,
                frozenLeft: true,
                cellClass: 'text-center',
                cellTemplate: cellTemplate.image,
            },
            {
                prop: 'type',
                name: 'Type',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                minWidth: 200,
                // maxWidth: 300,
                width: 250,
                cellClass: '',
                cellTemplate: cellTemplate.userType
            },
            {
                prop: 'sub_type',
                name: 'Sub Type',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                minWidth: 200,
                // maxWidth: 300,
                width: 250,
                cellClass: '',
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.userType,
                searchPlaceholder: 'Sub type',
            },
            {
                prop: 'name',
                name: 'Name',
                sortable: true,
                draggable: false,
                canAutoResize: true,
                minWidth: 200,
                // maxWidth: 300,
                width: 250,
                cellClass: '',
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.userType,
                searchPlaceholder: 'Name',
            },
            {
                prop: 'code',
                name: 'Code',
                sortable: true,
                draggable: false,
                canAutoResize: true,
                minWidth: 200,
                // maxWidth: 300,
                width: 250,
                cellClass: 'pointer',
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.assetDetails,
                searchPlaceholder: 'Code',
            },
            {
                prop: 'assetStatus',
                name: 'Asset Status',
                sortable: false,
                draggable: true,
                minWidth: 150,
                width: 200,
                canAutoResize: true,
            },
            {
                prop: 'episodeSequence',
                name: 'Episode',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                minWidth: 200,
                // maxWidth: 300,
                width: 250,
                cellClass: '',
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.userType,
                searchPlaceholder: 'Episode',
            },
            {
                prop: 'episodeSequence',
                name: 'Sequence',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                minWidth: 200,
                // maxWidth: 300,
                width: 250,
                cellClass: '',
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.userType,
                searchPlaceholder: 'Sequence',
            },
            {
                prop: 'final_dept',
                name: 'Final Department',
                sortable: false,
                draggable: true,
                minWidth: 160,
                width: 200,
                cellClass: 'overflow-hidden',
                canAutoResize: true,
            },
            {
                prop: 'complexity',
                name: 'Complexity',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                minWidth: 200,
                // maxWidth: 300,
                width: 250,
                cellClass: '',
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.userType,
                searchPlaceholder: 'Complexity',
            },
            {
                prop: 'duration_days',
                name: 'Duration (Days)',
                sortable: false,
                draggable: true,
                minWidth: 120,
                width: 120,
                canAutoResize: true,
                pipe: dashLabel(),
                cellTemplate: cellTemplate.duration
            },
            {
                prop: 'completion',
                name: 'Completion %',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                minWidth: 200,
                // maxWidth: 300,
                width: 250,
                cellClass: '',
                cellTemplate: cellTemplate.userType,
                pipe: nullableZero()
            }];
        assetColumns.push(...modifiedArray)
        return assetColumns;
    } else if (key == 'shot') {
        const shotColumns = [
            {
                prop: 'id',
                name: 'Actions',
                sortable: false,
                draggable: true,
                width: 96,
                minWidth: 96,
                frozenRight: true,
                show: true,
                idDepartment: true,
                cellClass: 'action-button',
                headerClass: 'action-btn-datatable',
                cellTemplate: cellTemplate.ids,
            },
            {
                prop: 'image',
                name: 'Image',
                sortable: false,
                draggable: true,
                width: 60,
                minWidth: 60,
                frozenLeft: true,
                cellClass: 'text-center ',
                cellTemplate: cellTemplate.image,
            },
            {
                prop: 'seasonEpisode',
                name: 'Season-episode',
                sortable: false,
                draggable: true,
                width: 150,
                minWidth: 150,
                frozenLeft: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellClass: '',
                searchPlaceholder: 'Season - episode',
                canAutoResize: true,
            },
            {
                prop: 'sequence_number',
                name: 'Sequence',
                sortable: false,
                draggable: true,
                width: 150,
                minWidth: 150,
                frozenLeft: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellClass: '',
                searchPlaceholder: 'Sequence',
                canAutoResize: true,
            },
            {
                prop: 'shot_number',
                name: 'Shot',
                sortable: true,
                draggable: true,
                width: 150,
                minWidth: 150,
                frozenLeft: true,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.shotDetail,
                searchPlaceholder: 'Shot',
                cellClass: 'text-crop pointer',
            },
            {
                prop: 'no_of_frames',
                name: '# Frames',
                sortable: true,
                draggable: true,
                width: 100,
                minWidth: 100,
                canAutoResize: true,
            },
            {
                prop: 'projected_start_date',
                name: 'Projected Start Date',
                sortable: true,
                draggable: true,
                width: 200,
                minWidth: 200,
                canAutoResize: true,
                cellTemplate: cellTemplate.projectedStartDate,
            },
            {
                prop: 'projected_end_date',
                name: 'Projected End Date',
                sortable: true,
                draggable: true,
                width: 200,
                minWidth: 200,
                canAutoResize: true,
                cellTemplate: cellTemplate.projectedEndDate,
              },
              {
                prop: 'projected_bid',
                name: 'Projected Bid',
                sortable: true,
                draggable: true,
                width: 125,
                minWidth: 125,
                canAutoResize: true,
                pipe: dashLabel(),
                cellTemplate: cellTemplate.bid_days,
              },
              {
                prop: 'bid_days',
                name: 'Bid Days',
                sortable: true,
                draggable: true,
                width: 95,
                minWidth: 95,
                canAutoResize: true,
                pipe: dashLabel(),
                cellTemplate: cellTemplate.bid_days,
            },
            {
                prop: 'Cameras',
                name: 'Cameras',
                sortable: false,
                draggable: true,
                width: 80,
                minWidth: 80,
                canAutoResize: true,
                cellClass: 'pointer text-center',
                cellTemplate: cellTemplate.cameras,
            },
            {
                prop: 'layers',
                name: 'Layers',
                sortable: false,
                draggable: true,
                width: 80,
                minWidth: 80,
                canAutoResize: true,
                cellClass: 'pointer text-center',
                cellTemplate: cellTemplate.layers,
            },
            {
                prop: 'description',
                name: 'Description',
                sortable: false,
                draggable: true,
                width: 100,
                minWidth: 100,
                maxWidth: 700,
                cellClass: 'overflow-hidden',
                canAutoResize: true,
                resizable: true,
                pipe: dashLabel(),
            },
            {
                prop: 'final_dept',
                name: 'Final Department',
                sortable: false,
                draggable: true,
                minWidth: 150,
                width: 200,
                canAutoResize: true,
            },
            {
                prop: 'shotStatus',
                name: 'Shot Status',
                sortable: false,
                draggable: true,
                minWidth: 150,
                width: 200,
                canAutoResize: true,
            },
            {
                prop: 'completion',
                name: 'Completion %',
                sortable: true,
                draggable: true,
                minWidth: 130,
                width: 130,
                canAutoResize: true,
                pipe: dashLabel()
            }, {
                prop: 'reel',
                name: 'Reel',
                sortable: false,
                draggable: true,
                minWidth: 100,
                width: 100,
                canAutoResize: true,
                pipe: dashLabel()
            }
            ,
            {
                prop: 'edit_in',
                name: 'Edit In',
                sortable: false,
                draggable: true,
                minWidth: 100,
                width: 100,
                canAutoResize: true,
                pipe: dashLabel()
            },
            {
                prop: 'edit_out',
                name: 'Edit Out',
                sortable: false,
                draggable: true,
                minWidth: 100,
                width: 100,
                canAutoResize: true,
                pipe: dashLabel()
            },
            {
                prop: 'scan_in',
                name: 'Scan In',
                sortable: false,
                draggable: true,
                minWidth: 100,
                width: 100,
                canAutoResize: true,
                pipe: dashLabel()
            },
            {
                prop: 'scan_out',
                name: 'Scan Out',
                sortable: false,
                draggable: true,
                minWidth: 100,
                width: 100,
                canAutoResize: true,
                pipe: dashLabel()
            },
            {
                prop: 'input_path',
                name: 'Input path',
                sortable: false,
                draggable: true,
                minWidth: 100,
                width: 100,
                canAutoResize: true,
                pipe: dashLabel(),
                cellTemplate: cellTemplate.inputPath
            },
            {
                prop: 'qt_path',
                name: 'Output path',
                sortable: false,
                draggable: true,
                minWidth: 100,
                width: 100,
                canAutoResize: true,
                cellClass: 'pointer text-center custom-qt-path',
                pipe: dashLabel(),
                cellTemplate: cellTemplate.qtPath
            },
            {
                prop: 'duration_days',
                name: 'Duration (Days)',
                sortable: false,
                draggable: true,
                minWidth: 120,
                width: 120,
                canAutoResize: true,
                pipe: dashLabel(),
                cellTemplate: cellTemplate.duration
            },
            {
                prop: 'no_of_sec',
                name: 'Duration (s)',
                sortable: false,
                draggable: true,
                minWidth: 100,
                width: 100,
                canAutoResize: true,
                pipe: dashLabel()
            },
            {
                prop: 'complexity',
                name: 'Complexity',
                sortable: false,
                draggable: true,
                minWidth: 150,
                width: 150,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Complexity',
                canAutoResize: true,
            },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: true,
                minWidth: 150,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Status',
                cellTemplate: cellTemplate.status,

            }
        ]

        shotColumns.push(...modifiedArray)
        return shotColumns;
    } else if (key == 'seasons') {
        const seasonsColumn = [
            {
                prop: 'id',
                name: 'Actions',
                sortable: false,
                draggable: false,
                width: 160,
                minWidth: 160,
                maxWidth: 160,
                frozenRight: true,
                cellClass: 'action-button p-0 secondaryActionColumn',
                headerClass: 'action-btn-datatable',
                cellTemplate: cellTemplate.ids,
            },
            {
                prop: 'image',
                name: 'Image',
                sortable: false,
                draggable: true,
                minWidth: 60,
                width: 60,
                maxWidth: 60,
                cellClass: 'action-button',
                cellTemplate: cellTemplate.image,
            },
            {
                prop: 'name',
                name: 'Season #',
                sortable: true,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.number,
                searchPlaceholder: 'Season #',
                cellClass: 'text-crop pointer',
            },
            {
                prop: 'number',
                name: 'Name',
                sortable: true,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: '',
                searchPlaceholder: 'Name',
                cellClass: 'text-crop',
            },
            {
                prop: 'episode_count',
                name: 'Episode Count',
                sortable: true,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
            },
            {
                prop: 'start_date',
                name: 'Start Date',
                sortable: false,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.startDateTime,
                searchPlaceholder: 'Start date',
                cellClass: 'text-crop',
            },
            {
                prop: 'end_date',
                name: 'End Date',
                sortable: false,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.endDateTime,
                searchPlaceholder: 'End date',
                cellClass: 'text-crop',
            },
            {
                prop: 'duration_days',
                name: 'Duration (Days)',
                sortable: false,
                draggable: true,
                minWidth: 100,
                width: 100,
                canAutoResize: true,
                pipe: dashLabel(),
                cellTemplate: cellTemplate.duration
            },
            {
                prop: 'totalBidsVsActualSpent',
                name: 'Bids / Actual',
                sortable: true,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                cellTemplate: '',
                searchPlaceholder: 'Bids / actual',
                cellClass: 'text-crop',
            },
            {
                prop: 'projected_episodes',
                name: 'Projected Episodes',
                sortable: true,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: '',
                searchPlaceholder: 'Projected episodes',
                cellClass: 'text-crop',
            },
            {
                prop: 'projected_minutes',
                name: 'Projected Minutes',
                sortable: true,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: '',
                searchPlaceholder: 'Projected minutes',
                cellClass: 'text-crop',
            },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Status',
                cellTemplate: cellTemplate.status,
            }
        ]
        return seasonsColumn;
    }
    else if (key == 'tasks') {
        const tasksColumns = [
            {
                prop: 'id',
                name: 'Actions',
                sortable: false,
                draggable: true,
                maxWidth: 90,
                width: 90,
                show: true,
                minWidth: 90,
                frozenRight: true,
                cellClass: 'action-button',
                headerClass: 'action-btn-datatable',
                cellTemplate: cellTemplate.ids,
            },
            {
                prop: 'asset_image',
                name: 'Image',
                sortable: false,
                draggable: true,
                width: 60,
                canAutoResize: false,
                resizable: false,
                cellClass: 'text-center',
                cellTemplate: cellTemplate.image,
            },
            {
                prop: 'thumbnail',
                name: 'Entity',
                sortable: false,
                draggable: false,
                width: 60,
                canAutoResize: false,
                resizable: false,
                cellClass: 'text-center',
                cellTemplate: cellTemplate.icon,
            },
            {
                prop: 'info',
                name: 'Info',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                width: 300,
                frozenLeft: false,
                cellClass: 'text-crop pointer d-flex-center',
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Priority',
                cellTemplate: cellTemplate.info,
            },
            {
                prop: 'season_and_episode',
                name: 'Season-episode',
                sortable: false,
                draggable: true,
                width: 150,
                canAutoResize: true,
            },
            {
                prop: 'sequence_name',
                name: 'Sequence',
                sortable: false,
                draggable: true,
                width: 150,
                canAutoResize: true,
            },
            // {
            //     prop: 'duration_days',
            //     name: 'Duration (Days)',
            //     sortable: false,
            //     draggable: true,
            //     minWidth: 100,
            //     width: 100,
            //     canAutoResize: true,
            //     pipe: dashLabel(),
            //     cellTemplate: cellTemplate.duration
            // },
            {
                prop: 'shot_number',
                name: 'Shot',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                width: 80,
                cellClass: '',
            },
            {
                // prop: 'ta_start_date',
                prop: 'min_start_date',
                name: 'Start Date',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                width: 192,
                cellTemplate: cellTemplate.startDate,
                cellClass: '',
            },
            {
                // prop: 'ta_end_date',
                prop: 'max_end_date',
                name: 'End Date',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellTemplate: cellTemplate.endDate,
                width: 192,
                cellClass: '',
            },
            {
                prop: 'actual_start_date',
                name: 'Actual Start Date',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellTemplate: cellTemplate.actualStartDate,
                width: 192,
                cellClass: '',
            },
            {
                prop: 'actual_end_date',
                name: 'Actual End Date',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                cellTemplate: cellTemplate.actualEndDate,
                width: 192,
                cellClass: '',
            },
            {
                prop: 'department',
                name: 'Department',
                sortable: false,
                draggable: true,
                canAutoResize: false,
                width: 200,
                cellClass: '',
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Department',
            },
            {
                prop: 'version',
                name: 'Version',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                width: 90,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Version',
            },
            {
                prop: 'bid_days',
                name: 'Bid Days',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                width: 60,
                cellClass: '',
                cellTemplate: cellTemplate.bid_days,
            },
            {
                prop: 'complexity',
                name: 'Complexity',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                width: 120,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Complexity',
                cellTemplate: cellTemplate.complexity,
            },
            {
                prop: 'assigned_to',
                name: 'Assigned To',
                sortable: true,
                draggable: true,
                canAutoResize: true,
                width: 150,
                cellClass: 'text-crop pointer',
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.userType,
                searchPlaceholder: 'Assigned to',
            },
            {
                prop: 'actualStatus',
                name: 'Status',
                sortable: false,
                draggable: true,
                // width: 150,
                width: 166,
                minWidth: 166,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Status',
                cellTemplate: cellTemplate.status,

            },
            {
                prop: 'type',
                name: 'Type',
                sortable: false,
                draggable: true,
                width: 100,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Type',

            },
            {
                prop: 'undertaking',
                name: 'Undertaking',
                sortable: false,
                draggable: true,
                width: 100,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Undertaking',

            },
            {
                prop: 'completion',
                name: 'Completion',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                width: 100,
                cellClass: 'text-crop pointer',
                cellTemplate: cellTemplate.complition,
            },];

        return tasksColumns;
    } else if (key == 'episode') {
        const seasonsColumn = [
            {
                prop: 'id',
                name: 'Actions',
                sortable: false,
                draggable: false,
                width: 180,
                frozenRight: true,
                cellClass: 'action-button p-0 secondaryActionColumn',
                headerClass: 'action-btn-datatable',
                cellTemplate: cellTemplate.ids,
            },
            {
                prop: 'thumbnail',
                name: 'Image',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                maxWidth: 60,
                width: 60,
                minWidth: 60,
                cellClass: 'text-center action-button',
                cellTemplate: cellTemplate.image,
            },
            {
                prop: 'season_id',
                name: 'Season #',
                sortable: false,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: '',
                searchPlaceholder: 'Season #',
                cellClass: '',
            },
            {
                prop: 'number',
                name: 'Episode #',
                sortable: false,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.number,
                searchPlaceholder: 'Episode #',
                cellClass: 'text-crop pointer',
            },
            {
                prop: 'name',
                name: 'Name',
                sortable: false,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: '',
                searchPlaceholder: 'Name',
                cellClass: 'text-crop',
            },
            {
                prop: 'sequence_count',
                name: 'Sequence Count',
                sortable: true,
                draggable: true,
                minWidth: 150,
                maxWidth: 150,
                width: 150,
                canAutoResize: true,
            },
            {
                prop: 'start_date',
                name: 'Start Date',
                sortable: false,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.startDateTime,
                searchPlaceholder: 'Start date',
                cellClass: 'text-crop',
            },
            {
                prop: 'end_date',
                name: 'End Date',
                sortable: false,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.endDateTime,
                searchPlaceholder: 'End date',
                cellClass: 'text-crop',
            },
            {
                prop: 'duration_days',
                name: 'Duration (Days)',
                sortable: false,
                draggable: true,
                minWidth: 150,
                width: 150,
                canAutoResize: true,
                pipe: dashLabel(),
                cellTemplate: cellTemplate.duration
            },
            {
                prop: 'totalBids',
                name: 'Bids / Actual',
                sortable: true,
                draggable: true,
                minWidth: 120,
                maxWidth: 120,
                width: 120,
                canAutoResize: true,
                cellTemplate: cellTemplate.bidDays,
                cellClass: 'text-crop',
            },
            {
                prop: 'projected_episodes',
                name: 'Projected Episodes',
                sortable: true,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: '',
                searchPlaceholder: 'Projected episodes',
                cellClass: 'text-crop',
            },
            {
                prop: 'projected_shots',
                name: 'Projected Shots',
                sortable: true,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: '',
                searchPlaceholder: 'Projected shots',
                cellClass: 'text-crop',
            },
            {
                prop: 'projected_seconds',
                name: 'Projected Seconds',
                sortable: true,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: '',
                searchPlaceholder: 'Projected seconds',
                cellClass: 'text-crop',
            },
            {
                prop: 'completion',
                name: 'Completion',
                sortable: true,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
            },
            {
                prop: 'id',
                name: 'Stats',
                sortable: true,
                draggable: true,
                minWidth: 100,
                maxWidth: 150,
                width: 100,
                cellClass: 'pointer',
                canAutoResize: true,
                cellTemplate: cellTemplate.stats,
            },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: true,
                minWidth: 150,
                maxWidth: 250,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Status',
                cellTemplate: cellTemplate.status,
            },
        ]
        seasonsColumn.push(...modifiedArray)
        return seasonsColumn;
    } else if (key == 'sequence') {
        const sequenceColumns = [
            {
                prop: 'id',
                name: 'Actions',
                sortable: false,
                draggable: false,
                width: 150,
                minWidth: 150,
                maxWidth: 150,
                frozenRight: true,
                cellClass: 'action-button p-0 secondaryActionColumn',
                headerClass: 'action-btn-datatable',
                cellTemplate: cellTemplate.ids,
            },
            {
                prop: 'thumbnail',
                name: 'Image',
                sortable: false,
                draggable: true,
                width: 60,
                minWidth: 60,
                maxWidth: 60,
                cellClass: 'text-center action-button',
                cellTemplate: cellTemplate.image,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
            },
            {
                prop: 'episode_id',
                name: 'Season# - Episode#',
                sortable: false,
                draggable: true,
                maxWidth: 200,
                minWidth: 200,
                headerTemplate: cellTemplate.hdrTpl,
                cellClass: '',
                searchPlaceholder: 'Season# - episode#',
                canAutoResize: true,
            },
            {
                prop: 'number',
                name: 'Sequence',
                sortable: false,
                draggable: true,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Sequence',
                cellClass: 'text-crop pointer',
            },
            {
                prop: 'name',
                name: 'Name',
                sortable: true,
                draggable: true,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.sequenceDetail,
                searchPlaceholder: 'Name',
            },
            {
                prop: 'shots.total_shot',
                name: 'Shots Count',
                sortable: false,
                draggable: true,
                width: 150,
                canAutoResize: true,
                cellTemplate: cellTemplate.sequenceDetail,
                searchPlaceholder: 'Name',
            },
            {
                prop: 'no_of_sec',
                name: 'Duration (s)',
                sortable: true,
                draggable: true,
                width: 150,
                canAutoResize: true,
            },
            {
                prop: 'totalBidsVsActualSpent',
                name: 'Bids / Actual',
                sortable: false,
                draggable: true,
                width: 150,
                canAutoResize: true
            },
            {
                prop: 'lead',
                name: 'Lead',
                sortable: false,
                draggable: true,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Lead',
            },
            {
                prop: 'producer',
                name: 'Producer',
                sortable: false,
                draggable: true,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Producer',
            },
            {
                prop: 'complexity',
                name: 'Complexity',
                sortable: true,
                draggable: true,
                width: 150,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                searchPlaceholder: 'Complexity',
                canAutoResize: true
            },
            {
                prop: 'duration_days',
                name: 'Duration (Days)',
                sortable: false,
                draggable: true,
                minWidth: 150,
                width: 150,
                canAutoResize: true,
                pipe: dashLabel(),
                cellTemplate: cellTemplate.duration
            },
            {
                prop: 'projected_shots',
                name: 'Projected Shots',
                sortable: false,
                draggable: true,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Projected shots',
                cellClass: 'text-crop pointer',
            },
            {
                prop: 'projected_seconds',
                name: 'Projected Sec',
                sortable: false,
                draggable: true,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Projected secs',
                cellClass: 'text-crop pointer',
            },
            {
                prop: 'completion',
                name: 'Completion %',
                sortable: false,
                draggable: true,
                width: 120,
                canAutoResize: true,
            },
            {
                prop: 'id',
                name: 'Stats',
                sortable: true,
                draggable: true,
                minWidth: 100,
                maxWidth: 150,
                width: 100,
                cellClass: 'pointer',
                canAutoResize: true,
                cellTemplate: cellTemplate.stats,
            },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: true,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                searchPlaceholder: 'Status',
                cellTemplate: cellTemplate.status,
            }
        ];
        sequenceColumns.push(...modifiedArray)
        return sequenceColumns;
    }
    else if (key == 'clients') {
        const clientsColumns = [
          {
            prop: 'id',
            name: 'Actions',
            sortable: false,
            draggable: true,
            width: 80,
            canAutoResize: false,
            resizable: false,
            frozenRight: true,
            headerClass: 'action-btn-datatable',
            cellClass: 'action-button',
            cellTemplate: cellTemplate.idsT,
          },
            // {
            //     prop: 'image',
            //     name: 'Image',
            //     sortable: false,
            //     draggable: true,
            //     width: 90,
            //     minWidth: 46,
            //     maxWidth: 90,
            //     canAutoResize: true,
            //     cellClass: 'keepItemsMiddle',
            //     cellTemplate: cellTemplate.thumbnail,
            // },
            {
                prop: 'name',
                name: 'Client Name',
                sortable: true,
                draggable: true,
                width: 220,
                minWidth: 220,
                maxWidth: 320,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.name,
                searchPlaceholder: 'Name',
            },
            // {
            //     prop: 'email',
            //     name: 'Email',
            //     sortable: true,
            //     draggable: true,
            //     width: 230,
            //     minWidth: 230,
            //     maxWidth: 330,
            //     canAutoResize: true,
            //     headerTemplate: cellTemplate.hdrTpl,
            //     cellTemplate: cellTemplate.email,
            //     searchPlaceholder: 'Email',
            // },
            {
                prop: 'phone',
                name: 'Phone',
                sortable: true,
                draggable: true,
                width: 230,
                minWidth: 230,
                maxWidth: 330,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.phone,
                searchPlaceholder: 'Phone',
            },
            {
                prop: 'contact_person',
                name: 'Contact Person',
                sortable: true,
                draggable: true,
                width: 230,
                minWidth: 230,
                maxWidth: 330,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.contactPerson,
                searchPlaceholder: 'Contact person',
            },
            {
                prop: 'country',
                name: 'Country',
                sortable: true,
                draggable: true,
                width: 230,
                minWidth: 230,
                maxWidth: 330,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.country,
                searchPlaceholder: 'country',
            },
            {
                prop: 'website',
                name: 'Website',
                sortable: true,
                draggable: true,
                width: 230,
                minWidth: 230,
                maxWidth: 330,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.website,
                searchPlaceholder: 'Website',
            },
            {
                prop: 'project_count',
                name: 'Project Count',
                sortable: false,
                draggable: true,
                width: 230,
                minWidth: 230,
                maxWidth: 330,
                canAutoResize: true,
                pipe: dashLabel()
            },
        ];
        return clientsColumns;
    }
    else if (key == 'vendors') {
        const vendorsColumns = [
            // {
            //     prop: 'image',
            //     name: 'Image',
            //     sortable: false,
            //     draggable: true,
            //     width: 66,
            //     canAutoResize: true,
            //     cellClass: 'keepItemsMiddle',
            //     cellTemplate: cellTemplate.thumbnail,
            // },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: true,
                width: 80,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.status,

                searchPlaceholder: 'Status',
            },
            {
                prop: 'name',
                name: 'Vendor Name',
                sortable: true,
                draggable: true,
                width: 160,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.name,
                searchPlaceholder: 'Name',
            },

            {
                prop: 'vendor_id',
                name: 'Vendor Id',
                sortable: true,
                draggable: true,
                width: 160,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.vendor_id,
                searchPlaceholder: 'Vendor code',
            },

            // {
            //     prop: 'email',
            //     name: 'Email',
            //     sortable: true,
            //     draggable: true,
            //     width: 200,
            //     canAutoResize: true,
            //     headerTemplate: cellTemplate.hdrTpl,
            //     cellTemplate: cellTemplate.email,
            //     searchPlaceholder: 'Email',
            // },
            {
                prop: 'vendor_type',
                name: 'Type',
                sortable: false,
                draggable: true,
                width: 90,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.vendor_type,
                searchPlaceholder: 'Type',
            },
            {
                prop: 'secondary_department',
                name: 'Department',
                sortable: false,
                draggable: true,
                width: 200,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.secondary_department,
                searchPlaceholder: 'Department',
            },
            {
                prop: 'grade',
                name: 'Grade',
                sortable: true,
                draggable: true,
                width: 70,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.grade,
                searchPlaceholder: 'Grade',
            },
            {
                prop: 'team_count',
                name: 'Team',
                sortable: true,
                draggable: true,
                width: 70,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.team_count,
                searchPlaceholder: 'Team count',
            },
            {
                prop: 'phone',
                name: 'Phone',
                sortable: true,
                draggable: true,
                width: 120,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.phone,
                searchPlaceholder: 'Phone',
            },
            {
                prop: 'address1',
                name: 'Address',
                sortable: true,
                draggable: true,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.address1,
                searchPlaceholder: 'Address',
            },
            {
                prop: 'city',
                name: 'City',
                sortable: true,
                draggable: true,
                width: 90,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.city,
                searchPlaceholder: 'City',
            },
            {
                prop: 'state',
                name: 'State',
                sortable: true,
                draggable: true,
                width: 90,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.state,
                searchPlaceholder: 'State',
            },
            {
                prop: 'country',
                name: 'Country',
                sortable: true,
                draggable: true,
                width: 110,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.country,
                searchPlaceholder: 'Country',
            },
            {
                prop: 'id',
                name: 'Actions',
                sortable: false,
                draggable: true,
                canAutoResize: true,
                width: 80,
                frozenRight: true,
                cellClass: 'action-button',
                headerClass: 'action-btn-datatable',
                cellTemplate: cellTemplate.idsT,
            },
        ];
        return vendorsColumns;
    }
    else if (key == 'projects') {
        const projectsColumn = [
            {
                prop: 'id',
                name: 'Actions',
                sortable: false,
                draggable: true,
                width: 86,
                minWidth: 86,
                maxWidth: 86,
                canAutoResize: false,
                resizable: false,
                frozenRight: true,
                headerClass: 'action-btn-datatable',
                cellClass: 'action-button',
                cellTemplate: cellTemplate.idsT,
            },
            {
                prop: 'image',
                name: 'Image',
                sortable: false,
                draggable: false,
                width: 60,
                resizable: false,
                canAutoResize: false,
                cellClass: 'keepItemsMiddle',
                cellTemplate: cellTemplate.thumbnail,
            },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: true,
                width: 80,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.status,
                cellClass: 'keepItemsMiddle',
                searchPlaceholder: 'Status',
            },
            {
                prop: 'project_code',
                name: 'Project Code',
                sortable: true,
                draggable: true,
                width: 120,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.project_code,
                cellClass: 'text-crop pointer',
                searchPlaceholder: 'Project code',
            },
            {
                prop: 'name',
                name: 'Project Name',
                sortable: true,
                draggable: true,
                width: 200,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.name,
                searchPlaceholder: 'Name',
            },
            {
                prop: 'fps',
                name: 'FPS',
                sortable: true,
                draggable: true,
                width: 80,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.fps,
                searchPlaceholder: 'FPS',
            },
            {
                prop: 'episodic_series',
                name: 'Episodic Series',
                sortable: false,
                draggable: true,
                width: 120,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.episodic_series,
                searchPlaceholder: 'Type',
            },
            {
                prop: 'project_type',
                name: 'Project Type',
                sortable: false,
                draggable: true,
                width: 200,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.project_type,
                searchPlaceholder: 'Project type',
            },
            {
                prop: 'output_resolution',
                name: 'Resolution',
                sortable: true,
                draggable: true,
                width: 100,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.output_resolution,
                searchPlaceholder: 'Resolution',
            },
            {
                prop: 'country',
                name: 'Country',
                sortable: true,
                draggable: true,
                width: 115,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.country,
                searchPlaceholder: 'Country',
            },
            {
                prop: 'client',
                name: 'Client',
                sortable: true,
                draggable: true,
                width: 150,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.client,
                searchPlaceholder: 'Client',
            },
            {
                prop: 'length',
                name: 'Length',
                sortable: true,
                draggable: true,
                width: 70,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.length,
                searchPlaceholder: 'Length',
            },
            {
                prop: 'bid_days',
                name: 'Bid',
                sortable: true,
                draggable: true,
                width: 70,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.bid_days,
                searchPlaceholder: 'Bid',
            },
            {
                prop: 'start_date',
                name: 'Start Date',
                sortable: true,
                draggable: true,
                width: 100,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.startDate,
                searchPlaceholder: 'Start date',
            },
            {
                prop: 'end_date',
                name: 'End Date',
                sortable: true,
                draggable: true,
                width: 100,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.endDate,
                searchPlaceholder: 'End date',
            },
            {
                prop: 'category',
                name: 'Vertical',
                sortable: true,
                draggable: true,
                width: 90,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.category,
                searchPlaceholder: 'Vertical',
            },
        ];
        return projectsColumn;
    }
    else if (key == 'employees') {
        const employeesColumn = [
            {
                prop: 'id',
                name: 'Actions',
                sortable: false,
                draggable: true,
                frozenRight: true,
                width: 100,
                canAutoResize: false,
                resizable:false,
                headerClass: 'action-btn-datatable',
                cellClass: 'action-button',
                cellTemplate: cellTemplate.idsT,
            },
            // {
            //     prop: 'manageId',
            //     name: '',
            //     sortable: false,
            //     draggable: false,
            //     canAutoResize: true,
            //     width: 70,
            //     frozenRight: true,
            //     headerClass: 'emptyHeaderCell',
            //     cellClass: 'text-center',
            //     cellTemplate: cellTemplate.manageBtns,
            // },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: true,
                width: 112,
                canAutoResize: false,
                resizable: false,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                searchPlaceholder: 'Status',
                cellTemplate: cellTemplate.status,
            },
            // {
            //     prop: 'image',
            //     name: 'Image',
            //     sortable: false,
            //     draggable: true,
            //     canAutoResize: true,
            //     cellClass: 'keepItemsMiddle',
            //     cellTemplate: cellTemplate.thumbnail,
            // },
            {
                prop: 'location',
                name: 'Location',
                sortable: true,
                draggable: true,
                width: 115,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.location,
                searchPlaceholder: 'Location',
            },
            // {
            //     prop: 'emp_code_final',
            //     name: 'ECode',
            //     sortable: true,
            //     draggable: true,
            //     width: 100,
            //     canAutoResize: true,
            //     headerTemplate: cellTemplate.hdrTpl,
            //     cellTemplate: cellTemplate.emp_code_final,
            //     searchPlaceholder: 'Employee code',
            // },
            {
                prop: 'name',
                name: 'Name',
                sortable: true,
                draggable: true,
                width: 250,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.name,
                searchPlaceholder: 'Name',
            },
            {
                prop: 'emp_code_final',
                name: 'Emp Code',
                sortable: true,
                draggable: true,
                width: 120,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Emp code',
            },
            {
                prop: 'projectsAssigned',
                name: 'Projects',
                sortable: true,
                draggable: true,
                width: 85,
                canAutoResize: true,
            },
            {
                prop: 'mode',
                name: 'Mode',
                sortable: true,
                draggable: true,
                width: 80,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.mode,
                searchPlaceholder: 'Mode',
            },
            {
                prop: 'primary_department',
                name: 'Department',
                sortable: true,
                draggable: true,
                width: 200,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.primary_department,
                searchPlaceholder: 'Primary department',
            },
            {
                prop: 'joining_date',
                name: 'DOJ',
                sortable: false,
                draggable: true,
                width: 110,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDateTemplate1,
                cellTemplate: cellTemplate.dateOfJoining,
                searchPlaceholder: 'Joining date',
            },
            {
                prop: 'vertical',
                name: 'Vertical',
                sortable: true,
                draggable: true,
                width: 90,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.vertical,
                searchPlaceholder: 'Vertical',
            },
            {
                prop: 'role_id',
                name: 'Role (Type)',
                sortable: true,
                draggable: true,
                width: 200,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.role_id,
                searchPlaceholder: 'Role',
            },
            {
                prop: 'reporting_to',
                name: 'Reporting To',
                sortable: true,
                draggable: true,
                // width: 230,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.reporting_to,
                searchPlaceholder: 'Reporting person',
            },
            {
                prop: 'prod_person',
                name: 'Production Person',
                sortable: true,
                draggable: true,
                // width: 230,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.prod_person,
                searchPlaceholder: 'Production person',
            },
            {
                prop: 'software_skills',
                name: 'Soft Skills',
                sortable: true,
                draggable: true,
                // width: 230,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.software_skills,
                searchPlaceholder: 'Software skills',
            },
            {
                prop: 'secondary_department',
                name: 'Secondary Dept',
                sortable: true,
                draggable: true,
                width: 200,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.secondary_department,
                searchPlaceholder: 'Secondary department',
            },
            {
                prop: 'designation',
                name: 'Designation',
                sortable: true,
                draggable: true,
                width: 110,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.designation,
                searchPlaceholder: 'Designation',
            },
            {
                prop: 'dob',
                name: 'DOB',
                sortable: false,
                draggable: true,
                width: 110,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDateTemplate2,
                cellTemplate: cellTemplate.dateOfBirth,
                searchPlaceholder: 'DOB',
            },
            {
                prop: 'gender',
                name: 'Gender',
                sortable: true,
                draggable: true,
                width: 80,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.gender,
                searchPlaceholder: 'Gender',
            },
            {
                prop: 'exit_date',
                name: 'Exit Date',
                sortable: false,
                draggable: true,
                width: 110,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDateTemplate3,
                cellTemplate: cellTemplate.exitDate,
                searchPlaceholder: 'Exit date',
            },
            {
                prop: 'attrition_type',
                name: 'Attr Type',
                sortable: true,
                draggable: true,
                width: 100,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.attrition_type,
                searchPlaceholder: 'Attrition type',
            },
            {
                prop: 'exit_reason',
                name: 'Reason for Exit',
                sortable: true,
                draggable: true,
                width: 200,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.exit_reason,
                searchPlaceholder: 'Exit reason',
            },
            {
                prop: 'tenure',
                name: 'Tenure',
                sortable: true,
                draggable: true,
                width: 100,
                canAutoResize: true,
                cellTemplate: cellTemplate.tenure,
            },
            {
                prop: 'government_id',
                name: 'Gov Id',
                sortable: true,
                draggable: true,
                width: 80,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.government_id,
                searchPlaceholder: 'Government id',
            },
            {
                prop: 'age',
                name: 'Age (Y)',
                sortable: true,
                draggable: true,
                width: 80,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.age,
                searchPlaceholder: 'Age',
            },
            {
                prop: 'email',
                name: 'Email',
                sortable: true,
                draggable: true,
                // width: 230,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.email,
                searchPlaceholder: 'Email',
            },
            {
                prop: 'phone',
                name: 'Phone',
                sortable: true,
                draggable: true,
                width: 105,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.phone,
                searchPlaceholder: 'Phone',
            },
            {
                prop: 'address1',
                name: 'Address 1',
                sortable: true,
                draggable: true,
                width: 200,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.address1,
                searchPlaceholder: 'Address1',
            },
            {
                prop: 'address2',
                name: 'Address 2',
                sortable: true,
                draggable: true,
                width: 200,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.address2,
                searchPlaceholder: 'Address2',
            },
            {
                prop: 'city',
                name: 'City',
                sortable: true,
                draggable: true,
                width: 115,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.city,
                searchPlaceholder: 'City',
            },
            {
                prop: 'state',
                name: 'State',
                sortable: true,
                draggable: true,
                width: 115,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.state,
                searchPlaceholder: 'State',
            },
            {
                prop: 'country',
                name: 'Country',
                sortable: true,
                draggable: true,
                width: 115,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.country,
                searchPlaceholder: 'Country',
            },
            {
                prop: 'zipcode',
                name: 'Zipcode',
                sortable: true,
                draggable: true,
                width: 115,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.zipcode,
                searchPlaceholder: 'Zipcode',
            },
            {
                prop: 'exp_duration',
                name: 'Exp Duration',
                sortable: true,
                draggable: true,
                width: 140,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.exp_duration,
                searchPlaceholder: 'Exp duration',
            },
            {
                prop: 'exp_designation',
                name: 'Exp Designation',
                sortable: true,
                draggable: true,
                width: 140,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.exp_designation,
                searchPlaceholder: 'Exp designation',
            },
            {
                prop: 'exp_company',
                name: 'Exp Company',
                sortable: true,
                draggable: true,
                width: 140,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.exp_company,
                searchPlaceholder: 'Exp company',
            },
            {
                prop: 'column1',
                name: 'column1',
                sortable: true,
                draggable: true,
                // width: 230,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.column1,
                searchPlaceholder: 'Column1',
            },
            {
                prop: 'column2',
                name: 'column2',
                sortable: true,
                draggable: true,
                // width: 230,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.Column2,
                searchPlaceholder: 'Column2',
            },
            {
                prop: 'column3',
                name: 'column3',
                sortable: true,
                draggable: true,
                // width: 230,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.Column3,
                searchPlaceholder: 'Column3',
            },
            {
                prop: 'column4',
                name: 'column4',
                sortable: true,
                draggable: true,
                // width: 230,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.Column4,
                searchPlaceholder: 'Column4',
            },
            {
                prop: 'column5',
                name: 'column5',
                sortable: true,
                draggable: true,
                // width: 230,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.Column5,
                searchPlaceholder: 'Column5',
            },
            {
                prop: 'column6',
                name: 'column6',
                sortable: true,
                draggable: true,
                // width: 230,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                cellTemplate: cellTemplate.Column6,
                searchPlaceholder: 'Column6',
            }
        ];
        return employeesColumn;
    }
    else if (key == 'OutsourcedTasks') {
        const OutsourcedTasksColumn = [

            {
                prop: 'id',
                name: 'Actions',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                width: 70,
                frozenRight: true,
                headerClass: 'action-btn-datatable',
                cellClass: 'action-button',
                cellTemplate: cellTemplate.idsT,
            },
            {
                prop: 'po_number',
                name: 'PO Number',
                sortable: false,
                draggable: true,
                width: 100,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                cellTemplate: cellTemplate.po_number,
                searchPlaceholder: 'PO number',
                cellClass: 'pointer',
            },
            {
                prop: 'assigned_to',
                name: 'Vendor',
                sortable: false,
                draggable: true,
                width: 180,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                searchPlaceholder: 'Vendor',
                cellTemplate: cellTemplate.assignedTo,
            },
            {
                prop: 'vendor_type',
                name: 'Vendor Type',
                sortable: false,
                draggable: true,
                width: 105,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                searchPlaceholder: 'Vendor type',
            },
            {
                prop: 'project_id',
                name: 'Project',
                sortable: false,
                draggable: true,
                width: 180,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                searchPlaceholder: 'Project',
                cellTemplate: cellTemplate.project_id,
            },
            {
                prop: 'info',
                name: 'Info',
                sortable: false,
                draggable: true,
                width: 200,
                canAutoResize: true,
                cellClass: 'pointer',
                cellTemplate: cellTemplate.taskInfo,
                headerTemplate: cellTemplate.info,
            },
            {
                prop: 'department',
                name: 'Department',
                sortable: false,
                draggable: true,
                width: 200,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                searchPlaceholder: 'Department',
            },
            {
                prop: 'version',
                name: 'Version',
                sortable: false,
                draggable: true,
                width: 80,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrTpl,
                searchPlaceholder: 'Version',
            },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: true,
                width: 120,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDropdownTpl,
                searchPlaceholder: 'Status',
                cellTemplate: cellTemplate.statusCell,
                cellClass: 'statusCellTemplateOSScreen',
            },
            {
                prop: 'start_date',
                name: 'Start Date',
                sortable: false,
                draggable: true,
                width: 192,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDateTemplate1,
                cellTemplate: cellTemplate.startDate,
                searchPlaceholder: 'Start date',
            },
            {
                prop: 'end_date',
                name: 'End Date',
                sortable: false,
                draggable: true,
                width: 192,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDateTemplate2,
                cellTemplate: cellTemplate.endDate,
                searchPlaceholder: 'End date',
            },
            {
                prop: 'actual_start_date',
                name: 'Actual Start Date',
                sortable: false,
                draggable: true,
                width: 192,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDateTemplate3,
                cellTemplate: cellTemplate.actualStartDate,
                searchPlaceholder: 'Actual start date',
            },
            {
                prop: 'actual_end_date',
                name: 'Actual End Date',
                sortable: false,
                draggable: true,
                width: 192,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDateTemplate4,
                cellTemplate: cellTemplate.actualEndDate,
                searchPlaceholder: 'Actual end date',
            },
            {
                prop: 'approval_date',
                name: 'Approval Date',
                sortable: false,
                draggable: true,
                width: 192,
                canAutoResize: true,
                headerTemplate: cellTemplate.hdrDateTemplate5,
                cellTemplate: cellTemplate.approvalDate,
                searchPlaceholder: 'Approval date',
            },
            {
                prop: 'bidDays' ,
                name: 'Bid Days',
                sortable: false,
                draggable: true,
                width: 100,
                canAutoResize: true,
                cellTemplate: cellTemplate.taskBidCell,
                cellClass: 'editableCost',
            },
            {
                prop: 'cost',
                name: 'Cost',
                sortable: false,
                draggable: true,
                width: 100,
                canAutoResize: true,
                cellTemplate: cellTemplate.taskCostCell,
                cellClass: 'editableCost',
            },
        ];
        return OutsourcedTasksColumn;
    }
    else if (key == 'shotsComments') {
        const shotCommentsColumn = [
            {
                prop: 'episode',
                name: 'Episode #',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellTemplate: cellTemplate.shotEpisode,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'sequence',
                name: 'Sequence #',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'shot',
                name: 'Shot #',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                // cellTemplate: cellTemplate.shotNumber,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center text-crop pointer'
            },
            {
                prop: 'frames',
                name: 'Frames',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            }
        ];
        return shotCommentsColumn;
    }
    else if (key == 'assetsComments') {
        const assetsCommentsColumn = [
            {
                prop: 'asset',
                name: 'Asset #',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'assetCode',
                name: 'AssetCode #',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center text-crop pointer'
            },
            {
                prop: 'asset_type',
                name: 'Asset type',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            }
        ];
        return assetsCommentsColumn;
    }
    else if (key == 'prePostComments') {
        const prePostComments = [
            {
                prop: 'task_number',
                name: 'Task',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellTemplate: cellTemplate.prePostTaskNumber,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'dttName',
                name: 'Department',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellTemplate: cellTemplate.prePostDept,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'artist',
                name: 'Artist',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'reviewer',
                name: 'Comment By',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellTemplate: cellTemplate.commentedBy,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'commentedAt',
                name: 'Date',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'revision',
                name: 'Revision',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'version',
                name: 'Version',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'versionStartDate',
                name: 'Version Start Date',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'versionEndDate',
                name: 'Version End Date',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'retakeTypeName',
                name: 'Retake Type',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                cellTemplate: cellTemplate.retakeType,
                headerClass: 'a-link-class text-center',
                cellClass: 'align-center'
            },
            {
                prop: 'comment',
                name: 'Comments',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                width: 350
            }
        ];
        return prePostComments;
    }
    else if (key == 'bidding') {
        const biddingColumns = [
            {
                prop: 'id',
                name: 'Action',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                width: 100,
                frozenRight: true,
                headerClass: 'action-btn-datatable',
                cellClass: 'action-button',
                cellTemplate: cellTemplate.idsT,
            },
            {
                prop: 'subtype',
                name: 'Entity Type',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'text-center',
                width: 350
            },
            {
                prop: 'version',
                name: 'Version',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'text-center text-crop pointer',
                width: 350
            },
            {
                prop: 'total_bid_days',
                name: 'Total Bids',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'text-center',
                width: 350,
            },
            {
                prop: 'created_at',
                name: 'Creation Date',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'text-center',
                width: 350,
                cellTemplate: cellTemplate.dateCell
            },
            {
                prop: 'updated_at',
                name: 'Last Modified Date',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                headerClass: 'a-link-class text-center',
                cellClass: 'text-center',
                width: 350,
                cellTemplate: cellTemplate.dateCell
            }

        ];
        return biddingColumns;
    }
    else if (key == 'accounts') {
        const accountsColumns = [
            {
                prop: 'id',
                name: 'Action',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                width: 80,
                frozenRight: true,
                headerClass: 'action-btn-datatable',
                cellClass: 'action-button',
                cellTemplate: cellTemplate.idsT
            },
            {
                prop: 'client_code',
                name: 'Code',
                sortable: true,
                draggable: false,
                canAutoResize: true,
                headerClass: 'text-center',
                cellClass: 'text-center',
                searchPlaceholder: "Search Code",
                width: 120,
                headerTemplate: cellTemplate.hdrTpl
            },
            {
                prop: 'name',
                name: 'Name',
                sortable: true,
                draggable: false,
                canAutoResize: true,
                headerClass: 'text-center',
                cellClass: 'text-center text-crop pointer',
                searchPlaceholder: "Search Name",
                width: 150,
                headerTemplate: cellTemplate.hdrTpl
            },
            {
                prop: 'email',
                name: 'Email',
                sortable: true,
                draggable: false,
                canAutoResize: true,
                headerClass: 'text-center',
                cellClass: 'text-center cursor-pointer',
                searchPlaceholder: "Search Email",
                width: 280,
                minWidth: 280,
                headerTemplate: cellTemplate.hdrTpl
            },
            {
                prop: 'active_users',
                name: '# Active users',
                sortable: true,
                draggable: false,
                canAutoResize: true,
                headerClass: 'text-center',
                cellClass: 'text-center',
                width: 80
            },
            {
                prop: 'users_limit',
                name: 'Users Limit',
                sortable: true,
                draggable: false,
                canAutoResize: true,
                headerClass: 'text-center',
                cellClass: 'text-center',
                width: 80
            },
            {
                prop: 'account_type',
                name: 'Account Type',
                sortable: true,
                draggable: false,
                canAutoResize: true,
                headerClass: 'text-center',
                cellClass: 'text-center',
                width: 80,
                headerTemplate: cellTemplate.hdrTpl
            },
            {
                prop: 'expiration_date',
                name: 'Expiration Date',
                sortable: true,
                draggable: false,
                canAutoResize: true,
                headerClass: 'text-center',
                cellClass: 'text-center',
                width: 80
            },
            {
                prop: 'created_at',
                name: 'Created Date',
                sortable: true,
                draggable: false,
                canAutoResize: true,
                headerClass: 'text-center',
                cellClass: 'text-center',
                width: 80
            },
            {
                prop: 'status',
                name: 'Status',
                sortable: true,
                draggable: false,
                canAutoResize: true,
                headerClass: 'text-center',
                cellClass: 'text-center',
                width: 80,
                cellTemplate: cellTemplate.status
            },

        ];
        return accountsColumns;
    }
    else if (key == 'mytasks') {
      const myTasksColumns = [
            {
              prop: 'thumbnail',
              name: 'Image',
              sortable: false,
              draggable: true,
              resizable: false,
              canAutoResize: false,
              width: 60,
              cellClass: 'text-center imageIconCell',
              cellTemplate: cellTemplate.imageIcon,
            },
            {
              prop: 'icon',
              name: 'Entity',
              sortable: false,
              draggable: true,
              resizable: false,
              canAutoResize: false,
              width: 60,
              cellClass: 'text-center imageIconCell',
              cellTemplate: cellTemplate.taskThumbnail,
            },
            {
              prop: 'project_name',
              name: 'Project Name',
              sortable: false,
              draggable: false,
              width: 120,
              canAutoResize: true,
              headerClass: '',
              cellClass: '',
           },
            {
             prop: 'info',
             name: 'Task Name',
             sortable: false,
             draggable: false,
             width: 230,
             canAutoResize: true,
             headerClass: '',
             cellClass: 'pointer',
             cellTemplate: cellTemplate.info,
            },
            {
              prop: 'department_name',
              name: 'Department',
              sortable: false,
              draggable: false,
              canAutoResize: true,
              headerClass: '',
              cellClass: ''
            },
            {
              prop: 'version',
              name: 'Version',
              sortable: false,
              draggable: false,
              canAutoResize: true,
              headerClass: '',
              cellClass: ''
            },
            {
              prop: 'working_days',
              name: 'W/B Days',
              sortable: false,
              draggable: false,
              canAutoResize: true,
              headerClass: '',
              cellClass: '',
              cellTemplate: cellTemplate.wbdays,
            },
            {
              prop: 'status',
              name: 'Status',
              sortable: false,
              draggable: false,
              canAutoResize: true,
              headerClass: '',
              cellClass: 'text-center myTaskStatusCell',
              cellTemplate: cellTemplate.status,
            },
            {
              prop: 'is_running',
              name: 'Activity',
              sortable: false,
              draggable: false,
              canAutoResize: true,
              width: 40,
              // frozenRight: true,
              headerClass: 'text-center',
              cellClass: 'text-center imageIconCell',
              cellTemplate: cellTemplate.activity,
            },
            {
              prop: 'total_duration',
              name: 'Time log',
              sortable: false,
              draggable: false,
              canAutoResize: true,
              headerClass: '',
              cellClass: 'text-center',
              cellTemplate: cellTemplate.timeLog,
            },

        ];
        return myTasksColumns;
      }

}
