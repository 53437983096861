import { Directive, ElementRef, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[appThumbnail]'
})
export class ThumbnailDirective {

  public href: string = "";


  constructor(
    private elementRef: ElementRef,
    private router: Router
  ) {

  }

  ngOnInit(): void {
    this.href = this.router.url;
    if (this.router.url.startsWith("/ac/ZA")) {
      this.elementRef.nativeElement.style.display = 'none';
      this.elementRef.nativeElement.remove();
    }

  }

}
