import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customDatepipe'
})
export class CustomDatepipePipe implements PipeTransform {

  transform(inputDate:string): any {
    if(!inputDate){
      return inputDate;
    }
    // console.log(inputDate);
    const today = new Date();
    const currentDate = new Date(today);
    const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
    const tomorrow = new Date(currentDate);
        tomorrow.setDate(currentDate.getDate() + 1);
    const parsedInputDate = new Date(inputDate);

    if ( parsedInputDate.getDate() === yesterday.getDate()  &&  parsedInputDate.getMonth() === yesterday.getMonth()  &&  parsedInputDate.getFullYear() === yesterday.getFullYear()) {
      return 'Yesterday';
    }
    else if ( parsedInputDate.getDate() === currentDate.getDate()  &&  parsedInputDate.getMonth() === currentDate.getMonth()  &&  parsedInputDate.getFullYear() === currentDate.getFullYear()) {
      return 'Today';
    }
    else if ( parsedInputDate.getDate() === tomorrow.getDate()  &&  parsedInputDate.getMonth() === tomorrow.getMonth()  &&  parsedInputDate.getFullYear() === tomorrow.getFullYear()) {
      return 'Tomorrow';
    }
    else {
      return parsedInputDate.toLocaleDateString('es-CL'); // 'dd-MM-yyyy' format
    }
  }

  private isSameDate(date1: Date, date2: Date): boolean {
    return date1.getDate() === date2.getDate()   &&   date1.getMonth() === date2.getMonth()   &&   date1.getFullYear() === date2.getFullYear();
  }

}
