import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import swal from 'sweetalert2';
import * as _ from "lodash";
declare var $: any;
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
import { ToastrService } from '../../../services/toastr.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

//  import { DragulaService } from 'ng2-dragula';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.css']
})
export class ProjectSettingsComponent implements OnInit {

  entityName = 'Settings';
  entityNameSingular = 'Setting';
  entityNameRight = 'Settings';
  apiRoutePrefix = 'project-settings';

  apiRoutePrefixListItem = 'listItem';
  @Input('attr.data') dataHtml: any;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  settings: { [index: string]: any } = {};
  data: any;
  myform: any;
  //errors:any;
  focused: string = 'focused';
  SaveButtonText: string = 'SAVE';
  settingsAll: any = JSON.parse(localStorage.getItem('settings'));
  projectId: string;

  output_resolution: Array<any> = this.appConfigs.prj_output_resolution;
  _opened: boolean = false;
  _loadSideWindow: boolean = false;
  _ng_sidebar_data = {};
  project_type: Array<any>;
  //changeComponent:any;
  changeComponent = 'team';
  errors: { [index: string]: any } = {};

  team: { [index: string]: any } = {};
  roleTypeList = [];
  roleTypes = [];
  roles = [];
  roleName: string;

  selectedRoleType = [];
  _team_opened: boolean = false;
  _teamSideWindow: boolean = false;
  _ng_sidebar_team_data = {};
  isButtonDisabled = [];
  isTeam: boolean = true;
  toggleTitle = 'school';
  toggleClass = 'btn btn-default waves-effect waves-float btn-team-btn team-toggle';
  toggleClassActive = 'btn btn-primary waves-effect waves-float btn-team-btn team-toggle';
  deptTeam = [];
  rowDetail = {};
  preUrl = this.commonJs.getUploadUrl() + 'users/';
  isProjectSettingsPermission = [];

  //Folder Structure
  entityArr = [
    { 'id': 'project_code', 'name': 'Project Code', 'slug': 'project.project_code', 'dynamic': true, 'checked': true },
    { 'id': 'season_number', 'name': 'Season Number', 'slug': 'sequence.sequence_number', 'dynamic': true, 'checked': true, 'isEpisodic': 1 },
    { 'id': 'episode_number', 'name': 'Episode Number', 'slug': 'sequence.episode_number', 'dynamic': true, 'checked': true, 'isEpisodic': 1 },
    { 'id': 'sequence_number', 'name': 'Sequence Number', 'slug': 'sequence.sequence_number', 'dynamic': true, 'checked': true },
    { 'id': 'shot_number', 'name': 'Shot Number', 'slug': 'shot.shot_number', 'dynamic': true, 'checked': true },
    { 'id': 'task_number', 'name': 'Task', 'slug': 'task.task', 'dynamic': true, 'checked': true },
    { 'id': 'version', 'name': 'Version', 'slug': 'task.version', 'dynamic': true, 'checked': false },
    { 'id': 'revision', 'name': 'Revision', 'slug': 'task.revision', 'dynamic': true, 'checked': false },
    { 'id': 'asset_code', 'name': 'Asset Code', 'slug': 'asset.asset_code', 'dynamic': true, 'checked': false },
    { 'id': 'asset_type', 'name': 'Asset Type', 'slug': 'asset.type', 'dynamic': true, 'checked': false }
  ];

  entityArrTemp = [];
  isFolderSubmitted: boolean = false;
  //focused:string='';

  //User can review departments
  users: { [index: string]: any } = {};
  secondary_department: Array<any>;
  isReviewSelection: boolean = false;

  env = environment;

  showTeamCheckbox: boolean = false;
  deleteAllId = [];
  bsModalRef: BsModalRef;
  roleTypePayload = {
    project_id: '',
    type: '',
    name: '',
    typeString: ''
  }

  searchKey:any = [
    { primaryDept:'', secondaryDept:'' },
    { primaryDept:'', secondaryDept:'' },
    { primaryDept:'', secondaryDept:'' },
    { primaryDept:'', secondaryDept:'' },
    { primaryDept:'', secondaryDept:'' },
  ];
  // searchKey = {
  //   primaryDept: { Creative:'', External:'', Production:'', Supervisor:'', Support:'' },
  //   secondaryDept: { Creative:'', External:'', Production:'', Supervisor:'', Support:'' }
  // };

  addTeamType:any ='';
  phasesExist = {};

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private dataService: DataService,
    public commonJs: CommonJs,
    private toastrService: ToastrService,
    private modalService: BsModalService,
    public appConfigs: AppConfigs,
    private elementRef: ElementRef) {
    // dragulaService.dropModel.subscribe((value) => {
    //   this.onDropModel(value.slice(1));
    // });
  }

  ngOnInit() {
    this.titleService.setTitle(this.settingsAll.html_prefix + 'Project ' + this.entityName);
    this.activatedRoute.params.subscribe((params: Params) => {
      //localStorage.setItem('projectId',params['id']);
      this.projectId = params['id'];
    });
    this.isProjectSettingsPermission['view'] = this.commonJs.checkAccess('project_settings', 'view', { 'baiPass': ['admin'] }, this.projectId);
    this.isProjectSettingsPermission['add'] = this.commonJs.checkAccess('project_settings', 'add', { 'baiPass': ['admin'] }, this.projectId);
    this.isProjectSettingsPermission['edit'] = this.commonJs.checkAccess('project_settings', 'edit', { 'baiPass': ['admin'] }, this.projectId);
    this.isProjectSettingsPermission['delete'] = this.commonJs.checkAccess('project_settings', 'delete', { 'baiPass': ['admin'] }, this.projectId);

    if (localStorage.getItem('phasesExist')) {
      this.phasesExist = JSON.parse(localStorage.getItem('phasesExist'));
    } else if (this.commonJs.phasesExist) {
      this.phasesExist = this.commonJs.phasesExist;
    } else {
      this.phasesExist = { pre: 1, character: 1, set: 1, prop: 1, shot: 1, post: 1 };
    }

    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
    }

    this.settings = {
      is_external_review: false,
      project_type: '',
      is_review_department_selection: '',
      show_done_status_tasks: '',
      is_department_based_task_accees: '',
      episodic_series: ''
    };


    if(localStorage.getItem('phasesExist')){
      this.phasesExist = JSON.parse(localStorage.getItem('phasesExist'));
      // console.log(this.phasesExist);
    }else if(this.commonJs.phasesExist){
      this.phasesExist = this.commonJs.phasesExist;
    }else{
      this.phasesExist = { pre:1, character:1, set:1, prop:1, shot:1, post:1 };
    }

    this.getProjectById();
    this.getRoleTypeRoles();
    this.getDeptWiseTeam();
    $.AdminBSB.input.activate();

    this.getProjectFolderStructure();

  }

  getProjectById() {
    this.dataService.getDataById('getProjectById/' + this.projectId)
      .subscribe((success) => {
        this.settings = success.data;
        this.isReviewSelection = success.data.is_review_department_selection;
        // console.log(this.isReviewSelection);
        this.settings.show_done_status_tasks = success.data.show_done_status_tasks;
        this.settings.is_department_based_task_accees = success.data.is_department_based_task_accees;
        if (success.data.is_external_review == 0 || success.data.is_external_review == '0' || (!success.data.is_external_review)) {
          this.settings.is_external_review = false;
        }
        this.settings.allow_pre_post_task_with_episode = success.data.allow_pre_post_task_with_episode;
        this.settings.episodic_series = success.data.episodic_series;
      }, (rejected) => {
      });
  }


  onSubmit(form: NgForm) {
    form.value.project_id = this.projectId;
    this.SaveButtonText = 'SAVING';

    if (form.value.is_review_department_selection) {
      form.value.is_review_department_selection = this.settings.is_review_department_selection;
    }

    if (form.value.show_done_status_tasks) {
      form.value.show_done_status_tasks = this.settings.show_done_status_tasks;
    }

    if (form.value.is_location_based_task_assignment) {
      form.value.is_location_based_task_assignment = this.settings.is_location_based_task_assignment;
    }

    if (form.value.is_department_based_task_accees) {
      form.value.is_department_based_task_accees = this.settings.is_department_based_task_accees;
    }

    if (form.value.allow_pre_post_task_with_episode) {
      form.value.allow_pre_post_task_with_episode = this.settings.allow_pre_post_task_with_episode;
    }


    this.saveForm(form);
  }

  saveForm(form) {
    let settingArr = {};
    settingArr['project_id'] = this.projectId;
    if (form == undefined || form == '') {
      settingArr['project_id'] = this.projectId;
      settingArr['is_review_department_selection'] = this.settings.is_review_department_selection;
    } else {
      settingArr = form.value;
    }
    this.dataService.updateData(this.apiRoutePrefix, settingArr)
      .subscribe((success) => {
        this.SaveButtonText = 'SAVED';
        this.getProjectById();
        setTimeout(() => {
          this.SaveButtonText = 'SAVE';
          //this.commonJs.getAllSettings();
        }, 2);

      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
        }
      });
  }

  onChange(flag, column = '', fromTeam = false) {
    if (column == 'is_review_department_selection') {
      if (flag) {
        this.settings.is_review_department_selection = 1;
      } else {
        this.settings.is_review_department_selection = 0;
      }
      if (fromTeam) {
        this.saveForm('');
      }
    } else if (column == 'show_done_status_tasks') {
      if (flag) {
        this.settings.show_done_status_tasks = 1;
      } else {
        this.settings.show_done_status_tasks = 0;
      }
    } else if (column == 'is_location_based_task_assignment') {
      if (flag) {
        this.settings.is_location_based_task_assignment = 1;
      } else {
        this.settings.is_location_based_task_assignment = 0;
      }
    } else if (column == 'is_department_based_task_accees') {
      if (flag) {
        this.settings.is_department_based_task_accees = 1;
      } else {
        this.settings.is_department_based_task_accees = 0;
      }
    } else if (column == 'allow_pre_post_task_with_episode') {
      if (flag) {
        this.settings.allow_pre_post_task_with_episode = 1;
      } else {
        this.settings.allow_pre_post_task_with_episode = 0;
      }
    }
  }

  //! Sidebar Options Change Function

  changePage(page) {
    this.changeComponent = page;
    if (page == 'settings') {
      this.titleService.setTitle(this.settingsAll.html_prefix + 'Project Settings');
    }
    else if (page == 'team') {
      this.titleService.setTitle(this.settingsAll.html_prefix + 'Team');
    }
    else if (page == 'phase') {
      this.titleService.setTitle(this.settingsAll.html_prefix + 'Phases');
    }
    else if (page == 'client-stagged-review') {
      this.titleService.setTitle(this.settingsAll.html_prefix + 'Internal Staged Review');
    }
    else if (page == 'project-folder-structure') {
      this.titleService.setTitle(this.settingsAll.html_prefix + 'Folder Structure');
      this.getProjectFolderStructure();
    }
  }

  onRowBasic(row) {
    this.rowDetail = row;
  }

  closeSettings(event) {
    if (event.id) {
      this.getRoleTypeRoles();
      this.getDeptWiseTeam();
      this.isButtonDisabled = [];
    } else {
      this.changeComponent = '';
    }

  }


  search(query, column, roletype = "", pts = []) {
    if (column == 'role') {
      this.dataService.getOtherData('getRolesByType', { 'query': query['query'], 'role_type_id': roletype })
        .subscribe((success) => {
          this.roles = success.data;
        });
    } else if (column == 'secondary_department') {
      this.getProjectDepartments(query['query'], pts);
    }
  }

  getRoleTypeRoles() {
    this._opened = false;
    this.roleTypePayload.project_id = this.projectId;
    this.dataService.getListData('getRoleTypeRoles', this.roleTypePayload)
      .subscribe((success) => {
        let tempRoleTypeList = success.data;
        this.roleTypeList = tempRoleTypeList.filter((ele)=>(ele.name!='Support'));
      }, (rejected) => {

      });
  }

  getDeptWiseTeam() {
    // console.log('test')
    this.dataService.getListData('getDeptWiseTeam', { 'project_id': this.projectId })
      .subscribe((success) => {
        let tempDeptTeam = success.data;

        this.deptTeam = tempDeptTeam.filter((ele)=>{
          if(ele.type=='asset'){
            if(this.phasesExist[ele.subtype.toLowerCase()]==1){
              return ele;
            }
          }else{
            if(this.phasesExist[ele.type.toLowerCase()]==1){
              return ele;
            }
          }
        });
        // console.log(this.deptTeam, this.phasesExist);
      }, (rejected) => {

      });
  }

  toggleTeam(title) {
    if (title == 'school') {
      this.toggleTitle = 'school';
      this.isTeam = true;
    } else {
      this.toggleTitle = 'view_quilt';
      this.isTeam = false;
    }
  }

  onSelectRole(event) {
  }

  selectedOptionChange(event, parentId) {
    this.selectedRoleType[parentId] = event.id;
    this.roleName = event.name;
    if (parentId != '') {
      this.isButtonDisabled[parentId] = parentId;
    } else {
      this.isButtonDisabled = [];
    }
  }

  // To open a side window for assigning users to the team
  openSideWindow(teamType) {
    this._opened = true;
    console.log(teamType);
    this.addTeamType = teamType;
  }

  onOpenedEmp() {
		this._loadSideWindow = true;
	}

	onClosedEmp() {
		this._loadSideWindow = false;
		// this.gridService.updateValue('New value from parent');
	}

  closeCreateUser() {
	}

  // To open a modal for assigning users to the team
  openSideWind(assignModal: TemplateRef<any>, loadWindow, mode = 'create', data = {}, type = 0, role = '', roletype = 0, typeName = '') {
    if (loadWindow == 'team') {
      // this._team_opened = true;
      this._ng_sidebar_team_data['role'] = role;
      console.log(role,roletype);
      this._ng_sidebar_team_data['roletype'] = roletype;
      this._ng_sidebar_team_data['roletypeName'] = typeName;
      this._ng_sidebar_team_data['roleName'] = this.roleName;
    }

    this.bsModalRef = this.modalService.show(assignModal, {
      class: "selectTeamMemberModal", ignoreBackdropClick: true
    });
  }

  showCheckAll() {
    this.deleteAllId = [];
    this.showTeamCheckbox = !this.showTeamCheckbox;
  }

  public checkedAction(IS_ALL, row: any, roletypeName: string, roletypeIndex: any): void {
    if (IS_ALL) {
      let checkboxChecked = $('#' + roletypeName).is(':checked');
      if (checkboxChecked) {
        this.deleteAllId = [];
        $('#' + roletypeName).prop('checked', false);
        $('.' + roletypeName).prop('checked', false);
      } else {
        let i;
        this.deleteAllId = [];
        for (i = 0; i < this.roleTypeList[roletypeIndex]['project_team'].length; i++) {
          this.deleteAllId.push(this.roleTypeList[roletypeIndex]['project_team'][i]['id']);
        }
        $('#' + roletypeName).prop('checked', true);
        $('.' + roletypeName).prop('checked', true);


      }

    } else {
      let checkboxChecked = $('#' + roletypeName + '-' + row.id).is(':checked');
      if (checkboxChecked) {
        const index: number = this.deleteAllId.indexOf(row.id);
        if (index !== -1) {
          this.deleteAllId.splice(index, 1);
        }
        $('#' + roletypeName + '-' + row.id).prop('checked', false);

        $('#' + roletypeName + '-' + row.id).removeClass('active-task');

      } else {
        this.deleteAllId.push(row.id);
        $('#' + roletypeName + '-' + row.id).prop('checked', true);
      }
      $('#' + roletypeName + '-' + row.id).focus();
    }
  }

  removeAllUsersFromTeam(roleType) {
    if (this.deleteAllId.length > 0) {
      let that = this;
      swal.fire({
        //title: 'Delete '+this.deleteAllId.length+' '+this.entityNameSingular+'(s)',
        title: 'Unassign ' + this.deleteAllId.length + ' ' + roleType + ' user(s)',
        html: "Are you sure you want to unassign these users ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Unassign",
        cancelButtonText: "Cancel"
      }).then((result) => {
        if (result.value) {
          that.dataService.deleteData('removeAllUsersFromTeam', that.deleteAllId)
            .subscribe((success) => {
              if (success.status == 'error') {
                that.toastrService.notify('error', '', success.message);
              } else {
                that.toastrService.notify('success', '', success.message);
              }

              that.getRoleTypeRoles();
              that.getDeptWiseTeam();
              that.deleteAllId = [];
              that.showTeamCheckbox = false;

            }, (error) => {
              if (error.error) {
                var err = error.error;
                that.toastrService.notify('error', '', err.message);
              }

              that.getRoleTypeRoles();
              that.getDeptWiseTeam();
              that.deleteAllId = [];
              that.showTeamCheckbox = false;

            });


        } else {
          //console.log('cancelled');
        }
      })
    } else {
      swal.fire(
        'Oops...',
        'Please select at least one User',
        'error'
      );
    }
  }

  removeUser(data) {
    let that = this;
    swal.fire({
      //title: 'Delete '+this.deleteAllId.length+' '+this.entityNameSingular+'(s)',
      title: 'Unassign ' + data.role_name,
      html: "Are you sure you want to unassign <span style='font-weight:600;'>" + data.user_name + "</span> ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Unassign",
      cancelButtonText: "Cancel",
      customClass: {
        content: 'unassignModalContent',
      }
    }).then((result) => {
      if (result.value) {
        that.dataService.deleteData('removeUser', data)
          .subscribe((success) => {
            if (success.status == 'error') {
              that.toastrService.notify('error', '', success.message);
            } else {
              that.toastrService.notify('success', '', success.message);
              that.getRoleTypeRoles();
              that.getDeptWiseTeam();
            }
          }, (error) => {
            if (error.error) {
              var err = error.error;
              that.toastrService.notify('error', '', err.message);
            }
          });
      } else {
        //console.log('cancelled');
      }
    })
  }

  onOpened(loadWindow) {
    this._teamSideWindow = true;
  }

  onClosed(status) {
    this._teamSideWindow = false;
  }

  onChangeEntity(data, key, flag) {
    let index1 = this.entityArr.findIndex((item) => item.id === key);
    this.entityArr[index1]['checked'] = flag;
  }

  filterAction(IS_ALL, value, row, entity) {
  }

  private onDropModel(args) {

  }

  saveFolderStructure() {
    this.dataService.storeData('createFolderStructure', { 'data': this.entityArr, 'project_id': this.projectId })
      .subscribe((success) => {
        this.roles = success.data;
        //this.focused
      }, (error) => {
        //this.alertMessage = 'Something went wrong, Please try again!';
        if (error.error) {
          var err = error.error;
        }
      });
  }

  getProjectFolderStructure() {
    this.dataService.getOtherData('getProjectFolderStructure', { 'project_id': this.projectId })
      .subscribe((success) => {
        if (success.data != null) {
          this.entityArr = success.data;
        }
      }, (error) => {
        //this.alertMessage = 'Something went wrong, Please try again!';
        if (error.error) {
          var err = error.error;
        }
      });
  }

  getProjectDepartments(query = '', userArr = []) {
    let userArrTemp = [{ user_id: userArr['user_id'], role_id: userArr['role_id'], role_type_id: userArr['role_type_id'] }]
    this.dataService.getOtherData('getProjectDepartmentsForUserReview', { 'data': userArrTemp, 'query': query, projectId: this.projectId })
      .subscribe((success) => {
        this.secondary_department = success.data;
      }, (rejected) => {

      });
  }

  selectedUserReviewDepartment(event, userId, roleId, roleTypeId) {
    let userArr = [{ 'project_id': this.projectId, user_id: userId, role_id: roleId, role_type_id: roleTypeId, department_id: event.id }];
    this.dataService.storeData('saveProjectDepartmentForUserReview', { 'data': userArr, 'project_id': this.projectId })
      .subscribe((success) => {
        if (success.status == 'error') {
          this.toastrService.notify('error', '', success.message);
        } else {
          this.toastrService.notify('success', '', success.message);
          if (event.id == 0) {
            this.getRoleTypeRoles();
          }
        }
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.toastrService.notify('error', '', err.message);
        }
      });
  }

  removeDepartment(event, userId, roleId, roleTypeId) {
    let userArr = [{ 'project_id': this.projectId, user_id: userId, role_id: roleId, role_type_id: roleTypeId, department_id: event.id }];
    this.dataService.storeData('removeProjectDepartmentFromUserReview', { 'data': userArr, 'project_id': this.projectId })
      .subscribe((success) => {
        if (success.status == 'error') {
          this.toastrService.notify('error', '', success.message);
        } else {
          this.toastrService.notify('success', '', success.message);
        }
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.toastrService.notify('error', '', err.message);
        }
      });
  }

  filterProjects(event, data: any, type: any, filterInputIndex) {
    this.roleTypePayload.name = data.name;
    this.roleTypePayload.type = type;
    this.roleTypePayload.typeString = event.target.value;
    // console.log(this.roleTypePayload.typeString==''?true:false);
    if(this.roleTypePayload.typeString==''){
      this.roleTypePayload.name = this.roleTypePayload.type = '';
    }

    this.dataService.getListData('getRoleTypeRoles', this.roleTypePayload)
    .subscribe((success) => {
      this.roleTypeList = success.data;


      setTimeout(()=>{
          this.setFocus(type+filterInputIndex);
        },100);

      // console.log(success.data);
    }, (rejected) => {

    });

  }

  private setFocus(targetInput): void {

    const inputElement: HTMLInputElement = this.elementRef.nativeElement.querySelector('#searchKey'+targetInput);

    if (inputElement) {
      inputElement.focus();
    }
  }

  // For getting first two letters of name
  getInitials(inputString: string): string {
    const words = inputString.trim().split(' ');
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      const secondInitial = words[1].charAt(0).toUpperCase();
      return `${firstInitial}${secondInitial}`;
    } else if (words.length === 1) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      return `${firstInitial}`;
    } else {
      return '';
    }
  }

}
