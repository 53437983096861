import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonJs } from '../../../common/common-js';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-google-conf',
  templateUrl: './google-conf.component.html',
  styleUrls: ['./google-conf.component.css']
})
export class GoogleConfComponent implements OnInit {

  googleDrive: FormGroup;
  f1Focused: string = '';
  SaveButtonText: string = 'CONNECT';
  bsModalRef: BsModalRef;

  constructor(
    private fb: FormBuilder,
    public dataService: DataService,
    private toastrService: ToastrService,
    public commonjs: CommonJs,
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
    const response: any = this.commonjs.getAllSettingResponse;
    if (response.is_google_drive_enable == 1) {
      this.SaveButtonText = 'CONNECTED'
    }
    this.googleDrive = this.fb.group({
      clientId: [response.clientId ? response.clientId : null],
      clientSecret: [response.clientSecret ? response.clientSecret : null],
      refreshToken: [response.refreshToken ? response.refreshToken : null],
      folderId: [response.folderId ? response.folderId : null],
    });
  }

  /** On submit*/
  onSubmit(form: any) {
    const response: any = this.commonjs.getAllSettingResponse;
    if (response.is_aws_s3_enable == 1) {
      this.toastrService.notify('warn', '', 'At a time one storage will get connected');
    } else {
      if (!this.checkControlPost(form)) {
        if (this.findInvalidControls().length === 0) {
          this.SaveButtonText = 'CONNECTING';
          form.type = 2;
          this.dataService.awsConfig(form).subscribe((res: any) => {
            this.SaveButtonText = 'CONNECTED';
            if (res.status != 'error') {
              this.toastrService.notify('success', '', res.message);
              this.SaveButtonText = 'CONNECTED';
            } else {
              this.toastrService.notify('warn', '', res.message);
              this.SaveButtonText = 'CONNECT';
            }
          });
        }
      }
    }
    if (this.checkControlPost(form)) {
      this.markFormTouched(this.googleDrive);
    }
  }

  /** Disconnect Google drive */
  disConnectS3() {
    var form1 = { type: 2 };
    this.dataService.awsDisconnect(form1).subscribe((res: any) => {
      if (res.status != 'error') {
        this.toastrService.notify('success', '', res.message);
        this.googleDrive = this.fb.group({
          clientId: [null],
          clientSecret: [null],
          refreshToken: [null],
          folderId: [null],
        });
        this.SaveButtonText = 'CONNECT';
      } else {
        this.toastrService.notify('warn', '', res.message);
      }
    });
  }


  /** Check Control Post */
  checkControlPost = (post) => {
    let invalid = false;
    Object.keys(post).forEach((key: string) => {

      if (key === 'clientId' && !this.googleDrive.get(`${key}`).value) {
        this.googleDrive.get(`${key}`).setValidators([Validators.required]);
        this.googleDrive.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }


      if (key === 'clientSecret' && !this.googleDrive.get(`${key}`).value) {
        this.googleDrive.get(`${key}`).setValidators([Validators.required]);
        this.googleDrive.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }


      if (key === 'refreshToken' && !this.googleDrive.get(`${key}`).value) {
        this.googleDrive.get(`${key}`).setValidators([Validators.required]);
        this.googleDrive.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }

      if (key === 'folderId' && !this.googleDrive.get(`${key}`).value) {
        this.googleDrive.get(`${key}`).setValidators([Validators.required]);
        this.googleDrive.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }

    });
    return invalid;
  }

  /** Find Invalid Controls */
  findInvalidControls = () => {
    const invalid = [];
    const controls = this.googleDrive.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  /**Mark Form Touched*/
  markFormTouched = (group: FormGroup | FormArray) => {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  // To open confirmation modal
  openConfiramtionModal(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(template);
  }

}
