import { Component, OnInit } from '@angular/core';
import { CommonJs } from 'src/app/common/common-js';
import { GridService } from 'src/app/services/grid.service';
import { ToastrService } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css']
})
export class AddEmployeeComponent implements OnInit {
  rows: Array<any> = [];
  skeleton: boolean = true;
  config = {
    "start": 0,
    "itemsPerPage": 150,
    "sort": {
      "title": "#ID",
      "name": "id",
      "sort": "desc",
      "className": "hide-this hide-this"
    },
    "sortNew": {
      "id": "desc"
    },
    "FilterColumns": [
      "name",
      "email"
    ],
    "filtering": {
      "filterString": ""
    },
    "FilterByColumns": {
      "status": "",
      "location": "",
      "emp_code_final": "",
      "name": "",
      "mode": "",
      "primary_department": "",
      "joining_date": "",
      "vertical": "",
      "role_id": "",
      "reporting_to": "",
      "prod_person": "",
      "software_skills": "",
      "secondary_department": "",
      "designation": "",
      "dob": "",
      "gender": "",
      "exit_date": "",
      "attrition_type": "",
      "exit_reason": "",
      "tenure": "",
      "age": "",
      "email": "",
      "phone": "",
      "government_id": "",
      "address1": "",
      "address2": "",
      "city": "",
      "state": "",
      "country": "",
      "zipcode": "",
      "exp_duration": "",
      "exp_designation": "",
      "exp_company": "",
      "column1": "",
      "column2": "",
      "column3": "",
      "column4": "",
      "column5": "",
      "column6": ""
    },
    "where": {
      "status": [],
      "gender": [],
      "mode": [],
      "vertical": [],
      "role_id": [],
      "type": [],
      "primary_department": [],
      "secondary_department": [],
      "reporting_to": [],
      "prod_person": [],
      "software_skills": [],
      "attrition_type": [],
      "exit_reason": []
    },
    "columns": {},
    "export": false
  }

  public maxSize = 100
  public page: number = 1;
  public itemsPerPage: number = 150;
  //public itemsPerPage:number = 3;
  public length;
  public numPages: number = 0;
  pagePayload: any = { page: 0, itemsPerPage: 150 }
  pageSize: number = 150;


  constructor(
    private gridService: GridService,
    public commonJs: CommonJs,
    private toastrService: ToastrService,
  ) { }

  ngOnInit() {
    this.getEmployeesList();
  }


  // To get Employeeslist
  getEmployeesList() {
    this.gridService.postData('usersList', this.config).subscribe((res: any) => {
      this.rows = res.data;
      this.length = res.totalCount;
      this.skeleton = false;
    });
  }

  /** Checkbox event */
  checkboxChanged(event: any, emp: any) {
    const isChecked = event.target.checked;
    const payload = {
      id: emp.id,
      isOnlineAccess: isChecked ? 1 : 0
    }
    this.gridService.postData('users/onlineAccess', payload).subscribe((res: any) => {
      this.toastrService.notify('success', '', 'Employee Online Access updated successfully.');
    });
  }

  /** On page change */
  onChangePage(e: any) {
    e.page = e.page - 1;
    this.pagePayload.page = e.page * this.pageSize;
    this.config.start = this.pagePayload.page;
    this.skeleton = true;
    this.rows = [];
    this.getEmployeesList();
  }

  // When a filter is applied
  onColumnFilter(e: any): void {
    this.getEmployeesList();
  }

  // For getting first two letters of name
  getInitials(inputString: string): string {
    const words = inputString.trim().split(' ');
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      const secondInitial = words[1].charAt(0).toUpperCase();
      return `${firstInitial}${secondInitial}`;
    } else if (words.length === 1) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      return `${firstInitial}`;
    } else {
      return '';
    }
  }

}
