import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { AppConfigs } from 'src/app/common/app-configs';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';
import { DatatableService } from 'src/app/services/datatable/datatable.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { columns } from '../utility/column-generator';
import * as _ from "lodash";
import * as moment from 'moment';
import swal from 'sweetalert2';
import { Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThumbnailModalServiceService } from 'src/app/services/thumbnail-modal-service.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationPopupComponent } from 'src/app/common/confirmation-popup/confirmation-popup.component';
@Component({
  selector: 'app-season-list',
  templateUrl: './season-list.component.html',
  styleUrls: ['./season-list.component.css']
})
export class SeasonListComponent implements OnInit {

  settings: any = JSON.parse(localStorage.getItem('settings'));

  // Datatable variables
  isLoading: boolean = true;
  currentPage: number = 0;
  pageSize: number = 100;
  totalItems: number = 0;
  rows: any = [];
  projectId = '';
  rowDetail = {};
  pagePayload: any = {
    page: 0,
    itemsPerPage: 100,
  }
  public readonly pageLimitOptions = [
    { value: 50 },
    { value: 100 },
    { value: 200 },
    { value: 300 },
  ];
  public dataTableColumns: TableColumn[] = [];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('image', { static: false }) public image: TemplateRef<any>;
  @ViewChild('number', { static: true }) public number: TemplateRef<any>;
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;
  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;
  @ViewChild("startDateTime", { static: true }) startDateTime: TemplateRef<any>;
  @ViewChild("endDateTime", { static: true }) endDateTime: TemplateRef<any>;

  _opened: boolean = false;
  episode_opened: boolean = false;
  _loadSideWindow: boolean = false;
  episodeSideWindow: boolean = false;
  _ng_sidebar_data = {};
  _ng_sidebar_episode_data = {};

  seasonObj: any = {};
  public config: any = {
    paging: true,
    sorting: { columns: this.dataTableColumns },
    FilterByColumns: {
      "number": '',
      "name": '',
      "start_date": '',
      "end_date": '',
      "projected_episodes": '',
      "projected_minutes": '',
      "status": ''
    },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered'],
    where: { 'project_id': this.seasonObj.projectId },
    isLoadingFirstTime: true,
    isClearFilter: false,
  };

  public rangePicker = {
    singleDatePicker: false,
    autoUpdateInput: true,
    showDropdowns: true,
    autoApply: true,
    drops: 'down',
    //startDate: '',
    locale: { format: this.settings.date_format },
  }

  public FilterColumns: Array<any> = ['name'];
  apiRoutePrefix = 'seasons';
  isFilterApplied: boolean = false;
  private searchTerms = new Subject<any>();
  bsModalRef: BsModalRef;

  isFullScreen:boolean = false;
  previousOffsetY:number = 0;

  startDate:string = '';
  endDate:string = '';

  constructor(
    private dataService: DataService,
    private datatableService: DatatableService,
    private titleService: Title,
    private router: Router,
    public commonJs: CommonJs,
    public appConfigs: AppConfigs,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private thumbnailModalService: ThumbnailModalServiceService,
    private modalService: BsModalService,
  ) {
    this.seasonObj.statusOptions = [
      { id: 1, text: "Active" },
      { id: 0, text: "Inactive" },
    ];
    this.searchTerms
      .pipe(debounceTime(300), switchMap(term => this.search()))
      .subscribe(results => {
      });
  }

  ngOnInit() {
    this.datatableService.setParam(this.config, 'config');
    this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
    this.datatableService.setParam(this.apiRoutePrefix + 'List', 'serverUrl');
    this.titleService.setTitle(this.settings.html_prefix + ' Seasons');

    // Get project id
    this.activatedRoute.params.subscribe((params: Params) => {
      this.seasonObj.projectId = params['id'];
      this.projectId = params['id'];
      this.config.where.project_id = params['id'];
      setTimeout(() => {
        localStorage.setItem('projectId', params['id']);
      }, 500);
    });

    //Permission
    this.seasonObj.isSeasonAddPermission = this.commonJs.checkAccess('seasons', 'add', { 'baiPass': ['admin'] }, this.seasonObj.projectId);
    this.seasonObj.isSeasonEditPermission = this.commonJs.checkAccess('seasons', 'edit', { 'baiPass': ['admin'] }, this.seasonObj.projectId);
    this.seasonObj.isSeasonDeletePermission = this.commonJs.checkAccess('seasons', 'delete', { 'baiPass': ['admin'] }, this.seasonObj.projectId);
    this.seasonObj.isEpisodeAddPermission = this.commonJs.checkAccess('episodes', 'add', { 'baiPass': ['admin'] }, this.seasonObj.projectId);
    this.seasonObj.isEpisodeViewPermission = this.commonJs.checkAccess('episodes', 'view', { 'baiPass': ['admin'] }, this.seasonObj.projectId);
    this.createColumns();
  }

  // open glbal image thumbnail viewer
  openImageModal(imageUrl: string): void {
    this.thumbnailModalService.openThumbnailModal(imageUrl);
  }

  /** Create Column */
  private createColumns(): void {
    let cellTemplate: any = {};
    setTimeout(() => {
      cellTemplate = {
        ids: this.idsT,
        number: this.number,
        image: this.image,
        status: this.status,
        hdrTpl: this.hdrTpl,
        startDateTime: this.startDateTime,
        endDateTime: this.endDateTime,
      };
      this.dataTableColumns = [];
      this.dataTableColumns = columns('seasons', cellTemplate);
      // this.onChangeTable(this.config);
    });
  }

  public onChangeTable(config: any, page: any = { page: this.pagePayload.page, itemsPerPage: this.pagePayload.itemsPerPage }, column: any = '', isAppend: any = false, isSort: any = false): any {
    this.spinner.hide();
    if (!this.isLoading) {
      this.isLoading = true;
    }
    if (column) {
      this.config.isClearFilter = false;
      this.config.isLoadingFirstTime = false;
    }
    this.datatableService.onChangeTable(this.config, page, column)
      .subscribe((success) => {
        this.totalItems = success.totalElements
        if (this.config.export) {
          window.open(success.data);
        } else {
          // cache our list
          this.isFilterApplied = success.isFilterApplied;

          this.rows = success.data
          this.isLoading = false;
          this.config.export = false;
          if (Array.isArray(success.filterArr) && success.filterArr.length > 0) {
            this.config.FilterByColumns.end_date = success.filterArr.FilterByColumns.end_date;
            this.config.FilterByColumns.name = success.filterArr.FilterByColumns.name;
            this.config.FilterByColumns.number = success.filterArr.FilterByColumns.number;
            this.config.FilterByColumns.projected_episodes = success.filterArr.FilterByColumns.projected_episodes;
            this.config.FilterByColumns.projected_minutes = success.filterArr.FilterByColumns.projected_minutes;
            this.config.FilterByColumns.start_date = success.filterArr.FilterByColumns.start_date;
            this.config.FilterByColumns.status = success.filterArr.FilterByColumns.status;
          }
        }
        this.isLoading = false;
      });
  }

  /** Action Button */
  actionClick(action: string, row: any, column: any): void {

  }

  /** Create Seasons */
  createSeasons() {
    this._ng_sidebar_data['projectId'] = this.seasonObj.projectId;
    this._ng_sidebar_data['mode'] = 'Create';
    this._opened = true;
  }

  /** Edit Seasons */
  editSeasons(data: any) {
    this._ng_sidebar_data['seasonid'] = data.id;
    this._ng_sidebar_data['projectId'] = this.seasonObj.projectId;
    this._ng_sidebar_data['mode'] = 'edit';
    this._opened = true;
  }

  /** Create Episode */
  createEpisode(data: any) {
    this.episode_opened = true;
    this._ng_sidebar_episode_data['mode'] = 'create';
    this._ng_sidebar_episode_data['projectId'] = this.seasonObj.projectId;
    this._ng_sidebar_episode_data['season'] = true;
    this._ng_sidebar_episode_data['seasonid'] = data.id;
  }

  onRowBasic(row) {
    this.rowDetail = row;
  }

  onOpened(loadWindow) {
    if (loadWindow == 'episode') {
      this.episodeSideWindow = true;
    } else {
      this._loadSideWindow = true;
    }
  }

  onClosed(closeWindow) {
    if (closeWindow == 'episode') {
      this.episodeSideWindow = false;
    } else {
      this._loadSideWindow = false;
    }
  }

  /** Delete season */
  deleteSeasons(id) {
    const config = {
      initialState: {
        title: 'Delete Season',
        message: "Are you sure you want to delete this Season(s)",
      },
      class: 'modal-md custom-modal-css',
      centered: true,
      animated: false
    };
    this.bsModalRef = this.modalService.show(ConfirmationPopupComponent, config);
    // Subscribe to the onConfirmAction event
    this.bsModalRef.content.onConfirmAction.subscribe(() => {
      // Handle the confirmation logic here
      this.dataService.deleteData(this.apiRoutePrefix + 'Destroy', { id })
        .subscribe((success) => {
          if (success.status == 'error') {
            this.toastrService.notify('error', '', success.message);
          } else {
            this.toastrService.notify('success', '', success.message);
            this.isLoading = true;
            this.onChangeTable(this.config);
          }
        }, (rejected) => {

        });
    });

    // let that = this;
    // swal.fire({
    //   title: 'Delete Season(s)',
    //   text: "Are you sure you want to delete this Season(s)",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#DD6B55",
    //   confirmButtonText: "Delete",
    //   cancelButtonText: "Cancel",
    // }).then((result) => {
    //   if (result.value) {
    //     that.dataService.deleteData(that.apiRoutePrefix + 'Destroy', { id })
    //       .subscribe((success) => {
    //         if (success.status == 'error') {
    //         } else {
    //           that.rows = [];
    //           that.onChangeTable(that.config);
    //         }
    //         that.toastrService.notify(success.status, '', success.message);
    //       }, (rejected) => {

    //       });
    //   } else {
    //   }
    // });
  }



  onColumnFilter(term: string): void {
    this.config.start = 0;
    this.searchTerms.next(term);
  }

  search(): Observable<any> {
    // Perform your API call here
    // You can use Angular's HttpClient to make the HTTP request to your API
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.config.isLoadingFirstTime = false;
    this.onChangeTable(this.config);
    return
  }


  selectedDatepicker(column, $event) {
    let startDateValue = moment($event.start).format('YYYY-MM-DD');
    let searchValue = '';
    let endDateValue = moment($event.end).format('YYYY-MM-DD');
    if (startDateValue != '' && startDateValue != null && startDateValue != 'Invalid date' && endDateValue != '' && endDateValue != null && endDateValue != 'Invalid date') {
      searchValue = startDateValue + '|' + endDateValue;
    }
    if (searchValue != '') {
      setTimeout(() => {
        this.config.where[column] = searchValue;
        this.pagePayload.page = 0;
        this.table.offset = 0;
        this.config.isLoadingFirstTime = false;
        this.config.start = 0;
        this.onChangeTable(this.config);
      }, 2);
    }
  }

  searchStatus(event) {
    this.seasonObj.statusOptions = [...this.seasonObj.statusOptions];
  }

  /** On Select Option */
  onselectOption(event: any, type: string) {
    this.config.start = 0;
    this.config.where[type] = [event];
    setTimeout(() => {
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.onChangeTable(this.config);
    }, 200);
  }

  /** On clear selection */
  onselectionClear(event: any, type: string) {
    this.config.where[type] = event.isTrusted == true ? [] : event;
    setTimeout(() => {
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.onChangeTable(this.config);
    }, 200);
  }

  /** Close Season  */
  closeCreateSeason() {
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.onChangeTable(this.config);
  }


  // TODO[Dmitry Teplov] wrap dynamic limit in a separate component.
  public onLimitChange(limit: any): void {
    this.changePageLimit(limit);
    this.table.limit = this.pageSize;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor((this.table.rowCount - 1) / this.table.limit);
      }
    });
  }

  private changePageLimit(limit: any): void {
    this.pageSize = parseInt(limit, 10);
    this.pagePayload.itemsPerPage = limit;
    this.dataService.pagePreferancePost({
      pageSize: this.pageSize,
      currentPage: this.config.start,
      pageIndex: this.table.offset,
      user_id: JSON.parse(localStorage.getItem('user')).id,
      project_id: this.seasonObj.projectId
    }, 'season_list_page');
    this.onChangeTable(this.config);
  }

  /**
  * Description: Page change event
  * @param e pager object
  */
  firstTimeLoad: boolean = false;
  openedPage: number = 0;

  onPageChange(e): void {
    this.openedPage = e.offset;
    // this.currentPage = e.offset;
    this.pagePayload.page = e.offset * this.pageSize;
    if (this.firstTimeLoad) {
      this.table.offset = this.openedPage;
      this.dataService.pagePreferancePost({
        pageSize: this.pageSize,
        currentPage: this.config.start,
        pageIndex: this.table.offset,
        user_id: JSON.parse(localStorage.getItem('user')).id,
        project_id: this.seasonObj.projectId
      }, 'season_list_page');
      this.onChangeTable(this.config);
    } else {
      this.dataService.pagePreferanceGet('season_list_page').then((res: any) => {
        if (res.data.length == undefined) {
          this.pageSize = res.data.pageSize;
          this.config.itemsPerPage = res.data.pageSize;
          this.config.start = res.data.currentPage;
          this.table.offset = res.data.pageIndex;
          this.onChangeTable(this.config);
        } else {
          this.onChangeTable(this.config);
        }
      }, (err: any) => {
        console.warn("Error while fetching page set")
      });
    }
    this.firstTimeLoad = true;
  }

  cellClick(data: any) {
    if (this.commonJs.checkAccess('episodes', 'view', { 'baiPass': ['admin'] }, this.projectId)) {
      this.router.navigate([this.commonJs.getClientCodePrefix() + '/projects/episodes/' + this.projectId + '/' + data]);
    }
  }

  refreshFilter() {
    this.onChangeTable(this.config);
  }

  clearFilter() {
    this.config.where = {};
    this.config.where.project_id = this.projectId;
    this.config.FilterByColumns = {};
    this.config.isLoadingFirstTime = false;
    this.config.isClearFilter = true;
    this.seasonObj.status = [];
    this.startDate = '';
    this.endDate = '';
    this.onChangeTable(this.config);
  }

  // On datatable body scrolled
  onScrolled(event){

    // console.log(event);
    if(event['offsetY'] && (event['offsetY'] != this.previousOffsetY)){
      this.isFullScreen = event['offsetY']>this.previousOffsetY ? true : false;
      this.dataService.fullScreenView.next(this.isFullScreen);

      this.previousOffsetY = event['offsetY'];
    }
  }

}
