import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Ng2TableModule } from 'ng2-table/ng2-table';

import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SidebarModule } from 'ng-sidebar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../shared/shared.module';
import { UsersRoutingModule } from './users-routing.module';
import { CreateUserComponent } from './create-user/create-user.component';
import { RolesComponent } from './roles/roles.component';

import { Daterangepicker } from 'ng2-daterangepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ManageColumnComponent } from './manage-column/manage-column.component';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { AutoCompleteModule, GrowlModule, InputSwitchModule, MultiSelectModule } from 'primeng/primeng';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxFileDropModule } from 'ngx-file-drop';
import { EmployeesGridComponent } from './employees-grid/employees-grid.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AlphabetOnlyDirective } from './create-user/alphabet-only.directive';
import { AppRoutingModule } from 'src/app/app-routing.module';

@NgModule({
  imports: [
    CommonModule,
    // AppRoutingModule,
    UsersRoutingModule,
    FormsModule,
    SharedModule,
    Ng2TableModule,
    NgxFileDropModule,
    NgxDatatableModule,
    TooltipModule,
    PaginationModule,
    SidebarModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),

    Daterangepicker,
    InfiniteScrollModule,
    DragulaModule,
    AutoCompleteModule,
    GrowlModule,
    MultiSelectModule,
    NgMultiSelectDropDownModule,
    DragDropModule,
    InputSwitchModule
  ],
  entryComponents: [
  ],
  providers: [
    DragulaService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  declarations: [
    CreateUserComponent,
    RolesComponent,
    ManageColumnComponent,
    EmployeesGridComponent,
    AlphabetOnlyDirective]
})
export class UsersModule { }
