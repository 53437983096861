import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { DataService } from '../../../services/data.service';
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
import { ToastrService } from '../../../services/toastr.service';
declare var $: any;
import * as moment from 'moment';
import * as _ from "lodash";

@Component({
	selector: 'app-comments',
	templateUrl: './comments.component.html',
	styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
	entityName = '';
	entityNameSingular = 'Comments';

	projectId = '';
	uploadUrl = this.commonJs.getUploadUrl();
	projectroletype = JSON.parse(localStorage.getItem('projectRoleType'));
	userId = localStorage.getItem('userId');
	is_external_login: boolean = false;
	is_external_review: boolean = false;

	@Input('attr.data') dataHtml: any;
	@ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
	@ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
	@Output() closeSideWindow = new EventEmitter();
	@Output() displayDetail = new EventEmitter();
	@ViewChild('scrollToBottom', { static: false }) private scrollToBottomContainer: ElementRef;

	data: any;
	comments = [];
	taskAssVerList = [];
	taskVersionsData = [];


	constructor(private dataService: DataService, public commonJs: CommonJs,
		private activatedRoute: ActivatedRoute) { }

	ngOnInit() {
		if (this.dataHtml != undefined) {
			this.data = this.dataHtml;
			this.getCommentList();
		}
		this.scrollToBottom();
	}

	getCommentList() {
		// let vId = '';
		// if (this.data.allow_status) {
		// 	vId = this.data.id;
		// } else {
		// 	vId = this.data.task_version_id;
		// }

		this.dataService.getOtherData('commentList', { 'project_id': this.data.projectId, 'task_id': this.data.task.id, 'task_version_id': this.data.task.task_version_id })
			.subscribe((success) => {
				var tempComm = success.data;
        this.comments = tempComm.map((tempcomData) => {
          tempComm.forEach((comment_data) => {
            comment_data.comment = comment_data.comment.replace('<br />', ' \n ').trim();

            comment_data.comment = comment_data.comment.replace(/@([\w-]+)/g, (match, word) => {
              // Edit the word here, for example, add "Edited" in front of it.
              return `<span class="badge badge-secondary mentionedUserTag " >@ ${word.replace('_',' ')}</span>`;
            });

          });
          return tempcomData
        });

				this.taskAssVerList = success.taskAssVerList;
			}, (rejected) => {

			});
	}

	ngAfterViewChecked() {
		this.scrollToBottom();
	}

	scrollToBottom(): void {
		try {
			this.scrollToBottomContainer.nativeElement.scrollTop = this.scrollToBottomContainer.nativeElement.scrollHeight;
		} catch (err) { }
	}

   // For getting first two letters of name
   getInitials(inputString: string): string {
    const words = inputString.trim().split(' ');
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      const secondInitial = words[1].charAt(0).toUpperCase();
      return `${firstInitial}${secondInitial}`;
    } else if (words.length === 1) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      return `${firstInitial}`;
    } else {
      return '';
    }
  }

}
