import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
declare var $: any;
import * as _ from "lodash";
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { GridService } from 'src/app/services/grid.service';

@Component({
    selector: 'tasks-assign-and-comments',
    templateUrl: './tasks-assign-and-comments.component.html',
    styleUrls: ['./tasks-assign-and-comments.component.css']
})
export class TasksAssignAndCommentsComponent implements OnInit {

    entityName = '';
    entityNameSingular = '';
    entityNameTask = 'Task';

    projectId = '';
    is_external_login: boolean = false;
    is_external_review: boolean = false;
    commentTagUserList: Array<any> = [];

    @Input('attr.data') dataHtml: any;
    @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
    @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
    @Output() closeSideWindow = new EventEmitter();
    @ViewChild('scrollToBottomComments', { static: false }) private scrollToBottomCommentsContainer: ElementRef;
    @Output() closeCreateTask = new EventEmitter();
    @ViewChild("commentBox", { static: false }) commentBox;

    data: any;
    projectData = [];

    defaultImage = 'https://www.placecage.com/1000/1000';
    where: { [index: string]: any } = {};


    //Task Assignment
    tempType = '';
    isEpisodicSeries: boolean = false;
    isTaskAssignSubmitted: boolean = false;
    hideundertaking: boolean;
    isDisabled: boolean = false;
    SaveButtonText: string = 'SAVE';
    tasks: { [index: string]: any } = {};
    errors: { [index: string]: any } = {};
    focused: string = '';
    clientCode = localStorage.getItem('access_code');
    userId = localStorage.getItem('userId');

    types: Array<any> = [{ "id": 0, "name": "R&D" }, { "id": 1, "name": "Production" }];
    DateFormat: any;
    dateOptions: { [index: string]: any } = {};

    public endDate = {
        singleDatePicker: true,
        autoUpdateInput: true,
        showDropdowns: true,
        timePicker: true,
        //timePickerIncrement:5,
        autoApply: true,
        drops: 'down',
        locale: {
            format: 'DD MMM YYYY hh:mm A'
            //format: 'DD MMM YYYY'+ ' 23:59'
        }
    }

    public startDate = {
        singleDatePicker: true,
        autoUpdateInput: true,
        showDropdowns: true,
        //timePickerIncrement:5,
        timePicker: true,
        autoApply: true,
        drops: 'down',
        "maxDate": "31 Dec 2030",
        locale: {
            format: 'DD MMM YYYY hh:mm A'
            //format: 'DD MMM YYYY'+ ' 00:00'
        }
    }

    priority: Array<any> = this.appConfigs.task_priority;
    undertaking: Array<any> = this.appConfigs.task_undertaking;
    istransfer: number;

    task: any;

    isTransfer: boolean = false;
    settingsAll: any = JSON.parse(localStorage.getItem('settings'));
    assigned_to: Array<any> = [];

    isAssign: boolean = false;
    isAssignList: boolean = false;
    isCloseSidebar: boolean = false;
    isDisableAssign: boolean = false;
    isStatusDisabled: boolean = true;

    assigned_users: Array<any> = [];
    assigned_users1: Array<any> = [];


    //Comments
    isComments: boolean = false;
    isCommentSubmitted: boolean = false;
    uploadUrl = this.commonJs.getUploadUrl();
    taskComment: { [index: string]: any } = {};
    taskCommentStatus = [];
    //taskAssVerList = [];
    taskAssVerList: { [index: string]: any } = {};
    taskVersionsData = [];
    multipleFiles: Array<any> = [];
    TempMultipleFiles: Array<any> = [];
    isFirstTime: boolean = true;
    comments = [];

    currentTab = 'task-assignment';


    //Status Change
    currentRole = '';
    projectRoleType = JSON.parse(localStorage.getItem('projectRoleType'));
    projectRoles = JSON.parse(localStorage.getItem('projectRoles'));

    is_stagged_review: boolean = false;
    display_resche: boolean = false;
    taskDepts: { [index: string]: any } = {};
    departments: { [index: string]: any } = {};
    statusData = [];
    canChangeStatus: boolean = false;
    taskAssignViewPermission: boolean = false;
    taskAssignPermission: boolean = false;

    showDoneCheckbox: boolean = false;

    env = environment;

    isLocationBasedTaskAssignments: boolean = false;

    //Custom status
    statusArr = [];
    customStatusArr = [];

    retakeType = [];
    isShowRetakeTypes: boolean = false;
    isRetakeTypeMandatory: boolean = false;

    noUsersAddedInTeam: boolean = false;

    isTaskPermissions = [];

    isDeleteMode: boolean = false;
    deleteId: number;
    deleteName: string = '';
    DeleteButtonText: string = 'UNASSIGN';

    transfered_to: Array<any> = [];
    task_transfer: Array<any> = [];

    getFileUploadData: any = [];

    commentSButtonTooltip = 'Change Status';
    fileSizeError: boolean = false;
    getAllSettingResponse: any;
    getProjectDatabyId: any;
    busy: boolean = false;

    // config used for getting users list to MENTION them
    configDefault: any = {
        "start": 0,
        "itemsPerPage": '100',
        "sort": {
            "title": "#ID",
            "name": "id",
            "sort": "desc",
            "className": "hide-this hide-this"
        },
        "sortNew": {
            "id": "desc"
        },
        "FilterColumns": [
            "name",
            "email"
        ],
        "filtering": {
            "filterString": ""
        },
        "FilterByColumns": {
            "status": "",
            "location": "",
            "emp_code_final": "",
            "name": "",
            "mode": "",
            "primary_department": "",
            "joining_date": "",
            "vertical": "",
            "role_id": "",
            "reporting_to": "",
            "prod_person": "",
            "software_skills": "",
            "secondary_department": "",
            "designation": "",
            "dob": "",
            "gender": "",
            "exit_date": "",
            "attrition_type": "",
            "exit_reason": "",
            "tenure": "",
            "age": "",
            "email": "",
            "phone": "",
            "government_id": "",
            "address1": "",
            "address2": "",
            "city": "",
            "state": "",
            "country": "",
            "zipcode": "",
            "exp_duration": "",
            "exp_designation": "",
            "exp_company": "",
            "column1": "",
            "column2": "",
            "column3": "",
            "column4": "",
            "column5": "",
            "column6": ""
        },
        "where": {
            "status": [],
            "gender": [],
            "mode": [],
            "vertical": [],
            "role_id": [],
            "type": [],
            "primary_department": [],
            "secondary_department": [],
            "reporting_to": [],
            "prod_person": [],
            "software_skills": [],
            "attrition_type": [],
            "exit_reason": []
        },
        "columns": {},
        "export": false
    };
    selectedMentions: any[] = [];
    selectedBidType: {name:string} = {name:'Day'};
    bidTypes:Array<any> = [ {name:'Day'},{name:'Hour'}];

    constructor(
        private dataService: DataService,
        public commonJs: CommonJs,
        public appConfigs: AppConfigs,
        private activatedRoute: ActivatedRoute,
        private toastrService: ToastrService,
        private gridService: GridService,
    ) { }

    ngOnInit() {
        this.getAllSettingResponse = this.commonJs.getAllSettingResponse;
        if (this.dataHtml != undefined) {
            this.data = this.dataHtml;
            // console.log(this.dataHtml);
        }
        this.data.mode = 'create';
        this.activatedRoute.params.subscribe((params: Params) => {
            this.projectId = params['id'];


            let param: any = {
                project_id: this.projectId
            }
            this.dataService.getProjectData(param).subscribe((res: any) => {
                this.getProjectDatabyId = res.data;
            })
        });


        if (this.projectRoleType != undefined && this.projectRoleType['project_' + this.projectId] != undefined && this.projectRoleType['project_' + this.projectId] == 'external') {
            this.is_external_login = true;
        }

        if (this.projectRoles != undefined && this.projectRoleType != undefined && this.projectRoles['project_' + this.projectId] == 'lead' || this.projectRoleType['project_' + this.projectId] == 'supervisor' || this.projectRoleType['project_' + this.projectId] == 'production' || this.projectRoles['project_' + this.projectId] == 'client') {
            this.canChangeStatus = true;
        }

        this.taskAssignViewPermission = this.commonJs.checkAccess('task_assignment', 'view', { 'baiPass': ['admin'] }, this.projectId);
        this.taskAssignPermission = this.commonJs.checkAccess('task_assignment', 'add', { 'baiPass': ['admin'] }, this.projectId);


        this.currentRole = this.projectRoles['project_' + this.projectId];
        if (this.currentRole == 'admin') {
            this.commentSButtonTooltip = 'Add Notes';
        }

        this.entityName = this.data.type;
        this.entityNameSingular = this.data.type;

        //this.projectId = this.data.projectId;
        this.DateFormat = this.settingsAll.date_format;
        this.startDate.locale.format = 'DD MMM YYYY hh:mm A';
        this.focused = "focused";
        this.tasks = {
            project_id: this.projectId,
            name: '',
            bid_days: '',
            type: null,
            //end_date:moment().format('DD MMM YYYY hh:mm A'),
            status: []
        }

        let tasksData = {
            'projectId': this.projectId,
            'shot_id': this.data.data.id,
            'asset_id': this.data.data.id,
            'task_dept_column': this.data.task_dept_column,
            'task_dept_id': this.data.task_dept_id,
            'type': this.data.data.type,
            'task_assignment_id': this.data.task_assignment_id,
            'task_version_id': this.data.task_version_id,
        }
        //this.getTaskFromAssetShot(tasksData);

        if (this.data.task_dept_column == 'department_id') {
            this.data.department_id = this.data.task_dept_id;
            this.data.department_tasktype_id = null;
        } else {
            this.data.department_id = null;
            this.data.department_tasktype_id = this.data.task_dept_id;
        }

        this.getTaskFromAssetShot(tasksData).then((res) => {

            if (this.task.isDeptBasedTaskAccess && this.task.isCustomReview && this.task.deptArr < 1 && (this.projectRoles['project_' + this.projectId] != 'lead' && this.projectRoles['project_' + this.projectId] != 'client' && this.projectRoles['project_' + this.projectId] != 'admin')) {
                if (this.canChangeStatus) {
                    this.canChangeStatus = false;
                }

                if (this.taskAssignPermission) {
                    this.taskAssignPermission = false;
                }
            }
            if (this.task.task_assignment_id != null && this.task.task_assignment_id != '') {
                // this.isAssignList = true;
                // this.isAssign = false;
                this.currentTab = 'task-comments';
                this.changeTab('task-comments');
                //this.getAssetShotTaskStatus(tasksData);
                if (!this.taskAssignViewPermission) {
                    this.isAssignList = false;
                    this.isAssign = false;
                } else if (this.data.actualStatus == 'NA' || this.data.actualStatus == 'OOP' || this.data.actualStatus == 'On Hold' || this.task.revision != 1) {
                    this.isAssignList = false;
                    this.isAssign = false;
                    //this.taskAssignViewPermission = false;
                } else {
                    //this.getTransferHistory();
                }
                this.getProjectData().then((res) => {
                    this.data.isReview = true;
                    //this.data.allow_status = false;
                    //this.commentOnInit();
                });
                //this.getTransferHistory();
            } else if (this.data.actualStatus == 'NA' || this.data.actualStatus == 'OOP' || this.data.actualStatus == 'On Hold') {
                this.isAssignList = false;
                this.isAssign = false;
                this.taskAssignViewPermission = false;
                this.changeTab('task-comments');
            } else if (!this.taskAssignPermission && this.taskAssignViewPermission) {
                this.isAssignList = true;
                this.isAssign = false;
                this.getTransferHistory();
            } else {
                this.isAssign = true;
                this.isAssignList = false;
                this.isCloseSidebar = true;
                this.getProjectData().then((res) => {
                });
            }
            this.taskComment.isNotifyNextDept = this.task.is_next_dept_notified;

        });
        this.subngOnInit();
        //this.scrollToBottom();

        this.isTaskPermissions['task_assignment_add'] = this.commonJs.checkAccess('task_assignment', 'add', { 'baiPass': ['admin'] }, this.projectId);
        this.isTaskPermissions['task_assignment_view'] = this.commonJs.checkAccess('task_assignment', 'view', { 'baiPass': ['admin'] }, this.projectId);
        this.isTaskPermissions['task_assignment_edit'] = this.commonJs.checkAccess('task_assignment', 'edit', { 'baiPass': ['admin'] }, this.projectId);
        this.isTaskPermissions['task_assignment_delete'] = this.commonJs.checkAccess('task_assignment', 'delete', { 'baiPass': ['admin'] }, this.projectId);

        // mention

        this.gridService.postData('usersList', this.configDefault).subscribe((res: any) => {
            let tempArray = [];
            res.data.map((ele) => {
                tempArray.push({ id: ele.id, name: ele.name, combined: ele.name.split(' ').join('_') });
            });
            this.commentTagUserList = tempArray;
        });


    }

    changeTab(tab) {
        if (tab == 'task-assignment') {
            //this.isAssign = true;
            //this.isAssignList = true;
            if (!this.taskAssignPermission && this.taskAssignViewPermission) {
                if (this.task.task_assignment_id != null && this.task.task_assignment_id != '') {
                    this.isAssignList = true;
                    this.isAssign = false;
                    this.getTransferHistory();
                    //this.getTransferHistory();
                } else {
                    // this.isAssign = true;
                    this.isAssignList = true;
                    this.isCloseSidebar = true;
                }
            } else if (this.taskAssignPermission && !this.taskAssignViewPermission) {
                this.isAssign = false;
                this.isAssignList = false;
            } else if (this.taskAssignPermission && this.taskAssignViewPermission) {
                if (this.task.task_assignment_id != null && this.task.task_assignment_id != '') {
                    this.isAssignList = true;
                    this.isAssign = false;
                    this.getTransferHistory();
                    //this.getTransferHistory();
                } else {
                    this.isAssign = true;
                    this.isAssignList = false;
                    this.isCloseSidebar = true;
                }
            } else if (this.taskAssignViewPermission) {
                this.isAssignList = false;
                this.getTransferHistory();
            } else {
            }

            this.isComments = false;
        } else if (tab == 'task-comments') {
            this.isComments = true;
            this.isAssign = false;
            this.isAssignList = false;
            let tasksData = {
                'projectId': this.projectId,
                'shot_id': this.data.data.id,
                'asset_id': this.data.data.id,
                'task_dept_column': this.data.task_dept_column,
                'task_dept_id': this.data.task_dept_id,
                'type': this.data.data.type,
            }

            this.getAssetShotTaskStatus(tasksData).then((res) => {
                //this.data.status =
                this.getCommentList();
                //this.commentOnInit();
            }).then((res => {
                this.getStatusList().then((res) => {
                    this.commentOnInit();
                });
            }));
            //this.scrollToBottom();
            //this.getAssetShotTaskStatus(tasksData);

            this.data.allow_status = false;
            this.data.isReview = true;
            //this.commentOnInit();
        }
        this.currentTab = tab;
    }

    ngAfterViewInit() {
        $.AdminBSB.input.activate();
    }

    subngOnInit() {
        this.tasks = {
            name: '',
            bid_days: '',
            status: [],
            asset_shot: [],
            type: null
        }
        this.tasks.status = { id: "Inventory", name: "Inventory" };
        if (this.data.mode == 'create') {
            this.tasks.start_date = moment().format('DD MMM YYYY hh:mm A');
            this.startDate['startDate'] = moment().format('DD MMM YYYY hh:mm A');
            this.tasks.undertaking = "In House";
            this.tasks.priority = "Normal";
            this.tasks.asset_shot.subtype = '';
            this.tasks.end_date = moment().add(1, 'days').format('DD MMM YYYY hh:mm A');
            this.endDate['startDate'] = moment().add(1, 'days').format('DD MMM YYYY hh:mm A');
            this.tasks.type = 1;
            //this.projects.end_date = moment().format(this.DateFormat);
        }
        if (this.data.taskid && (this.data.mode == 'edit' || this.data.mode == 'view')) {
            this.focused = "focused";
            this.isDisabled = true;
            this.dataService.getDataById('tasks/' + this.data.taskid + '/edit')
                .subscribe((success) => {
                    this.tasks = success.data;
                    //this.tasks.end_date = moment().format('DD MMM YYYY hh:mm A');
                    if (!this.tasks.status) {
                        this.tasks.status = { id: "Inventory", name: "Inventory" };
                    }

                    if (!this.tasks.undertaking) {
                        this.tasks.undertaking = "In House";
                    }
                    if (!this.tasks.priority) {
                        this.tasks.priority = "Normal";
                    }
                    if (this.tasks.start_date) {
                        this.startDate['startDate'] = moment(this.tasks.start_date).format('DD MMM YYYY hh:mm A');
                        this.tasks.start_date = moment(this.tasks.start_date).format('DD MMM YYYY hh:mm A');
                    } else {
                        this.tasks.start_date = moment().format('DD MMM YYYY hh:mm A');
                    }
                    if (this.tasks.end_date) {
                        this.endDate['startDate'] = moment(this.tasks.end_date).format('DD MMM YYYY hh:mm A');
                        this.tasks.end_date = moment(this.tasks.end_date).format('DD MMM YYYY hh:mm A');
                    } else {
                        this.endDate['startDate'] = moment().add(1, 'days').format('DD MMM YYYY hh:mm A');
                        this.tasks.end_date = moment().add(1, 'days').format('DD MMM YYYY hh:mm A');
                    }
                }, (rejected) => {

                    this.SaveButtonText = "SAVE"
                });
            //this.getData();
        }
    }

    search(query, column) {
        if (column == 'assigned_to') {
            this.istransfer = 0;
            if (this.tasks.undertaking == 'In House') {
                this.getTaskAssignableUsers(this.task.id ? this.task.id : this.data.data.id, this.data['department_id'] ? this.data['department_id'] :this.task.department_id, query, '', 'assign', this.data.is_multiple_assign);
                // this.getTaskAssignableUsers(this.data.data.id, this.task.department_id, query, '', 'assign', this.data.is_multiple_assign);
            } else {
                this.getTaskAssignableUsers(this.task.id ? this.task.id : this.data.data.id, this.data['department_id'] ? this.data['department_id']: this.task.department_id, query, 'Outsourced', 'assign');
                // this.getTaskAssignableUsers(this.data.data.id, this.task.department_id, query, 'Outsourced', 'assign');
            }
            // if (this.tasks.undertaking == 'In House') {
            //     this.getTaskAssignableUsers(this.task.id, this.task.department_id, query, '', 'assign');
            // } else {
            //     this.getTaskAssignableUsers(this.task.id, this.task.department_id, query, 'Outsourced', 'assign');
            // }
        } else if (column == 'transfered_to') {

            if (this.tasks.undertaking == 'In House') {
                this.getTaskTransferableUsers(this.task.id, this.task.department_id, query, '', 'transfer');
            }
            else {
                this.getTaskTransferableUsers(this.task.id, this.task.department_id, query, 'Outsourced', 'transfer');
            }
        } else if (column == 'bidType') {
          this.bidTypes = [...this.bidTypes];
        }
    }

    getTaskFromAssetShot(tasksData) {
        return new Promise((resolve, reject) => {
            // console.log(this.data);
            let assetShot = tasksData.type == 'Shot' ? 'getTaskFromShot' : 'getTaskFromAsset';
            this.dataService.getListData(assetShot, { 'project_id': this.projectId, 'type': this.data.type, 'shot_id': tasksData.shot_id, 'asset_id': tasksData.asset_id, 'task_dept_id': tasksData.task_dept_id = tasksData.task_dept_id.replace("t", ""), 'task_dept_column': tasksData.task_dept_column })
                .subscribe((success) => {
                    this.task = success.data.task;
                    // console.log(this.task);
                    resolve(200);
                }, (rejected) => {

                });
        });
    }

    getAssetShotTaskStatus(tasksData) {
        return new Promise((resolve, reject) => {
            let assetShot = tasksData.type == 'Shot' ? 'getShotTaskStatus' : 'getAssetTaskStatus';
            this.dataService.getListData(assetShot, { 'project_id': this.projectId, 'task_id': this.task.id })
                .subscribe((success) => {
                    this.statusData = success.data.status;
                    this.data.status = this.statusData;
                    this.data.actualStatus = success.data.actualStatus;
                    resolve(200);
                }, (rejected) => {

                });
        });
    }

    onUtChange(undertaking, flag, form: NgForm) {
        if (this.istransfer == 0) {
            this.tasks.assigned_to = null;
        } else {
            this.tasks.transfered_to = null;
        }

        if (flag) {
            this.tasks.undertaking = undertaking;
        } else {
            this.tasks.undertaking = "In House";
        }
    }

    onAssign(form: NgForm) {
        this.isTaskAssignSubmitted = true;
        this.hideundertaking = true;
        this.SaveButtonText = 'SAVING';
        if (form.value.assigned_to) {
            form.value.assigned_to = this.tasks.assigned_to.id;
        }

        if ($('#start_date').val() != '' && $('#start_date').val() != undefined) {
            form.value.start_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').format('YYYY-MM-DD HH:mm:ss');
        } else {
            form.value.start_date = null;
        }
        if ($('#end_date').val() != '' && $('#end_date').val() != undefined) {
            form.value.end_date = moment($('#end_date').val(), 'DD MMM YYYY hh:mm A').format('YYYY-MM-DD HH:mm:ss');
        } else {
            form.value.end_date = null;
        }

        if (form.value.status) {
            form.value.status = this.tasks.status.id;
        }
        if (form.value.bid_days) {
            form.value.bid_days = form.value.bid_days;
        } else {
            form.value.bid_days = 0;
        }
        form.value.project_id = this.projectId;
        form.value.task_id = this.task.id;

        form.value.is_multiple_assign = false;

        if (form.value.undertaking == undefined) {
            form.value.undertaking = this.tasks.undertaking;
        }

        if (this.tasks.task_assignment_id && this.tasks.task_assignment_id != '' && !this.isTransfer) {
            form.value.assigned_to = this.tasks.assigned_to.id;
            this.dataService.updateData('taskAssignUpdate/' + this.tasks.task_assignment_id, form.value)
                .subscribe((success) => {
                    setTimeout(() => {
                        this.SaveButtonText = 'SAVE';
                        this.close(2);
                    }, 2);

                    this.isAssign = false;
                    this.isAssignList = true;
                    this.tasks = [];
                    //this.getAssignedUsers();
                    this.getTransferHistory();
                    this.errors = [];
                    this.isTaskAssignSubmitted = false;
                    this.tasks['id'] = form.value.task_id;
                    this.tasks['taskid'] = form.value.task_id;
                    this.tasks['task_id'] = form.value.task_id;
                }, (error) => {
                    if (error.error) {
                        var err = error.error;
                        this.errors = err.error;
                        this.SaveButtonText = 'SAVE';
                        this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
                        this.isTaskAssignSubmitted = false;
                    }
                });
        } else if (this.isTransfer) {
            form.value.assigned_to = this.tasks.assigned_to.id;
            if (form.value.transfered_to) {
                //form.value.transfered_to= this.tasks.status.id;
                form.value.to_user_id = this.tasks.transfered_to.id;
            }
            form.value.from_user_id = this.tasks.assigned_to.id;
            form.value.task_assignment_id = this.tasks.task_assignment_id;
            form.value.status = this.tasks.status.id;
            this.dataService.updateData('assignedTaskTransfer/' + this.tasks.task_assignment_id, form.value)
                .subscribe((success) => {
                    setTimeout(() => {
                        this.SaveButtonText = 'SAVE';
                        this.close(2);
                    }, 2);
                    this.isAssign = false;
                    this.isAssignList = true;
                    this.isTransfer = false;
                    this.tasks = [];
                    //this.getAssignedUsers();
                    this.getTransferHistory();
                    this.errors = [];
                    this.isTaskAssignSubmitted = false;
                    if (success.notifications.length > 0) {
                        let that = this;
                        _.forEach(success.notifications, function (notification, key) {
                            that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
                        });
                    }
                }, (error) => {
                    if (error.error) {
                        var err = error.error;
                        this.errors = err.error;
                        this.SaveButtonText = 'SAVE';
                        this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
                        this.isTaskAssignSubmitted = false;
                    }
                });
        } else {
            form.value.status = this.tasks.status.id;
            this.dataService.storeData('taskAssign', form.value)
                .subscribe((success) => {
                    setTimeout(() => {
                        this.SaveButtonText = 'SAVE';
                        this.close(1);
                    }, 2);

                    //this.dataService.addNotification(this.clientCode,this.tasks.assigned_to.id,notification);
                    if (success.data.notifications.length > 0) {
                        let that = this;
                        _.forEach(success.data.notifications, function (notification, key) {
                            that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
                        });
                    }
                    this.errors = [];
                    this.isTaskAssignSubmitted = false;
                }, (error) => {
                    if (error.error) {
                        var err = error.error;
                        this.errors = err.errors;
                        this.SaveButtonText = 'SAVE';
                        this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
                        this.isTaskAssignSubmitted = false;
                    }
                });
        }
    }

    getTaskAssignableUsers(id, department_id, query = '', undertaking = '', activity, is_multiple_assign = false) {
        if (undertaking == 'Outsourced') {
            this.dataService.getListData('getTaskAssignableUsers', { 'project_id': this.projectId, 'task_id': id, 'department_id': department_id, 'query': query['query'], 'undertaking': undertaking, 'is_multiple_assign': is_multiple_assign })
                .subscribe((success) => {
                    this.assigned_to = success.data;
                    if (this.assigned_to.length == 0) {
                        this.noUsersAddedInTeam = true;
                    }
                }, (rejected) => {

                });
        } else {
            this.dataService.getListData('getTaskAssignableUsers', { 'project_id': this.projectId, 'task_id': id, 'department_id': department_id, 'query': query['query'], 'undertaking': '', 'is_multiple_assign': is_multiple_assign, 'isLocationBasedTaskAssignments': this.isLocationBasedTaskAssignments })
                .subscribe((success) => {
                    this.assigned_to = success.data;
                    if (this.assigned_to.length == 0) {
                        this.noUsersAddedInTeam = true;
                    }
                }, (rejected) => {

                });
        }
    }

    getTaskTransferableUsers(id, department_id, query = '', outsource, activity) {
        if (outsource == 'Outsourced') {
            this.dataService.getListData('getTaskTransferableUsers', { 'project_id': this.projectId, 'task_id': id, 'department_id': department_id, 'query': query['query'], 'outsource': outsource })
                .subscribe((success) => {
                    this.transfered_to = success.data;
                    this.transfered_to = this.transfered_to.filter(user => user.id !== this.tasks.assigned_to.id);
                }, (rejected) => {

                });
        }
        else {
            this.dataService.getListData('getTaskTransferableUsers', { 'project_id': this.projectId, 'task_id': id, 'department_id': department_id, 'query': query['query'], 'outsource': outsource, 'isLocationBasedTaskAssignments': this.isLocationBasedTaskAssignments })
                .subscribe((success) => {
                    this.transfered_to = success.data;
                    this.transfered_to = this.transfered_to.filter(user => user.id !== this.tasks.assigned_to.id);
                }, (rejected) => {

                });
        }
    }

    getTransferHistory() {
        //this.isTransfer = false;
        //this.isDeleteMode = false;
        this.dataService.getListData('getTransferHistory/' + this.task.id, '')
            .subscribe((success) => {
                this.assigned_users = success.data;
            }, (rejected) => {

            });
    }

    getAssignedTask(id, type, version) {
        this.tempType = type;
        if (type != 'transfer') {
            this.hideundertaking = false;
        } else {
            this.hideundertaking = true;
        }
        this.isDisableAssign = true;
        this.isStatusDisabled = false;

        if (type == 'transfer') {
            this.tasks.priority = "Normal";
            this.isTransfer = true;
            this.tasks.end_date = moment(this.tasks.end_date).format('DD MMM YYYY hh:mm A');
            this.errors = [];
        }
        this.dataService.getDataById('getAssignedTask/' + id + '/edit')
            .subscribe((success) => {
                this.tasks = success.data;
                this.tasks.task_id = success.data.task_id;
                this.tasks.task_assignment_id = id;
                this.isAssign = true;
                this.isAssignList = false;
                this.tasks.version = version;
                if (this.tasks.start_date) {
                    this.startDate['startDate'] = moment(this.tasks.start_date).format('DD MMM YYYY hh:mm A');
                    this.tasks.start_date = moment(this.tasks.start_date).format('DD MMM YYYY hh:mm A');
                } else {
                    this.tasks.start_date = moment().format('DD MMM YYYY hh:mm A');
                }
                if (this.tasks.end_date) {
                    this.endDate['startDate'] = moment(this.tasks.end_date).format('DD MMM YYYY hh:mm A');
                    this.tasks.end_date = moment(this.tasks.end_date).format('DD MMM YYYY hh:mm A');
                } else {
                    this.tasks.end_date = moment().format('DD MMM YYYY hh:mm A');
                }
                this.focused = 'focused';

            }, (rejected) => {

            });
    }

    deleteAssignedTask(event, id, name) {
        event.stopPropagation();
        this.deleteId = id;
        this.deleteName = name;
        this.isDeleteMode = true;
        this.isAssign = false;
        this.isAssignList = false;
    }

    deleteAssignedTaskAction(id) {
        let that = this;
        this.DeleteButtonText = 'UNASSIGNING';
        this.dataService.deleteData('assigendTaskDestroy', { 'projectId': this.projectId, 'id': id })
            .subscribe((success) => {
                that.DeleteButtonText = 'UNASSIGNED';
                setTimeout(() => {
                    that.subngOnInit();
                    that.DeleteButtonText = "UNASSIGN";
                    that.isDeleteMode = false;
                    that.isAssign = false;
                    that.isAssignList = true;
                    //this.getAssignedUsers();
                    this.getTransferHistory();
                    this.close(2);
                }, 2);

                if (success.data.notifications.length > 0) {
                    let that = this;
                    _.forEach(success.data.notifications, function (notification, key) {
                        that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
                    });
                }

            }, (rejected) => {
                if (rejected._body) {
                    var err = JSON.parse(rejected._body);
                    this.errors = err.error;
                    this.DeleteButtonText = "UNASSIGN";
                    this.toastrService.notify('warn', '', err.message);
                }
            });
    }

    assignTaskForm() {
        this.tempType = 'add';
        this.hideundertaking = true;
        this.isAssign = true;
        this.isAssignList = false;
        this.isDisableAssign = false;
        this.tasks.bid_days = '';
        //this.startDate.locale.format = this.DateFormat;
        //this.startDate.locale.format = moment().format('DD MMM YYYY hh:mm');
        //this.endDate.locale.format = moment().format('DD MMM YYYY hh:mm');
        this.tasks.start_date = moment().format('DD MMM YYYY hh:mm A');
        this.tasks.end_date = moment().format('DD MMM YYYY hh:mm A');
        //this.tasks.end_date = '';
        this.tasks.assigned_to = [];
        this.tasks.status = { id: "Inventory", name: "Inventory" };
        this.tasks.undertaking = "In House";
        this.tasks.priority = "Normal";
        this.errors = [];
        //this.tasks.start_date = moment().format(this.DateFormat);
    }


    onSubmit(form: NgForm) {
        this.busy = true;
        this.isCommentSubmitted = true;
        this.SaveButtonText = 'SAVING';
        form.value.project_id = this.projectId;
        form.value.task_id = this.task.id;
        if (form.value.comment) {
            let comment = form.value.comment;
            comment = comment.replace(/\r?\n/g, '<br />');
            form.value.comment = comment;
        }

        // if (this.data.allow_status) {
        //     form.value.task_version_id = this.data.id;
        // } else {
        //     form.value.task_version_id = this.task.task_version_id;
        // }

        form.value.task_version_id = this.task.task_version_id;
        form.value.revision = this.task.revision;
        form.value.version = this.task.version;
        form.value.department_id = this.task.department_id;
        form.value.department_tasktype_id = this.task.department_tasktype_id;
        if (this.data.status != this.taskComment.status.id && !this.data.is_artist) {
            form.value.status = this.taskComment.status;
        } else {
            form.value.status = '';
        }

        if (this.multipleFiles.length > 0) {

            /** get current time stamp */

            var isPerformanceSupported = (
                window.performance &&
                window.performance.now &&
                window.performance.timing &&
                window.performance.timing.navigationStart
            );

            var timeStampInMs = (
                isPerformanceSupported ?
                    window.performance.now() +
                    window.performance.timing.navigationStart :
                    Date.now()
            );

            /** Dir name */

            if (this.getProjectDatabyId.projects.episodic_series != 0) {

                this.multipleFiles[0].dir_name = this.getProjectDatabyId.projects.name + '/' + this.data.department_name + '/' + this.data.data.shot_number;

            } else {

                this.multipleFiles[0].dir_name = this.getProjectDatabyId.projects.name + '/' + this.data.department_name + '/' + this.data.data.shot_number;

            }

            /** Studio image name with revision and version */
            const imageName = this.multipleFiles[0].image_display_name.split('.');

            let revision: any = 'R00' + this.task.revision
            let version: any = 'V00' + this.task.version

            const stFileName = imageName[0] + '_' + Date.now() + '_' + revision + '_' + version + '.' + imageName[1];
            this.multipleFiles[0].studio_image_name = stFileName;
            form.value.images = this.multipleFiles;
            form.value.dir_name = this.multipleFiles[0].dir_name;
            form.value.studio_image_name = stFileName;
        }

        // if (this.multipleFiles.length > 0) {
        //     form.value.images = this.multipleFiles;
        // }

        //form.value.reschedule = this.taskAssVerList;
        let that = this;
        let i = 0;
        form.value.reschedule = [];
        _.forEach(this.taskAssVerList, function (value) {
            form.value.reschedule[i] = value;

            if ($('.start_date-' + value.task_assignment_id).val() != '' && $('.start_date-' + value.task_assignment_id).val() != undefined) {
                form.value.reschedule[i]['start_date'] = moment($('.start_date-' + value.task_assignment_id).val()).format('YYYY-MM-DD HH:mm:ss');
            } else {
                form.value.reschedule[i]['start_date'] = null;
            }

            if ($('.end_date-' + value.task_assignment_id).val() != '' && $('.end_date-' + value.task_assignment_id).val() != undefined) {
                form.value.reschedule[i]['end_date'] = moment($('.end_date-' + value.task_assignment_id).val()).format('YYYY-MM-DD HH:mm:ss');
            } else {
                form.value.reschedule[i]['end_date'] = null;
            }

            i++;
        });

        form.value.old_status = this.data.actualStatus;
        form.value.is_external_login = this.is_external_login;

        if (this.taskComment.isNotifyNextDept) {
            form.value.isNotifyNextDept = this.taskComment.isNotifyNextDept;
        }

        if (this.isShowRetakeTypes) {
            if (this.isRetakeTypeMandatory && this.taskComment.retake_type == undefined) {
                this.errors.retake_type = "Required";
                this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
                return false;
            }

            if (this.taskComment.retake_type != undefined) {
                form.value.isShowRetakeTypes = true;
                form.value.retake_type = this.taskComment.retake_type.id;
                form.value.retake_typeName = this.taskComment.retake_type.item_name;
            }
        }

        this.dataService.fileUploadForcomment('commentStore', this.getFileUploadData, form.value)
            .subscribe((success) => {
                this.taskComment.comment = '';
                //this.getCommentList();
                if (this.data.actualStatus != '' && this.data.actualStatus != null) {
                    // this.close(1);
                    this.getCommentList();

                } else {
                    this.getCommentList();
                }
                this.busy = false;
                this.multipleFiles = [];
                this.getFileUploadData = [];
                this.TempMultipleFiles = [];
                this.isFirstTime = true;
                this.toastrService.notify('success', '', 'Comment added successfully!');
                if (success.data.notifications.length > 0) {
                    let that = this;
                    _.forEach(success.data.notifications, function (notification, key) {
                        that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
                    });
                }
                this.isCommentSubmitted = false;
            }, (error) => {
                if (error.error) {
                    var err = error.error;
                }
                this.busy = false;
                this.isCommentSubmitted = false;
            });
    }

    getProjectData() {
        return new Promise((resolve, reject) => {
            this.dataService.getDataById('getProjectSettings/' + this.projectId)
                .subscribe((success) => {
                    this.is_external_review = success.data.is_external_review;
                    this.isLocationBasedTaskAssignments = success.data.is_location_based_task_assignment;
                    resolve(200);
                }, (rejected) => {

                });
        });
    }

    checkDepartmentStaggedReview(dept_id, dtt_id) {
        return new Promise((resolve, reject) => {
            let department = {};
            department['dept_id'] = dept_id;
            department['dtt_id'] = dtt_id;
            this.dataService.getListData('checkDepartmentStaggedReview', { 'project_id': this.projectId, 'department': department })
                .subscribe((success) => {
                    this.is_stagged_review = success.data.is_stagged_review;
                    resolve(200);
                }, (rejected) => {

                });
        });
    }

    getStatusList() {
        return new Promise<void>((resolve, rejected) => {
            // this.dataService.getOtherData('getStatusList', {})
            //   .subscribe((success) => {
            this.statusArr = [...this.dataService.allStatusList]
            resolve();

        });
        // });
    }

    getRetakeTypes(query = '') {
        this.dataService.getOtherData('searchItemList', { list_name: 'retake_type', 'query': query['query'] })
            .subscribe((success) => {
                this.retakeType = success.data;
            }, (rejected) => {

            });
    }

    commentOnInit() {
        let that = this;
        _.forEach(this.statusArr, function (value) {
            that.customStatusArr[value.status] = value.studio_status;
        });
        this.taskCommentStatus = [{ "id": "On Hold", "name": "On Hold", 'studioStatus': this.customStatusArr['On Hold'] }];
        //this.taskCommentStatus.push({"id":"OOP","name":"OOP"});
        if (this.data.actualStatus == 'Inventory') {

        } else if (this.data.actualStatus == 'Awaiting Inventory') {
            //this.taskCommentStatus.push({"id":"NA","name":"NA"});
        } else if (this.data.actualStatus == 'WIP') {
            if (!this.data.isReview) {
                this.taskCommentStatus.push({ "id": "Sent for Review", "name": "Sent for Review", 'studioStatus': this.customStatusArr['Sent for Review'] });
            }
        } else if (this.data.actualStatus == 'Sent for Review') {
            this.taskCommentStatus.push({ "id": "Done", "name": "Done", 'studioStatus': this.customStatusArr['Done'] });
            this.taskCommentStatus.push({ "id": "Retake", "name": "Retake", 'studioStatus': this.customStatusArr['Retake'] });

            this.checkDepartmentStaggedReview(this.data.department_id, this.data.department_tasktype_id).then((res) => {
                if (this.is_external_review && (!this.is_stagged_review)) {
                    this.taskCommentStatus.push({ "id": "Sent to Client", "name": "Sent to Client", 'studioStatus': this.customStatusArr['Sent to Client'] });
                    if (this.data.task_type == 'R&D') {
                        this.taskCommentStatus.push({ "id": "Approved", "name": "Approved", 'studioStatus': this.customStatusArr['Approved'] });
                    }
                } else {
                    this.taskCommentStatus.push({ "id": "Approved", "name": "Approved", 'studioStatus': this.customStatusArr['Approved'] });
                }
            });

        } else if (this.data.actualStatus == 'Done') {
            this.taskCommentStatus.push({ "id": "Retake", "name": "Retake", 'studioStatus': this.customStatusArr['Retake'] });

            this.checkDepartmentStaggedReview(this.data.department_id, this.data.department_tasktype_id).then((res) => {
                if (this.is_external_review && (!this.is_stagged_review)) {
                    this.taskCommentStatus.push({ "id": "Sent to Client", "name": "Sent to Client" });
                } else {
                    this.taskCommentStatus.push({ "id": "Approved", "name": "Approved", 'studioStatus': this.customStatusArr['Approved'] });
                }
            });

        } else if (this.data.actualStatus == 'Sent to Client') {
            if (this.currentRole == 'client') {
                this.taskCommentStatus.push({ "id": "Approved", "name": "Approved", 'studioStatus': this.customStatusArr['Approved'] });
                this.taskCommentStatus.push({ "id": "Retake", "name": "Retake", 'studioStatus': this.customStatusArr['Retake'] });
            }

        } else if (this.data.actualStatus == 'Retake' || this.data.actualStatus == 'Unapproved' || this.data.actualStatus == 'CBB') {

        } else if (this.data.actualStatus == 'Approved') {
            this.checkDepartmentStaggedReview(this.data.department_id, this.data.department_tasktype_id);
            this.taskCommentStatus = [
                { "id": "Unapproved", "name": "Unapproved", 'studioStatus': this.customStatusArr['Unapproved'] },
                { "id": "CBB", "name": "CBB", 'studioStatus': this.customStatusArr['CBB'] },
            ];

        } else if (this.data.actualStatus == 'NA') {
            this.taskCommentStatus = [];
        } else if (this.data.actualStatus == 'On Hold') {
            this.taskCommentStatus = [
                { "id": "Off Hold", "name": "Off Hold", 'studioStatus': 'Off Hold' }
            ];
        }
        else {
            this.taskCommentStatus.push({ "id": "Sent for Review", "name": "Sent for Review", 'studioStatus': this.customStatusArr['Sent for Review'] });
            this.taskCommentStatus.push({ "id": "Done", "name": "Done", 'studioStatus': this.customStatusArr['Done'] });
            this.taskCommentStatus.push({ "id": "Retake", "name": "Retake", 'studioStatus': this.customStatusArr['Retake'] });
            this.taskCommentStatus.push({ "id": "Unapproved", "name": "Unapproved", 'studioStatus': this.customStatusArr['Unapproved'] });
            this.taskCommentStatus.push({ "id": "CBB", "name": "CBB", 'studioStatus': this.customStatusArr['CBB'] });
            this.dataService.getDataById('getProjectSettings/' + this.projectId)
                .subscribe((success) => {
                    if (success.data.is_external_review) {
                        this.taskCommentStatus.push({ "id": "Sent to Client", "name": "Sent to Client", 'studioStatus': this.customStatusArr['Sent to Client'] });
                    } else {
                        this.taskCommentStatus.push({ "id": "Approved", "name": "Approved", 'studioStatus': this.customStatusArr['Approved'] });
                    }
                }, (rejected) => {

                });

        }

        if (this.data.actualStatus != '') {
            this.taskComment.status = { "id": this.data.status, "name": this.data.status, 'studioStatus': this.customStatusArr[this.data.actualStatus] };
        }
    }

    getCommentList() {
        // let vId = '';
        // if (this.data.allow_status) {
        //     vId = this.data.id;
        // } else {
        //     vId = this.data.task_version_id;
        // }

        this.taskAssVerList = [];
        this.dataService.getOtherData('commentList', { 'project_id': this.projectId, 'task_id': this.task.id, 'task_version_id': this.task.task_version_id, 'actualStatus': this.data.actualStatus })
            .subscribe((success) => {
                var tempComm = success.data;
                this.comments = tempComm.map((tempcomData) => {
                    tempComm.forEach((comment_data) => {
                        comment_data.comment = comment_data.comment.replace('<br />', ' \n ').trim();

                        comment_data.comment = comment_data.comment.replace(/@([\w-]+)/g, (match, word) => {
                            // Edit the word here, for example, add "Edited" in front of it.
                            return `<span class="badge badge-secondary mentionedUserTag " >@ ${word.replace('_', ' ')}</span>`;
                        });

                    });
                    return tempcomData
                });
                this.scrollToBottom('getCommentList');
                this.taskAssVerList = success.taskAssVerList;
            }, (rejected) => {

            });
    }

    scrollToBottom(from): void {
        try {
            setTimeout(() => {
                if (this.scrollToBottomCommentsContainer != undefined) {
                    this.scrollToBottomCommentsContainer.nativeElement.scrollTop = this.scrollToBottomCommentsContainer.nativeElement.scrollHeight;
                }
            }, 2);
            //this.scrollToBottomCommentsContainer.nativeElement.scrollTo({left: 0 , top: this.scrollToBottomCommentsContainer.nativeElement.scrollHeight, behavior: 'smooth'});
        } catch (err) {
            //console.log(err);
        }
    }

    multipleFilesUpdated(files) {
        let i;
        let multipleFiles_temp = [];
        this.getFileUploadData = files;
        for (i = 0; i < files.length; i++) {
            let file = files[i];
            let temp_file = {};

            if (file) {
                var reader = new FileReader();

                temp_file['image_name'] = file.name;
                temp_file['image_display_name'] = file.name;
                temp_file['image_type'] = file.type;

                reader.onload = (event: any) => {
                    if (this.getAllSettingResponse.is_aws_s3_enable == 0) {
                        let size = (event.total / 1024 / 1024).toFixed(2);
                        let fileSize = Number(size);
                        if (fileSize > 3) {
                            this.fileSizeError = true;
                        }
                    }
                    // // temp_file['url'] = event.target.result;
                    // if (temp_file['image_type'] != 'image/png' && temp_file['image_type'] != 'image/jpg' && temp_file['image_type'] != 'image/jpeg' && temp_file['image_type'] != 'image/gif') {
                    //   temp_file['preview_src'] = './assets/images/default/attechment.png';
                    // } else {
                    //   // temp_file['preview_src'] = temp_file['url'];
                    // }
                }
                reader.readAsDataURL(file);

                setTimeout(() => {
                    multipleFiles_temp.push(temp_file);
                }, 0);
            }
        }

        setTimeout(() => {
            if (this.isFirstTime) {
                this.multipleFiles = this.multipleFiles.concat(multipleFiles_temp);
                this.isFirstTime = false;
            } else {
                this.multipleFiles = this.TempMultipleFiles.concat(multipleFiles_temp);
            }
        }, 0);
    }

    unlinkMultiImage(index, event) {
        event.stopPropagation();
        this.multipleFiles.splice(index, 1);
        this.getFileUploadData = [];
        this.fileSizeError = false;
    }

    statusChange(column, event) {
        this.taskComment.status = event;
        // if ( event.id == 'Retake' || event.id == 'CBB' || event.id == 'Unapproved' ) {
        //     this.display_resche = true;
        // } else {
        //     this.display_resche = false;
        // }


        if (event.id == 'Retake' || event.id == 'CBB' || event.id == 'Unapproved') {
            this.display_resche = true;
            if (this.currentRole != 'client') {
                this.getTaskAssignments();
            }
            this.isShowRetakeTypes = true;
            this.getRetakeTypes();
            this.isRetakeTypeMandatory = this.settingsAll.is_retake_type_mandatory;
        } else {
            this.isShowRetakeTypes = false;
            this.display_resche = false;
        }
    }

    getTaskAssignments() {
        this.taskAssVerList = [];
        // let vId = '';
        // if (this.data.allow_status) {
        //   vId = this.task.task_version_id;
        // } else {
        //   vId = this.task.task_version_id;
        // }
        this.dataService.getOtherData('getAssignmentsFromComments', { 'project_id': this.task.project_id, 'task_id': this.task.id, 'task_version_id': this.task.task_version_id, 'status': this.data.status, 'to_status': this.taskComment.status, 'isExternalReview': this.is_external_review, 'isStagedReview': this.is_stagged_review })
            .subscribe((success) => {
                this.taskAssVerList = success.taskAssVerList;

                let i = 0;
                let that = this;
                _.forEach(this.taskAssVerList, function (v) {
                    /*if (v.start_date) {
                      that.startDate['startDate'] = moment(v.start_date).format('DD MMM YYYY hh:mm A');
                      that.taskAssVerList[i]['start_date'] = moment(v.start_date).format('DD MMM YYYY hh:mm A');
                    }*/
                    that.dateOptions[i] = [];
                    that.dateOptions[i]['start_date'] = {
                        singleDatePicker: true,
                        autoUpdateInput: true,
                        showDropdowns: true,
                        timePickerIncrement: 5,
                        timePicker: true,
                        autoApply: true,
                        drops: 'down',
                        "maxDate": "31 Dec 2030",
                        locale: {
                            format: 'DD MMM YYYY hh:mm A'
                            //format: 'DD MMM YYYY'+ ' 00:00'
                        }
                    };

                    that.dateOptions[i]['end_date'] = {
                        singleDatePicker: true,
                        autoUpdateInput: true,
                        showDropdowns: true,
                        timePicker: true,
                        timePickerIncrement: 5,
                        autoApply: true,
                        drops: 'down',
                        locale: {
                            format: 'DD MMM YYYY hh:mm A'
                            //format: 'DD MMM YYYY'+ ' 23:59'
                        }
                    };

                    if (that.taskAssVerList[i]['start_date'] != null && that.taskAssVerList[i]['start_date'] != '') {
                        that.dateOptions[i]['start_date']['startDate'] = moment(that.taskAssVerList[i]['start_date']).format('DD MMM YYYY hh:mm A');
                        that.taskAssVerList[i]['start_date'] = moment(that.taskAssVerList[i]['start_date']).format('DD MMM YYYY hh:mm A');
                    }

                    if (that.taskAssVerList[i]['end_date'] != null && that.taskAssVerList[i]['end_date'] != '') {
                        that.dateOptions[i]['end_date']['startDate'] = moment(that.taskAssVerList[i]['end_date']).format('DD MMM YYYY hh:mm A');
                        that.taskAssVerList[i]['end_date'] = moment(that.taskAssVerList[i]['end_date']).format('DD MMM YYYY hh:mm A');
                    }
                    i++;
                });

                //disable checkbox for single artist retake and CBB / Unapproved
                if (this.taskComment.status.name != 'CBB' && this.taskComment.status.name != 'Unapproved') {
                    if (this.taskComment.status.name == 'Retake' && this.taskAssVerList.length > 1) {
                        this.showDoneCheckbox = true;
                    }
                }

            }, (rejected) => {

            });
    }

    isCheckBoxChecked(flag, field) {
        if (field == 'isMultipleRetake') {
            if (flag) {
                this.taskComment.isMultipleRetake = 1;
            } else {
                this.taskComment.isMultipleRetake = 0;
                //Reset multiple departments select
                this.taskComment.departments = [];
            }
        } else if (field == 'isNotifyNextDept') {
            if (flag) {
                this.taskComment.isNotifyNextDept = 1;
            } else {
                this.taskComment.isNotifyNextDept = 0;
            }
        }
    }

    getTaskDepartments(query, column, detData) {
        let taskData = {};
        taskData['project_id'] = detData.project_id;
        taskData['task_id'] = detData.task_id;
        taskData['assigned_to'] = detData.assigned_to;
        taskData['asset_id'] = detData.asset_id;
        taskData['shot_id'] = detData.shot_id;
        taskData['department_id'] = detData.department_id;
        taskData['task_version_id'] = detData.task_version_id;
        taskData['revision'] = detData.revision;
        taskData['version'] = detData.version;
        taskData['department_name'] = detData.department_name;
        taskData['department_tasktype_name'] = detData.department_tasktype_name;
        if (column == 'departments') {
            //this.getEpisodes(query);
            this.dataService.getOtherData('getTaskDepartments', { 'data': taskData, 'query': query['query'] })
                .subscribe((success) => {
                    this.departments = success.data.tasksDepts;
                    if (detData.department_tasktype_id != null) {
                        this.departments = this.departments.filter(dept => dept.dtt_id !== detData.department_tasktype_id);
                    } else {
                        this.departments = this.departments.filter(dept => dept.department_id !== detData.department_id);
                    }
                }, (rejected) => {

                });
        }
    }

    close(id) {
        this.closeCreateTask.emit({ id: id });
    }

    toggleFrm() {
        if (this.assigned_users.length < 0) {
            this.CloseOtherWindow.nativeElement.click();
        }
        this.isAssignList = true;
        this.isAssign = false;
        this.isComments = false;
        this.isTransfer = false;
        this.tasks = [];
        this.isDeleteMode = false;
    }


    /** view in annotation scree, */
    viewinReviewScreen(tempdata) {
        let taskDetail: any = {};
        taskDetail.data = tempdata;
        taskDetail.projectName = this.getProjectDatabyId.projects.name;
        taskDetail.currentTaskDetail = this.data.info;
        taskDetail.version_id = this.data.version;
        taskDetail.revision_id = this.data.revision;
        taskDetail.task_version_id = this.data.task_version_id;
        taskDetail.actualStatus = this.data.actualStatus;
        taskDetail.isReview = this.data.isReview;
        taskDetail.department_id = this.data.department_id;
        taskDetail.department_tasktype_id = this.data.department_tasktype_id;
        taskDetail.is_external_review = this.data.is_external_review;
        taskDetail.is_stagged_review = this.data.is_stagged_review;
        taskDetail.task_type = this.data.task_type;

        if (tempdata.aws_s3_url == '') {
            taskDetail.localImagePath = this.uploadUrl + 'comment_attachment/' + tempdata.image_display_name;
        }

        localStorage.setItem('annotationData', JSON.stringify(taskDetail));
        // window.open('#/' + this.commonJs.getClientCodePrefix() + '/video-annotation', "_blank");
    }

    // On date selected
    public calendarApplied(e: any) {
      console.log('test');
      let start_date;
      let end_date;
      if ($('#start_date').val() != '' && $('#start_date').val() != undefined) {
        start_date = moment($('#start_date').val()).format('YYYY-MM-DD HH:mm:ss');
      }
      if ($('#end_date').val() != '' && $('#end_date').val() != undefined) {
        end_date = moment($('#end_date').val()).format('YYYY-MM-DD HH:mm:ss');
      }

      // let diff = moment(end_date).diff(start_date, 'days');
      // if (diff < 0) {
      //   this.errors.end_date = "End date is negative, which is not allowed.";
      // } else if (diff < this.tasks.bid_days && diff !== 0) {
      //   this.errors.end_date = "End date is less than Bid days.";
      // } else {
      //   this.errors.end_date = "";
      // }
      // e.event
      // e.picker
    }

    // on biddays value entered
    bidDaysEntered(value) {
        if (value < 0) {
            this.tasks.bid_days = Math.abs(value);
        }
        if (this.isDisableAssign) {
            return true;
        }
        // const startDate = new Date(this.tasks.start_date); // replace with your start date
        // const endDate = new Date(new Date(startDate).toLocaleString("en-US", { timeZone: JSON.parse(localStorage.getItem('settings')).timezone.split("=>")[0] }));
        // endDate.setDate(startDate.getDate() + this.tasks.bid_days);
        // this.tasks.end_date = moment(endDate).format('DD MMM YYYY hh:mm A');
        const startDate = new Date(this.tasks.start_date); // replace with your start date
        this.tasks.end_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').add(this.tasks.bid_days, 'days').set({ hour: startDate.getHours(), minute: startDate.getMinutes() }).format('DD MMM YYYY hh:mm A');

    }

  // on bid type changed
  onBidTypeChanged(value){
    if (value < 0) {
      this.tasks.bid_days = Math.abs(value);
    }
    if (this.isDisableAssign) {
      return true;
    }
    // const startDate = new Date(this.tasks.start_date); // replace with your start date
    // const endDate = new Date(startDate);
    // endDate.setDate(startDate.getDate() + this.tasks.bid_days);
    // this.tasks.end_date = moment(endDate).format('DD MMM YYYY hh:mm A')
    if(this.selectedBidType['name'] && this.selectedBidType['name']=='Day'){
      const startDate = new Date(this.tasks.start_date); // replace with your start date

      if(this.tasks.bid_days%1==0){ // value is integer
        this.tasks.end_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').add(this.tasks.bid_days, 'days').set({hour:startDate.getHours(),minute:startDate.getMinutes()}).format('DD MMM YYYY hh:mm A');
      } else { // value is decimal
        this.tasks.end_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').add(Math.floor(this.tasks.bid_days), 'days').add(JSON.parse((this.tasks.bid_days % 1).toFixed(3))*8, 'hours').set({minute:startDate.getMinutes()}).format('DD MMM YYYY hh:mm A');
      }

    } else {
      const startDate = new Date(this.tasks.start_date); // replace with your start date

      if(this.tasks.bid_days%1==0){ // value is integer
        this.tasks.end_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').add(this.tasks.bid_days, 'hours').set({minute:startDate.getMinutes()}).format('DD MMM YYYY hh:mm A');
      } else { // value is decimal
        this.tasks.end_date = moment($('#start_date').val(), 'DD MMM YYYY hh:mm A').add(this.tasks.bid_days, 'hours').add((this.tasks.bid_days % 1).toFixed(2), 'minutes').format('DD MMM YYYY hh:mm A');
      }
      // console.log(this.tasks.bid_days%1==0);
    }
  }


    // When mention selected
    mentionSelect(event) {
        this.selectedMentions.push(event);
        this.taskComment.comment = this.taskComment.comment + ' ';
    }

    // For getting first two letters of name
    getInitials(inputString: string): string {
        const words = inputString.trim().split(' ');
        if (words.length >= 2) {
            const firstInitial = words[0].charAt(0).toUpperCase();
            const secondInitial = words[1].charAt(0).toUpperCase();
            return `${firstInitial}${secondInitial}`;
        } else if (words.length === 1) {
            const firstInitial = words[0].charAt(0).toUpperCase();
            return `${firstInitial}`;
        } else {
            return '';
        }
    }

}
