import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { Subscription } from 'rxjs';
import * as _ from "lodash";
declare var $: any;
import { CommonJs } from '../../../common/common-js';
import * as moment from 'moment-timezone';

@Component({
	selector: 'studio-settings',
	templateUrl: './studio-settings.component.html',
	styleUrls: ['./studio-settings.component.css']
})
export class StudioSettingsComponent implements OnInit {

	entityName = 'Studio Settings';
	entityNameSingular = 'Studio';
	apiRoutePrefix = 'studio-settings';
	myform: any;
	focused: string = 'focused';
	settings: { [index: string]: any } = {};
	timeZones: { [index: string]: any } = {};
	errors: any;
	SaveButtonText: string = 'SAVE';
	settingsAll: any = JSON.parse(localStorage.getItem('settings'));
	@Output() closeSettings = new EventEmitter();
	currency = [
		{ id: "EUR", text: "EUR" },
		{ id: "INR", text: "INR" },
		{ id: "USD", text: "USD" }
	];
	billing_unit = [
		{ id: "Days", text: "Days" },
		{ id: "Hours", text: "Hours" }
	];
	taskTimerLockSuggestions: Array<any> = [{ id: '0', value: '0' }, { id: '30', value: '30' }, { id: '60', value: '60' }];

	constructor(private titleService: Title,
		private dataService: DataService,
		private toastrService: ToastrService,
		public commonJs: CommonJs) { }

	ngOnInit() {
		let timezone = this.settingsAll.timezone.includes("=>") ? this.settingsAll.timezone.split("=>", 2) : this.settingsAll.studio_timezone.split("=>", 2);
    // console.log(this.settingsAll.studio_timezone.split("=>", 2), this.settingsAll.timezone);
		this.settings = {
			week_working_days: this.settingsAll.week_working_days,
			half_day_hrs: this.settingsAll.half_day_hrs,
			full_day_hrs: this.settingsAll.full_day_hrs,
			currency: { id: this.settingsAll.currency, text: this.settingsAll.currency },
			billing_unit: { id: this.settingsAll.billing_unit, text: this.settingsAll.billing_unit },
			studio_timezone: { id: timezone[0], name: timezone[1] },
			emp_custom_columns: this.settingsAll.emp_custom_columns,
			display_emp_custom_columns: this.settingsAll.display_emp_custom_columns,
			is_retake_type_mandatory: this.settingsAll.is_retake_type_mandatory,
			is_studio_multiple_location: this.settingsAll.is_studio_multiple_location == 1 ? 1 : 0,
			task_timer_lock: { id: this.settingsAll.task_timer_lock, value: this.settingsAll.task_timer_lock }
		};
		$.AdminBSB.input.activate();
		this.titleService.setTitle(this.settingsAll.html_prefix + this.entityName);
	}

	search(query, column) {
		if (column == 'currency') {
			this.currency = this.currency.map(v => { return v });
		} else if (column == 'billing_unit') {
			this.billing_unit = this.billing_unit.map(v => { return v });
		} else if (column == 'studio_timezone') {
			this.dataService.getOtherData('getstudioTimezoneList', { 'query': query['query'] })
				.subscribe((success) => {
					this.timeZones = success.data;
				});
		} else if (column == 'task_timer_lock') {
			this.taskTimerLockSuggestions = this.taskTimerLockSuggestions.map((item: any) => { return item });
		}
	}

	onSubmit(form: NgForm) {
		this.SaveButtonText = 'SAVING';
		if (!form.value.week_working_days) {
			form.value.week_working_days = this.settings.week_working_days;
		}
		if (!form.value.full_day_hrs) {
			form.value.full_day_hrs = this.settings.full_day_hrs;
		}
		if (!form.value.half_day_hrs) {
			form.value.half_day_hrs = this.settings.half_day_hrs;
		}
		if (form.value.currency) {
			form.value.currency = this.settings.currency.id;
		}
		if (form.value.billing_unit) {
			form.value.billing_unit = this.settings.billing_unit.id;
		}
		if (form.value.studio_timezone) {
			form.value.studio_timezone = this.settings.studio_timezone;
			moment.tz.setDefault(form.value.studio_timezone.id);

			// To change the global time-zone
			this.commonJs.changeGlobalTimeZone();
		}
		if (form.value.task_timer_lock) {
			form.value.task_timer_lock = this.settings.task_timer_lock.id;
		}
		this.dataService.updateData(this.apiRoutePrefix, form.value)
			.subscribe((success) => {
				this.SaveButtonText = 'SAVED';
				setTimeout(() => {
					this.SaveButtonText = 'SAVE';
					this.commonJs.getAllSettings();
					let getSettings = localStorage.getItem("settings");
					if (!getSettings) {
						this.commonJs.getAllSettings();
					}
				}, 2);
				this.toastrService.notify('success', '', 'Studio settings updated successfully.');

			}, (error) => {
				if (error.error) {
					var err = error.error;
					this.errors = err.error;
					this.SaveButtonText = "SAVE";
				}
			});
	}
	close() {
		this.closeSettings.emit();
	}

	onChange(setting, flag) {
		if (setting == 'display_emp_custom_columns') {
			if (flag) {
				this.settings.display_emp_custom_columns = 1;
			} else {
				this.settings.display_emp_custom_columns = 0;
			}
		} else if (setting == 'is_retake_type_mandatory') {
			if (flag) {
				this.settings.is_retake_type_mandatory = 1;
			} else {
				this.settings.is_retake_type_mandatory = 0;
			}
		} else if (setting == 'is_studio_multiple_location') {
			if (flag) {
				this.settings.is_studio_multiple_location = 1;
			} else {
				this.settings.is_studio_multiple_location = 0;
			}
		}
	}

	getstudioTimezoneList() {

	}

}
// interface Settings {
// 	week_working_days:string,
// 	half_day_hrs:string,
// 	full_day_hrs:string,
// 	currency:string,
// 	billing_unit:string,
// 	emp_custom_columns:string
//   	display_emp_custom_columns:number
// }
