export function columns(userRole:string, key: string, cellTemplate: any = {}, modifiedArray: any = []) {

  if(key == 'pipeline' ){

    if(userRole=='vendor'){
      const pipelineColumns = [
        {
          prop: 'unread_comments',
          name: 'Action',
          sortable: false,
          draggable: false,
          canAutoResize: false,
          width: 120,
          frozenRight: true,
          headerClass: 'action-btn-datatable',
          cellClass: '',
          cellTemplate: cellTemplate.idsT,
        },
        {
          prop: 'thumbnail',
          name: 'Image',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.imageIcon,
        },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
        },
        {
          prop: 'info',
          name: 'Task Name',
          sortable: false,
          draggable: false,
          width: 230,
          canAutoResize: true,
          headerClass: '',
          // cellClass: 'pointer',
          cellClass: '',
          cellTemplate: cellTemplate.info,
       },
        {
          prop: 'department_name',
          name: 'Department',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'version',
          name: 'Version',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'status',
          name: 'Status',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'text-center',
          cellTemplate: cellTemplate.status,
        },
        {
          prop: 'is_running',
          name: 'Track',
          sortable: false,
          draggable: true,
          width: 80,
          // frozenRight: true,
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellTemplate: cellTemplate.running,
        },
    ];
    return pipelineColumns;
    }
    const pipelineColumns = [
        {
          prop: 'unread_comments',
          name: 'Action',
          sortable: false,
          draggable: false,
          canAutoResize: false,
          width: 120,
          frozenRight: true,
          headerClass: 'action-btn-datatable',
          cellClass: '',
          cellTemplate: cellTemplate.idsT,
        },
        {
          prop: 'thumbnail',
          name: 'Image',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.imageIcon,
        },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
        },
        {
          prop: 'info',
          name: 'Task Name',
          sortable: false,
          draggable: false,
          width: 230,
          canAutoResize: true,
          headerClass: '',
          // cellClass: 'pointer',
          cellClass: '',
          cellTemplate: cellTemplate.info,
       },
        {
          prop: 'department_name',
          name: 'Department',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'version',
          name: 'Version',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'working_days',
          name: 'W/B Days',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: '',
          cellTemplate: cellTemplate.wbdays,
        },
        {
          prop: 'status',
          name: 'Status',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'text-center',
          cellTemplate: cellTemplate.status,
        },
        {
          prop: 'is_running',
          name: 'Track',
          sortable: false,
          draggable: true,
          width: 80,
          // frozenRight: true,
          headerClass: 'text-center',
          cellClass: 'text-center',
          cellTemplate: cellTemplate.running,
        },
    ];
    return pipelineColumns;
  }
  else if(key == 'rejected_inventory'){

    if(userRole=='vendor'){
      const rejInvColumns = [
        {
          prop: 'unread_comments',
          name: 'Action',
          sortable: false,
          draggable: false,
          canAutoResize: false,
          width: 120,
          frozenRight: true,
          headerClass: 'action-btn-datatable',
          cellClass: '',
          cellTemplate: cellTemplate.idsT,
        },
        {
          prop: 'thumbnail',
          name: 'Image',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.imageIcon,
        },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
        },
        {
          prop: 'info',
          name: 'Task Name',
          sortable: false,
          draggable: false,
          width: 230,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'pointer',
          cellTemplate: cellTemplate.info,
       },
        {
          prop: 'department_name',
          name: 'Department',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'version',
          name: 'Version',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'status',
          name: 'Status',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'text-center',
          cellTemplate: cellTemplate.status,
        },
    ];
    return rejInvColumns;
    }
    const rejInvColumns = [
        {
          prop: 'unread_comments',
          name: 'Action',
          sortable: false,
          draggable: false,
          canAutoResize: false,
          width: 120,
          frozenRight: true,
          headerClass: 'action-btn-datatable',
          cellClass: '',
          cellTemplate: cellTemplate.idsT,
        },
        {
          prop: 'thumbnail',
          name: 'Image',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.imageIcon,
        },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
        },
        {
          prop: 'info',
          name: 'Task Name',
          sortable: false,
          draggable: false,
          width: 230,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'pointer',
          cellTemplate: cellTemplate.info,
       },
        {
          prop: 'department_name',
          name: 'Department',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'version',
          name: 'Version',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'working_days',
          name: 'W/B Days',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: '',
          cellTemplate: cellTemplate.wbdays,
        },
        {
          prop: 'status',
          name: 'Status',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'text-center',
          cellTemplate: cellTemplate.status,
        },
    ];
    return rejInvColumns;
  }
  else if(key == 'ready'){

    if(userRole=='vendor'){
      const readyColumns = [
        {
          prop: 'unread_comments',
          name: 'Action',
          sortable: false,
          draggable: false,
          canAutoResize: false,
          width: 120,
          frozenRight: true,
          headerClass: 'action-btn-datatable',
          cellClass: '',
          cellTemplate: cellTemplate.idsT,
        },
        {
          prop: 'thumbnail',
          name: 'Image',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.imageIcon,
        },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
        },
        {
          prop: 'info',
          name: 'Task Name',
          sortable: false,
          draggable: false,
          width: 230,
          canAutoResize: true,
          headerClass: '',
          // cellClass: 'pointer',
          cellClass: '',
          cellTemplate: cellTemplate.info,
       },
        {
          prop: 'department_name',
          name: 'Department',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'version',
          name: 'Version',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'status',
          name: 'Status',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'text-center',
          cellTemplate: cellTemplate.status,
        },
        {
          prop: 'is_running',
          name: 'Track',
          sortable: false,
          draggable: true,
          width: 80,
          // frozenRight: true,
          headerClass: '',
          cellClass: '',
          cellTemplate: cellTemplate.running,
        },
      ];
      return readyColumns;
    }
    const readyColumns = [
      {
        prop: 'unread_comments',
        name: 'Action',
        sortable: false,
        draggable: false,
        canAutoResize: false,
        width: 120,
        frozenRight: true,
        headerClass: 'action-btn-datatable',
        cellClass: '',
        cellTemplate: cellTemplate.idsT,
      },
      {
        prop: 'thumbnail',
        name: 'Image',
        sortable: false,
        draggable: true,
        resizable: false,
        canAutoResize: false,
        width: 60,
        cellClass: 'text-center',
        cellTemplate: cellTemplate.imageIcon,
      },
      {
        prop: 'icon',
        name: 'Entity',
        sortable: false,
        draggable: true,
        resizable: false,
        canAutoResize: false,
        width: 60,
        cellClass: 'text-center',
        cellTemplate: cellTemplate.taskThumbnail,
      },
      {
        prop: 'info',
        name: 'Task Name',
        sortable: false,
        draggable: false,
        width: 230,
        canAutoResize: true,
        headerClass: '',
        // cellClass: 'pointer',
        cellClass: '',
        cellTemplate: cellTemplate.info,
     },
      {
        prop: 'department_name',
        name: 'Department',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: ''
      },
      {
        prop: 'version',
        name: 'Version',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: ''
      },
      {
        prop: 'working_days',
        name: 'W/B Days',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: '',
        cellTemplate: cellTemplate.wbdays,
      },
      {
        prop: 'status',
        name: 'Status',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: 'text-center',
        cellTemplate: cellTemplate.status,
      },
      {
        prop: 'is_running',
        name: 'Track',
        sortable: false,
        draggable: true,
        width: 80,
        // frozenRight: true,
        headerClass: '',
        cellClass: '',
        cellTemplate: cellTemplate.running,
      },
    ];
    return readyColumns;
  }
  else if(key == 'wip'){

    if(userRole=='vendor'){
      const wipColumns = [
        {
          prop: 'unread_comments',
          name: 'Action',
          sortable: false,
          draggable: false,
          canAutoResize: false,
          width: 120,
          frozenRight: true,
          headerClass: 'action-btn-datatable',
          cellClass: '',
          cellTemplate: cellTemplate.idsT,
        },
        {
          prop: 'thumbnail',
          name: 'Image',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.imageIcon,
        },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
        },
        {
          prop: 'info',
          name: 'Task Name',
          sortable: false,
          draggable: false,
          width: 230,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'pointer',
          cellTemplate: cellTemplate.info,
       },
        {
          prop: 'department_name',
          name: 'Department',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'version',
          name: 'Version',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'status',
          name: 'Status',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'text-center',
          cellTemplate: cellTemplate.status,
        },
        {
          prop: 'is_running',
          name: 'Track',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          width: 80,
          // frozenRight: true,
          headerClass: '',
          cellClass: 'text-center',
          cellTemplate: cellTemplate.running,
        },
      ];
      return wipColumns;
    }
    const wipColumns = [
      {
        prop: 'unread_comments',
        name: 'Action',
        sortable: false,
        draggable: false,
        canAutoResize: false,
        width: 120,
        frozenRight: true,
        headerClass: 'action-btn-datatable',
        cellClass: '',
        cellTemplate: cellTemplate.idsT,
      },
      {
        prop: 'thumbnail',
        name: 'Image',
        sortable: false,
        draggable: true,
        resizable: false,
        canAutoResize: false,
        width: 60,
        cellClass: 'text-center',
        cellTemplate: cellTemplate.imageIcon,
      },
      {
        prop: 'icon',
        name: 'Entity',
        sortable: false,
        draggable: true,
        resizable: false,
        canAutoResize: false,
        width: 60,
        cellClass: 'text-center',
        cellTemplate: cellTemplate.taskThumbnail,
      },
      {
        prop: 'info',
        name: 'Task Name',
        sortable: false,
        draggable: false,
        width: 230,
        canAutoResize: true,
        headerClass: '',
        cellClass: 'pointer',
        cellTemplate: cellTemplate.info,
     },
      {
        prop: 'department_name',
        name: 'Department',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: ''
      },
      {
        prop: 'version',
        name: 'Version',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: ''
      },
      {
        prop: 'working_days',
        name: 'W/B Days',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: '',
        cellTemplate: cellTemplate.wbdays,
      },
      {
        prop: 'status',
        name: 'Status',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: 'text-center',
        cellTemplate: cellTemplate.status,
      },
      {
        prop: 'total_duration',
        name: 'Time log',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: 'text-center',
        cellTemplate: cellTemplate.timeLog,
      },
      {
        prop: 'is_running',
        name: 'Track',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        width: 80,
        // frozenRight: true,
        headerClass: '',
        cellClass: 'text-center',
        cellTemplate: cellTemplate.running,
      },
    ];
    return wipColumns;
  }
  else if(key == 'completed'){

    if(userRole=='vendor'){
      const completedColumns = [
        {
          prop: 'unread_comments',
          name: 'Action',
          sortable: false,
          draggable: false,
          canAutoResize: false,
          width: 120,
          frozenRight: true,
          headerClass: 'action-btn-datatable',
          cellClass: '',
          cellTemplate: cellTemplate.idsT,
        },
        {
          prop: 'thumbnail',
          name: 'Image',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.imageIcon,
        },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
        },
        {
          prop: 'info',
          name: 'Task Name',
          sortable: false,
          draggable: false,
          width: 230,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'pointer',
          cellTemplate: cellTemplate.info,
       },
        {
          prop: 'department_name',
          name: 'Department',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'version',
          name: 'Version',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'status',
          name: 'Status',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'text-center',
          cellTemplate: cellTemplate.status,
        },
      ];
      return completedColumns;
    }
    const completedColumns = [
      {
        prop: 'unread_comments',
        name: 'Action',
        sortable: false,
        draggable: false,
        canAutoResize: false,
        width: 120,
        frozenRight: true,
        headerClass: 'action-btn-datatable',
        cellClass: '',
        cellTemplate: cellTemplate.idsT,
      },
      {
        prop: 'thumbnail',
        name: 'Image',
        sortable: false,
        draggable: true,
        resizable: false,
        canAutoResize: false,
        width: 60,
        cellClass: 'text-center',
        cellTemplate: cellTemplate.imageIcon,
      },
      {
        prop: 'icon',
        name: 'Entity',
        sortable: false,
        draggable: true,
        resizable: false,
        canAutoResize: false,
        width: 60,
        cellClass: 'text-center',
        cellTemplate: cellTemplate.taskThumbnail,
      },
      {
        prop: 'info',
        name: 'Task Name',
        sortable: false,
        draggable: false,
        width: 230,
        canAutoResize: true,
        headerClass: '',
        cellClass: 'pointer',
        cellTemplate: cellTemplate.info,
     },
      {
        prop: 'department_name',
        name: 'Department',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: ''
      },
      {
        prop: 'version',
        name: 'Version',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: ''
      },
      {
        prop: 'working_days',
        name: 'W/B Days',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: '',
        cellTemplate: cellTemplate.wbdays,
      },
      {
        prop: 'status',
        name: 'Status',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: 'text-center',
        cellTemplate: cellTemplate.status,
      },
      {
        prop: 'total_duration',
        name: 'Time log',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: '',
        cellTemplate: cellTemplate.timeLog,
      },
    ];
    return completedColumns;
  }
  else if(key == 'under_review'){

    if(userRole=='vendor'){
      const underReviewColumns = [
        {
          prop: 'unread_comments',
          name: 'Action',
          sortable: false,
          draggable: false,
          canAutoResize: false,
          width: 120,
          frozenRight: true,
          headerClass: 'action-btn-datatable',
          cellClass: '',
          cellTemplate: cellTemplate.idsT,
        },
        {
          prop: 'thumbnail',
          name: 'Image',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.imageIcon,
        },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
        },
        {
          prop: 'info',
          name: 'Task Name',
          sortable: false,
          draggable: false,
          width: 230,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'pointer',
          cellTemplate: cellTemplate.info,
       },
        {
          prop: 'department_name',
          name: 'Department',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'version',
          name: 'Version',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: ''
        },
        {
          prop: 'status',
          name: 'Status',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: 'text-center',
          cellTemplate: cellTemplate.status,
        },
      ];
      return underReviewColumns;
    }
    const underReviewColumns = [
      {
        prop: 'unread_comments',
        name: 'Action',
        sortable: false,
        draggable: false,
        canAutoResize: false,
        width: 120,
        frozenRight: true,
        headerClass: 'action-btn-datatable',
        cellClass: '',
        cellTemplate: cellTemplate.idsT,
      },
      {
        prop: 'thumbnail',
        name: 'Image',
        sortable: false,
        draggable: true,
        resizable: false,
        canAutoResize: false,
        width: 60,
        cellClass: 'text-center',
        cellTemplate: cellTemplate.imageIcon,
      },
      {
        prop: 'icon',
        name: 'Entity',
        sortable: false,
        draggable: true,
        resizable: false,
        canAutoResize: false,
        width: 60,
        cellClass: 'text-center',
        cellTemplate: cellTemplate.taskThumbnail,
      },
      {
        prop: 'info',
        name: 'Task Name',
        sortable: false,
        draggable: false,
        width: 230,
        canAutoResize: true,
        headerClass: '',
        cellClass: 'pointer',
        cellTemplate: cellTemplate.info,
     },
      {
        prop: 'department_name',
        name: 'Department',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: ''
      },
      {
        prop: 'version',
        name: 'Version',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: ''
      },
      {
        prop: 'working_days',
        name: 'W/B Days',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: '',
        cellTemplate: cellTemplate.wbdays,
      },
      {
        prop: 'toBeReviewedBy',
        name: 'Reviwer',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: '',
      },
      {
        prop: 'status',
        name: 'Status',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: 'text-center',
        cellTemplate: cellTemplate.status,
      },
      {
        prop: 'total_duration',
        name: 'Time log',
        sortable: false,
        draggable: false,
        canAutoResize: true,
        headerClass: '',
        cellClass: 'text-center',
        cellTemplate: cellTemplate.timeLog,
      },
    ];
    return underReviewColumns;
  }


}
