import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { columns } from './online-access-group';
import { GridService } from 'src/app/services/grid.service';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';
import { CommonJs } from 'src/app/common/common-js';
import { ToastrService } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-online-access-group',
  templateUrl: './online-access-group.component.html',
  styleUrls: ['./online-access-group.component.css']
})
export class OnlineAccessGroupComponent implements OnInit {
  @Output() openSideWindowClick: EventEmitter<void> = new EventEmitter<void>();
  public dataTableColumns: TableColumn[] = [];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('image', { static: false }) public image: TemplateRef<any>;
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  totalItems: number = 0;
  rows: any = [];
  temp = [];
  isLoading: boolean = true;

  subscription: Subscription;
  receivedValue: string;

  constructor(
    private gridService: GridService,
    public commonJs: CommonJs,
    private toastrService: ToastrService,

  ) {
    this.subscription = this.gridService.mySubjectForAccessGroup$.subscribe((value) => {
      this.getUserList();
    });
  }

  ngOnInit() {
    this.createColumns();
    this.getUserList();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /** Create Column */
  private createColumns(): void {
    let cellTemplate: any = {};
    setTimeout(() => {
      cellTemplate = {
        ids: this.idsT,
        image: this.image,
      };
      this.dataTableColumns = [];
      this.dataTableColumns = columns('online-access', cellTemplate);
    });
  }

  openSideWindow() {
    this.openSideWindowClick.emit();
  }

  /** get user list */
  getUserList() {
    this.isLoading = true;
    this.gridService.postData('users/onlineAccessList', {}).subscribe((res: any) => {
      this.totalItems = res.user.length;
      this.temp = res.user;
      this.rows = [...this.temp];
      this.isLoading = false;
    });
  }


  /** Action Button */
  actionClick(type: string, row: any) {
    let id: any = row.id
    let that = this;
    const payload = {
      id: id,
      isOnlineAccess: 0
    }
    swal.fire({
      title: 'Remove Employee',
      text: "Are you sure you want to Remove this Employee?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        that.gridService.postData('users/onlineAccess', payload)
          .subscribe((success) => {
            this.isLoading = true;
            this.toastrService.notify('success', '', 'Employee Remove successfully.');
            this.getUserList();
          }, (rejected) => {

          });
      } else {
        //console.log('cancelled');
      }
    });

  }

  // For getting first two letters of name
  getInitials(inputString: string): string {
    const words = inputString.trim().split(' ');
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      const secondInitial = words[1].charAt(0).toUpperCase();
      return `${firstInitial}${secondInitial}`;
    } else if (words.length === 1) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      return `${firstInitial}`;
    } else {
      return '';
    }
  }

}
