import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { DndModule } from 'ng2-dnd';

import { FoucsonfirstDirective } from '../../directives/foucsonfirst.directive';
import { RoleTypeComponent } from '../accounts/role-type/role-type.component';
import { ImportComponent } from '../../common/import/import.component';
import { AutoCompleteModule, GrowlModule, ProgressBarModule } from 'primeng/primeng';
import { ReportDetailsComponent } from '../reports/report-details/report-details.component';
import { EmailSubscriptionComponent } from '../settings/email-subscription/email-subscription.component';
import { ExcelmapimportComponent } from '../../common/excelmapimport/excelmapimport.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { RouterModule } from '@angular/router';
import { FocusOnClickDirective } from '../../directives/focus-on-click.directive';
import { HideDataDirective } from '../../directives/hide-data.directive';
import { PaginationComponent } from './pagination/pagination.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AddMissionTaskModalComponent } from '../projects/add-mission-task-modal/add-mission-task-modal.component';
import { ProgressbarModalComponent } from 'src/app/common/progressbar-modal/progressbar-modal.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ThumbnailModalComponent } from './imageThumbnail/thumbnail-modal/thumbnail-modal.component';
import { SidebarModule } from 'primeng/sidebar';
import { CustomDatepipePipe } from 'src/app/pipes/custom-datepipe.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    DndModule.forRoot(),
    NgxFileDropModule,
    Ng2TableModule,
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
    AutoCompleteModule,
    GrowlModule,
    RouterModule,
    NgMultiSelectDropDownModule.forRoot(),
    ProgressbarModule.forRoot(),
    ModalModule.forRoot(),
    SidebarModule

  ],
  declarations: [
    ImportComponent,
    RoleTypeComponent,
    FoucsonfirstDirective,
    ReportDetailsComponent,
    EmailSubscriptionComponent,
    AddMissionTaskModalComponent,
    ExcelmapimportComponent,
    DashboardHeaderComponent,
    FocusOnClickDirective,
    HideDataDirective,
    PaginationComponent,
    SkeletonComponent,
    ProgressbarModalComponent,
    ThumbnailModalComponent,
    CustomDatepipePipe,

  ],
  entryComponents: [
    ReportDetailsComponent,
    ExcelmapimportComponent,
    AddMissionTaskModalComponent,
    ProgressbarModalComponent,
    ImportComponent,
    ThumbnailModalComponent,

  ],
  providers: [
    RoleTypeComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [CommonModule, ImportComponent, FormsModule, DndModule,
    RoleTypeComponent,
    FoucsonfirstDirective,
    FocusOnClickDirective,
    HideDataDirective,
    EmailSubscriptionComponent,
    AddMissionTaskModalComponent,
    ExcelmapimportComponent,
    DashboardHeaderComponent,
    PaginationComponent,
    SkeletonComponent,
    CustomDatepipePipe,
  ]
})
export class SharedModule { }
