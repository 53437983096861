import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonJs } from '../../../common/common-js';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';

@Component({
  selector: 'app-one-drive-conf',
  templateUrl: './one-drive-conf.component.html',
  styleUrls: ['./one-drive-conf.component.css']
})
export class OneDriveConfComponent implements OnInit {

  s3bucket: FormGroup;
  f1Focused: string = '';
  SaveButtonText: string = 'CONNECT';

  constructor(
    private fb: FormBuilder,
    public dataService: DataService,
    private toastrService: ToastrService,
    public commonjs: CommonJs
  ) { }

  ngOnInit() {
    const response: any = this.commonjs.getAllSettingResponse;
    this.s3bucket = this.fb.group({
      s3_key: [response.s3_key ? response.s3_key : null],
      s3_secret: [response.s3_secret ? response.s3_secret : null],
      s3_region: [response.s3_region ? response.s3_region : null],
      s3_bucket: [response.s3_bucket ? response.s3_bucket : null],
    });
  }

  /** On submit*/
  onSubmit(form: any) {
    if (!this.checkControlPost(form)) {
      if (this.findInvalidControls().length === 0) {
        this.SaveButtonText = 'CONNECTING';
        this.dataService.awsConfig(form).subscribe((res: any) => {
          this.SaveButtonText = 'CONNECTED';
          setTimeout(() => {
            this.SaveButtonText = 'CONNECT';
          }, 2);
          if (res.status != 'error') {
            this.toastrService.notify('success', '', res.message);
          } else {
            this.toastrService.notify('warn', '', res.message);
          }
        });
      }
    }

    if (this.checkControlPost(form)) {
      this.markFormTouched(this.s3bucket);
    }
  }


  /** Check Control Post */
  checkControlPost = (post) => {
    let invalid = false;
    Object.keys(post).forEach((key: string) => {

      if (key === 's3_key' && !this.s3bucket.get(`${key}`).value) {
        this.s3bucket.get(`${key}`).setValidators([Validators.required]);
        this.s3bucket.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }


      if (key === 's3_secret' && !this.s3bucket.get(`${key}`).value) {
        this.s3bucket.get(`${key}`).setValidators([Validators.required]);
        this.s3bucket.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }


      if (key === 's3_region' && !this.s3bucket.get(`${key}`).value) {
        this.s3bucket.get(`${key}`).setValidators([Validators.required]);
        this.s3bucket.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }

      if (key === 's3_bucket' && !this.s3bucket.get(`${key}`).value) {
        this.s3bucket.get(`${key}`).setValidators([Validators.required]);
        this.s3bucket.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }

    });
    return invalid;
  }

  /** Find Invalid Controls */
  findInvalidControls = () => {
    const invalid = [];
    const controls = this.s3bucket.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  /**Mark Form Touched*/
  markFormTouched = (group: FormGroup | FormArray) => {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }


}
