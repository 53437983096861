import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DataService } from '../../../services/data.service';
import { CommonJs } from '../../../common/common-js';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
declare var $: any;
import * as _ from "lodash";
import { DatatableService } from '../../../services/datatable/datatable.service';
import { AppConfigs } from '../../../common/app-configs';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GridService } from 'src/app/services/grid.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { columns } from '../../projects/utility/column-generator';

@Component({
    selector: 'tasks-comments',
    templateUrl: './tasks-comments.component.html',
    styleUrls: ['./tasks-comments.component.css']
})
export class TasksCommentsComponent implements OnInit {

    entityName = 'Comments';
    entityNameSingular = 'Comments';
    myform: { [index: string]: any } = {};
    taskComment: { [index: string]: any } = {};
    commentsFilter: { [index: string]: any } = {};

    departments: { [index: string]: any } = {};
    episodes: { [index: string]: any } = {};
    sequences: { [index: string]: any } = {};
    shots: { [index: string]: any } = {};
    assets: { [index: string]: any } = {};
    roleTypes: { [index: string]: any } = {};
    empCodeList: { [index: string]: any } = {};
    vendorList: { [index: string]: any } = {};

    comments = [];
    projectId = '';

    projectroletype = JSON.parse(localStorage.getItem('projectRoleType'));
    is_external_login: boolean = false;
    is_external_review: boolean = false;

    projectroles = {};
    currentRole = '';

    filterIds = [];
    filterArr = [];

    where: { [index: string]: any } = { project_id: this.projectId };
    public config: any = {
        paging: true,
        sorting: { columns: [] },
        filtering: { filterString: '' },
        className: ['table-striped', 'table-bordered'],
        where: { 'project_id': this.projectId, role: '', is_external_login: this.is_external_login, filterArr: this.filterArr },
    };

    public maxSize = this.datatableService.maxSize;
    public page: number = this.datatableService.page;
    public itemsPerPage: number = this.datatableService.itemsPerPage;
    public readyLength = this.datatableService.length;
    public length = this.datatableService.length;
    public numPages: number = this.datatableService.numPages;

    public FilterColumns: Array<any> = ['list_name'];

    noRecordFound: boolean = false;
    noRecordFoundMsg: any = 'Sorry, no records found!';

    isEpisodic: boolean;
    isFilterApplied: boolean;
    busy: Subscription;

    settings: any = JSON.parse(localStorage.getItem('settings'));
    environment = environment;

    //errors:{ [index:string] : any } = {};
    public commentDate = {
        singleDatePicker: true,
        autoUpdateInput: true,
        showDropdowns: true,
        autoApply: true,
        drops: 'down',
        "maxDate": "31 Dec 2030",
        locale: {
            format: 'DD MMM YYYY'
        },
        //startDate:moment().format('YYYY-MM-DD'),
    }

    dateRange: Array<any>;

    isFilterSubmitted: boolean = false;
    isFilterReset: boolean = false;

    //! Chirag

    //Hide-show tables
    isAssetsCollapsed = false;
    assetCollapsedArrow = 'keyboard_arrow_up';

    isShotsCollapsed = false;
    shotCollapsedArrow = 'keyboard_arrow_up';

    assetComments: any = [];
    shotComments: any = [];
    otherComments: any = [];
    bsModalRef: BsModalRef;
    commentDetail: any = [];
    commentDetailAsset: any = [];


    @ViewChild(DatatableComponent, { static: false }) table1: DatatableComponent;
    @ViewChild(DatatableComponent, { static: false }) table2: DatatableComponent;
    @ViewChild(DatatableComponent, { static: false }) table3: DatatableComponent;
    @ViewChild('shotEpisode', { static: false }) public shotEpisode: TemplateRef<any>;
    @ViewChild('shotNumber', { static: false }) public shotNumber: TemplateRef<any>;
    @ViewChild('prePostTaskNumber', { static: false }) public prePostTaskNumber: TemplateRef<any>;
    @ViewChild('prePostDept', { static: false }) public prePostDept: TemplateRef<any>;
    @ViewChild('commentedBy', { static: false }) public commentedBy: TemplateRef<any>;
    @ViewChild('retakeType', { static: false }) public retakeType: TemplateRef<any>;

    tableGridHeight = { headerHeight: 45, rowHeight: 26, footerHeight: 50 };
    pageSize: number = 50;
    currentPage1: number = 0;
    currentPage2: number = 0;
    currentPage3: number = 0;
    totalElements: number = 0;
    openedPage: number = 0;
    isLoading: boolean = false;
    pagePayload: any = { page: 0, itemsPerPage: 100 };


    shotsCommentsRows: any[] = [];
    totalShotsCount: number = 0;
    shotsCommentsColumns: any[] = [

    ];

    assetsCommentsRows: any[] = [];
    totalAssetsCount: number = 0;
    assetsCommentsColumns: any[] = [

    ];

    prePostCommentsRows: any[] = [];
    totalPrePostCount: number = 0;
    prePostCommentsColumns: any[] = [
     ];

    constructor(
        private dataService: DataService,
        public commonJs: CommonJs,
        private datatableService: DatatableService,
        public appConfigs: AppConfigs,
        private titleService: Title,
        private activatedRoute: ActivatedRoute,
        private modalService: BsModalService,
        private gridService: GridService,
    ) {
        this.datatableService.setParam([], 'columns');
        this.datatableService.setParam(this.config, 'config');
        this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
        this.datatableService.setParam('getTaskComments', 'serverUrl');
    }

    // ! ngOnInit
    ngOnInit() {
        // this.itemsPerPage = 50;
        this.titleService.setTitle(this.settings.html_prefix + this.entityName);
        this.activatedRoute.params.subscribe((params: Params) => {
            this.projectId = params['id'];
            this.config.where.project_id = this.projectId;
        });
        this.dataService.getDataById('getProjectSettings/' + this.projectId)
            .subscribe((success) => {
                this.is_external_review = success.data.is_external_review;
            }, (rejected) => {

            });
        this.projectroles = (function () {
            var result;
            try {
                let result = JSON.parse(localStorage.getItem('projectRoles'))
                return result;
            } catch (exception) {
            }
        }());

        if (this.projectroles) {
            this.currentRole = this.projectroles['project_' + this.projectId];
            this.config.where.role = this.currentRole;
        }
        //this.getTaskComments();
        let cellTemplateShot: any = {};
        setTimeout(() => {
          cellTemplateShot = {
          shotEpisode: this.shotEpisode,
          // shotNumber: this.shotNumber
        };
        // console.log(columns('shotsComments',cellTemplateShot))
          this.shotsCommentsColumns = columns('shotsComments',cellTemplateShot);
      });


        setTimeout(()=>{
          this.assetsCommentsColumns = columns('assetsComments');
        });

        let cellTemplatePrePost: any = {};
        setTimeout(() => {
          cellTemplatePrePost = {
            prePostTaskNumber: this.prePostTaskNumber,
            prePostDept : this.prePostDept,
            commentedBy : this.commentedBy,
            retakeType : this.retakeType,
          };
          // console.log(columns('prePostComments',cellTemplatePrePost), this.is_external_review);
          let tempPrePostCommentsColumns = columns('prePostComments',cellTemplatePrePost);
          if(this.is_external_review){
            tempPrePostCommentsColumns.splice(tempPrePostCommentsColumns.findIndex(item => item.prop === 'revision'),1);
          }
          this.prePostCommentsColumns = tempPrePostCommentsColumns;
        });
        this.isLoading = true;
        this.onChangeTable(this.config);
    }

    // ! ngOnDestroy
    ngOnDestroy() {
    }

    // ! Select Date Picker
    selectedDatepicker($event) {
        let start = moment($event.start).format('YYYY-MM-DD');
        //let end = moment($event.end).format('YYYY-MM-DD');
        this.dateRange = [start];
    }

    //! Search
    search(query, column) {
        if (column == 'episodes') {
            this.getEpisodes(query);
        } else if (column == 'shots') {
            this.getShots(query)
        } else if (column == 'sequences') {
            this.getSequences(query);
        } else if (column == 'departments') {
            this.getDepartments(query);
        } else if (column == 'roleType') {
            this.getRoleTypes(query);
        } else if (column == 'assets') {
            this.getAssets(query);
        } else if (column == 'emp_code') {
            this.getEmpDetails(this.projectId, query, 'all');
        } else if (column == 'vendor') {
            this.getVendorList(this.projectId, query);
        }
    }

    // ! On data table change
    public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
        this.busy = this.datatableService.onChangeTable(this.config, page)
            .subscribe((success) => {
                const tmpData = {};
                const tmpDataAsset = {};
                this.comments = success.data;
                this.prePostCommentsRows = this.otherComments = this.comments.filter(data => {
                    if (data.asset === null && data.shot === null) {
                        return data
                    }
                });
                this.assetsCommentsRows = this.assetComments = this.comments.filter(data => {
                    if (data.asset != null) {
                        return data
                    }
                });
                this.assetComments.forEach(data => {
                    if (tmpDataAsset[`${data.assetCode}`]) {
                        if (!tmpDataAsset[`${data.assetCode}`].child) {
                            tmpDataAsset[`${data.assetCode}`].child = []
                        }
                        tmpDataAsset[`${data.assetCode}`].child.push(data)
                    } else {
                        tmpDataAsset[`${data.assetCode}`] = data;
                        tmpDataAsset[`${data.assetCode}`].child = []
                    }

                });
                this.assetsCommentsRows = this.assetComments = Object.values(tmpDataAsset);
                this.totalAssetsCount = this.assetsCommentsRows.length;

                this.shotsCommentsRows = this.shotComments = this.comments.filter(data => {
                    if (data.asset == null) {
                        return data
                    }

                });
                this.shotComments.forEach(comment => {
                    if (tmpData[`${comment.season}${comment.episode}${comment.sequence}${comment.shot}`]) {
                        if (!tmpData[`${comment.season}${comment.episode}${comment.sequence}${comment.shot}`].child) {
                            tmpData[`${comment.season}${comment.episode}${comment.sequence}${comment.shot}`].child = []
                        }
                        tmpData[`${comment.season}${comment.episode}${comment.sequence}${comment.shot}`].child.push(comment)
                    } else {
                        tmpData[`${comment.season}${comment.episode}${comment.sequence}${comment.shot}`] = comment;
                        tmpData[`${comment.season}${comment.episode}${comment.sequence}${comment.shot}`].child = []
                    }

                });
                this.shotsCommentsRows = this.shotComments = Object.values(tmpData);



                this.length = success.total;

                this.totalElements = success.totalElements;
                this.totalShotsCount = success.totalElements;
                this.noRecordFound = true;
                this.isLoading = false;
            }, (error)=>{
                this.isLoading = false;
            } );
    }

    onSubmitComments(form: NgForm) {
        this.isFilterSubmitted = true;
        if (form.value.shot_id != undefined) {
            this.config.where.shot_id = form.value.shot_id.id;
        }
        if (form.value.sequence_id != undefined) {
            this.config.where.sequence_id = form.value.sequence_id.id;
        }
        if (form.value.episode_id != undefined) {
            this.config.where.episode_id = form.value.episode_id.id;
        }
        if (form.value.department_id != undefined) {
            this.config.where.department = form.value.department_id;
        }
        if (form.value.role_type_id != undefined) {
            this.config.where.role_type_id = form.value.role_type_id.id;
        }
        if (form.value.asset_id != undefined) {
            this.config.where.asset_id = form.value.asset_id.id;
        }

        if (form.value.emp_code != undefined) {
            this.config.where.user_id = form.value.emp_code.id;
        }

        if (form.value.vendor_id != undefined) {
            this.config.where.vendor_id = form.value.vendor_id.id;
        }

        if ($('#commentDate').val() != '' && $('#commentDate').val() != undefined) {
            this.config.where.commentDate = moment($('#commentDate').val()).format('YYYY-MM-DD');
        }
        //this.isAdvanceSearchApplied = true;
        this.page = 1;
        this.onChangeTable(this.config);
        setTimeout(() => {
            this.isFilterSubmitted = false;
        }, 2000);
    }

    //Comments tab
    getTaskComments() {
        this.where.projectId = this.projectId;
        this.dataService.getListData('getTaskComments', { 'where': this.where })
            .subscribe((success) => {
                this.comments = success.data;
            }, (rejected) => {

            });
    }

    getEpisodes(query) {
        this.dataService.getListData('episodesItems', { project_id: this.projectId, 'fromCreateTask': true, 'query': query['query'] })
            .subscribe((success) => {
                this.episodes = success.data;
            }, (rejected) => {

            });
    }

    getSequences(query) {
        this.dataService.getListData('sequencesItems', { project_id: this.projectId, 'fromTasks': true, 'query': query['query'] })
            .subscribe((success) => {
                this.sequences = success.data;
            }, (rejected) => {

            });
    }

    getShots(query) {
        this.dataService.getListData('shotsItems', { project_id: this.projectId, 'fromTasks': true, 'query': query['query'] })
            .subscribe((success) => {
                this.shots = success.data;
            }, (rejected) => {

            });
    }

    getAssets(query) {
        this.dataService.getListData('assetsItems', { project_id: this.projectId, 'fromTasks': true, 'query': query['query'] })
            .subscribe((success) => {
                this.assets = success.data;
            }, (rejected) => {

            });
    }

    getDepartments(query) {
        this.dataService.getListData('getDepartments', { projectId: this.projectId, 'query': query['query'] })
            .subscribe((success) => {
                this.departments = success.data;
            }, (rejected) => {

            });
    }

    getRoleTypes(project_id) {
        return new Promise((resolve, reject) => {
            this.dataService.getListData('role_typeList', { 'project_id': project_id })
                .subscribe((success) => {
                    this.roleTypes = success.data;
                    resolve(200);
                }, (rejected) => {

                });
        });
    }

    getEmpDetails(project_id, query, type) {
        this.where.project_id = project_id;
        this.where.queryType = type;
        this.dataService.getListData('tlgetEmpDetails', { 'where': this.where, 'query': query['query'] })
            .subscribe((success) => {
                this.empCodeList = success.data;
                this.empCodeList.unshift({ id: 0, emp_code: 'All' });
                //this.roleTypes.unshift({ id:0,name: 'All'});
            }, (rejected) => {

            });
    }

    getVendorList(projectId, query) {
        this.where.project_id = projectId;
        this.dataService.getOtherData('getVendorList', { 'query': query['query'] })
            .subscribe((success) => {
                this.vendorList = success.data;

            }, (rejected) => {

            });
    }

    clearFilter() {
        this.isFilterReset = true;
        this.commentsFilter.episode_id = [];
        this.commentsFilter.sequence_id = [];
        this.commentsFilter.shot_id = [];
        this.commentsFilter.asset_id = [];
        this.commentsFilter.department_id = [];
        this.commentsFilter.department = [];
        this.commentsFilter.role_type_id = [];
        this.commentsFilter.emp_code = [];
        this.commentsFilter.vendor_id = [];
        let date = (<HTMLInputElement>document.getElementById('commentDate'));
        date.value = '';
        //this.page = 1;
        this.config.where.episode_id = '';
        this.config.where.sequence_id = '';
        this.config.where.shot_id = '';
        this.config.where.asset_id = '';
        this.config.where.department_id = '';
        this.config.where.department = [];
        this.config.where.role_type_id = '';
        this.config.where.commentDate = '';
        this.config.where.emp_code = '';
        this.config.where.user_id = '';
        this.config.where.vendor_id = '';
        this.page = 1;
        this.onChangeTable(this.config);
        setTimeout(() => {
            this.isFilterReset = false;
        }, 2000);
    }

    downloadTaskComments() {
        this.gridService.openProgressModal();
        this.where.export = true;
        this.where.project_id = this.projectId;
        this.where.role = this.currentRole;
        this.where.is_external_login = this.is_external_login;
        this.where.episode_id = this.config.where.episode_id;
        this.where.sequence_id = this.config.where.sequence_id;
        this.where.asset_id = this.config.where.asset_id;
        this.where.shot_id = this.config.where.shot_id;
        this.where.department = this.config.where.department;
        this.where.role_type_id = this.config.where.role_type_id;
        this.where.user_id = this.config.where.user_id;
        this.where.vendor_id = this.config.where.vendor_id;
        this.dataService.getListData('downloadTaskComments', { 'where': this.where })
            .subscribe((success) => {
                window.location.href = success.data;
                this.gridService.myEventEmitterForProgressModal.emit(true);
            }, (rejected) => {

            });
        this.gridService.progressFunction();

    }

    // ! Chirag


    //! Collapse Toggle Icon Menu

    toggleIcon(table, arrow) {
        if (table == 'assets') {
            this.isAssetsCollapsed = !this.isAssetsCollapsed;
            if (this.isAssetsCollapsed) {
                this.assetCollapsedArrow = 'keyboard_arrow_up';
            } else {
                this.assetCollapsedArrow = 'keyboard_arrow_down';
            }
        } else if (table == 'shots') {
            this.isShotsCollapsed = !this.isShotsCollapsed;
            if (this.isShotsCollapsed) {
                this.shotCollapsedArrow = 'keyboard_arrow_up';
            } else {
                this.shotCollapsedArrow = 'keyboard_arrow_down';
            }
        }
    }

    // ! Show Task Details For Shot


    taskDetailsModal(template: TemplateRef<any>, data: any) {
      console.log('clicked')
        this.commentDetail = [];
        this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
        this.commentDetail.push(data);
    }

    // ! Show Task Details For Shot


    taskDetailsModalAsset(template: TemplateRef<any>, data: any) {
        this.commentDetailAsset = [];
        this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
        this.commentDetailAsset.push(data);
    }

    // Datatable functions

        // on user event
      onUserEvent(event, shotTemplate: TemplateRef<any>, assetTemplate: TemplateRef<any>) {
        if (event.type == "click") {

          const row = event.row;
          const column = event.column;
          //  this.cellClick(row, column.prop);

          if(column.name=='Shot #'){
            this.taskDetailsModal(shotTemplate,row);
          }
          else if(column.name=='AssetCode #'){
            this.taskDetailsModalAsset(assetTemplate,row);
          }

          console.log(row, column);
        }
      }

      onPageChange(e, tableNumber): void {
        this.openedPage = e.offset;

        if(tableNumber=='table1'){
          this.table1.offset = this.openedPage;
          this.currentPage1 = e.offset;
        }
        else if(tableNumber=='table2'){
          this.table2.offset = this.openedPage;
          this.currentPage2 = e.offset;
        }
        else if(tableNumber=='table3'){
          this.table3.offset = this.openedPage;
          this.currentPage3 = e.offset;
        }


        this.pagePayload.page = e.offset * this.pageSize;
        this.config.start = this.pagePayload.page;

        this.onChangeTable(this.config);
      }

      // TODO[Dmitry Teplov] wrap dynamic limit in a separate component.
      public onLimitChange(limit: any, tableNumber): void {
        this.changePageLimit(limit);

        if(tableNumber=='table1'){
          this.table1.limit = this.pageSize;
          this.table1.recalculate();
        }
        else if(tableNumber=='table2'){
          this.table2.limit = this.pageSize;
          this.table2.recalculate();
        }
        else if(tableNumber=='table3'){
          this.table3.limit = this.pageSize;
          this.table3.recalculate();
        }

        setTimeout(() => {
          if(tableNumber=='table1'){
            if (this.table1.bodyComponent.temp.length <= 0) {
              this.table1.offset = Math.floor((this.table1.rowCount - 1) / this.table1.limit);
            }
          }
          else if(tableNumber=='table2'){
            if (this.table2.bodyComponent.temp.length <= 0) {
              this.table2.offset = Math.floor((this.table2.rowCount - 1) / this.table2.limit);
            }
          }
          else if(tableNumber=='table3'){
            if (this.table3.bodyComponent.temp.length <= 0) {
              this.table3.offset = Math.floor((this.table3.rowCount - 1) / this.table3.limit);
            }
          }
        });
      }

      private changePageLimit(limit: any): void {
        this.pageSize = parseInt(limit, 10);
        this.config.itemsPerPage = limit;

        this.onChangeTable(this.config);
      }


}
