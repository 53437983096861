import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { Ng2TableModule } from 'ng2-table/ng2-table';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../../shared/shared.module';

import { SidebarModule } from 'ng-sidebar';

import { DragulaModule, DragulaService } from 'ng2-dragula';
import { Daterangepicker } from 'ng2-daterangepicker';

import { PopoverModule } from 'ngx-bootstrap/popover';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutoCompleteModule, ButtonModule, GrowlModule, InputSwitchModule } from 'primeng/primeng';
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { TasksRoutingModule } from './tasks-routing.module';



import { TaskRescheduleComponent } from '../task-reschedule/task-reschedule.component';
import { CreateTaskComponent } from '../create-task/create-task.component';
import { CommentsComponent } from '../comments/comments.component';
import { TasksReviewComponent } from '../tasks-review/tasks-review.component';
import { TaskTrackComponent } from '../task-track/task-track.component';
import { TaskDetailsComponent } from '../task-details/task-details.component';
import { TaskChecklistComponent } from '../task-checklist/task-checklist.component';
import { TaskColumnComponent } from '../task-column/task-column.component';
import { ThumbnailDirectiveTasks } from '../directives/thumbnail-task.directive';
import { CustomChipComponent } from '../task-track/layer-chip/layer-chip.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TaskListComponent } from '../task-list/task-list.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NO_ERRORS_SCHEMA } from '@angular/compiler/src/core';
import { LightboxModule } from 'primeng/lightbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MentionModule } from 'angular-mentions';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { VersionComponent } from '../task-details/datatables/version/version.component';
import { TasksReviewDatatableComponent } from '../tasks-review-datatable/tasks-review-datatable.component';
import { TaskTrackDatatableComponent } from '../task-track-datatable/task-track-datatable.component';


@NgModule({
  imports: [
    CommonModule,
    TasksRoutingModule,
    FormsModule,
    SharedModule,
    Ng2TableModule,
    NgxFileDropModule,
    TooltipModule,
    PaginationModule,
    SidebarModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    DragulaModule,
    Daterangepicker,
    NgxSpinnerModule,
    PopoverModule,
    InfiniteScrollModule,
    AutoCompleteModule,
    LazyLoadImageModule,
    NgxDatatableModule,
    InputSwitchModule,
    LightboxModule,
    ButtonModule,
    ProgressSpinnerModule,
    MentionModule,
    DragDropModule,
  ],
  declarations: [
    TaskRescheduleComponent,
    CreateTaskComponent,
    CommentsComponent,
    TasksReviewComponent,
    TaskTrackComponent,
    TaskDetailsComponent,
    TaskChecklistComponent,
    TaskColumnComponent,
    ThumbnailDirectiveTasks,
    CustomChipComponent,
    TaskListComponent,
    VersionComponent,
    TasksReviewDatatableComponent,
    TaskTrackDatatableComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DragulaService
  ], exports: [CreateTaskComponent]
})
export class TasksModule {
}
