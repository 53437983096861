import { Component, OnInit } from '@angular/core';
import { CommonJs } from '../../common/common-js';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  role = localStorage.getItem('role');
  //homeURL:any;
  homeURL = '/dashboard';

  public isSuperAdmin: boolean;
  public AccessCode = '';

  constructor(
    public commonJs: CommonJs,
    public router: Router,
    private activatedRoute: ActivatedRoute) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isSuperAdmin = (event.url.indexOf('superadmin') !== -1);
      }
    });

  }

  ngOnInit() {
    // this.activatedRoute.params.subscribe((params: Params) => {
    //   });
    let clientCodePre = this.commonJs.getClientCodePrefix();
    if (this.role == 'superadmin') {
      this.homeURL = '/accounts';
    }

    this.activatedRoute.params.subscribe(val => {
      //let param = this.activatedRoute.snapshot.paramMap.get('access_code');
      this.AccessCode = localStorage.getItem('access_code');
    });
  }

}
