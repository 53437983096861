import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-date-help-modal',
  templateUrl: './date-help-modal.component.html',
  styleUrls: ['./date-help-modal.component.css']
})
export class DateHelpModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
