import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
declare var $: any;
import { CommonJs } from '../../../common/common-js';
import { ToastrService } from '../../../services/toastr.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
	selector: 'list-item',
	templateUrl: './list-item.component.html',
	styleUrls: ['./list-item.component.css']
})
export class ListItemComponent implements OnInit {

	bsModalRef: BsModalRef;
	entityName = 'List items';
	entityNameSingular = 'Item Name';
	apiRoutePrefix = 'listItem';
	modalType: string = 'Add';
	selectAll: boolean = false;

	@Input('attr.data') dataHtml: any;
	@ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
	@ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
	//@ViewChild("listItemInput") listItemInput: ElementRef;
	@Output() closeCreateListItemAdmins = new EventEmitter();

	data: any;
	listItems_data: any;
	hideListFields: boolean = false;
	myform: any;
	lists: { [index: string]: any } = {};
	listItems: ListItemAdmins;
	onClose: any;
	errors: any;
	focused: string = '';
	focused_list: string = '';
	ListSaveButtonText: string = 'SAVE';
	SaveButtonText: string = 'SAVE';
	DeleteButtonText: string = 'DELETE';
	isDeleteMode: any;
	clickOn: string = 'save';
	deleteId: number;
	deleteName: string = '';
	isDisplyFrm: boolean = false;
	deleteType: string;
	alertMessage: string = '';
	settings: any = JSON.parse(localStorage.getItem('settings'));
	role: string = this.commonJs.getLocalStorage('role');
	type: Array<any> = [
		{ id: "1", text: "Normal" },
		{ id: "2", text: "Account" }
	];

	//isEditable = [{"id":0,"name":"Yes"},{"id":1,"name":"No"}];
	//isEditable = [{id:1, name:"Yes", checked:false}];

	color: string = "#333333";
	showListSection: boolean = false;
	selectedItems: Array<any> = [];

	constructor(
		private modalService: BsModalService,
		private dataService: DataService,
		public commonJs: CommonJs,
		private toastrService: ToastrService,
	) { }

	ngOnInit() {
		if (this.dataHtml != undefined) {
			this.data = this.dataHtml;
		}
		this.lists = { id: 0, list_name: '', old_list_name: '', type: '', editable: 1 };
		this.listItems = { item_name: '', slug: '', color: '#333333' };
		$.AdminBSB.input.activate();
		this.subngOnInit();
	}

	search(query, column) {
		if (column == 'type') {
			this.type = this.type.map(v => { return v });
		}
	}

	onChange(classId, flag) {
		var tempCheck = [];
		if (flag) {
			this.lists.editable = 1;
		} else {
			this.lists.editable = 0;
		}
	}

	// To get item from the list to edit in the modal form
	getListItem(template: TemplateRef<any>, id) {
		// open modal for edit
		this.bsModalRef = this.modalService.show(template);
		this.modalType = 'Edit';

		this.dataService.getDataById(this.apiRoutePrefix + '/' + id + '/edit')
			.subscribe((success) => {
				this.listItems = success.data;
				this.color = success.data.color;
				this.hideListFields = true;
				this.isDisplyFrm = true;
				this.focused = 'focused';
			}, (rejected) => {

			});
	}

	subngOnInit() {
		this.focused_list = 'focused';
		let list_type = this.type.findIndex((col) => {
			return col.id == this.data.type;
		});

		let listType = this.type[list_type]['text'];
		this.lists = {
			id: this.data.id,
			list_name: this.data.list_name,
			old_list_name: this.data.list_name,
			//type:this.data.type,
			type: { id: this.data.type, text: listType },
			editable: this.data.editable
		};
		//this.listItemInput.nativeElement.focus();
		this.dataService.getListData(this.apiRoutePrefix + 'List/' + this.data.list_name, '')
			.subscribe((success) => {
				if (this.settings.is_studio_multiple_location == '' && this.data.list_name === 'emp_location') {
					this.listItems_data = success.data.slice(0, 1);
				} else {
					this.listItems_data = success.data;
				}
			}, (rejected) => {

			});
	}

	onSubmitList(formList: NgForm) {
		this.data.type = formList.value.type;
		if (formList.value.type) {
			formList.value.type = formList.value.type.id;
		}
		formList.value.id = this.data.id;
		this.ListSaveButtonText = 'SAVING';
		if (formList.value.old_list_name != '') {
			this.dataService.updateData('list/' + formList.value.old_list_name, formList.value)
				.subscribe((success) => {
					this.ListSaveButtonText = 'SAVED';
					setTimeout(() => {
						this.ListSaveButtonText = 'SAVE';
						//this.lists = { list_name: '', old_list_name: '',type:'' };
						this.focused_list = 'focuse';
						//this.onChangeTable(this.config);
						this.data.list_name = formList.value.list_name;
						this.lists.old_list_name = formList.value.list_name;

						this.close(1);
					}, 2);
					this.showListSection = false;

				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
					}
				});

		} else {
			this.dataService.storeData('list', formList.value)
				.subscribe((success) => {
					this.ListSaveButtonText = 'SAVED';
					setTimeout(() => {
						this.ListSaveButtonText = 'SAVE';
						//this.lists = { list_name: '', old_list_name: '',type:'' };
						this.focused_list = 'focuse';
						//this.onChangeTable(this.config);
						this.close(1);
					}, 2);
					this.showListSection = false;

				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
					}
				});
		}
	}

	onSubmit(form: NgForm) {
		if (this.clickOn == 'save') {
			this.SaveButtonText = 'SAVING';
		}
		form.value.role = this.role;
		form.value.list_name = this.data.list_name;
		form.value.type = this.data.type;
		this.bsModalRef.hide();

		if (this.listItems['id'] != undefined && this.listItems['id'] != '') {

			if (this.listItems) {
				form.value.id = this.listItems['id'];
			}

			this.dataService.updateData(this.apiRoutePrefix + '/' + form.value.id, form.value)
				.subscribe((success) => {
					// Save effect within button
					if (this.clickOn == 'save') {
						this.SaveButtonText = 'SAVED';
						this.subngOnInit();
						setTimeout(() => {
							this.SaveButtonText = "SAVE";
						}, 2);
						this.alertMessage = success.message;
						this.hideListFields = false;
						this.listItems = {
							item_name: '',
							slug: '',
							color: '#333333'
						}
						this.color = "#333333";
					}
					this.toastrService.notify(success.status, '', success.message);
					this.isDisplyFrm = false;
					this.focused = '';

				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
					}
					setTimeout(() => {
						this.SaveButtonText = "SAVE";
					}, 2);
				});
		} else {

			this.dataService.storeData(this.apiRoutePrefix, form.value)
				.subscribe((success) => {
					// Save effect within button
					if (this.clickOn == 'save') {
						this.SaveButtonText = 'SAVED';
						this.subngOnInit();
						setTimeout(() => {
							this.SaveButtonText = "SAVE";
						}, 2);
						this.alertMessage = success.message;

						this.hideListFields = false;
						this.listItems = {
							item_name: '',
							slug: '',
							color: '#333333'
						}
						this.color = "#333333";
						this.isDisplyFrm = false;
					}

				}, (error) => {
					//this.alertMessage = 'Something went wrong, Please try again!';
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
						this.SaveButtonText = "SAVE";

					}
				});
		}
	}

	backToCreate(event) {
		event.stopPropagation();
		this.showListSection = false;
		this.hideListFields = false;
		this.isDeleteMode = false;
		this.listItems = {
			item_name: '',
			slug: '',
			color: '#333333'
		};
		this.color = "#333333";
		this.isDisplyFrm = false;
		this.focused = 'focuse';
		this.errors = [];
		//this.listItemInput.nativeElement.focus();
	}

	deleteListItem(event, id, name) {
		event.stopPropagation();
		this.deleteId = id;
		this.deleteName = name;
		this.isDeleteMode = true;
		this.deleteType = 'list-item';
	}

	deleteList(list_name) {
		event.stopPropagation();
		this.deleteName = list_name;
		this.isDeleteMode = true;
		this.deleteType = 'list';
	}

	deleteListAction(list_name) {
		let that = this;
		this.DeleteButtonText = 'DELETEING';
		this.dataService.deleteData('listDestroy', { list_name })
			.subscribe((success) => {
				//that.onChangeTable(that.config);
				that.DeleteButtonText = 'DELETED';
				setTimeout(() => {
					that.subngOnInit();
					that.isDeleteMode = false;
					that.DeleteButtonText = "DELETE";
					this.close(2);
				}, 2);

			}, (rejected) => {

			});
	}

	deleteListItemAction(id) {
		let that = this;
		this.DeleteButtonText = 'DELETEING';
		this.dataService.deleteData(that.apiRoutePrefix + 'Destroy', { id })
			.subscribe((success) => {
				//that.onChangeTable(that.config);

				this.selectedItems.splice(this.selectedItems.indexOf(id), 1);

				that.DeleteButtonText = 'DELETED';
				setTimeout(() => {
					that.subngOnInit();
					that.isDeleteMode = false;
					that.DeleteButtonText = "DELETE";
					that.isDisplyFrm = false;
				}, 2);
				this.toastrService.notify(success.status, '', success.message);

			}, (rejected) => {

			});
	}

	listSectionToggle(val) {
		this.showListSection = val;
	}

	onDropSuccess($event) {
		let rec = [];
		rec.push($event);
	}

	onDragEnd() {
		// this.targetListItem.push($event.dragData);
	}

	close(id) {
		this.closeCreateListItemAdmins.emit({ id: id });
	}

	onChangeCustomEmploye(setting, flag) {
		if (setting == 'display_emp_custom_columns') {
			if (flag) {
				this.settings.display_emp_custom_columns = 1;
			} else {
				this.settings.display_emp_custom_columns = 0;
			}

			this.dataService.updateData("studio-settings", { display_emp_custom_columns: this.settings.display_emp_custom_columns, currency: this.settings.currency, billing_unit: this.settings.billing_unit })
				.subscribe((success) => {
					this.SaveButtonText = 'SAVED';
					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.commonJs.getAllSettings();
						let getSettings = localStorage.getItem("settings");
						if (!getSettings) {
							this.commonJs.getAllSettings();
						}
					}, 2);
					this.toastrService.notify('success', '', 'Studio settings updated successfully.');

				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
						this.SaveButtonText = "SAVE";
					}
				});
		}
	}

	changeStatus(id, item, flag) {
		if (flag) {
			item.status = 1;
		} else {
			item.status = 0;
		}
		item.role = this.role;
		this.dataService.updateData(this.apiRoutePrefix + '/' + id, item)
			.subscribe((success) => {
				// Save effect within button
				if (this.clickOn == 'save') {
					this.SaveButtonText = 'SAVED';
					this.subngOnInit();
					setTimeout(() => {
						this.SaveButtonText = "SAVE";
					}, 2);
					this.alertMessage = success.message;
					this.hideListFields = false;
					this.listItems = {
						item_name: '',
						slug: '',
						color: '#333333'
					}
					this.color = "#333333";
				}
				this.toastrService.notify(success.status, '', success.message);
				this.isDisplyFrm = false;
				this.focused = '';

			}, (error) => {
				if (error.error) {
					var err = error.error;
					this.errors = err.error;
				}
				setTimeout(() => {
					this.SaveButtonText = "SAVE";
				}, 2);
			});
	}

	frmToggle(template: TemplateRef<any>, event) {

		this.modalType = 'Add';
		this.listItems.item_name = '';
		event.stopPropagation();

		this.bsModalRef = this.modalService.show(template);

		this.isDisplyFrm = !this.isDisplyFrm;
		this.errors = '';

		//this.listItemInput.nativeElement.focus();
		this.focused = 'focused';
		this.listItems = {
			item_name: '',
			slug: '',
			color: '#333333',
		}
		this.color = "#333333";
		//return false;
	}

	// To check an individual
	checkedAction(checkAllItems?, itemId?) {
		if (checkAllItems) {
			if (this.selectedItems.length == 0) {
				this.selectAll = true;
				this.selectedItems = [];
				this.listItems_data.forEach((ele: any) => {
					this.selectedItems.push(ele.id);
				});
			} else {
				this.selectAll = false;
				this.selectedItems = [];
			}
		}
		else {
			if (this.selectedItems.indexOf(itemId) < 0) {
				this.selectedItems.push(itemId);
			}
			else {
				this.selectedItems.splice(this.selectedItems.indexOf(itemId), 1);
			}
			// console.log(this.selectedItems);
		}
	}

	// To close the modal
	hideModal() {
		this.bsModalRef.hide();
	}

	// To multipleDelete
	massDelete() {
		this.selectedItems.forEach((ele: any) => {
			this.deleteListItemAction(ele);

		})
	}

}
interface Lists {
	id: number,
	list_name: string,
	old_list_name: string,
	type: string,
	editable: number
}
interface ListItemAdmins {
	item_name: string,
	slug: string
	color: string
}
