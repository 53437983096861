import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { CommonJs } from '../../common/common-js';
import { ToastrService } from '../../services/toastr.service';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { GridService } from 'src/app/services/grid.service';
declare var $: any;

@Component({
	selector: 'footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	isActive: boolean = true;
	isShowHide: boolean = false;
	taskFromLocal: { [index: string]: any } = {};
	currentTask: { [index: string]: any } = {};
	taskDetails: any;
	role = localStorage.getItem('role');
	uploadUrl = this.commonJs.getUploadUrl();
	allTask: any = [];
	settings: any = JSON.parse(localStorage.getItem('settings'));
	clientCode = localStorage.getItem('access_code');
	userId = localStorage.getItem('userId');
	currentRunningSub;
	changeProjectSub;
	pauseTaskSub;
	showCompletionInput: boolean = false;
	errors: { [index: string]: any } = {};
	where: { [index: string]: any } = {};
	leadList: Array<any>;
	taskDetail: { [index: string]: any } = {};
	isTaskSentForReview: any;
	isLoading: boolean = true;


	constructor(private dataService: DataService,
		public commonJs: CommonJs,
		private router: Router,
		private toastrService: ToastrService,
		public gridService: GridService,
		public cd: ChangeDetectorRef
	) { }

	ngOnInit() {
		this.toastrService.closeTrackButton.subscribe(() => {
			this.isShowHide = false;
		});
		this.dataService.getNotifications(this.clientCode, this.userId);
		this.isActive = true;
		let role = localStorage.getItem('role');
		if (role == 'artist' || role == 'lead') {
			this.getRunningTask();
		}
	}

	getRunningTask() {
		let userData = {};
		userData['userId'] = localStorage.getItem('userId');
		userData['role'] = this.role;
		this.dataService.getListData('getRunningTask', { 'userData': userData })
			.subscribe((success) => {
				if (success.data.length > 0) {
					this.taskDetails = success.data.find(obj => obj.is_running === 1);
					this.allTask = success.data;
				} else {
					this.taskDetails = null;
					this.allTask = [];
				}
			}, (rejected) => {
				// //console.log(rejected);
			});
	}

	showPopover(currentTask) {
		this.currentTask = currentTask;
	}

	gotoTask(projectId, type) {
		if (type == 'project') {
			this.changeProjectSub = this.dataService.changeProject(projectId);
			this.router.navigate(['/' + this.commonJs.getClientCodePrefix() + '/projects/tasks-track/' + projectId]);
		} else if (type == 'general' && projectId != null && projectId != '') {
			this.changeProjectSub = this.dataService.changeProject(projectId);
			this.router.navigate(['/' + this.commonJs.getClientCodePrefix() + '/projects/dashboard/' + projectId]);
		}

		if (this.changeProjectSub) {
			this.changeProjectSub.unsubscribe();
		}
	}

	pauseTask(taskData, type) {
		//taskData.status = 'pause';
		this.pauseTaskSub = this.dataService.pauseTaskProject(taskData, type);
		if (type == 'project') {
			this.isActive = false;
		}
	}

	ngOnDestroy() {
		// this.currentRunningSub.unsubscribe();
		// if (this.changeProjectSub) {
		// 	this.changeProjectSub.unsubscribe();
		// }

		// if (this.pauseTaskSub) {
		// 	this.pauseTaskSub.unsubscribe();
		// }
	}

	completionPercentageCellClick() {
		this.showCompletionInput = true;
		this.errors = {};
		// $('.cursor-pointer').removeClass('active');
		// $('.' + key + '-td').addClass('active');
		// $('.' + key + '-input input').focus();
	}

	closeInput() {
		this.showCompletionInput = false;
		this.errors = {};
	}

	completionPercentageCellUpdate(key: any, data) {
		this.showCompletionInput = false;
		this.updateCompletionPercentage(key, data);
	}

	updateCompletionPercentage(key, data) {
		this.where.projectId = data.project_id;
		this.where.taskId = data.task_id;
		this.where.taskAssignmentId = data.ta_id;
		this.where.completion = data.completion;
		this.dataService.storeData('updateCompletionPercentage', { 'data': this.where })
			.subscribe((success) => {
				//this.assetTaskData = success.data;
				if (success.status == 'success') {
					this.toastrService.notify('success', '', success.message);
				} else {
					this.toastrService.notify('warn', '', success.message);
				}
			}, (error) => {
				error = error.json();
				this.errors = error.error;
				this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
			});
	}

	/** Task popup open and close  */
	taskPopup() {
		this.isShowHide = !this.isShowHide;
		if (this.isShowHide) {
			this.getRunningTask();
		}
	}



	convertToSeconds(timeStr: string): number {
		const [hours, minutes, seconds] = timeStr.split(":").map(Number);
		return hours * 3600 + minutes * 60 + seconds;
	}

	convertToMinutes(timeStr: string): number {
		const [hours, minutes, _] = timeStr.split(":").map(Number);
		return hours * 60 + minutes;
	}

	convertToHHMMSS(seconds: number): string {
		const hours = Math.floor(seconds / 3600);
		const minutes = Math.floor((seconds % 3600) / 60);
		const remainingSeconds = seconds % 60;
		const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
		const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
		const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;
		return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
	}

	/** Check task duration */
	checkTaskDutation() {
		const startDate = new Date(this.taskDetail.review_date);
		const currentDate = new Date();
		const elapsedTime = currentDate.getTime() - startDate.getTime();
		const elapsedHours = Math.floor(elapsedTime / (1000 * 60 * 60));
		const elapsedMinutes = Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60));
		const elapsedSeconds = Math.floor((elapsedTime % (1000 * 60)) / 1000);
		const elapsed_time = `${elapsedHours}:${elapsedMinutes}:${elapsedSeconds}`;
		const totalDurationInSeconds = this.convertToSeconds(this.taskDetail.total_duration);
		const timeSpendInSeconds = this.convertToSeconds(elapsed_time);
		const totalTimeInSeconds = totalDurationInSeconds + timeSpendInSeconds;
		const totalDuration = this.convertToHHMMSS(totalTimeInSeconds);
		const totalDurationInMinutes = this.convertToMinutes(totalDuration);
		if (totalDurationInMinutes > this.settings.task_timer_lock) {
			return true
			// You can take appropriate actions here.
		} else {
			return false
		}
	}

	onShownLead(task) {
		this.isLoading = true;
		this.commonJs.getAllSettings(true);
		this.gridService.taskPlayPuase.subscribe((res: any) => {
			this.gridService.resetEventEmitter();
			if (res) {
				this.settings = JSON.parse(localStorage.getItem('settings'));
				this.taskDetail = {};
				this.taskDetail = task;
				if (this.settings.task_timer_lock != 0) {
					this.isTaskSentForReview = this.checkTaskDutation();
				} else {
					this.isTaskSentForReview = true;
				}
				this.isLoading = false;
				this.cd.markForCheck();
				this.dataService.getListData('getReviewLeadList', { 'project_id': this.taskDetail.project_id, 'department_id': this.taskDetail.department_id })
					.subscribe((success) => {
						this.leadList = success.data;

						const objToMove = this.leadList.find(obj => obj.department === task.department_name); // find the index of the object


						const index = this.leadList.findIndex(obj => obj.department === task.department_name); // find the index of the object

						if (index !== -1) { // if the object is found in the array
							this.leadList.splice(index, 1); // remove it from its current position
							this.leadList.unshift(objToMove); // add it to the beginning of the array
						}
						this.taskDetail.count = success.count;
						if (success.count == 1) {
							this.taskDetail.lead = success.data[0];
						} else if (success.count == 0) {
							this.taskDetail.lead = [];
							this.taskDetail.supervisor = success.supervisor;
						} else if (success.count > 1) {
							this.taskDetail.lead = success.data[0];
						}

					}, (rejected) => {

					});

				this.errors = {};
			}
		});
	}


	dropdownClick(e) {
		e.originalEvent.stopPropagation();
	}

	search(query, column) {
		if (column == 'lead') {
			//this.getSecondaryDept(this.users.primary_department['id'],query['query']);
			this.leadList = this.leadList.map(o => {
				return o;
			});
		}
	}

	/** task start */
	taskStart(data: any, status: string) {
		// //console.log(data);
		const payload: any = {
			project_id: data.project_id,
			task_id: data.task_id,
			task_assignment_id: data.ta_id,
			task_version_id: data.task_version_id,
			status: status,
			current_role: this.role
		}

		//this.changesTaskStatus('', 'done', this.taskDetail);
		this.dataService.getOtherData('changeTaskStatus', payload)
			.subscribe((success) => {
				if (success.status == 'success') {
					this.toastrService.notify('success', '', success.message);
					this.dataService.notifyOther({ value: 'success' });
					this.getRunningTask();
				}
			}, (rejected) => {
				this.toastrService.notify('error', '', 'Something went wrong please try again!');
			});
	}

	/** Task send for review */
	onSubmit(form: NgForm) {
		const payload: any = {
			project_id: this.taskDetail.project_id,
			lead_user_id: this.taskDetail.lead,
			task_id: this.taskDetail.task_id,
			task_assignment_id: this.taskDetail.ta_id,
			task_version_id: this.taskDetail.task_version_id,
			status: 'done',
			current_role: this.role,
			comment: '',
			path: '',
			images: []
		}
		this.dataService.getOtherData('changeTaskStatus', payload)
			.subscribe((success) => {
				if (success.status == 'success') {
					this.toastrService.notify('success', '', success.message);
					this.dataService.notifyOther({ value: 'success' });
					this.getRunningTask();
				}

				if (success.status == 'waiting') {
					this.toastrService.notify('success', '', success.message);
					this.dataService.notifyOther({ value: 'success' });
					this.getRunningTask();
				}
			}, (rejected) => {
				this.toastrService.notify('error', '', 'Something went wrong please try again!');
			});
	}

}
