import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { TableColumn, SelectionType, DatatableComponent } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AppConfigs } from 'src/app/common/app-configs';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';
import { DatatableService } from 'src/app/services/datatable/datatable.service';
import { GridService } from 'src/app/services/grid.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { columns } from '../utility/column-generator';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ThumbnailModalServiceService } from 'src/app/services/thumbnail-modal-service.service';
import { ConfirmationPopupComponent } from 'src/app/common/confirmation-popup/confirmation-popup.component';

@Component({
  selector: 'app-edpisode-list',
  templateUrl: './edpisode-list.component.html',
  styleUrls: ['./edpisode-list.component.css']
})
export class EdpisodeListComponent implements OnInit {
  bsModalRef: BsModalRef;
  @ViewChild('showSummary', { static: false }) elementView: ElementRef;
  //Assets var
  episodeObj: any = {};

  // Datatable var

  rows: any = [];
  temp = [];
  currentPage: number = 0;
  pageSize: number = 100;
  totalItems: number = 0;
  isLoading: boolean = true;
  pagePayload: any = {
    page: 0,
    itemsPerPage: 100,
  }
  public readonly pageLimitOptions = [
    { value: 50 },
    { value: 100 },
    { value: 200 },
    { value: 300 },
  ];
  public dataTableColumns: TableColumn[] = [];
  selected = [];
  SelectionType: SelectionType[] = [];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('image', { static: false }) public image: TemplateRef<any>;
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;
  @ViewChild('duration', { static: false }) public duration: TemplateRef<any>;
  @ViewChild('number', { static: false }) public number: TemplateRef<any>;
  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;
  @ViewChild('stats', { static: true }) stats: ElementRef;
  @ViewChild('bidDays', { static: true }) bidDays: ElementRef;
  @ViewChild("departmentStatus", { static: true }) departmentStatus: TemplateRef<any>;
  @ViewChild("startDateTime", { static: true }) startDateTime: TemplateRef<any>;
  @ViewChild("endDateTime", { static: true }) endDateTime: TemplateRef<any>;

  deleteAllId = [];
  currentEpisode = [];

  //Config var
  public FilterColumns: Array<any> = ['name'];
  public config: any = {
    paging: true,
    FilterByColumns: {
      "season_id": "",
      "number": "",
      "name": "",
      "status": ""
    },
    sortNew: {},
    itemsPerPage: this.pagePayload.itemsPerPage,
    start: this.pagePayload.page,
    sorting: { columns: this.dataTableColumns },
    filtering: { filterString: '' },
    where: { 'project_id': this.episodeObj.projectId, phaseId: 0 },
    export: false,
    isLoadingFirstTime: true,
    isClearFilter: false,
  };

  isEpisodicSeries: boolean = false;
  _opened: boolean = false;
  _loadSideWindow: boolean = false;
  _ng_sidebar_data = {};
  _loadImportSideWindow: boolean = false;
  assetType: Array<any> = [];
  assetSubType: string = 'All';
  clientCodePrefix = this.commonJs.getClientCodePrefix();
  settings: any = JSON.parse(localStorage.getItem('settings'));
  sequenceSideWindow: boolean = false;
  sequence_opened: boolean = false;
  _ng_sidebar_sequence_data = {};
  episodeSummary = [];
  episodeSummaryTotal = [];
  statusArr = [];
  deptList = [];
  public configModal = {
    animated: true,
    keyboard: true,
    backdrop: true,
    ignoreBackdropClick: false
  };
  showDeptWiseSummary: boolean = false;
  isFilterApplied: boolean = false;

  isFullScreen:boolean = false;
  previousOffsetY:number = 0;

  constructor(
    private dataService: DataService,
    private gridService: GridService,
    private datatableService: DatatableService,
    private router: Router,
    public commonJs: CommonJs,
    public appConfigs: AppConfigs,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private titleService: Title,
    private modalService: BsModalService,
    private cd: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private thumbnailModalService: ThumbnailModalServiceService,
  ) {
    this.episodeObj.isAssetPermissions = {};
    this.episodeObj.subType = [];
    this.episodeObj.episodeSequence = [];
    this.episodeObj.complexity = this.appConfigs.sht_ast_complexity;
    this.datatableService.setParam(this.dataTableColumns, 'columns');
    this.datatableService.setParam(this.config, 'config');
    this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
    this.datatableService.setParam(this.episodeObj.apiRoutePrefix + 'List', 'serverUrl');
    this.episodeObj.statusOptions = [
      { id: 1, text: "Active" },
      { id: 0, text: "Inactive" },
    ];
  }

  searchStatus(event) {
    this.episodeObj.statusOptions = [...this.episodeObj.statusOptions];
  }

  ngOnInit() {
    this.episodeObj.parentId = ''
    this.titleService.setTitle(this.settings.html_prefix + ' Episodes');

    this.activatedRoute.params.subscribe((params: Params) => {
      this.episodeObj.projectId = params['id'];
      this.config.where.project_id = params['id'];
      if (params['seasons'] != undefined) {
        this.episodeObj.parentId = params['seasons'];
        this.dataService.getDataById('seasons/' + this.episodeObj.parentId + '/edit')
          .subscribe((success2) => {
            this.episodeObj.season_name = success2.data.name;
          });
        this.config.where['season_id'] = params['seasons'];
        this.config.isLoadingFirstTime = this.config.isLoadingFirstTime = false;
        this.pagePayload.page = 0;
        this.getProjectsById(this.episodeObj.projectId).then((res) => {
          this.createColumns([]);
        });
      } else {
        this.getProjectsById(this.episodeObj.projectId).then((res) => {
          this.createColumns([]);
        });
      }
    });

    this.episodeObj.isAssetPermissions['episode_add'] = this.commonJs.checkAccess('episodes', 'add', { 'baiPass': ['admin'] }, this.episodeObj.projectId);
    this.episodeObj.isAssetPermissions['episode_edit'] = this.commonJs.checkAccess('episodes', 'edit', { 'baiPass': ['admin'] }, this.episodeObj.projectId);
    this.episodeObj.isAssetPermissions['episode_view'] = this.commonJs.checkAccess('episodes', 'view', { 'baiPass': ['admin'] }, this.episodeObj.projectId);
    this.episodeObj.isAssetPermissions['episode_delete'] = this.commonJs.checkAccess('episodes', 'delete', { 'baiPass': ['admin'] }, this.episodeObj.projectId);
  }

  // open glbal image thumbnail viewer
  openImageModal(imageUrl: string): void {
    this.thumbnailModalService.openThumbnailModal(imageUrl);
  }

  episodeDepartment: any = [];

  /** Create Column */
  private createColumns(modifiedArray): void {
    let cellTemplate: any = {};
    setTimeout(() => {
      cellTemplate = {
        ids: this.idsT,
        image: this.image,
        status: this.status,
        hdrTpl: this.hdrTpl,
        duration: this.duration,
        number: this.number,
        stats: this.stats,
        bidDays: this.bidDays,
        departmentStatus: this.departmentStatus,
        startDateTime: this.startDateTime,
        endDateTime: this.endDateTime,
      };
      this.dataTableColumns = [];
      this.dataTableColumns = columns('episode', cellTemplate, modifiedArray);
      if (this.showDeptWiseSummary) {
        this.dataService.episodeDepartmentViewData(this.config).subscribe((department: any) => {
          this.episodeDepartment = department.data;
          // Iterate through the original array
          this.rows.forEach(originalObj => {
            // Find the matching dept object
            let matchingDeptObj = this.episodeDepartment.find(deptObj => deptObj.id === originalObj.id);

            // If a matching dept object is found, merge the dept values into the original object
            if (matchingDeptObj) {
              Object.assign(originalObj, matchingDeptObj.dept);
            }
          });
          this.rows = [...this.rows];
        });
      }
      // this.getEpisodeList();
    });
  }


  onUserEvent(event) {
    if (event.type == "click") {
      const row = event.row;
      const column = event.column;
      let shotData = {
        'type': 'shot',
        'task_dept_column': 'department_id',
        'task_dept_id': column.task_dept_id,
        'department': column.name,
        'data': row,
        'status': row.shotStatus,
        'actualStatus': row.shotStatus,
      }
      if (column.dynamicColumn && row[column.prop] != "") {
        // if (this.currentRole != 'client') {
        // this.openTaskAssignAndCommentsSideWindow(shotData);
      }
    }
  }

  // TODO[Dmitry Teplov] wrap dynamic limit in a separate component.
  public onLimitChange(limit: any): void {
    this.changePageLimit(limit);
    this.table.limit = this.pageSize;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor((this.table.rowCount - 1) / this.table.limit);
      }
    });
  }

  private changePageLimit(limit: any): void {
    this.isLoading = true;
    this.pageSize = parseInt(limit, 10);
    this.config.itemsPerPage = this.pageSize;
    this.dataService.pagePreferancePost({
      pageSize: this.pageSize,
      currentPage: this.config.start,
      pageIndex: this.table.offset,
      user_id: JSON.parse(localStorage.getItem('user')).id,
      project_id: this.episodeObj.projectId
    }, 'season_list_page');
    this.getEpisodeList();
  }

  /**
  * Description: Page change event
  * @param e pager object
  */
  firstTimeLoad: boolean = false;
  openedPage: number = 0;
  onPageChange(e): void {
    this.openedPage = e.offset;
    // this.currentPage = e.offset;
    this.isLoading = true;
    this.pagePayload.page = e.offset * this.pageSize;
    this.config.start = this.pagePayload.page;
    if (this.firstTimeLoad) {
      this.table.offset = this.openedPage;
      this.dataService.pagePreferancePost({
        pageSize: this.pageSize,
        currentPage: this.config.start,
        pageIndex: this.table.offset,
        user_id: JSON.parse(localStorage.getItem('user')).id,
        project_id: this.episodeObj.projectId
      }, 'season_list_page');
      this.getEpisodeList();
    } else {
      this.dataService.pagePreferanceGet('season_list_page').then((res: any) => {
        if (res.data.length == undefined) {
          this.pageSize = res.data.pageSize;
          this.config.itemsPerPage = res.data.pageSize;
          this.config.start = res.data.currentPage;
          this.table.offset = res.data.pageIndex;
          this.getEpisodeList();
        } else {
          this.getEpisodeList();
        }
      }, (err: any) => {
        console.warn("Error while fetching page set")
      });
    }
    this.firstTimeLoad = true;
    this.getEpisodeList();
  }


  /** Get Asset list */

  getEpisodeList() {
    this.spinner.hide();
    this.gridService.postData('episodesList', this.config).subscribe((res: any) => {
      if (!this.isLoading) {
        this.isLoading = true;
      }
      this.totalItems = res.totalElements;
      this.temp = [...res.data];
      this.rows = this.temp;
      this.statusArr = res.customStatusArr;
      this.config.export = false;
      this.isLoading = false;
      this.isFilterApplied = res.isFilterApplied;
      if (res.filterArr && res.filterArr.length > 0) {
        this.config.FilterByColumns.end_date = res.filterArr.FilterByColumns.end_date;
        this.config.FilterByColumns.name = res.filterArr.FilterByColumns.name;
        this.config.FilterByColumns.number = res.filterArr.FilterByColumns.number;
        this.config.FilterByColumns.projected_seconds = res.filterArr.FilterByColumns.projected_seconds;
        this.config.FilterByColumns.projected_shots = res.filterArr.FilterByColumns.projected_shots;
        this.config.FilterByColumns.season_id = res.filterArr.FilterByColumns.season_id;
        this.config.FilterByColumns.start_date = res.filterArr.FilterByColumns.start_date;
        this.config.FilterByColumns.status = res.filterArr.FilterByColumns.status;
        this.episodeObj.status = res.where.status;
      }
    });
  }

  /** Action Button */
  actionClick(type: string, row: any) {
    let id: any = row.id
    if (type == 'delete') {
      const config = {
        initialState: {
          title: 'Delete episode',
          message: "Are you sure you want to delete this episode(s)?",
        },
        class: 'modal-md custom-modal-css',
        centered: true,
        animated: false
      };
      this.bsModalRef = this.modalService.show(ConfirmationPopupComponent, config);
      // Subscribe to the onConfirmAction event
      this.bsModalRef.content.onConfirmAction.subscribe(() => {
        // Handle the confirmation logic here
        this.dataService.deleteData('episodes' + 'Destroy', { id })
          .subscribe((success) => {
            if (success.status == 'error') {
              this.toastrService.notify('error', '', success.message);
            } else {
              this.toastrService.notify('success', '', success.message);
              this.isLoading = true;
              this.getEpisodeList();
            }
          }, (rejected) => {

          });
      });

      // let that = this;
      // swal.fire({
      //   title: 'Delete  episode(s)',
      //   text: "Are you sure you want to delete this episode(s)?",
      //   icon: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#DD6B55",
      //   confirmButtonText: "Delete",
      //   cancelButtonText: "Cancel",
      // }).then((result) => {
      //   if (result.value) {
      //     that.dataService.deleteData('episodes' + 'Destroy', { id })
      //       .subscribe((success) => {
      //         if (success.status == 'error') {
      //           //that.toastrService.notify('error','', success.message);
      //         } else {
      //           this.isLoading = true;
      //           this.getEpisodeList();
      //         }
      //         that.toastrService.notify(success.status, '', success.message);

      //       }, (rejected) => {

      //       });
      //   } else {
      //     //console.log('cancelled');
      //   }
      // });
    } else {
      if (id != undefined && id != '') {
        this._ng_sidebar_data['episodeid'] = id;
      }
      this._ng_sidebar_data['season'] = true;
      this._ng_sidebar_data['seasonid'] = this.episodeObj.parentId;
      this._ng_sidebar_data['isActive'] = true;
      this._ng_sidebar_data['projectId'] = this.episodeObj.projectId;
      this._ng_sidebar_data['mode'] = 'edit';
      this._opened = true;
    }

  }

  /** Search Auto complete */
  search(event: any, type: string) {
    if (type == 'season_id') {
      this.dataService.getOtherData('seasonsItems', { 'project_id': this.episodeObj.projectId, 'query': event['query'] })
        .subscribe((success) => {
          this.episodeObj.season_id = success.data;
        }, (rejected) => {
        });

    }
  }

  /** On Select Option */
  onselectOption(event: any, type: string) {
    this.config.where[type] = [event];
    setTimeout(() => {
      this.isLoading = true;
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.config.start = 0;
      this.getEpisodeList();
    }, 200);
  }

  /** On clear selection */
  onselectionClear(event: any, type: string) {
    this.config.where[type] = event.isTrusted == true ? [] : event;
    setTimeout(() => {
      this.isLoading = true;
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.getEpisodeList();
    }, 200);
  }

  // Get project detail
  getProjectsById(id) {
    return new Promise<void>((resolve, reject) => {
      this.episodeObj.projectId = id;
      this.dataService.getDataById('projects/' + id + '/edit')
        .subscribe((success) => {
          this.isEpisodicSeries = success.data.episodic_series;
          resolve();
        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.toastrService.notify('warn', '', err.message);
            this.router.navigate(['/dashboard']);
          }
        });
    });
  }

  // Get column filter
  onColumnFilter(e: any, id: string): void {
    setTimeout(() => {
      this.isLoading = true;
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.config.start = 0;
      this.getEpisodeList();
    }, 200);
  }

  // Create Episode Sidebar open
  openCreateEpisode() {
    this._ng_sidebar_data['season'] = true;
    this._ng_sidebar_data['seasonid'] = this.episodeObj.parentId;
    this._ng_sidebar_data['projectId'] = this.episodeObj.projectId;
    this._ng_sidebar_data['isActive'] = true;
    this._ng_sidebar_data['mode'] = 'create';
    this._opened = true;
  }




  // On close sidebar function
  closeSideWindow() {
    this.isLoading = true;
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.config.isLoadingFirstTime = false;
    this.getEpisodeList();
  }

  // Export asset list
  exportData() {
    this.config.export = true;
    this.getEpisodeList();
  }

  selectedDatepicker(column, $event) {
    let startDateValue = moment($event.start).format('YYYY-MM-DD');
    let searchValue = '';
    let endDateValue = moment($event.end).format('YYYY-MM-DD');
    if (startDateValue != '' && startDateValue != null && startDateValue != 'Invalid date' && endDateValue != '' && endDateValue != null && endDateValue != 'Invalid date') {
      searchValue = startDateValue + '|' + endDateValue;
    }
    if (searchValue != '') {
      setTimeout(() => {
        this.config.where[column] = searchValue;
        this.pagePayload.page = 0;
        this.table.offset = 0;
        this.config.isLoadingFirstTime = false;
        this.config.start = 0;
        this.getEpisodeList();
      }, 2);
    }
  }

  cellClick(data: any) {
    if (this.commonJs.checkAccess('episodes', 'view', { 'baiPass': ['admin'] }, this.episodeObj.projectId)) {
      this.router.navigate([this.commonJs.getClientCodePrefix() + '/projects/sequences/' + this.episodeObj.projectId + '/' + data + '/' + this.episodeObj.parentId]);
    }
  }

  onOpenStart() { }

  onOpened(loadWindow = '') {
    if (loadWindow == 'sequence') {
      this.sequenceSideWindow = true;
    } else {
      this._loadSideWindow = true;
    }
  }

  onClosed(closeWindow = '') {
    if (closeWindow == 'sequence') {
      this.sequenceSideWindow = false;
    } else {
      this._loadSideWindow = false;
    }
  }

  closeCreateEpisode() {
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.getEpisodeList();
  }

  closeCreateSequence() {
    this.closeCreateEpisode();
  }

  /** Create Episode */
  createSequence(data: any) {
    this.sequence_opened = true;
    this._ng_sidebar_sequence_data['mode'] = 'create';
    this._ng_sidebar_sequence_data['projectId'] = this.episodeObj.projectId;
    this._ng_sidebar_sequence_data['episode'] = true;
    this._ng_sidebar_sequence_data['episode_id'] = data.id;
    this.sequence_opened = true;
  }


  showEpisodeDeptSummary(showSummary: TemplateRef<any>, row) {
    this.gridService.openProgressModal();
    let episodeId = row.id
    this.currentEpisode = row;
    let query = { query: '' };
    this.dataService.getListData('getEpisodesDeptWiseSummary', { 'project_id': this.episodeObj.projectId, episode_id: episodeId, 'phaseId': 0, type: 'episode', 'query': query['query'] })
      .subscribe((success) => {
        this.episodeSummary = success.data.deptCounts;
        this.episodeSummaryTotal = success.data.finalCounts;
        if (this.deptList.length <= 0) {
          this.deptList = success.data.depts;
        }
        this.bsModalRef = this.modalService.show(this.elementView, Object.assign({}, this.configModal, { class: 'episodes-dept-summary-modal' }));
        this.gridService.myEventEmitterForProgressModal.emit(true);
      }, (rejected) => {
        this.gridService.myEventEmitterForProgressModal.emit(true);
      });
    this.gridService.progressFunction();
    this.commonJs.initiateJS();
  }

  // projectDepartmentList() {
  //   return new Promise<void>((resolve, reject) => {
  //     this.dataService.getListData('projectDepartmentList', { 'project_id': this.episodeObj.projectId, 'type': 'episode', 'phaseId': 0, 'fromAssetShot': false, query: '' })
  //       .subscribe((success) => {
  //         this.deptList = success.data;
  //         resolve();
  //       }, (rejected) => {

  //       });
  //   });
  // }


  departMentList: any = [];
  projectDepartmentList() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getListData('projectDepartmentList', { 'project_id': this.episodeObj.projectId, 'type': 'episode', 'phaseId': this.episodeObj.phaseId, 'fromAssetShot': false, query: '' })
        .subscribe((success) => {
          this.departMentList = success.data;
          const modifiedArray = success.data.map(obj => ({
            prop: obj.department_name,
            task_dept_id: obj.department_id,
            name: obj.department_name,
            sortable: false,
            draggable: false,
            width: 150,
            mimWidth: 150,
            maxWidth: 150,
            canAutoResize: true,
            dynamicColumn: true,
            cellTemplate: this.departmentStatus
          }));
          this.episodeObj.modifiedArray = modifiedArray;
          this.createColumns(modifiedArray);
          resolve();
        }, (rejected) => {

        });
    });
  }

  // To change display view
  changeView(view) {
    if (view == 'list') {
      // this.isListView = true;
      // this.isThumbnailView = false;
      this.showDeptWiseSummary = false;
    } else if (view == 'thumbnail') {
      // this.isThumbnailView = true;
      // this.isListView = false;
      this.showDeptWiseSummary = false;
    } else {
      this.showDeptWiseSummary = true;
      // this.isThumbnailView = false;
      // this.isListView = false;
      this.projectDepartmentList();
    }
  }

  refreshFilter() {
    this.getEpisodeList();
  }

  clearFilter() {
    this.config.where = {};
    this.config.where.project_id = this.episodeObj.projectId;
    this.config.FilterByColumns = {};
    this.config.isLoadingFirstTime = false;
    this.config.isClearFilter = true;
    this.episodeObj.status = [];
    this.getEpisodeList();
  }

  /** Close modal */
  closeModal() {
    this.episodeSummaryTotal = [];
    this.bsModalRef.hide();
  }

  // On datatable body scrolled
  onScrolled(event){

    // console.log(event);
    if(event['offsetY'] && (event['offsetY'] != this.previousOffsetY)){
      this.isFullScreen = event['offsetY']>this.previousOffsetY ? true : false;
      this.dataService.fullScreenView.next(this.isFullScreen);

      this.previousOffsetY = event['offsetY'];
    }
  }

}
