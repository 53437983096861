import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
import * as moment from 'moment';
import * as _ from "lodash";
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
declare var $: any;

@Component({
	selector: 'create-season',
	templateUrl: './create-season.component.html',
	styleUrls: ['./create-season.component.css']
})
export class CreateSeasonComponent implements OnInit {
	entityName = 'Seasons';
	entityNameSingular = 'Season';
	apiRoutePrefix = 'seasons';
	dirName = 'seasons';
	apiRoutePrefixListItem = 'listItem';

	@Input('attr.data') dataHtml: any;
	@ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
	@ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
	@Output() closeCreateSeason = new EventEmitter();

	data: any;
	myform: any;
	onClose: any;
	seasons: { [index: string]: any } = {};

	errors: { [index: string]: any } = {};
	focused: string = '';
	SaveButtonText: string = 'SAVE';
	isMessgaeMode: any;
	settingsAll: any = JSON.parse(localStorage.getItem('settings'));
	projectId = '';//this.commonJs.getLocalStorage('projectId');
	isDeleteMode: boolean = false;
	deleteId: number;
	deleteName: string = '';
	isDisplyFrm: boolean = false;
	alertMessage: string = '';
	DeleteButtonText: string = 'DELETE';
	DateFormat: any;

	public endDate = {
		singleDatePicker: true,
		autoUpdateInput: true,
		showDropdowns: true,
		autoApply: true,
		drops: 'down',
		locale: {
			format: 'DD MMM YYYY'
		}
	}

	public startDate = {
		singleDatePicker: true,
		autoUpdateInput: true,
		showDropdowns: true,
		autoApply: true,
		drops: 'down',
		"maxDate": "31 Dec 2030",
		locale: {
			format: 'DD MMM YYYY'
		},
		//startDate:moment().format('YYYY-MM-DD'),
	}

	url: any;
	thumbnail_preview_src: string = '';
	thumbnail: string;
	thumbnail_name: string;
	thumbnail_type: string;
	thumbnail_old: string;
	status = [{ "id": 1, "name": "Active" }, { "id": 0, "name": "Inactive" }];
	uploadUrl = this.commonJs.getUploadUrl();

	isSaveDisabled: boolean = false;
	public files: NgxFileDropEntry[] = [];

	constructor(private dataService: DataService, private toastrService: ToastrService, public commonJs: CommonJs, public appConfigs: AppConfigs) { }

	ngOnInit() {
		if (this.dataHtml != undefined) {
			this.data = this.dataHtml;
		}
		this.projectId = this.data.projectId;
		$.AdminBSB.input.activate();
		this.subngOnInit();
		this.DateFormat = this.settingsAll.date_format;
		this.startDate.locale.format = this.DateFormat;
		this.endDate.locale.format = this.DateFormat;

	}

	subngOnInit() {
		this.seasons = {
			project_id: this.projectId,
			name: '',
			number: '',
			description: '',
			status: 1,
			complexity: '',
			projected_episodes: '',
			projected_minutes: '',
			thumbnail: '',
			thumbnail_name: '',
			thumbnail_type: '',
			thumbnail_old: '',
			thumbnail_display_name: '',
		};

		if (this.data.mode == 'Create') {
			this.seasons.start_date = moment().format(this.settingsAll.date_format);
			this.dataService.getListData('getLastRecordProject', { 'table': this.apiRoutePrefix, 'project_id': this.projectId })
				.subscribe((success) => {
					if (success.data != null) {
						this.seasons.number = this.appConfigs.autoincrementStr(success.data.number);
					} else {
						this.seasons.number = "season1";
					}
				}, (rejected) => {

				});
		}
		if (this.data.seasonid && (this.data.mode == 'edit' || this.data.mode == 'view')) {
			this.focused = "focused";
			this.dataService.getDataById(this.apiRoutePrefix + '/' + this.data.seasonid + '/edit')
				.subscribe((success) => {
					this.seasons = success.data;

					if (this.seasons.start_date) {
						this.startDate['startDate'] = moment(this.seasons.start_date).format(this.DateFormat);
						this.seasons.start_date = moment(this.seasons.start_date).format(this.DateFormat);
					}
					if (this.seasons.end_date) {
						this.endDate['startDate'] = moment(this.seasons.end_date).format(this.DateFormat);
						this.seasons.end_date = moment(this.seasons.end_date).format(this.DateFormat);
					}

					if (this.seasons.thumbnail) {
						this.thumbnail_old = this.seasons.thumbnail;
						let fileExtension = this.seasons.thumbnail.split('.').pop();
						if (fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg' && fileExtension != 'gif') {
							this.thumbnail_preview_src = './assets/images/default/attechment.png';
						} else {
							this.thumbnail_preview_src = this.uploadUrl + this.dirName + '/' + this.seasons.thumbnail;
						}
					}
					if (this.seasons.thumbnail_display_name) {
						this.thumbnail_name = this.seasons.thumbnail_display_name;
					}

				}, (rejected) => {

					this.SaveButtonText = "SAVE";
				});
		}
	}

	onSubmit(form: NgForm) {
		this.isSaveDisabled = true;
		form.value.project_id = this.projectId;
		if (this.url != undefined) {
			form.value.thumbnail = this.url;
		}
		form.value.thumbnail_name = this.thumbnail_name;
		form.value.thumbnail_old = this.thumbnail_old;
		// if(form.value.status){
		// 		 form.value.status= form.value.status.id;
		// 		 //form.value.status= this.seasons.status.id;
		// }
		if ($('#start_date').val() != '' && $('#start_date').val() != undefined) {
			form.value.start_date = moment($('#start_date').val()).format('YYYY-MM-DD');
		} else {
			form.value.start_date = null;
		}
		if ($('#end_date').val() != '' && $('#end_date').val() != undefined) {
			form.value.end_date = moment($('#end_date').val()).format('YYYY-MM-DD');
		} else {
			form.value.end_date = null;
		}
		this.SaveButtonText = 'SAVING';
		if (this.data.seasonid && this.data.mode == 'edit') {
			form.value.id = this.data.seasonid;
			this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.seasonid, form.value)
				.subscribe((success) => {

					// Save effect within button
					this.SaveButtonText = 'SAVED';
					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.isSaveDisabled = false;

				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						this.SaveButtonText = "SAVE";
						this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
					}
					this.isSaveDisabled = false;
				});
		} else {
			this.dataService.storeData(this.apiRoutePrefix, form.value)
				.subscribe((success) => {
					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.isSaveDisabled = false;
				}, (error) => {
					//this.alertMessage = 'Something went wrong, Please try again!';
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						this.SaveButtonText = "SAVE";
						this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
					}
					this.isSaveDisabled = false;
				});
		}
	}

	close() {
		this.closeCreateSeason.emit();
	}

	public dropped(files: NgxFileDropEntry[]) {
		this.files = files;
		for (const droppedFile of files) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {

					// Here you can access the real file
					//console.log(droppedFile.relativePath, file);

					var reader = new FileReader();
					this.thumbnail_name = file.name;
					this.thumbnail_type = file.type;

					reader.onload = (event: any) => {
						this.url = event.target.result;
						if (this.thumbnail_type != 'image/png' && this.thumbnail_type != 'image/jpg' && this.thumbnail_type != 'image/jpeg' && this.thumbnail_type != 'image/gif') {
							this.thumbnail_preview_src = './assets/images/default/attechment.png';
						} else {
							this.thumbnail_preview_src = this.url;
						}
					}
					reader.readAsDataURL(file);
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
				//console.log(droppedFile.relativePath, fileEntry);
			}
		}
	}

	public fileOver(event) {
		//console.log(event);
	}

	public fileLeave(event) {
		//console.log(event);
	}

	unlinkImage(event) {
		event.stopPropagation();
		this.thumbnail_preview_src = '';
		this.thumbnail = '';
		this.thumbnail_name = 'unset';
		this.url = '';
	}
}
