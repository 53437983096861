import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { Subscription } from 'rxjs';
import { CommonJs } from '../../../common/common-js';
//import {NgProgressService} from 'ngx-progressbar';
import { AppConfigs } from '../../../common/app-configs';
declare var $: any;
import * as _ from "lodash";

@Component({
  selector: 'app-custom-status',
  templateUrl: './custom-status.component.html',
  styleUrls: ['./custom-status.component.css']
})
export class CustomStatusComponent implements OnInit {
  entityName = 'Custom Status';

  statusArr = [];

  busy: Subscription;

  constructor(private titleService: Title, private dataService: DataService,
    private toastrService: ToastrService,
    //
    public commonJs: CommonJs,
    public appConfigs: AppConfigs,) { }

  ngOnInit() {
    this.titleService.setTitle(this.entityName);
    let that = this;
    this.getStatusList();
  }

  getStatusList() {
    // this.busy = this.dataService.getOtherData('getStatusList', {})
    //   .subscribe((success) => {
    this.statusArr = [...this.dataService.allStatusList]
    // });
  }

  cellClick(data: any, column) {
    $('.cursor-pointer').removeClass('active');
    $('.' + column + '-' + data + '-td').addClass('active');
    $('.' + column + '-' + data + '-input input').focus();
    $('#' + column + '-' + data + '-editor-val').val(data[column]);
  }

  cellUpdate(data, column, event, isMain = false, isTypeAhead = false) {
    data['column'] = column;
    if (column == 'studio_status' || column == 'studio_short_status') {
      this.dataService.storeData('updateStatusList', data)
        .subscribe((success) => {
          if (success.data.length > 0) {
            this.statusArr = success.data;
          }
          this.toastrService.notify(success.status, '', success.message);
        }, (error) => {

        });
    }
    $('.' + column + '-' + data.id + '-td').removeClass('active');
    this.getStatusList();
  }



}
