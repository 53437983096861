import { Component, OnInit, Output, EventEmitter, ElementRef, AfterViewChecked, ViewChild, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonJs } from '../../../common/common-js';
//  import { DragulaService } from 'ng2-dragula';
import swal from 'sweetalert2';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
//  import { NgProgressService } from 'ngx-progressbar';
import { AppConfigs } from '../../../common/app-configs';

import { ToastrService } from '../../../services/toastr.service';

declare var $: any;
import * as _ from "lodash";
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'phases',
  templateUrl: './phases.component.html',
  styleUrls: ['./phases.component.css']
})
export class PhasesComponent implements OnInit {

  @ViewChild('hidePhase', { static: false }) hidePhase: ElementRef;
  @ViewChild('hideDept', { static: false }) hideDept: ElementRef;


  @Output() changePage = new EventEmitter();
  taskTypeInput: any;

  depatmentName = 'Depatments';
  depatmentNameSingular = 'Depatment';
  apiRoutePrefix = 'depatment';

  phaseName = 'Phases';
  phaseNameSingular = 'Phase';
  apiPhaseRoutePrefix = 'phase';

  apiTemplateRoutePrefix = 'deptTemplate';
  apiTasktypeRoutePrefix = 'tasktype';

  bsModalRef: BsModalRef;
  errors: { [index: string]: any } = {};
  edit_errors: { [index: string]: any } = {};
  SaveButtonTextDept: string = 'SAVE';
  SaveButtonTextPhase: string = 'SAVE';
  templateId: number = 0;

  templates: Templates;
  phase: Phase;
  department: Department;
  depatmentPhaseList: any;
  skeleton: boolean = true;

  displayDeptTask: boolean = false;
  deptTaskList = [
    { id: "1", name: "Task 1" },
    { id: "2", name: "Task 2" },
    { id: "3", name: "Task 3" },
  ];

  isDeleteMode: boolean = false;
  deleteModeData = [];

  moreToggleFlag: { [index: string]: boolean } = {};

  typeOptions = [
    { id: "pre", text: "Pre" },
    { id: "asset", text: "Asset" },
    { id: "shot", text: "Shot" },
    { id: "post", text: "Post" },
  ];

  assetOptions = this.appConfigs.assets_subtype;
  shotOptions = this.appConfigs.shot_subtype;

  settingsAll: any = JSON.parse(localStorage.getItem('settings'));
  loginRole = localStorage.getItem('role');

  dragulaOptions: any = {
    moves: function (el, container, handle) {
      return handle.className === 'dragula-handle material-icons';
    }
  }
  subs: Subscription[] = [];

  isPhaseSubmitted: boolean = false;
  isTaskTypeError: boolean = false;
  constructor(
    private dragulaService: DragulaService,
    private modalService: BsModalService,
    public commonJs: CommonJs,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    public appConfigs: AppConfigs,
    private dataService: DataService,

    private toastrService: ToastrService) {

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params['template_id'] != undefined) {
        this.templateId = params['template_id'];
      }
    });

    this.dragulaService.createGroup('another-bag', {
      moves: (el, container, handle) => handle.className === ''
    });

  }

  ngOnInit() {
    this.subs.push(
      this.dragulaService.drop().subscribe(({ name, el, source, target, sibling }) => {
        // Find the source department and its phase
        const sourceDepartmentId = +(<HTMLElement>el).getAttribute('data-id');
        const sourcePhase = this.depatmentPhaseList.find(phase => phase.department.some(dept => dept.id === sourceDepartmentId));

        // Find the target department and its phase
        const targetDepartmentId = +(<HTMLElement>sibling).getAttribute('data-id');
        const targetPhase = this.depatmentPhaseList.find(phase => phase.department.some(dept => dept.id === targetDepartmentId));

        if (sourcePhase && targetPhase) {
          // Find the source and target department indices
          const sourceIndex = sourcePhase.department.findIndex(dept => dept.id === sourceDepartmentId);
          const targetIndex = targetPhase.department.findIndex(dept => dept.id === targetDepartmentId);

          // Move the department from the source to the target phase
          const movedDepartment = sourcePhase.department.splice(sourceIndex, 1)[0];
          targetPhase.department.splice(targetIndex, 0, movedDepartment);

          this.dataService.getListData(this.apiRoutePrefix + 'Order', { 'depatmentPhaseList': this.depatmentPhaseList })
            .subscribe((success) => {
              // this.toastrService.notify('success', '', success.msg ? success.msg : 'success');
            }, (rejected) => {
              this.toastrService.notify('error', '', rejected.msg ? rejected.msg : 'Something went wrong');
              console.log(rejected)
            });
        }
      })
    );
    //const elem = document.querySelectorAll('.scrollLast');
    //elem.scrollIntoView();
    this.templates = {
      name: '',
      is_default: 1,
      status: 1
    }

    this.phase = {
      name: '',
      type: '',
      subtype: '',
      dept_template_id: this.templateId,
      is_production: 0
    }

    this.department = {
      name: '',
      phase_id: 0,
      is_default: 0,
      dept_template_id: this.templateId
    }

    // this.dataService.getDataById(this.apiTemplateRoutePrefix + '/' + this.templateId + '/edit')
    //   .subscribe((success) => {
    //     this.templates = success.data;
    //   }, (rejected) => {

    //   });

    this.getDepatmentPhaseList();

    // this.dataService.getListData('listItemList/ac_assets_subtype', '')
    //   .subscribe((success) => {
    //     this.assetOptions = success.data;
    //   }, (rejected) => {

    //   });

    // this.dataService.getListData('listItemList/ac_shots_subtype', '')
    //   .subscribe((success) => {
    //     this.shotOptions = success.data;
    //   }, (rejected) => {

    //   });

    this.titleService.setTitle(this.phaseName);
  }

  // ngAfterViewChecked(){
  // }



  ngOnDestroy() {
    this.dragulaService.destroy('another-bag');
  }

  deleteDepartment(name, id) {
    if (name && id) {
      this.dataService.deleteData(this.apiRoutePrefix + 'Destroy', { id })
        .subscribe((success) => {

          if (success.status == "success") {
            this.getDepatmentPhaseList();
          }

          this.toastrService.notify(success.status, '', success.message);

        }, (rejected) => {

        });
    } else {
      //console.log('cancelled');
    }
    this.bsModalRef.hide();
    this.isDeleteMode = false;
  }

  deletePhase(name, id) {
    let that = this;
    swal.fire({
      title: "Delete Phase",
      html: "Are you sure you want to delete <span style='font-weight:600'>" + name + "</span> phase?",
      // icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        that.dataService.deleteData(that.apiPhaseRoutePrefix + 'Destroy', { id })
          .subscribe((success) => {
            if (success.status == 'error') {
              that.toastrService.notify('error', '', success.message);
            } else {
              that.getDepatmentPhaseList();
            }
          }, (error) => {
            //console.log(error);
          });
      } else {
        //console.log('cancelled');
      }
    }
    )
  }

  routerLink(path) {
    this.router.navigate(['/' + this.commonJs.getClientCodePrefix() + '/phases/dependency/' + path]);
  }

  onShownPhase(phaseData) {
    /*this.phase = {
      name : phase.name,
      type : phase.type
    }*/
    if (phaseData != undefined && phaseData != '') {
      this.phase['id'] = phaseData.id;
      this.phase['name'] = phaseData.name;
      this.phase['type'] = { id: phaseData.type, text: phaseData.type };
      this.phase['subtype'] = { id: phaseData.subtype, name: phaseData.subtype };
      this.phase['is_default'] = phaseData.is_default;
      this.phase['is_production'] = phaseData.is_production;
    } else {
      this.phase['id'] = '';
      this.phase['name'] = '';
      this.phase['type'] = '';
      this.phase['subtype'] = '';
      this.phase['is_default'] = 0;
      this.phase['is_production'] = 0;
    }
    this.errors = {};
  }

  onSubmit(form: NgForm) {
    this.isPhaseSubmitted = true;
    this.SaveButtonTextPhase = 'SAVING';

    if (form.value.subtype) {
      form.value.subtype = this.phase.subtype.id;
    }

    if (form.value.type) {
      form.value.type = this.phase.type.id;
      if (this.phase['type']['id'] == 'shot') {
        form.value.subtype = 'Shot';
      }
    }

    if (this.phase['type']['id'] == 'shot' || this.phase['type']['id'] == 'asset') {
      form.value.is_production = 1;
    }

    if (this.phase['id'] != '') {

      this.dataService.updateData(this.apiPhaseRoutePrefix + '/' + this.phase['id'], form.value)
        .subscribe((success) => {
          // Save effect within button
          setTimeout(() => {
            this.SaveButtonTextPhase = 'SAVE';
            this.getDepatmentPhaseList();
          }, 2);

          setTimeout(() => {
            this.isPhaseSubmitted = false;
          }, 2000);

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.errors = err.error;

          }
          this.SaveButtonTextPhase = 'SAVE';
          this.isPhaseSubmitted = false;
        });

    } else {

      this.dataService.storeData(this.apiPhaseRoutePrefix, form.value)
        .subscribe((success) => {
          // Save effect within button
          setTimeout(() => {
            this.SaveButtonTextPhase = 'SAVE';
            this.getDepatmentPhaseList();
            this.hidePhase.nativeElement.click();
          }, 2);

          setTimeout(() => {
            this.isPhaseSubmitted = false;
          }, 2000);

        }, (error) => {
          //this.alertMessage = 'Something went wrong, Please try again!';
          if (error.error) {
            var err = error.error;
            this.errors = err.error;

          }
          this.SaveButtonTextPhase = 'SAVE';
          this.isPhaseSubmitted = false;
        });
    }
  }

  onShownDept(phase_id, depart_id, depart_name, is_default = 0) {
    if (depart_id != undefined && depart_id != '') {
      this.department['id'] = depart_id;
      this.department['name'] = depart_name;
      this.getTasktypeList(depart_id);
    } else {
      this.department['id'] = '';
      this.department['name'] = '';
    }
    this.department.phase_id = phase_id;
    this.department.is_default = is_default;

    this.errors = {};
  }

  onSubmitDept(form: NgForm) {
    this.SaveButtonTextDept = 'SAVING';

    if (this.department['id'] != '') {

      this.dataService.updateData(this.apiRoutePrefix + '/' + this.department['id'], this.department)
        .subscribe((success) => {
          // Save effect within button
          setTimeout(() => {
            this.SaveButtonTextDept = 'SAVE';
            this.getDepatmentPhaseList();
          }, 2);

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.errors = err.error;

          }
          this.SaveButtonTextDept = 'SAVE';
        });

    } else {

      this.dataService.storeData(this.apiRoutePrefix, this.department)
        .subscribe((success) => {
          // Save effect within button
          setTimeout(() => {
            this.SaveButtonTextDept = 'SAVE';
            this.getDepatmentPhaseList();
            //this.hideDept.nativeElement.click();
          }, 2);

        }, (error) => {
          //this.alertMessage = 'Something went wrong, Please try again!';
          if (error.error) {
            var err = error.error;
            this.errors = err.error;
            this.toastrService.notify(err.status, '', err.message);

          }
          this.SaveButtonTextDept = 'SAVE';
        });
    }
    this.bsModalRef.hide();

  }

  getDepatmentPhaseList() {
    //$('.collapse-btn').html('More');
    this.dataService.getListData(this.apiRoutePrefix + 'List', { 'template_id': this.templateId })
      .subscribe((success) => {
        this.depatmentPhaseList = success.data;
        this.skeleton = false;
        /*let that = this;
        _.forEach(this.depatmentPhaseList, function(item) {
            if ( item['type'] == 'asset' ) {

                const index = that.assetOptions.findIndex((col) => {
                  return col.id == item['subtype'];
                });
                if (index !== -1) {
                  that.assetOptions.splice(index, 1);
                }
            } else if (item['type'] == 'shot') {

                const index = that.shotOptions.findIndex((col) => {
                  return col.id == item['subtype'];
                });
                if (index !== -1) {
                  that.shotOptions.splice(index, 1);
                }
            }
        });*/
        this.moreToggleFlag = {};
        //assetOptions

      }, (rejected) => {
        this.skeleton = false;
      });
  }

  onChange(flag) {
    var tempCheck = [];
    if (flag) {
      this.phase['is_production'] = 1;
    } else {
      this.phase['is_production'] = 0;
      this.phase['type'] = '';
      this.phase['subtype'] = '';
    }
  }

  moreToggle(pi) {
    this.moreToggleFlag[pi] = !this.moreToggleFlag[pi];
    if (this.moreToggleFlag[pi]) {
      $('.other-dept.other-dept-' + pi).show();
    } else {
      $('.other-dept.other-dept-' + pi).hide();
    }
  }

  cellClick(id): any { //onCellClick
    if (id != undefined) {
      $('.cursor-pointer').removeClass('active');
      $('.' + id + '-td').addClass('active');
      $('.' + id + '-input input').focus();
    }
  }

  cellUpdate(data: any = {}, isNew = false, depId = 0): any {
    //this.isTaskTypeError = false;
    this.errors = {};
    this.edit_errors = {};
    if (isNew) {
      if (data.target.value == "") {
        //this.edit_errors.name = "Required";
        return false;
      }
      //this.department['tasks'].push({id: (Object.keys(this.department['tasks']).length + 1), name: data.target.value});
      this.dataService.storeData(this.apiTasktypeRoutePrefix, { department_id: depId, name: data.target.value })
        .subscribe((success) => {
          setTimeout(() => {
            this.getTasktypeList(depId);

          }, 2);

        }, (error) => {
          //this.alertMessage = 'Something went wrong, Please try again!';
          if (error.error) {
            var err = error.error;
            this.errors = err.error;

            //this.isTaskTypeError = true;
          }
        });

    } else {
      if (data.name == "") {
        //this.edit_errors.name = "Required";
        return false;
      }
      this.dataService.updateData(this.apiTasktypeRoutePrefix + '/' + data.id, data)
        .subscribe((success) => {

          $('.' + data.id + '-td').removeClass('active');
          setTimeout(() => {
            this.getTasktypeList(depId);
          }, 2);

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.edit_errors[data.id] = err.error;

            this.isTaskTypeError = true;
          }
        });
    }
    this.getDepatmentPhaseList();
  }

  deleteTask(id, name, depId) {
    this.dataService.deleteData(this.apiTasktypeRoutePrefix + 'Destroy', { id })
      .subscribe((success) => {
        this.isDeleteMode = false;
        if (success.status == 'error') {
          this.toastrService.notify('error', '', success.message);
        } else {
          this.getTasktypeList(depId);
        }
      }, (rejected) => {

      });
  }

  getTasktypeList(depId) {
    this.dataService.getListData(this.apiTasktypeRoutePrefix + 'List', { 'department_id': depId })
      .subscribe((success) => {
        this.department['tasks'] = success.data;
        if (Object.keys(this.department['tasks']).length > 0) {
          //$('.'+depId+'-tasktypeCount').show();
          //$('.'+depId+'-tasktypeCount').html('('+Object.keys(this.department['tasks']).length+')');
        } else {
          //$('.'+depId+'-tasktypeCount').hide();
        }
      }, (rejected) => {

      });
  }

  deleteModeTogg(task, taskIndex) {
    // this.isDeleteMode = true;
    this.deleteModeData = task;
    // this.department['tasks'].splice(taskIndex,1);
    // console.log(this.deleteModeData['tasktype'].total_tasktype);
    this.deleteTask(this.deleteModeData['id'], this.deleteModeData['name'], this.department['id']);
    this.getDepatmentPhaseList();

  }

  search(query, column) {

    if (column == 'type') {
      this.typeOptions = this.typeOptions.map(o => {
        return o;
      });

    } else if (column == 'asset-subtype') {
      this.assetOptions = this.assetOptions.map(o => {
        return o;
      });

    } else if (column == 'shot-subtype') {
      this.shotOptions = this.shotOptions.map(o => {
        return o;
      });

    }
  }

  // To open modal for edit department detail
  openDepartmentModal(template: TemplateRef<any>, actionType, deleteData?) {

    switch (actionType) {
      case 'editDept':
        this.displayDeptTask = this.isDeleteMode = false;
        break;
      case 'editSubtask':
        this.displayDeptTask = true;
        this.isDeleteMode = false;
        break;
      case 'deleteDept':
        this.displayDeptTask = false;
        this.deleteModeData = deleteData;
        this.isDeleteMode = true;
        break;
      default:
        this.displayDeptTask = false;
        this.isDeleteMode = false;
        break;
    }
    // this.isDeleteMode = false;
    // this.displayDeptTask = actionType=='editDept'?false:true;
    this.bsModalRef = this.modalService.show(template);
  }

  // Get Unassigned tasks
  onButtonToggled(value: any, phaseValue:any) {
    this.dataService.getListData(this.apiRoutePrefix + 'Order', { 'depatmentPhaseList': this.depatmentPhaseList })
    .subscribe((success) => {

      if(success.status=='denied'){

        this.depatmentPhaseList.map((phase)=>{
          if(phase.name == phaseValue.name){
            phase.isHidden = 0;
          }
         });
         this.toastrService.notify('error', '', success.message ? success.message : 'error');
      }else{
        // console.log(this.depatmentPhaseList)
        this.dataService.pagePreferancePost({
          departmentPhaseList: this.depatmentPhaseList.map(obj => ({ id: obj.id, name: obj.name, isHidden: obj.isHidden, type: obj.type , subtype: obj.subtype}))
        }, 'phasesExistence');

        let phasesExist = this.depatmentPhaseList.reduce((obj, item) => {
          if (item.name!="SUPPORT" && (item.isHidden == 0 || item.isHidden == false)) {

            if(item.subtype==null || item.subtype==''){
              obj[item.type.toLowerCase()] = item.isHidden ? 0 : 1;
            }else{
              obj[item.subtype.toLowerCase()] = item.isHidden ? 0 : 1;
            }
          }
          return obj;
        }, {});

        // console.log(this.commonJs.phasesExist);console.log(phasesExist)
        for(let ps of ['pre', 'character', 'set', 'prop', 'shot', 'post']){
          if(!phasesExist.hasOwnProperty(ps)){
            phasesExist[ps] = 0;
          }
        }

        // console.log(phasesExist);
        localStorage.setItem('phasesExist',JSON.stringify(phasesExist));
        // localStorage.setItem('phasesExist',JSON.stringify(this.commonJs.phasesExist));

        this.toastrService.notify('success', '', success.message ? success.message : 'success');

      }
    }, (rejected) => {
      console.log(rejected)
    });
  }

}

interface Templates {
  name: string,
  is_default: number,
  status: number
}

interface Phase {
  name: string,
  type: any,
  subtype: any,
  dept_template_id: number,
  is_production: number
}

interface Department {
  name: string,
  phase_id: number,
  is_default: number,
  dept_template_id: number
}
