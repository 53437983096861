import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
import * as moment from 'moment';
import * as _ from "lodash";
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
declare var $: any;
//  import { NgProgressService } from 'ngx-progressbar';
@Component({
	selector: 'create-projects',
	templateUrl: './create-projects.component.html',
	styleUrls: ['./create-projects.component.css']
})
export class CreateProjectsComponent implements OnInit {

	entityName = 'Projects';
	entityNameSingular = 'Project';
	apiRoutePrefix = 'projects';
	projectDetailsRoute = 'project_details';
	dirName = 'projects';
	apiRoutePrefixListItem = 'listItem';
	phaseDepartmentroute = 'depatment';
	@Input('attr.data') dataHtml: any;
	@ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
	@ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
	@Output() closeCreateProject = new EventEmitter();

	data: any;
	myform: any;
	onClose: any;
	projects: { [index: string]: any } = {};
	projectDetails: ProjectDetails;

	errors: { [index: string]: any } = {};
	focused: string = '';
	SaveButtonText: string = 'SAVE';
	isMessgaeMode: any;
	settingsAll: any = JSON.parse(localStorage.getItem('settings'));

	client: Array<any>;
	user: Array<any>;
	projectUsers: Array<any>;
	isDeleteMode: boolean = false;
	hideUsers: boolean = false;
	deleteId: number;
	deleteName: string = '';
	isDisplyFrm: boolean = false;
	alertMessage: string = '';
	DeleteButtonText: string = 'DELETE';
	DateFormat: any;
	public endDate = {
		singleDatePicker: true,
		autoUpdateInput: true,
		showDropdowns: true,
		autoApply: true,
		drops: 'down',
		locale: {
			format: 'DD MMM YYYY'
		}
	}

	public startDate = {
		singleDatePicker: true,
		autoUpdateInput: true,
		showDropdowns: true,
		autoApply: true,
		drops: 'down',
		"maxDate": "31 Dec 2030",
		locale: {
			format: 'DD MMM YYYY'
		},
		//startDate:moment().format('YYYY-MM-DD'),
	}

	url: any;
	thumbnail_preview_src: string = '';
	thumbnail: string;
	thumbnail_name: string;
	thumbnail_type: string;
	thumbnail_old: string;
	public files: NgxFileDropEntry[] = [];

	country: Array<any> = this.appConfigs.prj_country;
	/*output_resolution:Array<any> = [];*/
	//client:Array<any>;
	//status:Array<any>=[{"id":1,"name":"Active"},{"id":2,"name":"On Hold"},{"id":4,"name":"Closed"},{"id":3,"name":"Cancelled"}];
	status: Array<any> = [];
	category: Array<any> = [];
	project_type: Array<any> = [];
	output_resolution: Array<any> = [];

	phaseDepartments: Array<any>;
	uploadUrl = this.commonJs.getUploadUrl();
  selectedLengthType: {name:string} = { name:'Minutes' };
  lengthTypes:Array<any> = [ { name:'Minutes' }, { name:'Seconds' } ];

  constructor(private dataService: DataService, private toastrService: ToastrService, public commonJs: CommonJs, public appConfigs: AppConfigs) { }

	ngOnInit() {
		if (this.dataHtml != undefined) {
			this.data = this.dataHtml;
		}
		this.DateFormat = this.settingsAll.date_format;
		this.startDate.locale.format = this.DateFormat;
		this.projects = {
			name: '',
			project_code: '',
			fps: 24,
			episodic_series: 0,
			thumbnail: '',
			thumbnail_name: '',
			thumbnail_type: '',
			thumbnail_old: '',
			thumbnail_display_name: '',
			client_id: '',
			country: '',
			length: 0,
			user: 0,
			bid_days: '',
			description: '',
			category: '',
			status: []
		}
		$.AdminBSB.input.activate();
		this.errors = [];
		this.subngOnInit();
	}

	subngOnInit() {
		this.projects = {
			name: '',
			project_code: '',
			fps: 24,
			episodic_series: 0,
			thumbnail: '',
			thumbnail_name: '',
			thumbnail_type: '',
			thumbnail_old: '',
			thumbnail_display_name: '',
			client_id: '',
			country: '',
			length: 0,
			user: 0,
			bid_days: '',
			scheduled_days: '',
			description: '',
			type: '',
			status: []
		}
		this.projects.status = { "id": "In Bidding", "name": "In Bidding" };
		if (this.data.mode == 'create') {
			this.projects.start_date = moment().format(this.DateFormat);
			//this.projects.end_date = moment().format(this.DateFormat);
		}
		if (this.data.projectid && (this.data.mode == 'edit' || this.data.mode == 'view')) {
			//this.getProjectUsers();
			this.getPhaseDepartment();
			this.focused = "focused";
			this.dataService.getDataById(this.apiRoutePrefix + '/' + this.data.projectid + '/edit')
				.subscribe((success) => {
					this.projects = success.data;
					// if( this.projects.start_date ) {
					//     this.projects.start_date = moment(this.projects.start_date).format(this.DateFormat);
					// }
					if (this.projects.category != "" && this.projects.category != null) {
						this.projects.category = this.projects.category;
					} else {
						this.projects.category = [];
					}
					if (this.projects.start_date) {
						this.startDate['startDate'] = moment(this.projects.start_date).format(this.DateFormat);
						this.projects.start_date = moment(this.projects.start_date).format(this.DateFormat);
					}
					if (this.projects.end_date) {
						this.endDate['startDate'] = moment(this.projects.end_date).format(this.DateFormat);
						this.projects.end_date = moment(this.projects.end_date).format(this.DateFormat);
					}

					if (this.projects.length) {
            if(this.projects.length.includes('|')){
              this.selectedLengthType = this.projects.length.split('|')[1]== 1 ? { name:'Minutes' } : { name:'Seconds' };
            } else {
              this.selectedLengthType = { name:'Minutes' };
            }

						this.projects.length = this.projects.length.includes('|') ? this.projects.length.split('|')[0] : this.projects.length;
					}
					// if(this.projects.status){
					//  let st = this.appConfigs.prj_status.findIndex((v) => {
					//        return v.id == this.projects.status;
					//      });
					//  this.projects.status = this.appConfigs.prj_status[st];
					// }
					if (this.projects.thumbnail) {
						this.thumbnail_old = this.projects.thumbnail;
						let fileExtension = this.projects.thumbnail.split('.').pop();
						if (fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg' && fileExtension != 'gif') {
							this.thumbnail_preview_src = './assets/images/default/attechment.png';
						} else {
							this.thumbnail_preview_src = this.uploadUrl + this.dirName + '/' + this.projects.thumbnail;
						}
					}
					if (this.projects.thumbnail_display_name) {
						this.thumbnail_name = this.projects.thumbnail_display_name;
					}
				}, (rejected) => {

				});


		}
	}

	search(query, column) {
		if (column == 'client') {
			this.dataService.getListData('getClients', '')
				.subscribe((success) => {
          if(typeof this.projects.client_id == 'string'){
            this.client = success.data.filter(ele=> ele.name.toLowerCase().includes(this.projects.client_id.toLowerCase()));
          }else{
            this.client = success.data;
          }
				}, (rejected) => {

				});
		} else if (column == 'country') {
			this.country = this.appConfigs.prj_country.map(v => { return v });
		} else if (column == 'status') {
			this.status = this.appConfigs.prj_status.map(v => { return v });
		} else if (column == 'category') {
			this.category = this.appConfigs.prj_category.map(v => { return v });
		} else if (column == 'project_type') {
			this.dataService.getOtherData('searchItemList', { list_name: 'prj_type', 'query': query['query'] })
				.subscribe((success) => {
					this.project_type = success.data;
				}, (rejected) => {

				});
		} else if (column == 'output_resolution') {

			this.dataService.getOtherData('searchItemList', { list_name: 'output_resolution', 'query': query['query'] })
				.subscribe((success) => {
					this.output_resolution = success.data;
				}, (rejected) => {

				});

			// this.output_resolution = this.appConfigs.prj_output_resolution.map(v => { return v });
		} else if (column == 'lengthType') {
      this.lengthTypes = [...this.lengthTypes];
    }
	}

	onSubmit(form: NgForm) {
		//console.log(form.value);
		if (this.url != undefined) {
			form.value.thumbnail = this.url;
		}
		form.value.thumbnail_name = this.thumbnail_name;
		form.value.thumbnail_old = this.thumbnail_old;

		if (form.value.country && this.projects.country.id != undefined) {
			form.value.country = this.projects.country.id;
		} else if (!form.value.country) {
		} else {
			this.errors.country = 'Select option from list';
			this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
			this.SaveButtonText = "SAVE";

			return false;

		}
		if (form.value.client_id) {
			form.value.client_id = this.projects.client_id.id;
		}
		if (form.value.project_type && this.projects.project_type.item_name != undefined) {
			form.value.project_type = this.projects.project_type.item_name;
		} else {
			form.value.project_type = form.value.project_type;
		}

		if (form.value.output_resolution && this.projects.output_resolution.item_name != undefined) {
			form.value.output_resolution = this.projects.output_resolution.item_name;
		} else {
			form.value.output_resolution = form.value.output_resolution;
		}

		if (form.value.category) {
			var arr = "";
			_.forEach(this.projects.category, function (value) {
				arr += "|";
				arr += value['id'];
				arr += "|";
				arr += ",";
			});
			form.value.category = arr.slice(0, -1);
		} else {
			form.value.category = form.value.category;
		}

    if (form.value.length) {
      // form.value.length = this.projects.length;
      if(this.projects.length && this.selectedLengthType['name']){
        // console.log(this.selectedLengthType['name'],this.selectedLengthType['name']=='Minutes');
        form.value.length = [this.projects.length ,  this.selectedLengthType['name']=='Minutes'? 1 : 0 ];
        var arr = "";
			_.forEach(form.value.length, function (value) {
				// arr += "|";
				arr += value;
				// arr += "|";
				arr += "|";
			});
			form.value.length = arr.slice(0, -1);
      }
		}

		if (form.value.status) {
			form.value.status = this.projects.status.id;
		}
		if ($('#start_date').val() != '' && $('#start_date').val() != undefined) {
			form.value.start_date = moment($('#start_date').val()).format('YYYY-MM-DD');
		} else {
			form.value.start_date = null;
		}
		if ($('#end_date').val() != '' && $('#end_date').val() != undefined) {
			form.value.end_date = moment($('#end_date').val()).format('YYYY-MM-DD');
		} else {
			form.value.end_date = null;
		}
		this.SaveButtonText = 'SAVING';
		if (this.data.projectid && this.data.mode == 'edit') {
			form.value.id = this.data.projectid;
			this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.projectid, form.value)
				.subscribe((success) => {
					// Save effect within button
					this.SaveButtonText = 'SAVED';
					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.dataService.getListTotal('projectListByUser')
						.subscribe((success) => {
							this.dataService.getProjectListByUser = success.data;
							this.dataService.getAllProjectList();
						}, (rejected) => {
							// //console.log(rejected);
						});
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
						this.SaveButtonText = "SAVE"
					}

				});
		} else {
			this.dataService.storeData(this.apiRoutePrefix, form.value)
				.subscribe((success) => {
					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.dataService.getListTotal('projectListByUser')
						.subscribe((success) => {
							this.dataService.getProjectListByUser = success.data;
							this.dataService.getAllProjectList();
						}, (rejected) => {
							// //console.log(rejected);
						});
				}, (error) => {
					//console.log(error);
					//this.alertMessage = 'Something went wrong, Please try again!';
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
						this.SaveButtonText = "SAVE";

					}
				});
		}
	}

	isChecked(flag, field) {
		if (field == 'episodic_series') {
			if (flag) {
				this.projects.episodic_series = 1;
			} else {
				this.projects.episodic_series = 0;
			}
		} else if (field == 'is_external_review') {
			if (flag) {
				this.projects.is_external_review = 1;
			} else {
				this.projects.is_external_review = 0;
			}
		}
	}

	close() {
		this.closeCreateProject.emit();
	}

	getPhaseDepartment() {
		this.dataService.getListData(this.phaseDepartmentroute + 'List', { 'template_id': 0 })
			.subscribe((success) => {
				this.phaseDepartments = success.data;
			}, (rejected) => {

			});
	}

	onDropSuccess($event) {
		let rec = [];
		rec.push($event);
	}

	frmToggle() {
		this.isDisplyFrm = !this.isDisplyFrm;
		this.hideUsers = !this.hideUsers;
		this.focused = 'focused';
	}

	backToCreate(event) {
		event.stopPropagation();
		this.hideUsers = false;
		this.isDeleteMode = false;
		this.isDisplyFrm = false;
	}

	onDragEnd() {
		// this.targetList.push($event.dragData);
	}

	public dropped(files: NgxFileDropEntry[]) {
		this.files = files;
		for (const droppedFile of files) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {

					// Here you can access the real file
					//console.log(droppedFile.relativePath, file);

					var reader = new FileReader();
					this.thumbnail_name = file.name;
					this.thumbnail_type = file.type;

					reader.onload = (event: any) => {
						this.url = event.target.result;
						if (this.thumbnail_type != 'image/png' && this.thumbnail_type != 'image/jpg' && this.thumbnail_type != 'image/jpeg' && this.thumbnail_type != 'image/gif') {
							this.thumbnail_preview_src = './assets/images/default/attechment.png';
						} else {
							this.thumbnail_preview_src = this.url;
						}
					}
					reader.readAsDataURL(file);
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
				//console.log(droppedFile.relativePath, fileEntry);
			}
		}
	}

	public fileOver(event) {
		//console.log(event);
	}

	public fileLeave(event) {
		//console.log(event);
	}

	unlinkImage(event) {
		event.stopPropagation();
		this.thumbnail_preview_src = '';
		this.thumbnail = '';
		this.thumbnail_name = 'unset';
		this.url = '';
	}

	ngAfterViewInit() {
		$.AdminBSB.input.activate();
	}

	bidDaysEntered(value) {
		const startDate = new Date(this.projects.start_date); // replace with your start date
		const endDate = new Date(startDate);
		endDate.setDate(startDate.getDate() + this.projects.bid_days - 1);
		this.projects.end_date = moment(endDate).format(this.DateFormat);
	}

	// when start-date is entered
	startDateEntered(value) {
		const startDate = new Date(value.start._d);
		this.projects.start_date = moment(startDate).format(this.DateFormat);

		// when start date is changed but after end date is selected
		if (this.projects.end_date != null || this.projects.end_date != undefined) {
			this.projects.bid_days = Math.abs(moment(startDate).diff(this.projects.end_date, 'days')) + 1;

			let endLessThanStart: any = moment(new Date(value.start._d)).isSameOrBefore(this.projects.end_date);
			if (!endLessThanStart) {
				this.projects.end_date = moment(startDate).format(this.DateFormat);
				this.projects.bid_days = 1;
			}
		}
		// $('start_date').data('daterangepicker').setStartDate(JSON.stringify(startDate));
	}

	// when end-date is entered
	endDateEntered(value) {
		// when end date selected is less than start date selected
		let endLessThanStart: any = moment(new Date(this.projects.start_date)).isSameOrBefore(value.start._d);
		if (!endLessThanStart) {
			this.projects.start_date = moment(value.start._d).format(this.DateFormat);
			this.projects.bid_days = 1;
		} else {
			const endDate = new Date(value.start._d);
			this.projects.end_date = moment(endDate).format(this.DateFormat);
			this.projects.bid_days = Math.abs(moment(this.projects.start_date).diff(endDate, 'days')) + 1;
		}
		// $('#end_date').data('daterangepicker').setStartDate(JSON.stringify(new Date(value.start._d)));

	}

  // on Length Type Changed
  onLengthTypeChanged(){
    // if(this.projects.length && this.selectedLengthType['name']){
    //   this.projects.length = this.projects.length + ' | '+ this.selectedLengthType['name']=='Minutes'?1:0;
    // }
    // console.log(this.projects.length,this.selectedLengthType['name']);
  }


}
// interface Projects {
//   name:string,
//   thumbnail:string,
//   thumbnail_name:string,
//   thumbnail_type:string,
//   thumbnail_old:string,
//   thumbnail_display_name:string,
//   client:string,
//   user:number,
//   country:string,
//   length:number,
//   start_date:string,
//   end_date:string,
//   bid_days:string,
//   scheduled_days:string,
//   description:string,
//   type:string,
//   status:[]
// }
interface ProjectDetails {
	description: string
}
