import { Component, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { Location } from '@angular/common';
declare var $: any;
import { ToastrService } from '../../services/toastr.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
	public myform: any;
	forgot: forgotPassword;
	isSuperAdmin: boolean;
	isStandAlone: boolean;
	isSuccess: boolean;
	errors: any;
	login: Login;
	loginImages: LoginImages;
	clienCode: any;
	focused: string = '';
	isLoading: boolean = false;
	constructor(public router: Router,
		//public commonJs:CommonJs,
		private titleService: Title,
		private _location: Location,
		private dataService: DataService,
		private activatedRoute: ActivatedRoute,
		private toastrService: ToastrService,
		private renderer: Renderer2
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.isSuperAdmin = (event.url.indexOf('superadmin') !== -1);
				this.isStandAlone = false;
				localStorage.setItem('standalone', '0');
			}
		});
	}

	ngOnInit() {
		this.titleService.setTitle('Forgot Password');
		this.forgot = {
			email: '',
			client_code: ''
		}

		this.activatedRoute.params.subscribe(val => {
			this.login = {
				email: '',
				password: '123456',
				client_code: this.activatedRoute.snapshot.paramMap.get('access_code')
			}
			this.clienCode = this.activatedRoute.snapshot.paramMap.get('access_code');
			this.loginImages = {
				logo: '',
				logo_image: '',
				banner: '',
				banner_image: '',
			}

			this.dataService.getDataById('checkLoginImages/' + this.login.client_code)
				.subscribe((success) => {
					console.log(success)
					if (success.data.logo_url != '') {
						this.loginImages.logo = success.data.logo_url;
						this.loginImages.logo_image = success.data.logo_image;
					}

					if (success.data.banner_url != '') {
						this.loginImages.banner = success.data.banner_url;
						this.loginImages.banner_image = success.data.banner_image;
						const element = document.querySelector('.login-wrapper');
						this.renderer.setStyle(element, 'background-image', `url(${this.bannerImage(success.data.banner_url)})`);
					}

					localStorage.setItem('currentAccountImages', JSON.stringify(this.loginImages));

				}, (rejected) => {
					//console.log(rejected);
				});

		});
		this.focused = 'focused';
	}

	bannerImage(imageUrl: any) {
		return imageUrl;
	}

	back() {
		this.focused = "focus";
		//this.router.navigate(['/'+this.commonJs.getClientCodePrefix()+'/login']);
		this._location.back();
	}
	onSubmit(form: NgForm) {
		if (form.value.client_code == undefined) {
			form.value.client_code = this.clienCode;
			form.value.isStandAlone = this.isStandAlone;
		}

		form.value.isSuperAdmin = this.isSuperAdmin;
		this.isLoading = true;
		this.dataService.forgotpassword('forgot-password', form.value)
			.subscribe((success) => {
				if (success.success) {
					this.isSuccess = true;
					this.toastrService.notify('success', '', 'Request sent successfully, please check your email.');
					setTimeout(() => {
						this._location.back();
					}, 2);
				}
				this.isLoading = false;
			}, (error) => {
				if (error.error) {
					var err = error.error;
					this.errors = err.error;
				}
				this.isLoading = false;
			});
	}

}
interface forgotPassword {
	email: string,
	client_code: string
}

interface Login {
	email: string,
	password: string,
	client_code: string
}

interface LoginImages {
	logo: string,
	logo_image: string,
	banner: string,
	banner_image: string
}