import { Injectable, Optional, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class NotificationsService {
    public permission: Permission;
    constructor() {
        this.permission = this.isSupported() ? 'default' : 'denied';
    }

    public isSupported(): boolean {
        return 'Notification' in window;
    }

    requestPermission(): void {
        let self = this;
        if ('Notification' in window && this.permission != 'granted') {
            Notification.requestPermission(function (status) {
                return self.permission = status;
            });
        }
    }

    sendPushMessage(title, message, from = '') {
        let options = {
            "title": title,
            "body": message,
            "icon": "https://www.vanisoftware.com/wp-content/uploads/2019/04/favicon.png",
            "click_action": "",
            "sound": "default"
        }
        let _notify = new Notification(title, options);
    }


}

export declare type Permission = 'denied' | 'granted' | 'default';

export interface PushNotification {
    body?: string;
    icon?: string;
    tag?: string;
    data?: any;
    renotify?: boolean;
    silent?: boolean;
    sound?: string;
    noscreen?: boolean;
    sticky?: boolean;
    dir?: 'auto' | 'ltr' | 'rtl';
    lang?: string;
    vibrate?: number[];
} 
