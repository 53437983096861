import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { columns } from '../table-column';

@Component({
  selector: 'app-asset-task-info',
  templateUrl: './asset-task-info.component.html',
  styleUrls: ['./asset-task-info.component.css']
})
export class AssetTaskInfoComponent implements OnInit {
  @Input() assetTaskData: any;
  @Input() is_external_review: any;
  public dataTableColumns: TableColumn[] = [];
  @Input() isLoading: boolean = true;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;
  @ViewChild('department', { static: false }) public department: TemplateRef<any>;
  @ViewChild('revisionVersion', { static: false }) public revisionVersion: TemplateRef<any>;
  @ViewChild('revisionVersionHeader', { static: false }) public revisionVersionHeader: TemplateRef<any>;
  @ViewChild('plannedEndTime', { static: false }) public plannedEndTime: TemplateRef<any>;
  @ViewChild('actualStartTime', { static: false }) public actualStartTime: TemplateRef<any>;

  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.createColumns();
  }

  /** Create Column */
  private createColumns(): void {
    let cellTemplate: any = {
      status: this.status,
      department: this.department,
      revisionVersion: this.revisionVersion,
      revisionVersionHeader: this.revisionVersionHeader,
      actualStartTime: this.actualStartTime,
      plannedEndTime: this.plannedEndTime,
    };
    setTimeout(() => {
      this.dataTableColumns = columns('taskInfo', cellTemplate);
    });
  }
}

