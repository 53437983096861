import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { CommonJs } from '../common/common-js';
import { DataService } from '../services/data.service';
import { NotificationsService } from '../services/notifications/notifications.service';
import { ToastrService } from '../services/toastr.service';
import * as _ from "lodash";
declare var $: any;

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.css']
})
export class DashboardHeaderComponent implements OnInit, OnDestroy {
  apiRoutePrefix = 'projects';
  role = (localStorage.getItem('role') != '' && localStorage.getItem('role') != undefined) ? localStorage.getItem('role') : 'superadmin';
  client_code = (localStorage.getItem('client_code') != '' && localStorage.getItem('client_code') != undefined) ? localStorage.getItem('role') : 'superadmin';
  isProduction: boolean = true;
  st = localStorage.getItem('standalone');
  userData = JSON.parse(localStorage.getItem('user'));

  @Output() logout = new EventEmitter();
  @Output() showProjects = new EventEmitter();
  @Output() projectSidebarToggle = new EventEmitter();
  @ViewChild('multiSelect', { static: false }) multiSelect;

  isProjectHeader: boolean = false;
  isProjectPlanning: boolean = false;

  //project-details
  projects: any;
  ProjectName: any;
  projectData: any = JSON.parse(localStorage.getItem('localStorageProjectData'));
  projectId: any;

  isEpisodicSeries: boolean = false;
  noRecordFound: boolean = false;
  noRecordFoundMsg: any = 'Sorry, no records found!';
  openRightSidebar: boolean = false;
  dirName = 'projects';
  @Input() projectSidebarToggleVal: boolean = false;
  urlParam = this.router.url.split("/");
  projectPer = {};

  projectroles = {};
  projectrolestype = {};

  checklistData = [];
  display_label_count: boolean = false;

  projectNotiData = [];
  projectNotiCount = {};
  studioNotiData = [];
  clientCode = localStorage.getItem('access_code');
  userId = localStorage.getItem('userId');
  subscribeisUserTasksList: Subscription;
  //subscribeisUserTasksList: Subject<any> = new Subject<any>();
  studioType: any;
  env = environment;

  message;

  isProjectReportPermission: boolean = false;

  subscribeGeneralActivityTaskActive;
  changeArtistProjectTaskStatusSub;
  changeProjectSub;
  pauseGeneralTask;

  isViewPermission = [];
  isReportViewPermission = [];
  clientCodePrefix = "";
  uploadUrl = this.commonJs.getUploadUrl();

  studioNotificationCount;

  permissionChangedSub: Subscription;

  isExternalReview: boolean = false;

  currentProject: string;

  @Input() forceTosetProjectId: string;
  isprojectData: boolean = false;

  visiblePhases: any[] = [];
  phasesExist = {};

  constructor(public router: Router,
    public commonJs: CommonJs,
    private dataService: DataService,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.projectId = this.urlParam[5];
    this.currentProject = this.projectId;
    this.isProjectHeader = _.includes(this.router.url, 'projects');
    this.isProjectPlanning = _.includes(this.router.url, 'planning');
    this.dataService.isGeneralActivityTaskActive.observers ? this.dataService.isGeneralActivityTaskActive.observers.length = 0 : '';

    if(localStorage.getItem('phasesExist')){
      this.phasesExist = JSON.parse(localStorage.getItem('phasesExist'));
      // console.log(this.phasesExist);
    }else if(this.commonJs.phasesExist){
      this.phasesExist = this.commonJs.phasesExist;
    }else{
      this.phasesExist = { pre:1, character:1, set:1, prop:1, shot:1, post:1 };
    }

    this.permissionChangedSub = this.dataService.isProjectPermissionChanged$.subscribe(type => {
      this.isProjectReportPermission = this.commonJs.checkAccess('tasks', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.clientCodePrefix = this.commonJs.getClientCodePrefix();
      this.isViewPermission['tasks'] = this.commonJs.checkAccess('tasks', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['assets'] = this.commonJs.checkAccess('assets', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['seasons'] = this.commonJs.checkAccess('seasons', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['episodes'] = this.commonJs.checkAccess('episodes', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['sequences'] = this.commonJs.checkAccess('sequences', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['shots'] = this.commonJs.checkAccess('shots', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['bidding'] = this.commonJs.checkAccess('bidding', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['project_settings'] = this.commonJs.checkAccess('project_settings', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['task_comments'] = this.commonJs.checkAccess('task_comments', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isReportViewPermission['operational_report'] = this.commonJs.checkAccess('operational_report', 'view', { 'baiPass': ['admin'] });
      this.studioNotificationCount = this.commonJs.getLocalStorage('studio_notification_count');
    });
    this.dataService.setProjectData$.subscribe(item => {
      this.getProjectsById(item);
    });

  }



  setProjectId(id) {
    this.getProjectsById(id);
    this.currentProject = id;
  }

  showRightSidebar() {
    this.openRightSidebar = !this.openRightSidebar;
  }

  setNames(id) {
    this.openRightSidebar = false;
    this.getProjectsById(this.projectId);
  }

  getProjectsById(item) {
    this.projectId = item.id;
    this.projectData = item;
    this.ProjectName = this.projectData.name;
    this.isEpisodicSeries = this.projectData.episodic_series;
    this.isExternalReview = this.projectData.is_external_review;
    this.cd.markForCheck();
    if (this.role != 'admin') {
      this.commonJs.getProjectPermission(item.id);
    }
    this.isprojectData = true;
    this.getProjectRole(item.id);
  }

  getProjectRole(id) {
    this.dataService.getOtherData('getProjectRole', { 'project_id': id })
      .subscribe((success) => {
        if (localStorage.getItem('projectRoles') != null) {
          this.projectroles = JSON.parse(localStorage.getItem('projectRoles'));
        } else {
          this.projectroles = {};
        }
        this.projectroles['project_' + id] = success.role;
        localStorage.setItem('projectRoles', JSON.stringify(this.projectroles));

        if (localStorage.getItem('projectRoleType') != null) {
          this.projectrolestype = JSON.parse(localStorage.getItem('projectRoleType'));
        } else {
          this.projectrolestype = {};
        }
        this.projectrolestype['project_' + id] = success.role_type;
        localStorage.setItem('projectRoleType', JSON.stringify(this.projectrolestype));
      }, (error) => {
      });
  }


  isMenuActive(slug, prID = '') {
    return _.includes(this.router.url, slug);
  }

  isProjectActive(projectId = '') {
    return _.includes(this.router.url, projectId);
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(changes.forceTosetProjectId.currentValue);
    this.projectId = changes.forceTosetProjectId.currentValue;
  }

  closeSidebar(projectSidebarToggleVal) {
    this.projectSidebarToggleVal = !this.projectSidebarToggleVal;
  }

  ngOnDestroy() {
    this.permissionChangedSub.unsubscribe();
  }
}
