import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import { DataService } from '../../services/data.service';
import { ToastrService } from '../../services/toastr.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
type AOA = any[][];
import * as _ from "lodash";
import { GridService } from 'src/app/services/grid.service';
import { ProgressbarModalComponent } from '../progressbar-modal/progressbar-modal.component';
declare var $: any;

@Component({
  selector: 'app-excelmapimport',
  templateUrl: './excelmapimport.component.html',
  styleUrls: ['./excelmapimport.component.css']
})
export class ExcelmapimportComponent implements OnInit, OnDestroy {
  public event: EventEmitter<any> = new EventEmitter();
  data: AOA = [];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'Import_Shots.xlsx';
  excelHeader: any = [];
  @ViewChild('multiSelect', { static: false }) multiSelect;

  stepNumber: number = 1;
  projectId: any;
  urlParam = this.router.url.split("/");
  apiRoutePrefix = 'import';
  currentEntity: any = 'shots';
  xlsxBase64: any;

  list: { [index: string]: any } = [];
  title: any;
  selectedOption: any[] = [];

  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  ColumnNames: any;
  uploadedFileInfo: any = {};
  uploadImageZip: any = {};

  @Input() message: string;

  public modalRef: BsModalRef;
  selectedRows: any = [];
  requiredColumn: any;
  projectData: any;
  max: number = 5;
  public files: NgxFileDropEntry[] = [];

  autoSelectedValues: any = [];


  /** Shot column list */
  ShotColumns: any = [
    { headerDisplay: "Season #", column: "season", isDisabled: false, type: "Text", required: "<i class='font-bold icon-color-light-green material-icons'>check</i>", desc: "Required only if project is Episodic Series Current project must have a season with this Season #" },
    { headerDisplay: "Season Name", column: "season_name", type: "Text", required: '' },
    { headerDisplay: "Episode #", column: "episode", isDisabled: false, type: "Text", required: "<i class='font-bold icon-color-light-green material-icons'>check</i>", desc: "Required only when project is Episodic Series Current project must have a episode with this Episode # for given season" },
    { headerDisplay: "Episode Name", column: "episode_name", type: "Text", required: '' },
    { headerDisplay: "Sequence #", column: "sequence", isDisabled: false, type: "Text", required: "<i class='font-bold icon-color-green material-icons'>check</i>", desc: "Current project must have a sequence with this Sequence # for given season and episode combination." },
    { headerDisplay: "Sequence Name", column: "sequence_name", type: "Text", required: true },
    { headerDisplay: "Shot number", column: "shot_number", isDisabled: false, type: "Text", required: "<i class='font-bold icon-color-green material-icons'>check</i>", desc: "Must be Unique per Sequence" },
    { headerDisplay: "Image", column: "image", isDisabled: false, type: "Text", required: "", desc: "Image name" },
    { headerDisplay: "Complexity", column: "complexity", isDisabled: false, type: "Text", required: "", desc: "Must be Simple / Medium / Complex / Very Complex" },
    { headerDisplay: "No of frames", column: "no_of_frames", isDisabled: false, type: "Numeric", required: "<i class='font-bold icon-color-green material-icons'>check</i>", desc: "Numeric inputs only" },
    { headerDisplay: "Frame In & Out", column: "frame_in_out", isDisabled: false, type: "Text", required: "" },
    { headerDisplay: "Bid", column: "bid_days", isDisabled: false, type: "Numeric", required: "", desc: "Numeric inputs only" },
    { headerDisplay: "Departments", column: "departments", isDisabled: false, type: "Text", required: "<i class='font-bold icon-color-green material-icons'>check</i>", desc: "Numeric inputs only" },
    { headerDisplay: "Final Department", column: "final_department", isDisabled: false, type: "Text", required: "<i class='font-bold icon-color-green material-icons'>check</i>", desc: "Numeric inputs only" },
    { headerDisplay: "Description", column: "description", isDisabled: false, type: "Text", required: "", desc: "" },
    { headerDisplay: "Assets", column: "assets", isDisabled: false, type: "Text", required: "", desc: "Asset Codes must match Asset Code from that project Multiple values comma separated" },
    { headerDisplay: "Status", column: "status", isDisabled: false, type: "Text", required: "<i class='font-bold icon-color-green material-icons'>check</i>", desc: "Must be Active / OOP" },
    { headerDisplay: "Reel", column: "reel", isDisabled: false, type: "Text", required: "" },
    { headerDisplay: "Edit in", column: "edit_in", isDisabled: false, type: "Text", required: "" },
    { headerDisplay: "Edit Out", column: "edit_out", isDisabled: false, type: "Text", required: "" },
    { headerDisplay: "Scan In", column: "scan_in", isDisabled: false, type: "Text", required: "" },
    { headerDisplay: "Scan Out", column: "scan_out", isDisabled: false, type: "Text", required: "" },
  ];

  /** Shot validation */
  shotValidation: any = [
    'season',
    'episode',
    'sequence',
    'shot_number',
    'no_of_frames',
    'departments',
    'final_department',
    'status'
  ]

  /** Shot column without season and expisode */
  shotColumnWihtoutSeason: any = [
    'sequence',
    'shot_number',
    'no_of_frames',
    'departments',
    'final_department',
    'status'
  ]

  /** Asset column list */
  assetColumn: any = [
    { headerDisplay: "Name", column: "name", type: "Text", required: true },
    { headerDisplay: "Image", column: "image", type: "Text", required: false },
    { headerDisplay: "Code", column: "code", type: "Text", required: true },
    { headerDisplay: "Type", column: "type", type: "Text", required: true },
    { headerDisplay: "Sub Type", column: "sub_type", type: "Text", required: false },
    { headerDisplay: "Complexity", column: "complexity", type: "Text", required: false },
    { headerDisplay: "Departments", column: "departments", isDisabled: false, type: "Text", required: "<i class='font-bold icon-color-green material-icons'>check</i>", desc: "Numeric inputs only" },
    { headerDisplay: "Final Department", column: "final_department", isDisabled: false, type: "Text", required: "<i class='font-bold icon-color-green material-icons'>check</i>", desc: "Numeric inputs only" },
    { headerDisplay: "Description", column: "description", type: "Text", required: false },
    { headerDisplay: "Status", column: "status", type: "Text", required: true },
  ]

  assetValidation: any = [
    'name',
    'code',
    'type',
    'departments',
    'final_department',
    'status'
  ]

  /** Sequence column list */
  sequenceColumn: any = [
    { headerDisplay: "Season #", column: "season", type: "Text", required: true },
    { headerDisplay: "Season Name", column: "season_name", type: "Text", required: true },
    { headerDisplay: "Episode #", column: "episode", type: "Text", required: true },
    { headerDisplay: "Episode Name", column: "episode_name", type: "Text", required: true },
    { headerDisplay: "Sequence Number", column: "sequence_number", type: "Text", required: true },
    { headerDisplay: "Sequence Name", column: "sequence_name", type: "Text", required: true },
    { headerDisplay: "Image", column: "image", type: "text", required: false },
    { headerDisplay: "Description", column: "description", type: "Text", required: false },
    { headerDisplay: "Assets", column: "assets", type: "Text", required: false },
    { headerDisplay: "Lead", column: "lead", type: "Text", required: false },
    { headerDisplay: "Producer", column: "producer", type: "Text", required: false },
    { headerDisplay: "Projected Shots", column: "projected_shots", type: "Text", required: false },
    { headerDisplay: "Projected Seconds", column: "projected_seconds", type: "Text", required: false },
    { headerDisplay: "Complexity", column: "complexity", type: "Text", required: false },
    { headerDisplay: "Status", column: "status", type: "Text", required: true },
  ];

  /** sequence validation */
  sequenceValidation: any = [
    'season',
    'episode',
    'sequence_number',
    'sequence_name',
    'status'
  ]

  /** Shot column without season and expisode */
  sequenceColumnWihtoutSeason: any = [
    'sequence_number',
    'sequence_name',
    'status'
  ]

  /** Vendor column list */
  vendorColumn: any = [
    { headerDisplay: "Status", column: "status", type: "Text", required: true },
    { headerDisplay: "Vani Access", column: "vani_access", type: "Text", required: true },
    { headerDisplay: "Email", column: "email", type: "Text", required: true },
    { headerDisplay: "Password", column: "password", type: "Text", required: true },
    { headerDisplay: "Name", column: "name", type: "Text", required: true },
    { headerDisplay: "Phone", column: "phone", type: "Text", required: false },
    { headerDisplay: "Image", column: "image", type: "Text", required: false },
    { headerDisplay: "Address1", column: "address1", type: "Text", required: false },
    { headerDisplay: "City", column: "city", type: "Text", required: false },
    { headerDisplay: "State", column: "state", type: "Text", required: false },
    { headerDisplay: "Country", column: "country", type: "Text", required: false },
    { headerDisplay: "Zip", column: "zip", type: "Text", required: false },
    { headerDisplay: "Vendor Code", column: "vendor_code", type: "Text", required: true },
    { headerDisplay: "Dept", column: "department", type: "Text", required: false },
    { headerDisplay: "Grade", column: "grade", type: "Text", required: false },
    { headerDisplay: "Type", column: "type", type: "Text", required: true },
    { headerDisplay: "Team Count", column: "team_count", type: "Text", required: false },
  ]

  /** Vendaor validation */
  vendorValidation: any = [
    'status',
    'vani_access',
    'email',
    'password',
    'name',
    'vendor_code',
    'type'
  ]

  /** Employee column list */
  employeeColumn: any = [
    { headerDisplay: "Status", column: "status", type: "Text", required: true },
    { headerDisplay: "Vani Access", column: "vani_access", type: "Text", required: true },
    { headerDisplay: "Email", column: "email", type: "Text", required: true },
    { headerDisplay: "Password", column: "password", type: "Text", required: true },
    { headerDisplay: "Name", column: "name", type: "Text", required: true },
    { headerDisplay: "Phone", column: "phone", type: "Text", required: false },
    { headerDisplay: "Image", column: "image", type: "Text", required: false },
    { headerDisplay: "Date of birth", column: "dob", type: "Text", required: false },
    { headerDisplay: "Gender", column: "gender", type: "Text", required: true },
    { headerDisplay: "Address1", column: "address1", type: "Text", required: false },
    { headerDisplay: "Address2", column: "address2", type: "Text", required: false },
    { headerDisplay: "City", column: "city", type: "Text", required: false },
    { headerDisplay: "State", column: "state", type: "Text", required: false },
    { headerDisplay: "Country", column: "country", type: "Text", required: false },
    { headerDisplay: "Zip", column: "zip", type: "Text", required: false },
    { headerDisplay: "Mode", column: "mode", type: "Text", required: true },
    { headerDisplay: "Employee Code", column: "eCode", type: "Text", required: true },
    { headerDisplay: "Role", column: "role", type: "Text", required: true },
    { headerDisplay: "Gov Id", column: "gov_id", type: "Text", required: false },
    { headerDisplay: "Date Of Joining", column: "doj", type: "Text", required: true },
    { headerDisplay: "Location", column: "location", type: "Text", required: true },
    { headerDisplay: "Vert", column: "vert", type: "Text", required: true },
    { headerDisplay: "Designation", column: "designation", type: "Text", required: false },
    { headerDisplay: "Department", column: "dept", type: "Text", required: true },
    { headerDisplay: "Secondary Department", column: "secondary_dept", type: "Text", required: false },
    { headerDisplay: "Reporting To", column: "reporting_to", type: "Text", required: false },
    { headerDisplay: "Prod Person", column: "prod_person", type: "Text", required: false },
    { headerDisplay: "Soft Skills", column: "soft_skills", type: "Text", required: false },
    { headerDisplay: "Exp_Duration", column: "exp_Duration", type: "Text", required: false },
    { headerDisplay: "Exp_Designation", column: "exp_Designation", type: "Text", required: false },
    { headerDisplay: "Exp_Company", column: "exp_Company", type: "Text", required: false },
    { headerDisplay: "Exit Date", column: "exit_date", type: "Text", required: false },
    { headerDisplay: "Attr Type", column: "attr_type", type: "Text", required: false },
    { headerDisplay: "Reason for", column: "reason_for_exit", type: "Text", required: false },
  ]
  /** Employee validation */
  employeeValidation: any = [
    'status',
    'vani_access',
    'email',
    'password',
    'name',
    'gender',
    'mode',
    'eCode',
    'role',
    'doj',
    'location',
    'vert',
    'dept',
  ]


  /** Task column list */
  ManualTasksColumns = [
    { headerDisplay: "Department", column: "Department", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Task department name. Must be from Settings > Phases > department list E.g. Story, for Department task types enter task type name only." },
    { headerDisplay: "Season #", column: "Season", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if project is an episodic series. Must match with season # of this project in system." },
    { headerDisplay: "Episode #", column: "Episode", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if project is an episodic series. Must match with season # and Episode # combination of this project in system." },
    { headerDisplay: "Sequence #", column: "Sequence", "type": "Text", "required": "<i class='font-bold icon-color-light-green material-icons'>check</i>", "desc": "Required only if project is not an episodic series. Must match with Sequence # of this project in system." },
    { headerDisplay: "Name", column: "Name", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "" },
    { headerDisplay: "Number", column: "Number", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Unique per task" },
    { headerDisplay: "Type", column: "Type", "type": "Text", "required": "<i class='font-bold icon-color-green material-icons'>check</i>", "desc": "Must be R&D / Production" },
    { headerDisplay: "Thumbnail", column: "Thumbnail", "type": "Text", "required": "", "desc": "Thumbnail name" },
    { headerDisplay: "Description", column: "Description", "type": "Text", "required": "", "desc": "" }
  ];


  taskValidation: any = [
    'Department',
    'Season',
    'Episode',
    'Sequence',
    'Name',
    'Number',
    'Type',
  ]

  taskColumnWihtoutSeason: any = [
    'Department',
    'Sequence',
    'Name',
    'Number',
    'Type',
  ]

  constructor(
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private toastrService: ToastrService,
    private bsModalRef: BsModalRef,
    public router: Router,
    private gridService: GridService,
    private modalService: BsModalService,

  ) {

  }

  ngOnDestroy() {
    this.dataService.isModalOpened = false;
  }

  ngOnInit() {
    this.dataService.isModalOpened = true;
    this.projectId = this.urlParam[5];
    let fromComponentData: any = this.dataService.importInfo;
    if (fromComponentData.from != 'EmployeeComponent' && fromComponentData.from != 'VendorComponent') {
      this.getProjectsById(this.projectId);
    } else {
      this.columnAssign();
    }
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
    });

    this.dropdownList = [
      { item_id: 1, item_text: 'Mumbai' },
      { item_id: 2, item_text: 'Bangaluru' },
      { item_id: 3, item_text: 'Pune' },
      { item_id: 4, item_text: 'Navsari' },
      { item_id: 5, item_text: 'New Delhi' }
    ];

    // this.selectedItems = [
    //   { item_id: 3, item_text: 'Pune' },
    //   { item_id: 4, item_text: 'Navsari' }
    // ];

    this.dropdownSettings = {
      singleSelection: true,
      idField: 'column',
      textField: 'headerDisplay',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };

    this.uploadImageZip.istrue = false;
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false }));
      this.excelHeader = this.data[0].slice(0);
      this.cd.markForCheck();
    };
    reader.readAsBinaryString(target.files[0]);
  }

  //Profile
  filesUpdated(evt: any) {
    // //console.log(evt)
    let eventLastValue: any = [];
    eventLastValue.push(evt.pop());
    if (this.stepNumber == 1) {
      if (eventLastValue[0].type == 'application/zip' || eventLastValue[0].type == 'application/x-zip-compressed') {
        this.uploadImageZip.istrue = true;

        /* wire up file reader */
        const readerImage: FileReader = new FileReader();
        readerImage.onload = (e: any) => {
          /* read workbook */
          const bstrImage: string = e.target.result;

          this.uploadImageZip.base64 = bstrImage;
          /* grab first sheet */

          this.uploadImageZip.wsname = eventLastValue[0].name;
          this.uploadImageZip.preview_src = './assets/images/default/zip.png';
        };

        readerImage.readAsDataURL(eventLastValue[0]);
      } else {
        this.toastrService.notify('warn', '', 'Only zip file allowed');
      }
    } else {
      /* wire up file reader */
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        this.uploadedFileInfo.wsname = eventLastValue[0].name;
        this.uploadedFileInfo.preview_src = './assets/images/default/attechment.png';

        /* save data */
        this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false }));
        this.excelHeader = this.data[0].slice(0);
        this.cd.markForCheck();
      };
      reader.readAsBinaryString(eventLastValue[0]);
    }
  }


  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          // //console.log(droppedFile.relativePath, file);

          let eventLastValue: any = [];
          eventLastValue.push(file);
          if (this.stepNumber == 1) {
            if (eventLastValue[0].type == 'application/zip' || eventLastValue[0].type == 'application/x-zip-compressed') {
              this.uploadImageZip.istrue = true;

              /* wire up file reader */
              const readerImage: FileReader = new FileReader();
              readerImage.onload = (e: any) => {
                /* read workbook */
                const bstrImage: string = e.target.result;

                this.uploadImageZip.base64 = bstrImage;
                /* grab first sheet */

                this.uploadImageZip.wsname = eventLastValue[0].name;
                this.uploadImageZip.preview_src = './assets/images/default/zip.png';
              };

              readerImage.readAsDataURL(eventLastValue[0]);
            } else {
              this.toastrService.notify('warn', '', 'Only zip file allowed');
            }
          } else {
            /* wire up file reader */
            const reader: FileReader = new FileReader();
            reader.onload = (e: any) => {
              /* read workbook */
              const bstr: string = e.target.result;
              const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

              /* grab first sheet */
              const wsname: string = wb.SheetNames[0];
              const ws: XLSX.WorkSheet = wb.Sheets[wsname];

              this.uploadedFileInfo.wsname = eventLastValue[0].name;
              this.uploadedFileInfo.preview_src = './assets/images/default/attechment.png';

              /* save data */
              this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1, raw: false }));
              this.excelHeader = this.data[0].slice(0);
              this.cd.markForCheck();
            };
            reader.readAsBinaryString(eventLastValue[0]);
          }

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        // //console.log(droppedFile.relativePath, fileEntry);
      }
    }
    // //console.log(files)

  }

  public fileOver(event) {
    // //console.log(event);
  }

  public fileLeave(event) {
    // //console.log(event);
  }

  unlinkImage(event) {
    event.stopPropagation();
    if (this.stepNumber == 1) {
      this.uploadImageZip.base64 = '',
        this.uploadedFileInfo.wsname = ''
      this.uploadImageZip.istrue = false
    } else {
      this.uploadedFileInfo.wsname = '';
      this.uploadedFileInfo.preview_src = '';
      this.data = [];
    }
  }

  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* generate base64 string */
    var out = XLSX.write(wb, { type: 'base64' });

    this.xlsxBase64 = out;

    /* save to file */

    // const ev = XLSX.writeFile(wb, this.fileName);

  }

  /** Next step  */
  nextStep() {
    if (this.stepNumber == 1) {
      this.stepNumber = 2;
    } else if (this.stepNumber == 2) {
      if (this.data.length == 0) {
        this.toastrService.notify('warn', '', 'Please select a file.');
      } else {
        this.selectedItems = [];
        this.selectedRows = [];
        this.stepNumber = 3;
        $('.modal-dialog').addClass("modal-lg-import");
        const newArray = this.excelHeader.map(value => {
          const matchingObject = this.ColumnNames.find(obj => (obj.headerDisplay === value || obj.column === value));
          return matchingObject ? { headerDisplay: matchingObject.headerDisplay } : { headerDisplay: '' };
        });
        this.autoSelectedValues = newArray;
        const columnMapping = this.ColumnNames
          .filter(obj => this.excelHeader.includes(obj.headerDisplay) || this.excelHeader.includes(obj.column))
          .map(obj => ({ [obj.column]: obj.headerDisplay }));
        this.selectedRows = columnMapping;
        const updatedSecondArray = this.excelHeader.map(value => {
          const matchingObject = this.ColumnNames.find(obj => (obj.headerDisplay === value || obj.column === value));
          return matchingObject ? matchingObject.column : value;
        });
        this.excelHeader = updatedSecondArray
      }
    } else if (this.stepNumber == 3) {
      this.stepNumber = 3;
    }
  }

  /** Back step */
  backStep() {
    if (this.stepNumber == 3) {
      this.stepNumber = 2;
      this.data = [];
      this.uploadedFileInfo.wsname = '';
      this.uploadedFileInfo.preview_src = '';
      $('.modal-dialog').removeClass("modal-lg-import");
    } else {
      this.stepNumber = 1;
    }
  }

  // Get not selected required Column
  arr_diff(a1, a2) {

    var a = [], diff = [];

    for (var i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }

    for (var i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }

    for (var k in a) {
      diff.push(k + '   ');
    }

    return diff;
  }


  /** Submit file */
  importXlsx() {
    //Driver Code
    this.selectedRows.sort(); //Sorts both Ajax and user info.
    this.requiredColumn.sort();
    var missingColumn: any = [];
    var selectedRowKey: any = [];
    for (var key in this.selectedRows) {
      selectedRowKey.push(Object.keys(this.selectedRows[key])[0])
    }
    selectedRowKey.sort(); //Sorts both Ajax and user info.
    missingColumn = this.requiredColumn.filter(column => !selectedRowKey.includes(column));
    const formattedArray = missingColumn.map((element) => {

      // Capitalize the first letter of each word
      const capitalizedElement = element.replace(/\b\w/g, (char) => char.toUpperCase());

      // Replace underscores with spaces
      const formattedElement = capitalizedElement.replace(/_/g, ' ');

      return formattedElement;
    });
    // this.data[0] = this.excelHeader;
    if (formattedArray.length > 0) {
      this.toastrService.notify('warn', '', 'Please map all the ' + `"` + formattedArray + `"` + ' column with vani required columns.');
    } else if (this.uploadImageZip.istrue) {
      this.data[0] = this.excelHeader.slice(0);
      const body = {
        file: this.uploadImageZip.base64,
        file_name: this.uploadImageZip.wsname,
        image_upload: true,
        project_id: this.dataService.projectIdInfo
      }
      this.dataService.storeData(this.apiRoutePrefix + '/' + this.currentEntity, body)
        .subscribe((response) => {
          if ((response.url != '' || response.columnError == true) && !response.fileTypeError) {
            let successMsg = response.message ? response.message : false;
            if (successMsg && successMsg.includes(' 0 records were returned with errors')) {  // (this.apiRoutePrefix=='importAssetUpdate' || this.apiRoutePrefix=='importShotsUpdate')

              this.toastrService.notify('success', '', 'Data updated successfully.');
            }else{
                swal.fire({
                  title: 'Import Error',
                  text: response.message,
                  icon: "warning",
                  showCancelButton: false,
                  confirmButtonColor: "#DD6B55",
                  confirmButtonText: "Okay",
                }).then((result) => {
                  if (result.value) {
                  }
                }
                )
            }

            if (response.url != '') {
              window.location.href = response.url;
            }
            if (response.columnError) {
            }
          }
          else if (response.image_error == true) {
            return false;
          } else if (response.is_image_uploded == true) {
            this.toastrService.notify('success', '', 'Images uploded successfully.');
            this.xlsxUpload();
          } else if (response.fileTypeError) {
            return false;
          }
          this.triggerEvent('Confirmed');
        }, (error) => {
          this.triggerEvent('Confirmed');
        });
    } else {
      this.xlsxUpload();
    }

  }


  /** XLSX upload after image sucessfully uploaded */
  xlsxUpload() {
    var missingColumn: any = [];
    var selectedRowKey: any = [];
    for (var key in this.selectedRows) {
      selectedRowKey.push(Object.keys(this.selectedRows[key])[0])
    }
    // selectedRowKey.sort(); //Sorts both Ajax and user info.
    // missingColumn = this.arr_diff(selectedRowKey, this.requiredColumn)
    missingColumn = this.requiredColumn.filter(column => !selectedRowKey.includes(column));
    const formattedArray = missingColumn.map((element) => {
      // Capitalize the first letter of each word
      const capitalizedElement = element.replace(/\b\w/g, (char) => char.toUpperCase());
      // Replace underscores with spaces
      const formattedElement = capitalizedElement.replace(/_/g, ' ');
      return formattedElement;
    });
    // this.data[0] = this.excelHeader;
    if (formattedArray.length > 0) {
      this.toastrService.notify('warn', '', 'Please map all the ' + `"` + formattedArray + `"` + ' column with vani required columns.');
    } else {
      this.data[0] = this.excelHeader.slice(0);
      // this.excelHeader = selectedRowKey;
      this.export();
      const body = {
        file: "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + this.xlsxBase64,
        file_name: this.fileName,
        image_upload: false,
        is_image_upload: false,
        project_id: this.dataService.projectIdInfo
      }

      if (this.currentEntity == 'Manual Tasks') {
        this.currentEntity = 'manual_tasks';
      }
      this.bsModalRef.hide();
      this.gridService.apiProgress = 0;
      this.bsModalRef = this.modalService.show(ProgressbarModalComponent, {
        backdrop: 'static',
        keyboard: false,
        class: 'modal-dialog-centered'
      });
      this.dataService.storeData(this.apiRoutePrefix + '/' + this.currentEntity, body)
        .subscribe((response) => {
          console.log("response", response)
          if ((response.url != '' || response.columnError == true) && !response.fileTypeError) {
            let successMsg = response.message ? response.message : false;
            if (successMsg && successMsg.includes(' 0 records were returned with errors')) {  // (this.apiRoutePrefix=='importAssetUpdate' || this.apiRoutePrefix=='importShotsUpdate')

              this.toastrService.notify('success', '', 'Data updated successfully.');
            }else{

              swal.fire({
                title: 'Import Error',
                text: response.message,
                icon: "warning",
                showCancelButton: false,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Okay",
              }).then((result) => {
                if (result.value) {
                }
              }
              )
            }

            if (response.url != '') {
               window.open(response.url, '_self');
            }
            if (response.columnError) {
            }

          }
          else if (response.image_error == true) {
            return false;
          } else if (response.is_image_uploded == true) {
            this.toastrService.notify('success', '', 'Images uploded successfully.');
          } else if (response.fileTypeError) {
            return false;
          } else {
            this.toastrService.notify('success', '', 'Data imported successfully.');
          }
          if (response.is_image_uploded == true) {
          } else {
            setTimeout(() => {
              this.bsModalRef.hide();
            }, 2);
          }
          this.triggerEvent('Confirmed');
          this.gridService.apiProgress = 100;
        }, (error) => {
          this.triggerEvent('Confirmed');
        });

      const progressInterval = setInterval(() => {
        if (this.gridService.apiProgress < 90) { // Update progress up to 90%
          this.gridService.apiProgress += 10; // Increment progress by 10%
        }
      }, 1000); // Update progress every second
      setTimeout(() => {
        clearInterval(progressInterval);
      }, 10000); // Adjust the time as needed
    }

  }

  /** Close modal */
  closeModal() {
    this.bsModalRef.hide();
  }

  /** Trigger Event */
  triggerEvent = (data: string) => {
    this.event.emit(data);
  }

  /** On De-select */
  onItemDeSelect($event, indexx: any) {
    const index = this.selectedRows.findIndex(object => {
      return Object.keys(object)[0] === $event.column;
    }); // 👉️ 1
    this.excelHeader[indexx] = this.data[0][indexx];
    if (index !== -1) {
      this.selectedRows.splice(index, 1); // 2
    }
  }

  onItemSelect($event: any, headercolumn: any, indexx: any) {
    this.requiredColumn.filter((cl: any) => {
      if (cl == $event.column) {
        var obj = {};
        obj[$event.column] = headercolumn;
        this.selectedRows.push(obj);
      }
    });
    // const index = this.ColumnNames.findIndex(object => {
    //   return object.column === $event.column;
    // }); // 👉️ 1
    this.excelHeader[indexx] = $event.column;
    // if (index !== -1) {
    //   this.ColumnNames[index].column = $event.column;
    //   this.ColumnNames[index].isDisabled = true;
    // }
    // this.changeHeaderColumnName(headercolumn, $event);
  }

  onSelectAll(items: any) {
  }


  downloadFile() {
    //window.location.href = 'http://localhost/'+window.location.pathname+'/vani-offline/public/import/Template/Import_Assets.xls';
    //return true;
    this.dataService.storeData(this.apiRoutePrefix + 'download', { type: this.currentEntity })
      .subscribe((response) => {
        if (response.data != '') {
          let appLocation = window.location.origin;
          let fileName = '';

          if (response.entity == 'assets') {
            fileName = 'Import_Assets.xls';
          } else if (response.entity == 'shots') {
            fileName = 'Import_Shots.xls';
          } else if (response.entity == 'Manual Tasks') {
            fileName = 'Import_Manual_Tasks.xls';
          } else if (response.entity == 'employees') {
            fileName = 'Import_Employees.xls';
          } else if (response.entity == 'sequences') {
            fileName = 'Import_Sequences.xls';
          } else if (response.entity == 'vendors') {
            fileName = 'Import_Vendors.xls';
          }
          console.log(appLocation);
          if (appLocation == "https://app.vanisoftware.com" || appLocation == "https://staging.vanisoftware.com" || appLocation == "http://app.vanisoftware.com" || appLocation == "http://app.vanisoftware.com" || appLocation == "https://app.vanisoftware.com") {
            window.location.href = response.data;
          } else if (appLocation == 'http://127.0.0.1:4200' || appLocation=='http://localhost:4200') {
            let host = window.location.host;
            window.location.href = response.data;
            // window.open('http://' + host + '/vani-offline/public/import/Template/' + fileName, 'Download');
          } else {
            //window.location.href = response.data;
            let host = window.location.host;
            window.open('http://' + host + '/vani-offline/public/import/Template/' + fileName, 'Download');
          }

        }
      }, (error) => {
        this.toastrService.notify('warn', '', 'Sorry..Try Again.');
      });
  }


  /** Import help */
  public importHelp() {
    this.dataService.notifyOther({ refresh: true });
  }


  /** Get project data */
  getProjectsById(id) {
    // this.projectId = id;
    // this.dataService.getDataById('getProjectById/' + id)
    //   .subscribe((success) => {
    this.projectData = JSON.parse(localStorage.getItem('localStorageProjectData'));
    this.columnAssign();
    // }, (error) => {
    //   // //console.log(error);
    //   if (error.error) {
    //     var err = error.error;
    //     // //console.log(err);
    //     this.toastrService.notify('warn', '', err.message);
    //     this.router.navigate(['/dashboard']);
    //   }
    // });
  }

  // Column assign by which screen is come
  columnAssign() {
    let epiSeasion: any = ['Season #', 'Episode #'];
    let fromComponentData: any = this.dataService.importInfo;
    if (fromComponentData.from == 'ShotComponent') {
      this.ColumnNames = this.ShotColumns;
      if (this.projectData.episodic_series == 0) {
        this.requiredColumn = this.shotColumnWihtoutSeason;
      } else {
        this.requiredColumn = this.shotValidation;
      }
      this.cd.markForCheck();
    } else if (fromComponentData.from == 'AssetComponent') {
      this.ColumnNames = this.assetColumn;
      this.requiredColumn = this.assetValidation;
      this.currentEntity = 'assets';
      this.cd.markForCheck();
    } else if (fromComponentData.from == 'SequenceComponent') {
      this.ColumnNames = this.sequenceColumn;
      if (this.projectData.episodic_series == 0) {
        this.requiredColumn = this.sequenceColumnWihtoutSeason;
      } else {
        this.requiredColumn = this.sequenceValidation;
      }
      this.currentEntity = 'sequences';
      this.cd.markForCheck();
    }
    else if (fromComponentData.from == 'VendorComponent') {
      this.ColumnNames = this.vendorColumn;
      this.requiredColumn = this.vendorValidation;
      this.currentEntity = 'vendors';
      this.cd.markForCheck();
    }
    else if (fromComponentData.from == 'EmployeeComponent') {
      this.ColumnNames = this.employeeColumn.map((ele) => {
        if (ele.headerDisplay == "Employee Code" || ele.headerDisplay == "Date Of Joining" || ele.headerDisplay == "Department") {
          ele.headerDisplay == "Employee Code" ? (ele.headerDisplay = "ECode") : (ele.headerDisplay == "Date Of Joining" ? (ele.headerDisplay = "DOJ") : (ele.headerDisplay = "Dept"));
        }

        return ele;
      });
      console.log(this.ColumnNames, this.employeeColumn);
      this.requiredColumn = this.employeeValidation;
      this.currentEntity = 'employees';
      this.cd.markForCheck();
    }

    else if (fromComponentData.from == 'TaskComponent') {
      this.ColumnNames = this.ManualTasksColumns;
      if (this.projectData.episodic_series == 0) {
        this.requiredColumn = this.taskColumnWihtoutSeason;
      } else {
        this.requiredColumn = this.taskValidation;
      }
      this.currentEntity = 'Manual Tasks';
      this.cd.markForCheck();
    }
  }

  search(event: any) {
    this.ColumnNames = [...this.ColumnNames]
  }
}
