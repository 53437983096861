import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { GridService } from 'src/app/services/grid.service';
import { columns } from '../../projects/utility/column-generator';
import { ActivatedRoute, Params } from '@angular/router';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';
import { NgForm } from '@angular/forms';
import { DaterangePickerComponent } from 'ng2-daterangepicker';
import { ExcelmapimportComponent } from 'src/app/common/excelmapimport/excelmapimport.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'src/app/services/toastr.service';
import { AppConfigs } from 'src/app/common/app-configs';
import { DatatableService } from 'src/app/services/datatable/datatable.service';
import { Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { ThumbnailModalServiceService } from 'src/app/services/thumbnail-modal-service.service';
declare var $: any;
import * as _ from "lodash";
import * as moment from 'moment';
import * as lodashArray from "lodash";
import swal from 'sweetalert2';
import { ConfirmationPopupComponent } from 'src/app/common/confirmation-popup/confirmation-popup.component';

@Component({
	selector: 'app-employees-grid',
	templateUrl: './employees-grid.component.html',
	styleUrls: ['./employees-grid.component.css']
})
export class EmployeesGridComponent implements OnInit {
	permissionsObj: any = {};
	public dataTableColumns: TableColumn[] = [];
	rows: Array<any> = [];
	tempArray: Array<any> = [];
	advanceFilterColumnsValue: Array<any>;
	advanceFilterColumns: Array<any> = [];
	filterAppliedOn: Array<any> = [];
	reportingToOptions: Array<any> = [];
	prodPersonOptions: Array<any> = [];
	rolesOptions: Array<any> = [];
	typeOptions: Array<any> = [];
	primaryDeptOptions: Array<any> = [];
	secondaryDeptOptions: Array<any> = [];
	reasonForExitOptions: Array<any> = [];
	skillOptions: Array<any> = [];
	genderOptions: Array<any> = this.appConfigs.user_gender;
	modeOptions: Array<any> = this.appConfigs.user_mode;
	verticalOptions = this.appConfigs.user_vertical;
	attritionTypeOptions = this.appConfigs.user_attrition_type;
	preferenceColumns: any = {};
	bsModalRef: BsModalRef;
	headerDropdowns = {
		status: '',
		reporting_to: '',
		prod_person: '',
		role_id: '',
		primary_department: '',
		secondary_department: '',
		exit_reason: '',
		software_skills: '',
		gender: '',
		mode: '',
		vertical: '',
		attrition_type: '',
	};
	currentPage: number = 0;
	openedPage: number = 1;
	totalCount: number = 0;
	totalItems: number = 0;
	pageSize: number = 100;
	pagePayload: any = { page: 0, itemsPerPage: 100 }
	tableGridHeight = { headerHeight: 90, rowHeight: 40, footerHeight: 50 };

	isLoading: boolean = false;
	submitted: boolean = false;
	_import_opened: boolean = false;
	_filter_opened: boolean = false;
	_opened: boolean = false;
	_role_opened: boolean = false;
	_loadImportSideWindow: boolean = false;
	_loadRoleSideWindow: boolean = false;
	_loadPermissionSideWindow: boolean = false;
	_loadActivityLogSideWindow: boolean = false;
	_loadFilterSideWindow: boolean = false;
	_loadManageColSideWindow: boolean = false;
	_loadSideWindow: boolean = false;
	_manage_col_opened: boolean = false;

	_ng_sidebar_data = {};
	_ng_sidebar_role_data = {};
	_ng_sidebar_manage_col_data = {};
	_ng_sidebar_import_data = {};
	activeEmpDateValue: { [index: string]: any } = {};
	settings: any = JSON.parse(localStorage.getItem('settings'));

	_ng_import_type: string = '';
	fromNotify: string = '';
	datepickerDefault: { joining_date: any, dob: any, exit_date: any } = { joining_date: '', dob: '', exit_date: '' };

	@ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
	@ViewChild(DaterangePickerComponent, { static: false }) private picker: DaterangePickerComponent;
	@ViewChild('thumbnail', { static: false }) public thumbnail: TemplateRef<any>;
	@ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
	@ViewChild('manageBtns', { static: false }) public manageBtns: TemplateRef<any>;
	@ViewChild('name', { static: false }) public name: TemplateRef<any>;
	@ViewChild('status', { static: false }) public status: TemplateRef<any>;
	@ViewChild('mode', { static: false }) public mode: TemplateRef<any>;
	@ViewChild('emptyTableRow', { static: false }) public emptyTableRow: TemplateRef<any>;

	@ViewChild('dateOfJoining', { static: false }) public dateOfJoining: TemplateRef<any>;
	@ViewChild('dateOfBirth', { static: false }) public dateOfBirth: TemplateRef<any>;
	@ViewChild('exitDate', { static: false }) public exitDate: TemplateRef<any>;

	@ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;
	@ViewChild("hdrDropdownTpl", { static: true }) hdrDropdownTpl: TemplateRef<any>;
	@ViewChild("hdrDateTemplate1", { static: true }) hdrDateTemplate1: TemplateRef<any>;
	@ViewChild("hdrDateTemplate2", { static: true }) hdrDateTemplate2: TemplateRef<any>;
	@ViewChild("hdrDateTemplate3", { static: true }) hdrDateTemplate3: TemplateRef<any>;

	@ViewChild('date1', { static: true }) date1!: ElementRef;
	@ViewChild('date2', { static: true }) date2: ElementRef;
	@ViewChild('date3', { static: true }) date3: ElementRef;

	public activeEmpDate = {
		singleDatePicker: true,
		autoUpdateInput: true,
		showDropdowns: true,
		autoApply: true,
		drops: 'down',
		//startDate: '',
		locale: { format: this.settings.date_format }
	}

	public rangePicker = {
		singleDatePicker: false,
		autoUpdateInput: false,
		showDropdowns: true,
		autoApply: false,
		drops: 'down',

		locale: { format: this.settings.date_format },
	}
	checkFilters = { 'status': [], 'gender': [], 'mode': [], 'role_id': [], 'vertical': [], 'type': [], 'primary_department': [], 'secondary_department': [], 'reporting_to': [], 'prod_person': [], 'software_skills': [], 'attrition_type': [], 'exit_reason': [] };
	selectedValue = { 'status': [], 'gender': [], 'mode': [], 'role_id': [], 'vertical': [], 'type': [], 'primary_department': [], 'secondary_department': [], 'reporting_to': [], 'prod_person': [], 'software_skills': [], 'attrition_type': [], 'exit_reason': [] };
	statusOptions = [
		{ id: 1, name: "Active Emp" },
		{ id: 2, name: "Active Users" },
		{ id: 0, name: "Exit" },
	];
	statusTag = {
		'11': { className: 'icon-color-green material-icons', display: 'offline_pin', tooltip: 'Active User' },
		'10': { className: 'icon-color-green material-icons', display: 'brightness_1', tooltip: 'Active Emp' },
		'01': { className: 'icon-color-light-grey material-icons', display: 'offline_pin', tooltip: 'Inactive User' },
		'00': { className: 'icon-color-light-grey material-icons', display: 'brightness_1', tooltip: 'Inactive Emp' }
	};
	public readonly pageLimitOptions = [
		{ value: 50 },
		{ value: 100 },
		{ value: 200 },
		{ value: 300 },
	];



	config: any = {};
	configDefault: any = {
		"start": 0,
		"itemsPerPage": this.pagePayload.itemsPerPage,
		"sort": {
			"title": "#ID",
			"name": "id",
			"sort": "desc",
			"className": "hide-this hide-this"
		},
		"sortNew": {
			"id": "desc"
		},
		"FilterColumns": [
			"name",
			"email"
		],
		"filtering": {
			"filterString": ""
		},
		"FilterByColumns": {
			"status": "",
			"location": "",
			"emp_code_final": "",
			"name": "",
			"mode": "",
			"primary_department": "",
			"joining_date": "",
			"vertical": "",
			"role_id": "",
			"reporting_to": "",
			"prod_person": "",
			"software_skills": "",
			"secondary_department": "",
			"designation": "",
			"dob": "",
			"gender": "",
			"exit_date": "",
			"attrition_type": "",
			"exit_reason": "",
			"tenure": "",
			"age": "",
			"email": "",
			"phone": "",
			"government_id": "",
			"address1": "",
			"address2": "",
			"city": "",
			"state": "",
			"country": "",
			"zipcode": "",
			"exp_duration": "",
			"exp_designation": "",
			"exp_company": "",
			"column1": "",
			"column2": "",
			"column3": "",
			"column4": "",
			"column5": "",
			"column6": ""
		},
		"where": {
			"status": [],
			"gender": [],
			"mode": [],
			"vertical": [],
			"role_id": [],
			"type": [],
			"primary_department": [],
			"secondary_department": [],
			"reporting_to": [],
			"prod_person": [],
			"software_skills": [],
			"attrition_type": [],
			"exit_reason": []
		},
		"columns": {},
		"export": false
	};
	empCustomColumns = [];
	modalRef: BsModalRef;

  isFullScreen:boolean = false;
  previousOffsetY:number = 0;

  clientCodePrefix = "";

	constructor(
		private gridService: GridService,
		private activatedRoute: ActivatedRoute,
		public commonJs: CommonJs,
		private dataService: DataService,
		private modalService: BsModalService,
		private toastrService: ToastrService,
		public appConfigs: AppConfigs,
		private datatableService: DatatableService,
		private titleService: Title,
		private thumbnailModalService: ThumbnailModalServiceService,
		private cd: ChangeDetectorRef
	) {
		this.config = this.configDefault;
		this.permissionsObj.isProjectPermissions = {};
	}

	ngOnInit() {
    this.clientCodePrefix = this.commonJs.getClientCodePrefix();
		this.titleService.setTitle(this.settings.html_prefix + ' Employees');
		this.dataService.getListData('role_typeList', '')
			.subscribe((success) => {
				this.typeOptions = success.data;
			}, (rejected) => {

			});

		this.dataService.getListData('listItemList/emp_custom_columns', '')
			.subscribe((success) => {
				let temp_custom_columns = success.data;
				this.empCustomColumns = temp_custom_columns.map((ele) => {
					ele.name = 'column' + (temp_custom_columns.indexOf(ele) + 1);
					return ele;
				});
			}, (rejected) => {

			});

		this.dataService.getListData('listItemList/emp_reasons_for_exit', '')
			.subscribe((success) => {
				this.reasonForExitOptions = success.data;
			}, (rejected) => {

			});

		this.activatedRoute.params.subscribe((params: Params) => {
			this.permissionsObj.projectId = params['id'];
			// this.config.where.project_id = this.projectObj.projectId;
		});

		this.permissionsObj.isProjectPermissions['add'] = this.commonJs.checkAccess('employees', 'add', { 'baiPass': ['admin'] }, this.permissionsObj.projectId);
		this.permissionsObj.isProjectPermissions['delete'] = this.commonJs.checkAccess('employees', 'delete', { 'baiPass': ['admin'] }, this.permissionsObj.projectId);
		this.permissionsObj.isProjectPermissions['edit'] = this.commonJs.checkAccess('employees', 'edit', { 'baiPass': ['admin'] }, this.permissionsObj.projectId);
		this.permissionsObj.isProjectPermissions['view'] = this.commonJs.checkAccess('employees', 'view', { 'baiPass': ['admin'] }, this.permissionsObj.projectId);
		this.permissionsObj.isProjectPermissions['headcount_report'] = this.commonJs.checkAccess('headcount_report', 'view', { 'baiPass': ['admin'] });
		this.createColumns();
		/** Help modal show when you open from the  import modal window*/
		this.dataService.notifyObservable$.subscribe(res => {
			if (res.refresh) {
				this._import_opened = true;
				this._ng_import_type = 'employees';
				this.fromNotify = 'modalImport';
			}
		});
	}


	// open glbal image thumbnail viewer
	openImageModal(imageUrl: string): void {
		this.thumbnailModalService.openThumbnailModal(imageUrl);
	}

	// To transform date
	transformDate(value: string): string {
		if (!value) {
			// If the input date is blank or null, return an empty string
			return '';
		}
		const parts = value.split(' ');
		const datePart = parts[0].split('/');
		const timePart = parts[1];
		// Reformat the date to YYYY-MM-DD HH:mm:ss
		const formattedDate = `${datePart[2]}-${datePart[1]}-${datePart[0]} ${timePart}`;
		// Parse the reformatted date string into a JavaScript Date object
		const date = new Date(formattedDate);
		// Define the desired format for the output date
		const format = 'MMM dd, yyyy';
		// Use Angular's DatePipe to format the date
		const datePipe = new DatePipe('en-US');
		return datePipe.transform(date, format);
	}

	// To transform time
	transformTime(value: string): string {
		if (!value) {
			// If the input date is blank or null, return an empty string
			return '';
		}
		const parts = value.split(' ');
		const datePart = parts[0].split('/');
		const timePart = parts[1];
		// Reformat the date to YYYY-MM-DD HH:mm:ss
		const formattedDate = `${datePart[2]}-${datePart[1]}-${datePart[0]} ${timePart}`;
		// Parse the reformatted date string into a JavaScript Date object
		const date = new Date(formattedDate);
		// Define the desired format for the output date
		const format = 'h:mm:ss a';
		// Use Angular's DatePipe to format the date
		const datePipe = new DatePipe('en-US');
		return datePipe.transform(date, format);
	}

	/** Create Column */
	private createColumns(): void {
		this.isLoading = true;
		let cellTemplate: any = {};
		setTimeout(() => {
			cellTemplate = {
				thumbnail: this.thumbnail,
				idsT: this.idsT,
				manageBtns: this.manageBtns,
				hdrDateTemplate1: this.hdrDateTemplate1,
				hdrDateTemplate2: this.hdrDateTemplate2,
				hdrDateTemplate3: this.hdrDateTemplate3,
				name: this.name,
				emptyTableRow: this.emptyTableRow,
				status: this.status,
				mode: this.mode,
				dateOfJoining: this.dateOfJoining,
				dateOfBirth: this.dateOfBirth,
				exitDate: this.exitDate,
				hdrTpl: this.hdrTpl,
				hdrDropdownTpl: this.hdrDropdownTpl,
			};
			this.dataTableColumns = [];
			let tempColumn: any = columns('employees', cellTemplate);
			// this.dataTableColumns = columns('employees', cellTemplate);
			this.columnSet().then((response: any) => {
				console.log(response);
				if (response.status == 'success' && response.data.length != 0) {
					const rearrangedColumns = [];
					for (const item of response.data) {
						const matchingColumn = tempColumn.find(obj => obj.prop === item.prop && item.show);
						if (matchingColumn) {
							rearrangedColumns.push(matchingColumn);
						}
					}
					for (const obj of tempColumn) {
						if (!response.data.some(item => item.prop === obj.prop && item.show)) {
							rearrangedColumns.push(obj);
						}
					}
					tempColumn = rearrangedColumns;
					this.dataTableColumns = tempColumn.filter((obj: any) => {
						if (!obj.dynamicColumn) {
							return response.data.some(item => item.prop === obj.prop && item.show)
						} else {
							return obj
						}
					});

					// Getting custom column names
					this.empCustomColumns.forEach((ele) => {
						this.dataTableColumns.map((dbcolumn: any) => {
							if (dbcolumn.name == ele.name) {
								dbcolumn.name = ele.item_name;
								dbcolumn.searchPlaceholder = ele.item_name.charAt(0).toUpperCase() + ele.item_name.slice(1);
							}
						})
					});
				}
				else { // Default columns to show for newly logged in studio
					const columnsToShow = ['status', 'name', 'department', 'role_id', 'primary_department', 'secondary_department', 'phone', 'id'];
					this.dataTableColumns = tempColumn.map((column: any) => {
						if (columnsToShow.includes(column.prop)) {
							return column;
						} else {
							return null;
						}
					}).filter(column => column !== null);
				}
				this.isLoading = false;
			}, (err: any) => {
				console.warn("Error while fetching column set");
				this.isLoading = false;
			});
		});
	}


	/** Get column preference  */
	columnSet() {
		return new Promise((resolve, rejected) => {
			this.dataService.getListData('users' + '/employee_list_column/preferences', '')
				.subscribe((success) => {
					if (success.data != false) {
						this.datatableService.setParam(success.data, 'columns');
						this.preferenceColumns = success.data;
						let manageIdColumn = {
							"prop": "manageId",
							"name": "",
							"sortable": false,
							"draggable": false,
							"canAutoResize": true,
							"width": 70,
							"show": true,
							"frozenRight": true,
							"headerClass": "emptyHeaderCell",
							"cellClass": "text-center"
						};
						let actionIdColumn = {
							"prop": "id",
							"name": "Action",
							"sortable": false,
							"draggable": true,
							"canAutoResize": true,
							"width": 70,
							"frozenRight": true,
							"show": true,
							"headerClass": "action-btn-datatable",
							"cellClass": "action-button"
						};
						// console.log(this.preferenceColumns, this.preferenceColumns.includes(manageIdColumn));
						if (!this.preferenceColumns.includes(manageIdColumn)) {
							this.preferenceColumns.push(manageIdColumn);
						}
						if (!this.preferenceColumns.includes(actionIdColumn)) {
							this.preferenceColumns.push(actionIdColumn);
						}

						resolve(success);
					} else {
						resolve(success);
					}
				}, (rjt) => {
					rejected(rjt);
				});
		});
	}

	// on column sorted
	onSort(event) {
		this.table.offset = this.openedPage;
	}

	firstTimeLoad: boolean = false;
	onPageChange(e): void {
		this.openedPage = e.offset;
		this.pagePayload.page = e.offset * this.pageSize;
		this.config.start = this.pagePayload.page;
		if (this.firstTimeLoad) {
			this.table.offset = this.openedPage;
			this.dataService.pagePreferancePost({
				pageSize: this.pageSize,
				currentPage: this.config.start,
				pageIndex: this.table.offset,
				user_id: JSON.parse(localStorage.getItem('user')).id
			}, 'employee_list_page');
			this.getEmployeesList();
		} else {
			this.dataService.pagePreferanceGet('employee_list_page').then((res: any) => {
				if (res.data.length == undefined) {
					this.pageSize = res.data.pageSize;
					this.config.itemsPerPage = res.data.pageSize;
					this.config.start = res.data.currentPage;
					this.table.offset = res.data.pageIndex;
					this.getEmployeesList();
				} else {
					this.getEmployeesList();
				}
			}, (err: any) => {
				console.warn("Error while fetching page set")
			});
		}
		this.firstTimeLoad = true;
	}

	// TODO[Dmitry Teplov] wrap dynamic limit in a separate component.
	public onLimitChange(limit: any): void {
		this.changePageLimit(limit);
		this.table.limit = this.pageSize;
		this.table.recalculate();
		setTimeout(() => {
			if (this.table.bodyComponent.temp.length <= 0) {
				this.table.offset = Math.floor((this.table.rowCount - 1) / this.table.limit);
			}
		});
	}

	private changePageLimit(limit: any): void {
		this.pageSize = parseInt(limit, 10);
		this.dataService.pagePreferancePost({
			pageSize: this.pageSize,
			currentPage: this.config.start,
			pageIndex: this.table.offset
		}, 'employee_list_page');
		this.config.itemsPerPage = limit;
		this.getEmployeesList();
	}

	// on user event
	onUserEvent(event) {
		if (event.type == "click") {

			const row = event.row;
			const column = event.column;

			//  this.cellClick(row, column.prop);
		}
	}

	closeImport(event) {
		this._import_opened = false;
		this._loadImportSideWindow = false;

		if (this.fromNotify != 'modalImport') {
			this.getEmployeesList();
		}
	}

	search(event, column) {

		if (column == 'reporting_to') {
			this.dataService.getOtherData('getReportingTo', { 'query': event['query'] })
				.subscribe((success) => {

					// this.columns[index]['filtering']['tagOptions'] = success.data;
					this.reportingToOptions = success.data;
				});

		} else if (column == 'prod_person') {
			this.dataService.getOtherData('getProductionPerson', { 'query': event['query'] })
				.subscribe((success) => {

					// this.columns[index]['filtering']['tagOptions'] = success.data;
					this.prodPersonOptions = success.data;
				});
		} else if (column == 'role_id') {

			this.dataService.getOtherData('getRoles', { 'query': event['query'] })
				.subscribe((success) => {

					this.rolesOptions = success.data;
				});
		} else if (column == 'primary_department') {

			this.dataService.getOtherData('getPrimaryDept', { id: [0], 'query': event['query'] })
				.subscribe((success) => {
					// this.columns[index]['filtering']['tagOptions'] = success.data;
					this.primaryDeptOptions = success.data;

				}, (rejected) => {

				});
		} else if (column == 'secondary_department') {
			this.dataService.getOtherData('getSecondaryDept', { id: [0], 'query': event['query'] })
				.subscribe((success) => {
					// this.columns[index]['filtering']['tagOptions'] = success.data;
					this.secondaryDeptOptions = success.data;

				}, (rejected) => {

				});
		}
		else if (column == 'exit_reason') {
			if (this.reasonForExitOptions.length > 0) {
				this.reasonForExitOptions = [...this.reasonForExitOptions];
			} else {
				this.dataService.getOtherData('searchItemList', { list_name: 'emp_reasons_for_exit', 'query': event['query'] })
					.subscribe((success) => {

						// this.columns[index]['filtering']['tagOptions'] = success.data;
						this.reasonForExitOptions = success.data;
					}, (rejected) => {

					});
			}

		} else if (column == 'software_skills') {
			this.dataService.getOtherData('searchItemList', { list_name: 'emp_software_skills', 'query': event['query'] })
				.subscribe((success) => {
					// this.columns[index]['filtering']['tagOptions'] = success.data;
					this.skillOptions = success.data;

				}, (rejected) => {

				});
		} else if (column == 'gender') {
			this.genderOptions = this.genderOptions.map(o => { return o; });

		} else if (column == 'mode') {
			this.modeOptions = this.modeOptions.map(o => { return o; });

		} else if (column == 'vertical') {
			this.verticalOptions = this.verticalOptions.map(o => { return o; });

		} else if (column == 'attrition_type') {
			this.attritionTypeOptions = this.attritionTypeOptions.map(o => { return o; });

		} else if (column == 'status') {
			this.statusOptions = this.statusOptions.map((o) => { return o; });
		}
	}

	// When a filter is applied
	onColumnFilter(e, id: string): void {

		this.config.start = 0;

		// To not allow user to type invalid phone number
		if (id == 'phone') {
			if (isNaN(e.target.value)) {
				e.target.value = e.target.value.replace(/\D/g, '');
				return e.target.value;
			}
		}

		// To not allow user to type negative value
		if (id == 'age' && e.target.value < 0) {
			this.config.FilterByColumns['age'] = e.target.value = Math.abs(e.target.value);
			this.getEmployeesList();
			return e.target.value;
		}

		this.getEmployeesList();
	}

	selectedOptionChange(column, event, isMain = false, isTypeAhead = false, isClear = false) {

		this.config.start = 0;

		let onlyIds;
		if (isTypeAhead) {
			if (column == 'software_skills' || column == 'exit_reason') {
				//this.checkFilters[column] = ( event != '' && event != 'All' ) ? [event.item_name] : [];
				onlyIds = (event != '' && event != 'All') ? [event.item_name] : [];
				if (isMain) {
					this.checkFilters[column].push(event);
				} else {
					//this.checkFilters[column] = ( event != '' && event != 'All' ) ? [event.item_name] : [];
					this.checkFilters[column] = (event != '' && event != 'All') ? [{ id: event.item_name, name: event.item_name }] : [];
					this.filterAppliedOnFun(column, event, isClear);
				}
			} else {
				//this.checkFilters[column] = ( event != '' && event != 'All' ) ? [event.id] : [];
				onlyIds = (event != '' && event != 'All') ? [event.id] : [];
				if (isMain) {
					this.checkFilters[column].push(event);
				} else {
					//this.checkFilters[column] = ( event != '' && event != 'All' ) ? [event.id] : [];
					if (column == 'primary_department') {
						this.checkFilters[column] = (event != '' && event != 'All') ? [{ id: event.id, department: event.department }] : [];
					} else {
						this.checkFilters[column] = (event != '' && event != 'All') ? [{ id: event.id, name: event.name }] : [];
					}
					this.filterAppliedOnFun(column, event, isClear);
				}
			}

		} else {

			this.checkFilters[column] = (event != '' && event != 'All') ? [event] : [];
			onlyIds = (event != '' && event != 'All') ? [event] : [];
		}
		this.advanceFilterColumns = column;
		this.advanceFilterColumnsValue = event;
		// this.advanceFilterColumnsValue=event;


		if (!isMain) {
			setTimeout(() => {
				//this.columns.push({title: 'new', name: 'name', width: '10%' });

				this.config.where[column] = onlyIds;
				this.config.start = 0;
				this.getEmployeesList();
			}, 2);
		}
	}

	openSideWindow(mode, id, is_role, is_exit: any = false) {
		if (is_role != undefined && is_role == true) {
			this._role_opened = true;
		} else {
			if (id != undefined && id != '') {
				this._ng_sidebar_data['userid'] = id;
			}
			this._ng_sidebar_data['mode'] = mode;
			this._ng_sidebar_data['is_exit'] = is_exit;
			this._opened = true;
		}
	}


	// On special filter opened
	openFitlerWindow() {
		let that = this;
		lodashArray.forEach(that.checkFilters, function (value, key) {
			if (key != 'joining_date' && key != 'gender') {
				lodashArray.forEach(value, function (v, k) {
					that.selectedValue[key][v] = true;
					//that.selectedValue[key]['data'] = {'id':v, 'name': ''};
				});
			}
		});
		this._filter_opened = true;
	}

	// On special filter applied
	onChange(column, classId, flag) {
		if (flag) {
			this.checkFilters[column].push(classId);
			//$('.'+column+'-th').addClass('filter-activated');
		} else {
			const index: number = this.checkFilters[column].indexOf(classId);
			this.checkFilters[column].splice(index, 1);
			//$('.'+column+'-th').removeClass('filter-activated');
		}
		this.advanceFilterColumns.push(column);
		this.advanceFilterColumnsValue = flag;
		// this.filterAppliedOnFun(column, flag);
		/*setTimeout(() => {
			this.config.where[column] = this.checkFilters[column];
			this.onChangeTable(this.config);
			}, 2);*/
	}

	/**close modeal event */
	closeManageCol(value) {
		if (value.reload) {
			this.columnSet().then((response: any) => {
				if (response.status == 'success') {
					this.createColumns();
				}
			}, (err: any) => {
				console.warn("Error while fetching column set")
			});
		}
	}

	public actionClick(action: string, row: any) {
		let id: number = row.id;
		let client_code: number = row.client_code;
		switch (action) {
			case ("actionDelete"):
				this.confirm(id, row.name, row.emp_code_final);
				break;
			case ("actionEditSideWindow"):
				this.openSideWindow('edit', id, false);
				break;
			case ("actionViewSideWindow"):
				this.openSideWindow('view', id, false);
				break;
			case ("actionSideWindow"):
				this.openSideWindow('manage', id, false);
				break;
			case ("actionApprove"):
				this.changeStatus(1, id, 'Approve', client_code);
				break;
			case ("actionSuspend"):
				this.changeStatus(2, id, 'Suspend', client_code);
				break;
			case ("exchange"):
				this.openSideWindow('Transfer', id, false);
				break;
			case ("Mode"):
				this.openSideWindow('Mode', id, false);
				break
			case ("Exit"):
				this.openSideWindow('Exit', id, false);
				break
			case ("Active"):
				this.openSideWindow('Active', id, false);
				break
			default:
				break;
		}
	}

	confirm(id, name, ecode) {
		const config = {
			initialState: {
				title: 'Delete Employee',
				message: "Are you sure you want to delete " + name + " (" + ecode + ")?",
			},
			class: 'modal-md custom-modal-css',
			centered: true,
			animated: false
		};
		this.modalRef = this.modalService.show(ConfirmationPopupComponent, config);
		// Subscribe to the onConfirmAction event
		this.modalRef.content.onConfirmAction.subscribe(() => {
			// Handle the confirmation logic here
			this.dataService.deleteData('users' + 'Destroy', { id })
				.subscribe((success) => {
					if (success.status == 'error') {
						this.toastrService.notify('error', '', success.message);
					} else {
						this.toastrService.notify('success', '', success.message);
						this.isLoading = true;
						this.getEmployeesList();
					}
				}, (rejected) => {

				});
		});
	}

	changeStatus(status, id, action, client_code) {
		let actionData = {};
		actionData['status'] = status;
		actionData['userid'] = id;
		actionData['client_code'] = client_code;

		let that = this;
		swal.fire({
			title: action + " Employee ",
			text: "Are you sure you want to " + action + " this Employee ?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#DD6B55",
			confirmButtonText: action,
			cancelButtonText: "Cancel!",
		}).then((result) => {
			if (result.value) {
				that.dataService.changeAction('changeAction', actionData)
					.subscribe((success) => {
						this.isLoading = true;
						this.getEmployeesList();
					}, (rejected) => {

					});
			} else {
				//console.log('cancelled');
			}
		}
		)

	}

	// rearangeColumn(columnsShowHide) {
	// 	let tempColu = [];
	// 	let that = this;

	// 	lodashArray.forEach(that.dataTableColumns, function (mainCol, key) {
	// 		const index = columnsShowHide.findIndex((col) => {
	// 			return col.name == mainCol.name;
	// 		});

	// 		if (index !== -1) {
	// 			if (!columnsShowHide[index]['show']) {

	// 				if (mainCol.className != undefined) {
	// 					that.dataTableColumns[key]['className'] = mainCol.className + ' hide-this';
	// 				} else {
	// 					that.dataTableColumns[key]['className'] = 'hide-this';
	// 				}
	// 			} else {
	// 				if (mainCol.className != undefined) {
	// 					that.dataTableColumns[key]['className'] = mainCol.className.replace(/hide-this/g, '');
	// 				} else {
	// 					that.dataTableColumns[key]['className'] = '';
	// 				}
	// 			}
	// 			tempColu[index] = that.dataTableColumns[key];
	// 		} else {
	// 			tempColu[key] = that.dataTableColumns[key];
	// 		}
	// 	});

	// 	this.dataTableColumns = tempColu.filter(obj => Object.keys(obj).length !== 0);
	// }

	// Manage col sidebar open
	openManageColWindow() {
		this._ng_sidebar_manage_col_data['column'] = columns('employees');
		this._ng_sidebar_manage_col_data['key'] = 'employee_list_column';
		this._manage_col_opened = true;
	}

	// To export data
	exportData() {
		let selectedKeys = ["prop"]; // Define the selected keys
		let newArray = this.dataTableColumns.map(obj => {
			let newObj = {};
			selectedKeys.forEach(key => {
				newObj['name'] = obj.prop;
				newObj["title"] = obj.name;
				newObj['show'] = true;
				newObj['isManageable'] = false;

			});
			return newObj;
		});
		// let filteredArray = newArray.filter((item: any) => item.name !== "image");
		this.config.columns = newArray;
		this.config.export = true;
		this.gridService.openProgressModal();
		this.getEmployeesList();

	}

	public openModalWithComponent() {
		let data: any = {
			from: 'EmployeeComponent',
			header: 'Employees'
		}
		this.dataService.setImportFrom(data);
		this.bsModalRef = this.modalService.show(ExcelmapimportComponent, {
			backdrop: 'static',
			keyboard: false
		});
		this.bsModalRef.content.event.subscribe((res: any) => {
			this._import_opened = false;
			this._loadImportSideWindow = false;

			this.rows = [];
			// this.skeleton = true;
			this.getEmployeesList();

		});
	}

	onOpened(loadWindow) {
		if (loadWindow == 'role') {
			this._loadRoleSideWindow = true;
		} else if (loadWindow == 'permission') {
			this._loadPermissionSideWindow = true;
		} else if (loadWindow == 'manage_col') {
			this._loadManageColSideWindow = true;
		} else if (loadWindow == 'filter') {
			this._loadFilterSideWindow = true;
		} else if (loadWindow == 'import') {
			this._loadImportSideWindow = true;
		} else if (loadWindow == 'activitylog') {
			this._loadActivityLogSideWindow = true;
		} else {
			this._loadSideWindow = true;
		}
	}

	onClosed(status) {
		if (status == 'role') {
			this._loadRoleSideWindow = false;
		} else if (status == 'manage_col') {
			this._loadManageColSideWindow = false;
		} else if (status == 'filter') {
			// console.log("this.submitted", this.submitted)
			if (this.submitted != true) {
				this.clearGeneralFilter()
			}
			this._loadFilterSideWindow = false;
		} else if (status == 'permision') {
			this._loadPermissionSideWindow = false;
		}
		else if (status == 'activitylog') {
			this._loadActivityLogSideWindow = false;
		} else {
			this._loadSideWindow = false;
		}
	}

	// On create employee modal closed
	closeCreateUser() {
		this.rows = [];
		this.getEmployeesList();
	}

	onSubmit(form: NgForm) {
		this.submitted = true;
		let onlyIds = {};
		var that = this;
		if (form.value.role_id) {
			onlyIds['role_id'] = [];
			if (this.checkFilters['role_id'].length == 0) {
				this.checkFilters['role_id'] = [];
			} else {
				//onlyIds['role_id'].push(this.checkFilters['role_id'][0]['id']);
			}
			lodashArray.forEach(form.value.role_id, function (val) {
				that.checkFilters['role_id'].push({ id: val['id'], name: val['name'] });
				onlyIds['role_id'].push(val['id']);
			});
		}

		if (form.value.primary_department) {
			onlyIds['primary_department'] = [];
			if (this.checkFilters['primary_department'].length == 0) {
				this.checkFilters['primary_department'] = [];
			} else {
				//onlyIds['primary_department'].push(this.checkFilters['primary_department'][0]['id']);
			}
			lodashArray.forEach(form.value.primary_department, function (val) {
				that.checkFilters['primary_department'].push({ id: val['id'], name: val['department'] });
				onlyIds['primary_department'].push(val['id']);
			});
		}

		if (form.value.secondary_department) {
			onlyIds['secondary_department'] = [];
			if (this.checkFilters['secondary_department'].length == 0) {
				this.checkFilters['secondary_department'] = [];
			} else {
				//onlyIds['secondary_department'].push(this.checkFilters['secondary_department'][0]['id']);
			}
			lodashArray.forEach(form.value.secondary_department, function (val) {
				that.checkFilters['secondary_department'].push({ id: val['id'], name: val['department'] });
				onlyIds['secondary_department'].push(val['id']);
			});
		}
		if (form.value.reporting_to) {
			onlyIds['reporting_to'] = [];
			if (this.checkFilters['reporting_to'].length == 0) {
				this.checkFilters['reporting_to'] = [];
			} else {
				//onlyIds['reporting_to'].push(this.checkFilters['reporting_to'][0]['id']);
			}
			lodashArray.forEach(form.value.reporting_to, function (val) {
				that.checkFilters['reporting_to'].push({ id: val['id'], name: val['name'] });
				onlyIds['reporting_to'].push(val['id']);
			});
		}

		if (form.value.prod_person) {
			onlyIds['prod_person'] = [];
			if (this.checkFilters['prod_person'].length == 0) {
				this.checkFilters['prod_person'] = [];
			} else {
				//onlyIds['prod_person'].push(this.checkFilters['prod_person'][0]['id']);
			}
			lodashArray.forEach(form.value.prod_person, function (val) {
				that.checkFilters['prod_person'].push({ id: val['id'], name: val['name'] });
				onlyIds['prod_person'].push(val['id']);
			});
		}

		if (form.value.software_skills) {
			onlyIds['software_skills'] = [];
			if (this.checkFilters['software_skills'].length == 0) {
				this.checkFilters['software_skills'] = [];
			} else {
				//onlyIds['software_skills'].push(this.checkFilters['software_skills'][0]['item_name']);
			}
			lodashArray.forEach(form.value.software_skills, function (val) {
				that.checkFilters['software_skills'].push({ id: val['item_name'], name: val['item_name'] });
				onlyIds['software_skills'].push(val['item_name']);
			});
		}
		onlyIds['status'] = this.checkFilters['status'];
		onlyIds['gender'] = this.checkFilters['gender'];
		onlyIds['mode'] = this.checkFilters['mode'];
		onlyIds['vertical'] = this.checkFilters['vertical'];
		onlyIds['type'] = this.checkFilters['type'];
		onlyIds['exit_reason'] = this.checkFilters['exit_reason'];
		onlyIds['joining_date'] = this.checkFilters['joining_date'];

		onlyIds['activeEmp'] = '';
		if ($('#activeEmpDateValue-from').val() != '' && $('#activeEmpDateValue-from').val() != undefined) {
			onlyIds['activeEmp'] += moment($('#activeEmpDateValue-from').val()).format('YYYY-MM-DD');
			this.activeEmpDateValue.from = moment($('#activeEmpDateValue-from').val()).format(this.settings.date_format);
			this.filterAppliedOnFun('joining_date', this.activeEmpDateValue.from, false);
		}
		if ($('#activeEmpDateValue-to').val() != '' && $('#activeEmpDateValue-to').val() != undefined) {
			onlyIds['activeEmp'] += '|' + moment($('#activeEmpDateValue-to').val()).format('YYYY-MM-DD');
			this.activeEmpDateValue.to = moment($('#activeEmpDateValue-to').val()).format(this.settings.date_format);
		}
		else if (this.activeEmpDateValue.from != null && this.activeEmpDateValue.from != '' && this.activeEmpDateValue.from != undefined) {
			onlyIds['activeEmp'] += '|' + moment().format('YYYY-MM-DD');
			this.activeEmpDateValue.to = moment().format(this.settings.date_format);
		}
		this.filterAppliedOnFun(this.advanceFilterColumns, this.advanceFilterColumnsValue, false);
		this.config.where = onlyIds;
		this.getEmployeesList();
		this._filter_opened = false;
	}

	refreshFilter() {
		this.getEmployeesList();
	}

	clearFilter() {
		this.headerDropdowns = {
			status: '',
			reporting_to: '',
			prod_person: '',
			role_id: '',
			primary_department: '',
			secondary_department: '',
			exit_reason: '',
			software_skills: '',
			gender: '',
			mode: '',
			vertical: '',
			attrition_type: '',
		};

		this.datepickerDefault = { joining_date: '', dob: '', exit_date: '' };
		let that = this;
		lodashArray.forEach(that.dataTableColumns, function (mainCol, key) {
			if (mainCol.name == 'joining_date' || mainCol.name == 'dob' || mainCol.name == 'exit_date') {
				let newDate = moment();
				that.picker.datePicker.setStartDate(newDate);
				that.picker.datePicker.setEndDate(newDate);
			}

			if (that.dataTableColumns[key]['filtering'] != undefined) {
				that.dataTableColumns[key]['filtering']['filterString'] = '';
			}
		});
		$('.filter-input').val('');

		this.config.where = {};
		this.config.FilterByColumns = {};


		this.filterAppliedOn = [];
		this.submitted = false;
		this.clearGeneralFilter();
		// this.router.navigate(['/{{ clientCodePrefix }}/employees']);
		this.getEmployeesList();
	}

	clearGeneralFilter() {
		$('.column-th-lable').removeClass('filter-activated');
		$('.filter-check').prop('checked', false);
		// this.filterAppliedOn = [];
		this.checkFilters = { 'status': [], 'gender': [], 'mode': [], 'role_id': [], 'vertical': [], 'type': [], 'primary_department': [], 'secondary_department': [], 'reporting_to': [], 'prod_person': [], 'software_skills': [], 'attrition_type': [], 'exit_reason': [] };
		this.selectedValue = { 'status': [], 'gender': [], 'mode': [], 'role_id': [], 'vertical': [], 'type': [], 'primary_department': [], 'secondary_department': [], 'reporting_to': [], 'prod_person': [], 'software_skills': [], 'attrition_type': [], 'exit_reason': [] };
		this.config.where['status'] = [];
		this.config.where['gender'] = [];
		this.config.where['mode'] = [];
		this.config.where['vertical'] = [];
		this.config.where['role_id'] = [];
		this.config.where['type'] = [];
		this.config.where['primary_department'] = [];
		this.config.where['secondary_department'] = [];
		this.config.where['reporting_to'] = [];
		this.config.where['prod_person'] = [];
		this.config.where['software_skills'] = [];
		this.config.where['attrition_type'] = [];
		this.config.where['exit_reason'] = [];

		this.activeEmpDateValue = {};

		this.rangePicker = {
			singleDatePicker: false,
			autoUpdateInput: false,
			showDropdowns: true,
			autoApply: true,
			drops: 'down',

			locale: { format: this.settings.date_format },
		}
		//this.rangePicker['rangePicker'] = moment().format('DD MMM YYYY');

		this._filter_opened = false;
		this.firstTimeLoad ? '' : this.getEmployeesList();
		this.advanceFilterColumns = [];
	}

	selectedDatepicker(column, $event: any, type) {
		let startDateValue = moment($event.start).format('YYYY-MM-DD');
		let searchValue = '';
		if (type == 'daterangepicker') {
			let endDateValue = moment($event.end).format('YYYY-MM-DD');
			if (startDateValue != '' && startDateValue != null && startDateValue != 'Invalid date' && endDateValue != '' && endDateValue != null && endDateValue != 'Invalid date') {
				searchValue = startDateValue + '|' + endDateValue;
			}
			this.datepickerDefault[column] = `${moment($event.start).format(this.settings.date_format)} - ${moment($event.end).format(this.settings.date_format)}`;
		} else {

			if (startDateValue != '' && startDateValue != null && startDateValue != 'Invalid date') {
				searchValue = startDateValue;
			}
		}

		this.filterAppliedOnFun(column, searchValue, false);

		if (searchValue != '') {
			this.checkFilters[column] = searchValue;
			setTimeout(() => {
				//this.columns.push({title: 'new', name: 'name', width: '10%' });
				// this.page = 1;
				this.config.where[column] = this.checkFilters[column];
				this.getEmployeesList();
			}, 2);
		}
	}

	handleInput(column: string, newValue: any) {
		console.log("test")
		if (!newValue) {
			// Clear the date range
			this.config.where[column] = [];
			this.datepickerDefault[column] = '';
			this.rangePicker['startDate'] = null; // Clear the start date
			this.rangePicker['endDate'] = null; // Clear the end date
			this.getEmployeesList();
		}
	}



	filterAppliedOnFun(column, event, isClear) {
		if (Array.isArray(column)) {
			lodashArray.forEach(column, function (colVal) {
				if (event != '') {
					$('.' + colVal + '-th').addClass('filter-activated');
					if (colVal == 'type') {
						$('.role_id-th').addClass('filter-activated');
					}
				} else {
					if (colVal == 'type') {
						$('.role_id-th').removeClass('filter-activated');
					}
					$('.' + colVal + '-th').removeClass('filter-activated');
				}
			});
		} else {
			if (event != '') {
				$('.' + column + '-th').addClass('filter-activated');
				if (column == 'type') {
					$('.role_id-th').addClass('filter-activated');
				}
			} else {
				$('.' + column + '-th').removeClass('filter-activated');
				if (column == 'type') {
					$('.role_id-th').removeClass('filter-activated');
				}
			}
		}

		const filterAppliedOnIndex: number = this.filterAppliedOn.indexOf(column);
		if (filterAppliedOnIndex !== -1 && (event == '' || event == 'All')) {
			this.filterAppliedOn.splice(filterAppliedOnIndex, 1);
		} else if (filterAppliedOnIndex == -1 && event != '') {
			this.filterAppliedOn.push(column);
		}
	}

	// To get Employeeslist
	getEmployeesList() {
		this.isLoading = true;
		this.gridService.postData('usersList', this.config).subscribe((res: any) => {
			if (!this.isLoading) {
				this.isLoading = true;
			}
			if (this.config.export) {
				window.location.href = res.data;
				this.isLoading = false;
				setTimeout(() => {
					this.gridService.myEventEmitterForProgressModal.emit(true);
				}, 200);
				this.config.export = false;
			} else {
				this.totalItems = res.totalElements;
				this.totalCount = res.totalCount;
				this.tempArray = [...res.data];
				this.rows = this.tempArray;
				//   this.rows.length<1?this.rows=[{emptyMessage : 'No data found!'}]:this.rows=this.tempArray;
				this.config.export = false;
				this.config.columns = {};
				this.isLoading = false;
			}

		});
		this.gridService.progressFunction();
	}

  // For getting first two letters of name
  getInitials(inputString: string): string {
    const words = inputString.trim().split(' ');
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      const secondInitial = words[1].charAt(0).toUpperCase();
      return `${firstInitial}${secondInitial}`;
    } else if (words.length === 1) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      return `${firstInitial}`;
    } else {
      return '';
    }
  }

  // On datatable body scrolled
  onScrolled(event){

    // console.log(event);
    if(event['offsetY'] && (event['offsetY'] != this.previousOffsetY)){
      this.isFullScreen = event['offsetY']>this.previousOffsetY ? true : false;
      this.dataService.fullScreenView.next(this.isFullScreen);

      this.previousOffsetY = event['offsetY'];
    }
  }

}
