import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
import * as _ from "lodash";
import { SelectItem } from 'primeng/primeng';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
declare var $: any;

export interface camArrayInterface {
	name: string,
	id: any,
	frames: Array<{ frameIn: number, frameOut: number }>
}

@Component({
	selector: 'create-shot',
	templateUrl: './create-shot.component.html',
	styleUrls: ['./create-shot.component.css']
})
export class CreateShotComponent implements OnInit {

	entityName = 'Shots';
	entityNameSingular = 'Shot';
	apiRoutePrefix = 'shots';
	dirName = 'shots';

	clientCode = localStorage.getItem('client_code');

	@Input('attr.data') dataHtml: any;
	@ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
	@ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
	@Output() closeSideWindow = new EventEmitter();
	@Output() closeCreateShot = new EventEmitter();
	repeatedFrame: number = null;
	camArray: Array<camArrayInterface> = [];

	public singlePicker = {
		singleDatePicker: true,
		autoUpdateInput: true,
		showDropdowns: true,
		autoApply: true,
		drops: 'down',
		locale: {
			format: 'DD MMM YYYY'
		}
	}

	data: any;
	myform: any;
	onClose: any;
	shots: { [index: string]: any } = {};
	haveDepts: boolean = false;
	isEpisodicSeries: boolean = false
	url: any;
	preview_src: string = '';
	image_name: string;
	image_type: string;
	image_old: string;

	errors: { [index: string]: any } = {};
	focused: string = '';
	SaveButtonText: string = 'SAVE';
	isMessgaeMode: any;
	settingsAll: any = JSON.parse(localStorage.getItem('settings'));
	projectId = '';//this.commonJs.getLocalStorage('projectId');

	type: Array<any> = this.appConfigs.shot_subtype;
	complexity: Array<any> = this.appConfigs.sht_ast_complexity;

	status = [{ "id": 1, "name": "Active" }, { "id": 0, "name": "OOP" }];
	assets: SelectItem[];
	sequence_id: Array<any> = [];
	episode_id: Array<any> = [];
	uploadUrl = this.commonJs.getUploadUrl();
	isStatusDisabled: boolean = true;
	isSaveDisabled: boolean = false;

	dropdownSettings: any = {};
	dropdownSettingsSingle: any = {};
	dropdownSettingsDepartment: any = {}

	shotDepartmets: Array<any>;

	isMultipleCameras: boolean = false;
	public files: NgxFileDropEntry[] = [];

  selectedBidType: {name:string} = {name:'Day'};
  bidTypes:Array<any> = [ {name:'Day'},{name:'Hour'}];

	// ! Constructor

	constructor(
		private dataService: DataService,
		private toastrService: ToastrService,
		public commonJs: CommonJs,
		public appConfigs: AppConfigs,
		public cd: ChangeDetectorRef
	) { }

	// ! NgonInit
	ngOnInit() {
		if (this.dataHtml != undefined) {
			this.data = this.dataHtml;
		}
		this.projectId = this.data.projectId;
		$.AdminBSB.input.activate();
		this.getShotDepartments();

		this.subngOnInit();

		this.getAssetsList();

		this.dropdownSettings = {
			singleSelection: false,
			idField: "id",
			textField: "code",
			selectAllText: "Select All",
			unSelectAllText: "UnSelect All",
			itemsShowLimit: 10,
			allowSearchFilter: true,
			noDataAvailablePlaceholderText: 'No data available',
			searchPlaceholderText: 'Search assets',
			maxHeight: 90,
			autoPosition: false,
		};

		this.dropdownSettingsSingle = {
			singleSelection: true,
			idField: "asset_shot_department_id",
			textField: "department_name",
			selectAllText: "Select All",
			unSelectAllText: "UnSelect All",
			itemsShowLimit: 10,
			allowSearchFilter: true,
			noDataAvailablePlaceholderText: 'No data available',
			searchPlaceholderText: 'Search final department',
			maxHeight: 90,
			autoPosition: false,
		};

		this.dropdownSettingsDepartment = {
			singleSelection: false,
			idField: "asset_shot_department_id",
			textField: "department_name",
			selectAllText: "Select All",
			unSelectAllText: "UnSelect All",
			// itemsShowLimit: 2,
			allowSearchFilter: true,
			noDataAvailablePlaceholderText: 'No data available',
			searchPlaceholderText: 'Search department',
			maxHeight: 90,
			autoPosition: false,
		};

	}


	// To add fields in formArray for frames
	onAddFrames(camIndex, frameIndex, framein, frameout) {
		this.camArray[camIndex].frames.push({ frameIn: framein, frameOut: frameout });
	}

	// To add camera
	onAddCam(type, camId?: string) {
		let newCamName = this.camArray.length == 0 ? (1) : (JSON.parse(this.camArray[this.camArray.length - 1].name.replace("cam", "")) + 1);
		type == 'manualAdd' ? this.camArray.push({ name: 'cam' + newCamName, id: newCamName, frames: [{ frameIn: null, frameOut: null }] }) : this.camArray.push({ name: 'cam' + camId, id: camId, frames: [] });
	}

	// To remove frame
	onRemoveFrames(camIndex, frameIndex) {
		// //console.log(camIndex, frameIndex);
		this.camArray[camIndex].frames.splice(frameIndex, 1);
		this.sumAllFrames();
	}

	onItemSelect(item: any) {
	}
	onSelectAll(items: any) {
	}

	//! Sub NgOninit
	subngOnInit() {
		this.shots = {
			project_id: this.projectId,
			description: '',
			status: 1,
			assets: '',
			complexity: '',
			sequence_number: '',
			no_of_frames: '',
			image: '',
			image_name: '',
			image_old: '',
			image_display_name: '',
			departments: '',
			final_department: ''
		}
		this.getProjectsById(this.projectId);
		// if (this.data.mode == 'create') {
		// this.camArray = [{ name: 'cam1', id: 1, frames: [{ frameIn: null, frameOut: null }] }];
		// this.onAddCam('manualAdd');
		// To already add one frames-pair
		// this.onAddFrames(0,0,null, null);
    if(this.data.mode!='edit'){

      this.dataService.getListData('getLastRecordProject', { 'table': 'sequences', 'project_id': this.projectId, 'isActive': this.data.isActive })
        .subscribe((success) => {
          if (success.data != null) {
            let eId;
            if (this.data.sequence && this.data.sequence_id) {
              eId = this.data.sequence_id;
            } else {
              eId = success.data.id;
            }

            this.dataService.getListData('getLastRecordProjectSequence', { 'table': this.apiRoutePrefix, 'project_id': this.projectId, 'sequence_id': eId })
              .subscribe((success1) => {
                if (this.data.sequence) {
                  if (success1.data != null) {
                    this.dataService.getDataById('getSequenceById/' + eId)
                      .subscribe((success2) => {
                        this.shots.sequence_id = { "id": success2.data.id, "number": success2.data.number + ' - ' + success2.data.name, "episode_id": success2.data.episode_id };

                      });
                  } else {
                    this.dataService.getDataById('getSequenceById/' + eId)
                      .subscribe((success2) => {
                        this.shots.sequence_id = { "id": success2.data.id, "number": success2.data.number + ' - ' + success2.data.name, "episode_id": success2.data.episode_id };

                      });
                  }
                } else {
                  this.shots.sequence_id = { "id": success.data.id, "number": success.data.number + ' - ' + success.data.name, "episode_id": success.data.episode_id };

                }
                if (success1.data != null) {
                  this.shots.shot_number = this.appConfigs.autoincrementStr(success1.data.shot_number);
                } else {
                  this.shots.shot_number = "shot1";
                }
              }, (rejected) => {

              });

          }
        }, (rejected) => {

        });
    }
		// }

		if (this.data.shotid && (this.data.mode == 'edit' || this.data.mode == 'view')) {

      this.getShotDepartments().then((res: any) => {
				this.focused = "focused";
				this.dataService.getDataById(this.apiRoutePrefix + '/' + this.data.shotid + '/edit')
					.subscribe((success) => {
						this.shots = success.data;
						const departments: any = [];
						this.shotDepartmets.filter((res: any) => {
							this.shots.departments.filter((re: any) => {
								if (res.asset_shot_department_id == re) {
									departments.push(res);
								}

								if (res.asset_shot_department_id == this.shots.final_department) {
									this.shots.final_department = [];
									this.shots.final_department.push(res);
								}
							});
						});

            if(!this.shots.final_department){
              this.errors.final_department = 'Final Department must be Set.';
            }


						this.shots.departments = departments;

						if (this.shots.image) {
							this.image_old = this.shots.image;
							let fileExtension = this.shots.image.split('.').pop();
							if (fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg' && fileExtension != 'gif') {
								this.preview_src = './assets/images/default/attechment.png';
							} else {
								this.preview_src = this.uploadUrl + this.dirName + '/' + this.shots.image;
							}
						}
						//this.isStatusDisabled = this.shots.status;

						if (this.shots.image_display_name) {
							this.image_name = this.shots.image_display_name;
						}

						// // Adding frameIns & frameOuts for edit mode
						// this.testArrayForEdit.forEach((cam, index) => {

						// 	//console.log("index>>>>.", index);

						// 	this.onAddCam('');
						// 	for (let j = 0; j < cam.frames.length; j++) {
						// 		this.onAddFrames(index, '', cam.frames[j].frameIn, cam.frames[j].frameOut);
						// 	}
						// });

						if (this.shots.shot_frame_in_out.length > 0) {
							this.shots.shot_frame_in_out.forEach((cam: any, index: any) => {
								const arrayOfObjects = cam.frame_in.map((currentValue, index) => {
									return {
										frameIn: currentValue,
										frameOut: cam.frame_out[index],
									}
								})
								if (cam.frame_in.length > 0 && cam.frame_out.length > 0) {
									this.onAddCam('', cam.camera_name);
								}
								for (let j = 0; j < arrayOfObjects.length; j++) {
									this.onAddFrames(index, '', arrayOfObjects[j].frameIn, arrayOfObjects[j].frameOut);
								}
								//console.log(arrayOfObjects);
							});
						}
						if (this.shots.shot_frame_in_out.length > 0) {
							this.sumAllFrames();
						}
					}, (rejected) => {

					});
			});


		}
	}


	// ! Get Project Id

	getProjectsById(id) {
		// this.projectId = id;
		// this.dataService.getDataById('getProjectById/' + id)
		// 	.subscribe((success) => {
		this.isEpisodicSeries = JSON.parse(localStorage.getItem('localStorageProjectData')).episodic_series;
		console.log(this.isEpisodicSeries)
		// }, (error) => {
		// 	//console.log(error);
		// });
	}

	// ! Get assets list

	getAssetsList() {
		this.dataService.getListData('assetsItems', { project_id: this.projectId, 'shotId': this.shots.id })
			.subscribe((success) => {
				this.assets = success.data;
			}, (rejected) => {

			});
	}

	search(query, column) {
		if (column == 'assets') {
			this.dataService.getListData('assetsItems', { project_id: this.projectId, 'shotId': this.shots.id })
				.subscribe((success) => {
					this.assets = success.data;
				}, (rejected) => {

				});
		} else if (column == 'complexity') {
			this.complexity = this.appConfigs.sht_ast_complexity.map(v => { return v });
		} else if (column == 'type') {
			this.type = this.appConfigs.shot_subtype.map(v => { return v });
		}
		else if (column == 'sequence_id') {
			this.dataService.getListData('sequencesItems', { project_id: this.projectId, 'query': query['query'], column: 'seasonEpisode' })
				.subscribe((success) => {
					this.sequence_id = success.data;
				}, (rejected) => {

				});
		} else if (column == 'bidType') {
      this.bidTypes = [...this.bidTypes];
    }

	}


	/** Get shot departments */
	getShotDepartments() {
		return new Promise((resolve, reject) => {
			this.dataService.getListData('projectDepartmentList', { 'project_id': this.projectId, 'type': 'Shot', 'fromAssetShot': true })
				.subscribe((success) => {
					this.shotDepartmets = success.data;
					console.log("this.shotDepartmets????????????", this.shotDepartmets)
					resolve("Document uploaded!")
				});
		});
	}


	onSubmit(form: NgForm) {
		console.log(form.value)
		this.SaveButtonText = 'SAVING';
		this.isSaveDisabled = true;
		if (form.value.sequence_id) {
			form.value.sequence_id = this.shots.sequence_id.id;
			form.value.episode_id = this.shots.sequence_id.episode_id;
		}
		if (form.value.complexity) {
			form.value.complexity = this.shots.complexity.id;
		}

    if (form.value.bid_days) {
      form.value.bid_days = (this.selectedBidType['name'] && this.selectedBidType['name'] =='Hour')?(form.value.bid_days/8):form.value.bid_days;
    } else {
      form.value.bid_days = 0;
    }

		form.value.no_of_frames = this.shots.no_of_frames
		// if(form.value.type){
		// 	form.value.type = this.shots.type.id;
		// }
		form.value.type = "Shot";
		if (form.value.assets && form.value.assets != undefined) {
			var arr = "";
			_.forEach(this.shots.assets, function (value) {
				arr += "|";
				arr += value['id'];
				arr += "|";
				arr += ",";
			});
			form.value.assets = arr.slice(0, -1);
		}

		if (form.value.final_department.length > 0) {
			var fl: any;
			fl = this.shotDepartmets.find(value => value.asset_shot_department_id === form.value.final_department[0].asset_shot_department_id);
			form.value.final_department_key = fl.dtId ? 'dt_id' : 'department_id';
			form.value.final_department = fl.asset_shot_department_id;
		} else {
			form.value.final_department = '';
		}
		if (form.value.departments) {
			var dp: any = [];
			_.forEach(this.shotDepartmets, function (v1: any) {
				_.forEach(form.value.departments, function (v2: any) {
					if (v1.asset_shot_department_id === v2.asset_shot_department_id) {
						dp.push(v1.asset_shot_department_id);
					}
				});
			});
			form.value.departments = dp;
		}
		if (this.url != undefined) {
			form.value.image = this.url;
		}
		form.value.image_name = this.image_name;

		form.value.project_id = this.projectId;

		// To give camera name in proper sequence according to index
		// this.camArray.map((cam, index)=> { let camNumber = index +1; cam.name = 'cam' + camNumber });

		let totalFrames = 0;
		let validCamArray = this.camArray.map((cam) => {
			if (cam.frames.length > 0) {

				cam.frames = cam.frames.filter((fr) => {

					// will get only frame inputs of cameras whose frame-In/frame-Out are not empty
					if (fr.frameIn != null && fr.frameOut != null) {
						// will get total frameIn & frameOut differances at the end
						totalFrames = totalFrames + (fr.frameOut - fr.frameIn);
						return fr;
					}
				})
				{ return cam }

			}
		});
		//console.log(totalFrames);
		form.value.totalFrames = totalFrames;

		//console.log(validCamArray);
		form.value.camArray = validCamArray;

		form.value.departments = [...new Set(form.value.departments)];
		// form.value.departments = form.value.departments.filter((el, i, a) => i === a.indexOf(el));

		//this.Activitylog.generateKeyPair(form);
		if (this.data.shotid && this.data.mode == 'edit') {

			form.value.id = this.data.shotid;
			form.value.image_old = this.image_old;


			this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.shotid, form.value)
				.subscribe((success) => {

					// Save effect within button
					this.SaveButtonText = 'SAVED';
					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.isSaveDisabled = false;

				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
					}
					this.SaveButtonText = 'SAVE';
					this.isSaveDisabled = false;
				});
		} else {
			//console.log(form.value)
			this.dataService.storeData(this.apiRoutePrefix, form.value)
				.subscribe((success) => {

					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.isSaveDisabled = false;
				}, (error) => {
					//this.alertMessage = 'Something went wrong, Please try again!';
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						this.SaveButtonText = 'SAVE';
						if (err.message == 'No Departments') {
							this.haveDepts = true;
						}
						this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
						this.isSaveDisabled = false;
					}
				});
		}
		// //console.log(this.frameArray.filter((frame) =>(frame.frameIn!=null && frame.frameOut!=null)));
	}

	close() {
		this.closeSideWindow.emit();
		this.closeCreateShot.emit();
	}

	public dropped(files: NgxFileDropEntry[]) {
		this.files = files;
		for (const droppedFile of files) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {

					// Here you can access the real file
					//console.log(droppedFile.relativePath, file);

					var reader = new FileReader();
					this.image_name = file.name;
					this.image_type = file.type;

					reader.onload = (event: any) => {
						this.url = event.target.result;
						if (this.image_type != 'image/png' && this.image_type != 'image/jpg' && this.image_type != 'image/jpeg' && this.image_type != 'image/gif') {
							this.preview_src = './assets/images/default/attechment.png';
						} else {
							this.preview_src = this.url;
						}
					}
					reader.readAsDataURL(file);
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
				//console.log(droppedFile.relativePath, fileEntry);
			}
		}
	}

	public fileOver(event) {
		//console.log(event);
	}

	public fileLeave(event) {
		//console.log(event);
	}

	unlinkImage(event) {
		event.stopPropagation();
		this.preview_src = '';
		this.image_name = 'unset';
		this.url = '';
	}

	/** On deselect final department */
	onItemDeSelect($event) {
		if (this.shots.final_department.length > 0) {
			if ($event.asset_shot_department_id == this.shots.final_department[0].asset_shot_department_id) {
				this.shots.final_department = [];
			}
		}
	}

	// check if frameIn or frameOut already entered into the same camera
	checkFramePair(type, frameType, camera) {
		if (type == 'frameIn') {
			if (camera.frames.filter((frame) => (frame.frameIn == frameType)).length >= 2) {
				this.repeatedFrame = frameType;
			} else {
				this.repeatedFrame = null;
			}
		} else {
			if (camera.frames.filter((frame) => (frame.frameOut == frameType)).length >= 2) {
				this.repeatedFrame = frameType;
			} else {
				this.repeatedFrame = null;
			}
		}
		this.sumAllFrames();
	}

	/** Sum of all the frame In and Out */
	sumAllFrames() {
		this.shots.no_of_frames = null;
		for (let i = 0; i < this.camArray.length; i++) {
			const sum: any = this.camArray[i].frames.reduce((total, currentValue) => {
				return total + (currentValue.frameIn ? currentValue.frameIn : 0) - (currentValue.frameOut ? (currentValue.frameOut+1) : 0);
			}, 0);
			this.shots.no_of_frames = this.shots.no_of_frames - sum;
			this.shots.no_of_frames = Math.abs(this.shots.no_of_frames) === 0 ? null : Math.abs(this.shots.no_of_frames);
			this.isMultipleCameras = this.shots.no_of_frames == null ? false : true;
		}
		this.cd.markForCheck();
	}

  // on bid type changed
  onBidTypeChanged(){
    console.log(this.selectedBidType);
  }

}
