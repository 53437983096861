import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { CommonJs } from '../../../common/common-js';
import { DataService } from '../../../services/data.service';
declare var $: any;
import * as _ from "lodash";

@Component({
	selector: 'app-permissions',
	templateUrl: './permissions.component.html',
	styleUrls: ['./permissions.component.css']
})
export class PermissionsComponent implements OnInit {

	entityName = 'Permissions';
	entityNameSingular = 'Permission';
	apiRoutePrefix = 'permissions';
	permissions = JSON.parse(localStorage.getItem('permissions'));
	myform: any;
	SaveButtonText: string = 'SAVE';
	errors: any;
	rolesData: Array<any> = [];
	entityData: Array<any> = [];
	currentTheme = this.commonJs.getSettingValue('theme_color');
  subGroupsSlugs = [
                      {
                        'id': 0,
                        'GroupName': 'Projects Screens',
                        'groupKeys':  [ 'project_screens', 'assets', 'seasons', 'episodes', 'sequences', 'shots', 'task_comments', 'tasks', 'task_assignment', 'project_dashboard', 'project_settings' ],
                        'groupData': [],
                        'subCollapse': false
                      },
                      {
                        'id': 1,
                        'GroupName': 'Bidding',
                        'groupKeys': [ 'bidding_cost', 'bidding' ],
                        'groupData': [],
                        'subCollapse': false
                      }
      ];


	constructor(
		private dataService: DataService,
		public commonJs: CommonJs,
		private titleService: Title
	) { }

	ngOnInit() {
		this.dataService.getListData('rolesList', 'permissions')
			.subscribe((success) => {
				let tempRolesData = success.data.map((ele:any) => {
					ele.collapsed=false;
					return ele;
				});
				this.rolesData = tempRolesData;
			}, (rejected) => {

			});
		this.dataService.getListData('entityList', {})
			.subscribe((success) => {
				let tempEntityData = success.data;

      //  this.entityData = tempEntityData;
      //  this.entityData = tempEntityData.concat(this.subGroupsSlugs);

        // console.log(tempEntityData);
				tempEntityData.forEach((element,index)=>{

          if(!element['GroupName']){

               if(element['slug'] == 'headcount_report'){
                 tempEntityData.splice(index,1);
               }else if(element['slug'] == 'management_dashboard'){
                 tempEntityData.splice(index,1);
               }

               // console.log(element['slug'], index);
               this.subGroupsSlugs.forEach((subEle, subIndx)=>{
                   if(this.subGroupsSlugs[subIndx]['groupKeys'].includes(element['slug'])){
                     this.subGroupsSlugs[subIndx]['groupData'].push(element);
                     element['group'] = subEle;
                   }
               });
          }
					});

				this.entityData = this.subGroupsSlugs.concat(tempEntityData.filter((ele)=>(!ele.group)));
        // console.log(this.entityData);
			}, (rejected) => {

			});
		this.titleService.setTitle(this.entityName);
	}

	checkboxAction(access, id, slug, role_id) {
        // $('#' + access + '_' + slug + '_' + role_id).prop('checked', true);
		let isChecked = !$('#' + access + '_' + slug + '_' + role_id).is(':checked');
		let postData = {};
		postData[access + '_data'] = isChecked;
		this.dataService.storeData(this.apiRoutePrefix, { 'data': postData, 'role_id': role_id, 'entity_id': id })
			.subscribe((success) => {
			}, (error) => {
				if (error.error) {
					var err = error.error;
					this.errors = err.error;
				}
			});

	}

	getPermission(role_id, role_collapsed) {
		if(!role_collapsed){
			this.dataService.getOtherData('getPermissions', { 'role_id': role_id })
			.subscribe((success) => {
				let that = this;
				_.forEach(success, function (value) {
					$('#view_' + value["slug"] + '_' + role_id).prop('checked', value['view_data']);
					$('#add_' + value["slug"] + '_' + role_id).prop('checked', value['add_data']);
					$('#edit_' + value["slug"] + '_' + role_id).prop('checked', value['edit_data']);
					$('#delete_' + value["slug"] + '_' + role_id).prop('checked', value['delete_data']);
				});
			}, (error) => {
			});
		}
	}

}
