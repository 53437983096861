import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { CommonJs } from '../../common/common-js';
import { DataService } from '../../services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Injectable()
export class AuthService {

	constructor(
		private router: Router,
		location: Location,
		private dataService: DataService,
		private spinner: NgxSpinnerService,
	) { }

	getLogout() {
		this.spinner.show();
		localStorage.setItem('userId', '');
		localStorage.setItem('user', '');
		localStorage.removeItem('currentTask');
		localStorage.removeItem('projectRoles');
		localStorage.removeItem('projectRoleType');
		localStorage.removeItem('projectPermissions');
		localStorage.setItem('roles', JSON.stringify({}));
		localStorage.setItem('role', '');
		localStorage.setItem('standalone', undefined);
		let client_code = localStorage.getItem('client_code');
		this.dataService.logout('logout', '')
			.subscribe((success) => {
				if (success.success) {
					this.router.navigate(['/ac/' + client_code]);
					localStorage.setItem('token', '');
					localStorage.setItem('access_code', '');
				}
				this.spinner.hide();
			}, (error) => {
				this.router.navigate(['/ac/' + client_code]);
				localStorage.setItem('token', '');
				localStorage.setItem('access_code', '');
				this.spinner.hide();
			});
	}

}
