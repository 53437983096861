import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateProjectsComponent } from './create-projects/create-projects.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { ProjectPhaseComponent } from './project-phase/project-phase.component';
import { PlanningComponent } from './planning/planning.component';
import { BiddingComponent } from './bidding/bidding.component';
import { AuthGuardService } from './../../services/auth/auth-guard.service';
import { TasksCommentsComponent } from './tasks-comments/tasks-comments.component';
import { ShotDatatableComponent } from './shot-datatable/shot-datatable.component';
import { SeasonListComponent } from './season-list/season-list.component';
import { SequencesDatatableComponent } from './sequences-datatable/sequences-datatable.component';
import { ProjectsGridComponent } from './projects-grid/projects-grid.component';
import { EdpisodeListComponent } from './edpisode-list/edpisode-list.component';
import { AssetsListComponent } from './assets-list/assets-list.component';
import { ScriptListComponent } from './script-list/script-list.component';
const routes: Routes = [
	{
		path: '',
		component: ProjectsGridComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { projects: ['view'] }, checkProject: true }
	},
	{
		path: 'create',
		component: CreateProjectsComponent
	},
	{
		path: 'assets/:id',
		component: AssetsListComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { assets: ['view'] }, checkProject: true }
	},
	{
		path: 'assets/:id/:type',
		component: AssetsListComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { assets: ['view'] }, checkProject: true }
	},
	{
		path: 'planning/:id',
		component: PlanningComponent
	},
	{
		path: 'bidding/:id',
		component: BiddingComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { bidding: ['view'] }, checkProject: true }
	},
	{
		path: 'dashboard/:id',
		component: DashboardComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { project_screens: ['view'] }, checkProject: true }
	},
	{
		path: 'seasons/:id',
		component: SeasonListComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { sequences: ['view'] }, checkProject: true }
	},
	{
		path: 'sequences/:id',
		component: SequencesDatatableComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { sequences: ['view'] }, checkProject: true }
	},
	{
		path: 'sequences/:id/:episode',
		component: SequencesDatatableComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { sequences: ['view'] }, checkProject: true }
	},
	{
		path: 'sequences/:id/:episode/:seasons',
		component: SequencesDatatableComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { sequences: ['view'] }, checkProject: true }
	},
	{
		path: 'shots/:id',
		component: ShotDatatableComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { shots: ['view'] }, checkProject: true }
	},

	{
		path: 'shots/:id/:sequences',
		component: ShotDatatableComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { shots: ['view'] }, checkProject: true }
	},
	{
		path: 'shots/:id/:sequences/:episode',
		component: ShotDatatableComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { shots: ['view'] }, checkProject: true }
	},
	{
		path: 'shots/:id/:sequences/:episode/:seasons',
		component: ShotDatatableComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { shots: ['view'] }, checkProject: true }
	},
	{
		path: 'settings/:id',
		component: ProjectSettingsComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { project_settings: ['view'] }, checkProject: true }
	},
	{
		path: 'phases/:id',
		component: ProjectPhaseComponent
	},
	{
		path: 'episodes/:id',
		component: EdpisodeListComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { episodes: ['view'] }, checkProject: true }
	},
	{
		path: 'episodes/:id/:seasons',
		component: EdpisodeListComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { episodes: ['view'] }, checkProject: true }
	},
	{
		path: 'comments/:id',
		component: TasksCommentsComponent
	},
  {
		path: 'scripts/:id',
		component: ScriptListComponent,

	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ProjectsRoutingModule { }
