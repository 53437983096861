import { Component, OnInit, TemplateRef } from '@angular/core';
import { DataService } from '../../../../services/data.service';
import swal from 'sweetalert2';
import { ToastrService } from '../../../../services/toastr.service';
import { NotificationsService } from '../../../../services/notifications/notifications.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import * as moment from "moment";
import * as _ from "lodash";
import { environment } from '../../../../../environments/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CommonJs } from '../../../../common/common-js';
import Pusher from 'pusher-js/worker';
import { PusherService } from 'src/app/services/pusher.service';

@Component({
  selector: 'app-leave-list',
  templateUrl: './leave-list.component.html',
  styleUrls: ['./leave-list.component.css']
})
export class LeaveListComponent implements OnInit {
  allLeaves: Array<any> = [];
  allLeavesCopy: Array<any> = [];
  noRecordFoundMsg: any = 'Sorry, no records found!';
  settings: any = JSON.parse(localStorage.getItem('settings'));
  settingsAll: any = JSON.parse(localStorage.getItem('settings'));
  userData = JSON.parse(localStorage.getItem('user'));
  DateFormat: any;
  leaveDays: Array<any>;
  leaveType: Array<any>;
  leaveForm: FormGroup;
  fromAndTodate: any = {};
  editAction: boolean = false;
  SaveButtonText: string = 'APPLY';
  valisationError: any;
  clientCode = localStorage.getItem('access_code');
  leaveSubs: any;
  recordsPerPage: number = 10;
  activePage: number = 0;
  bsModalRef: BsModalRef;
  modalActionType: string = '';
  modalData: any;

  public to = {
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    autoApply: true,
    "maxDate": "31 Dec 2030",
    locale: {
      format: 'DD MMM YYYY'
    }
  }

  public from = {
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    autoApply: true,
    "maxDate": "31 Dec 2030",
    locale: {
      format: 'DD MMM YYYY'
    },
    //startDate:moment().format('YYYY-MM-DD'),
  }


  constructor(
    public dataService: DataService,
    private toastrService: ToastrService,
    private notificationsService: NotificationsService,
    private fb: FormBuilder,
    public datepipe: DatePipe,
    private modalService: BsModalService,
    public commonJs: CommonJs,
    private pusherService: PusherService
  ) {
    this.fromAndTodate.from = this.datepipe.transform(new Date(), this.DateFormat);
    this.fromAndTodate.to = this.datepipe.transform(new Date(), this.DateFormat);

    this.fromAndTodate.from2 = this.datepipe.transform(new Date(), this.DateFormat);
    this.fromAndTodate.to2 = this.datepipe.transform(new Date(), this.DateFormat);
  }

  ngOnInit() {
    console.log(this.userData)
    // this.noRecordFoundMsg = (this.settings.no_records != '') ? this.settings.no_records : this.noRecordFoundMsg;
    this.getLeaves();

    this.DateFormat = this.settingsAll.date_format;
    this.from.locale.format = this.DateFormat;
    this.leaveForm = this.fb.group({
      id: [null],
      leavetype: [null],
      from: [null],
      to: [null],
      leaveDays: [1],
      reason: [null],
    });

    this.leaveType = [
      {
        name: 'Casual Leave',
        id: 1
      },
      {
        name: 'Leave Without Pay',
        id: 2
      }
    ]

    this.leaveDays = [
      {
        name: 'Full Day',
        id: 1
      },
      {
        name: '1st Half',
        id: 2
      },
      {
        name: '2nd Half',
        id: 3
      },
    ]

    if (environment.prodType != 'offline') {
      //this.dataService.getNotifications(this.clientCode,this.userId,'header');
      this.leaveSubs = this.dataService.isNotification$.subscribe((notification) => {
        this.getLeaves();
      });
    }

    /** Pusher js live update data without reload page */
    if (environment.production) {
      // const channel = pusher.subscribe(`refetch-data`);
      const channel = this.pusherService.subscribeToChannel(`refetch-data`);
      channel.bind(`refetch-data`, (data) => {
        // code to update list
        this.getLeaves();

      });
    }

  }

  ngOnDestroy() {
    this.pusherService.unsubscribeFromChannel(`refetch-data`);
  }

  /** Get leaves */
  getLeaves() {
    this.dataService.getLeave('leaveManagementList').subscribe((res: any) => {
      this.allLeaves = res.data;
      this.allLeaves.map((leave) => { leave.typeShortName = leave.type.match(/\b\w/g).join(''); });
    });

    // To update after accept/reject
    setTimeout(() => { this.displayActivePage(this.activePage); }, 1000);

  }


  acceptRejectfun(data) {
    this.dataService.leaveAcceptRejectionfun(data).subscribe(
      result => {
        //console.log(result)
        if (result.status == "success") {
          this.toastrService.notify('success', '', result.message);
          if (result.data.notifications) {
            let that = this;
            that.dataService.addNotification(that.clientCode, result.data.notifications['user_id'], result.data.notifications);
          }
          this.notificationsService.sendPushMessage(result.data.notifications.description, 'Leave', result.data.notifications.type);
        }
        this.getLeaves();
      },
      error => {
        this.toastrService.notify('success', '', error.message);
        //console.log(error)
      }
    )
  }

  leaveAcceptRejectionfun(data: any, type, comment?) {
    //console.log(data);
    //console.log(comment);

    let statuObject: any = {};
    var inputOptionsPromise: any = new Promise(function (resolve) {
      // get your data and pass it to resolve()
      resolve(statuObject);
    });

    if (type == 'delete') {
      const body = {
        id: this.modalData.id,
        status: 3,
        action_by: localStorage.getItem('userId'),
        reason_for_accept_reject: comment ? comment : null
      }
      //console.log(inputOptionsPromise);
      this.allLeavesCopy.map((ele) => {
        if (ele.id == this.modalData.id) {
          ele.status = 3;
        }
        return ele;
      });

      this.acceptRejectfun(body);
    }

    if (data && type != 'delete') {
      const body = {
        id: this.modalData.id,
        status: type == 'approve' ? 1 : 2,
        action_by: localStorage.getItem('userId'),
        reason_for_accept_reject: comment ? comment : null
      }
      //console.log(inputOptionsPromise);
      this.allLeavesCopy.map((ele) => {
        if (ele.id == this.modalData.id) {
          ele.status = type == 'approve' ? 1 : 2;
        }
        return ele;
      });

      this.acceptRejectfun(body);
    }
    this.bsModalRef.hide();
  }


  /** Delete my leave */
  deleteMyLeave(data: any, comment) {
    //console.log(comment);
    if (data) {

      this.dataService.deleteLeave(data.id).subscribe(
        result => {
          //console.log(result);
          if (result.status == 'success') {
            this.toastrService.notify('success', '', result.message);
            this.getLeaves();
          }
        },
        errror => {
          this.toastrService.notify('warn', '', errror.message);
        },
        () => {

        }
      )

    }
    this.bsModalRef.hide();
  }

  /** Select datepicker */
  selectedDatepicker($event, type) {
    if (type == 'from') {
      let fromDate: any = moment(new Date(this.fromAndTodate.to)).isSameOrBefore($event.start);
      if (fromDate) {
        this.fromAndTodate.to = this.datepipe.transform($event.start, 'MMM d, y');
        this.fromAndTodate.to2 = this.datepipe.transform($event.start, 'yyyy-MM-dd');
      }
      this.fromAndTodate.from = this.datepipe.transform($event.start, 'MMM d, y');
      this.fromAndTodate.from2 = this.datepipe.transform($event.start, 'yyyy-MM-dd');
    } else {
      let toDate: any = moment(new Date(this.fromAndTodate.from)).isSameOrBefore($event.start);
      if (!toDate) {
        this.fromAndTodate.from = this.datepipe.transform($event.start, 'MMM d, y');
        this.fromAndTodate.from2 = this.datepipe.transform($event.start, 'yyyy-MM-dd');
      }
      this.fromAndTodate.to = this.datepipe.transform($event.start, 'MMM d, y');
      this.fromAndTodate.to2 = this.datepipe.transform($event.start, 'yyyy-MM-dd');
    }
  }

  /** On submit*/
  onSubmit(form: any) {
    if (!this.checkControlPost(form)) {
      if (this.findInvalidControls().length === 0) {
        const data: any = {
          "type": form.leavetype.id,
          "from": this.datepipe.transform(this.fromAndTodate.from2, 'yyyy-MM-dd'),
          "to": this.datepipe.transform(this.fromAndTodate.to2, 'yyyy-MM-dd'),
          "leave_day": form.leaveDays ? form.leaveDays : 1,
          "reason": form.reason
        }

        if (this.editAction) {
          data.id = this.leaveForm.get('id').value;
        }

        this.SaveButtonText = 'Applying';


        if (this.editAction) {

          this.dataService.editLeave(data).subscribe(
            res => {
              if (res.code != 422) {
                this.toastrService.notify('success', '', res.message);
                this.SaveButtonText = 'APPLY';
                if (res.data.notifications) {
                  let that = this;
                  that.dataService.addNotification(that.clientCode, res.data.notifications['user_id'], res.data.notifications);
                }
                this.notificationsService.sendPushMessage(res.data.notifications.description, 'Leave', res.data.notifications.type);
                this.resetForm();
                this.getLeaves();
              } else {
                this.toastrService.notify('warn', '', res.message);
                this.SaveButtonText = 'APPLY';
              }
              this.valisationError = '';
              this.editAction = false;
            }, error => {
              this.SaveButtonText = 'APPLY';
              var err = error.error;
              //console.log(err);
              this.toastrService.notify('warn', '', err.message);
              this.valisationError = err
              //console.log("this.valisationError", this.valisationError)
              //console.log(error)
            },
            () => {

            }
          )
        }
      }
    }

    if (this.checkControlPost(form)) {
      this.markFormTouched(this.leaveForm);
    }
  }


  /** Reset form */
  resetForm() {
    this.editAction = false;
    this.fromAndTodate.from = this.datepipe.transform(new Date(), 'MMM d, y');
    this.fromAndTodate.to = this.datepipe.transform(new Date(), 'MMM d, y');
    this.valisationError = '';
    this.leaveForm.get('leavetype').clearValidators();
    this.leaveForm.get('leavetype').updateValueAndValidity();
    this.leaveForm.get('reason').clearValidators();
    this.leaveForm.get('reason').updateValueAndValidity();
    this.leaveForm.reset();
  }


  /** Check Control Post */
  checkControlPost = (post) => {
    let invalid = false;
    Object.keys(post).forEach((key: string) => {

      if (key === 'leavetype' && !this.leaveForm.get(`${key}`).value) {
        this.leaveForm.get(`${key}`).setValidators([Validators.required]);
        this.leaveForm.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }


      // if (key === 'leaveDays' && !this.leaveForm.get(`${key}`).value) {
      //   this.leaveForm.get(`${key}`).setValidators([Validators.required]);
      //   this.leaveForm.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
      //   return invalid = true;
      // }

      if (key === 'reason' && !this.leaveForm.get(`${key}`).value) {
        this.leaveForm.get(`${key}`).setValidators([Validators.required]);
        this.leaveForm.get(`${key}`).updateValueAndValidity({ onlySelf: true, emitEvent: false });
        return invalid = true;
      }
    });
    return invalid;
  }

  /** Find Invalid Controls */
  findInvalidControls = () => {
    const invalid = [];
    const controls = this.leaveForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  /**Mark Form Touched*/
  markFormTouched = (group: FormGroup | FormArray) => {
    Object.keys(group.controls).forEach((key: string) => {
      const control = group.controls[key];
      if (control instanceof FormGroup || control instanceof FormArray) {
        control.markAsTouched();
        this.markFormTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }


  /** Get data  */
  getFilterData($event) {
    this.leaveType = [
      {
        name: 'Casual Leave',
        id: 1
      },
      {
        name: 'Leave Without Pay',
        id: 2
      }
    ]
  }

  /** On select leave type */
  onSelectLeaveType($event) {
    //console.log($event)
  }

  /** Leave days */
  getLeaveDays($event) {
    this.leaveDays = [
      {
        name: 'Full Day',
        id: 1
      },
      {
        name: '1st Half',
        id: 2
      },
      {
        name: '2nd Half',
        id: 3
      },
    ]
  }

  /** On select leave days */
  onSelectLeaveDays($event) {
  }


  //To detect the currently loaded page on pagination changed
  displayActivePage(activePageNumber: number) {
    setTimeout(() => {
      this.activePage = activePageNumber;
      this.allLeavesCopy = this.allLeaves.slice((activePageNumber - 1) * this.recordsPerPage, (activePageNumber - 1) * this.recordsPerPage + this.recordsPerPage);
      //console.log(this.allLeaves)
      //console.log(this.allLeavesCopy)
    }, 100);
  }

  // To open Approve/Reject/Delete modal
  openModal(template: TemplateRef<any>, actionType, data) {
    this.bsModalRef = this.modalService.show(template);
    this.modalActionType = actionType;
    this.modalData = data;
  }


}
