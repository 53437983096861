import { AppConfigs } from '../../../common/app-configs';
import { CommonJs } from '../../../common/common-js';
import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, ChangeDetectorRef, TemplateRef } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastrService } from '../../../services/toastr.service';
declare var $: any;
import * as _ from "lodash";
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { camArrayInterface } from '../create-shot/create-shot.component';
import { GridService } from 'src/app/services/grid.service';


@Component({
  selector: 'task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.css']
})
export class TaskDetailsComponent implements OnInit {
  rows = [
    { name: 'John Doe', age: 30, email: 'john@example.com' },
    { name: 'Jane Smith', age: 25, email: 'jane@example.com' },
    { name: 'Mike Johnson', age: 35, email: 'mike@example.com' }
  ];
  columns = [
    { prop: 'name' },
    { prop: 'age' },
    { prop: 'email' }
  ];

  @ViewChild('mentionTextarea', { static: false }) mentionTextarea: ElementRef;

  selectedMentions: any[] = [];
  @Input('attr.data') dataHtml: any;
  bsModalRef: BsModalRef;

  dirName = 'comment_attachment';
  myform: any;
  apiRoutePrefix = 'shots';
  data: any;
  commentData: any;
  projectId = '';
  is_external_login: boolean = false;
  is_external_review: boolean = false;
  is_stagged_review: boolean = false;

  projectRoles = JSON.parse(localStorage.getItem('projectRoles'));
  currentRole = '';

  projectroletype = JSON.parse(localStorage.getItem('projectRoleType'));

  settings: any = JSON.parse(localStorage.getItem('settings'));

  userId = localStorage.getItem('userId');

  taskComment: { [index: string]: any } = {};
  taskCommentStatus = [];

  comments = [];
  //taskAssVerList = [];
  taskAssVerList: { [index: string]: any } = {};
  taskVersionsData = [];
  timeLogData: { [index: string]: any } = {};
  timeLogArr: { [index: string]: any } = {};
  assetData: { [index: string]: any } = {};
  shotData: { [index: string]: any } = {};
  taskDepts: { [index: string]: any } = {};
  departments: { [index: string]: any } = {};

  currentTab = 'task_info';
  SaveButtonText: string = 'SAVE';
  SaveButtonTextRI: string = 'SAVE';
  SaveButtonTextRS: string = 'SAVE';
  display_resche: boolean = false;

  /*url:any;
  preview_src:string = '';
  image_name:string;
  image_type:string;*/

  commentTagUserList: Array<any> = [];

  multipleFiles: Array<any> = [];
  TempMultipleFiles: Array<any> = [];
  isFirstTime: boolean = true;

  checklistData = [];
  errors: { [index: string]: any } = {};
  edit_errors: { [index: string]: any } = {};
  checklist: { [index: string]: any } = {};
  dateOptions: { [index: string]: any } = {};

  //selectedStatus

  public endDate = {
    //opens: "top",
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    timePicker: true,
    timePickerIncrement: 5,
    autoApply: true,
    drops: 'up',
    locale: {
      format: 'DD MMM YYYY hh:mm A'
      //format: 'DD MMM YYYY'+ ' 23:59'
    }
  }

  public startDate = {
    //opens: "top",
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    timePickerIncrement: 5,
    timePicker: true,
    autoApply: true,
    drops: 'up',
    "maxDate": "31 Dec 2030",
    locale: {
      format: 'DD MMM YYYY hh:mm A'
      //format: 'DD MMM YYYY'+ ' 00:00'
    }
  }
  @Output() itemSelected: EventEmitter<any>;
  // config used for getting users list to MENTION them
  configDefault: any = {
    "start": 0,
    "itemsPerPage": '100',
    "sort": {
      "title": "#ID",
      "name": "id",
      "sort": "desc",
      "className": "hide-this hide-this"
    },
    "sortNew": {
      "id": "desc"
    },
    "FilterColumns": [
      "name",
      "email"
    ],
    "filtering": {
      "filterString": ""
    },
    "FilterByColumns": {
      "status": "",
      "location": "",
      "emp_code_final": "",
      "name": "",
      "mode": "",
      "primary_department": "",
      "joining_date": "",
      "vertical": "",
      "role_id": "",
      "reporting_to": "",
      "prod_person": "",
      "software_skills": "",
      "secondary_department": "",
      "designation": "",
      "dob": "",
      "gender": "",
      "exit_date": "",
      "attrition_type": "",
      "exit_reason": "",
      "tenure": "",
      "age": "",
      "email": "",
      "phone": "",
      "government_id": "",
      "address1": "",
      "address2": "",
      "city": "",
      "state": "",
      "country": "",
      "zipcode": "",
      "exp_duration": "",
      "exp_designation": "",
      "exp_company": "",
      "column1": "",
      "column2": "",
      "column3": "",
      "column4": "",
      "column5": "",
      "column6": ""
    },
    "where": {
      "status": [],
      "gender": [],
      "mode": [],
      "vertical": [],
      "role_id": [],
      "type": [],
      "primary_department": [],
      "secondary_department": [],
      "reporting_to": [],
      "prod_person": [],
      "software_skills": [],
      "attrition_type": [],
      "exit_reason": []
    },
    "columns": {},
    "export": false
  };

  @Output() displayDetail = new EventEmitter();
  @Output() closeSideWindow: EventEmitter<any> = new EventEmitter();
  @ViewChild('scrollToBottom', { static: false }) private scrollToBottomContainer: ElementRef;
  //public displayDetail = new EventEmitter();

  private commentTimer;
  uploadUrl = this.commonJs.getUploadUrl();
  clientCode = localStorage.getItem('access_code');
  isCommentSubmitted: boolean = false;
  isCheklistSubmitted: boolean = false;
  isInventoryRejected: boolean = false;

  rejectInventoryTab = 'reject_inventory';
  rejectInventoryTabTitle = 'Reject Inventory';
  isRejectInventory = false;
  isResentInventory = false;

  //Lazy-load images
  defaultImage = 'https://www.placecage.com/1000/1000';
  showInput: boolean = false;
  showCompletionInput: boolean = false;
  where: { [index: string]: any } = {};

  //dependency
  dependencyDetails = [];
  linkedAssets = [];

  showDoneCheckbox: boolean = false;

  env = environment;

  //Custom status
  statusArr = [];
  customStatusArr = [];

  retakeType = [];
  isShowRetakeTypes: boolean = false;
  isRetakeTypeMandatory: boolean = false;

  commentSButtonTooltip = 'Change Status';

  fileSizeError: boolean = false;

  getAllSettingResponse: any;

  public modalRef: BsModalRef;
  modalContentURL: any;
  getProjectDatabyId: any;
  getFileUploadData: any = [];
  busy: boolean = false;

  camArray: Array<camArrayInterface> = [];
  isMultipleCameras: boolean = false;
  repeatedFrame: number = null;
  isEditCameras: boolean = false;

  recordsPerPage: number = 10;
  activePage: number = 0;
  allCamerasData: Array<any> = [];
  noRecordFoundMsg: string = "Sorry, no records found!";
  presetLayersList: Array<any> = [];
  isDatatableLoading: boolean = true;

  constructor(private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    public commonJs: CommonJs,
    public appConfigs: AppConfigs,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private router: Router,
    private modalService: BsModalService,
    private gridService: GridService,) { }

  // ! NgonInit

  async ngOnInit() {
    this.getAllSettingResponse = this.commonJs.getAllSettingResponse;
    // this.noRecordFoundMsg = (this.settings.no_records != '') ? this.settings.no_records : this.noRecordFoundMsg;

    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;

      if(this.data.selectedTab=='checklist'){
       await this.getCheckList();
      }


      this.selectedChips = this.data.layers ? this.data.layers : [];
      this.is_external_review = this.data.is_external_review;
      this.currentTab = this.data.selectedTab;

      if (!this.data.isReview) {
        this.commentSButtonTooltip = "Add Notes";
      }

      if (this.data.open_from == "rejected_inventory" && this.currentTab == 'resend_inventory') {
        this.rejectInventoryTab = this.currentTab;
        this.rejectInventoryTabTitle = 'Resend Inventory';
        this.isResentInventory = true;
      } else if (this.data.open_from != "rejected_inventory" && this.currentTab == 'reject_inventory') {
        this.rejectInventoryTab = this.currentTab;
        this.rejectInventoryTabTitle = 'Reject Inventory';
        this.isRejectInventory = true;
      } else if (this.data.open_from == "rejected_inventory" && this.currentTab != 'resend_inventory') {
        //this.rejectInventoryTab = this.currentTab;
        this.rejectInventoryTabTitle = 'Resend Inventory';
        this.isResentInventory = false;
      }
    }

    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];

      let param: any = {
        project_id: this.projectId
      };
      this.dataService.getProjectData(param).subscribe((res: any) => {
        this.getProjectDatabyId = res.data;
      });
    });

    //Old
    // this.currentRole = this.projectRoles['project_' + this.projectId ? this.projectId : this.data.project_id];

    //New
    this.currentRole = this.projectRoles['project_' + this.projectId];

    if (this.projectroletype['project_' + this.projectId] == 'external') {
      this.is_external_login = true;
    }

    if (this.currentRole != 'artist' && this.currentRole != 'vendor') {
      //this.getStatusList();
    }

    // this.getProjectData().then((res) =>{
    // }).then((res => {
    //     this.getStatusList().then((res) =>{
    //         this.commentOnInit();
    //     });
    // }));

    this.getStatusList().then((res) => {
      this.commentOnInit();
    });


    this.scrollToBottom();

    this.gridService.postData('usersList', this.configDefault).subscribe((res: any) => {
      let tempArray = [];
      res.data.map((ele) => {
        tempArray.push({ id: ele.id, name: ele.name, combined: ele.name.split(' ').join('_') });
      });
      this.commentTagUserList = tempArray;
    });

    this.getPresetLayersList();
    this.getLinkedAssetsWithShot();
    this.getCheckList();
  }

  ngAfterViewInit() {
    //this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.scrollToBottomContainer.nativeElement.scrollTop = this.scrollToBottomContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  getPresetLayersList() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getListData('listItemList/preset_layers', '')
        .subscribe((success) => {
          // this.is_external_review = success.data.is_external_review;
          let tempPresets = success.data.map((ele) => { return ele.item_name });
          tempPresets.unshift('All')
          tempPresets.unshift('select from preset layers')
          this.presetLayersList = tempPresets;
          resolve();
        }, (rejected) => {

        });
    });
  }

  search(event) {
    this.presetLayersList = [...this.presetLayersList]
    console.log(event, this.presetLayersList);
  }

  onKeyUp(event) {
    console.log(event);
  }

  getProjectData() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getDataById('getProjectSettings/' + this.projectId)
        .subscribe((success) => {
          this.is_external_review = success.data.is_external_review;
          resolve();
        }, (rejected) => {

        });
    });
  }

  getStatusList() {
    return new Promise<void>((resolve, rejected) => {
      // this.dataService.getOtherData('getStatusList', {})
      //   .subscribe((success) => {
      this.statusArr = [...this.dataService.allStatusList]
      resolve();

    });
    // });
  }

  changeTab(tab) {
    this.isDatatableLoading = true;
    this.currentTab = tab;
    //this.commentTimer.unsubscribe();

    if (tab == 'version') {
      this.getVersionData();
    } else if (tab == 'time_log') {
      this.getTimeLogData();
    } else if (tab == 'asset_info') {
      this.getAssetData();
    } else if (tab == 'shots_info') {
      this.getShotData();
    } else if (tab == 'comment') {
      // this.artist.start_date = this.data.start_date;
      // this.artist.end_date = this.data.end_date;
    } else if (this.data.open_from == "rejected_inventory" && tab == 'resent_inventory') {
      this.isResentInventory = true;
    } else if (this.data.open_from != "rejected_inventory" && tab == 'resent_inventory') {
      this.isRejectInventory = true;
    } else if (this.data.open_from == "rejected_inventory" && tab != 'resend_inventory' && tab != 'reject_inventory') {
      //this.rejectInventoryTab = this.currentTab;
      this.rejectInventoryTabTitle = 'Resend Inventory';
      this.isResentInventory = false;
    } else if (this.data.open_from == "rejected_inventory" && tab == 'reject_inventory') {
      this.rejectInventoryTabTitle = 'Resend Inventory';
      this.isResentInventory = true;
    } else if (this.data.open_from != "rejected_inventory" && tab == 'reject_inventory' && tab != 'resend_inventory') {
      this.rejectInventoryTabTitle = 'Reject Inventory';
      this.isRejectInventory = true;
    } else if (tab == 'dependency') {
      this.getTaskDependencyDetails();
    } else if (tab == 'checklist') {
      this.getCheckList();
    } else if (tab == 'linked-assets') {
      this.getLinkedAssetsWithShot();
    }
  }

  checkDepartmentStaggedReview(dept_id, dtt_id) {
    return new Promise<void>((resolve, reject) => {
      let department = {};
      department['dept_id'] = dept_id;
      department['dtt_id'] = dtt_id;
      this.dataService.getListData('checkDepartmentStaggedReview', { 'project_id': this.projectId, 'department': department })
        .subscribe((success) => {
          this.is_stagged_review = success.data.is_stagged_review;
          resolve();
        }, (rejected) => {

        });
    });
  }

  commentOnInit() {
    //let customStatusArr = [];

    let that = this;
    _.forEach(this.statusArr, function (value) {
      that.customStatusArr[value.status] = value.studio_status;
    });
    this.taskCommentStatus = [
      { "id": "On Hold", "name": "On Hold", 'studioStatus': this.customStatusArr['On Hold'], 'bgcolor':'onHold' }
    ];

    if (this.data.actualStatus == 'Inventory') {
    } else if (this.data.actualStatus == 'Awaiting Inventory') {
    } else if (this.data.actualStatus == 'WIP') {
      if (!this.data.isReview) {
        this.taskCommentStatus.push({ "id": "Sent for Review", "name": "Sent for Review", 'studioStatus': this.customStatusArr['Sent for Review'],'bgcolor':'sentForReview' });
      }
    } else if (this.data.actualStatus == 'Sent for Review') {
      this.taskCommentStatus.push({ "id": "Done", "name": "Done", 'studioStatus': this.customStatusArr['Done'], 'bgcolor':'done' });
      this.taskCommentStatus.push({ "id": "Retake", "name": "Retake", 'studioStatus': this.customStatusArr['Retake'], 'bgcolor':'retake' });
      //this.checkDepartmentStaggedReview(this.data.department_id,this.data.department_tasktype_id);
      this.checkDepartmentStaggedReview(this.data.department_id, this.data.department_tasktype_id).then((res) => {
        if (this.is_external_review && (!this.is_stagged_review)) {
          this.taskCommentStatus.push({ "id": "Sent to Client", "name": "Sent to Client", 'studioStatus': this.customStatusArr['Sent to Client'], 'bgcolor':'sentToClient' });
          if (this.data.task_type == 'R&D') {
            this.taskCommentStatus.push({ "id": "Approved", "name": "Approved", 'studioStatus': this.customStatusArr['Approved'], 'bgcolor':'approved' });
          }
        } else {
          this.taskCommentStatus.push({ "id": "Approved", "name": "Approved", 'studioStatus': this.customStatusArr['Approved'], 'bgcolor':'approved' });
        }
      });

    } else if (this.data.actualStatus == 'Done') {
      this.taskCommentStatus.push({ "id": "Retake", "name": "Retake", 'studioStatus': this.customStatusArr['Retake'], 'bgcolor':'retake' });

      this.checkDepartmentStaggedReview(this.data.department_id, this.data.department_tasktype_id).then((res) => {
        if (this.is_external_review && (!this.is_stagged_review)) {
          this.taskCommentStatus.push({ "id": "Sent to Client", "name": "Sent to Client", 'studioStatus': this.customStatusArr['Sent to Client'], 'bgcolor':'sentToClient' });
        } else {
          this.taskCommentStatus.push({ "id": "Approved", "name": "Approved", 'studioStatus': this.customStatusArr['Approved'], 'bgcolor':'approved' });
        }
      });

    } else if (this.data.actualStatus == 'Sent to Client') {

      if (this.currentRole == 'client') {
        this.taskCommentStatus.push({ "id": "Approved", "name": "Approved", 'studioStatus': this.customStatusArr['Approved'], 'bgcolor':'approved' });
        this.taskCommentStatus.push({ "id": "Retake", "name": "Retake", 'studioStatus': this.customStatusArr['Retake'], 'bgcolor':'retake' });
      }

    } else if (this.data.actualStatus == 'Retake' || this.data.actualStatus == 'Unapproved' || this.data.actualStatus == 'CBB') {
      //this.taskCommentStatus.push({"id":"WIP","name":"WIP"});
    } else if (this.data.actualStatus == 'Approved') {
      this.checkDepartmentStaggedReview(this.data.department_id, this.data.department_tasktype_id);
      this.taskCommentStatus = [
        { "id": "Unapproved", "name": "Unapproved", 'studioStatus': this.customStatusArr['Unapproved'], 'bgcolor':'unapproved' },
        { "id": "CBB", "name": "CBB", 'studioStatus': this.customStatusArr['CBB'], 'bgcolor':'cbb' },
        //{"id":"OOP","name":"OOP"}
      ];

    } else if (this.data.actualStatus == 'NA') {
      this.taskCommentStatus = [];
      //this.taskCommentStatus.push({"id":"OOP","name":"OOP"});
    } else if (this.data.actualStatus == 'On Hold') {
      //this.taskCommentStatus = [];
      this.taskCommentStatus = [
        { "id": "Off Hold", "name": "Off Hold", 'studioStatus': 'Off Hold', 'bgcolor':'offHold' }
      ];
    }
    // else if(this.data.status == 'OOP'){
    //     this.taskCommentStatus = [];
    // }
    else {
      this.taskCommentStatus.push({ "id": "Sent for Review", "name": "Sent for Review", 'studioStatus': this.customStatusArr['Sent for Review'], 'bgcolor':'sentForReview' });
      this.taskCommentStatus.push({ "id": "Done", "name": "Done", 'studioStatus': this.customStatusArr['Done'], 'bgcolor':'done' });
      this.taskCommentStatus.push({ "id": "Retake", "name": "Retake", 'studioStatus': this.customStatusArr['Retake'], 'bgcolor':'retake' });
      this.taskCommentStatus.push({ "id": "Unapproved", "name": "Unapproved", 'studioStatus': this.customStatusArr['Unapproved'], 'bgcolor':'unapproved' });
      this.taskCommentStatus.push({ "id": "CBB", "name": "CBB", 'studioStatus': this.customStatusArr['CBB'], 'bgcolor':'cbb' });
      if (this.data.is_external_review) {
        this.taskCommentStatus.push({ "id": "Sent to Client", "name": "Sent to Client", 'studioStatus': this.customStatusArr['Sent to Client'], 'bgcolor':'sentToClient' });
      } else {
        this.taskCommentStatus.push({ "id": "Approved", "name": "Approved", 'studioStatus': this.customStatusArr['Approved'], 'bgcolor':'approved' });
      }

      // this.dataService.getDataById('getProjectSettings/' + this.projectId)
      //   .subscribe((success) => {

      //   }, (rejected) => {
      //
      //   });
    }

    if (this.data.actualStatus != '') {
      this.taskComment.status = { "id": this.data.status, "name": this.data.status, 'studioStatus': this.customStatusArr[this.data.actualStatus] };
    }

    this.taskComment.isNotifyNextDept = this.data.is_next_dept_notified;

    this.getCommentList();
  }

  getCommentList() {
    this.isCommentSubmitted = false;
    let vId = '';
    if (this.data.allow_status) {
      vId = this.data.id;
    } else {
      vId = this.data.task_version_id;
    }

    this.taskAssVerList = [];
    this.dataService.getOtherData('commentList', { 'project_id': this.data.project_id, 'task_id': this.data.task_id, 'task_version_id': vId, 'actualStatus': this.data.actualStatus })
      .subscribe((success) => {
        var tempComm = success.data;
        this.comments = tempComm.map((tempcomData) => {
          tempComm.forEach((comment_data) => {
            comment_data.comment = comment_data.comment.replace('<br />', ' \n ').trim();

            comment_data.comment = comment_data.comment.replace(/@([\w-]+)/g, (match, word) => {
              // Edit the word here, for example, add "Edited" in front of it.
              return `<span class="badge badge-secondary mentionedUserTag " >@ ${word.replace('_', ' ')}</span>`;
            });

          });
          return tempcomData
        });
        this.taskAssVerList = success.taskAssVerList;

        setTimeout(() => {
          this.scrollToBottom();
          //multipleFiles_temp.push(temp_file);
        }, 1000);

      }, (rejected) => {

      });
  }

  onSubmit(form: NgForm) {
    this.isCommentSubmitted = true;
    this.SaveButtonText = 'SAVING';
    form.value.project_id = this.data.project_id;
    form.value.task_id = this.data.task_id;
    if (form.value.comment) {
      let comment = form.value.comment;
      comment = comment.replace(/\r?\n/g, '<br />');
      form.value.comment = comment;
    }

    if (this.data.allow_status) {
      form.value.task_version_id = this.data.id;
    } else {
      form.value.task_version_id = this.data.task_version_id;
    }

    form.value.revision = this.data.revision_original;
    form.value.version = this.data.version_original;
    if (this.data.status != this.taskComment.status.id && !this.data.is_artist) {
      form.value.status = this.taskComment.status;
    } else {
      form.value.status = '';
    }

    if (this.multipleFiles.length > 0) {
      this.busy = true;
      /** get current time stamp */

      var isPerformanceSupported = (
        window.performance &&
        window.performance.now &&
        window.performance.timing &&
        window.performance.timing.navigationStart
      );

      var timeStampInMs = (
        isPerformanceSupported ?
          window.performance.now() +
          window.performance.timing.navigationStart :
          Date.now()
      );

      /** Dir name */


      const shotName = this.data.info.split('-');

      if (this.getProjectDatabyId.projects.episodic_series != 0) {

        this.multipleFiles[0].dir_name = this.getProjectDatabyId.projects.name + '/' + this.data.department_name + '/' + shotName[3];

      } else {

        this.multipleFiles[0].dir_name = this.getProjectDatabyId.projects.name + '/' + this.data.department_name + '/' + shotName[1];

      }


      /** Studio image name with revision and version */
      const imageName = this.multipleFiles[0].image_display_name.split('.');

      const stFileName = imageName[0] + '_' + Date.now() + '_' + this.data.revision + '_' + this.data.version + '.' + imageName[1];
      this.multipleFiles[0].studio_image_name = stFileName;
      form.value.images = this.multipleFiles;
      form.value.dir_name = this.multipleFiles[0].dir_name;
      form.value.studio_image_name = stFileName;
    }
    let that = this;
    let i = 0;
    form.value.reschedule = [];
    _.forEach(this.taskAssVerList, function (value) {
      form.value.reschedule[i] = value;
      if ($('.start_date-' + value.task_assignment_id).val() != '' && $('.start_date-' + value.task_assignment_id).val() != undefined) {
        form.value.reschedule[i]['start_date'] = moment($('.start_date-' + value.task_assignment_id).val()).format('YYYY-MM-DD HH:mm:ss');
      } else {
        form.value.reschedule[i]['start_date'] = value.start_date;
      }

      if ($('.end_date-' + value.task_assignment_id).val() != '' && $('.end_date-' + value.task_assignment_id).val() != undefined) {
        form.value.reschedule[i]['end_date'] = moment($('.end_date-' + value.task_assignment_id).val()).format('YYYY-MM-DD HH:mm:ss');
      } else {
        form.value.reschedule[i]['end_date'] = value.end_date;
      }
      i++;
    });
    form.value.old_status = this.data.actualStatus;
    form.value.is_external_login = this.is_external_login;
    form.value.department_id = this.data.department_id;
    form.value.department_tasktype_id = this.data.department_tasktype_id;

    if (this.taskComment.isMultipleRetake) {
      let taskData = {};
      taskData['project_id'] = this.data.project_id;
      taskData['task_id'] = this.data.task_id;
      taskData['task_assignment_id'] = this.data.ta_id;
      taskData['assigned_to'] = this.data.assigned_to;
      taskData['department_id'] = this.data.department_id;
      taskData['task_version_id'] = this.data.task_version_id;
      taskData['revision'] = this.data.revision;
      taskData['version'] = this.data.version;
      taskData['department_name'] = this.data.department_name;
      taskData['department_tasktype_name'] = this.data.department_tasktype_name;
      form.value.currentTaskData = taskData;
    }

    if (this.taskComment.isNotifyNextDept) {
      form.value.isNotifyNextDept = this.taskComment.isNotifyNextDept;
    }

    if (this.isShowRetakeTypes) {
      if (this.isRetakeTypeMandatory && this.taskComment.retake_type == undefined) {
        this.errors.retake_type = "Required";
        this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
        return false;
      }

      if (this.taskComment.retake_type != undefined) {
        form.value.isShowRetakeTypes = true;
        form.value.retake_type = this.taskComment.retake_type.id;
        form.value.retake_typeName = this.taskComment.retake_type.item_name;
      }
    }
    //console.log("this.getFileUploadData", this.getFileUploadData)
    //console.log("form.value", form.value)
    //console.log(form.value)
    this.dataService.fileUploadForcomment('commentStore', this.getFileUploadData, form.value)
      .subscribe((success) => {
        this.taskAssVerList = [];
        this.display_resche = false;
        this.busy = false;
        this.taskComment.comment = '';
        //this.preview_src = '';
        //this.image_name = '';
        //this.url = '';

        this.getCommentList();
        this.multipleFiles = [];
        this.TempMultipleFiles = [];
        this.getFileUploadData = [];
        this.isFirstTime = true;

        if (this.data.status != this.taskComment.status.id && !this.data.is_artist) {
          this.changeTab('task_info');
        }

        if (this.data.status != this.taskComment.status.id && !this.data.is_artist) {
          this.dataService.taskStatusChanged(true);
          this.toastrService.notify('success', '', "Task's status changed successfully.");
          this.cd.markForCheck();
        }

        if (success.data.notifications.length > 0) {
          let that = this;
          _.forEach(success.data.notifications, function (notification, key) {
            that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
          });
        } else if (success.data.notifications.length <= 0) {
          that.dataService.addNotification(that.clientCode, that.userId, '');
        }

        this.isCommentSubmitted = false;
      }, (error) => {
        if (error.error) {
          this.busy = false;
          var err = error.error;
          this.toastrService.notify('warn', '', err.message);
        }
        this.isCommentSubmitted = false;
      });
  }

  statusChange(column, event) {
    this.taskComment.status = event;
    if (event.id == 'Retake' || event.id == 'CBB' || event.id == 'Unapproved') {
      this.display_resche = true;
      if (this.currentRole != 'client') {
        this.getTaskAssignments();
      }

      this.isShowRetakeTypes = true;
      this.getRetakeTypes();
      this.isRetakeTypeMandatory = this.settings.is_retake_type_mandatory;

    } else {
      this.isShowRetakeTypes = false;
      this.display_resche = false;
    }


    if (this.taskComment.status.name != 'CBB' && this.taskComment.status.name != 'Unapproved') {
      if (this.taskComment.status.name == 'Retake' && this.data.assigned_to_name.length > 1) {
        this.showDoneCheckbox = true;
      }
    }
  }

  getTaskAssignments() {
    this.taskAssVerList = [];
    let vId = '';
    if (this.data.allow_status) {
      vId = this.data.id;
    } else {
      vId = this.data.task_version_id;
    }
    this.dataService.getOtherData('getAssignmentsFromComments', { 'project_id': this.data.project_id, 'task_id': this.data.task_id, 'task_version_id': vId, 'status': this.data.status, 'to_status': this.taskComment.status, 'isExternalReview': this.is_external_review, 'isStagedReview': this.is_stagged_review })
      .subscribe((success) => {
        this.taskAssVerList = success.taskAssVerList;

        let i = 0;
        let that = this;
        _.forEach(this.taskAssVerList, function (v) {
          /*if (v.start_date) {
            that.startDate['startDate'] = moment(v.start_date).format('DD MMM YYYY hh:mm A');
            that.taskAssVerList[i]['start_date'] = moment(v.start_date).format('DD MMM YYYY hh:mm A');
          }*/
          that.dateOptions[i] = [];
          that.dateOptions[i]['start_date'] = {
            singleDatePicker: true,
            autoUpdateInput: true,
            showDropdowns: true,
            timePickerIncrement: 5,
            timePicker: true,
            autoApply: true,
            drops: 'down',
            "maxDate": "31 Dec 2030",
            locale: {
              format: 'DD MMM YYYY hh:mm A'
              //format: 'DD MMM YYYY'+ ' 00:00'
            }
          };

          that.dateOptions[i]['end_date'] = {
            singleDatePicker: true,
            autoUpdateInput: true,
            showDropdowns: true,
            timePicker: true,
            timePickerIncrement: 5,
            autoApply: true,
            drops: 'down',
            locale: {
              format: 'DD MMM YYYY hh:mm A'
              //format: 'DD MMM YYYY'+ ' 23:59'
            }
          };

          if (that.taskAssVerList[i]['start_date'] != null && that.taskAssVerList[i]['start_date'] != '') {
            that.dateOptions[i]['start_date']['startDate'] = moment(that.taskAssVerList[i]['start_date']).format('DD MMM YYYY hh:mm A');
            that.taskAssVerList[i]['start_date'] = moment(that.taskAssVerList[i]['start_date']).format('DD MMM YYYY hh:mm A');
          }

          if (that.taskAssVerList[i]['end_date'] != null && that.taskAssVerList[i]['end_date'] != '') {
            that.dateOptions[i]['end_date']['startDate'] = moment(that.taskAssVerList[i]['end_date']).format('DD MMM YYYY hh:mm A');
            that.taskAssVerList[i]['end_date'] = moment(that.taskAssVerList[i]['end_date']).format('DD MMM YYYY hh:mm A');
          }
          i++;
        });

      }, (rejected) => {

      });
  }

  getRetakeTypes(query = '') {
    this.dataService.getOtherData('searchItemList', { list_name: 'retake_type', 'query': query['query'] })
      .subscribe((success) => {
        this.retakeType = success.data;
      }, (rejected) => {

      });
  }

  download(image) {
    window.location.href = this.uploadUrl + this.dirName + '/' + image;
  }

  multipleFilesUpdated(files) {
    let i;
    let multipleFiles_temp = [];
    this.getFileUploadData = files;
    for (i = 0; i < files.length; i++) {
      let file = files[i];
      // this.getFileUploadData = files[i];
      let temp_file = {};
      if (file) {
        var reader = new FileReader();
        temp_file['image_name'] = file.name;
        temp_file['image_display_name'] = file.name;
        temp_file['image_type'] = file.type;

        reader.onload = (event: any) => {
          if (this.getAllSettingResponse.is_aws_s3_enable == 0) {
            let size = (event.total / 1024 / 1024).toFixed(2);
            let fileSize = Number(size);
            if (fileSize > 3) {
              this.fileSizeError = true;
            }
          }
          // // temp_file['url'] = event.target.result;
          // if (temp_file['image_type'] != 'image/png' && temp_file['image_type'] != 'image/jpg' && temp_file['image_type'] != 'image/jpeg' && temp_file['image_type'] != 'image/gif') {
          //   temp_file['preview_src'] = './assets/images/default/attechment.png';
          // } else {
          //   // temp_file['preview_src'] = temp_file['url'];
          // }
        }
        reader.readAsDataURL(file);

        setTimeout(() => {
          multipleFiles_temp.push(temp_file);
        }, 0);
      }
    }

    setTimeout(() => {
      if (this.isFirstTime) {
        this.multipleFiles = this.multipleFiles.concat(multipleFiles_temp);
        this.isFirstTime = false;
      } else {
        this.multipleFiles = this.TempMultipleFiles.concat(multipleFiles_temp);
      }
    }, 0);
  }

  unlinkMultiImage(index, event) {
    event.stopPropagation();
    this.multipleFiles.splice(index, 1);
    this.getFileUploadData = [];
    this.fileSizeError = false;
  }

  getCheckList() {
    this.dataService.getOtherData('checklistList', { 'project_id': this.data.project_id, 'task_id': this.data.task_id, 'currentRole': this.currentRole })
      .subscribe((success) => {
        this.checklistData = success.data;
      }, (rejected) => {

      });
  }

  onChecklistSubmit(form: NgForm) {
    this.isCheklistSubmitted = true;
    form.value.project_id = this.data.project_id;
    form.value.task_id = this.data.task_id;
    if (this.data.allow_status) {
      form.value.task_version_id = this.data.id;
    } else {
      form.value.task_version_id = this.data.task_version_id;
    }

    form.value.revision = this.data.revision_original;
    form.value.version = this.data.version_original;
    form.value.currentRole = this.currentRole;

    this.dataService.storeData('checklistStore', form.value)
      .subscribe((success) => {

        setTimeout(() => {
          this.checklist.comment = '';
          this.getCheckList();
        }, 2);
        this.isCheklistSubmitted = false;
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
        }
        this.isCheklistSubmitted = false;
      });
  }

  cellClick(id): any { //onCellClick
    if (id != undefined && !this.data.is_artist) {
      $('.cursor-pointer').removeClass('active');
      $('.' + id + '-td').addClass('active');
      $('.' + id + '-input input').focus();
    }
  }

  cellUpdate(data: any = {}, isDelete = false, isDone = false, checkboxVal = ''): any {
    if (isDelete) {
      this.dataService.deleteData('checklistDestroy', [data.id])
        .subscribe((success) => {
          $('.' + data.id + '-td').removeClass('active');
          this.getCheckList();
        }, (rejected) => {

        });

    } else {

      data['is_done'] = isDone;
      this.dataService.updateData('checklistUpdate/' + data.id, data)
        .subscribe((success) => {

          $('.' + data.id + '-td').removeClass('active');
          this.getCheckList();

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.edit_errors[data.id] = err.error;
          }
        });


    }
  }


  getVersionData() {
    this.dataService.getOtherData('getTaskVersions', { 'project_id': this.data.project_id, 'task_id': this.data.task_id })
      .subscribe((success) => {
        // this.taskVersionsData = success.data;
        this.taskVersionsData = success.data.reverse();
        console.log(this.taskVersionsData)
        this.isDatatableLoading = false;
      }, (rejected) => {
        this.isDatatableLoading = false;
      });
  }

  getTimeLogData() {
    this.dataService.getOtherData('getTimeLog', { 'project_id': this.data.project_id, 'task_id': this.data.task_id })
      .subscribe((success) => {
        this.timeLogData = success.data;
        this.timeLogArr = success.timeLog;

      }, (rejected) => {

      });
  }

  getAssetData() {
    this.dataService.getDataById('assetData/' + this.data.asset_id + '/edit')
      .subscribe((success) => {
        this.assetData = success.data;

      }, (rejected) => {

      });
  }

  frameInOutHistory: any = [];
  getShotData() {
    this.frameInOutHistory = [];
    this.camArray = [];
    this.dataService.getDataById('shotData/' + this.data.shot_id + '/edit')
      .subscribe((success) => {
        this.shotData = success.data;
        if (this.shotData.shot_frame_in_out.length > 0) {
          this.shotData.shot_frame_in_out.forEach((cam: any, index: any) => {
            if (cam.frame_in != null && cam.frame_out != null) {
              const sumArr = cam.frame_in.reduce((acc, val, index) => {
                return acc + cam.frame_out[index];
              }, 0);
              cam.no_of_frames = sumArr;
            }
            if (cam.history.length > 0) {
              cam.history.map((item: any) => {
                item.historyCamera = cam.camera_name;
                this.frameInOutHistory.push(item);
              });
            }
            cam.arrayOfObjects = cam.frame_in.map((currentValue, index) => {
              return {
                frameIn: currentValue,
                frameOut: cam.frame_out[index],
              }
            });
          });
        }

        if (this.shotData.shot_frame_in_out.length > 0) {
          this.shotData.shot_frame_in_out.forEach((cam: any, index: any) => {
            const arrayOfObjects = cam.frame_in.map((currentValue, index) => {
              return {
                frameIn: currentValue,
                frameOut: cam.frame_out[index],
              }
            })
            arrayOfObjects.length == 0 ? '' : this.onAddCam('');
            for (let j = 0; j < arrayOfObjects.length; j++) {
              this.onAddFrames(index, '', arrayOfObjects[j].frameIn, arrayOfObjects[j].frameOut);
            }
          });
        }
        if (this.shotData.shot_frame_in_out.length > 0) {
          this.sumAllFrames();
        }
      }, (rejected) => {

      });
  }


  /**********    Side Window    **********/

  openDetailSideWindow(type, data) {
    this.displayDetail.emit();
  }

  getTaskDepartments(query, column, data, isRejectInventory = false, isMultipleRetake = false) {
    let taskData = {};
    taskData['project_id'] = data.project_id;
    // taskData['season_id'] = data.ss_season_id;
    // taskData['episode_id'] = data.e_episode_id;
    // taskData['sequence_id'] = data.sq_sequence_id;
    taskData['task_id'] = data.task_id;
    taskData['assigned_to'] = data.assigned_to;
    taskData['asset_id'] = data.asset_id;
    taskData['shot_id'] = data.shot_id;
    taskData['e_episode_id'] = data.e_episode_id;
    taskData['episode_id'] = data.episode_id;
    taskData['sequence_id'] = data.sequence_id;
    taskData['sq_sequence_id'] = data.sq_sequence_id;
    taskData['department_id'] = data.department_id;
    taskData['task_version_id'] = data.task_version_id;
    taskData['revision'] = data.revision;
    taskData['version'] = data.version;
    taskData['department_name'] = data.department_name;
    taskData['department_tasktype_name'] = data.department_tasktype_name;
    taskData['isRejectInventory'] = isRejectInventory;
    taskData['isMultipleRetake'] = isMultipleRetake;
    taskData['currentStatus'] = data.actualStatus;
    taskData['currentRole'] = this.currentRole;
    let result = JSON.parse(localStorage.getItem('episodicSeries'));
    taskData['isEpisodic'] = result;
    if (column == 'departments') {
      this.getTasksDepartments(data, taskData, query);
    }
  }

  getTasksDepartments(data, taskData, query) {
    this.dataService.getOtherData('getTaskDepartments', { 'data': taskData, 'query': query['query'] })
      .subscribe((success) => {
        this.departments = success.data.tasksDepts;
        if (data.department_tasktype_id != null) {
          this.departments = this.departments.filter(dept => dept.dtt_id !== data.department_tasktype_id);
        } else {
          this.departments = this.departments.filter(dept => dept.department_id !== data.department_id);
        }
      }, (rejected) => {

      });
  }

  getTaskDepartmentsToRejectInventory(data, taskData, query) {
    this.dataService.getOtherData('getTaskDepartmentsToRejectInventory', { 'data': taskData, 'query': query['query'] })
      .subscribe((success) => {
        this.departments = success.data.tasksDepts;
        if (data.department_tasktype_id != null) {
          this.departments = this.departments.filter(dept => dept.dtt_id !== data.department_tasktype_id);
        } else {
          this.departments = this.departments.filter(dept => dept.department_id !== data.department_id);
        }
      }, (rejected) => {

      });
  }

  rejectInventory(form: NgForm) {
    this.isInventoryRejected = true;
    this.SaveButtonTextRI = 'SAVING';
    form.value.task_id = this.data.task_id;
    form.value.task_assignment_id = this.data.ta_id;
    form.value.task_version_id = this.data.task_version_id;
    form.value.is_running = this.data.is_running;
    form.value.project_id = this.data.project_id;
    form.value.department_id = this.data.department_id;
    if (this.isRejectInventory) {
      this.dataService.getOtherData('rejectInventoryFromArtist', { 'data': form.value })
        .subscribe((success) => {
          this.toastrService.notify('success', '', success.data.message);
          this.isInventoryRejected = false;
          this.departments = [];
          this.taskDepts.departments = [];
          this.taskDepts.notes = '';
          this.SaveButtonTextRI = 'SAVED';

          if (success.data.notifications.length > 0) {
            let that = this;
            _.forEach(success.data.notifications, function (notification, key) {
              that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
            });
          }

          if (this.data.is_running) {
            this.dataService.getCurrentRunningTask([]);
          }
          setTimeout(() => {
            this.SaveButtonTextRI = 'SAVE';
            this.closeTaskDetail(success.data);
          }, 2);
        }, (rejected) => {

          this.isInventoryRejected = false;
        });
    }
  }

  resendInventory(form: NgForm) {
    let taskData = {};
    taskData['project_id'] = this.data.project_id;
    taskData['task_id'] = this.data.task_id;
    taskData['asset_id'] = this.data.asset_id;
    taskData['shot_id'] = this.data.shot_id;
    taskData['episode_id'] = this.data.episode_id;
    taskData['sequence_id'] = this.data.sequence_id;
    taskData['auto_created'] = this.data.auto_created;
    taskData['task_assignment_id'] = this.data.ta_id;
    taskData['assigned_to'] = this.data.assigned_to;
    taskData['department_id'] = this.data.department_id;
    taskData['task_version_id'] = this.data.task_version_id;
    taskData['revision'] = this.data.revision;
    taskData['version'] = this.data.version;
    taskData['department_name'] = this.data.department_name;
    taskData['department_tasktype_name'] = this.data.department_tasktype_name;
    taskData['inv_rej_from_task'] = this.data.inv_rej_from_task;
    taskData['inv_rej_from_assignment'] = this.data.inv_rej_from_assignment;
    this.dataService.getOtherData('resendInventoryFromArtist', { 'data': form.value, 'taskData': taskData })
      .subscribe((success) => {
        this.isInventoryRejected = false;
        this.taskDepts.notes = '';
        if (success.data.notifications.length > 0) {
          let that = this;
          _.forEach(success.data.notifications, function (notification, key) {
            that.dataService.addNotification(that.clientCode, notification['user_id'], notification);
          });
        }
        this.toastrService.notify('success', '', success.data.message);
        this.closeTaskDetail(success.data);
      }, (rejected) => {

        this.isInventoryRejected = false;
      });

  }

  resetResendInventory() {
    this.taskDepts.notes = "";
  }

  onFilterSelect(column, $event) {
    //this.onChangeTable(this.config);
  }

  onFilterUnselect(column, $event) {
  }

  isCheckBoxChecked(flag, field) {
    if (field == 'isMultipleRetake') {
      if (flag) {
        this.taskComment.isMultipleRetake = 1;
      } else {
        this.taskComment.isMultipleRetake = 0;
        //Reset multiple departments select
        this.taskComment.departments = [];
      }
    } else if (field == 'isNotifyNextDept') {
      if (flag) {
        this.taskComment.isNotifyNextDept = 1;
      } else {
        this.taskComment.isNotifyNextDept = 0;
      }
    }
  }

  framesCellClick(key) {
    if (!this.data.isReview) {
      return false;
    }
    this.showInput = true;
    $('.cursor-pointer').removeClass('active');
    $('.' + key + '-td').addClass('active');
    $('.' + key + '-input input').focus();
  }

  framesCellUpdate(key: any, data) {
    this.showInput = false;
    this.updateFramesPerSeconds(key, data);
  }

  completionPercentageCellClick(key) {
    if (this.userId != this.data.assigned_to) {
      return false;
    }
    this.showCompletionInput = true;
    this.errors = {};
    $('.cursor-pointer').removeClass('active');
    $('.' + key + '-td').addClass('active');
    $('.' + key + '-input input').focus();
  }

  completionPercentageCellUpdate(key: any, data) {
    this.showCompletionInput = false;
    this.updateCompletionPercentage(key, data);
  }

  updateFramesPerSeconds(key, shot) {
    this.where.projectId = this.projectId;
    this.where.shotId = shot.id;
    this.where.no_of_frames = shot.no_of_frames;
    this.dataService.storeData('updateFramesPerSeconds', { 'data': this.where })
      .subscribe((success) => {
        if (success.status == 'success') {
          this.toastrService.notify('success', '', success.message);
        } else {
          this.toastrService.notify('warn', '', success.message);
        }
      }, (rejected) => {

      });
  }

  updateCompletionPercentage(key, data) {
    this.where.projectId = this.projectId;
    this.where.taskId = data.task_id;
    this.where.taskAssignmentId = data.ta_id;
    this.where.completion = data.completion;
    this.dataService.storeData('updateCompletionPercentage', { 'data': this.where })
      .subscribe((success) => {
        if (success.status == 'success') {
          this.toastrService.notify('success', '', success.message);
        } else {
          this.toastrService.notify('warn', '', success.message);
        }
      }, (error) => {
        error = error.json();
        this.errors = error.error;
        this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
      });
  }

  getTaskDependencyDetails() {
    this.dependencyDetails = [];
    // console.log(this.data);
    // this.dataService.getOtherData('getTaskDependencyDetails', { 'project_id': this.data.project_id, 'task_id': this.data.task_id, 'shotAssets': this.data.shotAssets, 'e_episode_id': this.data.e_episode_id, 'sq_sequence_id': this.data.sq_sequence_id })
    this.dataService.getOtherData('getTaskDependencyDetails', { 'project_id': this.data.project_id, 'task_id': this.data.task_id, 'shotAssets': this.data.shotAssets, 'e_episode_id': (this.data.hasOwnProperty('e_episode_id') ? this.data.e_episode_id:this.data.episode_id), 'sq_sequence_id': (this.data.hasOwnProperty('sq_sequence_id') ? this.data.sq_sequence_id:this.data.sequence_id) })
      .subscribe((success) => {
        this.dependencyDetails = success.data;
      }, (rejected) => {

      });

  }

  getLinkedAssetsWithShot() {
    this.linkedAssets = [];
    this.dataService.getOtherData('getLinkedAssetsWithShot', { 'project_id': this.data.project_id, 'shot_id': this.data.shot_id })
      .subscribe((success) => {
        this.linkedAssets = success.data;
      }, (rejected) => {

      });
  }

  ngOnDestroy() {
  }

  // Select retak type
  onSelectRetakeType($event: any) {
    this.errors.retake_type = null;
    this.isCommentSubmitted = false;
  }

  /** Open modal with image and video */
  public openImageinModal(data: any) {
    this.modalContentURL = data;
  }

  /** view in annotation scree, */
  viewinReviewScreen(tempdata) {
    //console.log("this.data", this.data);
    let taskDetail: any = {};
    taskDetail.data = tempdata;
    // taskDetail.wholeDataFromTaskDetail = this.data;
    taskDetail.projectName = this.getProjectDatabyId.projects.name;
    taskDetail.currentTaskDetail = this.data.info;
    taskDetail.version_id = this.data.version_original;
    taskDetail.revision_id = this.data.revision_original;
    taskDetail.task_version_id = this.data.task_version_id;
    taskDetail.actualStatus = this.data.actualStatus;
    taskDetail.isReview = this.data.isReview;
    taskDetail.department_id = this.data.department_id;
    taskDetail.department_tasktype_id = this.data.department_tasktype_id;
    taskDetail.is_external_review = this.data.is_external_review;
    taskDetail.is_stagged_review = this.data.is_stagged_review;
    taskDetail.task_type = this.data.task_type;

    if (tempdata.aws_s3_url == '') {
      taskDetail.localImagePath = this.uploadUrl + 'comment_attachment/' + tempdata.image_display_name;
    }

    //console.log(taskDetail)

    localStorage.setItem('annotationData', JSON.stringify(taskDetail));
    // window.open('#/' + this.commonJs.getClientCodePrefix() + '/video-annotation', "_blank");
  }


  /** Camera frameIn and FrameOut */
  // To add fields in formArray for frames
  onAddFrames(camIndex, frameIndex, framein, frameout) {
    this.camArray[camIndex].frames.push({ frameIn: framein, frameOut: frameout });
  }

  // To add camera
  onAddCam(type) {
    let newCamName = this.camArray.length + 1;
    type == 'manualAdd' ? this.camArray.push({ name: 'cam' + newCamName, id: newCamName, frames: [{ frameIn: null, frameOut: null }] }) : this.camArray.push({ name: 'cam' + newCamName, id: newCamName, frames: [] });
  }

  // To remove frame
  onRemoveFrames(camIndex, frameIndex) {
    // //console.log(camIndex, frameIndex);
    this.camArray[camIndex].frames.splice(frameIndex, 1);
    this.sumAllFrames();
  }

  /** Sum of all the frame In and Out */
  sumAllFrames() {
    this.shotData.no_of_frames = null;
    for (let i = 0; i < this.camArray.length; i++) {
      const sum: any = this.camArray[i].frames.reduce((total, currentValue) => {
        return total + (currentValue.frameIn ? currentValue.frameIn : 0) - (currentValue.frameOut ? currentValue.frameOut : 0);
      }, 0);
      this.shotData.no_of_frames = this.shotData.no_of_frames - sum;
      this.shotData.no_of_frames = Math.abs(this.shotData.no_of_frames) === 0 ? null : Math.abs(this.shotData.no_of_frames);
      this.isMultipleCameras = this.shotData.no_of_frames == null ? false : true;
    }
    this.cd.markForCheck();
  }

  // check if frameIn or frameOut already entered into the same camera
  checkFramePair(type, frameType, camera) {
    if (type == 'frameIn') {
      if (camera.frames.filter((frame) => (frame.frameIn == frameType)).length >= 2) {
        this.repeatedFrame = frameType;
      } else {
        this.repeatedFrame = null;
      }
    } else {
      if (camera.frames.filter((frame) => (frame.frameOut == frameType)).length >= 2) {
        this.repeatedFrame = frameType;
      } else {
        this.repeatedFrame = null;
      }
    }
    this.sumAllFrames();
  }

  /** Update shot */
  updateShot() {
    const payload: any = {
      project_id: this.shotData.project_id,
      totalFrames: null,
      camArray: null,
      id: this.shotData.id
    }
    let totalFrames = 0;
    let validCamArray = this.camArray.map((cam) => {
      cam.frames = cam.frames.filter((fr) => {
        // will get only frame inputs of cameras whose frame-In/frame-Out are not empty
        if (fr.frameIn != null && fr.frameOut != null) {
          // will get total frameIn & frameOut differances at the end
          totalFrames = totalFrames + (fr.frameOut - fr.frameIn);
          return fr;
        }
      })
      { return cam }
    });
    payload.totalFrames = totalFrames;
    payload.camArray = validCamArray;
    this.dataService.updateData('frameInOutUpdate' + '/' + this.shotData.id, payload)
      .subscribe((success) => {
        this.isEditCameras = false;
        if (success.status == 'success') {
          this.toastrService.notify('success', '', 'Cameras Frame In & Out updated successfully.');
          this.getShotData();
        }
      }, (error) => {
        if (error.error) {
          this.isEditCameras = false;
          // var err = error.error;
          // this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
        }
      });
  }

  selectedChips: string[] = [];
  newChipValue: string = '';

  // For selected preset layer
  onPresetlayerSelected(event) {
    if (event == this.presetLayersList[1] || event == 'All') {
      this.presetLayersList.slice(2).forEach((ele) => {
        if (!this.selectedChips.includes(ele)) {
          this.newChipValue = ele;
          this.addChip();
        }
      });
      return;
    }

    if (event != this.presetLayersList[0] && event != this.presetLayersList[1]) {
      this.newChipValue = event;
      this.addChip();
    }
  }

  addChip() {

    if (this.updateChipData) {
      if (this.updateChipData.type === "edit" && this.selectedChips[this.updateChipData.index] !== undefined) {
        this.selectedChips[this.updateChipData.index] = this.newChipValue;
      }
      this.newChipValue = '';
      const payload = {
        shot_id: this.data.shot_id,
        layers: this.selectedChips
      }
      this.dataService.addUpdateLayers(payload).subscribe((item: any) => {
        //console.log(item)
        if (item.status == 'success') {
          this.toastrService.notify('success', '', item.message);
          this.selectedChips = item.completion;
          this.data.layers = item.completion;
          this.updateChipData = null;
        }
      }, (error: any) => {
        this.toastrService.notify('success', '', error.message);
      });
    } else {
      if (this.newChipValue && !this.selectedChips.includes(this.newChipValue)) {
        this.selectedChips.push(this.newChipValue);
        this.newChipValue = '';
        const payload = {
          shot_id: this.data.shot_id,
          layers: this.selectedChips
        }
        this.dataService.addUpdateLayers(payload).subscribe((item: any) => {
          //console.log(item)
          if (item.status == 'success') {
            this.toastrService.notify('success', '', item.message);
            this.selectedChips = item.completion;
            this.data.layers = item.completion;
            this.updateChipData = null;
          }
        }, (error: any) => {
          this.toastrService.notify('success', '', error.message);
        });
      }
    }

  }

  updateChipData: any;

  onChildEvent(chipValue: any) {
    if (chipValue.type == 'remove') {
      this.selectedChips = this.selectedChips.filter(chip => chip !== chipValue.value);
      const payload = {
        shot_id: this.data.shot_id,
        layers: this.selectedChips
      }
      this.dataService.addUpdateLayers(payload).subscribe((item: any) => {
        if (item.status == 'success') {
          this.toastrService.notify('success', '', item.message);
          this.selectedChips = item.completion;
          this.data.layers = item.completion;
        }
      }, (error: any) => {
        this.toastrService.notify('success', '', error.message);
      });
    } else {
      this.newChipValue = chipValue.value;
      this.updateChipData = chipValue;
    }

  }

  showCamersFrames(camerasFrames: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(camerasFrames, { class: "my-modal-cameras" });
  }

  //To detect the currently loaded page on pagination changed
  displayActivePage(activePageNumber: number) {
    setTimeout(() => {
      this.activePage = activePageNumber;
      this.allCamerasData = this.frameInOutHistory.slice((activePageNumber - 1) * this.recordsPerPage, (activePageNumber - 1) * this.recordsPerPage + this.recordsPerPage);
    }, 100);
  }

  closeTaskDetail(data) {
    this.closeSideWindow.emit(data);
  }

  // When mention selected
  mentionSelect(event) {
    this.selectedMentions.push(event);
    this.taskComment.comment = this.taskComment.comment + ' ';
  }

  // To check validation percentage
  percentageValidation(event) {
    if (event.target.value > 100) {
      return event.target.value = 100;
    } else if (event.target.value < 0) {
      return event.target.value = Math.abs(event.target.value);
    }
    else {
      return event.target.value;
    }
  }

  /** Set bg color */
  setBgColor(data: any) {
    if (data != 'dependency' && data) {
      return data;
    } else if (data == 'dependency' && data) {
      return '#ccb9a1'
    }
  }

  // For showing only layers which are not added
  uniquePresetlayers(layersArray) {
    layersArray = layersArray.filter(layer => !this.selectedChips.includes(layer))
    // console.log(layersArray)
    return layersArray;
  }

  // For getting first two letters of name
  getInitials(inputString: string): string {
    const words = inputString.trim().split(' ');
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      const secondInitial = words[1].charAt(0).toUpperCase();
      return `${firstInitial}${secondInitial}`;
    } else if (words.length === 1) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      return `${firstInitial}`;
    } else {
      return '';
    }
  }

}
