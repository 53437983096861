import { Component, OnInit } from '@angular/core';
import { CommonJs } from '../../common/common-js';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

  role = localStorage.getItem('role');
  //homeURL:any;
  homeURL = '/dashboard';

  public isSuperAdmin: boolean;
  public AccessCode = '';

  constructor(
    public commonJs: CommonJs,
    public router: Router,
    private activatedRoute: ActivatedRoute) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isSuperAdmin = (event.url.indexOf('superadmin') !== -1);
      }
    });

  }

  ngOnInit() {
    // this.activatedRoute.params.subscribe((params: Params) => {
    //   });
    let clientCodePre = this.commonJs.getClientCodePrefix();
    if (this.role == 'superadmin') {
      this.homeURL = '/accounts';
    }

    this.activatedRoute.params.subscribe(val => {
      this.AccessCode = localStorage.getItem('access_code');
    });
  }

}
