import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import * as _ from "lodash";
declare var $: any;
import { CommonJs } from '../../../common/common-js';

@Component({
  selector: 'role-type',
  templateUrl: './role-type.component.html',
  styleUrls: ['./role-type.component.css']
})
export class RoleTypeComponent implements OnInit {

  entityName = 'Role Types';
  entityNameSingular = 'Role Type';
  apiRoutePrefix = 'role_type';

  @Input('attr.data') dataHtml: any;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @ViewChild("roleInput", { static: false }) roleInput: ElementRef;
  @Output() closeCreateRoleAdmins = new EventEmitter();

  data: any;
  roles_data: any;
  hideRoles: boolean = false;
  myform: any;
  roles: RoleAdmins;
  onClose: any;
  errors: any;
  focused: string = '';
  SaveButtonText: string = 'SAVE';
  DeleteButtonText: string = 'DELETE';
  isDeleteMode: any;
  clickOn: string = 'save';
  deleteId: number;
  deleteName: string = '';
  isDisplyFrm: boolean = false;
  alertMessage: string = '';
  settingsAll: any = JSON.parse(localStorage.getItem('settings'));
  accessCode = localStorage.getItem('access_code');

  constructor(
    private dataService: DataService,
    public commonJs: CommonJs
  ) { }

  ngOnInit() {
    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
    }
    this.roles = {
      name: '',
      slug: ''
    }
    $.AdminBSB.input.activate();
    this.subngOnInit();
  }

  getRole(id) {
    this.dataService.getDataById(this.apiRoutePrefix + '/' + id + '/edit')
      .subscribe((success) => {
        this.roles = success.data;
        this.hideRoles = true;
        this.isDisplyFrm = true;
        this.focused = 'focused';
      }, (rejected) => {
        // //console.log(rejected);
      });
  }
  subngOnInit() {

    //this.roleInput.nativeElement.focus();
    this.dataService.getListData(this.apiRoutePrefix + 'List', '')
      .subscribe((success) => {
        this.roles_data = success.data;
      }, (rejected) => {
        // //console.log(rejected);
      });

  }

  onSubmit(form: NgForm) {
    if (this.clickOn == 'save') {
      this.SaveButtonText = 'SAVING';
    }
    if (this.roles['id'] != undefined && this.roles['id'] != '') {
      if (this.roles) {
        form.value.id = this.roles['id'];
      }
      this.dataService.updateData(this.apiRoutePrefix + '/' + form.value.id, form.value)
        .subscribe((success) => {
          // Save effect within button
          if (this.clickOn == 'save') {
            this.SaveButtonText = 'SAVED';
            this.subngOnInit();
            setTimeout(() => {
              this.SaveButtonText = "SAVE";
            }, 2);
            this.alertMessage = success.message;
            this.hideRoles = false;
            this.roles = {
              name: '',
              slug: ''
            }
          }
          this.isDisplyFrm = false;
          this.focused = '';

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.errors = err.error;
          }
        });
    } else {
      this.dataService.storeData(this.apiRoutePrefix, form.value)
        .subscribe((success) => {
          // Save effect within button
          if (this.clickOn == 'save') {
            this.SaveButtonText = 'SAVED';
            this.subngOnInit();
            setTimeout(() => {
              this.SaveButtonText = "SAVE";
            }, 2);
            this.alertMessage = success.message;

            this.hideRoles = false;
            this.roles = {
              name: '',
              slug: ''
            }
            this.isDisplyFrm = false;
          }

        }, (error) => {
          //this.alertMessage = 'Something went wrong, Please try again!';
          if (error.error) {
            var err = error.error;
            this.errors = err.error;
          }
        });
    }
  }

  backToCreate(event) {
    event.stopPropagation();
    this.hideRoles = false;
    this.isDeleteMode = false;
    this.isDisplyFrm = false;
    this.roles = {
      name: '',
      slug: ''
    };
    //this.roleInput.nativeElement.focus();
  }

  deleteRole(id, name) {
    event.stopPropagation();
    this.deleteId = id;
    this.deleteName = name;
    this.isDeleteMode = true;
  }

  deleteRoleAction(id) {
    let that = this;
    this.DeleteButtonText = 'DELETEING';
    this.dataService.deleteData(that.apiRoutePrefix + 'Destroy', { id })
      .subscribe((success) => {
        //that.onChangeTable(that.config); 
        that.DeleteButtonText = 'DELETED';
        setTimeout(() => {
          that.subngOnInit();
          that.hideRoles = false;
          that.isDeleteMode = false;
          that.DeleteButtonText = "DELETE";
        }, 2);

      }, (rejected) => {
        // //console.log(rejected);
      });
  }

  onDropSuccess($event) {
    let rec = [];
    rec.push($event);
  }

  onDragEnd() {
    // this.targetList.push($event.dragData);
  }

  close() {
    this.closeCreateRoleAdmins.emit();
  }

  frmToggle() {
    this.isDisplyFrm = !this.isDisplyFrm;
    this.hideRoles = !this.hideRoles;
    //this.roleInput.nativeElement.focus();
    this.focused = 'focused';
    this.roles = {
      name: '',
      slug: ''
    }
  }

}

interface RoleAdmins {
  name: string,
  slug: string
}