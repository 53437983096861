import { environment } from "../../environments/environment";

export class AppConfigs {

  public clientCode = '';
  // public appUrl = 'http://127.0.0.1/projects/vani/vani-api/';
  //public appUrl = 'http://127.0.0.1/vani-api-upgrade/';
  // public appUrl = 'https://app.vanisoftware.com/api/';

  // For offline only
  // public appUrl = 'https://app.vanisoftware.com/api/';

  public appUrl = environment.appUrl + 'api/';
  public serverUrl = '';
  public publishingServerUrl = '';
  public uploadUrl = environment.appUrl;

  public token = '';

  setVal(key, value) {
    this[key] = value;
  }

  // ! All Static Data

  // ? Create User and user Component
  public user_gender = [{ "id": "0", "name": "Male" }, { "id": "1", "name": "Female" }, { "id": "2", "name": "Other" }];

  public user_mode = [{ "id": 'contract', "name": "Contract" }, { "id": 'intern', "name": "Intern" }, { "id": 'payroll', "name": "Payroll" }, { "id": 'trainee', "name": "Trainee" }];

  public user_vertical = [{ "id": '2d', "name": "2D" }, { "id": '3d', "name": "3D" }, { "id": 'shared', "name": "Shared" }];

  public user_attrition_type = [{ "id": 'voluntary', "name": "Voluntary" }, { "id": 'involuntary', "name": "Involuntary" }];

  public prj_country = [{ "id": 'Canada', "name": "Canada" }, { "id": 'Germany', "name": "Germany" }, { "id": 'India', "name": "India" }, { "id": 'USA', "name": "USA" }];

  public prj_status = [
    { "id": "In Bidding", "name": "In Bidding" },
    { "id": "In Production", "name": "In Production" },
    { "id": "On Hold", "name": "On Hold" },
    { "id": "Cancelled", "name": "Cancelled" },
    { "id": "Completed", "name": "Completed" },
  ];

  public prj_status_all = [
    { "id": "All", "name": "All" },
    { "id": "In Bidding", "name": "In Bidding" },
    { "id": "In Production", "name": "In Production" },
    { "id": "On Hold", "name": "On Hold" },
    { "id": "Cancelled", "name": "Cancelled" },
    { "id": "Completed", "name": "Completed" },
  ];

  public prj_output_resolution = [
    { "id": "640 x 480", "name": "640 x 480" },
    { "id": "1280 x 720", "name": "1280 x 720" },
    { "id": "1920 x 1080", "name": "1920 x 1080" },
    { "id": "2560 x 1440", "name": "2560 x 1440" },
    { "id": "2048 x 1080", "name": "2048 x 1080" },
    { "id": "3840 x 2160", "name": "3840 x 2160" },
    { "id": "7680 x 4320", "name": "7680 x 4320" },
  ];

  public prj_category = [{ "id": "2D", "name": "2D" }, { "id": "3D", "name": "3D" }, { "id": "Shared", "name": "Shared" }];

  public sht_ast_complexity = [{ "id": "Simple", "name": "Simple" }, { "id": "Medium", "name": "Medium" }, { "id": "Complex", "name": "Complex" }, { "id": "Very Complex", "name": "Very Complex" }, { "id": "Priority", "name": "Priority" }];

  public shot_subtype = [{ "id": "R&D", "name": "R&D" }, { "id": "Shot", "name": "Shot" }];

  public assets_subtype =
    [{ "id": "Character", "name": "Character" },
    { "id": "Set", "name": "Set" },
    { "id": "Prop", "name": "Prop" },
    ];

  public sht_ast_status = [
    //{"id":"Shipment Received","name":"Shipment Received","color":"#87CEFA","code":"LightSkyBlue", "class":"status-color-shipment-received"},
    { "id": "Awaiting Inventory", "name": "Awaiting Inventory", "shortcut": "Wtg", "color": "#A9A9A9", "code": "#A9A9A9", "class": "status-color-awaiting-inventory" },
    { "id": "Inventory", "name": "Inventory", "shortcut": "Inv", "color": "#87CEFA", "code": "#87CEFA", "class": "status-color-inventory" },
    { "id": "WIP", "name": "WIP", "shortcut": "Wip", "color": "#1a66ef", "code": "#1a66ef", "class": "status-color-wip" },
    { "id": "Sent for Review", "name": "Sent for Review", "shortcut": "Rvw", "color": "#FF9800", "code": "#FF9800", "class": "status-color-sent-for-review" },
    { "id": "Done", "name": "Done", "shortcut": "Done", "color": "#a5d6a8", "code": "#a5d6a8", "class": "status-color-done" },
    { "id": "Sent to Client", "name": "Sent to Client", "shortcut": "Stc", "color": "#a5d6a8", "code": "#a5d6a8", "class": "status-color-sent-to-client" },
    { "id": "Retake", "name": "Retake", "shortcut": "Rtk", "color": "#A569BD", "code": "#A569BD", "class": "status-color-retake" },
    //{"id":"Revised Move Forward","name":"Revised Move Forward","color":"#388e3c","code":"#388e3c", "class":"status-color-revised-move-forward"},
    { "id": "Approved", "name": "Approved", "shortcut": "Apr", "color": "#2b982b", "code": "#2b982b", "class": "status-color-approved" },
    { "id": "Unapproved", "name": "Unapproved", "shortcut": "Upr", "color": "#ff6666", "code": "#ff6666", "class": "status-color-unapproved" },
    { "id": "On Hold", "name": "On Hold", "shortcut": "Hld", "color": "#bbb", "code": "#bbb", "class": "status-color-on-hold" },
    { "id": "OOP", "name": "OOP", "shortcut": "Oop", "color": "#bbb", "code": "#bbb", "class": "status-color-oop" },
    { "id": "NA", "name": "NA", "shortcut": "Na", "color": "#bbb", "code": "#bbb", "class": "status-color-na" },
    { "id": "CBB", "name": "CBB", "shortcut": "Cbb", "color": "#ff6666", "code": "#ff6666", "class": "status-color-cbb" },
  ];

  public task_undertaking = [{ "id": "In House", "name": "In House" }, { "id": "Outsourced", "name": "Outsourced" }];

  public task_priority = [{ "id": "High", "name": "High" }, { "id": "Normal", "name": "Normal" }, { "id": "Low", "name": "Low" }];

  public reportDetails = [
    {
      'real-time-production': [
        {
          'header': 'Active Users',
          'details': 'This report shows the number of artists and their productivity in the last 12 hours in all production departments.',
          'bi': "&#x2022; Extended working hours can be easily accessed."
        }, {
          'header': '48 Hrs Projection',
          'details': "This report shows the total number of tasks projected to be completed in the next 48 hours, grouped in 4-hour intervals.",
          'bi': "&#x2022; Assembly line workflow with Concrete confirmation of delivery in next 48 hours based on projected date and time"
        },
      ]
    },
    {
      'asset-readiness': [
        {
          'header': '',
          'details': ' Analysis assets dependency for shot production and also give detail completion % of assets in respective sequence',
          'bi': "&#x2022; Assets priority can be changed to generate shot production inventory based on assets readiness. <br> &#x2022; If character, sets and props MODELING is completed then you can start LAYOUT/ BLOCKING in shot production. <br> &#x2022; If character, sets and props RIGGING is completed then you can start ANIMATION in shot production. <br> &#x2022; If character, sets and props SURFACING/ TEXTURING is completed then you can start LIGHTING in shot production"

        }
      ]
    },
    {
      'weekly-projection': [
        {
          'header': 'Assets',
          'details': 'This report shows asset tasks projected to be completed in selected week.',
          'bi': "&#x2022;Focused monitoring to keep project on track and complete projected delivery on time. <br> &#x2022; Comparison will be easy to check mandays of resource vs. mandays of tasks planned to delivery. <br> &#x2022; Easy data access for department target meeting."
        },
        {
          'header': 'Shots',
          'details': 'This report shows shot tasks projected to be completed in selected week.',
          'bi': "&#x2022;Focused monitoring to keep project on track and complete projected delivery on time. <br> &#x2022; Comparison will be easy to check mandays of resource vs. mandays of tasks planned to delivery. <br> &#x2022; Easy data access for department target meeting."
        }
      ]
    },
    {
      'weekly-vs-actual-projection': [
        {
          'header': 'Assets',
          'details': 'This report shows asset tasks projected to be completed in selected week and actual tasks completed during the week.',
          'bi': "&#x2022; Analysis actual completion against planned deliveries on project. <br> &#x2022; Review actual days/ hours spent in last week. <br> &#x2022; Easy data access for department meeting to review actual efficiency"
        },
        {
          'header': 'Shots',
          'details': 'This report shows shot tasks projected to be completed in selected week and actual tasks completed during the week.',
          'bi': "&#x2022;Analysis actual completion against planned deliveries on project. <br> &#x2022; Review actual days/ hours spent in last week. <br>&#x2022; Easy data access for department meeting to review actual efficiency"
        }
      ]
    },
    {
      'actual-delivery-by-week': [
        {
          'header': 'Assets/Shots',
          'details': 'This report shows version wise count of tasks delivered and approved per week for selected department.',
          'bi': "&#x2022; Trend analysis of deliveries and approval. <br> &#x2022; Easy to compare with resource capacity and output. <br> &#x2022; Ratio of deliveries in v01 and retake"
        },
        {
          'header': 'Seconds',
          'details': 'This report shows version wise total seconds of tasks delivered and approved per week for selected department.',
          'bi': "&#x2022; Trend analysis of deliveries and approval. <br> &#x2022; Easy to compare with resource capacity and output. <br> &#x2022; Ratio of deliveries in v01 and retake"
        }
      ]
    },
    {
      'artist-performance': [
        {
          'header': '',
          'details': ' This report shows v1 tasks delivered/day, seconds delivered/day, tasks and seconds approved/day, bid days spent efficiency, internal retake%, external retake%, and average VANI timelog project-wise.',
          'bi': "&#x2022;  Business intelligence Identify inefficient artists. <br>&#x2022;   Resource allocation can be changed based on performance and project type.<br>&#x2022;  The department filter is to only filter employees of the matching primary department. Report data for that employee still shows the combined work of that employee in all departments he has worked in."

        }
      ]
    },
    {
      'project-status': [
        {
          'header': 'Asset Status',
          'details': 'This report shows status wise count and list of asset tasks in each department for selected phase.',
          'bi': "&#x2022; Overview of tasks status"
        },
        {
          'header': 'Shot Status',
          'details': 'This report shows status wise seconds and shots count and list of shot tasks in each department.',
          'bi': " &#x2022; Overview of tasks status"
        },
        {
          'header': 'Current WIP',
          'details': 'This report shows assets and shots tasks on which artists are currently working on.',
          'bi': "&#x2022; You can check who is working on which task and by when he will be available. <br> &#x2022; Current workload in department. <br> &#x2022; Current WIP gives the Idea about expected output in coming days and current work"
        }
      ]
    },
    {
      'time-log': [
        {
          'header': 'Review',
          'details': 'This report shows all assets and shots task timelog for current date and allows to adjust time.',
          'bi': "&#x2022; Supervisor can check and approve artist time log for particular day. <br> &#x2022; If there is any change in time then he will be able to change the start and end time."

        },
        {
          'header': 'Working hrs',
          'details': 'This report shows artist\'s total working hrs per day for selected duration.',
          'bi': "&#x2022; It helps production to analyze artist & department productivity. <br>&#x2022; Primary department is just for information artist may have worked on secondary department also."
        }
      ]
    },
    {
      'reviw-pending': [
        {
          'header': 'Assets',
          'details': 'This report shows asset task count that is pending for review for less than and greater than 24 hrs.',
          'bi': "&#x2022; Who is delaying the review.<br> &#x2022; How many tasks review exceed 24 hours. <br>&#x2022; Turnaround time can be reduced by monitoring this report"

        },
        {
          'header': 'Shots',
          'details': 'This report shows shot task count that is pending for review for less than and greater than 24 hrs.',
          'bi': "&#x2022; Who is delaying the review.<br> &#x2022; How many tasks review exceed 24 hours. <br>&#x2022; Turnaround time can be reduced by monitoring this report"
        }
      ]
    },
    {
      'episode-progress': [
        {
          'header': 'Episode Progress',
          'details': "- This report shows department wise task progress for all shots in each episode.",
          'bi': 'Top level monitoring process to check episodes progress. <br>&#x2022; Delay can be easily identified if episode/sequence progress is not up the expected department'
        },
        {
          'header': 'Sequence Progress',
          'details': "&#x2022; This report shows department wise task progress for all shots in each sequence.",
          'bi': 'Top level monitoring process to check episodes progress. <br>&#x2022; Delay can be easily identified if episode/sequence progress is not up the expected department'
        }
      ]
    },
    {
      'episode-sequence completion': [
        {
          'header': 'Episodes Completion',
          'details': "This report shows total count of Episodes, Episodes’ Seconds, Episodes' Minutes, Episodes’ Shots, Episodes’ Bid days, Actual Spent days completed by weekly or monthly.",
          'bi': "&#x2022; Week/ month wise delivery to check department capacity. <br>&#x2022;  Monthly output from department in particular project. <br> &#x2022;  Episodes/ sequence completion by month (can be used for billing). <br>&#x2022;  Trend can be analyzed to improve the department efficiency"

        },
        {
          'header': 'Sequence Completion',
          'details': "This report shows total count of Sequences, Sequences’ Seconds, Sequences' Minutes, Sequences’ Shots, Sequences’ Bid days, Actual Spent days completed by weekly or monthly.",
          'bi': "&#x2022; Week/ month wise delivery to check department capacity. <br>&#x2022;  Monthly output from department in particular project. <br> &#x2022;  Episodes/ sequence completion by month (can be used for billing). <br>&#x2022;  Trend can be analyzed to improve the department efficiency"
        }
      ]
    },
    {
      'retake-analysis': [
        {
          'header': 'Assets',
          'details': "This report shows counts for Total Assets Reviewed, Total Assets Delivered, Total Assets Approved, Total Retake, Contractual Retake Allowance, Retakes Available / Exceeded, Contractual Retake %, Actual Retake % grouped by v1 to v4 and v5 or above.",
          'bi': ''

        },
        {
          'header': 'Shots',
          'details': "This report shows counts for Total Shots Reviewed, Total Shots Delivered, Total Shots Approved, Total Retake, Contractual Retake Allowance, Retakes Available / Exceeded, Contractual Retake %, Actual Retake % grouped by v1 to v4 and v5 or above for selected department.",
          'bi': "&#x2022;  Projected retake % in each version and actual retake %.<br>&#x2022; High retake % can be controlled by discussing this with team"
        }
      ]
    },
    {
      'tasks-summary': [
        {
          'header': '',
          'details': " - This report shows details of all tasks in a selected phase.",
          'bi': " &#x2022; Task wise status in each department. <br>&#x2022; Inventory analysis.<br>&#x2022; Dependency flow.<br>&#x2022; Advance planning to manage dropped inventory to increased workload"

        }
      ]
    },
    {
      'headcount': [
        {
          'header': 'Live Headcount',
          'details': 'This report shows department wise count of artists in table and bar chart format.',
          'bi': "NA"
        },
        {
          'header': 'Headcount Trend',
          'details': 'This report shows monthly hiring, attrition and total headcount for last year.',
          'bi': "NA"
        },
        {
          'header': 'Attrition',
          'details': 'This report shows attrition charts for tenure years, attrition vs hiring, voluntary vs involuntary and reason for attrition.',
          'bi': "NA"
        },
        {
          'header': 'Other',
          'details': 'This report shows pie chart for Employee Mode, Vertical, Location, Age Ratio, Gender',
          'bi': ''
        }
      ]
    },
    {
      'production-dashboard': [
        {
          'header': 'Assignment Pending',
          'details': '&#x2022; V01 : Awaiting Inventory tasks which are yet to be assigned. Child departments tasks are not counted while dependency is set and status is Awaiting Inventory. <br> &#x2022; Internal Retake which are not rescheduled and not started. <br> &#x2022; External Retake which are not rescheduled and not started.',
          'bi': ''
        },
        {
          'header': 'Inventory (Fresh + Retake)',
          'details': '&#x2022; V01 Inventory tasks.<br> &#x2022; Internal tasks that are not yet started. <br> &#x2022; External tasks that are not yet started.',
          'bi': ''
        },
        {
          'header': 'Late Started Tasks',
          'details': '&#x2022; Tasks which are started beyond it’s Projected Start date.',
          'bi': ''
        },
        {
          'header': 'Active Tasks',
          'details': '&#x2022; In&#x2022;House: Current active Artists or Leads .<br> &#x2022; Outsourced: &#x2022; Current active Vendors. <br> &#x2022; Total Artists: &#x2022; (Creative &#x2022; Artist + Lead) assigned in Project Team <br> &#x2022; Available: &#x2022; From assigned artist how many are available.',
          'bi': ''
        },
        {
          'header': 'Review Pending',
          'details': 'Internal: Tasks ready for internal review. <br> External: Tasks ready for client review.',
          'bi': ''
        },
        {
          'header': 'Retake Not Delivered',
          'details': '&#x2022; Retake tasks that are yet to be delivered.',
          'bi': ''
        },
        {
          'header': 'Retake %',
          'details': '&#x2022; Internal retake % <br> &#x2022; External Retake%',
          'bi': '<b>Note:</b>  When client review is enabled in a project, Internal retakes are not counted.'
        },
        {
          'header': 'Outsourced Tasks',
          'details': '&#x2022; Tasks assigned to Vendors.',
          'bi': ''
        },
        {
          'header': 'Rejected Inventory',
          'details': '&#x2022; Rejected Inventories.',
          'bi': ''
        },
        {
          'header': 'Tasks Exceeded Deadline',
          'details': '&#x2022; Tasks surpassed Projected End date and yet to be delivered.',
          'bi': ''
        },
        {
          'header': 'Time log Exceed > 10hr',
          'details': '&#x2022; Tasks which are active and exceed more than 10 hrs for current day.',
          'bi': ''
        },
        {
          'header': 'Projected Delivery',
          'details': '&#x2022; Today: Tasks that are projected to be delivered Today. <br> Tomorrow: Tasks that are projected to be delivered by Tomorrow <br> This Week: Tasks that are projected to be delivered by the end of this week',
          'bi': ''
        },
        {
          'header': 'Actual Delivery',
          'details': '&#x2022; Today: Tasks that are Delivered and Approved Today. <br> Yesterday: Tasks that are Delivered and Approved Yesterday. <br> This Week: Tasks that are Delivered and Approved Yesterday.',
          'bi': '<b>Note:</b>  When client review is enabled in a project, Approved tasks are counted under External Approved.'
        },
        {
          'header': 'Project Status',
          'details': '&#x2022; Current tasks status of projects.',
          'bi': ''
        },
      ]
    }

  ];


  constructor() {
    this.clientCode = localStorage.getItem('client_code');
    let appLocation = window.location.origin;
    ////this.uploadUrl = this.uploadUrl+'public/uploads/'+this.clientCode+'/';
    // this.appUrl = window.location.origin;
    /*******  For offline setup uncomment if else condition (start) ***************/

    if (environment.prodType === 'offline') {
      if (appLocation == "https://api.vanisoftware.com/") {
        this.appUrl = 'https://api.vanisoftware.com/';
        this.uploadUrl = 'https://api.vanisoftware.com/';
      } else if (appLocation == "https://stagingapi.vanisoftware.com/") {
        this.appUrl = 'https://stagingapi.vanisoftware.com/';
        this.uploadUrl = 'https://stagingapi.vanisoftware.com/';
      } else if (appLocation == 'https://update.vanisoftware.com/api/api/') {
        this.appUrl = 'https://update.vanisoftware.com/api/api/';
        this.uploadUrl = 'https://update.vanisoftware.com/api/api/';
      }
      else {
        this.appUrl = appLocation + '/vani-offline/';
        this.uploadUrl = appLocation + '/vani-offline/';
      }
    }

    /*******  For offline setup uncomment if else condition (end) ***************/

    // this.appUrl = 'https://app.vanisoftware.com/api/';
    this.serverUrl = this.appUrl;
    this.publishingServerUrl = this.appUrl + 'publishing/';
  }

  // ! Get Full Url
  getUploadUrl() {
    this.clientCode = localStorage.getItem('client_code');
    if (this.clientCode) {
      this.clientCode = this.clientCode.toLowerCase();
    }
    return this.uploadUrl + 'public/uploads/' + this.clientCode + '/';
  }

  autoincrementStr(str) {
    if (str != undefined && str != '' && str != null) {
      var splitted = str.split("");
      var lastStrIndex = -1;
      var finalStr;

      splitted.forEach((item, index) => {
        if (!this.isNumeric(item)) {
          lastStrIndex = index;
        }
      });
      if (lastStrIndex != -1) {

        if (!str) return null; // Return null if the input is null or undefined

        const prefixMatch = str.match(/[a-zA-Z]+/);
        if (!prefixMatch) return null; // Return null if the input doesn't contain any alphabetic characters

        const prefix = prefixMatch[0]; // Extract the prefix (e.g., "emp")
        const numericMatch = str.match(/\d+/); // Find the first numeric part in the employee code
        if (!numericMatch) return null; // Return null if no numeric part is found

        const numericPart = numericMatch[0]; // Extract the numeric part (e.g., "5" from "emp5")
        const nextNumber = parseInt(numericPart) + 1; // Increment the numeric part by one

        const incrementedNumericPart = numericPart.replace(/\d+/, nextNumber.toString().padStart(numericPart.length, '0')); // Ensure the incremented numeric part has the same length and format
        finalStr = `${prefix}${incrementedNumericPart}`;

        // var lastNumber = str.slice(lastStrIndex + 1);
        // var prefixString = str.substring(0, lastStrIndex + 1);
        // lastNumber++;
        // finalStr = prefixString + (lastNumber);

      } else {

        if (!this.isNumeric(str)) {
          finalStr = str + '1';
        } else {
          var lastNumber1 = Number(str);
          lastNumber1++;
          finalStr = lastNumber1;
        }
      }

      return finalStr.toString().toLowerCase();
    } else {
      return '';
    }
  }

  isNumeric(n: any): n is number | string {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  getAppUrls() {
    let appLocation = window.location.origin;
    //this.uploadUrl = this.uploadUrl+'public/uploads/'+this.clientCode+'/';
    //this.appUrl = window.location.origin;
    if (appLocation == "https://app.vanisoftware.com/api/" ||
      appLocation == "https://www.vanisoftware.com" ||
      appLocation == "http://www.vanisoftware.com" ||
      appLocation == "https://app.vanisoftware.com/api/"
    ) {
      this.appUrl = 'https://app.vanisoftware.com/api//api/';
      this.uploadUrl = 'https://app.vanisoftware.com/api//api/';
    } else if (appLocation == "http://wts") {
      this.appUrl = 'http://wts/wtshub/vani-offline/';
      this.uploadUrl = 'http://wts/wtshub/vani-offline/';
    } else if (appLocation == 'http://127.0.0.1:4200') {
      // this.appUrl = 'http://127.0.0.1/projects/vani/vani-api/';
      // this.uploadUrl = 'http://127.0.0.1/projects/vani/vani-api/';
      this.appUrl = 'http://wts/wtshub/vani-offline/';
      this.uploadUrl = 'http://wts/wtshub/vani-offline/';
    }
    else {
      this.appUrl = appLocation + 'vani-offline/';
      this.uploadUrl = appLocation + 'vani-offline/';
    }
  }
}
