export function columns(key: string, cellTemplate: any = {}, modifiedArray: any = []) {
    if (key === 'online-access') {
        const onlineAccess = [
            {
                prop: 'id',
                name: 'Action',
                sortable: false,
                draggable: false,
                width: 80,
                minWidth: 80,
                maxWidth: 80,
                frozenRight: true,
                cellClass: 'action-button',
                headerClass: 'action-btn-datatable',
                cellTemplate: cellTemplate.ids,
            },
            {
                prop: 'id',
                name: 'Employee',
                sortable: false,
                draggable: true,
                width: 260,
                cellTemplate: cellTemplate.image,
            },
            {
                prop: 'email',
                name: 'Email',
                sortable: true,
                draggable: true,
                width: 230,
                canAutoResize: true,
            },
            {
                prop: 'primary_department',
                name: 'Primary Department',
                sortable: true,
                draggable: true,
                width: 230,
                canAutoResize: true,
            },
            {
                prop: 'role_id',
                name: 'Role',
                sortable: true,
                draggable: true,
                width: 230,
                canAutoResize: true,
            },
        ];
        return onlineAccess;
    }
}
