import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { columns } from '../utility/column-generator';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.css']
})
export class DatatableComponent implements OnInit {
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  readonly headerHeight = 58;
  readonly rowHeight = 64;
  readonly footerHeight = 58;
  page: number = 0;
  count: number;
  temp: any[] = [];
  rows: any[] = [];
  isLoading: boolean = true;
  public columns: TableColumn[] = [];
  pagePayload: any = {
    limit: 200,
    offset: 0,
    request_type: 0,	// 0 for all assigned trips , 1 for confirmed trips, 2 for diclined trips, 3 for cancelled trips, 4 for completed trips
    searchFlag: 0,
    searchString: "",
    is_export_csv: 0,
    sort_by: "",
    sort_order: "DESC",
    date_filter: 0,
    start_date: '',
    end_date: '',
    carBaseSearchString: '',
    riderSearchString: '',
    driverSearchString: '',
    location_filter: null,	// (1 - pickup location , 2 -- dropoff location)
    location_searchString: ''
    // driver_id: 'driver_id',
  };

  constructor() { }

  ngOnInit() {
    this.createColumns();
  }

  private createColumns(): void {
    let cellTemplate: any = {};
    setTimeout(() => {
      cellTemplate = {
        ids: this.idsT,
      };
      this.columns = [];
      this.columns = columns('asset', cellTemplate);
    });
  }

}
