import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  private pusher: Pusher;

  constructor() {
    Pusher.logToConsole = environment.pusher.debug;
    this.pusher = new Pusher(environment.pusher.key, {
        wsHost: environment.pusher.wsHost,
        wsPort: environment.pusher.wsPort,
        wssPort: environment.pusher.wssPort,
        forceTLS: false,
        cluster: environment.pusher.cluster,
        disableStats: true,
        enabledTransports: <any>environment.pusher.transports,
      });
  }

  // Subscribe to a channel
  subscribeToChannel(channelName: string) {
    return this.pusher.subscribe(channelName);
  }

  // Unsubscribe from a channel
  unsubscribeFromChannel(channelName: string) {
    this.pusher.unsubscribe(channelName);
  }
}