import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DataService } from '../../services/data.service';
import { TableData } from './table-data';
@Component({
  selector: 'app-superadmin-dashboard',
  templateUrl: './superadmin-dashboard.component.html',
  styleUrls: ['./superadmin-dashboard.component.css']
})
export class SuperadminDashboardComponent implements OnInit {
  public daterange: any = {};
  public options: any = {
    showCustomRangeLabel: true,
    autoApply: true,
    autoUpdateInput: true,
    locale: { format: 'YYYY-MM-DD' },
    alwaysShowCalendars: false,
  };
  /******************* chart *******************/

  // Active Accounts
  public rows: Array<any> = [];
  public columns: Array<any> = [
    { title: 'Account', name: 'account_name' },
    {
      title: 'Account Type',
      name: 'account_type',
    },
    { title: 'Status', name: 'account_status', sort: 'asc' },
  ];
  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered']
  };
  public page: number = 1;
  public itemsPerPage: number = 10;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  private data;

  /** Today / active accounts */

  public activerows: Array<any> = [];
  public activecolumns: Array<any> = [
    {
      title: 'Account',
      name: 'studio'
    },
    {
      title: 'Headcount',
      name: 'employeeCount',
    },
    {
      title: 'Projects',
      name: 'projectCount',
    },

    {
      title: 'Shots',
      name: 'shotCount',
    },

    {
      title: 'Assets',
      name: 'assetCount',
    },
    {
      title: 'Tasks',
      name: 'taskCount',
    },
    {
      title: 'Time Logs',
      name: 'taskTimeLogCount',
    },
  ];
  public activeconfig: any = {
    paging: true,
    sorting: { columns: this.activecolumns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered']
  };
  public activepage: number = 1;
  public activeitemsPerPage: number = 10;
  public activemaxSize: number = 5;
  public activenumPages: number = 1;
  public activelength: number = 0;
  private activedata;


  /** All time Active */

  public allTimerows: Array<any> = [];
  public allTimecolumns: Array<any> = [
    {
      title: 'Account',
      name: 'studio'
    },
    {
      title: 'Headcount',
      name: 'employeeCount',
    },
    {
      title: 'Projects',
      name: 'projectCount',
    },

    {
      title: 'Shots',
      name: 'shotCount',
    },

    {
      title: 'Assets',
      name: 'assetCount',
    },
    {
      title: 'Tasks',
      name: 'taskCount',
    },
    {
      title: 'Time Logs',
      name: 'taskTimeLogCount',
    },
  ];
  public allTimeconfig: any = {
    paging: true,
    sorting: { columns: this.allTimecolumns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered']
  };
  public allTimepage: number = 1;
  public allTimeitemsPerPage: number = 10;
  public allTimemaxSize: number = 5;
  public allTimenumPages: number = 1;
  public allTimelength: number = 0;
  private allTimedata: Array<any> = TableData;

  /** Account Type */

  public accountTyperows: Array<any> = [];
  public accountTypecolumns: Array<any> = [
    { title: 'Account type', name: 'type' },
    { title: 'Active', name: 'active' },
    { title: 'Suspend', name: 'suspend' },

  ];
  public accountTypeconfig: any = {
    paging: true,
    sorting: { columns: this.accountTypecolumns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered']
  };
  public accountTypepage: number = 1;
  public accountTypeitemsPerPage: number = 10;
  public accountTypemaxSize: number = 5;
  public accountTypenumPages: number = 1;
  public accountTypelength: number = 0;
  private accountTypedata;

  superAdminDashBoardObj: any = {};
  constructor(
    private titleService: Title,
    private dataService: DataService,
  ) {
    // this.activelength = this.activedata.length;
    this.allTimelength = this.allTimedata.length;
    this.superAdminDashBoardObj.allStudioList = [];
    this.superAdminDashBoardObj.studioData;
  }

  ngOnInit() {
    this.titleService.setTitle('Dashboard');
    // this.activeonChangeTable(this.activeconfig);
    // this.allTimeonChangeTable(this.allTimeconfig);
    // this.accountTypeonChangeTable(this.accountTypeconfig);

    this.getAllStudioDetail();
    this.allStudioList();
  }

  /** get all studio list */

  allStudioList() {
    this.dataService.getAllStudioList('getStudios').subscribe((success) => {
      this.superAdminDashBoardObj.allStudioList = success.data;
    })
  }

  /** get all studio data */
  getData(event) {
    this.allStudioList();
  }

  /** Get studio detail */

  getAllStudioDetail() {
    let client_code = localStorage.getItem('client_code');
    this.superAdminDashBoardObj.client_code = client_code;
    let userId = localStorage.getItem('userId');
    this.dataService.superadminDashboard({ client_code: client_code, person_id: userId })
      .subscribe((success) => {

        /** For a active account */
        this.data = success.data[0].active_accounts;
        this.length = this.data.length;
        this.onChangeTable(this.config);

        /** For a Today and active */
        this.activedata = success.data[0].today_active;
        this.activelength = this.activedata.length;
        this.activeonChangeTable(this.activeconfig);

        /** For a All time  active */
        this.allTimedata = success.data[0].all_time_active;
        this.allTimelength = this.allTimedata.length;
        this.allTimeonChangeTable(this.allTimeconfig);

        /** For a Account type and status */
        let custmizeAccountType = [];
        for (const [key, value] of Object.entries(success.data[0].accounts_type_Status)) {
          custmizeAccountType.push({
            type: key,
            active: value['active'],
            suspend: value['suspend'],
          });
        }
        this.accountTypedata = custmizeAccountType
        this.accountTypelength = this.accountTypedata.length;
        this.accountTypeonChangeTable(this.accountTypeconfig);
      });


  }


  /** Active accounts */
  public changePage(page: any, data: Array<any> = this.data): Array<any> {
    let start = (page.page - 1) * page.itemsPerPage;
    let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }

  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    let columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return data;
    }

    // simple sorting
    return data.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  public changeFilter(data: any, config: any): any {
    let filteredData: Array<any> = data;
    this.columns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          return item[column.name].match(column.filtering.filterString);
        });
      }
    });

    if (!config.filtering) {
      return filteredData;
    }

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.config.filtering.filterString));
    }

    let tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.columns.forEach((column: any) => {
        if (item[column.name] != null) {
          if (item[column.name].toString().match(this.config.filtering.filterString)) {
            flag = true;
          }
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }


  /** For a Active Accounts */
  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }

    let filteredData = this.changeFilter(this.data, this.config);
    let sortedData = this.changeSort(filteredData, this.config);
    this.rows = page && config.paging ? this.changePage(page, sortedData) : sortedData;
    this.length = sortedData.length;
  }


  public onCellClick(data: any): any {
  }

  /*********************************** Active Accounts ******************************/


  public activechangePage(page: any, data: Array<any> = this.activedata): Array<any> {
    let start = (page.page - 1) * page.itemsPerPage;
    let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }

  public activechangeSort(activedata: any, activeconfig: any): any {
    if (!activeconfig.sorting) {
      return activedata;
    }

    let columns = this.activeconfig.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return activedata;
    }

    // simple sorting
    return activedata.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  public activechangeFilter(activedata: any, activeconfig: any): any {
    let filteredData: Array<any> = activedata;
    this.activecolumns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          return item[column.name].match(column.filtering.filterString);
        });
      }
    });

    if (!activeconfig.filtering) {
      return filteredData;
    }

    if (activeconfig.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[activeconfig.filtering.columnName].match(this.activeconfig.filtering.filterString));
    }

    let tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.activecolumns.forEach((column: any) => {
        if (item[column.name].toString().match(this.activeconfig.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  public activeonChangeTable(activeconfig: any, page: any = { page: this.activepage, itemsPerPage: this.activeitemsPerPage }): any {
    if (activeconfig.filtering) {
      Object.assign(this.activeconfig.filtering, activeconfig.filtering);
    }

    if (activeconfig.sorting) {
      Object.assign(this.activeconfig.sorting, activeconfig.sorting);
    }

    let filteredData = this.activechangeFilter(this.activedata, this.activeconfig);
    let sortedData = this.activechangeSort(filteredData, this.activeconfig);
    this.activerows = page && activeconfig.paging ? this.activechangePage(page, sortedData) : sortedData;
    this.activelength = sortedData.length;
  }

  public activeonCellClick(data: any): any {
  }


  /**************************** All time Active **********************************/

  public allTimechangePage(page: any, data: Array<any> = this.allTimedata): Array<any> {
    let start = (page.page - 1) * page.itemsPerPage;
    let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }

  public allTimechangeSort(allTimedata: any, allTimeconfig: any): any {
    if (!allTimeconfig.sorting) {
      return allTimedata;
    }

    let columns = this.allTimeconfig.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return allTimedata;
    }

    // simple sorting
    return allTimedata.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  public allTimechangeFilter(data: any, config: any): any {
    let filteredData: Array<any> = data;
    this.allTimecolumns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          return item[column.name].match(column.filtering.filterString);
        });
      }
    });

    if (!config.filtering) {
      return filteredData;
    }

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.allTimeconfig.filtering.filterString));
    }

    let tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.allTimecolumns.forEach((column: any) => {
        if (item[column.name].toString().match(this.allTimeconfig.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  public allTimeonChangeTable(allTimeconfig: any, page: any = { page: this.allTimepage, itemsPerPage: this.allTimeitemsPerPage }): any {
    if (allTimeconfig.filtering) {
      Object.assign(this.allTimeconfig.filtering, allTimeconfig.filtering);
    }

    if (allTimeconfig.sorting) {
      Object.assign(this.allTimeconfig.sorting, allTimeconfig.sorting);
    }

    let filteredData = this.allTimechangeFilter(this.allTimedata, this.allTimeconfig);
    let sortedData = this.allTimechangeSort(filteredData, this.allTimeconfig);
    this.allTimerows = page && allTimeconfig.paging ? this.allTimechangePage(page, sortedData) : sortedData;
    this.allTimelength = sortedData.length;
  }

  public allTimeonCellClick(data: any): any {
  }


  /**************************** Account Type Active **********************************/

  public accountTypechangePage(page: any, data: Array<any> = this.accountTypedata): Array<any> {
    let start = (page.page - 1) * page.itemsPerPage;
    let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }

  public accountTypechangeSort(accountTypedata: any, accountTypeconfig: any): any {
    if (!accountTypeconfig.sorting) {
      return accountTypedata;
    }

    let columns = this.accountTypeconfig.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }

    if (!columnName) {
      return accountTypedata;
    }

    // simple sorting
    return accountTypedata.sort((previous: any, current: any) => {
      if (previous[columnName] > current[columnName]) {
        return sort === 'desc' ? -1 : 1;
      } else if (previous[columnName] < current[columnName]) {
        return sort === 'asc' ? -1 : 1;
      }
      return 0;
    });
  }

  public accountTypechangeFilter(data: any, config: any): any {
    let filteredData: Array<any> = data;
    this.accountTypecolumns.forEach((column: any) => {
      if (column.filtering) {
        filteredData = filteredData.filter((item: any) => {
          return item[column.name].match(column.filtering.filterString);
        });
      }
    });

    if (!config.filtering) {
      return filteredData;
    }

    if (config.filtering.columnName) {
      return filteredData.filter((item: any) =>
        item[config.filtering.columnName].match(this.accountTypeconfig.filtering.filterString));
    }

    let tempArray: Array<any> = [];
    filteredData.forEach((item: any) => {
      let flag = false;
      this.accountTypecolumns.forEach((column: any) => {
        if (item[column.name].toString().match(this.accountTypeconfig.filtering.filterString)) {
          flag = true;
        }
      });
      if (flag) {
        tempArray.push(item);
      }
    });
    filteredData = tempArray;

    return filteredData;
  }

  public accountTypeonChangeTable(accountTypeconfig: any, page: any = { page: this.accountTypepage, itemsPerPage: this.accountTypeitemsPerPage }): any {
    if (accountTypeconfig.filtering) {
      Object.assign(this.accountTypeconfig.filtering, accountTypeconfig.filtering);
    }

    if (accountTypeconfig.sorting) {
      Object.assign(this.accountTypeconfig.sorting, accountTypeconfig.sorting);
    }

    let filteredData = this.accountTypechangeFilter(this.accountTypedata, this.accountTypeconfig);
    let sortedData = this.accountTypechangeSort(filteredData, this.accountTypeconfig);
    this.accountTyperows = page && accountTypeconfig.paging ? this.accountTypechangePage(page, sortedData) : sortedData;
    this.accountTypelength = sortedData.length;
  }

  public accountTypeonCellClick(data: any): any {
  }

  /** on submit search form */
  applyFilter() {
  }

  /** reset filter */
  resetFilter() {
    this.daterange = {};
    this.superAdminDashBoardObj.id = '';
    this.superAdminDashBoardObj.studioData = null;
    this.superAdminDashBoardObj.allStudioList = [];
  }

  /** Select login to from date*/
  public selectedDate(value: any, datepicker?: any) {
    // this is the date  selected

    // any object can be passed to the selected event and it will be passed back here
    datepicker.start = value.start;
    datepicker.end = value.end;

    // use passed valuable to update state
    this.daterange.start = value.start;
    this.daterange.end = value.end;
    this.daterange.label = value.label;
  }

  /** select auto complete */
  selectAutoComplete(event) {
    this.superAdminDashBoardObj.id = event.id;
  }

}
