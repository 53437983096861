import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../services/auth/auth-guard.service';
import { SettingsComponent } from './settings/settings.component';
import { SuperadminSettingsComponent } from './superadmin-settings/superadmin-settings.component';

const routes: Routes = [
	{
	  	path: '',
	  	component: SettingsComponent,
    	data: { roles: ['admin'] }
	},
	{
	  	path: 'settings',
	  	component: SuperadminSettingsComponent,
	  	canActivate: [AuthGuardService],
    	data: { roles: ['superadmin'] }
	},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
