import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { DataService } from '../../../services/data.service';
import { CommonJs } from '../../../common/common-js';
declare var $: any;
import * as _ from "lodash";
import * as moment from 'moment';

@Component({
  selector: 'task-checklist',
  templateUrl: './task-checklist.component.html',
  styleUrls: ['./task-checklist.component.css']
})
export class TaskChecklistComponent implements OnInit {

  entityName = 'Task Checklist';
  entityNameSingular = 'Task Checklist';
  apiRoutePrefix = 'checklist';

  @Input('attr.data') dataHtml: any;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @Output() closeSideWindow = new EventEmitter();

  data: any;
  myform: any;
  SaveButtonText: string = 'SAVE';

  errors: { [index: string]: any } = {};
  edit_errors: { [index: string]: any } = {};
  checklist: { [index: string]: any } = {};
  checklistData = [];
  is_external_login: boolean = false;
  is_external_review: boolean = false;
  projectId = '';
  isSubmitted: boolean = false;

  projectRoles = JSON.parse(localStorage.getItem('projectRoles'));
  currentRole = '';
  constructor(private dataService: DataService, public commonJs: CommonJs, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
    }

    if (this.data.from == 'task_list') {
      this.data.task.task_id = this.data.task.id;
    }

    if (this.data.task.department == undefined) {
      this.data.task.department = this.data.task.department_name;
    }

    if (this.data.task.icon == undefined) {
      this.data.task.icon = this.data.task.thumbnail;
    }

    /*if ( this.data.task.info == '' ) {
      this.data.task.info = this.data.task.number + ' - ' + this.data.task.name;
    }*/
    this.subngOnInit();
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
    });
    this.dataService.getDataById('getProjectSettings/' + this.projectId)
      .subscribe((success) => {
        this.is_external_review = success.data.is_external_review;
      }, (rejected) => {

      });
    this.currentRole = this.projectRoles['project_' + this.projectId];

  }

  ngOnDestroy() {
    this.close();
  }

  subngOnInit() {
    this.getData();
  }

  getData() {
    this.dataService.getOtherData(this.apiRoutePrefix + 'List', { 'project_id': this.data.task.project_id, 'task_id': this.data.task.task_id, 'currentRole': this.currentRole })
      .subscribe((success) => {
        this.checklistData = success.data;
      }, (rejected) => {

      });
  }

  close() {
    this.closeSideWindow.emit();
  }

  onSubmit(form: NgForm) {
    this.isSubmitted = true;
    this.SaveButtonText = 'SAVING';
    form.value.project_id = this.data.task.project_id;
    form.value.task_id = this.data.task.task_id;
    form.value.currentRole = this.currentRole;

    if (this.data.from == 'task_list') {
      form.value.task_version_id = 0;
    } else {
      if (this.data.allow_status) {
        form.value.task_version_id = this.data.task.id;
      } else {
        form.value.task_version_id = this.data.task.task_version_id;
      }
    }


    form.value.revision = this.data.task.revision_original;
    form.value.version = this.data.task.version_original;

    this.dataService.storeData(this.apiRoutePrefix + 'Store', form.value)
      .subscribe((success) => {
        setTimeout(() => {
          this.SaveButtonText = 'SAVE';
          this.checklist.comment = '';
          this.getData();
        }, 2);

        setTimeout(() => {
          this.isSubmitted = false;
        }, 2000);

      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
        }
        this.isSubmitted = false;
      });
  }

  cellClick(id): any { //onCellClick
    if (id != undefined && !this.data.is_artist) {
      $('.cursor-pointer').removeClass('active');
      $('.' + id + '-td').addClass('active');
      $('.' + id + '-input input').focus();
    }
  }

  cellUpdate(data: any = {}, isDelete = false, isDone = false, checkboxVal = ''): any {
    if (isDelete) {
      this.dataService.deleteData(this.apiRoutePrefix + 'Destroy', [data.id])
        .subscribe((success) => {
          $('.' + data.id + '-td').removeClass('active');
          this.getData();
        }, (rejected) => {

        });

    } else {

      data['is_done'] = isDone;
      this.dataService.updateData(this.apiRoutePrefix + 'Update/' + data.id, data)
        .subscribe((success) => {

          $('.' + data.id + '-td').removeClass('active');
          this.getData();

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.edit_errors[data.id] = err.error;
          }
        });


    }

  }
}
