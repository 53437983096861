import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from '../../components/settings/settings/settings.component';
import { SuperadminSettingsComponent } from './superadmin-settings/superadmin-settings.component';
import { PhasesComponent } from '../../components/phases/phases/phases.component';
import { ActivityComponent } from '../../components/settings/activity/activity.component';
import { WorkCalenderComponent } from '../../components/settings/work-calender/work-calender.component';
import { ProfileComponent } from '../../components/settings/profile/profile.component';
import { ChangePasswordComponent } from '../../components/settings/change-password/change-password.component';
import { StudioSettingsComponent } from '../../components/settings/studio-settings/studio-settings.component';
import { PermissionsComponent } from '../../components/users/permissions/permissions.component';
import { CustomStatusComponent } from '../../components/settings/custom-status/custom-status.component';
import { ListItemComponent } from '../../components/settings/list-item/list-item.component';
import { SharedModule } from '../shared/shared.module';
import { AutoCompleteModule, GrowlModule, InputSwitchModule } from 'primeng/primeng';

import { DragulaModule } from 'ng2-dragula';
import { SidebarModule } from 'ng-sidebar';
import { Ng2TableModule } from 'ng2-table/ng2-table';

import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { Daterangepicker } from 'ng2-daterangepicker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ColorPickerModule } from 'ngx-color-picker';
import { DirectivesModule } from './numberonlyDirective/directives.module';
import { CalendarModule } from 'primeng/primeng';
import { S3bucketComponent } from './s3bucket/s3bucket.component';
import { StorageComponent } from './storage/storage.component';
import { GoogleConfComponent } from './google-conf/google-conf.component';
import { OneDriveConfComponent } from './one-drive-conf/one-drive-conf.component';
import { LeaveAddComponent } from './leave-managment/leave-add/leave-add.component';
import { LeaveListComponent } from './leave-managment/leave-list/leave-list.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { OnlineAccessGroupComponent } from './online-access-group/online-access-group.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AddEmployeeComponent } from './add-employee/add-employee.component';


@NgModule({
  imports: [
    CommonModule,
    SettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AutoCompleteModule,
    GrowlModule,
    DragulaModule,
    SidebarModule,
    Ng2TableModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule,
    Daterangepicker,
    PopoverModule,
    InfiniteScrollModule,
    ColorPickerModule,
    DirectivesModule,
    CalendarModule,
    NgxFileDropModule,
    NgxDatatableModule,
    InputSwitchModule

  ],
  declarations: [
    SettingsComponent,
    SuperadminSettingsComponent,
    PhasesComponent,
    ActivityComponent,
    WorkCalenderComponent,
    ProfileComponent,
    ChangePasswordComponent,
    StudioSettingsComponent,
    PermissionsComponent,
    CustomStatusComponent,
    ListItemComponent,
    S3bucketComponent,
    StorageComponent,
    GoogleConfComponent,
    OneDriveConfComponent,
    LeaveAddComponent,
    LeaveListComponent,
    OnlineAccessGroupComponent,
    AddEmployeeComponent],
  providers: [
    DatePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [
    AddEmployeeComponent,
  ]
})
export class SettingsModule { }
