export const TableData: Array<any> = [
    {
        'name': 'Victoria Cantrell',
        'position': 'Integer Corporation',
        'office': 'Croatia',
        'ext': '0839',
        'startDate': '2015/08/19',
        'salary': 208.178
    }, {
        'name': 'Pearl Crosby',
        'position': 'In PC',
        'office': 'Cambodia',
        'ext': '8262',
        'startDate': '2014/10/08',
        'salary': 114.367
    }, {
        'name': 'Colette Foley',
        'position': 'Lorem Inc.',
        'office': 'Korea, North',
        'ext': '8968',
        'startDate': '2015/07/19',
        'salary': 721.473
    },
];