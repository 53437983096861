import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CommonJs } from '../../../common/common-js';
import { ToastrService } from '../../../services/toastr.service';
//  import { DragulaService } from 'ng2-dragula';
import swal from 'sweetalert2';
declare var $: any;
import * as _ from "lodash";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'app-project-phase',
  templateUrl: './project-phase.component.html',
  styleUrls: ['./project-phase.component.css']
})
export class ProjectPhaseComponent implements OnInit {

  @ViewChild('hidePhase', { static: false }) hidePhase: ElementRef;
  @ViewChild('hideDept', { static: false }) hideDept: ElementRef;
  @Output() changePage = new EventEmitter();
  isOpen = false;
  depatmentName = 'Depatments';
  depatmentNameSingular = 'Depatment';
  apiRoutePrefix = 'depatment';
  myform: any;
  phaseName = 'Phases';
  phaseNameSingular = 'Phase';
  apiPhaseRoutePrefix = 'phase';

  apiTemplateRoutePrefix = 'deptTemplate';

  projectId = '';//this.commonJs.getLocalStorage('projectId');

  errors: { [index: string]: any } = {};
  SaveButtonTextDept: string = 'SAVE';
  SaveButtonTextPhase: string = 'SAVE';
  templateId: number = 0;

  depatmentPhaseList: Array<any>;
  projectDepatmentList: { [index: string]: any } = {};

  department: { [index: string]: any } = {};
  parent_departments: Array<any>;

  mode: string = 'list';

  settingsAll: any = JSON.parse(localStorage.getItem('settings'));

  checkAllIds = [];

  displayDeptTask: boolean = false;
  isDeleteMode: boolean = false;
  deleteModeData = [];

  isProjectSettingsPermission = [];

  isProjectDepartmentChecked: boolean = false;
  bsModalRef: BsModalRef;
  skeleton: boolean = true;

  dragulaOptions: any = {
    moves: function (el, container, handle) {
      return handle.className === 'dragula-handle material-icons';
    }
  }
  subs: Subscription[] = [];

  constructor(
    private dragulaService: DragulaService,
    public commonJs: CommonJs,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private dataService: DataService,
    private toastrService: ToastrService) {

    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
      if (params['template_id'] != undefined) {
        this.templateId = params['template_id'];
      }
    });

    this.dragulaService.createGroup('another-bag', {
      moves: (el, container, handle) => handle.className === ''
    });
  }

  ngOnInit() {

    this.subs.push(
      this.dragulaService.drop().subscribe(({ name, el, source, target, sibling }) => {
        // Find the source department and its phase
        const sourceDepartmentId = +(<HTMLElement>el).getAttribute('data-id');
        const sourcePhase = this.depatmentPhaseList.find(phase => phase.department.some(dept => dept.id === sourceDepartmentId));

        // Find the target department and its phase
        const targetDepartmentId = +(<HTMLElement>sibling).getAttribute('data-id');
        const targetPhase = this.depatmentPhaseList.find(phase => phase.department.some(dept => dept.id === targetDepartmentId));

        if (sourcePhase && targetPhase) {
          // Find the source and target department indices
          const sourceIndex = sourcePhase.department.findIndex(dept => dept.id === sourceDepartmentId);
          const targetIndex = targetPhase.department.findIndex(dept => dept.id === targetDepartmentId);

          // Move the department from the source to the target phase
          const movedDepartment = sourcePhase.department.splice(sourceIndex, 1)[0];
          targetPhase.department.splice(targetIndex, 0, movedDepartment);

          this.dataService.getListData(this.apiRoutePrefix + 'Order', { 'depatmentPhaseList': this.depatmentPhaseList, project_id: this.projectId })
            .subscribe((success) => {
              this.toastrService.notify('success', '', success.msg ? success.msg : 'success');
            }, (rejected) => {
              console.log(rejected)
            });
        }
      })
    );

    this.isProjectSettingsPermission['edit'] = this.commonJs.checkAccess('project_settings', 'edit', { 'baiPass': ['admin'] }, this.projectId);
    this.getDepatmentPhaseList();
    this.dataService.getListData('projectDepartmentList', { 'project_id': this.projectId, 'is_boolean_format': true })
      .subscribe((success) => {
        this.projectDepatmentList = success.data;
      }, (rejected) => {

      });
  }

  ngOnDestroy() {
    this.dragulaService.destroy('another-bag');
  }

  getDepatmentPhaseList() {
    this.dataService.getListData(this.apiRoutePrefix + 'List', { 'template_id': this.templateId, 'project_id': this.projectId })
      .subscribe((success) => {
        this.depatmentPhaseList = success.data;
        this.skeleton = false;
      }, (rejected) => {
        this.skeleton = false;
      });
  }


  onChange(phase, dept, flag) {
    var tempCheck = [];
    this.dataService.storeData('projectDepartmentSync', { 'project_id': this.projectId, 'dept_id': dept, 'phase_id': phase, 'action': flag, 'is_check_all': false })
      .subscribe((success) => {
        if (flag) {
          $('.' + phase + '-' + dept + '-li').addClass('selected');
        } else {
          $('.' + phase + '-' + dept + '-li').removeClass('selected');
          if (success.status == 'error') {
            this.toastrService.notify('error', '', success.message);
            this.projectDepatmentList[dept] = true;
          }
        }
      }, (error) => {
        //this.alertMessage = 'Something went wrong, Please try again!';
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
        }
      });
  }

  // ! Change Mode(Phase Department Edit to project)

  changeMode(mode) {
    this.mode = mode;
    this.dataService.getListData('projectDepartmentList', { 'project_id': this.projectId, 'is_boolean_format': true })
      .subscribe((success) => {
        this.projectDepatmentList = success.data;
        if ($('#phaseDept').hasClass('selected')) {
          // this.displayDeptTask =true;
        } else {
        }
      }, (rejected) => {

      });
  }

  checkedAction(all_check_val, phase_id) {
    this.checkAllIds = [];
    $('.check-sub-' + phase_id).prop('checked', all_check_val);
    const index = this.depatmentPhaseList.findIndex((col) => {
      return col.id == phase_id;
    });

    if (index !== -1) {
      let depList = this.depatmentPhaseList[index];
      let that = this;
      _.forEach(this.depatmentPhaseList[index].department, function (val) {
        that.checkAllIds.push(val['id']);
      });
    }

    this.dataService.storeData('projectDepartmentSync', { 'project_id': this.projectId, 'dept_id': this.checkAllIds, 'phase_id': phase_id, 'action': all_check_val, 'is_check_all': true })
      .subscribe((success) => {
        if (success.status == 'error') {
          this.toastrService.notify('error', '', success.message);
          this.changeMode('view');
          this.mode = 'list';
        }
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
        }
      });
  }

  onShownDept(phase_id, depart_id, depart_name, isChecked) {
    this.isProjectDepartmentChecked = true;
    if (isChecked == undefined) {
      this.isProjectDepartmentChecked = false;
    }
    if (this.mode == 'edit') {
      this.parent_departments = [];
      this.department['id'] = depart_id;
      this.department['name'] = depart_name;
      this.department['phase_id'] = phase_id;
      this.errors = {};

      // this.displayDeptTask = false;
      // this.isDeleteMode = false;

      this.getTasktypeList(depart_id, this.projectId);

      this.dataService.getListData('getProjectDepartmentDependency', { 'project_id': this.projectId, 'department_id': depart_id, 'phase_id': phase_id })
        .subscribe((success) => {
          this.department['parent_departments'] = success.parent;
        }, (rejected) => {

        });

    }
  }

  deleteModeTogg(task) {
    this.isDeleteMode = true;
    this.deleteModeData = task;
  }

  checkedTaskTypeAction(is_checked, depId, id) {
    this.dataService.deleteData('tasktypeDestroy', { 'department_tasktype_id': id, 'project_id': this.projectId, 'department_id': depId, 'add': is_checked })
      .subscribe((success) => {
        this.isDeleteMode = false;
        this.getTasktypeList(depId, this.projectId);
        if (success.status == 'error') {
          this.toastrService.notify('error', '', success.message);

        }
      }, (rejected) => {

      });
  }

  getTasktypeList(depId, projectId = this.projectId) {
    this.dataService.getListData('tasktypeList', { 'department_id': depId, 'project_id': projectId })
      .subscribe((success) => {
        this.department['tasks'] = success.data;
        this.department['tasks_val'] = success.value;
        if (Object.keys(this.department['tasks_val']).length > 0) {
          $('.' + depId + '-tasktypeCount').show();
          $('.' + depId + '-tasktypeCount').html('(' + Object.keys(this.department['tasks_val']).length + ')');
        } else {
          $('.' + depId + '-tasktypeCount').hide();
        }
      }, (rejected) => {

      });
  }

  unSelect(event) {
    //event.stopPropagation();
    // this.department['parent_departments'] = null;
  }

  search(query, column) {
    if (column == 'parent_departments') {
      //this.getSecondaryDept(this.users.primary_department['id'],query['query']);
      this.dataService.getListData('getProjectDepartmentsForDependency', { 'project_id': this.projectId, 'is_boolean_format': false, 'query': query['query']/*, 'phase_id': this.department['phase_id']*/, 'current_dept_id': this.department['id'], 'parent_dept_id': this.department['parent_departments'], 'phase_id': this.department.phase_id })
        .subscribe((success) => {
          this.parent_departments = success.data;
        }, (rejected) => {

        });
    }
  }

  onSubmitDept(form: NgForm) {
    if (form.value.parent_departments) {
      var arr = "";
      _.forEach(this.department.parent_departments, function (value) {
        if (value != undefined && value != '') {
          arr += "|";
          arr += value['id'];
          arr += "|";
          arr += ",";
        }
      });
      form.value.parent_departments = arr.slice(0, -1);
    }

    this.dataService.storeData('projectDepartmentDependency', { 'project_id': this.projectId, 'department_id': this.department.id, 'phase_id': this.department.phase_id, 'parent': form.value.parent_departments })
      .subscribe((success) => {
        this.changeMode('list');
        this.getDepatmentPhaseList();
      }, (error) => {
        //this.alertMessage = 'Something went wrong, Please try again!';
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
          //console.log(this.errors);
        }
      });
    this.bsModalRef.hide();
  }

  // To open modal for edit department detail
  openDepartmentModal(template: TemplateRef<any>, actionType) {
    this.isDeleteMode = false;
    this.displayDeptTask = actionType == 'editDept' ? false : true;
    this.bsModalRef = this.modalService.show(template);
  }

  // To hide card if contain no department
  checkBlockVisibility(department: Array<any>) {
    let tempDeptList = [];
    department.forEach((dept) => {
      if (this.projectDepatmentList[dept.id] == true) {
        tempDeptList.push(dept);
      }
    });
    if (tempDeptList.length == 0) {
      return true;
    } else { return false; }
  }

}