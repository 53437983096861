import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import * as _ from "lodash";
declare var $: any;
import { CommonJs } from '../../../common/common-js';

@Component({
  selector: 'roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {

  entityName = 'Roles';
  entityNameSingular = 'Role';
  apiRoutePrefix = 'roles';
  apiRouteTypePrefix = 'role_type';

  @Input('attr.data') dataHtml: any;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @ViewChild("roleInput", { static: false }) roleInput: ElementRef;
  @Output() closeCreateRoleAdmins = new EventEmitter();

  data: any;
  roles_data: any;
  role_type_data: any;
  roleType_roles_data: any;
  hideRoles: boolean = false;
  hideRoleType: boolean = false;
  myform: any;
  roles: RoleAdmins;
  role_type: RoleType;
  onClose: any;
  errors: any;
  focused: string = '';
  SaveButtonText: string = 'SAVE';
  DeleteButtonText: string = 'DELETE';
  isDeleteMode: any;
  isDeleteModeType: any;
  clickOn: string = 'save';
  deleteId: number;
  deleteName: string = '';
  isDisplyFrm: boolean = false;
  isDisplyFrmType: boolean = false;
  alertMessage: string = '';
  settingsAll: any = JSON.parse(localStorage.getItem('settings'));

  constructor(
    private dataService: DataService,
    public commonJs: CommonJs
  ) { }

  ngOnInit() {
    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
    }
    this.roles = {
      name: '',
      slug: ''
    }
    this.role_type = {
      name: '',
      slug: '',
      roles: ''
    }
    $.AdminBSB.input.activate();
    this.subngOnInit();
  }

  search(query, column) {
    if (column == 'roleType_roles_data') {
      this.dataService.getListData('pendingRoles', '')
        .subscribe((success) => {
          this.roleType_roles_data = success.data.role;
        }, (rejected) => {

        });
    }
  }

  getRole(id) {
    this.dataService.getDataById(this.apiRoutePrefix + '/' + id + '/edit')
      .subscribe((success) => {
        this.roles = success.data;
        this.hideRoles = true;
        this.isDisplyFrm = true;
        this.focused = 'focused';
      }, (rejected) => {

      });
  }

  getRoleType(id) {

    this.dataService.getDataById(this.apiRouteTypePrefix + '/' + id + '/edit')
      .subscribe((success) => {
        this.role_type = success.data.type;
        this.roleType_roles_data = success.data.role;

        // let tempRole = [];
        // _.forEach(this.role_type.roles, function(value) {
        //   tempRole.push(value['id']);
        // });
        // this.role_type.roles = tempRole;

        this.hideRoleType = true;
        this.isDisplyFrmType = true;
        this.focused = 'focused';
      }, (rejected) => {

      });
  }

  subngOnInit() {

    //this.roleInput.nativeElement.focus();
    this.dataService.getListData(this.apiRoutePrefix + 'List', '')
      .subscribe((success) => {
        this.roles_data = success.data;
      }, (rejected) => {

      });

    //this.roleInput.nativeElement.focus();
    this.dataService.getListData(this.apiRouteTypePrefix + 'List', '')
      .subscribe((success) => {
        this.role_type_data = success.data;
      }, (rejected) => {

      });

  }

  onSubmit(form: NgForm) {
    if (this.clickOn == 'save') {
      this.SaveButtonText = 'SAVING';
    }
    if (this.roles['id'] != undefined && this.roles['id'] != '') {
      if (this.roles) {
        form.value.id = this.roles['id'];
      }
      this.dataService.updateData(this.apiRoutePrefix + '/' + form.value.id, form.value)
        .subscribe((success) => {
          // Save effect within button
          if (this.clickOn == 'save') {
            this.SaveButtonText = 'SAVED';
            this.subngOnInit();
            setTimeout(() => {
              this.SaveButtonText = "SAVE";
            }, 2);
            this.alertMessage = success.message;
            this.hideRoles = false;
            this.roles = {
              name: '',
              slug: ''
            }
          }
          this.isDisplyFrm = false;
          this.focused = '';

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.errors = err.error;
          }
        });
    } else {
      this.dataService.storeData(this.apiRoutePrefix, form.value)
        .subscribe((success) => {
          // Save effect within button
          if (this.clickOn == 'save') {
            this.SaveButtonText = 'SAVED';
            this.subngOnInit();
            setTimeout(() => {
              this.SaveButtonText = "SAVE";
            }, 2);
            this.alertMessage = success.message;

            this.hideRoles = false;
            this.roles = {
              name: '',
              slug: ''
            }
            this.isDisplyFrm = false;
          }

        }, (error) => {
          //this.alertMessage = 'Something went wrong, Please try again!';
          if (error.error) {
            var err = error.error;
            this.errors = err.error;
          }
        });
    }
  }

  onSubmitType(form: NgForm) {
    if (this.clickOn == 'save') {
      this.SaveButtonText = 'SAVING';
    }

    if (this.role_type['id'] != undefined && this.role_type['id'] != '') {
      if (this.role_type) {
        form.value.id = this.role_type['id'];
      }
      this.dataService.updateData(this.apiRouteTypePrefix + '/' + form.value.id, form.value)
        .subscribe((success) => {
          // Save effect within button
          if (this.clickOn == 'save') {
            this.SaveButtonText = 'SAVED';
            this.subngOnInit();
            setTimeout(() => {
              this.SaveButtonText = "SAVE";
            }, 2);
            this.alertMessage = success.message;
            this.hideRoleType = false;
            this.role_type = {
              name: '',
              slug: '',
              roles: ''
            }
          }
          this.isDisplyFrmType = false;
          this.focused = '';

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.errors = err.error;
          }
        });
    } else {

      this.dataService.storeData(this.apiRouteTypePrefix, form.value)
        .subscribe((success) => {
          // Save effect within button
          if (this.clickOn == 'save') {
            this.SaveButtonText = 'SAVED';
            this.subngOnInit();
            setTimeout(() => {
              this.SaveButtonText = "SAVE";
            }, 2);
            this.alertMessage = success.message;

            this.hideRoleType = false;
            this.role_type = {
              name: '',
              slug: '',
              roles: ''
            }
            this.isDisplyFrmType = false;
          }

        }, (error) => {
          //this.alertMessage = 'Something went wrong, Please try again!';
          if (error.error) {
            var err = error.error;
            this.errors = err.error;
          }
        });
    }
  }

  backToCreate(event) {
    event.stopPropagation();
    this.hideRoles = false;
    this.isDeleteMode = false;
    this.isDisplyFrm = false;
    this.errors = '';
    this.roles = {
      name: '',
      slug: ''
    };
    //this.roleInput.nativeElement.focus();
  }

  backToCreateType(event) {
    event.stopPropagation();
    this.hideRoleType = false;
    this.isDeleteModeType = false;
    this.isDisplyFrmType = false;
    this.role_type = {
      name: '',
      slug: '',
      roles: ''
    };
    //this.roleInput.nativeElement.focus();
  }

  deleteRole(id, name) {
    event.stopPropagation();
    this.deleteId = id;
    this.deleteName = name;
    this.isDeleteMode = true;
  }

  deleteRoleType(id, name) {
    event.stopPropagation();
    this.deleteId = id;
    this.deleteName = name;
    this.isDeleteModeType = true;
  }

  deleteRoleAction(id) {
    let that = this;
    this.DeleteButtonText = 'DELETEING';
    this.dataService.deleteData(that.apiRoutePrefix + 'Destroy', { id })
      .subscribe((success) => {
        //that.onChangeTable(that.config); 
        that.DeleteButtonText = 'DELETED';
        setTimeout(() => {
          that.subngOnInit();
          that.hideRoles = false;
          that.isDeleteMode = false;
          that.DeleteButtonText = "DELETE";
        }, 2);

      }, (rejected) => {
        if (rejected._body) {
          var err = JSON.parse(rejected._body);
          this.errors = err.error;
          this.DeleteButtonText = "DELETE";
        }
      });
  }

  deleteRoleTypeAction(id) {
    let that = this;
    this.DeleteButtonText = 'DELETEING';
    this.dataService.deleteData(that.apiRouteTypePrefix + 'Destroy', { id })
      .subscribe((success) => {
        //that.onChangeTable(that.config); 
        that.DeleteButtonText = 'DELETED';
        setTimeout(() => {
          that.subngOnInit();
          that.hideRoleType = false;
          that.isDeleteModeType = false;
          that.DeleteButtonText = "DELETE";
        }, 2);

      }, (rejected) => {

      });
  }

  onDropSuccess($event) {
    let rec = [];
    rec.push($event);
  }

  onDragEnd() {
    // this.targetList.push($event.dragData);
  }

  close() {
    this.closeCreateRoleAdmins.emit();
  }

  frmToggle() {
    this.isDisplyFrm = !this.isDisplyFrm;
    this.hideRoles = !this.hideRoles;
    //this.roleInput.nativeElement.focus();
    this.focused = 'focused';
    this.roles = {
      name: '',
      slug: ''
    }
  }

  frmTypeToggle() {
    this.isDisplyFrmType = !this.isDisplyFrmType;

    if (this.isDisplyFrmType) {
      // this.dataService.getListData('pendingRoles','')
      //   .subscribe((success) => {
      //     this.roleType_roles_data = success.data.role;
      //     }, (rejected) => {
      //        
      //     });
    }

    this.hideRoleType = !this.hideRoleType;
    //this.roleInput.nativeElement.focus();
    this.focused = 'focused';
    this.role_type = {
      name: '',
      slug: '',
      roles: ''
    }
  }

}

interface RoleAdmins {
  name: string,
  slug: string
}

interface RoleType {
  name: string,
  slug: string,
  roles: any
}