import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
//  import { NgProgressService } from 'ngx-progressbar';
import { AppConfigs } from '.././common/app-configs';
import { Router } from '@angular/router';
import * as _ from "lodash";
import { Observable, BehaviorSubject, Subject, throwError } from 'rxjs';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { environment } from '../../environments/environment';
import * as moment from 'moment';
import { catchError, map, tap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class DataService {
  // private headers: any;
  private fileheaders: any
  isProjectHeader: boolean = false;
  public isGeneralActivityTaskActive: Subject<any> = new Subject<any>();
  public isArtistTaskActive: Subject<any> = new Subject<any>();
  public currentRunningTask: Subject<any> = new Subject<any>();
  public changeProject$: Subject<any> = new Subject<any>();
  public isGetAllProjectList$: Subject<any> = new Subject<any>();
  public setProjectData$: Subject<any> = new Subject<any>();
  public pauseGeneralTask$: Subject<any> = new Subject<any>();
  public pauseProjectTask$: Subject<any> = new Subject<any>();
  public isUserTasksList$: Subject<any> = new Subject<any>();
  public isNotification$: Subject<any> = new Subject<any>();
  public isStudioNotification$: Subject<any> = new Subject<any>();
  public isProjectNotification$: Subject<any> = new Subject<any>();
  public isSuggestionClicked$: Subject<any> = new Subject<any>();
  //Task Reviewed
  public isTaskReviewed$: Subject<any> = new Subject<any>();
  public isProjectPermissionChanged$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  private serverUrl = this.appConfigs.serverUrl;
  private publishingServerUrl = this.appConfigs.publishingServerUrl;
  public isModalOpened: boolean = false;

  //Firebase
  afdbRef: AngularFireList<any>;
  notifications$: Observable<any[]>;
  notificationsSubscribe;
  firebaseList = environment.firebaseList;
  studioType: any;
  permissions = [];
  accessReturn: boolean = false;
  public studioProjectId$: BehaviorSubject<string>;
  public importComponent$: BehaviorSubject<string>;
  public missingTaskInfo$: BehaviorSubject<string>;
  public annotationData$: BehaviorSubject<string>;
  initialState: any = '';
  public notify = new BehaviorSubject<any>('');
  notifyObservable$ = this.notify.asObservable();
  clientCode: any = localStorage.getItem('access_code');
  projectIdByDashboard: any;

  getProjectListByUser: any = [];
  allStatusList: any = [];
  episodePayload: any;
  sequencePayload: any;

  public fullScreenView = new BehaviorSubject<any>(false);
  fullScreenViewObservable$ = this.fullScreenView.asObservable();

  constructor(
    public http: HttpClient,
    private spinner: NgxSpinnerService,
    public appConfigs: AppConfigs,
    public router: Router,
    @Optional() private afdb: AngularFireDatabase
  ) {
    // this.setHeaders();
    this.setHeadersForFile();
    this.studioType = (function () {
      var result;
      try {
        let result = localStorage.getItem('studio_type');
        return result;
      } catch (exception) {
      }
    }());

    this.studioProjectId$ = new BehaviorSubject(this.initialState);
    this.importComponent$ = new BehaviorSubject(this.initialState);
    this.missingTaskInfo$ = new BehaviorSubject(this.initialState);
    this.annotationData$ = new BehaviorSubject(this.initialState);
  }

  loadConfigs() {
    if (environment.prodType == 'offline') {
      return false;
    } else {
      return true;
    }
  }

  changeGeneralTaskActivityStatus(type, isActive) {
    this.isGeneralActivityTaskActive.next(isActive);
  }

  changeArtistProjectTaskStatus(isActive) {
    this.isArtistTaskActive.next(isActive);
  }

  getCurrentRunningTask(taskData) {
    this.currentRunningTask.next(taskData);
  }

  changeProject(projectId) {
    this.changeProject$.next(projectId);
  }

  getAllProjectList() {
    this.isGetAllProjectList$.next(true);
  }

  setProjectData(data) {
    this.setProjectData$.next(data);
  }

  pauseTaskProject(taskData, type) {
    if (type == 'project') {
      this.pauseProjectTask$.next(taskData);
      //this.myObservable$.next(taskData);
    } else if (type == 'general') {
      this.pauseGeneralTask$.next(taskData);
    }
  }

  permissionChanged(type = "") {
    this.isProjectPermissionChanged$.next(type);
  }

  onClickSuggestions() {
    this.isSuggestionClicked$.next(true);
  }

  taskStatusChanged(isChanged) {
    this.isTaskReviewed$.next(isChanged);
  }

  /** projectId info */
  get projectIdInfo(): string {
    return this.studioProjectId$.getValue();
  }

  /** Set projectId info */
  setProjectId = (set: string) => {
    this.studioProjectId$.next(set);
  }


  /** projectId info */
  get importInfo(): string {
    return this.importComponent$.getValue();
  }

  /** Set projectId info */
  setImportFrom = (set: string) => {
    this.importComponent$.next(set);
  }

  // Mission task info
  setMissingTaskData = (set: string) => {
    this.missingTaskInfo$.next(set);
  }

  // Get missing task info
  get missingTaskInfo(): string {
    return this.missingTaskInfo$.getValue();
  }

  /** Get annotation data */
  get annotationData(): string {
    return this.annotationData$.getValue();
  }


  /** Set projectId info */
  setAnnotationData = (set: string) => {
    this.annotationData$.next(set);
  }

  getNotifications(clientCode, userId, from = '') {
    if (environment.prodType != 'offline') {
      this.afdbRef = this.afdb.list(this.firebaseList + '/' + clientCode + '/' + userId, ref =>
        ref.limitToLast(1)
      );


      this.notifications$ = this.afdbRef.valueChanges();
      this.notificationsSubscribe = this.notifications$.subscribe(val => {
        val.forEach((element, index) => {
          var v = element;
          if (index == 0 && v.user_id) {
            if (v.type == 'assign_task') {
              v['notification_to'] = 'artist';
              // this.isUserTasksList$.next(v);
              this.isNotification$.next(v);
              this.isProjectNotification$.next(v);
            } else if (v.type == 'task_sent_for_review') {
              v['notification_to'] = 'reviewer';
              // this.isUserTasksList$.next(v);
              this.isNotification$.next(v);
              this.isProjectNotification$.next(v);
            } else if (v.type == 'assign_project' || v.type == 'task_retake' || v.type == 'task_approved' || v.type == 'comment' || v.type == 'task_done' || v.type == 'parent_dept_task_approved' || v.type == 'task_on_hold' || v.type == 'task_cbb' || v.type == 'task_unapproved' || v.type == 'task_sent_to_client' || v.type == 'task_off_hold' || v.type == 'task_unassigned' || v.type == 'inventory_resent' || v.type == 'inventory_rejected') {
              v['notification_to'] = '0';
              // this.isUserTasksList$.next(v);
              this.isNotification$.next(v);
              this.isProjectNotification$.next(v);
            } else if (v.type == 'mode_change') {
              v['notification_to'] = '0';
              this.isNotification$.next(v);
              this.isProjectNotification$.next(v);
            } else if (v.type == 'task_transfer') {
              v['notification_to'] = 'task_transfer';
              // this.isUserTasksList$.next(v);
              this.isNotification$.next(v);
              this.isProjectNotification$.next(v);
            }
            else if (v.type == 'leave_accept' || v.type == 'leave_rejected' || v.type == 'leave_approved' || v.type == 'leave_requested') {
              this.isNotification$.next(v);
            }
          }
        });
      });
    }

    //return this.notifications$;
  }

  addNotification(clientCode, userId, data) {
    if (environment.prodType != 'offline') {
      //console.log(data);
      data.created_at = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
      this.afdbRef = this.afdb.list(this.firebaseList + '/' + clientCode + '/' + userId);
      this.afdbRef.push(data);
    }
    return;
  }

  unsubscribeNotifications() {
    this.notificationsSubscribe.unsubscribe();
  }

  // everyCallInit() {
  //   this.isProjectHeader = _.includes(this.router.url, 'projects');
  //   if (this.isProjectHeader) {
  //     var routerURL = this.router.url.split("/");
  //     const index = routerURL.findIndex((col) => {
  //       return col == 'projects';
  //     });
  //     if (index !== -1 && routerURL[index + 2] != undefined) {
  //       if (this.headers.get('pAccess-Code') != null) {
  //         this.headers.set('pAccess-Code', routerURL[index + 2]);
  //       } else {
  //         this.headers.append('pAccess-Code', routerURL[index + 2]);
  //       }
  //     } else {
  //       this.headers.set('pAccess-Code', '');
  //     }
  //   } else {
  //     this.headers.set('pAccess-Code', '');
  //   }
  // }

  // setHeaders() {
  //   this.headers = new HttpHeaders();
  //   this.headers = this.headers.append('Content-Type', 'application/json');
  //   this.headers = this.headers.append('Authorization', localStorage.getItem('token'));
  //   this.headers = this.headers.append('Access-Code', localStorage.getItem('access_code'));
  //   this.headers = this.headers.append('TZ', Intl.DateTimeFormat().resolvedOptions().timeZone);
  // }


  setHeadersForFile() {
    this.fileheaders = new HttpHeaders();
    this.fileheaders = this.fileheaders.append('Authorization', localStorage.getItem('token'));
    this.fileheaders = this.fileheaders.append('Access-Code', localStorage.getItem('access_code'));
    this.fileheaders = this.fileheaders.append('TZ', Intl.DateTimeFormat().resolvedOptions().timeZone);
  }

  getOtherData(url, data) {
    return this.http.post(this.serverUrl + url, data)
      .pipe(map((res: any) => {
        return res;
      }, (error) => {
      }));
  }

  getSetting(page) {
    return this.http.post(this.serverUrl + 'getSetting', JSON.stringify(page))
      .pipe(map((res: any) => {
        return res;
      }, (error) => {
      }));
  }

  getListTotal(url) {
    return this.http.get(this.serverUrl + url)
      .pipe(map((res: any) => {
        return res;
      }, (error) => {
      }));
  }

  getListSort(url, page) {
    return this.http.post(this.serverUrl + url, JSON.stringify(page))
      .pipe(map((res: any) => {
        return res;
      }));
  }

  getListData(url, page) {
    if (url == 'episodesList') {
      this.episodePayload = JSON.stringify(page)
    }
    if (url == 'sequencesList') {
      this.sequencePayload = JSON.stringify(page)
    }
    return this.http.post(this.serverUrl + url, JSON.stringify(page))
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  paginationUpdate(url, page) {
    return this.http.put(this.serverUrl + url, JSON.stringify(page))
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }


  paginationGet(url, page) {
    return this.http.post(this.serverUrl + url, JSON.stringify(page))
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  storeData(url, data) {
    return this.http.post(this.serverUrl + url, JSON.stringify(data))
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }


  getDataById(url) {
    let loader: boolean = true;
    if (url == 'getProjectById') {
      loader = false;
    }
    return this.http.get(this.serverUrl + url)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  updateData(url, data) {
    return this.http.put(this.serverUrl + url, JSON.stringify(data))
      .pipe(map((res: any) => {
        return res;
      }), catchError((error: HttpErrorResponse) => {
        // Handle the error
        return throwError(error);
      }));
  }

  deleteData(url, data) {
    return this.http.post(this.serverUrl + url, JSON.stringify(data))
      .pipe(map((res: any) => {
        return res;
      }, (error) => {
      }));
  }

  changeAction(url, data) {
    return this.http.post(this.serverUrl + url, JSON.stringify(data))
      .pipe(map((res: any) => {
        return res;
      }, (error) => {
      }));
  }

  login(url, data) {
    return this.http.post(this.serverUrl + url, JSON.stringify(data))
      .pipe(map((res: any) => {
        return res;
      }, (error) => {
        //console.log("error", error);
      }));
  }

  forgotpassword(url, data) {
    return this.http.post(this.serverUrl + url, JSON.stringify(data))
      .pipe(map((res: any) => {
        return res;
      }, (error) => {
      }));
  }

  logout(url, data) {
    return this.http.post(this.serverUrl + url, JSON.stringify(data))
      .pipe(map((res: any) => {
        return res;
      }, (error) => {
      }));
  }

  //Publishing Tool APIs
  getListDataPub(url, page) {
    return this.http.post(this.publishingServerUrl + url, JSON.stringify(page))
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  getDataByIdPub(url) {
    return this.http.get(this.publishingServerUrl + url)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Get team management data */
  getTeamManagmentData(data) {
    return this.http.post(this.serverUrl + 'getEmployeeAvailability', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }


  /** Get team management data */
  excelTeamManagmentData(data) {
    return this.http.post(this.serverUrl + 'teamManangmentExcel', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }



  /** Pagination Team mamagment */

  teamManagmentPaginate(data) {
    return this.http.post(data.url, data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Get employee list */
  getAllEmplyeeList(data) {
    return this.http.post(this.serverUrl + 'getEmployeeList', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Advance Search  filter*/
  advanceSearchFilter(data) {
    return this.http.post(this.serverUrl + 'getEmployeeListFilter', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Super admin dashboard Data */

  superadminDashboard(data) {
    return this.http.post(this.serverUrl + 'getAllStudioDetails', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** activity data update */

  activityDataUpdate(data: string) {
    return this.http.post(this.serverUrl + 'updateActivityTimeLog', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  getCrewPlanningExportData(url, page) {
    return this.http.post(this.serverUrl + url, JSON.stringify(page))
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** get all studio list */

  getAllStudioList(url) {
    return this.http.post(this.serverUrl + url, {})
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {

      }));
  }

  /**
  Get facility type
  */
  awsConfig(facilityType: any) {
    return this.http.put(this.serverUrl + 'storageSetting', facilityType).pipe(map((res: any) => {
      return res;
    }));
  }


  /** Disconnect s3 bucket */
  awsDisconnect(facilityType: any) {
    return this.http.put(this.serverUrl + 'disconnetStorageBucket', facilityType).pipe(map((res: any) => {
      return res;
    }));
  }

  /**
  Get Project data
  */

  getProjectData(data: any) {
    return this.http.post(this.serverUrl + 'getProjectData', data).pipe(map((res: any) => {
      return res;
    }));
  }


  /** Comment store  */
  fileUploadForcomment(url, file, formValue) {
    //console.log(file)
    const formData = new FormData();
    if (file.length > 0) {
      for (var i = 0; i < file.length; i++) {
        formData.append("file[]", file[i]);
      }
      // formData.append('file_to_upload', file);
    }
    formData.append('formValue', JSON.stringify(formValue));
    return this.http.post(this.serverUrl + url, formData, { headers: this.fileheaders })
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  // for script document upload
  scriptUpload(url, param) {
    // const formData = new FormData();
    // formData.append('file[]', file);
    // formData.append('formValue', JSON.stringify(formValue));
    return this.http.post(this.serverUrl + url, param)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }



  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }


  /**
  * Video annotation save api
*/
  saveAnnotation(data) {
    return this.http.post(this.serverUrl + 'saveAnnotation', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /**
 * Video annotation save api
*/
  getVideoAnnotation(data) {
    return this.http.post(this.serverUrl + 'getAnnotation', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Delete annotation */
  deleteAnnotation(data) {
    return this.http.post(this.serverUrl + 'annotationDelete', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }


  /** Delete annotation */
  getTaskAttechment(data) {
    return this.http.post(this.serverUrl + 'getTaskVersionList', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }




  autoDistExtract(url) {
    return this.http.post(this.serverUrl + url, {})
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }


  /** Leave managment */

  /** Add leave */
  addLeave(data) {
    return this.http.post(this.serverUrl + 'leave', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Edit leave */
  editLeave(data) {
    return this.http.put(this.serverUrl + 'leave/' + data.id, data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Get leave */
  getLeave(headerType) {
    // this.headers = this.headers.set('headertype', JSON.stringify(headerType));

    return this.http.get(this.serverUrl + 'leave')
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Leave Accept reject leave */
  leaveAcceptRejectionfun(data: any) {
    return this.http.post(this.serverUrl + 'leaveAcceptRejection', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }


  /** Delete leave */
  deleteLeave(id: any) {
    return this.http.post(this.serverUrl + 'leaveDestroy', { id: id })
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Get shot status report for project dashboard */
  shotStatusReport(id: any) {
    return this.http.post(this.serverUrl + 'shotStatusReport', { project_id: id })
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Get asset status report for project dashboard */
  assetStatusReport(id: any) {
    return this.http.post(this.serverUrl + 'assestStatusReport', { project_id: id })
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Add Layers */
  addUpdateLayers(data: any) {
    return this.http.post(this.serverUrl + 'updateLayesForLightingDept', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  // To get supervisor's list
  getReportingList() {
    return this.http.get(this.serverUrl + 'reportingList')
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  // Get episode department view data
  episodeDepartmentViewData(data: any) {
    return this.http.post(this.serverUrl + 'deptList', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }


  // Get episode department view data
  sequenceDepartmentViewData(data: any) {
    return this.http.post(this.serverUrl + 'seqDeptList', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  // Get holidays list
  getHolidaysList() {
    return this.http.get(this.serverUrl + 'holidayList')
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Open file directory API method */
  openFileDirectory(data: any) {
    return this.http.post(this.serverUrl + 'fileOpen', data)
      .pipe(map((res: any) => {
        return res;
      }), tap(err => {
      }));
  }

  /** Pagination Preferance */
  pagePreferancePost(payload, url) {
    this.paginationUpdate('pagination' + `${'/' + url}` + '/preferences', { 'value': payload })
      .subscribe((success) => {
        // console.log(success)
      }, (rejected) => {
        console.log(rejected)
      });
  }

  /** Pagination Get */
  pagePreferanceGet(url) {
    return new Promise((resolve, rejected) => {
      this.paginationGet('pagination' + `${'/' + url}` + '/preferences', '')
        .subscribe((success) => {
          resolve(success);
        }, (rejected) => {
          rejected(null);
        });
    })
  }
}
