import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Ng2TableModule } from 'ng2-table/ng2-table';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModule } from '../shared/shared.module';
import { SidebarModule } from 'ng-sidebar';
import { ProjectsRoutingModule } from './projects-routing.module';
import { CreateProjectsComponent } from './create-projects/create-projects.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectSettingsComponent } from './project-settings/project-settings.component';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { Daterangepicker } from 'ng2-daterangepicker';
import { CreateAssetComponent } from './create-asset/create-asset.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { AutoCompleteModule, ButtonModule, InputSwitchModule } from 'primeng/primeng';
import { CreateShotComponent } from './create-shot/create-shot.component';
import { ProjectPhaseComponent } from './project-phase/project-phase.component';
import { CreateSeasonComponent } from './create-season/create-season.component';
import { CreateEpisodeComponent } from './create-episode/create-episode.component';
import { CreateSequenceComponent } from './create-sequence/create-sequence.component';
import { TasksCommentsComponent } from './tasks-comments/tasks-comments.component';
import { TeamComponent } from './team/team.component';
import { PlanningComponent } from './planning/planning.component';
import { BiddingComponent } from './bidding/bidding.component';
import { DetailsComponent } from './details/details.component';
import { ChartsModule } from 'ng2-charts';
import 'chart.piecelabel.js';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ClientStaggedReviewComponent } from './client-stagged-review/client-stagged-review.component';
import { TasksAssignAndCommentsComponent } from './tasks-assign-and-comments/tasks-assign-and-comments.component';
import { MultiSelectModule } from 'primeng/primeng';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ShotsColumnComponent } from './shots-column/shots-column.component';
import { ThumbnailDirectiveProject } from './directives/thumbnail.directive';
import { HighlightOnHoverDirective } from '../../directives/highlight-on-hover.directive';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DatatableComponent } from './datatable/datatable.component';
import { ShotDatatableComponent } from './shot-datatable/shot-datatable.component';
import { SequencesDatatableComponent } from './sequences-datatable/sequences-datatable.component';
// import { ClientsDatatableComponent } from './clients-datatable/clients-datatable.component';
import { SeasonListComponent } from './season-list/season-list.component';
import { EdpisodeListComponent } from './edpisode-list/edpisode-list.component';
import { ProjectsGridComponent } from './projects-grid/projects-grid.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MentionModule } from 'angular-mentions';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AssetsListComponent } from './assets-list/assets-list.component';
import { ManageAssetColumnsComponent } from './assets-list/manage-asset-columns/manage-asset-columns.component';
import { ManageProjectColumnsComponent } from './projects-grid/manage-project-columns/manage-project-columns.component';
import { HttpClientModule } from '@angular/common/http';
import { ScriptListComponent } from './script-list/script-list.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { SafePipe } from './script-list/safe.pipe';
import { VfxComponent } from './bidding/vfx/vfx.component';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { SettingsModule } from '../settings/settings.module';
import { DirectivesModule } from '../settings/numberonlyDirective/directives.module';
import { AssetTaskInfoComponent } from './task-details/datatables/asset-task-info/asset-task-info.component';
import { BidComponent } from './task-details/datatables/bid/bid.component';
import { AssetShotLinkedComponent } from './task-details/datatables/asset-shot-linked/asset-shot-linked.component';
import { SidebarModule as PrimeNgSidebarModule } from 'primeng/sidebar';
import { AllArtistTaskListComponent } from './all-artist-task-list/all-artist-task-list.component';
import { AssignTeamMemberComponent } from './assign-team-member/assign-team-member.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    Ng2TableModule,
    //TinymceModule,
    //SuiModule,
    TooltipModule,
    PaginationModule,
    SidebarModule,
    PrimeNgSidebarModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    ProgressbarModule.forRoot(),
    ProjectsRoutingModule,
    DragulaModule,
    Daterangepicker,
    PopoverModule,
    InfiniteScrollModule,
    AutoCompleteModule,
    //OwlCarousel,
    ChartsModule,
    LazyLoadImageModule,
    MultiSelectModule,
    NgMultiSelectDropDownModule,
    NgxFileDropModule,
    NgxDatatableModule,
    ButtonModule,
    ProgressSpinnerModule,
    MentionModule,
    DragDropModule,
    HttpClientModule,
    NgxDocViewerModule,
    TableModule,
    TabViewModule,
    SettingsModule,
    DirectivesModule,
    InputSwitchModule,
  ],
  declarations: [
    CreateProjectsComponent,
    DashboardComponent,
    ProjectSettingsComponent,
    CreateAssetComponent,
    CreateShotComponent,
    ProjectPhaseComponent,
    CreateSeasonComponent,
    CreateEpisodeComponent,
    CreateSequenceComponent,
    TasksCommentsComponent,
    TeamComponent,
    PlanningComponent,
    BiddingComponent,
    DetailsComponent,
    ClientStaggedReviewComponent,
    TasksAssignAndCommentsComponent,
    ShotsColumnComponent,
    ThumbnailDirectiveProject,
    HighlightOnHoverDirective,
    DatatableComponent,
    ShotDatatableComponent,
    SequencesDatatableComponent,
    SeasonListComponent,
    AssetsListComponent,
    EdpisodeListComponent,
    ProjectsGridComponent,
    ManageAssetColumnsComponent,
    ManageProjectColumnsComponent,
    ScriptListComponent,
    SafePipe,
    VfxComponent,
    AssetTaskInfoComponent,
    BidComponent,
    AssetShotLinkedComponent,
    AllArtistTaskListComponent,
    AssignTeamMemberComponent,

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    DragulaService
  ]

})
export class ProjectsModule { }
