import {
	Component,
	OnInit,
	Input,
	ElementRef,
	ViewChild,
	Output,
	EventEmitter,
	TemplateRef,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { DataService } from "../../../services/data.service";
import { DatatableService } from "../../../services/datatable/datatable.service";
import { ToastrService } from "../../../services/toastr.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { CommonJs } from "../../../common/common-js";
import { AppConfigs } from "../../../common/app-configs";
declare var $: any;
import * as moment from "moment";
import * as _ from "lodash";
import { DatatableComponent, SelectionType, TableColumn } from "@swimlane/ngx-datatable";
import { columns } from "./team-column";
import { GridService } from "src/app/services/grid.service";


@Component({
	selector: "team",
	templateUrl: "./team.component.html",
	styleUrls: ["./team.component.css"],
})
export class TeamComponent implements OnInit {
	entityName = "Team";
	apiRoutePrefix = "team";
	myform: any;
	errors: any;
	focused: string = "";
	SaveButtonText: string = "SAVE";
	team: { [index: string]: any } = {};
	roleTypeList = [];
	roleTypes = [];
	roles = [];
	settings: any = JSON.parse(localStorage.getItem("settings"));
	data: any;
	@Input("attr.data") dataHtml: any;
	@ViewChild("CloseOtherWindow", { static: false }) CloseOtherWindow: ElementRef;
	@ViewChild("SaveButton", { static: false }) SaveButton: ElementRef;
	@Output() closeSettings = new EventEmitter();
	projectId: number = 0;
	deleteAllId = [];
	uncheckAllId = [];
	deleteAllId1 = "";
	primaryDeptOptions = [];
	secondaryDeptOptions = [];
	busy: Subscription;
	selector: string = ".table-responsive";
	public showFilterRow = true;
	public rows = this.datatableService.rows;
	public columns: Array<any> = [
		{ title: "#ID", name: "id", sort: "desc", className: "hide-this" },
		{ title: "checkAll", sort: false, width: "15px" },
		{
			title: "Ecode",
			name: "emp_code_final",
			show: false,
			filtering: {
				filterString: "",
				placeholder: "Filter by code",
				display: true,
			},
			width: "80px",
			className: "",
		},
		{
			title: "Vcode",
			name: "vendor_id",
			show: false,
			filtering: {
				filterString: "",
				placeholder: "Filter by vendor code",
				display: true,
			},
			width: "80px",
			className: "",
		},
		{
			title: "Name",
			name: "user_name",
			sort: false,
			filtering: {
				filterString: "",
				placeholder: "Filter by name",
				display: true,
			},
			width: "80px",
			className: "",
		},
		{
			title: "Studio Role",
			name: "role_name",
			sort: false,
			filtering: {
				filterString: "",
				placeholder: "Filter by name",
				display: true,
			},
			width: "80px",
			className: "",
		},
		{
			title: "Primary Dept",
			name: "primary_department",
			sort: false,
			width: "150px",
			className: "",
			filtering: {
				filterString: "",
				placeholder: "",
				display: true,
				type: "dropdown",
				tagOptions: this.primaryDeptOptions,
				optionKey: "id",
				optionValue: "department",
			},
		},
		{
			title: "Secondary Dept",
			name: "secondary",
			sort: false,
			width: "150px",
			className: "",
			filtering: {
				filterString: "",
				placeholder: "",
				display: true,
				type: "dropdown",
				tagOptions: this.secondaryDeptOptions,
				optionKey: "id",
				optionValue: "department",
			},
		},
		{
			title: "Vertical",
			name: "vertical",
			sort: false,
			filtering: {
				filterString: "",
				placeholder: "Filter by name",
				display: false,
			},
			width: "80px",
			className: "",
		},
	];

	public FilterColumns: Array<any> = ["user_name"];
	public config: any = {
		paging: true,
		sorting: { columns: this.columns },
		filtering: { filterString: "" },
		className: ["table-striped", "table-bordered"],
		where: { project_id: this.projectId },
	};

	public maxSize = this.datatableService.maxSize;
	public page: number = this.datatableService.page;
	public itemsPerPage: number = this.datatableService.itemsPerPage;
	public length = this.datatableService.length;

	noRecordFound: boolean = false;
	noRecordFoundMsg: any = "Sorry, no records found!";
	filterAppliedOn = [];
	clientCode = localStorage.getItem("access_code");
	isSubmitted: boolean = false;
  openedPage: number = 0;
  globalSelect: Array<any> = [];

	constructor(
		private titleService: Title,
		private datatableService: DatatableService,
		public router: Router,
		private activatedRoute: ActivatedRoute,
		private dataService: DataService,
		private toastrService: ToastrService,
		private gridService: GridService,
		public commonJs: CommonJs,
		public appConfigs: AppConfigs,
    private elementRef: ElementRef,
	) {
		this.rows = [];
		this.datatableService.setParam(this.columns, "columns");
		this.datatableService.setParam(this.config, "config");
		this.datatableService.setParam(this.FilterColumns, "FilterColumns");
		this.datatableService.setParam("getUsersByRole", "serverUrl");
	}

	ngOnInit() {
		$.AdminBSB.input.activate();
		this.titleService.setTitle(this.settings.html_prefix + this.entityName);
		this.activatedRoute.params.subscribe((params: Params) => {
			this.projectId = params["id"];
		});

		// this.noRecordFoundMsg = this.settings.no_records != "" ? this.settings.no_records : this.noRecordFoundMsg;
		// this.datatableService.setParam(this.settings.default_pagination,"itemsPerPage");
		this.titleService.setTitle(this.settings.html_prefix + this.entityName);

		if (this.dataHtml != undefined) {
			this.data = this.dataHtml;
			this.config.where.project_id = this.projectId;
			this.config.where.role = this.data.role;
			this.config.where.roletype = this.data.roletype;
			this.ngxConfig.where.project_id = this.projectId;
			this.ngxConfig.where.role = this.data.role;
			this.ngxConfig.where.roletype = this.data.roletype;
			this.createColumns();
			this.onChangeTable(this.config);
			if (this.data.roletype == 5) {
				if (this.data.roleName == "Client") {
					this.columns.splice(3, 1);
				}
				this.columns.splice(6, 1);
				this.columns.splice(2, 1);
			} else {
				this.columns.splice(3, 1);
			}

			// if{
			// 	this.columns.splice(3,1);
			// }
			// if(this.data.roletype !=1 ){
			// this.columns.splice(2,1);
			// }
		}

		// this.columns[Object.keys(this.columns).length] = {title: 'Ecode', name:'emp_code_final', width: '350px',sort:false };
	}

	onScrollDown() {
		this.page++;
		this.onChangeTable(
			this.config,
			{ page: this.page, itemsPerPage: this.itemsPerPage },
			"",
			true
		);
	}

	search(event, column) {
		const index = this.columns.findIndex((col) => {
			return col.name == column;
		});
		if (column == "primary_department") {
			this.dataService
				.getOtherData("getPrimaryDept", { id: [0], query: event["query"] })
				.subscribe(
					(success) => {
						if (index !== -1) {
							this.columns[index]["filtering"]["tagOptions"] = success.data;
						}
						this.primaryDeptOptions = success.data;
					},
					(rejected) => {

					}
				);
		} else if (column == "secondary") {
			this.dataService
				.getOtherData("getSecondaryDept", { id: [0], query: event["query"] })
				.subscribe(
					(success) => {
						if (index !== -1) {
							this.columns[index]["filtering"]["tagOptions"] = success.data;
						}
						this.secondaryDeptOptions = success.data;
					},
					(rejected) => {

					}
				);
		}
	}

	public onChangeTable(
		config: any,
		page: any = { page: this.page, itemsPerPage: this.itemsPerPage },
		column: any = "",
		isAppend: any = false,
		isSort: any = false
	): any {
		$("#checkAll").prop("checked", false);

		if (isSort) {
			this.page = 1;
			page.page = 1;
			//this.isSortApply = true;
			this.noRecordFound = false;
			this.length = 0;
		}

		this.busy = this.datatableService
			.onChangeTable(this.config, page, column)
			.subscribe((success) => {
				if (this.config.export) {
					window.open(success.data);
				} else {
					if (!isAppend) {
						this.rows = success.data;
					} else {
						let that = this;
						_.forEach(success.data, function (value) {
							that.rows.push(value);
						});
					}

					this.length = success.totalElements;
					//this.totalCount = success.totalCount;
					this.noRecordFound = true;
				}
				this.config.export = false;
			});
	}

	getUsersByRole(role, roletype, projectId) {
		this.dataService
			.getListData("getUsersByRole", {
				role: role,
				roletype: roletype,
				project_id: projectId,
			})
			.subscribe(
				(success) => {
					this.roleTypeList = success.data;
				},
				(rejected) => {

				}
			);
	}

	public checkedAction(IS_ALL, row: any, column: any, role_id): void {
		let flag = "";
		if (IS_ALL) {
			let checkboxChecked = $("#checkAll").is(":checked");
			let i;
			this.deleteAllId = [];
			for (i = 0; i < this.rows.length; i++) {
				this.deleteAllId.push(this.rows[i].id);
			}
			if (checkboxChecked) {
				$("#checkAll").prop("checked", false);
				$(".check-sub").prop("checked", false);
				flag = "detach";
				this.deleteAllId = [];
			} else {
				$("#checkAll").prop("checked", true);
				$(".check-sub").prop("checked", true);
				flag = "attach";
			}
		} else {
			if (!row) {
				const index: number = this.deleteAllId.indexOf(column);
				this.uncheckAllId = column;
				if (index !== -1) {
					this.deleteAllId.splice(index, 1);
				}
				$("#check-" + row.id).prop("checked", false);
				flag = "detach";
			} else {
				if (!this.deleteAllId.includes(column)) {
					this.deleteAllId.push(column);
				}
				this.uncheckAllId = column;
				$("#check-" + row.id).prop("checked", true);
				flag = "attach";
			}
			$("#check-" + row.id).focus();
		}
		let tempIds = [];
		if (!IS_ALL) {
			//tempIds=this.uncheckAllId;
			tempIds.push(this.uncheckAllId);
		} else {
			tempIds.push(this.deleteAllId);
		}
	}

	public saveTeam() {
		this.isSubmitted = true;
		this.dataService
			.storeData("saveProjectTeam", {
				project_id: this.projectId,
				user_id: this.deleteAllId,
				role_id: this.data.role,
				role_type_id: this.data.roletype,
				action: "attach",
			})
			.subscribe(
				(success) => {

          const element = this.elementRef.nativeElement.querySelector('.page-count span');
           if (element) {
             element.innerHTML = 0 + ' selected / ';
             // element.innerHTML = selectedRows.length + ' selected / ' + this.totalCount + ' total';
           }

					setTimeout(() => {
						this.config.where.project_id = this.projectId;
						this.config.where.role = this.data.role;
						this.config.where.roletype = this.data.roletype;
						this.page = 1;
						this.deleteAllId = [];
						// this.onChangeTable(this.config);
						this.isLoading = true;
						this.pagePayload.page = 0;
						this.table.offset = 0;
						this.userList();
						this.close(1);
					}, 2);
					this.isSubmitted = false;
					if (success.notifications.length > 0) {
						let that = this;
						_.forEach(success.notifications, function (notification, key) {
							that.dataService.addNotification(
								that.clientCode,
								notification["user_id"],
								notification
							);
						});
					}
				},
				(error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
						this.toastrService.notify(
							"warn",
							"",
							"Please correct all highlighted errors and try again."
						);
					}
					this.isSubmitted = false;
				}
			);
      this.deleteAllId = this.selected = [];
	}

	selectedOptionChange(column, event, isMain = false, isTypeAhead = false) {
		let onlyIds;
		if (isTypeAhead) {
			onlyIds = event != "" && event != "All" ? [event.id] : [];
		} else {
			onlyIds = event != "" && event != "All" ? [event] : [];
		}

		this.filterAppliedOnFun(column, event);

		if (!isMain) {
			setTimeout(() => {
				//this.columns.push({title: 'new', name: 'name', width: '10%' });
				this.page = 1;
				this.config.where[column] = onlyIds;
				this.onChangeTable(this.config);
			}, 2);
		}
	}

	public onChangeTableFilter(config, column = "", str = "") {
		this.filterAppliedOnFun(column, str);
		this.page = 1;
		this.onChangeTable(config);
	}

	filterAppliedOnFun(column, event) {
		if (event != "") {
			$("." + column + "-th").addClass("filter-activated");
		} else {
			$("." + column + "-th").removeClass("filter-activated");
		}

		const filterAppliedOnIndex: number = this.filterAppliedOn.indexOf(column);
		if (filterAppliedOnIndex !== -1 && (event == "" || event == "All")) {
			this.filterAppliedOn.splice(filterAppliedOnIndex, 1);
		} else if (filterAppliedOnIndex == -1 && event == "") {
			this.filterAppliedOn.splice(filterAppliedOnIndex, 1);
		} else if (filterAppliedOnIndex == -1) {
			this.filterAppliedOn.push(column);
		}
	}
	clearFilter() {
		let that = this;
		_.forEach(that.columns, function (mainCol, key) {
			if (that.columns[key]["filtering"] != undefined) {
				that.columns[key]["filtering"]["filterString"] = "";
			}
		});
		$(".filter-input").val("");
		this.page = 1;
		this.config.where["primary_department"] = [];
		this.config.where["secondary"] = [];
		this.config.FilterByColumns = {};

		this.filterAppliedOn = [];
		$(".column-th-lable").removeClass("filter-activated");
		$(".filter-check").prop("checked", false);
		this.onChangeTable(this.config);
	}
	close(id) {
		this.closeSettings.emit({ id: id });
	}


	@ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;
	@ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
	public dataTableColumns: TableColumn[] = [];
	dataRows: any = [];
	temp = [];
	currentPage: number = 0;
	pageSize: number = 100;
	totalItems: number = 0;
	isLoading: boolean = true;
	pagePayload: any = {
		page: 0,
		itemsPerPage: 100,
	}
	selected = [];
	SelectionType: SelectionType[] = [];

	public ngxConfig: any = {
		FilterByColumns: {
			"emp_code_final": "",
			"vendor_id": "",
			"user_name": "",
			"role_name": "",
			"primary_department": "",
			"secondary": "",
			"vertical": ""
		},
		itemsPerPage: this.pagePayload.itemsPerPage,
		start: this.pagePayload.page,
		"sortNew": {},
		"FilterColumns": ["user_name"],
		"filtering": {
			"filterString": ""
		},
		sort: {},
		where: { project_id: this.projectId },
	};
	routerUrl: any;
	public readonly pageLimitOptions = [
		{ value: 50 },
		{ value: 100 },
		{ value: 200 },
		{ value: 300 },
	];
	checboxColum = {
		prop: 'selected',
		name: '',
		sortable: false,
		frozenLeft: true,
		draggable: true,
		resizable: false,
		headerCheckboxable: true,
		checkboxable: true,
		maxWidth: 45,
		width: 45,
		minWidth: 45,
	}
	teamObj: any = {};

  // For row identity by Id
  getId(row) {
    return row.id;
  }

	/** Create Column */
	private createColumns(): void {
		let cellTemplate: any = {};
		setTimeout(() => {
			cellTemplate = {
				hdrTpl: this.hdrTpl,
			};
			this.dataTableColumns = columns('teamColumn', cellTemplate);
			this.dataTableColumns.unshift(this.checboxColum);
			if (this.data.roletype === 5 && this.data.roleName === "Client") {
				// Filter out the columns you want to remove
				this.dataTableColumns = this.dataTableColumns.filter(column =>
					column.prop !== 'emp_code_final' && column.prop !== 'secondary_department'
				);
			} else if (this.data.roleName === "Vendor") {
				this.dataTableColumns = this.dataTableColumns.filter(column =>
					column.prop !== 'emp_code_final'
				);
			} else {
				this.dataTableColumns = this.dataTableColumns.filter(column =>
					column.prop !== 'vendor_id'
				);
			}
		});
	}

	/** Get data  */
	userList() {
		this.gridService.postData('getUsersByRole', this.ngxConfig).subscribe((res: any) => {
			if (!this.isLoading) {
				this.isLoading = true;
			}
			this.totalItems = res.totalElements;
			this.temp = [...res.data];
			this.dataRows = this.temp;
			this.isLoading = false;
      this.deleteAllId = [];
      this.selected = [];
    });
	}

	// TODO[Dmitry Teplov] wrap dynamic limit in a separate component.
	public onLimitChange(limit: any): void {
    this.selected = [];
    this.globalSelect = [];

		this.changePageLimit(limit);
		this.table.limit = this.pageSize;
		this.table.recalculate();
		setTimeout(() => {
			if (this.table.bodyComponent.temp.length <= 0) {
				this.table.offset = Math.floor((this.table.rowCount - 1) / this.table.limit);
			}
		});
	}

	private changePageLimit(limit: any): void {
		this.isLoading = true;
		this.pageSize = parseInt(limit, 10);
		this.ngxConfig.itemsPerPage = this.pageSize;
		this.userList();
	}

	/**
* Description: Page change event
* @param e pager object
*/
	onPageChange(e): void {
		// this.currentPage = e.offset;
    this.openedPage = e.offset;
    this.selected = this.globalSelect[this.openedPage] != undefined ? this.globalSelect[this.openedPage] : [];

		this.isLoading = true;
		this.pagePayload.page = e.offset * this.pageSize;
		this.ngxConfig.start = this.pagePayload.page;
		this.userList();
	}

	/** On select the row */
	onSelect({ selected }) {

		// this.deleteAllId = row.selected.map(obj => obj.id);

    this.globalSelect[this.openedPage] = selected;
    if (selected.length > 0) {
      this.selected = selected;
    }

    // this.deleteAllId = this.selected.map(obj => obj.id);
    this.deleteAllId = [];
    this.globalSelect.forEach((element) => {
      element.map((obj) => {
        // if(!this.deleteAllId.includes(obj.id)){
        this.deleteAllId.push(obj.id);
        // }
      });
    });

           let totalSelected = 0;
           this.globalSelect.forEach( (ele) => {
             totalSelected += ele.length;
           });

           // To show changed number of selected items
           const element = this.elementRef.nativeElement.querySelector('.page-count span');
           if (element) {
             element.innerHTML = totalSelected + ' selected / ';
             // element.innerHTML = selectedRows.length + ' selected / ' + this.totalCount + ' total';
           }
	}

	// Get column filter
	onColumnFilter(e: any, id: string): void {
		setTimeout(() => {
			this.isLoading = true;
			this.pagePayload.page = 0;
			this.table.offset = 0;
			this.userList();
		}, 200);
	}

	/** On Select Option */
	onselectOption(event: any, type: string) {
		if (type == 'primary_department') {
			// Assuming this.teamObj.departmentSelected is your original array
			const departmentSelected = this.teamObj.primary_department;

			// Extracting IDs from the array of objects
			const departmentIDs = departmentSelected.map(department => department.id);

			// Updating the ngxConfig.where[type] property
			this.ngxConfig.where[type] = departmentIDs;

			this.ngxConfig.where[type] = departmentIDs
		} else {
			// Assuming this.teamObj.departmentSelected is your original array
			const departmentSelected = this.teamObj.secondary_department;

			// Extracting IDs from the array of objects
			const departmentIDs = departmentSelected.map(department => department.id);

			// Updating the ngxConfig.where[type] property
			this.ngxConfig.where[type] = departmentIDs;

			this.ngxConfig.where[type] = departmentIDs
		}
		setTimeout(() => {
			this.isLoading = true;
			this.pagePayload.page = 0;
			this.table.offset = 0;
			this.userList();
		}, 200);
	}

	/** On clear selection */
	onselectionClear(event: any, type: string) {
		this.ngxConfig.where[type] = event.isTrusted == true ? [] : event;
		setTimeout(() => {
			this.isLoading = true;
			this.pagePayload.page = 0;
			this.table.offset = 0;
			this.userList();
		}, 200);
	}

	// On selection remove
	onselectionRemove(event: any, type: string) {
		this.ngxConfig.where[type] = [];
		setTimeout(() => {
			this.isLoading = true;
			this.pagePayload.page = 0;
			this.table.offset = 0;
			this.userList();
		}, 200);
	}

	getRowClass = (row) => {
		return {
			'row-sameRole': this.data.roleName == row.role_name,
		};
	}
}
