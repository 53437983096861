import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { ToastrService } from '../../services/toastr.service';
import { CommonJs } from '../common-js';
import { environment } from '../../../environments/environment';
import * as _ from "lodash";
import { Location } from '@angular/common';
import { NotificationsService } from '../../services/notifications/notifications.service';
import { Subscribable, Subscription, interval } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, OnDestroy {
  isClassVisible = false;
  public isLogin: boolean;
  public isToken: boolean;
  loginImages: LoginImages;

  //profilePic:any;
  settingsAll: any = JSON.parse(localStorage.getItem('settings'));

  role = (localStorage.getItem('role') != '' && localStorage.getItem('role') != undefined) ? localStorage.getItem('role') : 'superadmin';
  client_code = (localStorage.getItem('client_code') != '' && localStorage.getItem('client_code') != undefined) ? localStorage.getItem('role') : 'superadmin';
  isProduction: boolean = true;
  st = localStorage.getItem('standalone');
  userData = JSON.parse(localStorage.getItem('user'));

  @Output() logout = new EventEmitter();
  @Output() showProjects = new EventEmitter();
  @Output() SidebarToggle = new EventEmitter();
  @Input() SidebarToggleVal: boolean = false;

  checklistData = [];
  display_label_count: boolean = false;

  studioNotiData = [];
  location: Location;
  isProject: boolean = false;

  clientCode = localStorage.getItem('access_code');
  userId = localStorage.getItem('userId');
  subscribeisUserTasksList;
  studioType: any;
  env = environment;

  //isCrewPlanningViewPermission:boolean = false;
  //isEmployeesViewPermission:boolean = false;
  isViewPermission = [];
  isReportViewPermission = [];

  studioNotificationCount;

  pauseGeneralTaskSub;

  clientCodePrefix = "";
  userProfile = [];
  permissionChangedSub;
  isGeneralActivity:Subscription;

  _opened: boolean = false;
  _loadSideWindow: boolean = false;
  private studio_polling;

  phasesExist: any = {};

  constructor(
    location: Location,
    public router: Router,
    private dataService: DataService,
    public commonJs: CommonJs,
    private toastrService: ToastrService,
    private notificationsService: NotificationsService,
    public authService: AuthService,
    private cd: ChangeDetectorRef,
  ) {
    //afdbRef.remove();
    this.settingsAll = JSON.parse(localStorage.getItem('settings'));
  }


  ngOnInit() {
    this.loginImages = {
      logo: '',
      banner: ''
    }

    let currentAccountImages = JSON.parse(localStorage.getItem('currentAccountImages'));
    if (currentAccountImages != null) {
      this.loginImages.logo = currentAccountImages['logo'];
      this.loginImages.banner = currentAccountImages['banner'];
    }
    if (this.role != 'superadmin' && this.role != 'admin') {
      setTimeout(() => {
        this.getData();
      }, 600);
    }

    this.pauseGeneralTaskSub = this.dataService.pauseGeneralTask$.subscribe(taskData => {
      this.changesTaskStatus('', 'pause', taskData, true);
    });
    //this.addTodo();

    this.studioType = (function () {
      var result;
      try {
        let result = localStorage.getItem('studio_type');
        return result;
      } catch (exception) {
      }
    }());

    if(localStorage.getItem('phasesExist')){
      this.phasesExist = JSON.parse(localStorage.getItem('phasesExist'));
      // console.log(this.phasesExist);
    }else if(this.commonJs.phasesExist){
      this.phasesExist = this.commonJs.phasesExist;
    }else{
      this.phasesExist = { pre:1, character:1, set:1, prop:1, shot:1, post:1 };
    }

    //this.isProject = _.includes(this.location.path(), 'projects/');
    //Subscribe Notfications
    this.dataService.getNotifications(this.clientCode, this.userId, 'header');
    if (environment.prodType != 'offline') {
      let role = localStorage.getItem('role');
      this.subscribeisUserTasksList = this.dataService.isNotification$.subscribe(notification => {
        if (this.role != 'superadmin' && this.role != 'admin') {
          this.getStudioNotifications()
        }
        let notifyTo = notification['notification_to'];
        if (notifyTo == 'artist' || notifyTo == 'reviewer' || notifyTo == '0' || notifyTo == 'task_transfer' || notifyTo == 'parent_dept_task_approved') {
          if (notification['notification_id'] && notification['notification_id'] != '') {
            if (this.role != 'superadmin' && this.role != 'admin') {
              this.sendPushNotification(this.userId, notification['notification_id']);
            }
          }
        }
      });
    } else {
      if (this.role != 'superadmin' && this.role != 'admin') {
        this.getStudioNotifications()
      }
    }

    if (this.role != 'superadmin') {
      this.permissionChangedSub = this.dataService.isProjectPermissionChanged$.subscribe(type => {
        setTimeout(() => {
          this.isViewPermission['crew_planning'] = this.commonJs.checkAccess('crew_planning', 'view', { 'baiPass': ['admin'] });
          this.isViewPermission['employees'] = this.commonJs.checkAccess('employees', 'view', { 'baiPass': ['admin'] });
          this.isViewPermission['vendors'] = this.commonJs.checkAccess('vendors', 'view', { 'baiPass': ['admin'] });
          this.isViewPermission['outsourced_tasks'] = this.commonJs.checkAccess('outsourced_tasks', 'view', { 'baiPass': ['admin'] });
          this.isViewPermission['clients'] = this.commonJs.checkAccess('clients', 'view', { 'baiPass': ['admin'] });
          this.isViewPermission['projects'] = this.commonJs.checkAccess('projects', 'view', { 'baiPass': ['admin'] });
          this.isViewPermission['headcount_report'] = this.commonJs.checkAccess('headcount_report', 'view', { 'baiPass': ['admin'] });
          this.isViewPermission['management_dashboard'] = this.commonJs.checkAccess('management_dashboard', 'view', { 'baiPass': ['admin'] });
          this.isViewPermission['team_management'] = this.commonJs.checkAccess('team_management', 'view', { 'baiPass': ['admin'] });

          //report permissions
          this.isReportViewPermission['operational_report'] = this.commonJs.checkAccess('operational_report', 'view', { 'baiPass': ['admin'] });
          this.isReportViewPermission['project_report'] = this.commonJs.checkAccess('project_report', 'view', { 'baiPass': ['admin'] });

          this.studioNotificationCount = this.commonJs.getLocalStorage('studio_notification_count');
          this.clientCodePrefix = this.commonJs.getClientCodePrefix();


          //user profile-pic

          this.userProfile['image'] = this.commonJs.getUserImage();
          this.userProfile['name'] = this.commonJs.getUserName('name');

          this.userProfile['title'] = this.commonJs.getSettingValue('title');
        }, 500)

      });
    } else {
      this.permissionChangedSub = this.dataService.isProjectPermissionChanged$.subscribe(type => {
        this.clientCodePrefix = this.commonJs.getClientCodePrefix();
        this.userProfile['image'] = this.commonJs.getUserImage();
        this.userProfile['name'] = this.commonJs.getUserName('name');
        this.userProfile['title'] = this.commonJs.getSettingValue('title');
      });
    }

    if (this.role != 'admin' && this.role != 'superadmin' && environment.prodType == 'offline') {
      this.startStudioPolling();
    }

    this.isGeneralActivity = this.dataService.isGeneralActivityTaskActive.subscribe({
      next: (resp) => {
        if (resp == false) {
          this.display_label_count = false;
          this.checklistData.forEach((task) => {
            task.is_running = 0;
          });
        }
      },
      error: (error) => {
        console.error('Error in isGeneralActivityTaskActive:', error);
      },
    });

  }

  sendPushNotification(userId, notificationId) {
    this.dataService.getOtherData('getNotificationById', { 'userId': userId, 'notification_id': notificationId, 'role': this.role })
      .subscribe((success) => {
        if (success.notify.length > 0) {
          let notify = success.notify[0];
          this.notificationsService.sendPushMessage(notify.description, notify.messge, 'header');
        }
        this.studioNotificationCount = success.notificationCount.toString();
      }, (rejected) => {
        // //console.log(rejected);
      });
  }

  startStudioPolling() {
    this.studio_polling = interval(10000).subscribe(x => {
      //this.getReviewTaskList();
      this.dataService.getOtherData('getStudioNotiCount', { 'env': environment.prodType, 'role': this.role })
        .subscribe((success) => {
          localStorage.setItem('studio_notification_count', success.notification);
          this.studioNotificationCount = success.notification.toString();
          if (success.pushNotification.length > 0) {
            let notify = success.pushNotification[0];
            this.notificationsService.sendPushMessage(notify.description, notify.messge, 'app');
          }
        }, (rejected) => {
        });
    });
  }



  isMenuActive(slug) {
    //return _.includes(this.router.url, slug);
    return (this.router.url.replace('/' + this.commonJs.getClientCodePrefix(), '') == slug) ? true : false;
  }

  closeSidebar(SidebarToggleVal) {
    //this.SidebarToggleVal = !this.SidebarToggleVal;
    this.SidebarToggleVal = false;
  }

  getData() {
    this.dataService.getOtherData('activityList', {})
      .subscribe((success) => {
        this.checklistData = success.data;
        this.display_label_count = false;
        let that = this;
        _.forEach(this.checklistData, function (value, key) {
          if (value.is_running == 1) {
            that.display_label_count = true;
          }
        });

      }, (rejected) => {
        // //console.log(rejected);
      });
  }

  getStudioNotifications() {
    this.dataService.getOtherData('getStudioNotifications', { 'role': this.role })
      .subscribe((success) => {
        this.studioNotiData = success.notification;
        this.studioNotiData = this.studioNotiData.map((data: any) => {
          if (data.project_id == 0) {
            data.link = 'settings';
          }
          return data;
        })
        // this.studioNotificationCount = success.notification.length.toString();
      }, (rejected) => {
        // //console.log(rejected);
      });
  }

  changesTaskStatus(event, status, taskData, isCurrent = false) {
    if (event != '') {
      event.stopPropagation();
    }
    this.dataService.getOtherData('changeActivityTaskStatus', { 'taskData': taskData, 'status': status, 'isCurrent': isCurrent })
      .subscribe((success) => {
        this.getData();
        taskData.tasktypeName = 'general';
        localStorage.removeItem('currentTask');
        if (status == 'start' || status == 'resume') {
          this.dataService.changeGeneralTaskActivityStatus('general', true);
          //this.dataService.changeGeneralTaskActivityStatus('artist',false);
          taskData.activity = 'active';
          localStorage.setItem('currentTask', JSON.stringify(taskData));
        } else {
          //this.dataService.changeGeneralTaskActivityStatus('general',false);
          taskData.activity = 'stop';
          localStorage.removeItem('currentTask');
          this.display_label_count = false;
        }
        this.dataService.getCurrentRunningTask(taskData);

      }, (rejected) => {
        // //console.log(rejected);
      });
  }

  markAsReadAll() {
    this.dataService.getOtherData('markAsReadAll', {})
      .subscribe((success) => {
        if (success.notifications) {
          this.studioNotiData = [];
          localStorage.setItem('studio_notification_count', '0');
          this.toastrService.notify('success', '', "Notifications read");
          this.studioNotificationCount = "0";
        }
      }, (rejected) => {
        // //console.log(rejected);
      });
  }

  ngOnDestroy() {
    // if(this.isGeneralActivity){
    //   this.dataService.isGeneralActivityTaskActive.unsubscribe();
    // }

    if (environment.prodType != 'offline') {
      this.subscribeisUserTasksList.unsubscribe();
    }
    this.pauseGeneralTaskSub.unsubscribe();

    if (this.permissionChangedSub) {
      this.permissionChangedSub.unsubscribe();
    }
    if (this.isGeneralActivity) {
      this.isGeneralActivity.unsubscribe();
    }
    if (this.studio_polling != undefined) {
      this.studio_polling.unsubscribe();
      //this.studio_polling = '';
    }
  }

  openSuggestionsWindow() {
    this._loadSideWindow = true;
  }

  onClosed(status) {
    if (status == 'filter') {
      //this._loadFilterSideWindow = false;
    }
    else {
      this._loadSideWindow = false;
    }
  }

  onOpened(loadWindow) {
    if (loadWindow == 'filter') {
      //this._loadFilterSideWindow = true;
    } else {
      this._loadSideWindow = true;
    }
  }

  onClickSuggestions(flag) {
    localStorage.setItem('suggestionClicked', '1');
    this.dataService.onClickSuggestions();
  }


  /** Mouse hover event */
  mouseHover() {

    $(".menu-wrapper").hover(function () {
      $(".menu-list-report").toggleClass("d-block");
    });
  }

  /** Mouse out event */
  mouseOutEvent() {

    $(".menu-wrapper").hover(function () {
      $(".menu-list-report").removeClass("d-block");
    });
  }


  toggle() {
    this.isClassVisible = !this.isClassVisible;
    this.cd.markForCheck();
  }

  logoutUser() {
    this.authService.getLogout();
  }

  /** Read User Notification */
  readNotification(notification, i) {
    this.dataService.getOtherData('markAsRead', { notification_id: notification.id }).subscribe({
      next: (res: any) => {
        this.studioNotiData[i].is_unread = 0;
      },
      error: (error: any) => {

      }
    });
  }

}



interface LoginImages {
  logo: string,
  banner: string
}
