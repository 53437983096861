import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-manage-project-columns',
  templateUrl: './manage-project-columns.component.html',
  styleUrls: ['./manage-project-columns.component.css']
})
export class ManageProjectColumnsComponent implements OnInit {


  entityName = 'Columns';
  entityNameSingular = 'Column';
  apiRoutePrefix = 'users';
  skeleton: boolean = true;

  @Input('attr.data') dataHtml: any;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @ViewChild("roleInput", { static: false }) roleInput: ElementRef;
  @Output() closeManageCol = new EventEmitter();

  data: any;
  column_data: any;
  public temp_columns = [];
  checkId = [];
  SaveButtonText: string = 'SAVE';
  errors: { [index: string]: any } = {};
  subs: Subscription[] = [];

  constructor(private dataService: DataService,
    public commonJs: CommonJs,
    private toastrService: ToastrService,
    private dragulaService: DragulaService,
  ) {
    this.dragulaService.createGroup('another-bag', {
      moves: (el, container, handle) => handle.className === ''
    });
  }

  ngOnInit() {
    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
    }
    $.AdminBSB.input.activate();
    this.subngOnInit();
    this.subs.push(
      this.dragulaService.drop().subscribe(({ name, el, source, target, sibling }) => {
        const draggedColumn = this.temp_columns.find(col => col.name === el.textContent.trim());
        const currentIndex = this.temp_columns.indexOf(draggedColumn);
        this.temp_columns.splice(currentIndex, 1);

        let siblingColumn = '';
        let newIndex = 0;

        if(sibling){
          siblingColumn = this.temp_columns.find(col => col.name === sibling.textContent.trim());
          newIndex = this.temp_columns.indexOf(siblingColumn);
        }else{      // If the dragged element been put on last index
          newIndex = this.temp_columns.length;
        }
        // const siblingColumn = this.temp_columns.find(col => col.name === sibling.textContent.trim());
        // const newIndex = this.temp_columns.indexOf(siblingColumn);

        this.temp_columns.splice(newIndex, 0, draggedColumn);
        console.log(this.temp_columns)
      })
    );
  }

  subngOnInit() {
    // console.log(this.data);
    this.dataService.getListData(this.apiRoutePrefix + '/' + this.data.key + '/preferences', '')
      .subscribe((success) => {
        if (success.status == 'success' && success.data.length != 0) {
          this.temp_columns = success.data;
          const namesToRemove = ["action", "id"];
          // Filter out objects with the specified names
          this.data.column = this.data.column.filter(item => !namesToRemove.includes(item.prop));
          // Filter out objects with the specified names
          // Create a new array with columns in the order of 'this.temp_columns'
          const reorderedColumns = [];
          // Add matched columns in the order of 'this.temp_columns'
          for (const tempColumn of this.temp_columns) {
            const matchingColumn = this.data.column.find(column => column.prop === tempColumn.prop);
            if (matchingColumn) {
              reorderedColumns.push(matchingColumn);
            }
          }
          // Add any remaining columns from 'this.data.column'
          for (const column of this.data.column) {
            if (!reorderedColumns.some(matchedColumn => matchedColumn.prop === column.prop)) {
              reorderedColumns.push(column);
            }
          }
          // Update 'this.data.column' with the reordered columns
          this.data.column = reorderedColumns;
          this.skeleton = false;
        } else {
          this.temp_columns = this.data.column;
          this.temp_columns = this.temp_columns.map(column => ({ ...column, show: true }));
          this.skeleton = false;
        }
      }, (rejected) => {
        this.skeleton = false;
      });
  }

  private onDropModel(args) {
    let [el, target, source] = args;
    //this.temp_columns = this.dataHtml.column;
  }


  toggle(col) {
    const isChecked = this.isChecked(col);
    if (isChecked) {
      this.temp_columns = this.temp_columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.temp_columns = [...this.temp_columns, col];
    }
  }

  isChecked(col) {
    return (
      this.temp_columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }


  saveReOrder() {
    this.SaveButtonText = 'SAVING';
    this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.key + '/preferences', { 'value': this.temp_columns })
      .subscribe((success) => {
        this.SaveButtonText = 'SAVED';
        setTimeout(() => {
          this.CloseOtherWindow.nativeElement.click();
          this.SaveButtonText = 'SAVE';
        }, 2);
        this.toastrService.notify('success', '', 'Project columns filtered successfully.');
        this.close(true);
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
          this.SaveButtonText = "SAVE";
          this.toastrService.notify('warn', '', err.error);
        }
      });
  }

  close(reload) {
    this.closeManageCol.emit({ column: this.temp_columns, reload: reload });
  }

  closeBtn() {
    this.CloseOtherWindow.nativeElement.click();
  }

  ngOnDestroy() {
    this.dragulaService.destroy('another-bag');
  }


}
