import { Injectable } from '@angular/core';
import { BehaviorSubject, } from 'rxjs';
import { Message } from 'primeng/primeng';
import { ToastModule } from 'primeng/toast';

//type Severities = 'success' | 'info' | 'warn' | 'error';
@Injectable()
export class ToastrService {
  //toastrChange: Subject<Object> = new Subject<Object>();
  public toasterStatus: BehaviorSubject<ToastModule> = new BehaviorSubject<ToastModule>(null);
  public closeTrackButton: BehaviorSubject<ToastModule> = new BehaviorSubject<ToastModule>(null);
  constructor() { }
  notify(type: string, header: string, content: string) {
    //this.toastrChange.next({ severity, summary, detail });
    let toasterObj: Message = { severity: type, summary: header, detail: content };
    this.toasterStatus.next(toasterObj);
  }

  hideToaster() {
    this.toasterStatus.next(null);
  }

}
