import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../../services/data.service';
import * as _ from "lodash";
declare var $: any;
import { DragulaService } from 'ng2-dragula';
import { CommonJs } from '../../../common/common-js';
import { ToastrService } from 'src/app/services/toastr.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'manage-column',
  templateUrl: './manage-column.component.html',
  styleUrls: ['./manage-column.component.css']
})
export class ManageColumnComponent implements OnInit {
  entityName = 'Columns';
  entityNameSingular = 'Column';
  apiRoutePrefix = 'users';
  skeleton: boolean = true;
  @Input('attr.data') dataHtml: any;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @ViewChild("roleInput", { static: false }) roleInput: ElementRef;
  @Output() closeManageCol = new EventEmitter();
  data: any;
  column_data: any;
  public temp_columns = [];
  checkId = [];
  SaveButtonText: string = 'SAVE';
  errors: { [index: string]: any } = {};
  subs: Subscription[] = [];
  empCustomColumns = [];

  constructor(
    private dataService: DataService,
    public commonJs: CommonJs,
    private dragulaService: DragulaService,
    private toastrService: ToastrService,
  ) {
    this.dragulaService.createGroup('another-bag', {
      moves: (el, container, handle) => handle.className === ''
    });

  }

  ngOnInit() {
    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
      const namesToRemove = ["action", "id", "manageId"];
      this.data.column = this.data.column.filter(item => !namesToRemove.includes(item.prop));
      this.dataService.getListData('listItemList/emp_custom_columns', '')
        .subscribe((success) => {
          if (success.data.length > 0) {
            let temp_custom_columns = success.data;
            this.empCustomColumns = temp_custom_columns.map((ele) => {
              ele.name = 'column' + (temp_custom_columns.indexOf(ele) + 1);
              return ele;
            });
          }
        }, (rejected) => {

        });
    }
    $.AdminBSB.input.activate();
    this.subngOnInit();
    this.subs.push(
      this.dragulaService.drop().subscribe(({ name, el, source, target, sibling }) => {
        const draggedColumn = this.temp_columns.find(col => col.name === el.textContent.trim());
        const currentIndex = this.temp_columns.indexOf(draggedColumn);
        this.temp_columns.splice(currentIndex, 1);

        let siblingColumn = '';
        let newIndex = 0;

        if (sibling) {
          siblingColumn = this.temp_columns.find(col => col.name === sibling.textContent.trim());
          newIndex = this.temp_columns.indexOf(siblingColumn);
        } else {      // If the dragged element been put on last index
          newIndex = this.temp_columns.length;
        }

        // const siblingColumn = this.temp_columns.find(col => col.name === sibling.textContent.trim());
        // const newIndex = this.temp_columns.indexOf(siblingColumn);
        this.temp_columns.splice(newIndex, 0, draggedColumn);
      })
    );
  }

  subngOnInit() {
    this.dataService.getListData(this.apiRoutePrefix + '/' + this.data.key + '/preferences', '')
      .subscribe((success) => {
        if (success.status === 'success' && success.data.length !== 0) {
          this.temp_columns = success.data;
          // Create a new array with columns in the order of 'this.temp_columns'
          const reorderedColumns = [];
          // Add matched columns in the order of 'this.temp_columns'
          for (const tempColumn of this.temp_columns) {
            const matchingColumn = this.data.column.find(column => column.prop === tempColumn.prop);
            if (matchingColumn) {
              reorderedColumns.push(matchingColumn);
            }
          }
          // Add any remaining columns from 'this.data.column'
          for (const column of this.data.column) {
            if (!reorderedColumns.some(matchedColumn => matchedColumn.prop === column.prop)) {
              reorderedColumns.push(column);
            }
          }
          // Update 'this.data.column' with the reordered columns
          this.data.column = reorderedColumns;
          this.skeleton = false;
        } else {
          // Keep 'this.temp_columns' as it is
          this.temp_columns = this.data.column;
          // Assuming this.temp_columns is an array of column objects
          const columnsToShow = ['status', 'name', 'department', 'role_id', 'email', 'phone'];
          this.temp_columns = this.temp_columns.map(column => {
            if (columnsToShow.includes(column.prop)) {
              return { ...column, show: true };
            } else {
              return { ...column, show: false }; // Set show to false for other columns
            }
          });
          this.skeleton = false;
          console.log(this.temp_columns);
        }
      }, (rejected) => {
        this.skeleton = false;
      });
  }

  // To show custom column name
  sanitizeCustomColumnname(columnName: any) {
    if (columnName && columnName.includes('column') && this.empCustomColumns.length > 0) {
      return this.empCustomColumns[columnName.replace('column', '') - 1].item_name;
    } else {
      return columnName;
    }

  }


  saveReOrder() {
    this.SaveButtonText = 'SAVING';
    this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.key + '/preferences', { 'value': this.temp_columns })
      .subscribe((success) => {
        this.SaveButtonText = 'SAVED';
        setTimeout(() => {
          this.CloseOtherWindow.nativeElement.click();
          this.SaveButtonText = 'SAVE';
        }, 2);
        this.toastrService.notify('success', '', 'Employee columns filtered successfully.');
        this.close(true);
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
          this.SaveButtonText = "SAVE";
          this.toastrService.notify('warn', '', err.error);
        }
      });
  }

  close(reload) {
    this.closeManageCol.emit({ column: this.temp_columns, reload: reload });
  }

  closeBtn() {
    this.CloseOtherWindow.nativeElement.click();
  }

  ngOnDestroy() {
    this.dragulaService.destroy('another-bag');
  }

}
