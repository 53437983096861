import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
import * as moment from 'moment';
import * as _ from "lodash";
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

declare var $: any;

@Component({
	selector: 'create-episode',
	templateUrl: './create-episode.component.html',
	styleUrls: ['./create-episode.component.css']
})
export class CreateEpisodeComponent implements OnInit {
	entityName = 'Episodes';
	entityNameSingular = 'Episode';
	apiRoutePrefix = 'episodes';
	dirName = 'episodes';
	apiRoutePrefixListItem = 'listItem';
	@Input('attr.data') dataHtml: any;
	@ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
	@ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
	@ViewChild('Season', { static: false }) Season: ElementRef;
	@Output() closeCreateEpisode = new EventEmitter();
	data: any;
	myform: any;
	onClose: any;
	episodes: { [index: string]: any } = {};
	errors: { [index: string]: any } = {};
	focused: string = '';
	SaveButtonText: string = 'SAVE';
	isMessgaeMode: any;
	settingsAll: any = JSON.parse(localStorage.getItem('settings'));
	projectId = '';//this.commonJs.getLocalStorage('projectId');
	isDeleteMode: boolean = false;
	deleteId: number;
	deleteName: string = '';
	isDisplyFrm: boolean = false;
	alertMessage: string = '';
	DeleteButtonText: string = 'DELETE';
	DateFormat: any;

	public endDate = {
		singleDatePicker: true,
		autoUpdateInput: true,
		showDropdowns: true,
		autoApply: true,
		drops: 'down',
		locale: {
			format: 'DD MMM YYYY'
		}
	}

	public startDate = {
		singleDatePicker: true,
		autoUpdateInput: true,
		showDropdowns: true,
		autoApply: true,
		drops: 'down',
		"maxDate": "31 Dec 2030",
		locale: {
			format: 'DD MMM YYYY'
		},
		//startDate:moment().format('YYYY-MM-DD'),
	}

	url: any;
	thumbnail_preview_src: string = '';
	thumbnail: string;
	thumbnail_name: string;
	thumbnail_type: string;
	thumbnail_old: string;
	status = [{ "id": 1, "name": "Active" }, { "id": 0, "name": "Inactive" }];
	seasons: { [index: string]: any } = {};
	uploadUrl = this.commonJs.getUploadUrl();

	reuseAssets: Array<any>;
	freshAssets: Array<any>;
	freshAssetsArr = [];
	reuseAssetsArr = [];

	isSaveDisabled: boolean = false;
	dropdownSettings: any = {};
	public files: NgxFileDropEntry[] = [];


	constructor(
		private dataService: DataService,
		private toastrService: ToastrService,
		public commonJs: CommonJs,
		public appConfigs: AppConfigs
	) {

		// ! Multi Dropdown Setting
		this.dropdownSettings = {
			singleSelection: false,
			idField: "asset_id",
			textField: "code",
			selectAllText: "Select All",
			unSelectAllText: "UnSelect All",
			itemsShowLimit: 2,
			allowSearchFilter: true,
			noDataAvailablePlaceholderText: 'No data available',
			searchPlaceholderText: 'Search assets',
			maxHeight: 90,
			autoPosition: false,
			groupBy: "asset_id"
		};
	}

	// ! NgOnInit
	ngOnInit() {
		if (this.dataHtml != undefined) {
			this.data = this.dataHtml;
		}
		this.projectId = this.data.projectId;
		$.AdminBSB.input.activate();
		this.subngOnInit();
		this.DateFormat = this.settingsAll.date_format;
		this.startDate.locale.format = this.DateFormat;
		this.endDate.locale.format = this.DateFormat;

	}

	// ! SubNgOnInit
	subngOnInit() {
		//this.Season.nativeElement.focus();
		this.episodes = {
			project_id: this.projectId,
			seasons_id: '',
			name: '',
			number: '',
			description: '',
			status: 1,
			complexity: '',
			projected_shots: '',
			projected_seconds: '',
			thumbnail: '',
			thumbnail_name: '',
			thumbnail_type: '',
			thumbnail_old: '',
			thumbnail_display_name: '',
		};

		if (this.data.mode == 'create') {
			this.getFressAssets();
			this.getReuseAssets()
			this.episodes.start_date = moment().format(this.settingsAll.date_format);

			this.dataService.getListData('getLastRecordProject', { 'table': 'seasons', 'project_id': this.projectId, 'isActive': this.data.isActive })
				.subscribe((success) => {
					if (success.data != null) {
						let sId;
						if (this.data.season && this.data.seasonid) {
							sId = this.data.seasonid;
						} else {
							sId = success.data.id;
						}
						this.dataService.getListData('getLastRecordProjectSeason', { 'table': this.apiRoutePrefix, 'project_id': this.projectId, 'season_id': sId })
							.subscribe((success1) => {
								if (this.data.season) {
									if (success1.data != null) {
										this.dataService.getDataById('getSeasonById/' + sId)
											.subscribe((success2) => {
												this.episodes.season_id = { "id": success2.data.id, "name": success2.data.number + ' - ' + success2.data.name };
											});
									} else {
										this.dataService.getDataById('getSeasonById/' + sId)
											.subscribe((success2) => {
												this.episodes.season_id = { "id": success2.data.id, "name": success2.data.number + ' - ' + success2.data.name };
											});
									}
								} else {
									this.episodes.season_id = { "id": success.data.id, "name": success.data.number + ' - ' + success.data.name };
								}
								if (success1.data != null) {
									this.episodes.number = this.appConfigs.autoincrementStr(success1.data.number);
								} else {
									this.episodes.number = "episode1";
								}
							}, (rejected) => {

							});

					}
				}, (rejected) => {

				});
		}
		if (this.data.episodeid && (this.data.mode == 'edit' || this.data.mode == 'view')) {
			this.focused = "focused";
			this.dataService.getDataById(this.apiRoutePrefix + '/' + this.data.episodeid + '/edit')
				.subscribe((success) => {
					this.episodes = success.data;
					if (this.episodes.start_date) {
						this.startDate['startDate'] = moment(this.episodes.start_date).format(this.DateFormat);
						this.episodes.start_date = moment(this.episodes.start_date).format(this.DateFormat);
					}
					if (this.episodes.end_date) {
						this.endDate['startDate'] = moment(this.episodes.end_date).format(this.DateFormat);
						this.episodes.end_date = moment(this.episodes.end_date).format(this.DateFormat);
					}

					if (this.episodes.thumbnail) {
						this.thumbnail_old = this.episodes.thumbnail;
						let fileExtension = this.episodes.thumbnail.split('.').pop();
						if (fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg' && fileExtension != 'gif') {
							this.thumbnail_preview_src = './assets/images/default/attechment.png';
						} else {
							this.thumbnail_preview_src = this.uploadUrl + this.dirName + '/' + this.episodes.thumbnail;
						}
					}
					if (this.episodes.thumbnail_display_name) {
						this.thumbnail_name = this.episodes.thumbnail_display_name;
					}

					let that = this;
					_.forEach(this.episodes.freshAssets, function (value, key) {
						that.freshAssetsArr.push(value['asset_id']);
					});
					_.forEach(this.episodes.reuseAssets, function (value, key) {
						// that.freshAssetsArr.push(value['asset_id']);
						that.reuseAssetsArr.push(value['asset_id']);
					});
					this.getReuseAssets();
					this.getFressAssets();

				}, (rejected) => {

					this.SaveButtonText = "SAVE";
				});
		}
	}

	// ! Get FreshAssets
	getFressAssets() {
		this.dataService.getListData('assetsItems', { project_id: this.projectId, episode_id: this.data.episodeid, fromEpisodes: true, isFresh: true, 'reuseArr': this.reuseAssetsArr })
			.subscribe((success) => {
				this.freshAssets = success.data;
			}, (rejected) => {

			});
	}

	//! Get ReuseAssets
	getReuseAssets() {
		this.dataService.getListData('assetsItems', { project_id: this.projectId, episode_id: this.data.episodeid, fromEpisodes: true, isFresh: false, 'freshArr': this.freshAssetsArr })
			.subscribe((success) => {
				this.reuseAssets = success.data;
			}, (rejected) => {

			});
	}

	// ! Search
	search(query, column) {
		if (column == 'seasons') {
			this.dataService.getListData('seasonsItems', { project_id: this.projectId, 'query': query['query'] })
				.subscribe((success) => {
					this.seasons = success.data;
				}, (rejected) => {

				});
		} else if (column == 'reuseAssets') {
			this.dataService.getListData('assetsItems', { project_id: this.projectId, episode_id: this.data.episodeid, fromEpisodes: true, isFresh: false, 'query': query['query'], 'freshArr': this.freshAssetsArr })
				.subscribe((success) => {
					this.reuseAssets = success.data;
				}, (rejected) => {

				});
		} else if (column == 'freshAssets') {
			this.dataService.getListData('assetsItems', { project_id: this.projectId, episode_id: this.data.episodeid, fromEpisodes: true, isFresh: true, 'query': query['query'], 'reuseArr': this.reuseAssetsArr })
				.subscribe((success) => {
					this.freshAssets = success.data;
				}, (rejected) => {

				});
		}
	}


	// ! On Submit Form
	onSubmit(form: NgForm) {
		this.isSaveDisabled = true;
		form.value.project_id = this.projectId;
		if (this.url != undefined) {
			form.value.thumbnail = this.url;
		}
		form.value.thumbnail_name = this.thumbnail_name;
		form.value.thumbnail_old = this.thumbnail_old;
		if (form.value.season_id) {
			form.value.season_id = this.episodes.season_id.id;
		}
		if ($('#start_date').val() != '' && $('#start_date').val() != undefined) {
			form.value.start_date = moment($('#start_date').val()).format('YYYY-MM-DD');
		} else {
			form.value.start_date = null;
		}
		if ($('#end_date').val() != '' && $('#end_date').val() != undefined) {
			form.value.end_date = moment($('#end_date').val()).format('YYYY-MM-DD');
		} else {
			form.value.end_date = null;
		}

		if (form.value.reuseAssets && form.value.reuseAssets != undefined) {
			var arr = "";
			_.forEach(this.episodes.reuseAssets, function (value) {
				arr += value['asset_id'];
				arr += ",";
			});
			form.value.reuseAssets = arr.slice(0, -1);
		}

		if (form.value.freshAssets && form.value.freshAssets != undefined) {
			var arr = "";
			_.forEach(this.episodes.freshAssets, function (value) {
				arr += value['asset_id'];
				arr += ",";
			});
			form.value.freshAssets = arr.slice(0, -1);
		}
		this.SaveButtonText = 'SAVING';
		if (this.data.episodeid && this.data.mode == 'edit') {
			form.value.id = this.data.episodeid;
			this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.episodeid, form.value)
				.subscribe((success) => {
					this.SaveButtonText = 'SAVED';
					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.isSaveDisabled = false;
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						this.SaveButtonText = "SAVE";
						this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
					}
					this.isSaveDisabled = false;
				});
		} else {
			this.dataService.storeData(this.apiRoutePrefix, form.value)
				.subscribe((success) => {
					setTimeout(() => {
						this.SaveButtonText = 'SAVE';
						this.CloseOtherWindow.nativeElement.click();
					}, 2);
					this.close();
					this.isSaveDisabled = false;
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.errors;
						this.SaveButtonText = "SAVE";
						this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
					}
					this.isSaveDisabled = false;
				});
		}
	}

	// ! Close
	close() {
		this.closeCreateEpisode.emit();
	}

	public dropped(files: NgxFileDropEntry[]) {
		this.files = files;
		for (const droppedFile of files) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {

					// Here you can access the real file
					//console.log(droppedFile.relativePath, file);

					var reader = new FileReader();
					this.thumbnail_name = file.name;
					this.thumbnail_type = file.type;

					reader.onload = (event: any) => {
						this.url = event.target.result;
						if (this.thumbnail_type != 'image/png' && this.thumbnail_type != 'image/jpg' && this.thumbnail_type != 'image/jpeg' && this.thumbnail_type != 'image/gif') {
							this.thumbnail_preview_src = './assets/images/default/attechment.png';
						} else {
							this.thumbnail_preview_src = this.url;
						}
					}
					reader.readAsDataURL(file);
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
				//console.log(droppedFile.relativePath, fileEntry);
			}
		}
	}

	public fileOver(event) {
		//console.log(event);
	}

	public fileLeave(event) {
		//console.log(event);
	}



	onSelectedFresh(event, type) {
		if (type == 'freshAssets') {
			if (this.freshAssetsArr != undefined) {
				this.freshAssetsArr.push(event.asset_id);
			} else {
				this.freshAssetsArr = event.asset_id;
			}
			this.getReuseAssets();
		} else {
			if (this.reuseAssetsArr != undefined) {
				this.reuseAssetsArr.push(event.asset_id);
			} else {
				this.reuseAssetsArr = event.asset_id;
			}
			this.getFressAssets();
		}

	}

	// ! On clear Fresh Assets
	onClearFresh(event, type) {
		if (type == 'freshAssets') {
			let index = this.freshAssetsArr.indexOf(event.asset_id);
			this.freshAssetsArr.splice(index, 1);
			this.getReuseAssets();
		} else {
			let index = this.reuseAssetsArr.indexOf(event.asset_id);
			this.reuseAssetsArr.splice(index, 1);
			this.getFressAssets()
		}
	}

	// ! On Select All Assets

	onSelectAll(event: any, type: string) {
		if (type == 'freshAssets') {
			this.freshAssetsArr = [];
			if (this.freshAssetsArr != undefined) {
				for (let data in event) {
					this.freshAssetsArr.push(event[data].asset_id);
				}
			} else {
				this.freshAssetsArr = event.asset_id;
			}
			this.getReuseAssets();
		} else {
			if (this.reuseAssetsArr != undefined) {
				this.reuseAssetsArr = [];
				for (let data in event) {
					this.reuseAssetsArr.push(event[data].asset_id);
				}
			} else {
				this.reuseAssetsArr = event.asset_id;
			}
			this.getFressAssets();
		}
	}

	// ! On DeSelect All Selected Assets
	onDeSelectAll(event: any, type: string) {
		if (type == 'freshAssets') {
			this.freshAssetsArr = event;
			this.getReuseAssets();
		} else {
			this.reuseAssetsArr = [];
			this.getFressAssets()
		}
	}

	unlinkImage(event) {
		event.stopPropagation();
		this.thumbnail_preview_src = '';
		this.thumbnail = '';
		this.thumbnail_name = 'unset';
		this.url = '';
	}
}
