export const environment = {
  production: true,
  isFirebaseEnabled: true,
  prodType: "production",
  appUrl: 'https://api.vanisoftware.com/',
  firebase: {
    apiKey: "AIzaSyBQv4XBZ46x_gTWAwpHoYN8EHFXIMRQtTw",
    authDomain: "vani-dev.firebaseapp.com",
    databaseURL: "https://vani-dev.firebaseio.com",
    projectId: "vani-dev",
    storageBucket: "vani-dev.appspot.com",
    messagingSenderId: "41300047286",
    appId: "1:41300047286:web:c0bd4d7108ca0e2a4c3d17",
  },
  firebaseList: "vani-prod",
  pusher: {
    debug: true,
    key: 'vani-production',
    cluster: 'mt1',
    wsHost: 'api.vanisoftware.com',
    wsPort: 6001,
    wssPort: 6001,
    transports: ['ws','wss'],
  },
};
