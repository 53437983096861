import { Component, HostListener, OnInit, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';
import { HttpClient } from '@angular/common/http';
import swal from 'sweetalert2';
import { ProgressbarModalComponent } from 'src/app/common/progressbar-modal/progressbar-modal.component';
import { GridService } from 'src/app/services/grid.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { ThumbnailModalServiceService } from 'src/app/services/thumbnail-modal-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-script-list',
  templateUrl: './script-list.component.html',
  styleUrls: ['./script-list.component.css']
})
export class ScriptListComponent implements OnInit {

  dragCounter = 0;

  @HostListener('document:dragenter', ['$event'])
  onDragEnter(event: DragEvent): void {
    event.preventDefault();
    this.dragCounter++;
    if (this.dragCounter === 1) {
      this.isComponentsDrag = true;
    }
  }

  @HostListener('document:dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    event.preventDefault();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.isComponentsDrag = false;
    }
  }

  @HostListener('document:drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.dragCounter = 0;
    this.isComponentsDrag = false;
  }

  bsModalRef: BsModalRef;
  projectScriptData: any[] = [];
  entityName: string = 'Scripts';
  projectId: string = '';
  // scriptToEdit:any = {};
  settings: any = JSON.parse(localStorage.getItem('settings'));
  public files: NgxFileDropEntry[] = [];
  editScriptMode: boolean = false;
  uploadedFileInfo: any = {};
  selectedFile: File | null = null;
  currentRole = '';
  isPermitted: boolean = false;
  projectroles = {};
  scriptSourcePath: any = '';
  clientCodeLowerCase: string = '';
  baseData: string;
  typeRestrictionArray: Array<any> = [
    'ppt', 'pdf', 'png', 'jpg', 'jpeg', 'svg', 'webP', 'doc', 'docx', 'xls', 'xlsx', 'csv', 'odf'
  ];
  isComponentsDrag: boolean = false;
  viewer = 'google';
  doc: string;
  env = environment;

  constructor(
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    public commonJs: CommonJs,
    private titleService: Title,
    private http: HttpClient,
    private gridService: GridService,
    private toastrService: ToastrService,
    private thumbnailModalService: ThumbnailModalServiceService,
  ) { }

  ngOnInit() {
    this.titleService.setTitle(this.settings.html_prefix + ' Scripts');
    this.clientCodeLowerCase = localStorage.getItem('client_code').toLowerCase();

    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
    });

    this.projectroles = (function () {
      var result;
      try {
        let result = JSON.parse(localStorage.getItem('projectRoles'))
        return result;
      } catch (exception) {
      }
    }());
    if (this.projectroles) {
      this.currentRole = this.projectroles['project_' + this.projectId];
    }

    if (this.currentRole == 'admin' || this.currentRole == 'producer' || this.currentRole == 'production' || this.currentRole == 'production coordinator' || this.currentRole == 'production manager') {
      this.isPermitted = true;
    } else {
      this.isPermitted = false;
    }

    this.getScriptsList();
  }

  // To download the script document
  downloadScriptDocument(fileName) {
    window.open(this.commonJs.uploadUrl + 'public/uploads/' + this.clientCodeLowerCase + '/script_attachment/' + fileName, 'Download');
  }

  /** View With Ngx Doc Viewer */
  viewWithNgxDocViewer(fileName: string, template: TemplateRef<any>) {
    if(fileName.split('.')[1]== 'png' || fileName.split('.')[1]== 'jpg' || fileName.split('.')[1]== 'jpeg'){
      this.doc = this.commonJs.uploadUrl + (this.env.prodType=='offline'?'uploads/':'public/uploads/') + this.clientCodeLowerCase + '/script_attachment/' + fileName;
      this.thumbnailModalService.openThumbnailModal(this.doc);
    }else{
      const config = {
        class: 'modal-md custom-modal-preview',
        centered: true,
        animated: false
      };
      // if(fileName.split('.')[1]== 'doc' || fileName.split('.')[1]== 'docx'){
      //   this.viewer = 'mammoth';
      // }else if(fileName.split('.')[1]== 'pdf'){
      //   this.viewer = 'mammoth';
      // }else{
      //   this.viewer = 'google';
      // }
      this.bsModalRef = this.modalService.show(template, config);
      this.doc = this.commonJs.uploadUrl + (this.env.prodType=='offline'?'uploads/':'public/uploads/') + this.clientCodeLowerCase + '/script_attachment/' + fileName;
    }
  }

  // To open a modal for adding document
  public openAddScriptModal(template: TemplateRef<any>, script?) {
    this.editScriptMode = false;
    this.uploadedFileInfo = {};
    this.dataService.setProjectId(this.projectId);
    this.editScriptMode = script ? true : false;
    if (script) {
      this.editScriptMode = true;
      // this.scriptToEdit = script;
    }
    this.bsModalRef = this.modalService.show(template, { ignoreBackdropClick: true });

  }

  // On file dropped
  dropped(files: NgxFileDropEntry[], type: string) {
    console.log(files[0])
    if (files.length > 0) {
      let gotFileType = files[0]['relativePath'].split('.')[1];
      if (this.typeRestrictionArray.includes(gotFileType)) {
        this.files = files;
        for (const droppedFile of files) {
          console.log(droppedFile.fileEntry.isFile)
          if (droppedFile.fileEntry.isFile) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => {
              // if file type is more than limit ( 5 MB )
              if (file.size > 5 * 1024 * 1024) { // if file size is greater than 5mb
                this.files = [];
                this.toastrService.notify('error', '', "File limit exceeds to 5mb ");
                return;
              }
              let eventLastValue: any = [];
              eventLastValue.push(file);
              /* wire up file reader */
              var reader: FileReader = new FileReader();
              reader.onload = (e: any) => {
                // console.log(droppedFile);
                this.uploadedFileInfo.docname = eventLastValue[0].name;
                this.uploadedFileInfo.file = file;
                // Access the Base64 string from reader result
                this.baseData = reader.result as string;
                // Here 'base64String' contains the Base64 representation of the file
              };
              reader.readAsDataURL(eventLastValue[0]);
            });
          } else {
            const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
            // Handle directory entries if needed
          }
        }
        this.dragCounter = 0;
        this.isComponentsDrag = false;
        type == 'default' ? this.addScriptDocument() : ''
      } else {
        this.files = [];
        this.toastrService.notify('error', '', "This type of file is not allowed!");
        this.dragCounter = 0;
        this.isComponentsDrag = false;
        return;
      }
    }


  }

  unlinkImage(event) {
    event.stopPropagation();

    this.uploadedFileInfo.docname = '';
    let selectedFile = event.target as HTMLInputElement;
    this.uploadedFileInfo.file = '';
    this.files = [];
    // this.data = [];
  }

  // On Filer over
  fileOver(event) {

  }

  // On Filer leave
  fileLeave(event) {

  }

  // To open file selector
  openFileSelector() {

  }

  // To get scripts list
  getScriptsList(script?) {
    this.dataService.getOtherData('scriptList', { 'project_id': this.projectId })
      .subscribe((success) => {
        this.scriptSourcePath = success.directoryPath
        this.projectScriptData = success.data.reverse();


      }, (rejected) => {

        console.error('Error fetching script-documents:', rejected);
      });
    this.bsModalRef ? this.bsModalRef.hide() : '';
  }

  // To add script-document
  addScriptDocument() {
    // console.log(this.uploadedFileInfo);
    const scriptData = {
      project_id: this.projectId,
      document_name: this.uploadedFileInfo.docname,
      file: this.baseData
    };
    // console.log(scriptData);
    //ppt, pdf, png, jpg, jpeg, svg, webP,  doc, docx, xls, xlsx, csv, odf (optional)

    //  hide modal
    this.bsModalRef ? this.bsModalRef.hide() : '';

    //  LOADER START
    this.gridService.apiProgress = 0;
    this.bsModalRef = this.modalService.show(ProgressbarModalComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    });

    const progressInterval = setInterval(() => {
      if (this.gridService.apiProgress < 90) { // Update progress up to 90%
        this.gridService.apiProgress += 10; // Increment progress by 10%
      }
    }, 1000); // Update progress every second
    setTimeout(() => {
      clearInterval(progressInterval);
    }, 10000);

    this.dataService.scriptUpload('addScripts', scriptData)
      .subscribe(
        (response) => {
          // console.log(response);
          this.toastrService.notify('success', '', response.message);
          this.baseData = '';
          this.gridService.apiProgress = 100;
          this.bsModalRef ? this.bsModalRef.hide() : '';
          this.files = [];
          this.getScriptsList('scriptAdded');
        },
        (error) => {
          // console.log(error);
          this.toastrService.notify('error', '', error.message);
          this.gridService.apiProgress = 100;
          this.bsModalRef ? this.bsModalRef.hide() : '';
          console.error('Error adding script:', error);
          this.baseData = '';
          this.files = [];
        }
      );
  }


  // Hide add script modal
  hideAddScriptModal() {
    this.bsModalRef.hide();
    this.files = [];
  }

  // To remove script-document
  actionClick(type: string, row: any) {
    let removeScriptData: any = {
      'id': row.id,
      'project_id': this.projectId,
      'document_name': row.document_name,
      'client_code': this.clientCodeLowerCase
    };
    if (type == 'delete') {
      let that = this;
      swal.fire({
        title: 'Delete script',
        text: "Are you sure you want to delete " + row.document_name + " ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.value) {
          // console.log(row)
          this.dataService.getOtherData('removeScript', removeScriptData).subscribe(
            (response) => {
              // console.log('Script added successfully:', response.script);
              this.getScriptsList();
              this.toastrService.notify('success', '', response.message);
            },
            (error) => {
              console.error('Error adding script:', error);
            }
          );

        }
      });

    } else {
      this.bsModalRef ? this.bsModalRef.hide() : '';
    }

  }


}
