import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import * as moment from 'moment';
import * as _ from "lodash";
declare var $: any;
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
//  import { NgProgressService } from 'ngx-progressbar';
import { Subscription } from 'rxjs';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';


@Component({
  selector: 'create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})


export class CreateUserComponent implements OnInit {
  public files: NgxFileDropEntry[] = [];
  entityName = 'Employees';
  entityNameSingular = 'Employee';
  apiRoutePrefix = 'users';
  dirName = 'users';
  apiRoutePrefixListItem = 'listItem';
  @Input('attr.data') dataHtml: any;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @Output() closeCreateUser = new EventEmitter();
  @ViewChild('newCodeRef', { static: false }) newCodeRef: ElementRef;
  data: any;
  myform: any;
  onClose: any;
  users: { [index: string]: any } = {};
  userDetails: { [index: string]: any } = {};
  uId: number;
  roles = [];
  errors: { [index: string]: any } = {};
  focused: string = '';
  SaveButtonText: string = 'SAVE';
  SaveButtonImg: string = "";
  isMessgaeMode: any;
  settingsAll: any = JSON.parse(localStorage.getItem('settings'));
  role = localStorage.getItem('role');
  clientCode = localStorage.getItem('client_code');
  location: Array<any>;
  software_skills: Array<any>;
  reporting_to: Array<any>;
  prod_person: Array<any>;
  primary_department: Array<any>;
  new_department: Array<any>;
  secondary_department: Array<any>;
  new_role: Array<any>;
  exit_reason: Array<any>;
  attachmentFile = './assets/images/default/attechment.png';
  docattachmentFile = './assets/images/default/doc-attachment.png';
  pdfattachmentFile = './assets/images/default/pdf-attachment.png';
  url: any;
  preview_src: string = '';
  isStatusDisabled: number = null;
  image_name: string;
  image_type: string;
  image_old: string;
  specialCharactersRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

  //Docs
  attachmentUrl: any;
  attachment_preview_src: any;
  attachment: string;
  attachment_type: string;
  attachment_old: string;
  multipleFiles: Array<any> = [];
  TempMultipleFiles: Array<any> = [];
  attachment_display_name: string;
  isFirstTime: boolean = true;
  attachmentArr = [];
  invalidAttach: boolean = false;
  private subscription: Subscription;
  private timer: Observable<any>;

  userForm: any;
  DateFormat: any;
  public birthDate = {
    singleDatePicker: true,
    autoUpdateInput: false,
    showDropdowns: true,
    autoApply: false,
    drops: 'down',
    "maxDate": "31 Dec 2030",
    locale: {
      format: 'DD MMM YYYY'
    },
  }

  public joiningDate = {
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    autoApply: true,
    drops: 'down',
    "maxDate": "31 Dec 2030",
    locale: {
      format: 'DD MMM YYYY'
    },
  }

  public exitDate = {
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    autoApply: true,
    drops: 'down',
    "maxDate": "31 Dec 2030",
    locale: {
      format: 'DD MMM YYYY'
    },
  }

  public transferDate = {
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    autoApply: true,
    drops: 'down',
    "maxDate": "31 Dec 2030",
    locale: {
      format: 'DD MMM YYYY'
    },
    startDate: moment().format('YYYY-MM-DD'),
  }

  gender: Array<any> = this.appConfigs.user_gender;
  mode: Array<any> = this.appConfigs.user_mode;
  new_mode: Array<any> = [];
  type: Array<any> = [{ "id": 'artist', "name": "Artist" }, { "id": 'lead', "name": "Lead" }, { "id": 'support', "name": "Support" }, { "id": 'supervisor', "name": "Supervisor" }];
  vertical: Array<any> = [];
  attrition_type: Array<any> = [];
  status: Array<any> = [];
  selectedDepartment = [];
  isExitDisplay: boolean = false;
  isManageSidebar: boolean = false;
  isDeleteMode: boolean = false;
  DeleteButtonText: string = 'DELETE';
  DeleteErrorMessage: string;
  deleteId: number;
  deleteName: string = '';
  deleteEcode: string = '';
  isDeptDisabled: boolean = false;
  isRoleDisabled: boolean = false;
  isModeDisabled: boolean = false;
  isTransfer: boolean = false;
  isModeTransfer: boolean = false;
  isStatusChange: boolean = false;
  transferListing: boolean = false;
  modeTransferListing: boolean = false;
  transfer_history_data = [];
  mode_transfer_history_data = [];
  empCustomColumns: Array<any> = [];
  uploadUrl = this.commonJs.getUploadUrl();

  isSaveDisabled: boolean = false;

  isEmployeesPermission = [];

  cars = [];

  dropdownSettings: any = {};
  dropdownSettingsSingle: any = {};
  dropdownSettingsDepartment: any = {}

  constructor(
    private dataService: DataService,
    private toastrService: ToastrService,

    public commonJs: CommonJs,
    public appConfigs: AppConfigs
  ) { }

  ngOnInit() {
    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
    }

    if (this.data.is_exit) {
      this.isExitDisplay = true;
    }

    if (this.data.mode == 'manage' || this.data.mode == 'Transfer' || this.data.mode == 'Mode' || this.data.mode == 'Exit' || this.data.mode == 'Active') {
      this.isManageSidebar = true;
      this.isExitDisplay = false;
    }
    this.DateFormat = this.settingsAll.date_format;
    this.birthDate.locale.format = this.DateFormat;
    this.joiningDate.locale.format = this.DateFormat;
    this.exitDate.locale.format = this.DateFormat;
    this.transferDate.locale.format = this.DateFormat;

    this.users = {
      name: '',
      email: '',
      password: '',
      joining_date: '',
      gender: '0',
      allow_access: 1,
      role_id: '',
      status: 1,
      primary_department: [],
      secondary_department: [],
      software_skills: [],
      image: '',
      image_name: '',
      image_old: '',
      image_display_name: '',
      payroll_ecode: '',
      contract_ecode: '',
      dob: '',
      location: '',
      mode: [],
      type: 'artist',
      prod_person: 0,
      exit_date: '',
      transfer_date: moment().format(this.DateFormat)
    }

    // if (this.settingsAll.emp_custom_columns != undefined) {
    //   this.empCustomColumns = this.settingsAll.emp_custom_columns.split(",");
    // }

    this.dataService.getListData('listItemList/emp_custom_columns', '')
      .subscribe((success) => {
        this.empCustomColumns = success.data;
      }, (rejected) => {

      });

    this.subngOnInit();

    this.isEmployeesPermission['edit'] = this.commonJs.checkAccess('employees', 'edit', { 'baiPass': ['admin'] });
    this.isEmployeesPermission['delete'] = this.commonJs.checkAccess('employees', 'delete', { 'baiPass': ['admin'] });

    // this.cars = [
    //       {label: 'Audi', value: 'Audi'},
    //       {label: 'BMW', value: 'BMW'},
    //       {label: 'Fiat', value: 'Fiat'},
    //       {label: 'Ford', value: 'Ford'},
    //       {label: 'Honda', value: 'Honda'},
    //       {label: 'Jaguar', value: 'Jaguar'},
    //       {label: 'Mercedes', value: 'Mercedes'},
    //       {label: 'Renault', value: 'Renault'},
    //       {label: 'VW', value: 'VW'},
    //       {label: 'Volvo', value: 'Volvo'}
    //   ];

    // this.users.secondary_department = [{label: 'Audi', value: 'Audi'},
    //

    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "code",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 10,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No data available',
      searchPlaceholderText: 'Search assets',
      maxHeight: 90,
      autoPosition: false,
    };

    this.dropdownSettingsSingle = {
      singleSelection: true,
      idField: "department_id",
      textField: "department_name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      itemsShowLimit: 10,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No data available',
      searchPlaceholderText: 'Search final department',
      maxHeight: 90,
      autoPosition: false,
    };

    this.dropdownSettingsDepartment = {
      singleSelection: false,
      idField: "department_id",
      textField: "department_name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No data available',
      searchPlaceholderText: 'Search department',
      maxHeight: 90,
      autoPosition: false,
    };

    this.getSecondaryDept(0, '');

  }

  ngAfterViewInit() {
    $.AdminBSB.input.activate();
  }

  subngOnInit() {
    console.log(this.settingsAll)
    this.users.role_id = { id: 14, name: "Artist (Creative)" };
    if (this.data.mode == 'create') {
      this.search('', 'mode');
      this.users.mode = { id: "payroll", name: "Payroll" };
      this.users.joining_date = moment().format(this.DateFormat);
      this.joiningDate['maxDate'] = moment().format(this.DateFormat);
    }

    if (this.data.userid && (this.data.mode == 'edit' || this.data.mode == 'view' || this.data.mode == 'manage') || this.data.mode == 'Transfer' || this.data.mode == 'Mode' || this.data.mode == 'Exit' || this.data.mode == 'Active') {
      this.focused = "focused";
      this.dataService.getDataById(this.apiRoutePrefix + '/' + this.data.userid + '/edit')
        .subscribe((success) => {
          this.users = success.data;
          if (this.users.secondary_department != "" && this.users.secondary_department != null) {
            this.users.secondary_department = this.users.secondary_department;
          } else {
            this.users.secondary_department = [];
          }
          if (this.users.software_skills != "" && this.users.software_skills != null) {
            this.users.software_skills = this.users.software_skills;
          } else {
            this.users.software_skills = [];
          }
          if (this.users.primary_department != 0 && this.users.primary_department != null && this.users.primary_department != undefined) {
            this.isDeptDisabled = true;
          }
          if (this.users.role_id != 0 && this.users.role_id != null && this.users.role_id != undefined) {
            this.isRoleDisabled = true;
          }
          if (this.users.mode != null && this.users.primary_department != undefined) {
            this.isModeDisabled = true;
          }
          if (this.users.dob) {
            this.birthDate['startDate'] = moment(this.users.dob).format(this.DateFormat);
            this.users.dob = moment(this.users.dob).format(this.DateFormat);
          }
          if (this.users.joining_date) {
            this.joiningDate['maxDate'] = moment().format(this.DateFormat);
            this.joiningDate['startDate'] = moment(this.users.joining_date).format(this.DateFormat);
            this.users.joining_date = moment(this.users.joining_date).format(this.DateFormat);
          }
          if (this.users.exit_date) {
            this.exitDate['startDate'] = moment(this.users.exit_date).format(this.DateFormat);
            this.users.exit_date = moment(this.users.exit_date).format(this.DateFormat);
          } else {
            this.users.exit_date = moment().format(this.DateFormat);
          }
          //transferDate
          this.transferDate['startDate'] = moment(this.users.transfer_date).format(this.DateFormat);
          this.users.transfer_date = moment(this.users.transfer_date).format(this.DateFormat);
          //}
          if (this.users.image) {
            this.image_old = this.users.image;
            let fileExtension = this.users.image.split('.').pop();
            if (fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg' && fileExtension != 'gif') {
              this.preview_src = this.attachmentFile;
            } else {
              this.preview_src = this.uploadUrl + this.dirName + '/' + this.users.image;
            }
          }
          if (this.users.image_display_name) {
            this.image_name = this.users.image_display_name;
          }

          if (this.users.attachments) {
            let i;
            for (i = 0; i < this.users.attachments.length; i++) {
              let temp_file = {};
              temp_file['attachment_old'] = this.users.attachments[i].attachment;
              temp_file['attachment'] = this.users.attachments[i].attachment;
              temp_file['attachment_display_name'] = this.users.attachments[i].attachment_display_name;
              temp_file['ext'] = this.users.attachments[i].ext;
              let fileExtension = (this.users.attachments[i].attachment.split('.').pop()).toLowerCase();
              temp_file['id'] = this.users.attachments[i].id;
              temp_file['user_id'] = this.users.attachments[i].user_id;
              if (fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg' && fileExtension != 'gif') {
                temp_file['attachment_preview_src'] = './assets/images/default/attechment.png';
              } else {
                temp_file['attachment_preview_src'] = this.uploadUrl + this.dirName + '/attachments/' + this.users.attachments[i].attachment;
              }
              this.multipleFiles.push(temp_file);
              this.TempMultipleFiles.push(temp_file);
            }
          }

          this.users.status_old = this.users.status;
          this.isStatusDisabled = this.users.status.id;
        }, (rejected) => {

        });
      if (this.data.mode == 'Transfer') {
        this.deptTransfer()
      }
      if (this.data.mode == 'Mode') {
        this.modeTransfer()
      }
      if (this.data.mode == 'Exit') {
        this.toggleExitFrm()
      }
      if (this.data.mode == 'Active') {
        this.statusChange()
      }
    } else {
      this.dataService.getListData('getLastRecord', { 'table': 'users' })
        .subscribe((success) => {
          this.users.emp_code_final = this.appConfigs.autoincrementStr(success.data);
        }, (rejected) => {

        });
      this.dataService.getOtherData('searchItemList', { list_name: 'emp_location', 'query': '' })
        .subscribe((success) => {
          this.location = success.data;
          if (this.location.length == 1) {
            this.users.location = { id: this.location[0]['item_name'], item_name: this.location[0]['item_name'] };
          }
        }, (rejected) => {

        });
    }
    this.studioLocation();
  }

  /** If Studio Location Enable */
  studioLocation() {
    this.dataService.getOtherData('searchItemList', { list_name: 'emp_location', 'query': '' })
      .subscribe((success) => {
        if (success.data.length > 0) {
          if (this.settingsAll.is_studio_multiple_location == '') {
            this.location = success.data.slice(0, 1);
            this.users.location = { id: this.location[0]['item_name'], item_name: this.location[0]['item_name'] };
          } else {
            this.location = success.data;
          }
        }
      }, (rejected) => {

      });
  }


  search(query, column) {
    if (column == 'reporting_to') {
      this.dataService.getOtherData('getReportingTo', { 'query': query['query'] })
        .subscribe((success) => {
          this.reporting_to = success.data;
        });
    } else if (column == 'prod_person') {
      this.dataService.getOtherData('getProductionPerson', { 'query': query['query'] })
        .subscribe((success) => {
          this.prod_person = success.data;
        });
    } else if (column == 'primary_department') {
      this.getPrimaryDept([0], '', query['query']);
    } else if (column == 'secondary_department') {
      this.getSecondaryDept(this.users.primary_department['id'], query['query']);
    }
    else if (column == 'software_skills') {
      this.dataService.getOtherData('searchItemList', { list_name: 'emp_software_skills', 'query': query['query'] })
        .subscribe((success) => {
          this.software_skills = success.data;
        }, (rejected) => {

        });
    }
    else if (column == 'location') {
      this.dataService.getOtherData('searchItemList', { list_name: 'emp_location', 'query': query['query'] })
        .subscribe((success) => {
          this.location = success.data;
        }, (rejected) => {

        });
    } else if (column == 'role_id') {
      this.dataService.getOtherData('getRoles', query)
        .subscribe((success) => {
          this.roles = success.data;
        });
    } else if (column == 'mode') {
      this.mode = this.appConfigs.user_mode.map(v => { return v });
    } else if (column == 'vertical') {
      this.vertical = this.appConfigs.user_vertical.map(v => { return v });
    } else if (column == 'attrition_type') {
      this.attrition_type = this.appConfigs.user_attrition_type.map(v => { return v });
    } else if (column == 'exit_reason') {
      this.dataService.getOtherData('searchItemList', { list_name: 'emp_reasons_for_exit', 'query': query['query'] })
        .subscribe((success) => {
          this.exit_reason = success.data;
        }, (rejected) => {

        });
    } else if (column == 'new_department') {
      this.getPrimaryDept([0], 'edit', query['query']);
    } else if (column == 'status') {
      this.status = [
        { 'id': 1, 'name': 'Active' },
        { 'id': 0, 'name': 'Exit' },
      ];
    } else if (column == 'new_mode') {
      this.new_mode = this.appConfigs.user_mode.map(v => { return v });
      this.new_mode = this.new_mode.filter(dept => dept.id !== this.users.mode.id);
    } else if (column == 'new_role') {
      this.dataService.getOtherData('getRoles', query)
        .subscribe((success) => {
          this.new_role = success.data;
          //this.new_role = this.new_role.filter(role => role.id !== this.users.role_id.id);
        });
    }

  }

  getdepartment(role_id) {
    this.dataService.getListData('getDefaultDepartment', { role_id: role_id })
      .subscribe((success) => {
        if (success.data != null) {
          /*this.primary_department=success.data;*/
          this.users.primary_department = success.data;
        }
        else {
          this.users.primary_department = null;
        }
      }, (rejected) => {

      });
  }

  // To not allow any special characters in DESIGNATION field
  onSpecialCharEntered(event) {

    if (this.specialCharactersRegex.test(event.target.value)) {
      this.errors.designation = 'Special characters are not allowed!';
    } else {
      this.errors.designation = '';
    }

  }


  onSubmit(form: NgForm) {
    this.SaveButtonText = 'SAVING';
    this.isSaveDisabled = true;
    if (this.url != undefined) {
      form.value.image = this.url;
      form.value.image_name = this.image_name;
    }

    if ($('#joining_date').val() != '' && $('#joining_date').val() != undefined) {
      form.value.joining_date = moment($('#joining_date').val()).format('YYYY-MM-DD');
    }

    if ($('#dob').val() != '' && $('#dob').val() != undefined) {
      form.value.dob = moment($('#dob').val()).format('YYYY-MM-DD');
    } else {
      form.value.dob = null;
    }

    if (form.value.gender == null || form.value.gender == '') {
      this.errors.gender = 'Required';
      this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
      this.SaveButtonText = "SAVE";

      return false;
    }


    if (form.value.secondary_department) {
      var arr = "";
      _.forEach(this.users.secondary_department, function (value) {
        arr += "|";
        arr += value['id'];
        arr += "|";
        arr += ",";
      });
      form.value.secondary_department = arr.slice(0, -1);
    }

    //software-skills
    if (form.value.software_skills) {
      var arr = "";
      _.forEach(this.users.software_skills, function (value) {
        arr += "|";
        arr += value['item_name'];
        arr += "|";
        arr += ",";
      });
      form.value.software_skills = arr.slice(0, -1);
    }

    // reporting to
    if (form.value.reporting_to) {
      var arr = "";
      _.forEach(this.users.reporting_to, function (value) {
        arr += "|";
        arr += value['id'];
        arr += "|";
        arr += ",";
      });
      form.value.reporting_to = arr.slice(0, -1);
    }

    // production persion
    if (form.value.prod_person) {
      var arr = "";
      _.forEach(this.users.prod_person, function (value) {
        arr += "|";
        arr += value['id'];
        arr += "|";
        arr += ",";
      });
      form.value.prod_person = arr.slice(0, -1);
    }

    //software-skills-ends
    if (form.value.reporting_to && this.users.reporting_to.id != undefined) {
      form.value.reporting_to = this.users.reporting_to.id;
    } else {
      form.value.reporting_to = form.value.reporting_to;
    }

    if (form.value.prod_person && this.users.prod_person.id) {
      form.value.prod_person = this.users.prod_person.id;
    } else {
      form.value.prod_person = form.value.prod_person;
    }

    if (this.users.location.item_name != undefined) {
      form.value.location = this.users.location.item_name;
    } else {
      form.value.location = form.value.location;
    }

    form.value.role_id = this.users.role_id.id;
    if (form.value.vertical && this.users.vertical.id != undefined) {
      form.value.vertical = this.users.vertical.id;
    }
    // else {
    //   this.errors.vertical = 'Select option from list';
    //   this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
    //   this.SaveButtonText = "SAVE";

    //   return false;
    // }

    form.value.role = this.role;
    form.value.client_code = this.clientCode;
    if (this.data.userid && this.data.mode == 'edit') {
      if (form.value.password == undefined) {
        form.value.password = '';
      }
      form.value.id = this.data.userid;
      form.value.image_old = this.image_old;
      form.value.primary_department = this.users.primary_department.id;
      form.value.old_primary_department = this.users.old_primary_department.id;
      form.value.mode = this.users.mode.id;
      this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.userid, form.value)
        .subscribe((success) => {
          // Save effect within button
          this.SaveButtonText = 'SAVED';
          setTimeout(() => {
            this.CloseOtherWindow.nativeElement.click();
          }, 2);
          this.close();
          this.isSaveDisabled = false;
        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.errors = err.error;
            this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
            this.SaveButtonText = "SAVE";

          }
          this.isSaveDisabled = false;
        });
    } else {
      if (form.value.mode && this.users.mode.id != undefined) {
        form.value.mode = this.users.mode.id;
      }
      else {
        this.errors.mode = 'Select option from list';
        this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
        this.SaveButtonText = "SAVE";

        this.isSaveDisabled = false;
        return false;
      }
      if (form.value.primary_department && form.value.primary_department != undefined) {
        if (this.users.primary_department.id != undefined) {
          form.value.primary_department = this.users.primary_department.id;
        }
      } else {
        this.errors.primary_department = 'Select option from list';
        this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
        this.SaveButtonText = "SAVE";

        this.isSaveDisabled = false;
        return false;
      }
      this.dataService.storeData(this.apiRoutePrefix, form.value)
        .subscribe((success) => {
          this.SaveButtonText = 'SAVED';
          setTimeout(() => {
            this.CloseOtherWindow.nativeElement.click();
          }, 2);
          this.close();
          this.isSaveDisabled = false;
        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.errors = err.errors;
            if (this.errors.users_limit) {
              this.toastrService.notify('warn', '', this.errors.users_limit);
            } else {
              this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
            }
            this.SaveButtonText = "SAVE";

          }
          this.isSaveDisabled = false;
        });
    }
  }


  onDocsSubmit(fattach: NgForm) {
    if (this.multipleFiles.length > 0) {
      fattach.value.attachmentArr = this.multipleFiles;
    }
    fattach.value.id = this.data.userid;
    this.dataService.storeData(this.apiRoutePrefix + 'Attachements', fattach.value)
      .subscribe((success) => {
        // Save effect within button
        this.SaveButtonText = 'SAVED';
        setTimeout(() => {
          this.CloseOtherWindow.nativeElement.click();
        }, 2);
        this.close();

      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
          this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
          this.SaveButtonText = "SAVE";

        }
      });
  }


  toggleFrm() {
    if (this.isTransfer && !this.transferListing) {
      this.isTransfer = true;
      this.transferListing = true;
    } else if (this.transferListing) {
      this.isTransfer = false;
      this.transferListing = false;
    } else if (this.isStatusChange) {
      this.isStatusChange = false;
    } else if (this.isModeTransfer && !this.modeTransferListing) {
      this.isModeTransfer = true;
      this.modeTransferListing = true;
    } else if (this.modeTransferListing) {
      this.isModeTransfer = false;
      this.modeTransferListing = false;
    } else if (this.isDeleteMode) {
      this.isDeleteMode = false;
    } else if (this.isExitDisplay) {
      this.isExitDisplay = false;
    }
  }


  toggleExitFrm() {
    this.users.attrition_type = " ";
    this.users.exit_reason = " ";
    this.errors.attrition_type = "";
    this.errors.exit_reason = "";
    this.isExitDisplay = true;
  }


  statusChange() {
    // this.users.status = { id: 0, name: 'Exit' };
    // this.isStatusChange = !this.isStatusChange;

    // fmsc.value.id = this.data.userid;
    // fmsc.value.userId = this.data.userid;
    // fmsc.value.image_old = this.image_old;
    // if (fmsc.value.status) {
    //   fmsc.value.status = this.users.status.id;
    // }
    // if (fmsc.value.status) {
    //   fmsc.value.status_old = this.users.status_old.id;
    // }

    let activateEmpObj = {
      id: this.data.userid,
      status: 1, // For Active employee status is '1'.
      status_old: this.users.status_old.id,
      userId: this.data.userid,
    }

    this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.userid, activateEmpObj)
      .subscribe((success) => {
        // Save effect within button
        this.SaveButtonText = 'SAVED';
        setTimeout(() => {
          this.CloseOtherWindow.nativeElement.click();
        }, 2);
        this.close();

      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;

        }
      });
  }

  onSubmitUserExit(fmExit: NgForm) {
    fmExit.value.joining_date = moment(this.users.joining_date).format('YYYY-MM-DD');
    fmExit.value.userId = this.data.userid;
    if ($('#exit_date').val() != '' && $('#exit_date').val() != undefined) {
      fmExit.value.exit_date = moment($('#exit_date').val()).format('YYYY-MM-DD');
    } else {
      this.errors.exit_date = 'Required';
      fmExit.value.exit_date = null;
    }
    if (fmExit.value.exit_date == null || fmExit.value.exit_date == undefined || fmExit.value.exit_date == '') {
      this.errors.exit_date = "Required";
      return false;
    }
    if (fmExit.value.attrition_type) {
      //fmExit.value.attrition_type = fmExit.value.attrition_type.name;
      fmExit.value.attrition_type = this.users.attrition_type.name;
    }
    if (fmExit.value.exit_reason) {
      //fmExit.value.exit_reason = fmExit.value.exit_reason.item_name;
      fmExit.value.exit_reason = this.users.exit_reason.item_name;
    }
    fmExit.value.image_old = this.image_old;
    this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.userid, fmExit.value)
      .subscribe((success) => {
        // Save effect within button
        this.SaveButtonText = 'SAVED';
        setTimeout(() => {
          this.CloseOtherWindow.nativeElement.click();
        }, 2);
        this.close();
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
          this.toastrService.notify('warn', '', err.message);
          this.SaveButtonText = "SAVE";
          setTimeout(() => {
            this.CloseOtherWindow.nativeElement.click();
          }, 2);
          this.close();
        }
      });
  }


  onTransfer(fmTransfer: NgForm) {
    fmTransfer.value.id = this.data.userid;
    fmTransfer.value.primary_department = this.users.primary_department;
    fmTransfer.value.old_role = this.users.role_id;
    if (fmTransfer.value.primary_department != undefined && fmTransfer.value.primary_department != "") {
      fmTransfer.value.primary_department = this.users.primary_department.id;
    }
    if (fmTransfer.value.primary_department == undefined || fmTransfer.value.primary_department == "") {
      fmTransfer.value.primary_department = 0;
    }
    if (fmTransfer.value.new_department != undefined && fmTransfer.value.new_department != "") {
      fmTransfer.value.new_department = this.users.new_department.id;
    }
    if (fmTransfer.value.old_role != undefined && fmTransfer.value.old_role != "") {
      fmTransfer.value.old_role = this.users.role_id.id;
    }
    if (fmTransfer.value.old_role == undefined || fmTransfer.value.old_role == "") {
      fmTransfer.value.old_role = 0;
    }
    if (fmTransfer.value.new_role != undefined && fmTransfer.value.new_role != "") {
      fmTransfer.value.new_role = this.users.new_role.id;
    } else {
      this.errors.new_role = "Required";
      this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
      return false;
    }
    if ($('#transfer_date').val() != '' && $('#transfer_date').val() != undefined) {
      fmTransfer.value.transfer_date = moment($('#transfer_date').val()).format('YYYY-MM-DD');
    } else {
      fmTransfer.value.transfer_date = null;
    }
    this.dataService.storeData(this.apiRoutePrefix + 'DeptTransfer', fmTransfer.value)
      .subscribe((success) => {
        this.SaveButtonText = 'SAVED';
        setTimeout(() => {
          this.CloseOtherWindow.nativeElement.click();
        }, 2);
        this.isTransfer = false;
        this.close();

      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err;
          if (this.errors.status == "error") {
            this.toastrService.notify('warn', '', this.errors.message);
          } else {
            this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
          }
          this.SaveButtonText = "SAVE";

        }
      });
  }


  onStatusChange(fmsc: NgForm) {
    fmsc.value.id = this.data.userid;
    fmsc.value.userId = this.data.userid;
    fmsc.value.image_old = this.image_old;
    if (fmsc.value.status) {
      fmsc.value.status = this.users.status.id;
    }
    if (fmsc.value.status) {
      fmsc.value.status_old = this.users.status_old.id;
    }

    this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.userid, fmsc.value)
      .subscribe((success) => {
        // Save effect within button
        this.SaveButtonText = 'SAVED';
        setTimeout(() => {
          this.CloseOtherWindow.nativeElement.click();
        }, 2);
        this.close();

      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;

        }
      });
  }


  onModeChange(fmmc: NgForm) {
    this.SaveButtonText = 'SAVING';
    fmmc.value.id = this.data.userid;
    fmmc.value.old_code = this.users.emp_code_final;
    fmmc.value.old_mode = this.users.mode.id;
    if (fmmc.value.new_mode != '' && fmmc.value.new_mode != undefined) {
      fmmc.value.new_mode = this.users.new_mode.id;
    }
    if ($('#transfer_date').val() != '' && $('#transfer_date').val() != undefined) {
      fmmc.value.transfer_date = moment($('#transfer_date').val()).format('YYYY-MM-DD');
    } else {
      fmmc.value.transfer_date = null;
    }
    this.dataService.storeData(this.apiRoutePrefix + 'ModeTransfer', fmmc.value)
      .subscribe((success) => {
        this.SaveButtonText = 'SAVED';
        setTimeout(() => {
          this.CloseOtherWindow.nativeElement.click();
        }, 2);
        this.isModeTransfer = false;
        this.close();
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
          this.SaveButtonText = "SAVE";
          this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');

        }
      });
  }


  close() {
    this.closeCreateUser.emit();
  }


  getUsers(id) {
    this.dataService.getOtherData('getUsers', { id: id })
      .subscribe((success) => {
      }, (rejected) => {

      });
  }


  getProductionPerson(id) {
    this.dataService.getOtherData('getProductionPerson', { id: id })
      .subscribe((success) => {
        this.prod_person = success.data;
      }, (rejected) => {

      });
  }

  getPrimaryDept(ids = [0], mode = "create", query = '') {
    this.dataService.getOtherData('getPrimaryDept', { id: ids, 'query': query })
      .subscribe((success) => {
        this.primary_department = success.data;
        if (mode == 'edit') {
          this.new_department = success.data;
        }
      }, (rejected) => {

      });
  }


  getSecondaryDept(ids = 0, query = '') {
    this.dataService.getOtherData('getSecondaryDept', { id: ids, 'query': query })
      .subscribe((success) => {
        this.secondary_department = success.data;
      }, (rejected) => {

      });
  }


  primaryOptions(e) {
    let sds = this.users.primary_department;
    this.getSecondaryDept(sds);
  }


  secondaryOptions(e) {
    let pds = this.users.secondary_department;
    this.getPrimaryDept(pds);
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          // Here you can access the real file
          //console.log(droppedFile.relativePath, file);

          var reader = new FileReader();
          this.image_name = file.name;
          this.image_type = file.type;

          reader.onload = (event: any) => {
            this.url = event.target.result;
            if (this.image_type != 'image/png' && this.image_type != 'image/jpg' && this.image_type != 'image/jpeg' && this.image_type != 'image/gif') {
              this.preview_src = './assets/images/default/attechment.png';
            } else {
              this.preview_src = this.url;
            }
          }
          reader.readAsDataURL(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        //console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    //console.log(event);
  }

  public fileLeave(event) {
    //console.log(event);
  }


  unlinkImage(event) {
    event.stopPropagation();
    this.preview_src = '';
    this.image_name = 'unset';
    this.url = '';
  }

  //Docs
  attachmentsUpdated(files) {
    this.invalidAttach = false;
    let i;
    let multipleFiles_temp = [];
    let validFormats = [];
    for (i = 0; i < files.length; i++) {
      let file = files[i];
      let temp_file = {};
      if (file) {
        var reader = new FileReader();
        temp_file['attachment'] = file.name;
        temp_file['attachment_display_name'] = file.name;
        temp_file['attachment_type'] = file.type;
        if (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'application/pdf' && file.type != 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' && file.type != 'application/msword') {
          this.setTimer();
          //return false;
        } else {
          reader.onload = (event: any) => {
            temp_file['attachmentUrl'] = event.target.result;
            if (temp_file['attachment_type'] != 'image/png' && temp_file['attachment_type'] != 'image/jpg' && temp_file['attachment_type'] != 'image/jpeg' && temp_file['attachment_type'] != 'image/gif') {
              temp_file['attachment_preview_src'] = this.attachmentFile
            } else {
              temp_file['attachment_preview_src'] = temp_file['attachmentUrl'];
            }
            temp_file['ext'] = temp_file['attachment'].split('.').pop().toLowerCase();
          }
          reader.readAsDataURL(file);

          setTimeout(() => {
            multipleFiles_temp.push(temp_file);
          }, 0);
        }


      }
    }

    setTimeout(() => {
      if (this.isFirstTime) {
        this.multipleFiles = this.multipleFiles.concat(multipleFiles_temp);
        this.isFirstTime = false;
      } else {
        this.multipleFiles = this.TempMultipleFiles.concat(multipleFiles_temp);
      }
      multipleFiles_temp = [];
    }, 0);
  }


  unlinkAttachments(index, event) {
    event.preventDefault();
    this.multipleFiles.splice(index, 1);
  }

  download(doc) {
    window.open(this.uploadUrl + this.dirName + '/attachments/' + doc);
    //this.commonJs.uploadUrl + this.dirName + '/attachments/' + doc;
  }

  setTimer() {
    this.invalidAttach = true;
    // this.timer = Observable.timer(3000); // 3000 millisecond means 3 seconds
    this.subscription = this.timer.subscribe(() => {
      this.invalidAttach = false;
    });
  }


  onChange(flag, today?) {
    if (today == 'allowVaniAccess') {
      this.users.allow_access = flag == 'A' ? 1 : 0;
      this.focused = 'focused';
    }
  }

  backToCreate(event) {
    event.stopPropagation();
    this.isManageSidebar = true;
    this.isDeleteMode = false;
    if (!this.transferListing) {
      this.transferListing = true;
    } else {
      this.isTransfer = false;
    }
  }

  deleteEmployee(id, name, ecode) {
    this.isDeleteMode = true;
    this.deleteId = id;
    this.deleteName = name;
    this.deleteEcode = ecode;
    this.DeleteErrorMessage = '';
  }

  deleteEmployeeAction(id) {
    this.dataService.deleteData(this.apiRoutePrefix + 'Destroy', { id })
      .subscribe((success) => {
        if (success.status == 'error') {
          this.DeleteErrorMessage = success.message;
        } else {
          this.DeleteButtonText = 'DELETING';
          setTimeout(() => {
            this.DeleteButtonText = 'DELETED';
            this.isDeleteMode = false;
            this.DeleteButtonText = "DELETE";
            this.CloseOtherWindow.nativeElement.click();
          }, 2);
          this.close();
        }


      }, (rejected) => {

      });
  }

  transferDeptForm() {
    this.users.new_department = ' ';
    this.users.new_role = ' ';
    this.errors.new_department = '';
    this.errors.new_role = '';
    this.transferListing = !this.transferListing;
  }


  transferCodeForm() {
    this.users.new_code = ' ';
    this.users.new_mode = '';
    this.errors.new_code = '';
    this.errors.new_mode = '';
    this.modeTransferListing = !this.modeTransferListing;
  }


  deptTransfer() {
    this.isTransfer = true;
    this.transferListing = true;
    this.dataService.getListData(this.apiRoutePrefix + '/' + this.data.userid + '/TransferHistory', '')
      .subscribe((success) => {
        this.transfer_history_data = success.data;
      }, (rejected) => {

      });
  }


  modeTransfer() {
    this.isModeTransfer = true;
    this.modeTransferListing = true;
    this.dataService.getListData(this.apiRoutePrefix + '/' + this.data.userid + '/ModeTransferHistory', '')
      .subscribe((success) => {
        this.mode_transfer_history_data = success.data;
      }, (rejected) => {

      });
  }

  /** Employee code check  */
  onInputChange() {
    const payload = {
      emp_code_final: this.users.emp_code_final,
      id: localStorage.getItem('userId')
    }
    this.dataService.getListData('users/checkEmpCodeExits', payload).subscribe((data: any) => {
      if (data.status == 'success') {
        this.errors.emp_code_final = '';
      }
    }, (rejected) => {
      this.errors = rejected.error.error;
    })
  }

  onDateRangeSelected(selectedDates: any) {
    // Update the input value manually
    this.users.dob = `${selectedDates.start.format('DD MMM YYYY')}`;
  }
}
