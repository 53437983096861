import { ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfigs } from 'src/app/common/app-configs';
import { DataService } from 'src/app/services/data.service';
import { DatatableService } from 'src/app/services/datatable/datatable.service';
import { ToastrService } from 'src/app/services/toastr.service';
import swal from 'sweetalert2';
declare var $: any;
import * as _ from "lodash";
import { DatatableComponent, SelectionType, TableColumn } from '@swimlane/ngx-datatable';
import { columns } from '../utility/column-generator';
import { CommonJs } from 'src/app/common/common-js';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ExcelmapimportComponent } from 'src/app/common/excelmapimport/excelmapimport.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { GridService } from 'src/app/services/grid.service';
import Pusher from 'pusher-js/worker';
import { AddMissionTaskModalComponent } from '../add-mission-task-modal/add-mission-task-modal.component';
import { ThumbnailModalServiceService } from 'src/app/services/thumbnail-modal-service.service';
import { ConfirmationPopupComponent } from 'src/app/common/confirmation-popup/confirmation-popup.component';
import { environment } from 'src/environments/environment';
import { PusherService } from 'src/app/services/pusher.service';


@Component({
  selector: 'app-shot-datatable',
  templateUrl: './shot-datatable.component.html',
  styleUrls: ['./shot-datatable.component.css']
})
export class ShotDatatableComponent implements OnInit {
  bsModalRef: BsModalRef;
  settings: any = JSON.parse(localStorage.getItem('settings'));
  shotDatatableObjects: any = {};
  isLoadingFirstTime: boolean = true;
  isClearFilter: boolean = false;
  // isUserView: boolean = false;
  scrollTimeout: any;
  suppressPagining: boolean = false;
  public config: any = {
    paging: true,
    sorting: { columns: '' },
    columnSort: { sortingType: '', columnName: '' },
    filtering: { filterString: '' },
    where: { 'project_id': this.shotDatatableObjects.projectId, 'type': 'Shot' },
    export: false,
    FilterByColumns: {
      "seasonEpisode": "",
      "sequence_number": "",
      "shot_number": "",
      "complexity": "",
      "status": ""
    },
    isLoadingFirstTime: this.isLoadingFirstTime,
    isClearFilter: this.isClearFilter,
  };
  public FilterColumns: Array<any> = ['shot_number'];
  complexityArray: Array<any>;
  statusOptions = [
    { id: 1, text: "Active" },
    { id: 0, text: "OOP" },
  ];
  deptStatusList = [];
  apiRoutePrefix = 'shots';
  apiRoutePrefixColumn = 'task';
  rows: any = [];
  popoverPlayblast: any = [];
  temp = [];
  currentPage: number = 0;
  openedPage: number = 0;
  pageSize: number = 100;
  totalItems: number = 0;
  isLoading: boolean = true;
  pagePayload: any = {
    page: 0,
    itemsPerPage: 100,
  }
  public readonly pageLimitOptions = [
    { value: 50 },
    { value: 100 },
    { value: 200 },
    { value: 300 },
  ];
  readonly headerHeight = 46;
  readonly rowHeight = 40;
  readonly footerHeight = 50;
  public dataTableColumns: TableColumn[] = [];
  public copyDataTableColumns: TableColumn[] = [];

  selected = [];
  SelectionType: SelectionType[] = [];
  statusArrayFromDepartmentList: any = [];
  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('image', { static: false }) public image: TemplateRef<any>;
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  @ViewChild('status', { static: false }) public status: TemplateRef<any>;
  @ViewChild('bid_days', { static: false }) public bid_days: TemplateRef<any>;
  @ViewChild('cameras', { static: false }) public cameras: TemplateRef<any>;
  @ViewChild('layers', { static: false }) public layers: TemplateRef<any>;
  @ViewChild('qtPath', { static: false }) public qtPath: TemplateRef<any>;
  @ViewChild('inputPath', { static: false }) public inputPath: TemplateRef<any>;
  @ViewChild('duration', { static: false }) public duration: TemplateRef<any>;
  @ViewChild('shotDetail', { static: false }) public shotDetail: TemplateRef<any>;
  @ViewChild('departmentStatusHeader', { static: false }) public departmentStatusHeader: TemplateRef<any>;
  @ViewChild('departmentStatus', { static: false }) public departmentStatus: TemplateRef<any>;
  @ViewChild('taskInfoHeader', { static: false }) public taskInfoHeader: TemplateRef<any>;
  @ViewChild("hdrTpl", { static: true }) hdrTpl: TemplateRef<any>;
  @ViewChild('container', { static: true }) containerRef: ElementRef;
  @ViewChild('projectedStartDate', { static: true }) projectedStartDate: ElementRef;
  @ViewChild('projectedEndDate', { static: true }) projectedEndDate: ElementRef;
  @ViewChild('deptTaskDateInfo', { static: true }) deptTaskDateInfo: ElementRef;


  isShotsPermissions = [];
  _ng_sidebar_data = {};
  _opened: boolean = false;
  _ng_import_type: string;
  _loadImportSideWindow: boolean = false;
  _loadDependencySideWindow: boolean = false;
  _loadManageColSideWindow: boolean = false;;
  _import_opened: boolean = false;
  _dependencyOpened: boolean = false;
  _loadSideWindow: boolean = false;
  _manage_col_opened: boolean = false;
  _ng_sidebar_manage_col_data = {};
  shotInformation: any;
  _loadDetailSideWindow: boolean = false;
  _ng_sidebar_detail_data = {};
  _detail_opened: boolean = false;
  isFilterApplied: boolean = false;
  _tasksAssignAndCommentsOpened: boolean = false;
  _ngTasksAssignCommentsData = {};
  _loadTaskAssignAndComments: boolean = false;
  projectroles = {};
  currentRole = '';
  deleteAllId = [];
  checboxColum = {
    prop: 'selected',
    name: '',
    sortable: false,
    frozenLeft: true,
    draggable: true,
    resizable: false,
    headerCheckboxable: true,
    checkboxable: true,
    width: 45,
  }
  clientCodePrefix = this.commonJs.getClientCodePrefix();
  globalSelect: Array<any> = [];

  fromNotify: string = '';
  isPlayblastOpen: any[] = [];
  display: boolean = false;
  userViewData: any = {};
  userViewList: any = [];
  isLoadingArtistTaskListTable: boolean = true;

  isFullScreen:boolean = false;
  previousOffsetY:number = 0;

  shotDataViewTab = { id:0, title:'statusView', name:'Status View' };
  shotDataViewTabSelected = { id:0, title:'statusView', name:'Status View' };
  viewList:any[] = [ { id:0, title:'statusView', name:'Status View' }, { id:1, title:'userView', name:'User View' } ];
  dependencyDetails = [];
  toggleDependencies:boolean = false;
  selectedDependencyFilter = '';
  openDependencyDetails = false;

  shotColDeptFilters = [];
  deptColumnHeaderFilters = {};

  constructor(
    public appConfigs: AppConfigs,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private cd: ChangeDetectorRef,
    private dataService: DataService,
    private router: Router,
    private datatableService: DatatableService,
    public commonJs: CommonJs,
    private modalService: BsModalService,
    private spinner: NgxSpinnerService,
    private gridService: GridService,
    private sanitizer: DomSanitizer,
    private thumbnailModalService: ThumbnailModalServiceService,
    private pusherService: PusherService
  ) {
    this.datatableService.setParam(this.config, 'config');
    this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
    this.datatableService.setParam(this.apiRoutePrefix + 'List', 'serverUrl');
    this.shotDatatableObjects.isProjectEpisodic = localStorage.getItem('episodicSeries') == '0' ? false : true;
    this.shotDatatableObjects.modifiedArray = [];
    this.shotDatatableObjects.optionList = [];
  }

  ngOnInit() {
    // Set HTML Prefic
    this.titleService.setTitle(this.settings.html_prefix + ' Shots');
    // this.noRecordFoundMsg = (this.settings.no_records != '') ? this.settings.no_records : this.noRecordFoundMsg;
    this.complexityArray = this.appConfigs.sht_ast_complexity.map(v => { return v });
    this.activatedRoute.params.subscribe((params: Params) => {
      this.shotDatatableObjects.projectId = params['id'];
      // this.getShotsStates();
      this.config.where.project_id = this.shotDatatableObjects.projectId;
      if (params['sequences'] != undefined) {
        this.shotDatatableObjects.parentId = params['sequences'];
        this.config.where['sequence_id'] = params['sequences'];
        this.dataService.getDataById('sequences/' + this.shotDatatableObjects.parentId + '/edit')
          .subscribe((success2) => {
            this.shotDatatableObjects.sequence_name = success2.data.name;
          });
      }
      if (params['episode'] != undefined) {
        this.shotDatatableObjects.episodeId = params['episode'];
        this.dataService.getDataById('episodes/' + this.shotDatatableObjects.episodeId + '/edit')
          .subscribe((success2) => {
            this.shotDatatableObjects.episode_name = success2.data.name;
          });
      }

      if (params['seasons'] != undefined) {
        this.shotDatatableObjects.seasonsId = params['seasons'];
        this.dataService.getDataById('seasons/' + this.shotDatatableObjects.seasonsId + '/edit')
          .subscribe((success2) => {
            this.shotDatatableObjects.season_name = success2.data.name;
          });
      }


      this.dataService.getDataById('projects/' + this.shotDatatableObjects.projectId + '/edit')
        .subscribe((success) => {
          this.shotDatatableObjects.projectData = success.data.episodic_series;
          if (!success.data.episodic_series) {
          }
          this.projectDepartmentList().then((res => {
          }));
        }, (error) => {
          if (error.error) {
            var err = error.error;
            //console.log(err);
            this.toastrService.notify('warn', '', err.message);
            this.router.navigate(['/dashboard']);
          }
        });
    });

    this.projectroles = (function () {
      var result;
      try {
        let result = JSON.parse(localStorage.getItem('projectRoles'))
        return result;
      } catch (exception) {
      }
    }());

    if (this.projectroles) {
      this.currentRole = this.projectroles['project_' + this.shotDatatableObjects.projectId];
    }

    this.isShotsPermissions['shots_add'] = this.commonJs.checkAccess('shots', 'add', { 'baiPass': ['admin'] }, this.shotDatatableObjects.projectId);
    this.isShotsPermissions['shots_view'] = this.commonJs.checkAccess('shots', 'view', { 'baiPass': ['admin'] }, this.shotDatatableObjects.projectId);
    this.isShotsPermissions['shots_delete'] = this.commonJs.checkAccess('shots', 'delete', { 'baiPass': ['admin'] }, this.shotDatatableObjects.projectId);
    this.isShotsPermissions['shots_edit'] = this.commonJs.checkAccess('shots', 'edit', { 'baiPass': ['admin'] }, this.shotDatatableObjects.projectId);


    /** Pusher js live update data without reload page */
    if (environment.production) {
      const channel = this.pusherService.subscribeToChannel(`shots`);
      // const channel = pusher.subscribe(`${'shots.'}${this.shotDatatableObjects.projectId}`);
      channel.bind(`shots`, (data) => {
        if(data.projectId == this.shotDatatableObjects.projectId){
          this.isLoading = true;
          this.onChangeTable(this.config);
        }
      });
    }

    this.dataService.notifyObservable$.subscribe(res => {
      if (res.refresh) {
        this._import_opened = true;
        this._ng_import_type = 'shot';
        this.fromNotify = 'modalImport';
      }
    });
    /** *************************************************** END ***************************************************/
  }

  ngOnDestroy() {
    this.pusherService.unsubscribeFromChannel(`shots`);
  }

  /** Git Shot Stats */
  shotStates: any = [];
  getShotsStates() {

    this.dataService.getListData('dashboardgetProjectPhaseDepts',
    { 'project_id': this.shotDatatableObjects.projectId, 'phase_id': 6, 'tab': 'shots',
    'filteredData': { 'seasonEpisode':this.shotDatatableObjects.seasonEpisode ,'sequence_number': this.shotDatatableObjects.sequence_number, 'shot_number': this.config.FilterByColumns['shot_number'] } })
      .subscribe((success) => {
        // console.log(success)
        this.shotStates = success.data.approvedDepts;

      }, (rejected) => {

      });
  }

  // open glbal image thumbnail viewer
  openImageModal(imageUrl: string): void {
    this.thumbnailModalService.openThumbnailModal(imageUrl);
  }

  projectDepartmentList() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getListData('projectDepartmentList', { 'project_id': this.shotDatatableObjects.projectId, 'type': 'Shot', 'fromAssetShot': true })
        .subscribe((success) => {
          this.statusArrayFromDepartmentList = success.customStatusArr;
          const modifiedArray = success.data.map(obj => ({
            prop: obj.task_status_id + "_task_status",
            task_dept_id: obj.task_status_id,
            name: obj.department_name,
            sortable: true,
            draggable: false,
            width: 190,
            minWidth: 190,
            canAutoResize: true,
            dynamicColumn: true,
            cellTemplate: this.departmentStatus,
            headerTemplate: this.departmentStatusHeader,
            headerClass:'deptsHeaderCell',
            show: true,
            idDepartment: true,
            cellClass: 'user-view',
            // Spread the properties from the original object
          }));

          modifiedArray.forEach((ele) => {
            this.deptColumnHeaderFilters[ele.prop+'_filter'] = '';
            return ele;
          });

          this.shotDatatableObjects.modifiedArray = modifiedArray;
          this.createColumns(modifiedArray);
          resolve();
        }, (rejected) => {

        });
    });
  }

  public onChangeTable(config: any, page: any = { page: this.pagePayload.page, itemsPerPage: this.pagePayload.itemsPerPage }, column: any = '', isAppend: any = false, isSort: any = false): any {
    this.spinner.hide();
    this.containerRef.nativeElement.scroll({ top: 0, behavior: 'smooth' });
    this.isLoading = true;
    if (column) {
      this.config.isClearFilter = false;
      this.config.isLoadingFirstTime = false;
    }

    config.where['isUserView'] = this.shotDataViewTab['title']=='userView' ? true : false;
    // console.log(this.config);

    this.datatableService.onChangeTable(this.config, page, column)
      .subscribe((success) => {
        if (this.config.export || this.config.where.isBulkShotsUpdate) {
          window.location.href = success.data;

          setTimeout(() => {
            this.gridService.myEventEmitterForProgressModal.emit(true);
            if (this.config.where.isBulkShotsUpdate) {
              // this.toastrService.notify('success', '', 'Data updated successfully');
              this.config.where.isBulkShotsUpdate = false;
            }
            // this.config = { 'project_id': this.shotDatatableObjects.projectId, 'type': 'Shot' };
          }, 200);
        } else {

          this.totalItems = success.totalElements
          this.isFilterApplied = success.isFilterApplied;
          let filterArr = success.filterArr;
          if (success.isFilterApplied && success.filterArr.length > 0) {
            this.config.where = filterArr['where'] ? filterArr['where'] : [];
            this.config.FilterByColumns = filterArr['FilterByColumns'] ? filterArr['FilterByColumns'] : [];
            this.config.sortNew = filterArr['sortNew'] ? filterArr['sortNew'] : [];

            let that = this;
            _.forEach(filterArr['FilterByColumns'], function (value, key) {
              _.forEach(that.dataTableColumns, function (colVal, colKey) {
                if (that.dataTableColumns[colKey]['name'] == key) {
                  that.dataTableColumns[colKey]['filtering']['filterString'] = value;
                }
              });

            });
            _.forEach(filterArr['where'], function (value, key) {
              _.forEach(that.dataTableColumns, function (colVal, colKey) {
                if (value.length > 0) {
                  if (that.dataTableColumns[colKey]['prop'] == key) {
                    if (that.dataTableColumns[colKey]['prop'] == 'seasonEpisode') {
                      that.dataTableColumns[colKey]['model'] = { id: value[0]['id'], name: value[0]['name'] };
                    } else if (that.dataTableColumns[colKey]['prop'] == 'sequence_number') {
                      that.dataTableColumns[colKey]['model'] = { id: value[0]['id'], number: value[0]['number'] };
                    }
                  }
                }
              });
            });
          }

          this.getShotsStates()
          this.temp = [...success.data];

          this.temp.map((ele)=>{
            for (const key in ele) {
              if (ele.hasOwnProperty(key)) {
                if (key.includes('_task_status') && ele[key]) {
                  if(ele[key]['status']=='OOP'){
                    ele['shotStatus'] = 'OOP';
                  }
                }
              }
            }
            return ele;
          });

          // console.log(this.temp);
          this.rows = success.data;
          // this.isLoading = false;
          this.shotDatatableObjects.totalCount = success.totalElements;
        }
        this.isLoading = false;
        this.config.export = false;

        //this.clearFilter();
        this.gridService.progressFunction();
      }, (error) => {
        this.isLoading = false;
        this.config.export = false;
        console.log(this.config);
      });



  }


  private createColumns(modifiedArray): void {
    this.isLoading = true;
    let tempColumn: any;
    let cellTemplate: any = {};
    setTimeout(() => {
      cellTemplate = {
        ids: this.idsT,
        image: this.image,
        status: this.status,
        bid_days: this.bid_days,
        cameras: this.cameras,
        duration: this.duration,
        qtPath: this.qtPath,
        inputPath: this.inputPath,
        shotDetail: this.shotDetail,
        layers: this.layers,
        hdrTpl: this.hdrTpl,
        projectedStartDate: this.projectedStartDate,
        projectedEndDate: this.projectedEndDate,
      };
      this.dataTableColumns = [];
      tempColumn = columns('shot', cellTemplate, modifiedArray);

      // this.dataTableColumns = columns('shot', cellTemplate, modifiedArray);
      if (!this.shotDatatableObjects.isProjectEpisodic) {
        tempColumn = tempColumn.filter(obj => obj.prop !== 'seasonEpisode');
      }
    });

    // this.onChangeTable(this.config);
    this.columnSet().then((response: any) => {
      if (response.status == 'success' && response.data.length != 0) {
        const rearrangedColumns = [];
        for (const item of response.data) {
          const matchingColumn = tempColumn.find(obj => obj.prop === item.prop && item.show);
          if (matchingColumn) {
            rearrangedColumns.push(matchingColumn);
          }
        }
        // for (const obj of tempColumn) {
        //   if (!response.data.some(item => item.prop === obj.prop)) {
        //     rearrangedColumns.push(obj);
        //   }
        // }
        tempColumn = rearrangedColumns;
        this.dataTableColumns = tempColumn.filter((obj: any) => {
          if (!obj.dynamicColumn) {
            return response.data.some(item => item.prop === obj.prop && item.show)
          } else {
            return obj
          }
        });
        if (!this.shotDatatableObjects.isProjectEpisodic) {
          this.dataTableColumns = this.dataTableColumns.filter(obj => obj.prop != 'seasonEpisode');
        }

        // To hide info of all the opened columns
        this.dataTableColumns.forEach((ele:any,index)=>{
          if(ele.prop.includes('_task_status') || ele.prop.includes('t_task_status')){
            ele.isExpanded = false;
            ele.maxWidth = 190;
            ele.minWidth = 190;
            ele.headerClass = 'deptsHeaderCell';
          }
        });

      } else {
        const columnsToShow = ['image', 'shot_number', 'description', 'duration_days'];
        this.dataTableColumns = tempColumn.map((column: any) => {
          if (columnsToShow.includes(column.prop) || column.idDepartment) {
            return column;
          } else {
            return null;
          }
        }).filter(column => column !== null);
      }
      // console.log(this.dataTableColumns);

      let shotTaskDateInfo = {
        prop: 'ta_bidDays',
        name: 'Dept Task Info',
        deptName: 'Dept name',
        sortable: false,
        draggable: false,
        width: 210,
        minWidth: 210,
        canAutoResize: true,
        headerTemplate: this.taskInfoHeader,
        cellTemplate: this.deptTaskDateInfo,
        show: false,
        cellClass: 'shotTaskDateInfo',
      }

      this.dataTableColumns.forEach((ele:any,index)=>{
        if(ele.prop.includes('_task_status') || ele.prop.includes('t_task_status')){
          ele.isExpanded = false;
        }
      });

      this.dataTableColumns.unshift(this.checboxColum);
      this.copyDataTableColumns = this.dataTableColumns;
      this.dataTableColumns = this.copyDataTableColumns.filter((ele:any)=>{ return !ele.hasOwnProperty('deptName') || ele.show===true });
      this.isLoading = false;
    }, (err: any) => {
      console.warn("Error while fetching column set");
      this.copyDataTableColumns = this.dataTableColumns;
      this.isLoading = false;

    });
  }

  // To disable checkbox of row which has already PO generated
  displayCheck(row) {
    if (row) {
      return true;
    }
  }

  /** Action Button */
  actionClick(type: string, row: any) {
    let id: number = row.id
    if (type == 'delete') {
      const config = {
        initialState: {
          title: 'Delete  shot',
          message: "Are you sure you want to delete this shot(s)?",
        },
        class: 'modal-md custom-modal-css',
        centered: true,
        animated: false
      };
      this.bsModalRef = this.modalService.show(ConfirmationPopupComponent, config);
      // Subscribe to the onConfirmAction event
      this.bsModalRef.content.onConfirmAction.subscribe(() => {
        // Handle the confirmation lprojectDepartmentListogic here
        this.dataService.deleteData(this.apiRoutePrefix + 'Destroy', { id })
          .subscribe((success) => {
            if (success.status == 'error') {
              this.toastrService.notify('error', '', success.message);
            } else {
              this.toastrService.notify('success', '', success.message);
              this.onChangeTable(this.config);
            }
          }, (rejected) => {

          });
      });
    } else {
      this.openSideWindow('edit', id);
    }

  }

  /**
   * Description: Page change event
   * @param e pager object
   */
  firstTimeLoad: boolean = false;
  onPageChange(e): void {
    this.openedPage = e.offset;
    this.selected = this.globalSelect[this.openedPage] != undefined ? this.globalSelect[this.openedPage] : [];
    if (!this.suppressPagining) {
      // this.currentPage = e.offset;
      this.pagePayload.page = e.offset * this.pageSize;
      if (this.firstTimeLoad) {
        this.table.offset = this.openedPage;
        this.dataService.pagePreferancePost({
          pageSize: this.pageSize,
          currentPage: this.config.start,
          pageIndex: this.table.offset,
          user_id: JSON.parse(localStorage.getItem('user')).id,
          project_id: this.shotDatatableObjects.projectId
        }, 'shot_list_page');
        this.onChangeTable(this.config);
      } else {
        this.dataService.pagePreferanceGet('shot_list_page').then((res: any) => {
          if (res.data.length == undefined) {
            this.pageSize = res.data.pageSize;
            this.config.itemsPerPage = res.data.pageSize;
            this.config.start = res.data.currentPage;
            this.config.columnSort = res.data.sortData;
            this.table.offset = res.data.pageIndex;
            this.onChangeTable(this.config);
          } else {
            this.onChangeTable(this.config);
          }
        }, (err: any) => {
          console.warn("Error while fetching page set")
        });
      }
      this.firstTimeLoad = true;
    }

  }

  handleScroll() {
    this.suppressPagining = true;
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }

    this.scrollTimeout = setTimeout(() => {
      this.suppressPagining = false;
    }, 100)
  }


  // TODO[Dmitry Teplov] wrap dynamic limit in a separate component.
  public onLimitChange(limit: any): void {
    this.selected = [];
    this.globalSelect = [];

    this.changePageLimit(limit);
    this.table.limit = this.pageSize;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor((this.table.rowCount - 1) / this.table.limit);
      }
    });
  }

  private changePageLimit(limit: any): void {
    this.pageSize = parseInt(limit, 10);
    this.pagePayload.itemsPerPage = limit;
    this.dataService.pagePreferancePost({
      pageSize: this.pageSize,
      currentPage: this.config.start,
      pageIndex: this.table.offset,
      user_id: JSON.parse(localStorage.getItem('user')).id,
      project_id: this.shotDatatableObjects.projectId
    }, 'shot_list_page');
    this.onChangeTable(this.config);
  }

  /** Search Auto complete */
  search(event: any, type: string) {
    if (type == 'complexity') {
      this.complexityArray = [...this.complexityArray]
    } else if (type == 'seasonEpisode') {
      this.shotDatatableObjects.sequenceOptions = [];
      this.dataService.getListData('episodesItems', { project_id: this.shotDatatableObjects.projectId, 'fromCreateTask': true, 'query': event['query'] })
        .subscribe((success) => {
          this.shotDatatableObjects.optionList = [...success.data];
        }, (rejected) => {
        });
    }
    else if (type == 'sequence_number') {
      let episodeId = 0;
      if (this.config.where['seasonEpisode']) {
        if (this.config.where.seasonEpisode.length > 0) {
          episodeId = this.config.where.seasonEpisode[0].id;
        }
      }
      this.dataService.getListData('sequencesItems', { project_id: this.shotDatatableObjects.projectId, 'query': event['query'], 'fromTasks': true, episodeId: episodeId })
        .subscribe((success) => {
          this.shotDatatableObjects.optionList = [...success.data];
        }, (rejected) => {

        });
    }
    else if(type == 'view'){
      this.viewList = [...this.viewList];
    }
    else if(type == 'deptStatus'){
      this.deptStatusList = [...this.dataService.allStatusList];
    }
    else {
      this.statusOptions = [...this.statusOptions]
    }
  }

  onColumnFilter(e: any, id: string): void {
    setTimeout(() => {
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.start = 0;
      this.config.isLoadingFirstTime = false;
      this.onChangeTable(this.config);
    }, 200);

  }

  /**********    Side Window    **********/

  openSideWindow(mode, id) {
    if (id != undefined && id != '') {
      this._ng_sidebar_data['shotid'] = id;
    }
    this._ng_sidebar_data['sequence'] = true;
    this._ng_sidebar_data['projectId'] = this.shotDatatableObjects.projectId;
    this._ng_sidebar_data['sequence_id'] = this.shotDatatableObjects.parentId;
    this._ng_sidebar_data['mode'] = mode;
    this._ng_sidebar_data['isActive'] = true;
    this._opened = true;
  }

  // Open create shot side window

  onOpened(type) {
    if (type == 'import') {
      this._loadImportSideWindow = true;
    } else if (type == 'manage_col') {
      this._loadManageColSideWindow = true;
    } else if (type == 'dependency') {
      this._loadDependencySideWindow = true;
    } else {
      this._loadSideWindow = true;
    }
  }

  // Close side window
  onClosed(type) {
    if (type == 'import') {
      this._loadImportSideWindow = false;
    }
    else if (type == 'manage_col') {
      this._loadManageColSideWindow = false;
    }
    else if (type == 'dependency') {
      this._loadDependencySideWindow = false;
    }
    else {
      this._loadSideWindow = false;
    }
  }

  // Opend create shot side window when close
  closeSideWindow() {
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.onChangeTable(this.config);
  }

  // Export data
  exportData() {
    this.config.export = true;
    this.gridService.openProgressModal();
    this.onChangeTable(this.config);
  }

  // Export data for bulk update
  exportBulkUpdateData() {
    this.config.export = false;
    this.config.where.isBulkShotsUpdate = true;
    this.gridService.openProgressModal();
    this.onChangeTable(this.config);
  }

  // Import data
  public openModalWithComponent() {
    this.dataService.setProjectId(this.shotDatatableObjects.projectId);

    let data: any = {
      from: 'ShotComponent',
      header: 'Shot'
    }

    this.dataService.setImportFrom(data);
    let list = [this.shotDatatableObjects.projectId];
    this.bsModalRef = this.modalService.show(ExcelmapimportComponent, {
      backdrop: 'static',
      keyboard: false
    });
    this.bsModalRef.content.title = 'Modal with component';
    this.bsModalRef.content.list = list;
    this.bsModalRef.content.event.subscribe((res: any) => {
      this._import_opened = false;
      this._loadImportSideWindow = false;
      this.onChangeTable(this.config);
    });
  }

  // Import data for bulk update
  importBulkUpdateData() {
    // this.dataService.setProjectId(this.shotDatatableObjects.projectId);

    // let data: any = {
    //   from: 'ShotComponent',
    //   header: 'Shot Update'
    // }

    // this.dataService.setImportFrom(data);
    // let list = [this.shotDatatableObjects.projectId];
    // this.bsModalRef = this.modalService.show(ExcelmapimportComponent, {
    //   backdrop: 'static',
    //   keyboard: false
    // });
    // this.bsModalRef.content.title = 'Modal with component';
    // this.bsModalRef.content.list = list;
    // this.bsModalRef.content.event.subscribe((res: any) => {
    //   this._import_opened = false;
    //   this._loadImportSideWindow = false;
    //   this.onChangeTable(this.config);
    // });
    this._import_opened = true;
    this._ng_import_type = 'Bulk Shot Update';
  }

  /** open shot column */
  openManageColWindow() {
    let shotDeptCols = this.shotDatatableObjects.modifiedArray;
    shotDeptCols = shotDeptCols.map((ele) => {
      let tempObj = {
        name: ele.name,
        prop: ele.prop,
        draggable: true,
        idDepartment: true,
        // width: 166,
        // minWidth: 166,
      };
      return tempObj
    });
    this._ng_sidebar_manage_col_data['column'] = [...columns('shot'), ...shotDeptCols]; // To concat Shot columns & Shot-Department columns
    this._ng_sidebar_manage_col_data['key'] = 'shot_list_column';
    this._ng_sidebar_manage_col_data['projectId'] = this.shotDatatableObjects.projectId;
    this._manage_col_opened = true;
  }

  /**close modeal event */
  closeManageCol(value) {
    if (value.reload) {
      this.columnSet().then((response: any) => {
        if (response.status == 'success') {
          this.createColumns(this.shotDatatableObjects.modifiedArray);
        }
      }, (err: any) => {
        console.warn("Error while fetching column set")
      });
    }
  }

  /** Get column preferance  */
  columnSet() {
    return new Promise((resolve, rejected) => {
      this.dataService.getListData(this.apiRoutePrefixColumn + '/' + 'shot_list_column' + '/preferences', { projectId: this.shotDatatableObjects.projectId })
        .subscribe((success) => {
          if (success.data != false) {
            this.datatableService.setParam(success.data, 'columns');
            resolve(success);
          } else {
            resolve(success);
          }
        }, (rjt) => {
          rejected(rjt);
        });
    });
  }

  /** Show cameras in modal */
  showCamersFrames(camerasFrames: TemplateRef<any>, column) {
    this.bsModalRef = this.modalService.show(camerasFrames);
    this.shotInformation = column;
    if (this.shotInformation.shot_frame_in_out.length > 0) {
      this.shotInformation.shot_frame_in_out.forEach((cam: any, index: any) => {
        if (cam.frame_in != null && cam.frame_out != null) {
          const sumArr = cam.frame_in.reduce((acc, val, index) => {
            return acc + cam.frame_out[index];
          }, 0);
          cam.no_of_frames = sumArr;
        }
        cam.arrayOfObjects = cam.frame_in.map((currentValue, index) => {
          return {
            frameIn: currentValue,
            frameOut: cam.frame_out[index],
          }
        });
      });
    }
  }

  /* Detail */
  openDetailSideWindow(type, data) {
    this._ng_sidebar_detail_data['projectId'] = this.shotDatatableObjects.projectId;
    this._ng_sidebar_detail_data['type'] = type;
    this._ng_sidebar_detail_data['shot'] = { 'id': data.id };
    this._detail_opened = true;
  }

  /** Open shot detail page */
  onDetailOpened() {
    this._loadDetailSideWindow = true;
  }

  /** Close shot detail page */
  onDetailClosed() {
    this._loadDetailSideWindow = false;
  }

  /** Delete All shots */
  deleteAllShots() {
    const config = {
      initialState: {
        title: 'Delete all ' + 'Shots',
        message: "Are you sure you want to delete this  Shot(s)? Note: All data associated with shot(s) will be deleted, this action can not be reverted.",
      },
      class: 'modal-md custom-modal-css',
      centered: true,
      animated: false
    };
    this.bsModalRef = this.modalService.show(ConfirmationPopupComponent, config);
    // Subscribe to the onConfirmAction event
    this.bsModalRef.content.onConfirmAction.subscribe(() => {
      // Handle the confirmation logic here
      this.dataService.deleteData('destroyAllShots', this.shotDatatableObjects.projectId)
        .subscribe((success) => {
          if (success.status == 'error') {
            this.toastrService.notify('error', '', success.message);
          } else {
            this.toastrService.notify('success', '', success.message);
            this.onChangeTable(this.config);
          }
        }, (rejected) => {

        });
    });

    // let that = this;
    // swal.fire({
    //   title: 'Delete all ' + 'Shots (s)',
    //   html: "Are you sure you want to delete this " + "Shots (s)? <br> <span style='color: #f8bb86;'>Note: All data associated with shot(s) will be deleted, this action can not be reverted.</span>",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#DD6B55",
    //   confirmButtonText: "Delete",
    //   cancelButtonText: "Cancel",
    // }).then((result) => {
    //   if (result.value) {
    //     that.dataService.deleteData('destroyAllShots', that.shotDatatableObjects.projectId)
    //       .subscribe((success) => {
    //         this.selected = [];
    //         that.onChangeTable(that.config);
    //       }, (rejected) => {
    //       });
    //   } else {
    //   }
    // });
  }

  /** On Select Option */
  onselectOption(event: any, type: string, deptColumnProp?, dependencyData?) {

    if(type=='deptStatus' || type=='dependencyType'){
      this.selectedDependencyFilter =  (dependencyData == this.selectedDependencyFilter) ? '' :dependencyData;

      // console.log(event, deptColumnProp);

      if(deptColumnProp){
        let deptFilterObj = this.shotColDeptFilters.find(ele=>ele.col==deptColumnProp);
        // console.log(deptFilterObj);

        if(deptFilterObj){

          if(type=='deptStatus'){
              deptFilterObj['statusValue'] = event.name;
          }else if(type=='dependencyType'){
                if(deptFilterObj['dependency']==dependencyData){
                  deptFilterObj['dependency'] = undefined;
                }else{
                  deptFilterObj['dependency'] = dependencyData;
                }
          }

        }else{
          let tempObj = { 'col':deptColumnProp };
          if(type=='deptStatus'){
            tempObj['statusValue'] = event.name;
          }else if(type=='dependencyType'){
            tempObj['dependency'] = dependencyData;
          }
          this.shotColDeptFilters.push(tempObj);
        }
      }

      this.config.where['shotColDeptFilters'] = this.shotColDeptFilters.filter(ele => (ele['dependency'] || ele['statusValue']) );
    }else{
      this.config.where[type] = [event];
    }

    setTimeout(() => {
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.config.start = 0;
      this.onChangeTable(this.config);
    }, 200);
  }

  /** On clear selection */
  onselectionClear(event: any, type: string, deptColumnProp?:any) {

    if(type=='deptStatus'){
      let deptFilterObj = this.shotColDeptFilters.find(ele=>ele.col==deptColumnProp);
      if(deptFilterObj){
        deptFilterObj['statusValue'] = undefined;
      }
      this.config.where['shotColDeptFilters'] = this.shotColDeptFilters.filter(ele => (ele['dependency'] || ele['statusValue']) );
    }else {
      this.config.where[type] = event.isTrusted == true ? [] : event;
    }

    setTimeout(() => {
      this.pagePayload.page = 0;
      this.table.offset = 0;
      this.config.isLoadingFirstTime = false;
      this.onChangeTable(this.config);
    }, 200);
  }

  refreshFilter() {
    this.onChangeTable(this.config);
  }

  clearFilter() {
    this.shotColDeptFilters = [];
    this.config.where = {};
    this.config.where.project_id = this.shotDatatableObjects.projectId;
    this.config.FilterByColumns = {};
    this.config.where['type'] = 'Shot';
    this.config.isLoadingFirstTime = false;
    this.config.isClearFilter = true;
    this.pagePayload.page = 0;
    this.table.offset = 0;
    this.shotDatatableObjects.sequence_number = [];
    this.shotDatatableObjects.status = [];
    this.shotDatatableObjects.seasonEpisode = [];

    Object.keys(this.deptColumnHeaderFilters).forEach(key => {
      this.deptColumnHeaderFilters[key] = '';
    });

    this.onChangeTable(this.config);
  }

  onTaskAssignAndCommentsOpened() {
    this._loadTaskAssignAndComments = true;
  }

  onTaskAssignAndCommentsClosed() {
    this._loadTaskAssignAndComments = false;
  }

  closeCreateTask($event) {
    if ($event.id == 3) {
    } else {
      if ($event.id == 1) {
        this._tasksAssignAndCommentsOpened = false;
        this._detail_opened = false;
        this.pagePayload.page = 0;
        this.table.offset = 0;
        this.onChangeTable(this.config);
      }
    }
  }

  // on Open Dependency Details
  openDependencyDetailsDrawer(eventRow, eventColumn, type){
    this.openDependencyDetails = type;
    if(type){
            if(eventRow[eventColumn.prop]['parentApprovalState']!='no-parent'){
              this._dependencyOpened = true;
              // console.log(event);
              this.getTaskDependencyDetails(eventRow, eventRow[eventColumn.prop]['task_id']);
            }
    }
  }

  // on comment/assign drawer opened
  onStatusChipClicked(eventRow, eventColumn){
    const row = eventRow;
    const column = eventColumn;

    if (column.task_dept_id) {
      let check = column.task_dept_id.indexOf("t") !== -1;

      let task_dept_column = check ? 'department_tasktype_id' : 'department_id';
      let shotData = {
        'type': 'shot',
        'task_dept_column': task_dept_column,
        'task_dept_id': column.task_dept_id,
        'department': column.name,
        'data': row,
        'status': row.shotStatus,
        'actualStatus': row.shotStatus,
      }

      console.log(this.openDependencyDetails);
      if (column.dynamicColumn && row[column.prop] != "" && row[column.prop] != "NA" && !this.openDependencyDetails) {
        // console.log(event);

              if (row[column.prop].users.length > 0 && this.shotDataViewTab['title']=='userView') {
                this.userViewData = this.userClickFromTooltipData ? this.userClickFromTooltipData : row[column.prop];
                console.log(this.userViewData)
                this.isLoadingArtistTaskListTable = true;
                this.userViewList = [];
                this.userTaskListProject(shotData, row.id, this.userViewData.id ? this.userViewData.id : this.userViewData.artist_id);
                this.display = true;
              } else {
                if (this.currentRole != 'client') {
                  this.openTaskAssignAndCommentsSideWindow(shotData);
                }
              }


      }
    }
  }

  onUserEvent(event) {
    if (event.type == "click") {
      // const row = event.row;
      // const column = event.column;
      // if (column.task_dept_id) {
      //   let check = column.task_dept_id.indexOf("t") !== -1;
      //   let task_dept_column = check ? 'department_tasktype_id' : 'department_id';
      //   let shotData = {
      //     'type': 'shot',
      //     'task_dept_column': task_dept_column,
      //     'task_dept_id': column.task_dept_id.replace('t', ''),
      //     'department': column.name,
      //     'data': row,
      //     'status': row.shotStatus,
      //     'actualStatus': row.shotStatus,
      //   };

      //   if (column.dynamicColumn && row[column.prop] != "" && row[column.prop] != "NA" && !this.openDependencyDetails) {
      //     if (row[column.prop].users.length > 0 && this.shotDataViewTab['title']=='userView') {
      //       this.userViewData = this.userClickFromTooltipData ? this.userClickFromTooltipData : row[column.prop];
      //       this.isLoadingArtistTaskListTable = true;
      //       this.userViewList = [];
      //       this.userTaskListProject(shotData, event.row.id, this.userViewData.id ? this.userViewData.id : this.userViewData.artist_id);
      //       this.display = true;
      //     } else {
      //       if (this.currentRole != 'client') {
      //         if(this.shotDataViewTab['title']=='statusView'){
      //           this.openTaskAssignAndCommentsSideWindow(shotData);
      //         }else if(this.shotDataViewTab['title']=='dependencyView'){
      //           if(event.value.parentApprovalState!='no-parent'){
      //             this._dependencyOpened = true;
      //             this.getTaskDependencyDetails(event.row,event.value.task_id);
      //           }
      //         }
      //       }
      //     }
      //   }
      // }

    }
  }

  /** User View Data */
  userTaskListProject(shotData, shotId, id) {
    const payload = {
      type: 'shot',
      project_id: this.shotDatatableObjects.projectId,
      task_dept_column: shotData.task_dept_column,
      task_dept_id: shotData.task_dept_id,
      shot_id: shotId,
      artist_id: id
    }
    this.dataService.getListData('getAllTaskByArtist', payload).subscribe((success) => {
      if (success) {
        if (success.data.message == 'success') {
          this.userViewList = success.data.task;
        }
      }
      this.isLoadingArtistTaskListTable = false;
      this.userClickFromTooltipData = null;
    }, (rejected) => {
      this.isLoadingArtistTaskListTable = false;
      this.userClickFromTooltipData = null;
    });
  }

  /** Get User task lists */
  userClickFromTooltipData: any;
  getUsetTaskLists(user) {
    this.userClickFromTooltipData = user;
  }

  // on sorting a column
  onSort(event) {
    let sortedData = {
      sortingType: event.newValue,
      columnName: event.column.prop
    }
    // console.log(this.rows,sortedData);

    if(sortedData.columnName.includes('_task_status')){
      this.table.externalSorting =true;
      // console.log(this.rows);
      if(sortedData.sortingType=='asc'){
              this.rows.sort((a, b) => {

                   if(!a[sortedData.columnName] || !b[sortedData.columnName]){
                     return -1;
                   }
                  //  console.log(a[sortedData.columnName]['status'],b[sortedData.columnName]['status']);
                   if ((a[sortedData.columnName]['status']=='Assign'?'Inventory':a[sortedData.columnName]['status']) < (b[sortedData.columnName]['status']=='Assign'?'Inventory':b[sortedData.columnName]['status'])) {
                       return 1;
                   }
                   if ((a[sortedData.columnName]['status']=='Assign'?'Inventory':a[sortedData.columnName]['status']) > (b[sortedData.columnName]['status']=='Assign'?'Inventory':b[sortedData.columnName]['status'])) {
                       return -1;
                   }
                   return 0;
            });
            // console.log('sorted=>',this.rows);
            }else{
              this.rows.sort((a, b) => {

                    if(!a[sortedData.columnName] || !b[sortedData.columnName]){
                      return -1;
                    }

                    if ((a[sortedData.columnName]['status']=='Assign'?'Inventory':a[sortedData.columnName]['status']) > (b[sortedData.columnName]['status']=='Assign'?'Inventory':b[sortedData.columnName]['status'])) {
                        return 1;
                    }
                    if ((a[sortedData.columnName]['status']=='Assign'?'Inventory':a[sortedData.columnName]['status']) < (b[sortedData.columnName]['status']=='Assign'?'Inventory':b[sortedData.columnName]['status'])) {
                        return -1;
                    }
                    return 0;
            });
            }

    }else{
      this.table.externalSorting = false;
    }

    this.config.columnSort = sortedData;

    this.dataService.pagePreferancePost({
      pageSize: this.pageSize,
      currentPage: this.config.start,
      pageIndex: this.table.offset,
      sortData: sortedData,
      user_id: JSON.parse(localStorage.getItem('user')).id,
      project_id: this.shotDatatableObjects.projectId
    }, 'shot_list_page');

  }

  //Tasks Assignment and  Comments
  openTaskAssignAndCommentsSideWindow(shotData) {
    console.log(shotData)
    this._ngTasksAssignCommentsData['type'] = shotData.type;
    this._ngTasksAssignCommentsData['department'] = shotData.department;
    this._ngTasksAssignCommentsData['data'] = shotData.data;
    this._ngTasksAssignCommentsData['task_dept_column'] = shotData.task_dept_column;
    this._ngTasksAssignCommentsData['task_dept_id'] = shotData.task_dept_id;
    this._ngTasksAssignCommentsData['status'] = shotData.status;
    this._ngTasksAssignCommentsData['actualStatus'] = shotData.actualStatus;
    this._ngTasksAssignCommentsData['allow_status'] = true;
    this._ngTasksAssignCommentsData['department_name'] = shotData.department;
    this._ngTasksAssignCommentsData['info'] = shotData.data.seasonEpisode + '-' + shotData.data.sequence_number + '-' + shotData.data.shot_number;
    this._tasksAssignAndCommentsOpened = true;
  }

  // Add missing tasks
  linkDepartments() {
    if (this.deleteAllId.length > 0) {
      this.dataService.setProjectId(this.shotDatatableObjects.projectId);
      let list: any = [this.shotDatatableObjects.projectId, this.deleteAllId, 'shot', 'linkShotsDepartments', this.deleteAllId];
      this.dataService.setMissingTaskData(list)
      this.bsModalRef = this.modalService.show(AddMissionTaskModalComponent, {
        backdrop: 'static',
        keyboard: false
      });
      this.bsModalRef.content.event.subscribe((res: any) => {
        this._import_opened = false;
        this._loadImportSideWindow = false;
        // this.page = 1;
        this.currentPage = 0;
        this.rows = [];
        this.deleteAllId = [];
        this.selected = [];
        this.onChangeTable(this.config);

      });
    } else {
      this.toastrService.notify('error', '', "Please select a shot");
    }

    // let that = this;
    // swal.fire({
    // 	title: 'Link ' + this.deleteAllId.length + ' department tasks to ' + this.entityNameSingular,
    // 	text: "Are you sure you want to create tasks for added departments?",
    // 	icon: "warning",
    // 	showCancelButton: true,
    // 	confirmButtonColor: "#DD6B55",
    // 	confirmButtonText: "Yes",
    // 	cancelButtonText: "Cancel",
    // }).then((result) => {
    // 	if (result.value) {
    // 		that.dataService.getOtherData('linkShotsDepartments', { 'projectId': that.projectId, 'ids': that.deleteAllId, 'type': 'shot' })
    // 			.subscribe((success) => {
    // 				$('#checkAll').prop('checked', false);
    // 				$('.check-sub').prop('checked', false);
    // 				that.page = 1;
    // 				that.config.where.project_id = that.projectId;
    // 				that.config.FilterByColumns = {};
    // 				that.deleteAllId = [];
    // 				if (success.status == 'success') {
    // 					that.onChangeTable(that.config);
    // 				}
    // 				if (success.status != '') {
    // 					that.toastrService.notify(success.status, '', success.message);
    // 				}

    // 			}, (rejected) => {

    // 			});
    // 	} else {
    // 	}
    // });
  }

  // For row identity by Id
  getId(row) {
    return row.id;
  }

  /** On select the row */
  onSelect({ selected }) {

    this.globalSelect[this.openedPage] = selected;
    if (selected && selected.length > 0) {
      this.selected = selected;
    }
    // console.log(this.selected)
    // this.deleteAllId = row.selected.map(obj => obj.id);
    this.deleteAllId = [];
    this.globalSelect.forEach((element) => {
      if(element){
          element.map((obj) => {
            // if(!this.deleteAllId.includes(obj.id)){
            this.deleteAllId.push(obj.id);
            // }
          });
      }
    });

  }

  /** Delete shot */
  deleteShots() {
    const config = {
      initialState: {
        title: 'Delete ' + this.deleteAllId.length + '  Shot(s)',
        message: "Are you sure you want to delete this  Shot(s)? Note: All data associated with shot(s) will be deleted, this action can not be reverted.",
      },
      class: 'modal-md custom-modal-css',
      centered: true,
      animated: false
    };
    this.bsModalRef = this.modalService.show(ConfirmationPopupComponent, config);
    // Subscribe to the onConfirmAction event
    this.bsModalRef.content.onConfirmAction.subscribe(() => {
      // Handle the confirmation logic here
      this.dataService.deleteData(this.apiRoutePrefix + 'Destroy', this.deleteAllId)
        .subscribe((success) => {
          this.selected = [];
          if (success.status == 'error') {
            this.toastrService.notify('error', '', success.message);
          } else {
            this.toastrService.notify('success', '', success.message);
            this.onChangeTable(this.config);
          }
        }, (rejected) => {
          // console.log(this.selected, this.globalSelect[0]);
          // this.selected = [];
        });
    });

    // let that = this;
    // swal.fire({
    //   title: 'Delete ' + this.deleteAllId.length + '  Shot(s)',
    //   html: "Are you sure you want to delete this  Shot(s)? <br> <span style='color: #f8bb86;'>Note: All data associated with shot(s) will be deleted, this action can not be reverted.</span>",
    //   icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#DD6B55",
    //   confirmButtonText: "Delete",
    //   cancelButtonText: "Cancel",
    // }).then((result) => {
    //   if (result.value) {
    //     that.dataService.deleteData(that.apiRoutePrefix + 'Destroy', that.deleteAllId)
    //       .subscribe((success) => {
    //         this.selected = [];
    //         that.toastrService.notify(success.status, '', success.message);
    //         that.deleteAllId = [];
    //         this.pagePayload.page = 0;
    //         this.table.offset = 0;
    //         this.onChangeTable(this.config);
    //       }, (rejected) => {
    //       });
    //   } else {
    //   }
    // });
  }

  /** Set bg color */
  /** Set bg color */
  setBgColor(data: any) {
    if (data != 'dependency' && data) {
      const statusObject = this.statusArrayFromDepartmentList.find(status => status.name === data);
      if (statusObject) {
        return statusObject.color;
      }
      return "rgb(169, 169, 169)"
    } else if (data == 'dependency' && data) {
      return '#ccb9a1'
    }
  }

  // To set bg color according to parent dependency
  setDependencyData(parentDependenyType, type?){
    if(type=='tooltip'){
      if(parentDependenyType){
        switch (parentDependenyType) {
          case ("no-parent"):
            return 'No Dependency' ;
          case ("approval-pending"):
            return 'Approval Pending';
          case ("partially-approved"):
            return 'Partially Approved';
          case ("fully-approved"):
            return 'Fully Approved';

          default:
            return 'No Dependency' ;
          }
        }
        // else{
        //   return 'No Dependency' ;
        // }
    }else{

      if(parentDependenyType){
        switch (parentDependenyType) {
          case ("no-parent"):
            return type=='bgcolor'?"#a9a9a9":'noDepend';
          case ("approval-pending"):
            return type=='bgcolor'?"#FF6666":'approvalPend';
          case ("partially-approved"):
            return type=='bgcolor'?"#ff9800":'partiallyAppr';
          case ("fully-approved"):
            return type=='bgcolor'?"#2b982b":'fullyAppr';

          default:
            return type=='bgcolor'?"#a9a9a9":'noDepend';
        }
      }
      // else{
      //   return type=='bgcolor'?"#a9a9a9":'noDepend';
      // }

    }
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl('vlc://' + url);
  }

  layersData: any;
  noRecordFoundMsg: string = "Sorry, no records found!";

  /** showLayers */
  showLayers(layersModal: TemplateRef<any>, column) {
    this.bsModalRef = this.modalService.show(layersModal);
    if (column.layers) {
      this.layersData = column.layers.split(",");
    } else {
      this.layersData = null;
    }
  }

  closeImport(event) {
    this._import_opened = false;
    this._loadImportSideWindow = false;

    if (this.fromNotify != 'modalImport') {
      this.onChangeTable(this.config);
    }
  }

  closeDependencySideWindow(){
    this._dependencyOpened = false;
    this._loadDependencySideWindow = false;
    this.dependencyDetails = [];
  }

  /** Open Input Path */
  openInputPath(path: string) {
    const params = {
      path: path
    }
    this.dataService.openFileDirectory(params).subscribe((res: any) => {
      console.log("Done", res);
    })
  }

  // On show playblast buttons
  showPlayblastbuttons(rowData) {
    // console.log(rowData.playblast);
    this.popoverPlayblast = rowData.playblast;
  }

  // For getting first two letters of name
  getInitials(inputString: string): string {
    const words = inputString.trim().split(' ');
    if (words.length >= 2) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      const secondInitial = words[1].charAt(0).toUpperCase();
      return `${firstInitial}${secondInitial}`;
    } else if (words.length === 1) {
      const firstInitial = words[0].charAt(0).toUpperCase();
      return `${firstInitial}`;
    } else {
      return '';
    }
  }

   // On datatable body scrolled
   onScrolled(event){

    // console.log(event);
    if(event['offsetY'] && (event['offsetY'] != this.previousOffsetY)){
      this.isFullScreen = event['offsetY'] > this.previousOffsetY ? true : false;
      this.dataService.fullScreenView.next(this.isFullScreen);

      this.previousOffsetY = event['offsetY'];
    }
  }

  // To change tabs between shot's views
  changeShotView(viewName){
    this.shotDataViewTab = viewName;
  }

  // For Dependency view side drawer
  getTaskDependencyDetails(shotData, shotTaskId) {
    this.dependencyDetails = [];
    // console.log(this.data);
    // this.dataService.getOtherData('getTaskDependencyDetails', { 'project_id': this.data.project_id, 'task_id': this.data.task_id, 'shotAssets': this.data.shotAssets, 'e_episode_id': this.data.e_episode_id, 'sq_sequence_id': this.data.sq_sequence_id })
    this.dataService.getOtherData('getTaskDependencyDetails', { 'project_id': shotData.project_id, 'task_id': shotTaskId, 'shotAssets': shotData.assets, 'e_episode_id': (shotData.hasOwnProperty('e_episode_id') ? shotData.e_episode_id:shotData.episode_id), 'sq_sequence_id': (shotData.hasOwnProperty('sq_sequence_id') ? shotData.sq_sequence_id:shotData.sequence_id) })
      .subscribe((success) => {
        this.dependencyDetails = success.data;
        // console.log(this.dependencyDetails);
      }, (rejected) => {

      });

    this.openDependencyDetails = false;
  }

  // To hide/show tasks date info(start, end, bid)
  toggleDateInfo(event){
    // console.log(event);
    let ele:any = this.copyDataTableColumns.find((item:any) => (item.prop === event.prop));
    // console.log(ele);
    if(ele){
      ele.isExpanded = !ele.isExpanded;
        ele.headerClass= ele.isExpanded ? 'deptsHeaderExpandedCell':'deptsHeaderCell';
        ele.width = ele.isExpanded ? (this.toggleDependencies? 566:502):190;
        ele.minWidth = ele.isExpanded ? (this.toggleDependencies? 566:502):190;
        ele.maxWidth = ele.isExpanded ? (this.toggleDependencies? 566:502):190;
        ele.resizable = ele.isExpanded? false:true;
        // ele.cellTemplate = this.departmentExpandedStatus;
        if(this.toggleDependencies){
          ele.headerClass = ele.isExpanded ? 'deptsHeaderExpandedCell ' : 'deptsHeaderCell deptstWidth-254';
          ele.cellClass = ele.isExpanded? 'user-view expanded-view-container':'user-view deptstWidth-254';
        }else{
          ele.cellClass = ele.isExpanded? 'user-view expanded-view-container':'user-view ';
        }
    }

    // this.copyDataTableColumns.map((ele:any,index)=>{

    //   if(ele==event){
    //     ele.isExpanded = !ele.isExpanded;
    //     ele.headerClass= ele.isExpanded ? 'deptsHeaderExpandedCell':'deptsHeaderCell';
    //     ele.width = ele.isExpanded ? 663:166;
    //     ele.minWidth = ele.isExpanded ? 633:166;
    //   }

    //   if(index==this.copyDataTableColumns.indexOf(event)+1){
    //     ele.show = !ele.show;
    //   }
    //   return ele;
    // });

    this.dataTableColumns = this.copyDataTableColumns.filter((item:any) => { return !item.hasOwnProperty('deptName') || item.show===true; });

    this.cd.detectChanges();
  }

  // To show only columns with show true
  getFilteredColumns() {

    // return this.dataTableColumns.map((column:any) => {
    //   if(column.hasOwnProperty('deptName')){
    //     if(column.show){
    //       return column;
    //     }else{
    //       return;
    //     }
    //   }
    //   return column;
    // });
    return this.dataTableColumns.filter((column:any) => ( !column.hasOwnProperty('deptName') && column.show));
  }

  // To toggle dependency
  toggleDependency(){
    this.toggleDependencies=!this.toggleDependencies;
    this.dataTableColumns = [];
    this.isLoading = true;
    setTimeout(()=>{
      this.dataTableColumns = this.copyDataTableColumns;
      this.dataTableColumns.forEach((ele:any,index)=>{
        if(ele.prop.includes('_task_status') || ele.prop.includes('t_task_status')){

            if(this.toggleDependencies){
              // ele.cellClass = ele.isExpanded ? 190:270;
              ele.headerClass = ele.isExpanded ? 'deptsHeaderExpandedCell ' : 'deptsHeaderCell deptstWidth-254';
              ele.cellClass = ele.isExpanded ? 'user-view expanded-view-container ' : 'user-view deptstWidth-254';
              ele.width = ele.isExpanded ? 566:190;
              ele.minWidth = ele.isExpanded ? 566:190;
              ele.maxWidth = ele.isExpanded ? 566:190;
            }else{
              // ele.cellClass = ele.isExpanded ? 190:190;
              ele.headerClass = ele.isExpanded ? 'deptsHeaderExpandedCell ' : 'deptsHeaderCell';
              ele.cellClass = ele.isExpanded ? 'user-view expanded-view-container ' : 'user-view ';
              ele.width = ele.isExpanded ? 502:190;
              ele.minWidth = ele.isExpanded ? 502:190;
              ele.maxWidth = ele.isExpanded ? 502:190;
            }

        }
      });
      this.isLoading = false;
    },600);

    this.cd.detectChanges();
  }

  // to check if is Dept Filter Active
  isDeptFilterActive(column, type){
    // this.selectedDependencyFilter
    // console.log()
    let checkDeptFilterActive = this.shotColDeptFilters.find(ele=>ele.col==column.prop);
    // console.log(checkDeptFilterActive);
    if(checkDeptFilterActive){
      if(checkDeptFilterActive.hasOwnProperty('dependency')){
        if(checkDeptFilterActive['dependency']==type){
          return true;
        }
      }
    }else{
      return false;
    }
  }

  // to check tooltip row position
  checkTooltipRowPosition(rowIndex){
    if(this.rows.length>=12){
      if(this.rows.length-5<rowIndex){
        return 'vi1-tooltip_02_up';
      }
    }
  }

}
