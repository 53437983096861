import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../../../services/auth/auth-guard.service';

import { TasksReviewComponent } from '../tasks-review/tasks-review.component';
import { TaskTrackComponent } from '../task-track/task-track.component';
import { TaskListComponent } from '../task-list/task-list.component';
import { TasksReviewDatatableComponent } from '../tasks-review-datatable/tasks-review-datatable.component';
import { TaskTrackDatatableComponent } from '../task-track-datatable/task-track-datatable.component';

const routes: Routes = [
	// {
	// 	path: 'tasks/:id',
	// 	component: TasksComponent,
	// 	canActivate: [AuthGuardService],
	// 	data: { baiPass: ['admin'], permissions: { tasks: ['view'] }, checkProject: true }
	// },
	{
		path: 'tasks/:id',
		component: TaskListComponent,
		canActivate: [AuthGuardService],
		data: { baiPass: ['admin'], permissions: { tasks: ['view'] }, checkProject: true }
	},
	{
		path: 'tasks-review/:id',
		component: TasksReviewComponent,
		canActivate: [AuthGuardService],
		//data: { permissions: { task_review: ['view'] }, checkProject: true }
		data: { baipass: '', permissions: {}, checkProject: true }
	},
	{
		path: 'tasks-review-grid/:id',
		component: TasksReviewDatatableComponent,
		canActivate: [AuthGuardService],
		//data: { permissions: { task_review: ['view'] }, checkProject: true }
		data: { baipass: '', permissions: {}, checkProject: true }
	},
	{
		path: 'tasks-track/:id',
		component: TaskTrackComponent,
		canActivate: [AuthGuardService],
		data: { baipass: ['artist', 'lead'], permissions: {}, checkProject: true }
	},
	{
		path: 'tasks-track-grid/:id',
		component: TaskTrackDatatableComponent,
		canActivate: [AuthGuardService],
		data: { baipass: ['artist', 'lead'], permissions: {}, checkProject: true }
	},
	{
		path: 'tasks-track/:id/:notify',
		component: TaskTrackComponent,
		canActivate: [AuthGuardService],
		data: { baipass: ['artist', 'lead'], permissions: { project_screens: ['view'] }, checkProject: true }
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class TasksRoutingModule { }
