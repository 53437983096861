import { ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DataService } from '../../services/data.service';
import { DatatableService } from '../../services/datatable/datatable.service';
import * as _ from "lodash";
import { CommonJs } from '../../common/common-js';
import { AppConfigs } from '../../common/app-configs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ReportDetailsComponent } from '../reports/report-details/report-details.component';
import { columns } from './dashboard-column';
import { DatatableComponent, TableColumn } from '@swimlane/ngx-datatable';
import { GridService } from 'src/app/services/grid.service';
//  import { NgProgressService } from 'ngx-progressbar';
declare var $: any;

export interface Notifications {
	client_code: string,
	created_by: number,
	description: string,
	is_unread: boolean,
	project_id: number,
	task_id: number,
	type: string,
	user_id: number,
	created_at: Date,
	updated_at: Date,
}

@Component({
	selector: 'dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})

export class DashboardComponent implements OnInit {
	entityName = 'Dashboard';
	_loadManageColSideWindow: boolean = false;;
	_loadTaskDetailsSideWindow: boolean = false;
	_task_details_opened: boolean = false;
	allProjectsVisible: boolean = false;
	_ng_sidebar_task_details_data = {};

	projectlistofuser = [];

	slideConfig = {
		dots: true,
		arrows: true,
		infinite: true,
		speed: 300,
		slidesToShow: 7,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 1900,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 3,
				}
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 2,
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 4,
				}
			},
			{
				breakpoint: 601,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	data: { [index: string]: any } = {};
	uploadUrl = this.commonJs.getUploadUrl();

	role = (localStorage.getItem('role') != '' && localStorage.getItem('role') != undefined) ? localStorage.getItem('role') : 'superadmin';

	//Dashboard Stats
	projectList: Array<any>;
	projectId = '';
	where: { [index: string]: any } = {};
	departments: { [index: string]: any } = {};
	inventory: Array<any>;
	assignments: Array<any>;
	lateStarted: Array<any>;
	wipTasks: Array<any>;
	reviewPending: Array<any>;
	retakeNotDelivered: Array<any>;
	rejectedInventory: Array<any>;
	outsourcedTasks: Array<any>;
	deadlineExceededTasks: Array<any>;
	retakePercentage: Array<any>;
	timelogExceeded: Array<any>;
	projectedDelivery: Array<any>;
	actualDelivery: Array<any>;
	projectStatus: Array<any>;

	//Actual Delivery Chart
	public actualDeliveryDonut: string = 'doughnut';
	public actualDeliveryTodayDataDonut: number[] = [0, 0, 0];
	public actualDeliveryYesterdayDataDonut: number[] = [0, 0, 0];
	public actualDeliveryWeekDataDonut: number[] = [0, 0, 0];
	public actualDeliveryOptionsDonut: any = {
		scaleShowVerticalLines: false,
		responsive: true,
		legend: {
			position: 'right',
			labels: {
				fontFamily: "Nunito",
				fontSize: 10
			},
		},
	};
	public actualDeliveryColors: any[] = [
		{
			backgroundColor: ["#4CAF50", "#2196F3", "#F9AE3E"]
		}];
	public actualDeliveryLabels: string[] = ['Task Delivered', 'Internal Approved', 'External Approved'];

	//Dashboard View Permission:
	isDashboardViewPermission = false;
	//isDashboardViewPermission = this.dataService.checkAccess('production_dashboard', 'view', {'baiPass':['admin']});

	busy: Subscription;

	dataFromDashboard = {
		'project_id': 1,
		'department': 1,
		'stat': 'inventory',
	};
	bsModalRef: BsModalRef;

	isTaskDetailsViewOpen: boolean = false;

	tasksArr: Array<any>;

	taskDetailsViewHeaderClass = 'table-head-assignment-pending';
	noRecordsMsg: string = 'Sorry, no records found!';

	taskDetailsBusy: Subscription;

	title = 'assignment-pending';
	type = 'internal';

	//Pagination
	public rows = this.datatableService.rows;
	public FilterColumns: Array<any> = ['list_name'];
	public config: any = {
		paging: true,
		sorting: { columns: [] },
		filtering: { filterString: '' },
		className: ['table-striped', 'table-bordered'],
		where: { 'project_id': this.projectId, 'departments': this.departments },
	};
	public maxSize = this.datatableService.maxSize;
	public page: number = this.datatableService.page;
	public itemsPerPage: number = this.datatableService.itemsPerPage;
	//public itemsPerPage:number = 3;
	public length = this.datatableService.length;
	public numPages: number = this.datatableService.numPages;

	isProjectStatusCollapsed: boolean = true;
	projectStatusCollapsedArrow: string = 'expand_more';

	//! Chirag

	dashBoardObj: any = {};
	currentRole: string;
	projectRoles = JSON.parse(localStorage.getItem('projectRoles'));
	isViewPermission = [];

	projectSkeleton: boolean = true;
	taskStatusSkeleton: boolean = true;

	skeleton: boolean = true;

	searchInput: string = ''; // Holds the search term
	filteredProjects: any[] = []; // Holds the filtered project list

  _ng_sidebar_data = {};
  _opened: boolean = false;
  _loadSideWindow: boolean = false;

  visiblePhases: any[] = [];
  phasesExist = {};

	// ! Constructor
	constructor(
		private titleService: Title,
		private dataService: DataService,
		private datatableService: DatatableService,
		public commonJs: CommonJs,
		public appConfigs: AppConfigs,
		private router: Router,
		private modalService: BsModalService,
		private cd: ChangeDetectorRef,
		private gridService: GridService) {
		this.dashBoardObj.AllprojectLength = 0
		this.datatableService.setParam(this.config, 'config');
		this.datatableService.setParam('getAssignmentPendingTasks', 'serverUrl');
		this.dashBoardObj.status = [];
		this.dashBoardObj.projectListByUser = [];
		this.dashBoardObj.tabName = 'previousIssue';
	}

	// ! NgonInit
	async ngOnInit() {
		this.titleService.setTitle(this.entityName);
		this.data.project_id = { id: 0, project_code: 'All' };
		this.data.department_id = { d_id: 0, department: 'All', dtt_id: null, dtt_name: null, phase_id: 0 };
		this.dashBoardObj.clientCodePrefix = this.commonJs.getClientCodePrefix();

    if(localStorage.getItem('phasesExist')){
      this.phasesExist = JSON.parse(localStorage.getItem('phasesExist'));
      // console.log(this.phasesExist);
    }else if(this.commonJs.phasesExist){
      this.phasesExist = this.commonJs.phasesExist;
    }else{
      this.phasesExist = { pre:1, character:1, set:1, prop:1, shot:1, post:1 };
    }

		setTimeout(() => {
			this.dashBoardObj.teamManagmentView = this.commonJs.checkAccess('team_management', 'view', { 'baiPass': ['admin'] });
			this.isDashboardViewPermission = this.commonJs.checkAccess('production_dashboard', 'view', { 'baiPass': ['admin'] });
			this.isViewPermission['projects'] = this.commonJs.checkAccess('projects', 'view', { 'baiPass': ['admin'] });
			if (this.isDashboardViewPermission && this.role != 'artist' && this.role != 'vendor' && this.role != 'client') {
				this.getAssignmentsAndActiveTasks(0, this.data.department_id);
				this.getRetakeAndRejectedTasks(0, this.data.department_id);
				this.getProjectedDelivery(0, this.data.department_id);
				if (!this.isProjectStatusCollapsed) {
					this.getProjectStatus(0, this.data.department_id);
				}
			}
			// this.selectProjectType('In Production','production');
		}, 500);

		// this.actualDeliveryOptionsDonut['pieceLabel'] = {
		// 	fontColor: '#fff',
		// 	fontSize: 10,
		// 	fontFamily: 'Nunito',
		// 	render: function (args) {
		// 		return args.value;
		// 	}
		// };

		$(".menu-mobile").click(function () {
			$(".custom-navbar-nav").toggleClass("active");
		});

		this.dataService.isGetAllProjectList$.subscribe(item => {
			this.projectListByUser();
			this.selectProjectType('In Production', 'production');
			this.projectSkeleton = false;
		});
		await this.projectListByUser();
		this.selectProjectType('In Production', 'production');
		// this.toggleIcon();

	}

	// ! Init Function
	initFunction(callFunc = '') {
		//this.getAssignmentPendingAll(0,this.data.department_id);
		callFunc = environment.production ? '' : callFunc;
		if (callFunc == '') {

		} else if (callFunc == 'getProjectedDelivery') {
			this.getProjectedDelivery(0, this.data.department_id);
		} else if (callFunc == 'getActualDelivery') {
			this.getActualDelivery(0, this.data.department_id);
		} else if (callFunc == 'getProjectStatus') {
			this.getProjectStatus(0, this.data.department_id);
		}
	}

	// ! Project Lists By user
	projectListByUser() {
		this.dashBoardObj.projectlistofuser = [];
		// this.dataService.getListTotal('projectListByUser').subscribe((success) => {
		this.projectlistofuser = this.dataService.getProjectListByUser;
		this.filteredProjects = this.allProjectsVisible ? this.projectlistofuser : this.projectlistofuser.slice(0, 6);
		// this.dataService.getProjectListByUser = success.data;
		this.dashBoardObj.inProductionCount = 0
		this.dashBoardObj.inBiddingCount = 0
		this.dashBoardObj.onHoldCount = 0
		this.dashBoardObj.cancelledCount = 0
		this.dashBoardObj.completedCount = 0
		this.projectlistofuser.filter((res: any) => {
			if (res.status == 'In Production') {
				this.dashBoardObj.inProductionCount++
			} else if (res.status == 'In Bidding') {
				this.dashBoardObj.inBiddingCount++
			} else if (res.status == 'On Hold') {
				this.dashBoardObj.onHoldCount++
			} else if (res.status == 'Cancelled') {
				this.dashBoardObj.cancelledCount++
			} else if (res.status == 'Completed') {
				this.dashBoardObj.completedCount++
			}
		});

		//console.log(this.dashBoardObj.inProductionCount)

		// manage filter project
		this.dashBoardObj.projectlistofuser = this.projectlistofuser;


		// by-default in production project display
		this.dashBoardObj.inProduction = {
			id: "In Production",
			name: "In Production"
		}
		// filter function
		// this.selectProjectType(this.dashBoardObj.inProduction);
		this.dashBoardObj.AllprojectLength = this.projectlistofuser.length;
		this.projectSkeleton = false;
		this.cd.markForCheck();

		// }, (error) => {
		// 	this.projectSkeleton = false;
		// });
	}

	// ! Overview
	goToOverview(id) {
		this.dataService.projectIdByDashboard = id;
		this.dataService.clientCode = this.commonJs.getClientCode();
		this.router.navigate(['/' + this.commonJs.getClientCodePrefix() + '/projects/dashboard/' + id]);
	}

	// ! Get Filter Data
	getFilterData(query, column) {
		if (column == 'project') {
			this.getProjects(query);
		} else if (column == 'department') {
			this.getDepartments(query);
		}
	}

	//! On Submit
	onSubmit(form: NgForm) {
		let project_id = form.value.project_id.id;
		let department = form.value.department_id;
		//this.getInventoryAll(project_id,department);
		this.getAssignmentsAndActiveTasks(project_id, department);
		this.getRetakeAndRejectedTasks(project_id, department);
		this.getProjectedDelivery(project_id, department);
		// this.getProjectStatus(project_id, department);

		if (this.isTaskDetailsViewOpen) {
			this.page = 1;
			this.where.project_id = project_id;
			this.where.departments = [department];
			this.getTaskDetailsView(this.title, this.type, '');
		}
	}

	// ! Get AssignMent And Active tasks
	getAssignmentsAndActiveTasks(project_id, department) {
		// department = [
		// 	{
		// 		"d_id":40,
		// 		"dtt_id":null,
		// 		"id":40,
		// 		"department":"Previz - SHOT PRODUCTION"
		// 	},
		// 	{
		// 		"d_id":38,
		// 		"dtt_id":1,
		// 		"id":38,
		// 		"department":"Key Pose - Animation"
		// 	}
		// ];
		//project_id = 1;
		this.where.project_id = project_id;
		this.where.departments = [department];
		this.dataService.getListData('getAssignmentsAndActiveTasks', { 'where': this.where })
			.subscribe((success) => {
				this.assignments = success.data.assignments;
				this.inventory = success.data.inventory;
				this.reviewPending = success.data.reviewPending;

				//
				this.lateStarted = success.data.lateStarted;
				this.wipTasks = success.data.activeTasks;
				this.taskStatusSkeleton = false;
			}, (rejected) => {
				this.taskStatusSkeleton = false;
				//console.log(rejected);
			});

	}

	// ! Get Retake And Rejected Tasks
	getRetakeAndRejectedTasks(project_id, department) {
		this.where.project_id = project_id;
		this.where.departments = [department];
		this.dataService.getListData('getRetakeAndRejectedTasks', { 'where': this.where })
			.subscribe((success) => {
				this.rejectedInventory = success.data.rejectedInventory;
				this.deadlineExceededTasks = success.data.deadlineExceededTasks;
				this.outsourcedTasks = success.data.outsourcedTasks;
				this.timelogExceeded = success.data.timelogExceeded;

				//retake not delivered
				this.retakeNotDelivered = success.data.retakeNotDelivered;

				//retake percentage
				this.retakePercentage = success.data.retakePercentage;
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	// ! Get Project Delivery
	getProjectedDelivery(project_id, department) {
		this.where.project_id = project_id;
		this.where.departments = [department];
		this.dataService.getListData('getProjectedDelivery', { 'where': this.where })
			.subscribe((success) => {
				this.projectedDelivery = success.data.projectedDelivery;
				this.actualDelivery = success.data.actualDelivery;
				this.actualDeliveryTodayDataDonut = success.data.actualDelivery.todayChartData;
				this.actualDeliveryYesterdayDataDonut = success.data.actualDelivery.yesterdayChartData;
				this.actualDeliveryWeekDataDonut = success.data.actualDelivery.weekChartData;
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	// ! Get Actual Delivery
	getActualDelivery(project_id, department) {
		this.where.project_id = project_id;
		this.where.departments = [department];
		this.dataService.getListData('getActualDelivery', { 'where': this.where })
			.subscribe((success) => {
				this.actualDelivery = success.data;
				this.actualDeliveryTodayDataDonut = success.data.todayChartData;
				this.actualDeliveryYesterdayDataDonut = success.data.yesterdayChartData;
				this.actualDeliveryWeekDataDonut = success.data.weekChartData;
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	// ! Project Status
	getProjectStatus(project_id, department) {
		this.where.project_id = project_id;
		this.where.departments = [department];
		this.busy = this.dataService.getListData('getProjectStatus', { 'where': this.where })
			.subscribe((success) => {
				this.projectStatus = success.data;
				this.skeleton = false;
			}, (rejected) => {
				//console.log(rejected);
				this.skeleton = false;
			});
	}

	// ! Get Projects
	getProjects(query) {
		this.dataService.getListData('projectsSideList', { 'where': this.where, 'query': query['query'] })
			.subscribe((success) => {
				this.projectList = success.data;
				this.projectList.unshift({ id: 0, project_code: 'All' });
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	// ! Get Departments
	getDepartments(query) {
		let projectId = this.data.project_id != undefined ? this.data.project_id.id : 0;
		this.dataService.getListData('dashboardGetAllDepartments', { 'project_id': projectId, 'query': query['query'] })
			.subscribe((success) => {
				this.departments = success.data;
				this.departments.unshift({ d_id: 0, department: 'All', dtt_id: null, dtt_name: null, phase_id: 0 });
			}, (rejected) => {
				//console.log(rejected);
			});
	}

	// ! Reset Filter
	resetFilter() {
		if (this.data.project_id.id != 0 || this.data.department_id.d_id != 0) {
			this.data.project_id = { id: 0, project_code: 'All' };
			this.data.department_id = { d_id: 0, department: 'All', dtt_id: null, dtt_name: null, phase_id: 0 };
			this.getAssignmentsAndActiveTasks(0, this.data.department_id);
			this.getRetakeAndRejectedTasks(0, this.data.department_id);
			this.getProjectedDelivery(0, this.data.department_id);
			// this.getProjectStatus(0, this.data.department_id);

			if (this.isTaskDetailsViewOpen) {
				this.page = 1;
				this.where.project_id = 0;
				this.where.departments = [this.data.department_id];
				this.getTaskDetailsView(this.title, this.type, '');
			}
		}
	}

	// ! Open Report Detail
	openReportDetails() {
		this.bsModalRef = this.modalService.show(ReportDetailsComponent);
		this.bsModalRef.content.title = this.entityName;
		this.bsModalRef.content.data = this.appConfigs.reportDetails[14]['production-dashboard'];
		this.bsModalRef.content.onClose = () => {
			this.bsModalRef.hide();
		};
		this.commonJs.initiateJS();
	}

	// ! On Table Change
	public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }): any {
		this.taskDetailsBusy = this.datatableService.onChangeTable(this.config, page)
			.subscribe((success) => {
				this.tasksArr = success.data.tasks;

				//this.length = success.total;
				this.length = success.data.total;
			});
	}

	// ! Get Task Details View
	getTaskDetailsView(title = 'assignment-pending', type = 'internal', event) {

		// to reset pagination back to zero when TASK STATUS tab change
		this.ngxConfig.start = 0;

		this.isLoading = true;
		this.isTaskDetailsViewOpen = true;
		this.config.where.title = this.title = title;
		this.config.where.type = this.type = type;

		this.config.where.project_id = this.where.project_id;
		this.config.where.departments = this.where.departments;

		this.taskDetailsViewHeaderClass = 'table-head-' + title;
		let route = 'getAssignmentPendingTasks';
		if (title == 'assignment-pending') {
			route = 'getAssignmentPendingTasks';
		} else if (title == 'inventory-fresh-retake') {
			route = 'getInventoryTasks';
		} else if (title == 'late-started-tasks') {
			route = 'getLateStartedTasksDetails';
		} else if (title == 'review-pending') {
			route = 'getReviewPendingTasks';
		} else if (title == 'retake-not-delivered') {
			route = 'getRetakeNotDeliveredTasks';
		} else if (title == 'rejected-inventory') {
			route = 'getRejectedInventoryTasks';
		} else if (title == 'tasks-exceeded-deadline') {
			route = 'getDeadlineExceededTasks';
		} else if (title == 'tasks-timelog-exceed') {
			route = 'getTimelogExceededTasks';
		}

		if (event != undefined && event != '') {
			this.page = event.page;
		} else {
			this.page = 0;
		}
		this.routerUrl = route;
		this.createColumns(route);
		// this.getTasksData(route);
		this.datatableService.setParam(route, 'serverUrl');
		// this.onChangeTable(this.config);
	}

	// ! Download Task Details
	downloadTaskDetails(title = 'assignment-pending', type = 'internal') {
		this.gridService.openProgressModal();
		this.where.title = this.title = title;
		this.where.type = this.type = type;
		this.where.isDownload = true;
		this.dataService.getListData('downloadedTaskDetails', { 'where': this.where })
			.subscribe((success) => {
				window.location.href = success.data;
				this.gridService.myEventEmitterForProgressModal.emit(true);
			}, (rejected) => {
				this.gridService.myEventEmitterForProgressModal.emit(true);
				//console.log(rejected);
			});
		this.gridService.progressFunction();

	}

	// ! Back to Dashboard
	backToDashboard() {
		this.isTaskDetailsViewOpen = false;
		this.tasksArr = [];
	}

	// ! Track by Tasks
	trackByTasks(index, item) {
		return item.id;
	}

	// ! Toggle Icon
	toggleIcon() {
		// this.isProjectStatusCollapsed = !this.isProjectStatusCollapsed;
		// if (!this.isProjectStatusCollapsed) {
		// this.projectStatusCollapsedArrow = 'expand_less';
		if (!this.projectStatus) {
			this.getProjectStatus(this.data.project_id.id, this.data.department_id);
		}
		// } else {
		// this.projectStatusCollapsedArrow = 'expand_more';
		// }
		//keyboard_arrow_down
		if (document.getElementById('projectStatusTab').classList.contains('collapsed')) {
			document.getElementById('projectStatusTab').classList.remove('collapsed');
		}
		else {
			document.getElementById('projectStatusTab').classList.add('collapsed');
		}
	}

	//Search Projects
	search(query, column) {
		if (column == 'status') {
			this.dashBoardObj.status = this.appConfigs.prj_status_all.map(v => { return v });
		}
	}

	// select  project type
	selectProjectType(event, id) {
		this.projectSkeleton = true;
		this.projectlistofuser = [];
		// only project type selected project list
		this.dashBoardObj.projectListByUser = [];

		if (event == 'all') {
			this.projectlistofuser = this.dashBoardObj.projectlistofuser;
			this.allProjectsVisible ? (this.filteredProjects = this.projectlistofuser) : (this.filteredProjects = this.projectlistofuser.slice(0, 6));

			//console.log("this.projectlistofuser", this.projectlistofuser);
		} else {
			this.projectlistofuser = this.dashBoardObj.projectlistofuser.filter((data) => {
				if (event == data.status) {
					return data;
				}
			});
			this.allProjectsVisible ? (this.filteredProjects = this.projectlistofuser) : (this.filteredProjects = this.projectlistofuser.slice(0, 6));

		}
		this.projectSkeleton = false;

		this.dashBoardObj.tabName = id;

		this.dashBoardObj.projectLength = this.dashBoardObj.projectListByUser.length;
		// while project lenght is zero
		if (this.dashBoardObj.projectListByUser == 0) {
			// this.projectlistofuser.length>0?(this.projectSkeleton=false):'';
			this.dashBoardObj.projectNoRecordmsg = {
				projectType: event + '!',
				empytyMsg: "Sorry, no records found"
			}

		}
		this.cd.markForCheck();
	}

	trackByFn(index: number, item: any): any {
		return item.id; // Replace 'id' with the property you want to use as the identifier
	}

  onOpened(){
    this._loadSideWindow = true;
  }

  onClosed(){
    this._loadSideWindow = false;
  }

  async closeCreateTask(event) {
    if (event.id == 3) {
      this._loadSideWindow = false;
      this._opened = false;
    } else {
      // this.isLoading = true;

      this._ng_sidebar_data['taskid'] = [];

      // this.getTaskDetailsView(this.title, this.type, '');
      // this.getTasksList();

      if (event.id == 1) {
        this._loadSideWindow = false;
        this._opened = false;

        await this.getTasksData(this.routerUrl);
        this.getAssignmentsAndActiveTasks(0, this.data.department_id);
      }
    }
  }

	onTaskDetailsOpened() {
		this._loadTaskDetailsSideWindow = true;
	}

	onTaskDetailsClosed() {
		this._loadTaskDetailsSideWindow = false;
		this._task_details_opened = false;
		this._ng_sidebar_task_details_data = [];
		$('.active-task').removeClass('active-task');
	}

	closeTaskDetails(event) {
	}

	openTaskDetails(data) {
		// const data: any = {
		// 	"id": 33753,
		// 	"project_id": 98,
		// 	"phase_id": 6,
		// 	"season_id": null,
		// 	"episode_id": null,
		// 	"sequence_id": null,
		// 	"asset_id": null,
		// 	"number": null,
		// 	"name": null,
		// 	"shot_id": 1144,
		// 	"department_id": 39,
		// 	"department_tasktype_id": "",
		// 	"description": null,
		// 	"type": "Production",
		// 	"prod_type": "prod",
		// 	"created_by": "1",
		// 	"auto_created": 1,
		// 	"is_converted": 0,
		// 	"thumbnail": "movie",
		// 	"thumbnail_display_name": null,
		// 	"created_at": "2022-09-20 05:34:09",
		// 	"updated_at": "2022-09-20 05:34:09",
		// 	"task_id": 33753,
		// 	"shot_number": "shot2",
		// 	"shot_complexity": null,
		// 	"shot_info": "season1 - episode1 - sequence1 - shot2",
		// 	"asset_info": null,
		// 	"asset_complexity": null,
		// 	"version": "V001",
		// 	"revision": "R001",
		// 	"task_version_id": 37852,
		// 	"version_status": "Awaiting Inventory",
		// 	"season_episode": null,
		// 	"sequence": null,
		// 	"bid_days": 1,
		// 	"phaseType": "shot",
		// 	"department": "Layout",
		// 	"completion": "0",
		// 	"taskAssignmentIds": "6178",
		// 	"assignedToArr": "Aladin",
		// 	"transferredArr": [
		// 		"0"
		// 	],
		// 	"descriptionArr": null,
		// 	"empCodeArr": [
		// 		"@l@4586"
		// 	],
		// 	"completionArr": null,
		// 	"undertaking": "In House",
		// 	"priority": "Normal",
		// 	"task_type": "Production",
		// 	"ta_id": 6178,
		// 	"ta_bid_days": "1",
		// 	"ta_start_date": "2022-10-22 08:23:00",
		// 	"ta_end_date": "2022-10-23 08:23:00",
		// 	"shot_image": "",
		// 	"asset_image": null,
		// 	"season_number": "season1",
		// 	"episode_number": "episode1",
		// 	"sq_sequence_id": 611,
		// 	"sequence_number": "sequence1",
		// 	"sequence_name": "1 - sequence1",
		// 	"e_episode_id": 139,
		// 	"is_inv_rejected": 0,
		// 	"assigned_to": "Aladin",
		// 	"info": "season1 - episode1 - sequence1 - shot2",
		// 	"statusColor": "status-color-awaiting-inventory",
		// 	"statusClass": "#A9A9A9",
		// 	"actualStatus": "Awaiting Inventory",
		// 	"status": "Wtg",
		// 	"complexity": null,
		// 	"assigned_to_name": [
		// 		"Aladin"
		// 	],
		// 	"completionPerArr": [
		// 		""
		// 	],
		// 	"descArr": [
		// 		""
		// 	],
		// 	"min_start_date": "22/10/22 13:53:00",
		// 	"max_end_date": "23/10/22 13:53:00",
		// 	"actual_start_date": null,
		// 	"actual_end_date": null,
		// 	"excel_actual_start_date": null,
		// 	"excel_actual_end_date": null,
		// 	"season_and_episode": "season1 - episode1"
		// }

		this._ng_sidebar_task_details_data['projectId'] = data.project_id;
		this._ng_sidebar_task_details_data['project_id'] = data.project_id;
		this._ng_sidebar_task_details_data['from'] = 'task_list';
		this._ng_sidebar_task_details_data['allow_status'] = false;
		this._ng_sidebar_task_details_data['is_artist'] = false;
		this._ng_sidebar_task_details_data['isReview'] = false;
		if (this.role != 'artist' && this.role != 'vendor') {
			this._ng_sidebar_task_details_data['isReview'] = true;
		}
		this._ng_sidebar_task_details_data['selectedTab'] = 'task_info';
		this._task_details_opened = true;
		this._ng_sidebar_task_details_data['task'] = data;
		this._ng_sidebar_task_details_data['task_id'] = data.task_id;
		this._ng_sidebar_task_details_data['actualStatus'] = data.actualStatus;
		this._ng_sidebar_task_details_data['department_id'] = data.department_id;
		this._ng_sidebar_task_details_data['department_tasktype_id'] = data.department_tasktype_id;
		this._ng_sidebar_task_details_data['task_version_id'] = data.task_version_id;
		this._ng_sidebar_task_details_data['id'] = data.task_version_id;
		this._ng_sidebar_task_details_data['status'] = data.status;
		this._ng_sidebar_task_details_data['revision'] = data.revision;
		this._ng_sidebar_task_details_data['version'] = data.version;
		this._ng_sidebar_task_details_data['task_type'] = data.task_type;
		this._ng_sidebar_task_details_data['priority'] = data.priority;
		this._ng_sidebar_task_details_data['working_days'] = data.working_days;
		this._ng_sidebar_task_details_data['bid_days'] = data.bid_days;
		this._ng_sidebar_task_details_data['completion'] = data.completion;
		this._ng_sidebar_task_details_data['undertaking'] = data.undertaking;
		this._ng_sidebar_task_details_data['descArr'] = data.descArr;
		this._ng_sidebar_task_details_data['assigned_to_name'] = data.assigned_to_name;
		this._ng_sidebar_task_details_data['assigned_to'] = data.assigned_to;
		this._ng_sidebar_task_details_data['completionPerArr'] = data.completionPerArr;
		this._ng_sidebar_task_details_data['transferredArr'] = data.transferredArr;
		this._ng_sidebar_task_details_data['shot_image'] = data.shot_image;
		this._ng_sidebar_task_details_data['asset_image'] = data.asset_image;
		//this._ng_sidebar_task_details_data['thumbnail'] = data.asset_image;
		if (data.shot_image && data.shot_image != "") {
			this._ng_sidebar_task_details_data['thumbnail'] = data.shot_image;
		}
		if (data.asset_image && data.asset_image != "") {
			this._ng_sidebar_task_details_data['thumbnail'] = data.asset_image;
		}
		this._ng_sidebar_task_details_data['asset_id'] = data.asset_id;
		this._ng_sidebar_task_details_data['shot_id'] = data.shot_id;
		this._ng_sidebar_task_details_data['task_auto_created'] = data.auto_created;
		this._ng_sidebar_task_details_data['department_name'] = data.department;
		this._ng_sidebar_task_details_data['department_tasktype_name'] = data.department_tasktype_name;
		this._ng_sidebar_task_details_data['info'] = data.info;
		if (data.auto_created == 0 && data.thumbnail != null && data.thumbnail != 'done_all') {
			this._ng_sidebar_task_details_data['thumbnail'] = data.thumbnail;
		} else {
			this._ng_sidebar_task_details_data['icon'] = data.thumbnail;
		}
		this._ng_sidebar_task_details_data['e_episode_id'] = data.e_episode_id;
		this._ng_sidebar_task_details_data['sq_sequence_id'] = data.sq_sequence_id;
	}


	@ViewChild('taskInfo', { static: false }) public taskInfo: TemplateRef<any>;
	@ViewChild('assign', { static: false }) public assign: TemplateRef<any>;
	@ViewChild('status', { static: false }) public status: TemplateRef<any>;
	@ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

	public dataTableColumns: TableColumn[] = [];
	dataRows: any = [];
	temp = [];
	currentPage: number = 0;
	pageSize: number = 50;
	totalItems: number = 0;
	isLoading: boolean = true;
	pagePayload: any = {
		page: 0,
		itemsPerPage: 50,
	}

	public ngxConfig: any = {
		itemsPerPage: this.pagePayload.itemsPerPage,
		start: this.pagePayload.page,
		"sortNew": {},
		"FilterColumns": [],
		"filtering": {
			"filterString": ""
		},
		"FilterByColumns": {},
		"where": this.config.where
	};
	routerUrl: any;
	public readonly pageLimitOptions = [
		{ value: 50 },
		{ value: 100 },
		{ value: 200 },
		{ value: 300 },
	];
	/** Create Column */
	private createColumns(url: any): void {
		let cellTemplate: any = {};
		setTimeout(() => {
			cellTemplate = {
				taskInfo: this.taskInfo,
				assign: this.assign,
				status: this.status,
			};
			this.dataTableColumns = [];
			this.dataRows = [];
			this.totalItems = 0;
			this.dataTableColumns = columns('dashboardTable', cellTemplate);
			this.getTasksData(url)
		});
	}

	/** Get data  */
	getTasksData(url: any) {
		this.isLoading = true;
		this.gridService.postData(url, this.ngxConfig).subscribe((res: any) => {
			if (!this.isLoading) {
				this.isLoading = true;
			}
			this.totalItems = res.data.total;
			this.temp = [...res.data.tasks];
			this.dataRows = this.temp;
			this.isLoading = false;
		});
	}

  // Assign the task
  openSideWindowAssign(value: any, type: any) {
    // this.spinner.hide();
    // console.log(value);
    if ((value.task_id != undefined && value.task_id != '')) {
      this._ng_sidebar_data['taskid'] = value.task_id;
    } else if (value.length > 0) {
      this._ng_sidebar_data['taskid'] = value;
    }
    this._ng_sidebar_data['task'] = true;
    this._ng_sidebar_data['projectId'] = value.project_id;
    this._ng_sidebar_data['department_id'] = value.department_id;
    this._ng_sidebar_data['mode'] = 'edit';
    this._ng_sidebar_data['info'] = value.info;
    this._ng_sidebar_data['auto_created'] = value.auto_created;
    this._ng_sidebar_data['undertaking'] = value.undertaking;
    this._ng_sidebar_data['number'] = value.number;
    this._ng_sidebar_data['name'] = value.name;
    this._ng_sidebar_data['department'] = value.department;
    this._ng_sidebar_data['thumbnail'] = value.thumbnail;
    this._ng_sidebar_data['is_assign'] = true;
    this._ng_sidebar_data['assigned_val'] = value.assigned_to==null ? 'Assign': value.assigned_to;
    this._ng_sidebar_data['is_multiple_assign'] = false;

    this._opened = true;
  }

	// TODO[Dmitry Teplov] wrap dynamic limit in a separate component.
	public onLimitChange(limit: any): void {
		this.changePageLimit(limit);
		this.table.limit = this.pageSize;
		this.table.recalculate();
		setTimeout(() => {
			if (this.table.bodyComponent.temp.length <= 0) {
				this.table.offset = Math.floor((this.table.rowCount - 1) / this.table.limit);
			}
		});
	}

	private changePageLimit(limit: any): void {
		this.isLoading = true;
		this.pageSize = parseInt(limit, 10);
		this.ngxConfig.itemsPerPage = this.pageSize;
		this.getTasksData(this.routerUrl);
	}

	/**
* Description: Page change event
* @param e pager object
*/
	onPageChange(e): void {
		// this.currentPage = e.offset;
		this.isLoading = true;
		this.pagePayload.page = e.offset * this.pageSize;
		this.ngxConfig.start = this.pagePayload.page;
		this.getTasksData(this.routerUrl);
	}

	// Function to filter projects based on searchInput
	filterProjects() {
		// console.log(this.allProjectsVisible);
		// console.log(this.projectlistofuser.filter(project =>project.name.toLowerCase().includes(this.searchInput.toLowerCase())),this.searchInput.toLowerCase());
		// this.filteredProjects =  this.allProjectsVisible ? this.projectlistofuser.filter(project =>	project.name.toLowerCase().includes(this.searchInput.toLowerCase()) ) :this.projectlistofuser.slice(0, 6).filter(project =>	project.name.toLowerCase().includes(this.searchInput.toLowerCase()) );
		this.filteredProjects = this.allProjectsVisible ? this.projectlistofuser.filter(project => project.name.toLowerCase().includes(this.searchInput.toLowerCase())) : this.projectlistofuser.filter(project => project.name.toLowerCase().includes(this.searchInput.toLowerCase())).slice(0, 6);

		this.dashBoardObj.inProductionCount = 0;
		this.dashBoardObj.inBiddingCount = 0;
		this.dashBoardObj.onHoldCount = 0;
		this.dashBoardObj.cancelledCount = 0;
		this.dashBoardObj.completedCount = 0;
		if (this.allProjectsVisible) {
			this.filteredProjects.filter((res: any) => {
				if (res.status == 'In Production') {
					this.dashBoardObj.inProductionCount++;
				} else if (res.status == 'In Bidding') {
					this.dashBoardObj.inBiddingCount++;
				} else if (res.status == 'On Hold') {
					this.dashBoardObj.onHoldCount++;
				} else if (res.status == 'Cancelled') {
					this.dashBoardObj.cancelledCount++;
				} else if (res.status == 'Completed') {
					this.dashBoardObj.completedCount++;
				}
			}).slice(0, 6);
		} else {
			this.filteredProjects.filter((res: any) => {
				if (res.status == 'In Production') {
					this.dashBoardObj.inProductionCount++;
				} else if (res.status == 'In Bidding') {
					this.dashBoardObj.inBiddingCount++;
				} else if (res.status == 'On Hold') {
					this.dashBoardObj.onHoldCount++;
				} else if (res.status == 'Cancelled') {
					this.dashBoardObj.cancelledCount++;
				} else if (res.status == 'Completed') {
					this.dashBoardObj.completedCount++;
				}
			});
		}

		this.filteredProjects.length <= 6 ? (this.allProjectsVisible = false) : '';

	}

	/** Set bg color */
	setBgColor(data: any) {
		if (data != 'dependency' && data) {
			return data;
		} else if (data == 'dependency' && data) {
			return '#ccb9a1'
		}
	}

	// For projects card show more/less
	projectsCardsToggle() {
		this.searchInput = '';
		this.allProjectsVisible = !this.allProjectsVisible;
		this.allProjectsVisible ? (this.filteredProjects = this.projectlistofuser) : (this.filteredProjects = this.projectlistofuser.slice(0, 6));
	}

}

