import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
//import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import * as _ from "lodash";
import { CommonJs } from '../../../common/common-js';
import { AppConfigs } from '../../../common/app-configs';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { GridService } from 'src/app/services/grid.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { columns } from '../utility/column-generator';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
type AOA = any[][];
declare var $: any;

@Component({
  selector: 'app-bidding',
  templateUrl: './bidding.component.html',
  styleUrls: ['./bidding.component.css']
})
export class BiddingComponent implements OnInit {

  entityName = "Bidding";
  projectId = '';
  currentTab = 'pre-prod-bid';
  where: { [index: string]: any } = {};
  versionList = [/*{'id': 'v1', 'name': 'V1'}*/];

  isAddPermission = false;
  isDeletePermission = false;
  isViewCostPermission = false;

  noOfDaysInWeek: any = 0;
  currency = this.commonJs.getSettingValue('currency');


  preProdData: any = [];
  postProdData: any = [];

  charData = [];
  setData = [];
  propData = [];
  value = [];
  complexity = this.appConfigs.sht_ast_complexity;


  shotProData = [];
  shotProValue = [];
  shotProDeptValue = [];
  //isDisplay = false;

  renderValue = [];
  storageValue = [];

  costSummaryData = [];
  costSummaryValue = [];
  costSummarySubTotal = [];

  assumptionsValue = [];
  techSpecsValue = [];

  staffingScheduleData = [];
  staffingScheduleWeekData = [];
  staffingScheduleValue = [];
  is_production_part: boolean = false;
  staffingScheduleOtherData = [];

  budgetScheduleValue = [];

  typeOptions = [
    { id: 'panel', name: "panel" },
    { id: 'shots', name: "shots" },
    { id: 'seconds', name: "seconds" },
  ];

  sstypeOptions = this.appConfigs.user_vertical;
  settings: any = JSON.parse(localStorage.getItem('settings'));
  isSubmitted: boolean = false;

  //Functions-as-variables
  totalMonthNodesVar: any;
  totalMNFSR: any;

  isNotEditableversion: boolean = true;
  projectData: any = JSON.parse(localStorage.getItem('localStorageProjectData'));

  isBiddingPermissions = [];

  phasesExist: any = {};

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('idsT', { static: false }) public idsT: TemplateRef<any>;
  @ViewChild('dateCell', { static: false }) public dateCell: TemplateRef<any>;

  biddingRows: any[] = [];
  biddingColumns: any[] = [];

  totalBidsCount: number = 0;
  tableGridHeight = { headerHeight: 42, rowHeight: 40, footerHeight: 50 };
  isLoading: boolean = false;
  bsModalRef: BsModalRef;
  addBidform: { [index: string]: any } = {};
  addbidtype:string = '';
  importtype:string = '';

  public files: NgxFileDropEntry[] = [];
  file_name: string;
	file_type: string;
	url: any;
  preview_src: string = '';

  apiRoutePrefix = 'import';
  currentEntity: any = 'bids';
  uploadedFileInfo: any = {};
  data: AOA = [];
  // openedPage:string = 'bidHistory';
  openedPage:string = 'advanced';
  simpleBidValue: any = {};

  constructor(private titleService: Title,
    private dataService: DataService,
    public commonJs: CommonJs,
    public appConfigs: AppConfigs,
    private activatedRoute: ActivatedRoute,
    private gridService: GridService,
    //private _hotkeysService: HotkeysService,
    private toastrService: ToastrService,
    private modalService: BsModalService,) {

    let that = this;
    _.forEach(that.complexity, function (value) {
      value['pre-production-breakdown'] = { 'char-count': 0 };
      value['post-production-breakdown'] = { 'char-count': 0 };
      value['characters-breakdown'] = { 'char-count': 0 };
      value['sets-breakdown'] = { 'char-count': 0 };
      value['props-breakdown'] = { 'char-count': 0 };
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
      this.where.project_id = params['id'];
    });

    //this.titleService.setTitle(this.settings.html_prefix + this.entityName);
    this.titleService.setTitle(this.settings.html_prefix + 'Pre Production');

    // this._hotkeysService.add(new Hotkey('alt+a', (event: KeyboardEvent): boolean => {
    //     this.exportData();
    //     return false;
    // }));

    if(localStorage.getItem('phasesExist')){
      this.phasesExist = JSON.parse(localStorage.getItem('phasesExist'));
      // console.log(this.phasesExist);
    }else if(this.commonJs.phasesExist){
      this.phasesExist = this.commonJs.phasesExist;
    }else{
      this.phasesExist = { pre:1, character:1, set:1, prop:1, shot:1, post:1 };
    }

    if (this.projectData.status != 'In Bidding') {
      this.isNotEditableversion = false;
    }

    this.loadInitCall();
    this.getNoOfDaysInWeek();
    this.isAddPermission = this.commonJs.checkAccess('bidding', 'add', { 'baiPass': ['admin'] }, this.projectId);
    this.isDeletePermission = this.commonJs.checkAccess('bidding', 'delete', { 'baiPass': ['admin'] }, this.projectId);
    this.isViewCostPermission = this.commonJs.checkAccess('bidding_cost', 'view', { 'baiPass': ['admin'] }, this.projectId);

    let cellTemplateBidding: any = {};
    setTimeout(() => {
      cellTemplateBidding = {
        idsT: this.idsT,
        dateCell: this.dateCell,
      };
      this.biddingColumns = columns('bidding',cellTemplateBidding);
    });
    this.getBiddingHistory('simple-bidding');
  }

  loadInitCall(overwrite_ver = true) {
    this.getVersionList(overwrite_ver).then((res) => {
      this.getValue('pre-production-breakdown');
      this.getValue('post-production-breakdown');
      this.getValue('characters-breakdown');
      this.getValue('sets-breakdown');
      this.getValue('props-breakdown');
      this.generateData();
    });
  }

  search(event, column) {
    if (column == 'type') {
      this.typeOptions = this.typeOptions.map(o => {
        return o;
      });
    } else if (column == 'version') {
      this.versionList = this.versionList.map(o => {
        return o;
      });
    } else if (column == 'sstype') {
      this.sstypeOptions = this.sstypeOptions.map(o => {
        return o;
      });
    }
  }

  // Export
  exportData() {
    this.gridService.openProgressModal();
    this.dataService.getListData('biddingReportExport', { 'where': this.where })
      .subscribe((success) => {
        window.location.href = success.data;
        setTimeout(() => {
          this.gridService.myEventEmitterForProgressModal.emit(true);
        }, 200);
      }, (rejected) => {

      });
    this.gridService.progressFunction();
  }

  generateVersion() {
    this.isSubmitted = true;
    this.dataService.getListData('generateVersion', { 'where': this.where })
      .subscribe((success) => {

        this.toastrService.notify(success.status, '', success.message);
        if (this.currentTab == 'asset-build-bid') {
          this.loadInitCall();
          let that = this;
          _.forEach(that.complexity, function (value) {
            value['pre-production-breakdown'] = { 'char-count': 0 };
            value['post-production-breakdown'] = { 'char-count': 0 };
            value['characters-breakdown'] = { 'char-count': 0 };
            value['sets-breakdown'] = { 'char-count': 0 };
            value['props-breakdown'] = { 'char-count': 0 };
          });
        } else {
          this.getVersionList().then((res) => {
            this.loadData(this.currentTab, this.is_production_part);
          });
        }
        setTimeout(() => {
          this.isSubmitted = false;
        }, 2000);
      }, (rejected) => {
        this.isSubmitted = false;

      });
  }

  deleteVersion() {
    let that = this;
    swal.fire({
      title: 'Delete Bidding Version',
      text: "Are you sure you want to delete these " + this.where.version['name'] + " version?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        that.dataService.deleteData('deleteVersion', { 'where': that.where })
          .subscribe((success) => {

            that.toastrService.notify(success.status, '', success.message);
            if (that.currentTab == 'asset-build-bid') {
              that.loadInitCall();
              _.forEach(that.complexity, function (value) {
                value['pre-production-breakdown'] = { 'char-count': 0 };
                value['post-production-breakdown'] = { 'char-count': 0 };
                value['characters-breakdown'] = { 'char-count': 0 };
                value['sets-breakdown'] = { 'char-count': 0 };
                value['props-breakdown'] = { 'char-count': 0 };
              });

            } else {
              that.getVersionList().then((res) => {
                that.loadData(that.currentTab, that.is_production_part);
              });
            }

          }, (rejected) => {

          });

      } else {
        //console.log('cancelled');
      }
    }
    )
  }


  getVersionList(overwrite_ver = true) {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getListData('getVersionList', { 'where': this.where })
        .subscribe((success) => {
          this.versionList = success.data;


          if (overwrite_ver) {
            this.where.version = this.versionList[0];
          }

          resolve();

        }, (rejected) => {

        });
    });
  }

  selectVersion(event) {
    //this.where.version = event.id;
    if (this.currentTab == 'asset-build-bid') {
      this.loadInitCall(false);
      let that = this;
      _.forEach(that.complexity, function (value) {
        value['pre-production-breakdown'] = { 'char-count': 0 };
        value['post-production-breakdown'] = { 'char-count': 0 };
        value['characters-breakdown'] = { 'char-count': 0 };
        value['sets-breakdown'] = { 'char-count': 0 };
        value['props-breakdown'] = { 'char-count': 0 };
      });

    } else {
      this.getVersionList(false).then((res) => {
        this.loadData(this.currentTab, this.is_production_part);
      });
    }
  }

  loadData(tab, is_production_schedule = false) {
    this.getNoOfDaysInWeek();
    this.is_production_part = is_production_schedule;
    this.currentTab = tab;

    if (tab == 'asset-build-bid') {
      this.titleService.setTitle(this.settings.html_prefix + ' Asset Build Bid');

      let that = this;
      _.forEach(that.complexity, function (value) {
        value['pre-production-breakdown'] = { 'char-count': 0 };
        value['post-production-breakdown'] = { 'char-count': 0 };
        value['characters-breakdown'] = { 'char-count': 0 };
        value['sets-breakdown'] = { 'char-count': 0 };
        value['props-breakdown'] = { 'char-count': 0 };
      });
      this.loadInitCall(false);

    } else if (tab == 'pre-prod-bid') {

      this.getStaffingScheduleData('pre-production-breakdown', 'main');

    } else if (tab == 'post-prod-bid') {

      this.getStaffingScheduleData('post-production-breakdown', 'main');

    } else if (tab == 'shot-production') {
      this.shotProData = [];
      this.generateShotProData();
      this.getShotProValue('shot-production', 'main');

    } else if (tab == 'render-storage') {
      this.getShotProValue('shot-production', 'main').then((res) => {
        this.getRenderStorageValue('render-storage', 'render');
        this.getRenderStorageValue('render-storage', 'storage');
      });

    } else if (tab == 'cost-summary') {
      this.costSummaryData = [];

      this.generateShotProData()
        .then((res) => {
          this.getShotProValue('shot-production', 'main');
          this.getRenderStorageValue('render-storage', 'render');
          this.getRenderStorageValue('render-storage', 'storage');
        })
        .then((res) => {
          this.getCostSummaryData();
          this.getCostSummaryValue('cost-summary', '');
        });

    } else if (tab == 'assumptions') {

      this.getShotProValue('shot-production', 'main').then((res) => {
        this.getAssumptionsValue('assumptions');
      });

    } else if (tab == 'tech-specs') {

      this.getShotProValue('shot-production', 'main')
        .then((res) => {
          this.getRenderStorageValue('render-storage', 'render');
          this.getRenderStorageValue('render-storage', 'storage');
        })
        .then((res) => {
          this.getTechSpecsValue('tech-specs');
        });

    } else if (tab == 'staffing-schedule') {

      this.staffingScheduleData = [];
      this.staffingScheduleWeekData = [];
      this.generateShotProData()
        .then((res) => {
          this.getShotProValue('shot-production', 'main');
          this.getCostSummaryValue('cost-summary', '');
        })
        .then((res) => {
          this.getStaffingScheduleData('staffing-schedule', 'main');
          this.getStaffingScheduleData('staffing-schedule', 'render-nodes');
          this.getStaffingScheduleData('staffing-schedule', 'storage-size');
          this.getStaffingScheduleData('staffing-schedule', 'project-management-team');
          this.getStaffingScheduleData('staffing-schedule', 'mis-team');
          this.getStaffingScheduleData('staffing-schedule', 'technology-team');
          this.getStaffingScheduleData('staffing-schedule', 'render-team');
          this.getStaffingScheduleData('staffing-schedule', 'staffing-team');
          this.getStaffingScheduleData('staffing-schedule', 'other-team');

        });
    } else if (tab == 'budget-schedule') {

      this.generateShotProData()
        .then((res) => {
          this.getShotProValue('shot-production', 'main');
          this.getCostSummaryValue('cost-summary', '');
        })
        .then((res) => {
          this.getStaffingScheduleData('staffing-schedule', 'main');
          this.getStaffingScheduleData('staffing-schedule', 'render-nodes');
          this.getStaffingScheduleData('staffing-schedule', 'storage-size');
          this.getStaffingScheduleData('staffing-schedule', 'project-management-team');
          this.getStaffingScheduleData('staffing-schedule', 'mis-team');
          this.getStaffingScheduleData('staffing-schedule', 'technology-team');
          this.getStaffingScheduleData('staffing-schedule', 'render-team');
          this.getStaffingScheduleData('staffing-schedule', 'staffing-team');
          this.getStaffingScheduleData('staffing-schedule', 'other-team');
        }).then((res) => {
          this.getBudgetScheduleValue('budget-schedule');
        });
    }
  }

  getNoOfDaysInWeek() {
    this.dataService.getListData('getNoOfDaysInWeek', {})
      .subscribe((success) => {
        this.noOfDaysInWeek = success.data;
      }, (rejected) => {

      });
  }

  /*calWeekTotalCost(data, dept_id){

    let dptTotal = 0;
    let that = this;
    _.forEach(data, function(item) {
      //dptTotal = dptTotal + that.ConvertToInt(item[column]);
    });
   return this.ConvertToParseInt(dptTotal);
  }*/

  calOtherBudgetTotal(column, data, is_week_col = false) {
    let dptTotal = 0;
    let that = this;
    _.forEach(data, function (item) {

      if (is_week_col) {
        if (item['data'] != undefined) {
          dptTotal = dptTotal + (that.ConvertToInt(item['data'][column]) * that.ConvertToInt(item['costPerWeek']));
        }
      } else {
        _.forEach(item['data'], function (val) {
          dptTotal = dptTotal + (that.ConvertToInt(val) * that.ConvertToInt(item['costPerWeek']));
        });
      }

    });
    // if(!is_week_col){
    // }
    return this.ConvertToParseInt(dptTotal) == '0.00' ? '-' : this.ConvertToParseInt(dptTotal);
  }

  calBudgetOtherCost(data) {
    let dptTotal = 0;
    let that = this;
    _.forEach(data, function (val) {
      dptTotal = dptTotal + that.ConvertToInt(val);
    });

    return this.ConvertToParseInt(dptTotal);
  }

  calBudgetWeeklyTotal(week) {
    let dptTotal = 0;
    let that = this;
    _.forEach(this.staffingScheduleValue, function (dpt, key) {
      _.forEach(dpt, function (val, week_name) {
        if (week == week_name) {
          dptTotal = dptTotal + that.ConvertToInt(val) * that.ConvertToInt(that.budgetScheduleValue[key]);
        }
      });
    });

    return this.ConvertToParseInt(dptTotal);
  }

  calBudgetCostTotal() {
    let dptTotal = 0;
    let that = this;

    _.forEach(this.staffingScheduleData, function (phase) {
      _.forEach(phase['department'], function (dpt) {
        if (that.budgetScheduleValue['dept-' + dpt['id']] != undefined) {
          dptTotal = dptTotal + that.ConvertToInt(that.calSchedulerDays(dpt['id']) * that.budgetScheduleValue['dept-' + dpt['id']]);
        }
      });
    });
    return this.ConvertToParseInt(dptTotal);
  }

  cellStaticUpdateBudget(key: any, data) {
    $('.' + key + '-td').removeClass('active');
    this.syncValue('budget-schedule', '', data);
  }

  calWeekBudget(weekWiseUsers = 0, perWeekCost = 0) {
    let Temp = this.ConvertToInt(weekWiseUsers) * this.ConvertToInt(perWeekCost);
    return (Temp != 0) ? this.ConvertToParseInt(Temp) : '';
  }

  getBudgetScheduleValue(type = '', subtype = '') {
    this.dataService.getListData('budgetScheduleValue', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype })
      .subscribe((success) => {
        this.budgetScheduleValue = success.data;
        //resolve();
      }, (rejected) => {

      });
  }

  calOtherTotal(column, data, is_week_col = false) {

    let dptTotal = 0;
    let that = this;
    _.forEach(data, function (item) {

      if (is_week_col) {
        if (item['data'] != undefined) {
          dptTotal = dptTotal + that.ConvertToInt(item['data'][column]);
        }
      } else {
        dptTotal = dptTotal + that.ConvertToInt(item[column]);
      }

    });

    return this.ConvertToParseInt(dptTotal) == ('0.00' || '0') ? '-' : this.ConvertToParseInt(dptTotal);
  }

  addOtherItem(subtype) {
    //this.costSummaryValue
    let newData = {
      'name': 'Item',
      'bit': '',
      'core': '',
      'ram': '',
      'data': {}
    };

    if (this.staffingScheduleOtherData[subtype] == '') {
      this.staffingScheduleOtherData[subtype] = [];
    }
    this.staffingScheduleOtherData[subtype].push(newData);
  }

  removeOtherItem(subtype, index) {
    this.staffingScheduleOtherData[subtype].splice(index, 1);
    this.syncValue('staffing-schedule', subtype, this.staffingScheduleOtherData[subtype]);
  }

  cellStaticUpdateScheduleOther(key: any, data, subtype) {
    $('.' + key + '-td').removeClass('active');
    this.syncValue('staffing-schedule', subtype, data);
  }

  calWeeklyTotal(week) {
    let dptTotal = 0;
    let that = this;
    _.forEach(this.staffingScheduleValue, function (dpt) {
      _.forEach(dpt, function (val, week_name) {
        if (week == week_name) {
          dptTotal = dptTotal + that.ConvertToInt(val);
        }
      });
    });

    return this.ConvertToParseInt(dptTotal);
  }

  calScheduledDaysTotal() {
    let dptTotal = 0;
    let that = this;
    _.forEach(this.staffingScheduleData, function (phase) {
      _.forEach(phase['department'], function (dpt) {
        dptTotal = dptTotal + that.ConvertToInt(that.calSchedulerDays(dpt['id']));
      });
    });

    return this.ConvertToParseInt(dptTotal);
  }

  calBidDaysTotal() {
    let dptTotal = 0;
    let that = this;
    _.forEach(this.staffingScheduleData, function (phase) {

      if (phase.type == 'asset' || phase.type == 'shot') {
        dptTotal = dptTotal + that.ConvertToInt(that.subTotalCost(phase.department, phase.type, phase.subtype));
      } else if (phase.type == 'pre') {
        dptTotal = dptTotal + that.ConvertToInt(that.subTotalPreProd(phase.department));
      } else {
        dptTotal = dptTotal + that.ConvertToInt(that.subTotalPost(phase.department));
      }

    });

    return this.ConvertToParseInt(dptTotal);
  }

  calSchedulerDays(dept_id) {
    let dptTotal = 0;
    let that = this;

    _.forEach(this.staffingScheduleValue['dept-' + dept_id], function (value) {
      if (value != 'type' && !Number.isNaN(that.ConvertToInt(value))) {
        dptTotal = dptTotal + that.ConvertToInt(value);
      }
    });
    //let rounded =  this.ConvertToParseInt((dptTotal*this.noOfDaysInWeek));
    //let fixed = rounded.toFixed(2);
    //return Number.parseFloat(fixed);
    return this.ConvertToParseInt((dptTotal * this.noOfDaysInWeek));
  }

  getStaffingScheduleData(type = '', subtype = '') {

    if (type == 'pre-production-breakdown' || type == 'post-production-breakdown') {

      //       this.dataService.getListData('staffingScheduleData', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype })
      //         .subscribe((success) => {
      //         this.preProdData = success.data.filter((ele)=>(ele.type=='pre'))[0];
      //         this.postProdData = success.data.filter((ele)=>(ele.type=='post'))[0];
      //         console.log(this.preProdData,this.postProdData)
      //       }, (rejected) => {

      //       });

      // } else {
      this.generateData();
    }
    this.dataService.getListData('staffingScheduleData', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype })
      .subscribe((success) => {

        if (subtype == 'main') {
          this.staffingScheduleData = success.data;
          this.staffingScheduleValue = success.weekValue;
          this.staffingScheduleWeekData = success.weekData;
        } else {
          this.staffingScheduleOtherData[subtype] = success.data
        }

      }, (rejected) => {

      });

    // }


  }

  getStaffingScheduleValue(type = '', subtype = '') {
    this.dataService.getListData('staffingScheduleValue', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype })
      .subscribe((success) => {

        this.staffingScheduleValue = success.data;

      }, (rejected) => {

      });
  }

  trackBystaffingWeekData(index, item) {
    return index;
  }

  cellStaticUpdateSchedule(key: any, data) {
    $('.' + key + '-td').removeClass('active');
    this.syncValue('staffing-schedule', 'main', data);
  }

  cellStaticUpdateScheduleDrop(key: any, deptID, data, event, subtype = 'main', is_other_data = false) {
    if (is_other_data) {
      data[deptID]['type'] = event;
    } else {
      data['dept-' + deptID]['type'] = event;
    }
    setTimeout(() => {
      $('.' + key + '-td').removeClass('active');
    }, 2);
    this.syncValue('staffing-schedule', subtype, data);
  }

  getTechSpecsValue(type = '', subtype = '') {

    this.dataService.getListData('techSpecsValue', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype })
      .subscribe((success) => {
        if (Object.keys(success.data).length > 0) {
          this.techSpecsValue = success.data;
        } else {
          this.techSpecsValue = [];
        }

      }, (rejected) => {

      });
  }

  cellStaticUpdateTechSpecs(key: any, data) {
    $('.' + key + '-td').removeClass('active');
    this.syncValue('tech-specs', '', data);
  }

  getAssumptionsValue(type = '', subtype = '') {

    this.dataService.getListData('assumptionsValue', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype })
      .subscribe((success) => {
        if (Object.keys(success.data).length > 0) {
          this.assumptionsValue = success.data;
        } else {
          this.assumptionsValue = [];
        }

      }, (rejected) => {

      });
  }

  addAssumptions() {
    //this.costSummaryValue
    let newData = { 'name': 'Item' };
    this.assumptionsValue.push(newData);
  }

  addTechSpecs() {
    //this.costSummaryValue
    let newData = { 'name': 'Item' };
    this.techSpecsValue.push(newData);
  }

  removeAssumptions(index) {
    this.assumptionsValue.splice(index, 1);
    this.syncValue('assumptions', '', this.assumptionsValue);
  }

  removeTechSpecs(index) {
    this.techSpecsValue.splice(index, 1);
    this.syncValue('tech-specs', '', this.techSpecsValue);
  }

  cellStaticUpdateAssumptions(key: any, data) {
    $('.' + key + '-td').removeClass('active');
    this.syncValue('assumptions', '', data);
  }

  getCostSummaryData() {

    this.dataService.getListData('costSummaryData', { 'project_id': this.projectId, 'version': this.where.version['id'] })
      .subscribe((success) => {
        this.costSummaryData = success.data;

      }, (rejected) => {

      });
  }

  getCostSummaryValue(type = '', subtype = '') {

    this.dataService.getListData('costSummaryValue', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype })
      .subscribe((success) => {
        this.costSummaryValue = success.data;

      }, (rejected) => {

      });
  }

  cellStaticUpdateCost(key: any, data) {
    $('.' + key + '-td').removeClass('active');
    this.syncValue('cost-summary', '', data);
  }

  subTotalCost(department, type, subtype, is_total_cost_col = false) {
    let dptTotal = 0;
    let temp;
    let that = this;
    _.forEach(department, function (value) {
      if (type == 'asset') {
        if (is_total_cost_col) {
          dptTotal = dptTotal + that.calTotalCostUnit(that.costSummaryValue['per-day-cost-' + value['id']]) * that.ConvertToInt(that.columnTotal(value['id'], true, ((subtype == 'Character') ? 'characters-breakdown' : ((subtype == 'Set') ? 'sets-breakdown' : 'props-breakdown'))));
        } else {
          dptTotal = dptTotal + that.ConvertToInt(that.columnTotal(value['id'], true, ((subtype == 'Character') ? 'characters-breakdown' : ((subtype == 'Set') ? 'sets-breakdown' : 'props-breakdown'))));
        }

      } else if (type == 'shot') {
        let v02Val = that.shotProDeptValue[value['id']]['v02'];
        let v03Val = that.shotProDeptValue[value['id']]['v03'];
        let calTotalScopeShot = that.calTotalScope(that.shotProDeptValue[value['id']], value['type']);
        temp = that.ConvertToInt(calTotalScopeShot / that.shotProDeptValue[value['id']]['output']);
        if (v02Val != undefined && v02Val != 0 && that.shotProDeptValue[value['id']]['v02-output'] != undefined && that.shotProDeptValue[value['id']]['v02-output'] != 0) {
          temp = temp + (that.ConvertToInt(calTotalScopeShot * (v02Val / 100)) / that.ConvertToInt(that.calRetakesOutput(that.shotProDeptValue[value['id']]['v02-output'])));
        }
        temp = that.convertToFixed(temp);

        if (v03Val != 0 && v03Val != undefined && v03Val != undefined && v03Val != 0 && that.shotProDeptValue[value['id']]['v03-output'] != undefined && that.shotProDeptValue[value['id']]['v03-output'] != 0) {
          let v03Output = that.ConvertToInt(that.calRetakesOutput(that.shotProDeptValue[value['id']]['v03-output']));
          temp = that.convertToFixed(temp) + that.convertToFixed((calTotalScopeShot * (v02Val / 100) * (v03Val / 100)) / v03Output);
        }
        if (!Number.isNaN(temp)) {
          if (is_total_cost_col) {
            dptTotal = dptTotal + that.calTotalCostUnit(that.costSummaryValue['per-day-cost-' + value['id']]) * (temp);
          } else {
            dptTotal = dptTotal + temp;
          }
        }
      }
    });
    this.costSummarySubTotal['subTotalCost' + subtype + ((is_total_cost_col) ? 'Total' : '')] = this.ConvertToParseInt(dptTotal);
    return this.ConvertToParseInt(dptTotal);
  }

  calPreProdDeptDays(department_id) {
    let dptTotal = 0;
    dptTotal = this.ConvertToInt(dptTotal) + this.ConvertToInt(this.columnTotal(department_id, true, 'pre-production-breakdown'));
    dptTotal = this.ConvertToInt(dptTotal) + this.ConvertToInt(this.columnTotal(department_id, true, 'post-production-breakdown'));
    dptTotal = this.ConvertToInt(dptTotal) + this.ConvertToInt(this.columnTotal(department_id, true, 'characters-breakdown'));
    dptTotal = this.ConvertToInt(dptTotal) + this.ConvertToInt(this.columnTotal(department_id, true, 'sets-breakdown'));
    dptTotal = this.ConvertToInt(dptTotal) + this.ConvertToInt(this.columnTotal(department_id, true, 'props-breakdown'));
    return dptTotal;
  }

  subTotalPreProd(department, is_total_cost_col = false) {
    let dptTotal = 0;
    let dptTotal2 = 0;
    let that = this;
    _.forEach(department, function (value) {
      if (is_total_cost_col) {
        dptTotal = dptTotal + that.ConvertToInt(that.calPreProdDeptDays(value['id']) * that.calTotalCostUnit(that.costSummaryValue['per-day-cost-' + value['id']]));
        that.costSummarySubTotal['subTotalPreProdTotal'] = dptTotal;
      } else {
        dptTotal2 = dptTotal2 + that.calPreProdDeptDays(value['id']);
        that.costSummarySubTotal['subTotalPreProd'] = dptTotal2;
      }
    });
    if (is_total_cost_col) {
      return this.ConvertToParseInt(dptTotal);
    } else {
      return this.ConvertToParseInt(dptTotal2);
    }

  }

  subTotalPost(department, is_total_col = false) {
    let dptTotal = 0;
    let dptTotal2 = 0;
    let that = this;
    _.forEach(department, function (value) {
      if (is_total_col) {
        dptTotal = dptTotal + that.ConvertToInt(that.costSummaryValue[value['id']]) * that.calTotalCostUnit(that.costSummaryValue['per-day-cost-' + value['id']]);
        that.costSummarySubTotal['subTotalPostTotal'] = dptTotal;
      } else {
        dptTotal2 = dptTotal2 + that.ConvertToInt(that.costSummaryValue[value['id']]);
        that.costSummarySubTotal['subTotalPost'] = dptTotal2;
      }
    });

    if (is_total_col) {
      return this.ConvertToParseInt(dptTotal);
    } else {
      return this.ConvertToParseInt(dptTotal2);
    }

    //this.costSummarySubTotal['subTotalPost'+((is_total_col) ? 'Total' : '')] = this.ConvertToParseInt(dptTotal);
    //return this.ConvertToParseInt(dptTotal);
  }

  grandTotalCost() {
    let temp = 0;
    temp = (this.ConvertToInt(this.costSummaryValue['render-days'])
      + this.ConvertToInt(this.costSummaryValue['storage-days']))
      + (this.ConvertToInt(this.costSummarySubTotal['subTotalPreProd'])
        + this.ConvertToInt(this.costSummarySubTotal['subTotalCostCharacter'])
        + this.ConvertToInt(this.costSummarySubTotal['subTotalCostSet'])
        + this.ConvertToInt(this.costSummarySubTotal['subTotalCostProp'])
        + this.ConvertToInt(this.costSummarySubTotal['subTotalCostShot'])
        + this.ConvertToInt(this.costSummarySubTotal['subTotalPost']));
    if (this.costSummaryValue['miscell'] != undefined && Object.keys(this.costSummaryValue['miscell']).length > 0) {
      temp = temp + this.ConvertToInt(this.calMiscellaneous());
    }
    return temp.toFixed(2);
  }

  grandTotalofTotalCost() {
    let costInRS = this.ConvertToInt(this.renderValue['cost-in-rs']);
    let temp = 0;
    temp = (this.ConvertToInt(this.totalMonthNodes() * costInRS)
      + this.ConvertToInt(
        this.ConvertToInt(this.totalStorageRequirement()) *
        (this.ConvertToInt(this.renderValue['storage-pick-time']) / 100)) *
      this.ConvertToInt(this.storageValue['storage-cost']) *
      this.ConvertToInt(this.storageValue['production-duration']))
      + (this.ConvertToInt(this.costSummarySubTotal['subTotalPreProdTotal'])
        + this.ConvertToInt(this.costSummarySubTotal['subTotalCostCharacterTotal'])
        + this.ConvertToInt(this.costSummarySubTotal['subTotalCostSetTotal'])
        + this.ConvertToInt(this.costSummarySubTotal['subTotalCostPropTotal'])
        + this.ConvertToInt(this.costSummarySubTotal['subTotalCostShotTotal'])
        + this.ConvertToInt(this.costSummarySubTotal['subTotalPostTotal']));

    if (this.costSummaryValue['miscell'] != undefined && Object.keys(this.costSummaryValue['miscell']).length > 0) {
      temp = temp + this.ConvertToInt(this.calMiscellaneous(true));
    }
    return this.ConvertToParseInt(temp);
  }

  addMiscell() {
    //this.costSummaryValue
    let newData = { 'name': 'Item', 'days': 0, 'total-cost': 0 };
    if (this.costSummaryValue['miscell'] == undefined) {
      this.costSummaryValue['miscell'] = [];
    }
    this.costSummaryValue['miscell'].push(newData);
  }

  removeMiscell(index) {
    this.costSummaryValue['miscell'].splice(index, 1);
    this.syncValue('cost-summary', '', this.costSummaryValue);
  }

  calMiscellaneous(is_cost_col = false) {

    let dptTotal = 0;
    let that = this;
    _.forEach(this.costSummaryValue['miscell'], function (value) {
      if (is_cost_col) {
        dptTotal = dptTotal + that.ConvertToInt(value['days']) * that.calTotalCostUnit(value['total-cost']);
      } else {
        dptTotal = dptTotal + that.ConvertToInt(value['days']);
      }
    });

    this.costSummarySubTotal['miscellaneous' + ((is_cost_col) ? 'Total' : 'Days')] = this.ConvertToParseInt(dptTotal);
    return this.ConvertToParseInt(dptTotal);
  }

  calTotalCostUnit(individual_val) {
    if (individual_val == undefined) {
      return 0;
    } else {
      return individual_val;
    }
  }

  getRenderStorageValue(type = '', subtype = '') {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getListData('renderStorageBidValue', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype })
        .subscribe((success) => {
          if (subtype == 'render') {
            this.renderValue = success.data;
          }

          if (subtype == 'storage') {
            this.storageValue = success.data;
          }
          resolve();
        }, (rejected) => {

        });
    });
  }

  cellStaticUpdateRender(key: any, data) {
    $('.' + key + '-td').removeClass('active');
    this.syncValue('render-storage', 'render', data);
  }

  cellStaticUpdateStorage(key: any, data) {
    $('.' + key + '-td').removeClass('active');
    this.syncValue('render-storage', 'storage', data);
  }

  totalStorageRequirement() {
    let shotProSize = this.ConvertToParseInt(this.totalShots() * this.storageValue['avg-size']);
    let typeTotal = ((this.columnTotal('char-count', false, 'characters-breakdown') * this.renderValue['size-char']) * (1 + (this.renderValue['retake-char'] / 100))) + ((this.columnTotal('char-count', false, 'sets-breakdown') * this.renderValue['size-sets']) * (1 + (this.renderValue['retake-sets'] / 100))) + ((this.columnTotal('char-count', false, 'props-breakdown') * this.renderValue['size-props']) * (1 + (this.renderValue['retake-props'] / 100)));

    return this.ConvertToParseInt(((this.ConvertToInt(shotProSize) + this.ConvertToInt(typeTotal)) / 1024));
  }

  totalShots() {
    return this.ConvertToParseInt(((this.shotProValue['duration-min'] * 60) / this.shotProValue['seconds-per-shot']));
  }

  totalFrmForRendering() {
    let v01FrmForRendering = (this.shotProValue['duration-min'] * 60) * this.shotProValue['frame-per-second'] * this.renderValue['layers-per-shot'];
    let retakeFrmForRendering = v01FrmForRendering * (this.renderValue['retake-percentage'] / 100);
    return this.ConvertToParseInt((this.ConvertToInt(v01FrmForRendering) + this.ConvertToInt(retakeFrmForRendering)));
  }

  totalMonthNodes(daysInMonth = this.renderValue['days-in-month']) {
    if (daysInMonth == undefined) {
      daysInMonth = 30;
    }
    let totalRenderingDays = ((this.totalFrmForRendering() * this.renderValue['render-time-per-frm']) / 60) / 24;
    let avgITDowntime = totalRenderingDays * (this.renderValue['avg-downtime'] / 100);
    this.totalMonthNodesVar = this.ConvertToParseInt((this.ConvertToInt(totalRenderingDays) + this.ConvertToInt(avgITDowntime)) / daysInMonth);
    //return this.ConvertToParseInt((this.ConvertToInt(totalRenderingDays)+this.ConvertToInt(avgITDowntime))/daysInMonth);
    return this.totalMonthNodesVar;
  }

  totalMonthNodesForStreoRen() {
    this.totalMNFSR = this.totalMonthNodesVar * (this.renderValue['STEREO-render'] / 100);
    return this.totalMNFSR.toFixed(2);
  }

  //To generate Shot-production structure
  generateShotProData() {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getListData('shortProductionBidData', { 'where': this.where })
        .subscribe((success) => {

          this.shotProData = success.data;
          this.shotProDeptValue = success.value;
          let that = this;
          _.forEach(this.shotProDeptValue, function (value, key) {
            if (that.shotProDeptValue[key]['v01'] == undefined) {
              that.shotProDeptValue[key]['v01'] = 100;
            }
          });

          resolve();
        }, (rejected) => {

        });

    });
  }

  //
  calPerDayTotal(dept) {
    let vOutput = this.shotProDeptValue[dept.department_id]['output'];
    let v02Output = this.shotProDeptValue[dept.department_id]['v02-output'];
    let v02Val = this.shotProDeptValue[dept.department_id]['v02'];
    let v03Output = this.shotProDeptValue[dept.department_id]['v03-output'];
    let v03Val = this.shotProDeptValue[dept.department_id]['v03'];
    var calTotalScope = this.calTotalScope(this.shotProDeptValue[dept.department_id], dept.type);
    var temp2 = (vOutput != 0 && vOutput != undefined) ? (calTotalScope / vOutput) : 0;

    if (v02Val != undefined && v02Output != undefined && v02Output != 0) {
      temp2 = temp2 + ((calTotalScope * (this.shotProDeptValue[dept.department_id]['v02'] / 100)) / this.calRetakesOutput(v02Output));
    }

    if (v02Val != undefined && v02Val != 0 && v03Val != undefined && v03Output != undefined && v03Output != 0) {
      temp2 = temp2 + ((calTotalScope * (v02Val / 100) * (v03Val / 100)) / this.calRetakesOutput(v03Output));
    }
    return calTotalScope / temp2;
  }

  calPerDayGrandTotal(dept) {
    let calTotalScope = this.calTotalScope(this.shotProDeptValue[dept.department_id], dept.type);
    var temp = (calTotalScope / this.shotProDeptValue[dept.department_id]['output']);
    let v02Output = this.shotProDeptValue[dept.department_id]['v02-output'];
    let v03Output = this.shotProDeptValue[dept.department_id]['v03-output'];
    let v2Val = this.shotProDeptValue[dept.department_id]['v02'];
    let v3Val = this.shotProDeptValue[dept.department_id]['v03']
    if (v2Val != undefined && v02Output != undefined && v02Output != 0) {
      let v02RetakeOutputVal = this.calRetakesOutput(v02Output);
      temp = temp + ((calTotalScope * (this.ConvertToInt(v2Val) / 100)) / this.calRetakesOutput(v02Output));
    }

    if (v2Val != undefined && v3Val != undefined && v03Output != undefined && v03Output != 0) {
      temp = temp + this.convertToFixed((calTotalScope * (this.ConvertToInt(v2Val) / 100) * (this.ConvertToInt(v3Val) / 100)) / this.calRetakesOutput(v03Output));
    }

    return temp;
  }

  calPerDayGrandTotalOtherTab(dept, type = '') {

    let totalScope = this.calTotalScope(this.shotProDeptValue[dept.id], dept.type);
    if (totalScope != undefined && !isNaN(totalScope) && this.shotProDeptValue[dept.id]['output'] != undefined) {
      var temp = (totalScope / this.shotProDeptValue[dept.id]['output']);
    } else {
      var temp = 0;
    }
    temp = this.ConvertToInt(temp);

    let v02Val = this.shotProDeptValue[dept.id]['v02'];
    if (v02Val != undefined && this.shotProDeptValue[dept.id]['v02-output'] != undefined) {
      let v02Output = this.ConvertToInt(this.calRetakesOutput(this.shotProDeptValue[dept.id]['v02-output']));
      temp = temp + ((totalScope * (v02Val / 100)) / v02Output);
      temp = this.ConvertToInt(temp);
    }
    if (this.shotProDeptValue[dept.id]['v03'] != undefined && this.shotProDeptValue[dept.id]['v03'] != '' && this.shotProDeptValue[dept.id]['v03-output'] != undefined && this.shotProDeptValue[dept.id]['v03-output'] != '') {

      let v3Output = this.calRetakesOutput(this.shotProDeptValue[dept.id]['v03-output']);
      //let v3Val = this.ConvertToInt(this.shotProDeptValue[dept.id]['v03']);
      let v3Val = this.shotProDeptValue[dept.id]['v03'];
      if (type == 'shot' && v02Val != undefined && v02Val != 0) {
        temp = this.convertToFixed(temp) + this.convertToFixed(((totalScope * (v02Val / 100) * (v3Val / 100)) / v3Output));
      } else {
        temp = temp + ((totalScope * (v3Val / 100)) / v3Output);
      }
    }
    return this.ConvertToInt(temp);
  }

  //get values for Shot-production
  getShotProValue(type = '', subtype = '') {
    return new Promise<void>((resolve, reject) => {
      this.dataService.getListData('shortProductionBidValue', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype })
        .subscribe((success) => {
          this.shotProValue = success.data;
          resolve();
        }, (rejected) => {

        });
    });
  }

  cellSPClick(data: any, column, is_type = false) {
    // if(column == 'type'){
    //     $('.'+column+'-'+data+'-td').click();
    //     $('.'+column+'-'+data+'-td').click();
    // }
    if (this.isAddPermission) {
      $('.cursor-pointer').removeClass('active');
      $('.' + column + '-' + data + '-td').addClass('active');
      if (!is_type) {
        $('.' + column + '-' + data + '-input input').focus();
        $('.' + column + '-' + data + '-input input').click();
      }
    }

  }

  cellSPUpdate(data: any, column, level, is_type = false, di = '', event = {}): any {
    $('.' + column + '-' + data + '-td').removeClass('active');
    if (is_type) {
      this.shotProData[di]['type'] = event['id'];
      level.type = event['id'];
    }
    this.syncValue('shot-production', data, level, column);
  }

  shotProductionBlur(data, column, fromDropdown = false) {
    if (!fromDropdown) {
    }
    //$('.'+column+'-'+data+'-td').removeClass('active');
    //$('.'+column+'-'+data+'-input input').click();
    $('.' + column + '-' + data + '-input input').blur();
  }

  cellStaticClick(key) {
    if (this.isAddPermission) {
      $('.cursor-pointer').removeClass('active');
      $('.' + key + '-td').addClass('active');
      $('.' + key + '-input input').focus();
    }

    //$('#'+key+'-editor-val').val(data[column]);
  }

  cellStaticUpdate(key: any, data) {
    $('.' + key + '-td').removeClass('active');
    this.syncValue('shot-production', 'main', data);
  }

  calRetakesOutput(number) {
    if (number != undefined && number != 0 && number != Infinity && !isNaN(number)) {
      return (this.ConvertToInt(number));
    } else {
      return 0;
    }
    //return (150*this.ConvertToInt(number))/100;
  }

  calVersionWiseManDays(calTotalScopeVal, v0Output) {
    if (v0Output != 0 && v0Output != undefined) {
      return this.ConvertToParseInt(calTotalScopeVal / v0Output);
    } else {
      return 0;
    }
  }

  calVersion03ManDays(dept, type, v0Output, v02Val = 0, v03Val = 0) {
    if (v0Output != 0 && v0Output != undefined && v03Val != 0 && v03Val != undefined) {
      let totalScope = this.calTotalScope(dept, type);
      return this.ConvertToParseInt((totalScope * (v02Val / 100) * (v03Val / 100)) / v0Output);
    } else {
      return 0;
    }
  }

  //Panel/Seconds/Shots calculation
  calTotalScope(data, type) {
    if (type == 'panel') {
      //(V1*Total Animation Duration (minutes))/100
      //return (this.shotProValue['duration-min']*90)*(data['v01']/100);
      return (this.shotProValue['duration-min'] * this.shotProValue['panel-per-minute']) * (data['v01'] / 100);
    } else if (type == 'seconds') {
      //(V1*Total Animation Duration (seconds))/100
      return (this.shotProValue['duration-min'] * 60) * (data['v01'] / 100);
    } else if (type == 'shots') {
      return ((this.shotProValue['duration-min'] * 60) / this.shotProValue['seconds-per-shot']) * (data['v01'] / 100);
    }

    /*}*/
  }

  //generate structure for Asset Build
  generateData() {
    this.dataService.getListData('assetBuildBidData', { 'where': this.where })
      .subscribe((success) => {
        this.preProdData = success.data.pre;
        this.postProdData = success.data.post;

        this.charData = success.data.char;
        this.setData = success.data.set;
        this.propData = success.data.prop;

      }, (rejected) => {

      });
  }

  //get values by type and subtype
  getValue(type = '', subtype = '') {

    this.dataService.getListData('assetBuildBidValue', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype })
      .subscribe((success) => {

        //this.value[type] = success.data;

        let that = this;
        _.forEach(success.data.value, function (value) {

          const index = that.complexity.findIndex((col) => {
            return col.id == value['subtype'];
          });

          if (index !== -1) {
            that.complexity[index][type] = value['data'];
          }

        });
      }, (rejected) => {

      });
  }

  // To check if its NaN or not
  checkIfNan(element) {
    if (isNaN(element)) {
      return true;
    } else {
      return false;
    }
  }


  //Click event on cell
  cellClick(data: any, column, type) {
    if (this.isAddPermission) {
      $('.cursor-pointer').removeClass('active');
      $('.' + type + '-' + column + '-' + data + '-td').addClass('active');
      $('.' + type + '-' + column + '-' + data + '-input input').focus();
      $('#' + type + '-' + column + '-' + data + '-editor-val').val(data[column]);
    }

  }

  //update cell by input value
  cellUpdate(column: any, level, is_dpt_column: true, type): any {
    $('.' + type + '-' + this.slugify(level.id) + '-' + column + '-td').removeClass('active');
    let rowTotal = this.rowTotal(level, type);
    $('.' + type + '-' + this.slugify(level.id) + '-total-artist-days-td').html(rowTotal);
    $('.' + type + '-' + column + '-total-artist-days-td').html(this.columnTotal(column, is_dpt_column, type));
    let that = this;
    _.forEach(level[type], function (value, key) {
      level[type][key] = that.convertToFixed(value);
    });
    this.syncValue(type, level.id, level[type]);
  }

  //Sync value in database
  syncValue(type = '', subtype = '', data: any = '', column = '') {
    this.dataService.storeData('projectOptionSync', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype, 'data': data })
      .subscribe((success) => {
        if (type == 'shot-production' && column == 'type') {
          $('.type-' + subtype + '-td').removeClass('active');
        }

      }, (rejected) => {

      });
  }

  //Asset Build - Total for perticular row
  rowTotal(level, type) {
    let preDeptTotal = 0;
    let postDeptTotal = 0;

    let dptTotal = 0;
    let dptTotalSet = 0;
    let dptTotalProp = 0;
    let that = this;
    if (type == 'characters-breakdown') {
      _.forEach(that.charData, function (value) {
        dptTotal = dptTotal + that.ConvertToInt(level[type][value['department_id']]);
      });
    } else if (type == 'sets-breakdown') {
      _.forEach(that.setData, function (value) {
        dptTotalSet = dptTotalSet + that.ConvertToInt(level[type][value['department_id']]);
      });
    } else if (type == 'props-breakdown') {
      _.forEach(that.propData, function (value) {
        if (level[type][value['department_id']] != undefined && !isNaN(level[type][value['department_id']])) {
          dptTotalProp = dptTotalProp + that.ConvertToInt(level[type][value['department_id']]);
        }
      });
    } else if (type == 'pre-production-breakdown') {
      _.forEach(that.preProdData, function (value) {
        preDeptTotal = preDeptTotal + that.ConvertToInt(level[type][value['department_id']]);
      });

    } else if (type == 'post-production-breakdown') {

      _.forEach(that.postProdData, function (value) {
        postDeptTotal = postDeptTotal + that.ConvertToInt(level[type][value['department_id']]);
      });

    }

    if (type == 'characters-breakdown') {

      level[type]['total-artist-days'] = this.ConvertToParseInt(dptTotal * this.ConvertToInt(level[type]['char-count']));
      return level[type]['total-artist-days'];

    } else if (type == 'sets-breakdown') {

      level[type]['total-artist-days'] = this.ConvertToParseInt(dptTotalSet * this.ConvertToInt(level[type]['char-count']));
      return level[type]['total-artist-days'];

    } else if (type == 'props-breakdown') {

      if (!isNaN(this.ConvertToInt(level[type]['char-count']))) {
        level[type]['total-artist-days'] = this.ConvertToParseInt(dptTotalProp * this.ConvertToInt(level[type]['char-count']));
        return level[type]['total-artist-days'];
      }

    } else if (type == 'pre-production-breakdown') {

      level[type]['total-artist-days'] = this.ConvertToParseInt(preDeptTotal * this.ConvertToInt(level[type]['char-count']));
      return level[type]['total-artist-days'];

    } else if (type == 'post-production-breakdown') {

      level[type]['total-artist-days'] = this.ConvertToParseInt(postDeptTotal * this.ConvertToInt(level[type]['char-count']));
      return level[type]['total-artist-days'];

    }

  }

  //Asset Build - Column total operation
  columnTotal(column, is_dpt_column = true, type) {
    let dptTotal = 0;
    let that = this;
    _.forEach(that.complexity, function (value) {
      if (is_dpt_column) {
        dptTotal = dptTotal + (that.ConvertToInt(value[type][column]) * that.ConvertToInt(value[type]['char-count']));
      } else {
        dptTotal = dptTotal + that.ConvertToInt(value[type][column]);
      }
    });

    return this.ConvertToParseInt(dptTotal);
  }

  calTNSum(num1, num2) {
    return this.ConvertToParseInt(this.ConvertToInt(num1) + this.ConvertToInt(num2));
  }

  slugify(str) {
    return str.replace(/ /g, "-").toLowerCase();
  }

  ConvertToInt(val) {
    if (val != undefined) {
      //Number.parseFloat(val);
      return Number(val);
    } else {
      return 0;
    }
  }

  ConvertToParseInt(val) {
    if (val != undefined) {
      if (val !== val) {
        return 0;
      } else {
        return val.toFixed(2);
      }
    } else {
      return 0;
    }
  }

  convertToFixed(val) {
    return Number(parseFloat(val).toFixed(2))
  }

  convertToFixedWithoutRound(val) {
    if (val != undefined && Number(val) != 0 && !isNaN(val) && val != Infinity) {
      return Number(val.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]);
    } else {
      return 0;
    }
  }

   // on user event
   onUserEvent(event, importTemplate:TemplateRef<any>){
        if (event.type == "click") {
          const row = event.row;
          const column = event.column;

          //  redirect to simple/advanced version
          if(column.prop=='version'){
            this.simpleBidValue = row;
            this.openedPage = 'simple';
          }
        }
   }

   onPageChange(e, tableNumber): void {

   }

   // To open add bidding pop-up
   addBidding(template:TemplateRef<any>){
    this.addbidtype = this.importtype = '';
    this.bsModalRef = this.modalService.show(template, { class: 'modal-lg' });
   }

  public dropped(files: NgxFileDropEntry[]) {
   this.files = files;
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {

        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          let eventLastValue: any = [];
          eventLastValue.push(file);
          // this.uploadedFileInfo.wsname = eventLastValue[0].name;
          // console.log(this.uploadedFileInfo);

          // Here you can access the real file
          //console.log(droppedFile.relativePath, file);

          var reader = new FileReader();
          this.file_name = file.name;
          // this.uploadedFileInfo = file.name
          this.file_type = file.type;

          reader.onload = (event: any) => {
            this.url = event.target.result;

            // console.log(files,this.file_type)
            // if(this.file_type!='application/vnd.ms-excel' && this.file_type!='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
            //   return;
            // }

            // if (this.file_type != 'image/png' && this.file_type != 'image/jpg' && this.file_type != 'image/jpeg' && this.file_type != 'image/gif') {
            //   this.preview_src = './assets/images/default/attechment.png';
            // } else {
              this.preview_src = this.url;
              // this.uploadedFileInfo.wsname = eventLastValue[0].name;
              this.uploadedFileInfo.preview_src = './assets/images/default/attechment.png';
              this.uploadedFileInfo.wsname = eventLastValue[0].name;
              this.uploadedFileInfo.istrue = true;

            // }
          }
          reader.readAsDataURL(file);
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        //console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }

  public fileOver(event) {
    // //console.log(event);
  }

  public fileLeave(event) {
    // //console.log(event);
  }


  unlinkImage(event) {
    event.stopPropagation();
      this.uploadedFileInfo.wsname = '';
      this.uploadedFileInfo.preview_src = '';
      this.uploadedFileInfo.istrue = false;
      this.data = [];

  }

  // To download template file
  downloadFile() {
    this.dataService.storeData(this.apiRoutePrefix + 'download', { type: this.currentEntity })
    .subscribe((response) => {
      if (response.data != '') {
        let appLocation = window.location.origin;
        let fileName = '';

        if (response.entity == 'bids') {
          fileName = 'Import_Bids.xls';
        }

        if (appLocation == "https://app.vanisoftware.com" || appLocation == "https://staging.vanisoftware.com" || appLocation == "http://app.vanisoftware.com" || appLocation == "http://app.vanisoftware.com" || appLocation == "https://app.vanisoftware.com") {
          window.location.href = response.data;
        } else if (appLocation == 'http://127.0.0.1:4200' || appLocation=='http://localhost:4200') {
          let host = window.location.host;
          // window.open('http://' + host + '/vani-offline/public/import/Template/' + fileName, 'Download');
          window.location.href = response.data;
        } else {
          //window.location.href = response.data;
          let host = window.location.host;
          window.open('http://' + host + '/vani-offline/public/import/Template/' + fileName, 'Download');
        }
      }
    }, (error) => {
      this.toastrService.notify('warn', '', 'Sorry..Try Again.');
    });
  }

  // To empty file when bidding changed
  biddingTypeChanged(){
    this.importtype = '';
    this.uploadedFileInfo = {};
  }

  // To close simple bid & coming back to bid-history
  closeSimpleBidding(){
    this.openedPage = 'bidHistory';
  }

  // To close advanced bid & coming back to bid-history
  goBackToBidHistory(){
    this.openedPage = 'bidHistory';
  }


  // To get bidding history data
  getBiddingHistory(type = '', subtype = '', data: any = '', column = ''){
        let simpleBidConfig = {
                                where : { 'project_id': this.projectId },
                                type: type
                              };

    // this.dataService.storeData('projectOptionSync', { 'project_id': this.projectId, 'version': this.where.version['id'], 'type': type, 'subtype': subtype, 'data': data }).subscribe(
    this.dataService.storeData('simpleBiddingList', simpleBidConfig).subscribe(
      (success)=>{
        // console.log(success);
        this.biddingRows = success.data.value;
        // this.biddingRows = [
        //   {"id":770,"project_id":150,"version":"V1","type":"simple-bidding","subtype":"shot","data":"a:2:{i:39;i:1;i:120;i:0;}","created_at":"2024-04-09T06:43:29.000000Z","updated_at":"2024-04-09T06:43:29.000000Z","total_bid_days":1},
        //   {"id":771,"project_id":150,"version":"V1","type":"simple-bidding","subtype":"asset","data":"a:3:{i:9;i:0;i:12;i:0;i:14;i:0;}","created_at":"2024-04-08T14:10:58.000000Z","updated_at":"2024-04-08T14:10:58.000000Z","total_bid_days":0},
        //   {"id":772,"project_id":150,"version":"V2","type":"simple-bidding","subtype":"asset","data":"a:3:{i:9;i:0;i:12;i:0;i:14;i:0;}","created_at":"2024-04-08T14:10:58.000000Z","updated_at":"2024-04-08T14:10:58.000000Z","total_bid_days":0}
        // ];
      },
      (error)=>{
        console.log(error);
        this.biddingRows = [];
      });
  }

  // To add bidding data
  onSubmit(form: NgForm){

    if(this.addbidtype=='advanced'){
      this.openedPage = 'advanced';
    }else if(this.addbidtype=='simple'){

      this.importSimpleBidding();
      // this.openedPage = 'simple';

    }

    this.bsModalRef.hide();
  }

  // To import simple bidding file
  importSimpleBidding() {

    let importConfig =  {
      'project_id' : this.projectId,
      type: 'simple-bidding',
      subtype: this.importtype,
      file_name: this.uploadedFileInfo.wsname,
      // image_upload: false,
      // is_image_upload: false,
      // url:this.url
      file:this.url
    };

    this.dataService.storeData('import/importSimpleBidding', importConfig).subscribe(
      (success)=>{
        //  this.openedPage = 'simple';
        // console.log(success);
        this.toastrService.notify(success.status, '', success.message);
        this.getBiddingHistory('simple-bidding');
      },(error)=>{

      });
  }

}
