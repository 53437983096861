import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../../services/data.service';
import { ToastrService } from '../../services/toastr.service';
import { CommonJs } from '../common-js';
import { environment } from '../../../environments/environment';
import * as _ from "lodash";
declare var $: any;
import { Subscription } from 'rxjs';
@Component({
  selector: 'project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.css'],
  host: {
    "(window:click)": "onClick()"
  }
})

export class ProjectHeaderComponent implements OnInit {
  apiRoutePrefix = 'projects';
  role = (localStorage.getItem('role') != '' && localStorage.getItem('role') != undefined) ? localStorage.getItem('role') : 'superadmin';
  client_code = (localStorage.getItem('client_code') != '' && localStorage.getItem('client_code') != undefined) ? localStorage.getItem('role') : 'superadmin';
  isProduction: boolean = true;
  st = localStorage.getItem('standalone');
  userData = JSON.parse(localStorage.getItem('user'));

  @Output() logout = new EventEmitter();
  @Output() showProjects = new EventEmitter();
  @Output() projectSidebarToggle = new EventEmitter();
  @ViewChild('multiSelect', { static: false }) multiSelect;

  isProjectHeader: boolean = false;
  isProjectPlanning: boolean = false;

  //project-details
  ProjectName: any;
  projectData: any = JSON.parse(localStorage.getItem('localStorageProjectData'));
  projectId: string;

  isEpisodicSeries: boolean = false;
  noRecordFound: boolean = false;
  noRecordFoundMsg: any = 'Sorry, no records found!';
  openRightSidebar: boolean = false;
  dirName = 'projects';
  @Input() projectSidebarToggleVal: boolean = false;

  urlParam = this.router.url.split("/");
  projectPer = {};

  projectroles = {};
  projectrolestype = {};

  checklistData = [];
  display_label_count: boolean = false;

  projectNotiData = [];
  projectNotiCount = {};
  private project_polling = {};

  studioNotiData = [];

  clientCode = localStorage.getItem('access_code');
  userId = localStorage.getItem('userId');
  subscribeisUserTasksList: Subscription;
  //subscribeisUserTasksList: Subject<any> = new Subject<any>();
  studioType: any;
  env = environment;

  message;

  isProjectReportPermission: boolean = false;

  subscribeGeneralActivityTaskActive;
  changeArtistProjectTaskStatusSub;
  pauseGeneralTask;

  isViewPermission = [];
  isReportViewPermission = [];
  clientCodePrefix = "";
  uploadUrl = this.commonJs.getUploadUrl();

  studioNotificationCount;

  permissionChangedSub: Subscription;

  isExternalReview: boolean = false;

  currentProject: string;

  getprojects: any = [];
  parentProjectId: any;
  isClassVisible = false;

  visiblePhases:any[] = [];
  phasesExist = {};

  constructor(
    public router: Router,
    public commonJs: CommonJs,
    private dataService: DataService,
    private cd: ChangeDetectorRef
  ) {
    this.projectId = this.urlParam[5] ? this.urlParam[5] : this.router.url.split("/")[5];
  }

  ngOnInit() {
    // setTimeout(() => {
      // this.projectId = this.urlParam[5];
    // }, 500);
    this.currentProject = this.projectId;

    if(localStorage.getItem('phasesExist')){
      this.phasesExist = JSON.parse(localStorage.getItem('phasesExist'));
      // console.log(this.phasesExist);
    }else if(this.commonJs.phasesExist){
      this.phasesExist = this.commonJs.phasesExist;
    }else{
      this.phasesExist = { pre:1, character:1, set:1, prop:1, shot:1, post:1 };
    }

    this.projectroles = JSON.parse(localStorage.getItem('projectRoles'));
    this.projectrolestype['project_' + this.projectId] = JSON.parse(localStorage.getItem('projectRoleType'));
    this.isProjectHeader = _.includes(this.router.url, 'projects');
    this.isProjectPlanning = _.includes(this.router.url, 'planning');
    this.ProjectName = this.projectData.name;
    this.isEpisodicSeries = this.projectData.episodic_series;
    this.isExternalReview = this.projectData.is_external_review;
    this.dataService.setProjectData$.subscribe(item => {
      this.getProjectsById(item);
    });
    this.userPermission();
  }

  // Get permission
  userPermission() {
    this.permissionChangedSub = this.dataService.isProjectPermissionChanged$.subscribe(type => {
      this.isProjectReportPermission = this.commonJs.checkAccess('tasks', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.clientCodePrefix = this.commonJs.getClientCodePrefix();
      this.isViewPermission['tasks'] = this.commonJs.checkAccess('tasks', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['assets'] = this.commonJs.checkAccess('assets', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['seasons'] = this.commonJs.checkAccess('seasons', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['episodes'] = this.commonJs.checkAccess('episodes', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['sequences'] = this.commonJs.checkAccess('sequences', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['shots'] = this.commonJs.checkAccess('shots', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['bidding'] = this.commonJs.checkAccess('bidding', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['scripts'] = this.commonJs.checkAccess('scripts', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['project_settings'] = this.commonJs.checkAccess('project_settings', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isViewPermission['task_comments'] = this.commonJs.checkAccess('task_comments', 'view', { 'baiPass': ['admin'] }, this.projectId);
      this.isReportViewPermission['operational_report'] = this.commonJs.checkAccess('operational_report', 'view', { 'baiPass': ['admin'] });
      this.studioNotificationCount = this.commonJs.getLocalStorage('studio_notification_count');
    });
  }



  getProjectsById(item) {
    this.projectId = item.id;
    this.projectData = item;
    this.ProjectName = this.projectData.name;
    this.isEpisodicSeries = this.projectData.episodic_series;
    this.isExternalReview = this.projectData.is_external_review;
    this.projectroles = JSON.parse(localStorage.getItem('projectRoles'));
    this.projectrolestype['project_' + item.id] = JSON.parse(localStorage.getItem('projectRoleType'));
    if (this.role != 'admin') {
      this.commonJs.getProjectPermission(item.id);
    }
  }

  ngOnChanges() {
  }

  onClick() {
    this.isClassVisible = false;
  }
  closeSidebar(projectSidebarToggleVal) {
    this.projectSidebarToggleVal = !this.projectSidebarToggleVal;
  }


  ngOnDestroy() {
    this.permissionChangedSub.unsubscribe();
  }


  setProjectId(id) {
    let route = this.router.url.split('/');
    let finalUrl = "/" + route[1] + '/' + route[2] + '/' + route[3] + '/' + 'dashboard'
    this.projectId = id;
    this.checkRouterUpdate(finalUrl, id).then((res: any) => {
      if (res) {
        this.userPermission();
      }
    }).catch((error: any) => {
    })
    if (this.role != 'admin') {
      this.commonJs.getProjectPermission(id);
    }
    this.currentProject = id;
    this.parentProjectId = id;
    this.isClassVisible = !this.isClassVisible;
  }

  checkRouterUpdate(url: any, id: any) {
    return new Promise((resolve, reject) => {
      this.router.navigate([url + '/' + id]);
      this.projectroles = JSON.parse(localStorage.getItem('projectRoles'));
      this.projectrolestype['project_' + this.projectId] = JSON.parse(localStorage.getItem('projectRoleType'));
      resolve(true);
    });
  }

  openDrodown(event) {
    event.stopPropagation();
    this.isClassVisible = !this.isClassVisible;
  }
}
