import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NumberDirective } from "./numbers-only.directive";

@NgModule({
    imports: [CommonModule],
    declarations: [NumberDirective],
    exports: [NumberDirective]
})

export class DirectivesModule { }