import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { AppConfigs } from '../../../common/app-configs';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

declare var $: any;

@Component({
	selector: 'app-profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

	@Output() closeSettings = new EventEmitter();
  role = localStorage.getItem('role');
	entityName = 'Profile';
	entityNameSingular = 'Profile';
	apiRoutePrefix = 'users';
	dirName = 'users';

	myform: any;
	errors: any;
	focused: string = '';
	f1Focused: string = '';
	SaveButtonText: string = 'SAVE';
	PasswordSaveButtonText: string = 'SAVE';
	userId = JSON.parse(localStorage.getItem('userId'));
	//userProfile:Users;
	userProfile: { [index: string]: any };
	current_password: string = '';
	password: string = '';
	confirm_password: string = '';

	url: any;
	preview_src: string = '';
	image_name: string;
	image_type: string;
	image_old: string;
	settings: any = JSON.parse(localStorage.getItem('settings'));

	public files: NgxFileDropEntry[] = [];

	constructor(private titleService: Title, private dataService: DataService, private toastrService: ToastrService, public AppConfigs: AppConfigs) { }

	ngOnInit() {
		this.userProfile = {
			id: this.userId,
			name: '',
			email: '',
			password: '',
			role_id: 0,
			status: 0
		}
		$.AdminBSB.input.activate();
		this.subngOnInit();
		this.titleService.setTitle(this.entityName);

	}

	subngOnInit() {
		this.focused = "focused";
		this.dataService.getDataById(this.apiRoutePrefix + '/' + this.userId + '/getProfile')
			.subscribe((success) => {
				this.userProfile.name = success.data.name;
				this.userProfile.email = success.data.email;
				this.userProfile.role_id = success.data.role_id;
				this.userProfile.status = success.data.status;
				this.userProfile.image = success.data.image;
				this.userProfile.image_display_name = success.data.image_display_name;
				this.userProfile.password = '';

				if (this.userProfile.image) {
					this.image_old = this.userProfile.image;
					let fileExtension = this.userProfile.image.split('.').pop();
					if (fileExtension != 'png' && fileExtension != 'jpg' && fileExtension != 'jpeg' && fileExtension != 'gif') {
						this.preview_src = './assets/images/default/attechment.png';
					} else {
						this.preview_src = this.AppConfigs.getUploadUrl() + this.dirName + '/' + this.userProfile.image;
					}
				}

				if (this.userProfile.image_display_name) {
					this.image_name = this.userProfile.image_display_name;
				}

			}, (error) => {
				var err = error.error;
				if (error.statusText == "Unauthorized") {
					this.toastrService.notify('warn', '', 'It seems your session is expired. Please login again.');
				}
			});
	}

	onSubmit(form: NgForm) {
    this.SaveButtonText = 'SAVING';

          if (this.url != undefined) {
              form.value.image = this.url;
          }
          form.value.image_name = this.image_name;
          form.value.image_old = this.image_old;
          form.value.profile = true;
          this.dataService.updateData(this.apiRoutePrefix + '/' + this.userId + '/saveProfile', form.value)
              .subscribe((success) => {
                  // Save effect within button
                  this.SaveButtonText = 'SAVED';
                  setTimeout(() => {
                      this.SaveButtonText = 'SAVE';
                  }, 2);
                  this.toastrService.notify('success', '', 'Profile updated successfully.');
                  var user = JSON.parse(localStorage.getItem('user'));
                  user.name = form.value.name;
                  user.image = success.data.user.image;
                  localStorage.setItem('user', JSON.stringify(user));
                  // localStorage.setItem('token', success.data.user.token);
                  // this.dataService.setHeaders();
                  this.dataService.setHeadersForFile();
                  if (success.data.image) {
                      this.image_old = success.data.image.image;
                  }

              }, (error) => {
                  if (error.error) {
                      var err = error.error;
                      this.errors = err.error;
                      this.SaveButtonText = "SAVE";
                  }
              });
    }

	savePassword(f1: NgForm) {
		this.PasswordSaveButtonText = 'SAVING';
		this.dataService.updateData(this.apiRoutePrefix + '/' + this.userId + '/changePassword', f1.value)
			.subscribe((success) => {
				// Save effect within button
				this.PasswordSaveButtonText = 'SAVED';
				f1.reset();
				this.f1Focused = 'focus';
				setTimeout(() => {
					this.PasswordSaveButtonText = 'SAVE';
				}, 2);
				this.toastrService.notify('success', '', 'Password changed successfully.');
			}, (error) => {
				if (error.error) {
					var err = error.error;
					this.errors = err.error;
				}
			});
	}

	unlinkImage() {
		this.preview_src = '';
		this.image_name = '';
		this.url = '';
	}

	close() {
		// this.userProfile.name = this.userProfile.email = this.image_name = this.image_type = '';
		this.files = [];
		this.closeSettings.emit();
	}

	public dropped(files: NgxFileDropEntry[]) {
		this.files = files;
		for (const droppedFile of files) {
			// Is it a file?
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {

					// Here you can access the real file
					//console.log(droppedFile.relativePath, file);

					var reader = new FileReader();
					this.image_name = file.name;
					this.image_type = file.type;

					reader.onload = (event: any) => {
						this.url = event.target.result;
						if (this.image_type != 'image/png' && this.image_type != 'image/jpg' && this.image_type != 'image/jpeg' && this.image_type != 'image/gif') {
							this.preview_src = './assets/images/default/attechment.png';
						} else {
							this.preview_src = this.url;
						}
					}
					reader.readAsDataURL(file);
				});
			} else {
				// It was a directory (empty directories are added, otherwise only files)
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
				//console.log(droppedFile.relativePath, fileEntry);
			}
		}
	}

	public fileOver(event) {
		//console.log(event);
	}

	public fileLeave(event) {
		//console.log(event);
	}
}
interface Users {
	id: number,
	name: string,
	email: string,
	password: string,
	role_id: number,
	status: number
}
