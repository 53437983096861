import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DataService } from 'src/app/services/data.service';
import { ToastrService } from 'src/app/services/toastr.service';

@Component({
  selector: 'app-add-mission-task-modal',
  templateUrl: './add-mission-task-modal.component.html',
  styleUrls: ['./add-mission-task-modal.component.css']
})
export class AddMissionTaskModalComponent implements OnInit {
  public event: EventEmitter<any> = new EventEmitter();
  public modalRef: BsModalRef;
  @Input() list: any[] = [];
  missingTaskObj: any = {};
  urlParam = this.router.url.split("/");
  departmentIds: number[];


  constructor(
    private bsModalRef: BsModalRef,
    private dataService: DataService,
    public router: Router,
    private toastrService: ToastrService,
  ) {
    this.missingTaskObj.projectDepartmentList = [];
    this.missingTaskObj.departments = [];
  }

  ngOnInit() {
    console.log("this.dataService.missingTaskInfo", this.dataService.missingTaskInfo)
  }


  /** Close modal */
  closeModal() {
    this.bsModalRef.hide();
  }


  /** Search Auto complete */
  search(event: any) {
    const id_type: any = this.dataService.missingTaskInfo[2] == 'shot' ? 'shot_id' : 'asset_id';
    // Create an object with a dynamic key using computed property names
    const requestData = {
      'project_id': this.dataService.missingTaskInfo[0],
      'type': this.dataService.missingTaskInfo[2],
      'subtype': 'missingTask',
      'is_asset': 0,
      [id_type]: this.dataService.missingTaskInfo[4],  // Use id_type as a dynamic key
      'fromAssetShot': true,

    };

    // Check if id_type is 'asset' and add additional keys and values
    if (id_type === 'asset_id') {
      requestData['is_asset'] = 1;
    }

    this.dataService.getListData('projectDepartmentList', requestData)
      .subscribe((success) => {
        this.missingTaskObj.projectDepartmentList = [...success.data]
      }, (rejected) => {

      });

  }

  /** On Select Option */
  onselectOption(event: any) {

  }

  /** On clear selection */
  onselectionClear(event: any) {

  }

  addMissingTaska() {
    if (this.missingTaskObj.departments.length > 0) {
      this.dataService.getOtherData(this.dataService.missingTaskInfo[3], { 'projectId': this.dataService.missingTaskInfo[0], 'ids': this.dataService.missingTaskInfo[1], 'type': this.dataService.missingTaskInfo[2], 'department_ids': this.missingTaskObj.departments.map(item => item.asset_shot_department_id) })
        .subscribe((success) => {
          if (success.status == 'success') {
            this.triggerEvent('Confirmed');
            this.toastrService.notify('success', '', success.message);
          }
          this.closeModal();
        }, (rejected) => {
          this.triggerEvent('Reject');
          this.closeModal();
        });
    } else {
      this.toastrService.notify('error', '', "Please select a departments");
    }

  }

  /** Trigger Event */
  triggerEvent = (data: string) => {
    this.event.emit(data);
  }
}
