import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appAlphabetOnly]'
})
export class AlphabetOnlyDirective {
    constructor(private el: ElementRef) { }

    @HostListener('input', ['$event']) onInputChange(event: any) {
        const initialValue = this.el.nativeElement.value;
        const regex = /^[a-zA-Z0-9]*$/;

        if (!regex.test(initialValue)) {
            this.el.nativeElement.value = initialValue.replace(/[^a-zA-Z0-9]/g, '');
        }
    }
}
