import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appHideData]'
})
export class HideDataDirective {

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    let client_code = localStorage.getItem('client_code');
    if (client_code == 'GG') {
      this.elementRef.nativeElement.style.display = 'none';
      // this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', '<span> -</span>');
    }
  }

}
