export function columns(key: string, cellTemplate: any = {}, modifiedArray: any = []) {

    if(key == 'pipeline'){
      const pipelineColumns = [
          {
            prop: 'unread_comments',
            name: 'Action',
            sortable: false,
            draggable: false,
            canAutoResize: false,
            width: 80,
            frozenRight: true,
            headerClass: 'action-btn-datatable',
            cellClass: '',
            cellTemplate: cellTemplate.idsT,
          },
          {
            prop: 'thumbnail',
            name: 'Image',
            sortable: false,
            draggable: true,
            width: 60,
            cellClass: 'text-center',
            cellTemplate: cellTemplate.imageIcon,
        },
          {
            prop: 'icon',
            name: 'Entity',
            sortable: false,
            draggable: true,
            width: 60,
            cellClass: 'text-center',
            cellTemplate: cellTemplate.taskThumbnail,
        },
        {
            prop: 'info',
            name: 'Task Name',
            sortable: false,
            draggable: false,
            width: 300,
            canAutoResize: true,
            headerClass: '',
            cellClass: 'pointer',
            cellTemplate: cellTemplate.info,
        },
        {
            prop: 'department_name',
            name: 'Department',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: ''
        },
        {
            prop: 'version',
            name: 'Version',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: '',
            cellTemplate: cellTemplate.version,
        },
        {
            prop: 'status',
            name: 'Status',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: 'text-center',
            cellTemplate: cellTemplate.status,
        },
        {
            prop: 'received_date',
            name: 'End Date',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: '',
            cellTemplate: cellTemplate.receivedDate
        },
        {
            prop: 'assigned_to_name',
            name: 'Artist',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: '',
            cellTemplate: cellTemplate.assigned
        },
      ];
      return pipelineColumns;
    }
    else if(key == 'ready' || key == 'completed'){
      const readyColumns = [
          {
            prop: 'unread_comments',
            name: 'Action',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            width: 80,
            frozenRight: true,
            headerClass: 'action-btn-datatable',
            cellClass: 'action-button',
            cellTemplate: cellTemplate.idsT,
          },
          {
            prop: 'thumbnail',
            name: 'Image',
            sortable: false,
            draggable: false,
            canAutoResize: false,
            width: 60,
            cellClass: 'text-center',
            cellTemplate: cellTemplate.imageIcon,
          },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
      },
        {
            prop: 'info',
            name: 'Task Name',
            sortable: false,
            draggable: false,
            width: 300,
            canAutoResize: true,
            headerClass: '',
            cellClass: 'pointer',
            cellTemplate: cellTemplate.info,
        },
        {
            prop: 'department_name',
            name: 'Department',
            sortable: false,
            draggable: false,
            width: 220,
            canAutoResize: true,
            headerClass: '',
            cellClass: ''
        },
        {
            prop: 'version',
            name: 'Version',
            sortable: false,
            draggable: false,
            width: 120,
            canAutoResize: true,
            headerClass: '',
            cellClass: '',
            cellTemplate: cellTemplate.version,
        },
        {
            prop: 'status',
            name: 'Status',
            sortable: false,
            draggable: false,
            width: 150,
            canAutoResize: true,
            headerClass: '',
            cellClass: 'text-center',
            cellTemplate: cellTemplate.status,
        },
        {
            prop: 'received_date',
            name: 'Received Date',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: '',
            cellTemplate: cellTemplate.receivedDate
        },
        {
            prop: 'assigned_to_name',
            name: 'Artist',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: '',
            cellTemplate: cellTemplate.assigned
        },
      ];
      return readyColumns;
    }
    else if(key == 'rejected_inventory'){
      const rejected_inventoryColumns = [
          {
            prop: 'unread_comments',
            name: 'Action',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            width: 80,
            frozenRight: true,
            headerClass: 'action-btn-datatable',
            cellClass: 'action-button',
            cellTemplate: cellTemplate.idsT,
          },
          {
            prop: 'thumbnail',
            name: 'Image',
            sortable: false,
            draggable: true,
            resizable: false,
            canAutoResize: false,
            width: 60,
            cellClass: 'text-center',
            cellTemplate: cellTemplate.imageIcon,
        },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
      },
        {
            prop: 'info',
            name: 'Task Name',
            sortable: false,
            draggable: false,
            width: 300,
            canAutoResize: true,
            headerClass: '',
            cellClass: 'pointer',
            cellTemplate: cellTemplate.info,
        },
        {
            prop: 'department_name',
            name: 'Department',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: ''
        },
        {
            prop: 'version',
            name: 'Version',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: '',
            cellTemplate: cellTemplate.version,
        },
        {
            prop: 'status',
            name: 'Status',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: 'text-center',
            cellTemplate: cellTemplate.status,
        },
        {
            prop: 'assigned_to_name',
            name: 'Assigned To',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: '',
            cellTemplate: cellTemplate.assigned
        },
        {
            prop: 'rejectedBy',
            name: 'Rejected By',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: ''
        },
      ];
      return rejected_inventoryColumns;
    }
    else if(key == 'bucket'){
      const bucketColumns = [
          // {
          //   prop: 'unread_comments',
          //   name: 'Action',
          //   sortable: false,
          //   draggable: false,
          //   canAutoResize: true,
          //   width: 80,
          //   frozenRight: true,
          //   headerClass: 'action-btn-datatable',
          //   cellClass: 'action-button',
          //   cellTemplate: cellTemplate.idsT,
          // },
          {
            prop: 'selected',
            name: '',
            sortable: false,
            frozenLeft: true,
            draggable: true,
            headerCheckboxable: true,
            checkboxable: true,
            resizable: false,
            canAutoResize: false,
            width: 60,
          },
          {
            prop: 'thumbnail',
            name: 'Image',
            sortable: false,
            resizable: false,
            canAutoResize: false,
            width: 60,
            draggable: true,
            cellClass: 'text-center',
            cellTemplate: cellTemplate.imageIcon,
        },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          resizable: false,
          canAutoResize: false,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
      },
        {
            prop: 'info',
            name: 'Task Name',
            sortable: false,
            draggable: false,
            width: 300,
            canAutoResize: true,
            headerClass: '',
            cellClass: 'pointer',
            cellTemplate: cellTemplate.info,
        },
        {
            prop: 'department_name',
            name: 'Department',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: ''
        },
        {
            prop: 'version',
            name: 'Version',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: '',
            cellTemplate: cellTemplate.version,
        },
        {
            prop: 'status',
            name: 'Status',
            sortable: false,
            draggable: false,
            width: 60,
            canAutoResize: true,
            headerClass: '',
            cellClass: 'text-center',
            cellTemplate: cellTemplate.status,
        },
        {
          prop: 'assigned_to_name',
          name: 'Assigned To',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: '',
          cellTemplate: cellTemplate.assigned
        }
      ];
      return bucketColumns;
    }
    else if(key == 'under_review'){
      const under_reviewColumns = [
          {
            prop: 'unread_comments',
            name: 'Action',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            width: 80,
            frozenRight: true,
            headerClass: 'action-btn-datatable',
            cellClass: 'action-button',
            cellTemplate: cellTemplate.idsT,
          },
          {
            prop: 'thumbnail',
            name: 'Image',
            sortable: false,
            draggable: true,
            width: 60,
            cellClass: 'text-center',
            cellTemplate: cellTemplate.imageIcon,
        },
        {
          prop: 'icon',
          name: 'Entity',
          sortable: false,
          draggable: true,
          width: 60,
          cellClass: 'text-center',
          cellTemplate: cellTemplate.taskThumbnail,
      },
        {
            prop: 'info',
            name: 'Task Name',
            sortable: false,
            draggable: false,
            width: 300,
            canAutoResize: true,
            headerClass: '',
            cellClass: 'pointer',
            cellTemplate: cellTemplate.info,
        },
        {
            prop: 'department_name',
            name: 'Department',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: ''
        },
        {
            prop: 'version',
            name: 'Version',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: '',
            cellTemplate: cellTemplate.version,
        },
        {
            prop: 'status',
            name: 'Status',
            sortable: false,
            draggable: false,
            canAutoResize: true,
            headerClass: '',
            cellClass: 'text-center',
            cellTemplate: cellTemplate.status,
        },
        {
          prop: 'assigned_to_name',
          name: 'Assigned To',
          sortable: false,
          draggable: false,
          canAutoResize: true,
          headerClass: '',
          cellClass: '',
          cellTemplate: cellTemplate.assigned
        }
      ];
      return under_reviewColumns;
    }


}
