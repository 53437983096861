import { EventEmitter, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProgressbarModalComponent } from '../common/progressbar-modal/progressbar-modal.component';
import { AppConfigs } from '../common/app-configs';

@Injectable({
  providedIn: 'root'
})
export class GridService {
  myEventEmitterForProgressModal: EventEmitter<any> = new EventEmitter();
  taskPlayPuase: EventEmitter<any> = new EventEmitter<any>();
  private serverUrl = this.appConfigs.serverUrl;
  apiProgress: number = 0;
  bsModalRef: BsModalRef;

  private mySubjectForAccessGroup = new Subject<string>();

  // Expose the subject as an observable
  mySubjectForAccessGroup$ = this.mySubjectForAccessGroup.asObservable();

  // Function to update the subject's value
  updateValue(newValue: string) {
    this.mySubjectForAccessGroup.next(newValue);
  }

  constructor(
    private http: HttpClient,
    private modalService: BsModalService,
    public appConfigs: AppConfigs,
  ) {

    /** Need to hide the modal from event get */
    this.myEventEmitterForProgressModal.subscribe((event: any) => {
      if (event) {
        this.bsModalRef.hide();
      }
    });

  }

  public postData(endpoint: string, data: any): Observable<any> {
    return this.http.post<any>(`${this.serverUrl}${endpoint}`, data);
  }

  /** Progress function */
  progressFunction() {
    const progressInterval = setInterval(() => {
      if (this.apiProgress < 90) { // Update progress up to 90%
        this.apiProgress += 10; // Increment progress by 10%
      }
    }, 1000); // Update progress every second
    setTimeout(() => {
      clearInterval(progressInterval);
    }, 10000); // Adjust the time as needed
  }

  /** Open Progress modal */
  openProgressModal() {
    this.apiProgress = 0;
    this.bsModalRef = this.modalService.show(ProgressbarModalComponent, {
      backdrop: 'static',
      keyboard: false,
      class: 'modal-dialog-centered'
    });
  }

  /** Hide open modal */
  hideProgressModal() {
    this.bsModalRef.hide();
  }

  /** Progress bar modal event emitter */
  progressBarModalEventEmmiter(data: any) {
    this.myEventEmitterForProgressModal.emit(data);
  }

  /** Reset event */
  resetEventEmitter() {
    // Clear existing event listeners
    this.taskPlayPuase.unsubscribe();

    // Create a new EventEmitter instance
    this.taskPlayPuase = new EventEmitter<any>();
  }

  /** Get time */
  generateTimeOptions(startHour: number, endHour: number): { value: string }[] {
    const timeOptions: { value: string }[] = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      const time = hour % 12;
      const period = hour < 12 ? 'AM' : 'PM';
      const formattedHour = hour === 0 ? 12 : time;
      const value = `${hour.toString().padStart(2, '0')} (${formattedHour} ${period})`;
      timeOptions.push({ value });
    }
    return timeOptions;
  }

  /** Get minutes */
  generateMinuteOptions(): { value: string }[] {
    const minuteOptions: { value: string }[] = [];
    for (let minute = 0; minute < 60; minute++) {
      const value = minute.toString().padStart(2, '0') + ' mins';
      minuteOptions.push({ value });
    }
    return minuteOptions;
  }
}
