import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, OnDestroy, Output, ViewChild } from '@angular/core';
import { CommonJs } from '../../../common/common-js';
import { DataService } from '../../../services/data.service';
declare var $: any;
import * as _ from "lodash";
import { ToastrService } from 'src/app/services/toastr.service';
import { DragulaService } from 'ng2-dragula';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-task-column',
  templateUrl: './task-column.component.html',
  styleUrls: ['./task-column.component.css']
})
export class TaskColumnComponent implements OnInit {
  entityName = 'Columns';
  entityNameSingular = 'Column';
  apiRoutePrefix = 'task';
  skeleton: boolean = true;

  @Input('attr.data') dataHtml: any;
  @Input('selectedSubTab') selectedSubTab: any;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @ViewChild("roleInput", { static: false }) roleInput: ElementRef;
  @Output() closeManageCol = new EventEmitter();

  data: any;
  column_data: any;
  public temp_columns = [];
  checkId = [];
  SaveButtonText: string = 'SAVE';
  errors: { [index: string]: any } = {};
  actionColumn: any = {};
  subs: Subscription[] = [];

  constructor(
    private dataService: DataService,
    public commonJs: CommonJs,
    private toastrService: ToastrService,
    private dragulaService: DragulaService,
    private cdr: ChangeDetectorRef
  ) {

    // dragulaService.createGroup('another-bag', {
    //   revertOnSpill: true
    // });

    this.dragulaService.createGroup('another-bag', {
      moves: (el, container, handle) => handle.className === ''
    });

  }

  onDragStart(event: DragEvent, col: any) {
    // Additional logic if needed
    console.log('Drag started:', col);
  }

  ngOnInit() {
    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
      // Filter out the object with name "Actions"
      this.actionColumn = this.data.column.filter(item => item.name == "Actions")[0];
    }
    $.AdminBSB.input.activate();
    this.subngOnInit();
    this.subs.push(
      this.dragulaService.drop().subscribe(({ name, el, source, target, sibling }) => {
        const draggedColumn = this.temp_columns.find(col => col.name === el.textContent.trim());
        const currentIndex = this.temp_columns.indexOf(draggedColumn);
        this.temp_columns.splice(currentIndex, 1);

        let siblingColumn = '';
        let newIndex = 0;

        if (sibling) {
          siblingColumn = this.temp_columns.find(col => col.name === sibling.textContent.trim());
          newIndex = this.temp_columns.indexOf(siblingColumn);
        } else {      // If the dragged element been put on last index
          newIndex = this.temp_columns.length;
        }

        // const siblingColumn = this.temp_columns.find(col => col.name === sibling.textContent.trim());
        // const newIndex = this.temp_columns.indexOf(siblingColumn);
        this.temp_columns.splice(newIndex, 0, draggedColumn);
      })
    );
  }

  ngOnDestroy() {
    this.dragulaService.destroy('another-bag');
  }

  subngOnInit() {
    this.dataService.getListData(this.apiRoutePrefix + '/' + this.data.key + '/preferences', { 'projectId': this.data.projectId })
      .subscribe((success) => {
        if (success.status == 'success' && success.data.length != 0) {
          this.temp_columns = success.data;
          // Create a new array with columns in the order of 'this.temp_columns'
          const reorderedColumns = [];
          // Add matched columns in the order of 'this.temp_columns'
          for (const tempColumn of this.temp_columns) {
            const matchingColumn = this.data.column.find(column => column.prop === tempColumn.prop);
            if (matchingColumn) {
              reorderedColumns.push(matchingColumn);
            }
          }
          // Add any remaining columns from 'this.data.column'
          for (const column of this.data.column) {
            if (!reorderedColumns.some(matchedColumn => matchedColumn.prop === column.prop)) {
              reorderedColumns.push(column);
            }
          }
          // Update 'this.data.column' with the reordered columns
          this.data.column = reorderedColumns;
          this.skeleton = false;
        } else {
          // Keep 'this.temp_columns' as it is
          this.temp_columns = this.data.column;
          // Assuming this.temp_columns is an array of column objects
          const columnsToShow = ['thumbnail', 'info', 'shot_number', 'actual_start_date', 'actual_end_date', 'department', 'bid_days', 'assigned_to', 'actualStatus','id'];
          this.temp_columns = this.temp_columns.map(column => {
            if (columnsToShow.includes(column.prop)) {
              return { ...column, show: true };
            } else {
              return { ...column, show: false }; // Set show to false for other columns
            }
          });
          this.skeleton = false;
          console.log(this.temp_columns);
        }
      }, (rejected) => {
        this.skeleton = false;
      });
  }

  saveReOrder() {
    // this.temp_columns.filter((ele) => (ele.name == "Actions")).length == 0 ? this.temp_columns.push(this.actionColumn) : '';
    this.SaveButtonText = 'SAVING';
    this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.key + '/preferences', { 'value': this.temp_columns, 'projectId': this.data.projectId })
      .subscribe((success) => {
        this.SaveButtonText = 'SAVED';
        setTimeout(() => {
          this.CloseOtherWindow.nativeElement.click();
          this.SaveButtonText = 'SAVE';
        }, 2);
        this.toastrService.notify('success', '', 'Shot column filtered successfully.');
        // console.log(this.temp_columns)
        this.close(true);
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
          this.SaveButtonText = "SAVE";
          this.toastrService.notify('warn', '', err.error);
        }
      });
  }

  close(reload) {
    this.closeManageCol.emit({ column: this.temp_columns, reload: reload });
  }

  closeBtn() {
    this.CloseOtherWindow.nativeElement.click();
  }


}
