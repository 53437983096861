import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { CommonJs } from '../../../common/common-js';
declare var $: any;
import * as _ from "lodash";
import * as moment from 'moment';

@Component({
  selector: 'task-reschedule',
  templateUrl: './task-reschedule.component.html',
  styleUrls: ['./task-reschedule.component.css']
})
export class TaskRescheduleComponent implements OnInit {

  entityName = 'Task Reschedule';
  entityNameSingular = 'Task Reschedule';
  apiRoutePrefix = 'taskReschedule';

  @Input('attr.data') dataHtml: any;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @Output() closeSideWindow = new EventEmitter();

  data: any;
  myform: any;
  SaveButtonText: string = 'SAVE';

  errors: { [index: string]: any } = {};
  taskAssVerList: { [index: string]: any } = {};
  isAtt: boolean = false;

  dateOptions: { [index: string]: any } = {};
  public endDate = {
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    timePicker: true,
    timePickerIncrement: 5,
    autoApply: true,
    drops: 'up',
    locale: {
      format: 'DD MMM YYYY hh:mm A'
      //format: 'DD MMM YYYY'+ ' 23:59'
    }
  }
  public startDate = {
    singleDatePicker: true,
    autoUpdateInput: true,
    showDropdowns: true,
    timePickerIncrement: 5,
    timePicker: true,
    autoApply: true,
    drops: 'up',
    "maxDate": "31 Dec 2030",
    locale: {
      format: 'DD MMM YYYY hh:mm A'
      //format: 'DD MMM YYYY'+ ' 00:00'
    }
  }
  clientCode = localStorage.getItem('access_code');
  userId = localStorage.getItem('userId');

  showDoneCheckbox: boolean = false;
  constructor(private dataService: DataService, public commonJs: CommonJs) { }

  ngOnInit() {
    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
    }
    this.subngOnInit();
  }

  ngOnDestroy() {
    this.close();
  }

  subngOnInit() {
    this.getData();
  }

  getData() {
    this.taskAssVerList = [];
    this.dataService.getOtherData(this.apiRoutePrefix + 'List', { 'project_id': this.data.project_id, 'task_id': this.data.task_id })
      .subscribe((success) => {
        this.taskAssVerList = success.taskAssVerList;

        /*setTimeout(() => {
           _.forEach(this.taskAssVerList, function(v) {
              if (v.is_completed) {
                $('#is_completed-'+v.task_assignment_id).prop('checked', true);
              }
          });
        }, 2);*/

        let i = 0;
        let that = this;
        _.forEach(this.taskAssVerList, function (v) {
          /*if (v.start_date) {
            that.startDate['startDate'] = moment(v.start_date).format('DD MMM YYYY hh:mm A');
            that.taskAssVerList[i]['start_date'] = moment(v.start_date).format('DD MMM YYYY hh:mm A');
          }*/
          that.dateOptions[i] = [];
          that.dateOptions[i]['start_date'] = {
            singleDatePicker: true,
            autoUpdateInput: true,
            showDropdowns: true,
            timePickerIncrement: 5,
            timePicker: true,
            autoApply: true,
            drops: 'down',
            "maxDate": "31 Dec 2030",
            locale: {
              format: 'DD MMM YYYY hh:mm A'
              //format: 'DD MMM YYYY'+ ' 00:00'
            }
          };

          that.dateOptions[i]['end_date'] = {
            singleDatePicker: true,
            autoUpdateInput: true,
            showDropdowns: true,
            timePicker: true,
            timePickerIncrement: 5,
            autoApply: true,
            drops: 'down',
            locale: {
              format: 'DD MMM YYYY hh:mm A'
              //format: 'DD MMM YYYY'+ ' 23:59'
            }
          };

          if (that.taskAssVerList[i]['start_date'] != null && that.taskAssVerList[i]['start_date'] != '') {
            that.dateOptions[i]['start_date']['startDate'] = moment(that.taskAssVerList[i]['start_date']).format('DD MMM YYYY hh:mm A');
            that.taskAssVerList[i]['start_date'] = moment(that.taskAssVerList[i]['start_date']).format('DD MMM YYYY hh:mm A');
          }

          if (that.taskAssVerList[i]['end_date'] != null && that.taskAssVerList[i]['end_date'] != '') {
            that.dateOptions[i]['end_date']['startDate'] = moment(that.taskAssVerList[i]['end_date']).format('DD MMM YYYY hh:mm A');
            that.taskAssVerList[i]['end_date'] = moment(that.taskAssVerList[i]['end_date']).format('DD MMM YYYY hh:mm A');
          }

          i++;
        });

        if (this.data.actualStatus != 'CBB' && this.data.actualStatus != 'Unapproved') {
          if (this.data.actualStatus == 'Retake' && this.taskAssVerList.length > 1) {
            this.showDoneCheckbox = true;
          }
        }

      }, (rejected) => {

      });
  }

  close() {
    this.closeSideWindow.emit();
  }

  onSubmit(form: NgForm) {

    this.SaveButtonText = 'SAVING';
    form.value.project_id = this.data.project_id;
    form.value.task_id = this.data.task_id;

    //form.value.reschedule = this.taskAssVerList;
    let that = this;
    let i = 0;
    form.value.reschedule = [];
    _.forEach(this.taskAssVerList, function (value) {

      form.value.reschedule[i] = value;

      if ($('.start_date-' + value.task_assignment_id).val() != '' && $('.start_date-' + value.task_assignment_id).val() != undefined) {
        form.value.reschedule[i]['start_date'] = moment($('.start_date-' + value.task_assignment_id).val()).format('YYYY-MM-DD HH:mm:ss');
      } else {
        form.value.reschedule[i]['start_date'] = null;
      }

      if ($('.end_date-' + value.task_assignment_id).val() != '' && $('.end_date-' + value.task_assignment_id).val() != undefined) {
        form.value.reschedule[i]['end_date'] = moment($('.end_date-' + value.task_assignment_id).val()).format('YYYY-MM-DD HH:mm:ss');
      } else {
        form.value.reschedule[i]['end_date'] = null;
      }

      let notification = {
        client_code: that.clientCode,
        created_by: that.userId,
        description: 'retake task updated',
        is_unread: true,
        project_id: 1,
        task_id: 1,
        type: 'task_retake',
        user_id: value.user_id
      }
      // let that = this;
      // _.forEach(notifications, function(notification,key) {
      //     that.dataService.addNotification(that.clientCode,notification['user_id'],notification);
      // });
      that.dataService.addNotification(that.clientCode, notification['user_id'], notification);

      i++;
    });
    this.dataService.storeData(this.apiRoutePrefix + 'Update', form.value.reschedule)
      .subscribe((success) => {
        setTimeout(() => {
          this.SaveButtonText = 'SAVE';
          this.CloseOtherWindow.nativeElement.click();
          this.close();

        }, 2);

      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
        }
      });
  }
}
