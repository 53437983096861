import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { CommonJs } from '../../../common/common-js';
declare var $: any;
import * as _ from "lodash";
import { camArrayInterface } from '../create-shot/create-shot.component';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  entityName = '';
  entityNameSingular = '';
  apiRoutePrefix = 'shots';

  projectId = '';
  projectroletype = JSON.parse(localStorage.getItem('projectRoleType'));
  is_external_login: boolean = false;
  is_external_review: boolean = false;

  clientCode = localStorage.getItem('client_code');

  @Input('attr.data') dataHtml: any;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @Output() closeSideWindow = new EventEmitter();
  @ViewChild('scrollToBottom', { static: false }) private scrollToBottomContainer: ElementRef;

  data: any;
  projectData = [];

  assetData: { [index: string]: any } = {};
  assetTaskData: { [index: string]: any } = {};
  assetShotData: { [index: string]: any } = {};

  shotData: { [index: string]: any } = {};
  shotTaskData: { [index: string]: any } = {};
  shotAssetData: { [index: string]: any } = {};
  episodesSequences: { [index: string]: any } = {};

  timeLogData: { [index: string]: any } = {};
  uploadUrl = this.commonJs.getUploadUrl();
  showCommentLog: boolean = false;
  //Lazy-load images
  defaultImage = 'https://www.placecage.com/1000/1000';
  showInput: boolean = false;
  where: { [index: string]: any } = {};

  isEpisodicSeries: boolean = false;
  shotAssetDepartments = [];
  totalBidDays: number = 0;
  totalActualSpentDays: number = 0;
  currentTab: string;
  camArray: Array<camArrayInterface> = [];
  isMultipleCameras: boolean = false;
  repeatedFrame: number = null;
  isEditCameras: boolean = false;


  constructor(
    private dataService: DataService,
    public commonJs: CommonJs,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    public cd: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
      this.isEpisodicSeries = this.data.isEpisodicSeries;
    }
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
    });

    if (this.projectroletype['project_' + this.projectId] == 'external') {
      this.is_external_login = true;
    }

    this.dataService.getDataById('getProjectSettings/' + this.projectId)
      .subscribe((success) => {
        this.is_external_review = success.data.is_external_review;
      }, (rejected) => {

      });

    if (this.data.type == 'shot') {
      this.dataService.getDataById('projects/' + this.projectId + '/edit')
        .subscribe((success) => {
          this.projectData = success.data;
        }, (rejected) => {

        });
    }

    this.entityName = this.data.type;
    this.entityNameSingular = this.data.type;
    this.subngOnInit();
    //this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.scrollToBottomContainer.nativeElement.scrollTop = this.scrollToBottomContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  subngOnInit() {
    if (this.data.type == 'asset') {
      this.getAssetData();

    } else if (this.data.type == 'shot') {
      this.getShotData();
    }
  }

  getAssetData() {
    this.dataService.getDataById('assetData/' + this.data.asset.id + '/edit')
      .subscribe((success) => {
        this.assetData = success.data;
      }, (rejected) => {

      });
  }

  getShotData() {
    this.dataService.getDataById('shotData/' + this.data.shot.id + '/edit')
      .subscribe((success) => {
        this.shotData = success.data;
        //console.log("this.shotData", this.shotData)
        if (this.shotData.shot_frame_in_out.length > 0) {
          this.shotData.shot_frame_in_out.forEach((cam: any, index: any) => {
            if (cam.frame_in != null && cam.frame_out != null) {
              const sumArr = cam.frame_in.reduce((acc, val, index) => {
                return acc + cam.frame_out[index];
              }, 0);
              cam.no_of_frames = sumArr;
            }
            cam.arrayOfObjects = cam.frame_in.map((currentValue, index) => {
              return {
                frameIn: currentValue,
                frameOut: cam.frame_out[index],
              }
            });
          });
        }

        if (this.shotData.shot_frame_in_out.length > 0) {
          this.shotData.shot_frame_in_out.forEach((cam: any, index: any) => {
            const arrayOfObjects = cam.frame_in.map((currentValue, index) => {
              return {
                frameIn: currentValue,
                frameOut: cam.frame_out[index],
              }
            })
            this.onAddCam('');
            for (let j = 0; j < arrayOfObjects.length; j++) {
              this.onAddFrames(index, '', arrayOfObjects[j].frameIn, arrayOfObjects[j].frameOut);
            }
            //console.log(arrayOfObjects);
          });
        }
        if (this.shotData.shot_frame_in_out.length > 0) {
          this.sumAllFrames();
        }
      }, (rejected) => {

      });
  }

  isDatatableLoading: boolean = true;
  changeTab(tab, entity = 'shot') {
    this.isDatatableLoading = true;
    this.currentTab = tab;
    if (tab == 'task_info') {
      this.assetTaskData = [];
      this.dataService.getOtherData('getTasksLastVerByAssetId', { 'asset_id': this.data.asset.id })
        .subscribe((success) => {
          this.assetTaskData = success.data;
          this.isDatatableLoading = false;
        }, (rejected) => {
          this.isDatatableLoading = false;
        });
    }

    if (tab == 'shot_info') {
      this.assetTaskData = [];
      this.dataService.getOtherData('getShotByAssetId', { 'asset_id': this.data.asset.id })
        .subscribe((success) => {
          this.assetShotData = success.data;
          this.isDatatableLoading = false;
        }, (rejected) => {
          this.isDatatableLoading = false;

        });
    }

    if (tab == 'shot_task_info') {
      this.shotTaskData = [];
      this.dataService.getOtherData('getTasksLastVerByAssetId', { 'shot_id': this.data.shot.id })
        .subscribe((success) => {
          this.shotTaskData = success.data;
          this.isDatatableLoading = false;
        }, (rejected) => {
          this.isDatatableLoading = false;
        });
    }

    if (tab == 'shot_assets_info') {
      this.shotAssetData = this.shotData.assets;
      this.isDatatableLoading = false;
    }

    if (tab == 'shot_comments') {
      //let shotData = [];
      //this.scrollToBottom();
      this.getCommentsByShotAsset(this.data, 'shot');
    }
    if (tab == 'asset_comments') {
      //let shotData = [];
      this.getCommentsByShotAsset(this.data, 'asset');
      //this.scrollToBottom();
    }

    if (tab == 'episodes_sequences') {
      this.getAssetsEpisodesSequences(this.data.asset.id);
    }

    if (tab == 'shot_bids_info' || tab == 'asset_bids_info') {
      let entityId = 0;
      if (entity == 'shot') {
        entityId = this.data.shot.id;
      } else {
        entityId = this.data.asset.id;
      }
      this.getShotAssetBidDaysActualDays(entity, entityId);
    }
  }

  getCommentsByShotAsset(data, assetShot) {
    let callMethod = (assetShot == 'shot') ? 'getCommentsByShot' : 'getCommentsByAsset';
    this.dataService.getOtherData(callMethod, { 'data': data, 'assetShot': assetShot })
      .subscribe((success) => {
        this.timeLogData = success.data;
        this.showCommentLog = this.timeLogData['keys'].length > 0 ? true : false;
      }, (rejected) => {
      });
  }

  cellClick(key) {
    this.showInput = true;
    $('.cursor-pointer').removeClass('active');
    $('.' + key + '-td').addClass('active');
    $('.' + key + '-input input').focus();
  }

  getAssetsEpisodesSequences(id) {
    this.episodesSequences = [];
    this.dataService.getOtherData('getAssetsEpisodesSequences', { 'projectId': this.projectId, 'assetId': this.data.asset.id, 'isEpisodicSeries': this.isEpisodicSeries })
      .subscribe((success) => {
        this.episodesSequences = success.data.episodesSequences;
      }, (rejected) => {

      });
  }

  cellUpdate(key: any, data) {
    this.showInput = false;
    this.updateFramesPerSeconds(key, data);
  }

  updateFramesPerSeconds(key, shot) {
    this.where.projectId = this.projectId;
    this.where.shotId = shot.id;
    this.where.no_of_frames = shot.no_of_frames;
    this.dataService.storeData('updateFramesPerSeconds', { 'data': this.where })
      .subscribe((success) => {
        //this.assetTaskData = success.data;
        if (success.status == 'success') {
          this.toastrService.notify('success', '', success.message);
        } else {
          this.toastrService.notify('warn', '', success.message);
        }
      }, (rejected) => {

      });
  }

  getShotAssetBidDaysActualDays(entity, Id) {
    this.dataService.storeData('getShotAssetBidDaysActualDays', { 'entity': entity, 'entityId': Id })
      .subscribe((success) => {
        //this.assetTaskData = success.data;
        this.shotAssetDepartments = success.data.shotDepartments.shotDepartments;
        this.totalBidDays = success.data.shotDepartments.total.totalBidDays;
        this.totalActualSpentDays = success.data.shotDepartments.total.totalActualSpentDays;
        this.isDatatableLoading = false;
      }, (rejected) => {
        this.isDatatableLoading = false;
      });
  }

  /** Camera frameIn and FrameOut */
  // To add fields in formArray for frames
  onAddFrames(camIndex, frameIndex, framein, frameout) {
    this.camArray[camIndex].frames.push({ frameIn: framein, frameOut: frameout });
  }

  // To add camera
  onAddCam(type) {
    let newCamName = this.camArray.length + 1;
    type == 'manualAdd' ? this.camArray.push({ name: 'cam' + newCamName, id: newCamName, frames: [{ frameIn: null, frameOut: null }] }) : this.camArray.push({ name: 'cam' + newCamName, id: newCamName, frames: [] });
  }

  // To remove frame
  onRemoveFrames(camIndex, frameIndex) {
    // //console.log(camIndex, frameIndex);
    this.camArray[camIndex].frames.splice(frameIndex, 1);
    this.sumAllFrames();
  }

  /** Sum of all the frame In and Out */
  sumAllFrames() {
    this.shotData.no_of_frames = null;
    for (let i = 0; i < this.camArray.length; i++) {
      const sum: any = this.camArray[i].frames.reduce((total, currentValue) => {
        return total + (currentValue.frameIn ? currentValue.frameIn : 0) - (currentValue.frameOut ? currentValue.frameOut : 0);
      }, 0);
      this.shotData.no_of_frames = this.shotData.no_of_frames - sum;
      this.shotData.no_of_frames = Math.abs(this.shotData.no_of_frames) === 0 ? null : Math.abs(this.shotData.no_of_frames);
      this.isMultipleCameras = this.shotData.no_of_frames == null ? false : true;
    }
    this.cd.markForCheck();
  }

  // check if frameIn or frameOut already entered into the same camera
  checkFramePair(type, frameType, camera) {
    if (type == 'frameIn') {
      if (camera.frames.filter((frame) => (frame.frameIn == frameType)).length >= 2) {
        this.repeatedFrame = frameType;
      } else {
        this.repeatedFrame = null;
      }
    } else {
      if (camera.frames.filter((frame) => (frame.frameOut == frameType)).length >= 2) {
        this.repeatedFrame = frameType;
      } else {
        this.repeatedFrame = null;
      }
    }
    this.sumAllFrames();
  }

  /** Update shot */
  updateShot() {
    this.shotData.departments = this.shotData.departments == null ? [] : this.shotData.departments;
    this.dataService.updateData(this.apiRoutePrefix + '/' + this.shotData.id, this.shotData)
      .subscribe((success) => {

      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.toastrService.notify('warn', '', 'Please correct all highlighted errors and try again.');
        }
      });
  }

  setBgColor(data: any) {
    if (data != 'dependency' && data) {
      return data;
    } else if (data == 'dependency' && data) {
      return '#ccb9a1'
    }
  }
}
