import {
    Directive,
    ElementRef,
    Renderer2,
    OnInit,
    HostListener,
    HostBinding,
    Input
} from '@angular/core';
declare var $: any;

@Directive({
    selector: '[appHighlightOnHover]'
})
export class HighlightOnHoverDirective {

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2) { }

    ngOnInit() {

    }




    @HostListener('mouseenter') mouseover() {
        setTimeout(() => {
            $(".menu-list").toggleClass("d-block");
        }, 100);

    }

    @HostListener('mouseleave') mouseleave() {

    }

}