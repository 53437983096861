export function columns(key: string, cellTemplate: any = {}) {
    if (key === 'dashboardTable') {
        const dashboardColumn = [
            {
                prop: 'projectName',
                name: 'Project',
                sortable: false,
                draggable: false,
                canAutoResize: false,
                width: 230,
                cellClass: '',
            },
            {
                prop: 'info',
                name: 'Task',
                sortable: false,
                draggable: false,
                canAutoResize: false,
                width: 230,
                cellClass: 'pointer',
                cellTemplate: cellTemplate.taskInfo,
            },
            {
                prop: 'department',
                name: 'Department',
                sortable: false,
                draggable: false,
                canAutoResize: false,
                width: 230,
                cellClass: '',
            },
            {
                prop: 'version',
                name: 'Version',
                sortable: false,
                draggable: false,
                canAutoResize: false,
                width: 230,
                cellClass: '',
            },
            {
                prop: 'assigned_to',
                name: 'Assigned To',
                sortable: false,
                draggable: false,
                canAutoResize: false,
                width: 230,
                cellClass: '',
                cellTemplate: cellTemplate.assign,
            },
            {
                prop: 'status',
                name: 'Status',
                sortable: false,
                draggable: false,
                canAutoResize: false,
                width: 225,
                cellClass: '',
                cellTemplate: cellTemplate.status,
            },
        ]
        return dashboardColumn;
    }
}
