export function columns(key: string, cellTemplate: any = {}) {
    if (key === 'teamColumn') {
        const teamColumn = [
            {
                prop: 'vendor_id',
                name: 'Vendor Code',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                // minWidth: 200,
                // maxWidth: 200,
                // width: 200,
                searchPlaceholder: 'Search vendor code',
                headerTemplate: cellTemplate.hdrTpl,
            },
            {
                prop: 'emp_code_final',
                name: 'Ecode',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                // minWidth: 200,
                // maxWidth: 200,
                // width: 200,
                searchPlaceholder: 'Search ecode',
                headerTemplate: cellTemplate.hdrTpl,
            },
            {
                prop: 'user_name',
                name: 'Name',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                // minWidth: 200,
                // maxWidth: 200,
                // width: 200,
                searchPlaceholder: 'Search name',
                headerTemplate: cellTemplate.hdrTpl,
            },
            {
                prop: 'role_name',
                name: 'Studio Role',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                // minWidth: 200,
                // maxWidth: 200,
                // width: 200,
                searchPlaceholder: 'Search studio role',
                headerTemplate: cellTemplate.hdrTpl,
            },
            {
                prop: 'primary_department',
                name: 'Primary Dept',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                // minWidth: 200,
                // maxWidth: 200,
                // width: 200,
                searchPlaceholder: 'Search primary dept',
                headerTemplate: cellTemplate.hdrTpl,
            },
            {
                prop: 'secondary',
                name: 'Secondary Dept',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                // minWidth: 200,
                // maxWidth: 200,
                // width: 200,
                searchPlaceholder: 'Search secondary dept',
                headerTemplate: cellTemplate.hdrTpl,
            },
            {
                prop: 'vertical',
                name: 'Verticle',
                sortable: false,
                draggable: false,
                canAutoResize: true,
                // minWidth: 230,
                // maxWidth: 250,
                // width: 250,
                searchPlaceholder: 'Search verticle',
                headerTemplate: cellTemplate.hdrTpl,
            },
        ]
        return teamColumn;
    }
}
