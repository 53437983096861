import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SelectItem } from 'primeng/primeng';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-vfx',
  templateUrl: './vfx.component.html',
  styleUrls: ['./vfx.component.css']
})
export class VfxComponent implements OnInit {

  dropdownSettingsDepartment: any = {}
  departmentsSelected: any = [];
  projectId = '';
  rowsData: any = [];

  @Output() closeSimpleBidding = new EventEmitter();
  @Input('simpleBid') simpleBid: any = {};

  shotAssetDeptList: any[];
  simpleBidDepartments: any[] = [];
  simpleBidData: any = {};

  constructor(
    public commonJs: CommonJs,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,

  ) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
    });
  }

  ngOnInit() {

    this.dropdownSettingsDepartment = {
      singleSelection: false,
      idField: "id",
      textField: "name",
      selectAllText: "Select All",
      unSelectAllText: "UnSelect All",
      // itemsShowLimit: 2,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No data available',
      searchPlaceholderText: 'Search department',
      maxHeight: 90,
      autoPosition: false,
    };

    console.log(this.simpleBid);
    this.getSimpleBiddingList('simple-bidding', this.simpleBid);
  }

  // Get simple bidding list
  getSimpleBiddingList(type = 'simple-bidding', bid){
    let simpleBidViewConfig = {
                            where : { 'project_id': this.projectId, 'id': bid.id },
                            type: type
                          };

    this.dataService.storeData('simpleBiddingView', simpleBidViewConfig).subscribe(
      (success)=>{
        console.log(success);
        this.simpleBidData = success.data;
        this.shotAssetDeptList = success.departments;

        this.inputValue =  Object.keys(this.simpleBidData.data).length;
        this.shotAssetDeptList.forEach((ele)=>{ele.name = ele.department_name});

        console.log(this.simpleBidData['data'][0], this.shotAssetDeptList)
        this.simpleBidDepartments = this.shotAssetDeptList.filter(dept => Object.keys(this.simpleBidData['data'][0]).includes(dept.department_id.toString()));
        console.log(this.simpleBidDepartments);
        this.departmentsSelected = this.shotAssetDeptList;

        this.createArray();
        console.log(this.simpleBidData,this.shotAssetDeptList);
      },
      (error)=>{
        console.log(error);
        this.simpleBidData = {};
        this.shotAssetDeptList = [];

      });
  }


  onItemSelect(event: any) {
    this.createArray();
  }

  inputValue: number;
  createArray(): void {
    // console.log(this.departmentsSelected);
    // let shotNumberColumnIndex = -1;
    // // Find if 'Shot' department already exists
    // this.departmentsSelected.forEach((department, index) => {
    //   if (department.name === 'Shot') {
    //     shotNumberColumnIndex = index;
    //   }
    // });

    // // Add 'Shot' department if not already present
    // if (shotNumberColumnIndex === -1) {
    //   const shotNumberColumn = {
    //     id: 0,
    //     name: 'Shot'
    //   };
    //   this.departmentsSelected.unshift(shotNumberColumn);
    //   shotNumberColumnIndex = 0; // Updated index after unshifting
    // }
    // Store the previous length of the rowsData array
    const prevRowsDataLength = this.rowsData.length;

    // Check if the new input value is less than the previous length
    if (this.inputValue < prevRowsDataLength) {
        // Remove excess entries from rowsData
        this.rowsData = this.rowsData.slice(0, this.inputValue);
    }

    for (let i = 1; i <= this.inputValue; i++) {
      let shotObject;

      // Check if the shot object already exists
      if (prevRowsDataLength >= i) {
        shotObject = this.rowsData[i - 1]; // Get existing shot object
      } else {
        shotObject = { Shot: i }; // Create new shot object if not exists
      }

      // Update shot object with department keys
      this.departmentsSelected.forEach(department => {
        if (department.name === "Shot") {
          shotObject[department.name.replace(/\s+/g, '')] = i;
        } else {
          const departmentKey = department.name.replace(/\s+/g, '');
          if (!shotObject.hasOwnProperty(departmentKey)) {
            shotObject[departmentKey] = '';
          }
        }
      });
      // Push or update shot object in rowsData array
      if (prevRowsDataLength >= i) {
        this.rowsData[i - 1] = shotObject; // Update existing shot object
      } else {
        this.rowsData.push(shotObject); // Push new shot object
      }
    }
    console.log(this.rowsData)
}

calculateRowTotal(rowData: any): number {
  let total = 0;
  // Iterate through each column and sum up the values
  this.departmentsSelected.forEach(column => {
    total += rowData[column.name] || 0;
  });
  return total;
}

calculateColumnTotal(columnName: string): number | null {
  if (columnName === 'total') {
      let total = 0;
      this.rowsData.forEach(row => {
          this.departmentsSelected.forEach(column => {
              total += row[column.name] || 0;
          });
      });
      return total;
  } else {
     let total = 0;
        this.rowsData.forEach(row => {
            total += row[columnName] || 0;
        });
        return total;
  }
}

  // Go back to bid-history
  goBackToBidHistory(){
    this.closeSimpleBidding.emit();
  }


}
