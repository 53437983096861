import { Component, OnInit } from '@angular/core';
import { GridService } from 'src/app/services/grid.service';

@Component({
  selector: 'app-progressbar-modal',
  templateUrl: './progressbar-modal.component.html',
  styleUrls: ['./progressbar-modal.component.css']
})
export class ProgressbarModalComponent implements OnInit {

  constructor(
    private gridService: GridService,
  ) { }

  ngOnInit() {
  }

}
