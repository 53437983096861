import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DataService } from '../../../services/data.service';
import { CommonJs } from '../../../common/common-js';
declare var $: any;
import * as _ from "lodash";
import * as moment from 'moment';
import { ToastrService } from '../../../services/toastr.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit {

  entityName = 'General Activity';
  entityNameSingular = 'General Activity';
  apiRoutePrefix = 'activity';

  /*	@ViewChild('CloseOtherWindow') CloseOtherWindow:ElementRef;*/
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @Output() closeSettings = new EventEmitter();

  data: any;
  myform: any;
  errors: { [index: string]: any } = {};
  edit_errors: { [index: string]: any } = {};
  checklist: { [index: string]: any } = {};
  checklistData = [];
  isSubmitted: boolean = false;
  constructor(private dataService: DataService, public commonJs: CommonJs, private titleService: Title, private toastrService: ToastrService,) { }

  ngOnInit() {
    this.getData();
    this.titleService.setTitle(this.entityName);
  }

  getData() {
    this.dataService.getOtherData(this.apiRoutePrefix + 'List', { 'is_main': true })
      .subscribe((success) => {
        this.checklistData = success.data;
      }, (rejected) => {

      });
  }

  onSubmit(form: NgForm) {
    this.isSubmitted = true;
    this.dataService.storeData(this.apiRoutePrefix, form.value)
      .subscribe((success) => {

        setTimeout(() => {
          this.checklist.name = '';
          this.getData();
        }, 2);

        setTimeout(() => {
          this.isSubmitted = false;
        }, 1500);

      }, (error) => {
        if (error.error) {
          this.toastrService.notify('error', '', 'Activity already exist');
          var err = error.error;
          this.errors = err.error;
        }
        this.isSubmitted = false;
      });
  }

  cellClick(id): any { //onCellClick
    if (id != undefined) {
      $('.cursor-pointer').removeClass('active');
      $('.' + id + '-td').addClass('active');
      $('.' + id + '-input input').focus();
    }
  }

  cellUpdate(data: any = {}, isDelete = false, isDone = false, checkboxVal = ''): any {
    if (isDelete) {
      this.dataService.deleteData(this.apiRoutePrefix + 'Destroy', [data.id])
        .subscribe((success) => {
          $('.' + data.id + '-td').removeClass('active');
          this.toastrService.notify(success.status, '', success.message);
          this.getData();
        }, (rejected) => {

        });

    } else {

      data['is_done'] = isDone;
      this.dataService.updateData(this.apiRoutePrefix + '/' + data.id, data)
        .subscribe((success) => {

          $('.' + data.id + '-td').removeClass('active');
          this.getData();

        }, (error) => {
          if (error.error) {
            var err = error.error;
            this.edit_errors[data.id] = err.error;
          }
        });
    }
  }
}
