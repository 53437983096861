import { Component, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-thumbnail-modal',
  templateUrl: './thumbnail-modal.component.html',
  styleUrls: ['./thumbnail-modal.component.css']
})
export class ThumbnailModalComponent implements OnInit {

  constructor(public modalService:BsModalService) {
  }

  ngOnInit() {
  }


}
