import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CommonJs } from '../../../common/common-js';

import { DataService } from '../../../services/data.service';
import { DatatableService } from '../../../services/datatable/datatable.service';
import { NgTableComponent, NgTableFilteringDirective, NgTablePagingDirective, NgTableSortingDirective } from 'ng2-table/ng2-table';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { AppConfigs } from '../../../common/app-configs';

declare var $: any;
import * as moment from 'moment';
import * as _ from "lodash";

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.css']
})
export class PlanningComponent implements OnInit {

  entityName = 'Planning';
  entityNameSingular = 'Planning';
  apiRoutePrefix = 'planning';

  busy: Subscription;

  projectId = '';
  myform: any;
  planning: { [index: string]: any } = {};
  errors: { [index: string]: any } = {};
  assetList: { [index: string]: any } = {};
  focused: string = '';

  public rows = this.datatableService.rows;
  settings: any = JSON.parse(localStorage.getItem('settings'));
  selector: string = '.table-responsive';
  public showFilterRow = false;
  isSubmitted: boolean = false;
  public columns: Array<any> = [
    { title: '#ID', name: 'id', sort: 'desc', className: 'hide-this' },
    { title: 'Season# - Episode #', name: 'seasonEpisode', width: '150px' },
    { title: 'Sequence #', name: 'number', sort: true, width: '150px' },
    { title: 'Projected Shots', name: 'projected_shots', sort: true, width: '150px', className: 'editor-td', editor: { type: 'text' } },
    { title: 'Projected Seconds', name: 'projected_seconds', sort: true, width: '150px', className: 'editor-td', editor: { type: 'text' } },
    { title: 'Assets', name: 'assets', sort: false, width: '700px', className: 'editor-td', editor: { type: 'dropdown-multi', options: this.assetList, optionKey: 'id', optionValue: 'code', selectionKey: 'assetArr' } },
  ]

  public FilterColumns: Array<any> = ['name', 'email'];
  public config: any = {
    paging: true,
    sorting: { columns: this.columns },
    filtering: { filterString: '' },
    className: ['table-striped', 'table-bordered'],
    where: { 'project_id': this.projectId },
    export: false,
  };
  public maxSize = this.datatableService.maxSize;
  public page: number = this.datatableService.page;
  // public itemsPerPage: number = this.settings.default_pagination;
  public itemsPerPage: number = this.datatableService.itemsPerPage ? this.datatableService.itemsPerPage :50;
  public length = this.datatableService.length;

  noRecordFound: boolean = false;
  noRecordFoundMsg: any = 'Sorry, no records found!';
  totalCount: number;

  isEpisodicSeries: boolean = false;
  episodes: Array<any>;

  @ViewChild('scrollToBottom', { static: false }) private scrollToBottomContainer: ElementRef;

  constructor(
    private titleService: Title,
    public commonJs: CommonJs,
    private dataService: DataService,
    private datatableService: DatatableService,
    public appConfigs: AppConfigs,
    private activatedRoute: ActivatedRoute,
  ) {
    this.rows = [];
    this.datatableService.setParam(this.columns, 'columns');
    this.datatableService.setParam(this.config, 'config');
    this.datatableService.setParam(this.FilterColumns, 'FilterColumns');
    this.datatableService.setParam(this.apiRoutePrefix + 'List', 'serverUrl');
  }

  ngOnInit() {
    $.AdminBSB.input.activate();
    // this.noRecordFoundMsg = (this.settings.no_records != '') ? this.settings.no_records : this.noRecordFoundMsg;
    // this.datatableService.setParam(this.settings.default_pagination, 'itemsPerPage');
    this.titleService.setTitle(this.settings.html_prefix + this.entityName);
    this.activatedRoute.params.subscribe((params: Params) => {
      this.projectId = params['id'];
      this.config.where.project_id = params['id'];

      this.dataService.getDataById('projects/' + this.projectId + '/edit')
        .subscribe((success) => {
          if (!success.data.episodic_series) {
            const index = this.columns.findIndex((col) => {
              return col.name == 'seasonEpisode';
            });
            if (index !== -1) {
              this.columns[index]['className'] = 'hide-this';
            }
          }
        }, (rejected) => {

        });
    });
    this.onChangeTable(this.config);
    this.getProjectsById(this.projectId);
  }

  onScrollDown() {
    this.page++;
    this.onChangeTable(this.config, { page: this.page, itemsPerPage: this.itemsPerPage }, '', true);
  }

  onScrollUp() {
    this.page--;
  }

  public onChangeTableFilter(config, column = '', str = '') {
    //this.filterAppliedOnFun(column, str);
    this.page = 1;
    this.onChangeTable(config);
  }

  exportData() {
    this.config.export = true;
    this.onChangeTable(this.config);
  }

  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }, column: any = '', isAppend: any = false, isSort: any = false): any {
    $('#checkAll').prop('checked', false);
    if (isSort) {
      this.page = 1;
      page.page = 1;
      //this.isSortApply = true;
      this.noRecordFound = false;
      this.length = 0;
    }
    if (column) {
      this.config.isClearFilter = false;
      this.config.isLoadingFirstTime = false;
    }
    this.busy = this.datatableService.onChangeTable(this.config, page, column)
      .subscribe((success) => {

        if (this.config.export) {
          if (success.data != '') {
            window.open(success.data);
          }
        } else {

          if (!isAppend) {
            this.rows = success.data;
          } else {

            let that = this;
            _.forEach(success.data, function (value) {
              that.rows.push(value);
            });
          }

          this.length = success.totalElements;
          this.totalCount = success.totalElements;
          this.noRecordFound = true;

          /*if ( isSort ) {
            setTimeout(() => {
            let that = this;
            this.filterAppliedOn.forEach((item, index) => {
              $('.'+item+'-th').addClass('filter-activated');
            });
            }, 2);
          }*/
        }

        this.config.export = false;
        //this.isSortApply = false;
      });
  }

  search(event, column) {
    const index = this.columns.findIndex((col) => {
      return col.name == column;
    });

    if (column == 'assets') {

      this.dataService.getListData('assetsItems', { project_id: this.projectId, 'query': event['query'] })
        .subscribe((success) => {

          if (index !== -1) {
            this.columns[index]['editor']['options'] = success.data;
          }

        }, (rejected) => {

        });
    } else if (column == 'episodes') {
      this.errors = [];
      this.dataService.getListData('episodesItems', { project_id: this.projectId, 'query': event['query'] })
        .subscribe((success) => {
          this.episodes = success.data;
        }, (rejected) => {

        });
    }
  }

  cellClick(editor, data: any, column) {
    if (editor != undefined) {
      $('.cursor-pointer').removeClass('active');
      $('.' + column + '-' + data.id + '-td').addClass('active');
      $('.' + column + '-' + data.id + '-input input').focus();

      if (column != 'assets') {
        $('#' + column + '-' + data.id + '-editor-val').val(data[column]);
      } else {
        /*var arr="";
        _.forEach(data[column], function(value) {
          arr += value['name'];
          arr += ",";
        });
        $('#'+column+'-'+data.id+'-editor-val').val(arr);*/
      }
    }
  }

  cellUpdate(data1: any, column, $event = {}): any {
    $('.' + column + '-' + data1.id + '-td').removeClass('active');

    setTimeout(() => {
      this.dataService.updateData(this.apiRoutePrefix + '/' + data1.id, data1)
        .subscribe((success) => {
          this.page = 1;
          this.onChangeTable(this.config);
          //this.scrollToBottom();
        }, (error) => {

        });
    }, 2);
  }

  onSubmit(form: NgForm) {
    // this.isSubmitted = true;
    let episode_id = 0;
    if (form.value.episode_id) {
      episode_id = form.value.episode_id.id;
    } else {
      episode_id = form.value.episode_id = 0;
    }
    var data = [];
    if (form.value.seq_prefix == undefined) {
      this.dataService.getListData('getLastRecordProjectSequence', { 'table': 'sequences', 'project_id': this.projectId, 'episode_id': episode_id, 'fromPlanning': true })
        .subscribe((success1) => {
          if (success1.data != null && success1.data.number != undefined) {
            let lastStr = success1.data.number;
            for (let i = 1; i <= form.value.seq_count; i++) {
              lastStr = this.appConfigs.autoincrementStr(lastStr);
              let newName = {
                //id:i.toString(),
                project_id: this.projectId,
                number: lastStr
              };

              if (this.isEpisodicSeries) {
                newName['episode_id'] = episode_id;
              }

              data.push(newName);
              setTimeout(() => {
                this.isSubmitted = false;
              }, 2000);

              this.planning = {};
            }
          } else {
            let newName = {
              //id:i.toString(),
              project_id: this.projectId,
              number: 'sequence1'
            };

            if (this.isEpisodicSeries) {
              newName['episode_id'] = episode_id;
            }

            data.push(newName);
            setTimeout(() => {
              this.isSubmitted = false;
            }, 2000);
          }
          //let lastStr = success1.data.number;
          this.store(data);
          this.planning = {};
        }, (rejected) => {

          this.isSubmitted = false;
        });
    } else {

      for (let i = 1; i <= form.value.seq_count; i++) {
        let newName = {
          //id:i.toString(),
          project_id: this.projectId,
          number: form.value.seq_prefix + '_' + i
        };

        if (this.isEpisodicSeries) {
          newName['episode_id'] = episode_id;
        }

        data.push(newName);
      }
      this.store(data);
    }
  }

  store(data) {
    this.dataService.storeData(this.apiRoutePrefix, data)
      .subscribe((success) => {

        this.onChangeTable(this.config);

      }, (error) => {
        //this.alertMessage = 'Something went wrong, Please try again!';
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
        }
      });
  }

  getProjectsById(id) {
    // this.dataService.getDataById('projects/' + id + '/edit')
    //   .subscribe((success) => {
    this.isEpisodicSeries = JSON.parse(localStorage.getItem('localStorageProjectData')).episodic_series;
    // }, (rejected) => {

    // });
  }

  scrollToBottom(): void {
    try {
      //this.scrollToBottomContainer.nativeElement.scrollTop = this.scrollToBottomContainer.nativeElement.scrollHeight;
      this.scrollToBottomContainer.nativeElement.scrollTop = 0;
    } catch (err) { }
  }
}
