import { Component, Input, OnChanges } from '@angular/core';

@Component({
    selector: 'app-skeleton',
    templateUrl: './skeleton.component.html',
    styleUrls: ['./skeleton.component.css']
})
export class SkeletonComponent implements OnChanges {

    @Input() type: any;
    @Input() count: any;
    @Input() tableColumn: any;

    constructor() { }

    ngOnChanges(): any {
    }

    ngOnInit() {
        //console.log(this.count)
        //console.log(this.tableColumn)
    }
}
