import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, Params } from '@angular/router';
import { AppConfigs } from './common/app-configs';
import { CommonJs } from './common/common-js';
//import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { DataService } from './services/data.service';
import { ToastrService } from './services/toastr.service';
import { NotificationsService } from './services/notifications/notifications.service';
import { environment } from '../environments/environment';
//  import { NgProgressService } from 'ngx-progressbar';
import { Location } from '@angular/common';
import { Message, MessageService } from 'primeng/primeng';
//import { Subscription } from 'rxjs';
import { Subscription, Observable, interval } from 'rxjs';
import * as _ from "lodash";
import { filter, pairwise } from 'rxjs/operators';
import { GridService } from './services/grid.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  // @HostBinding('class') themeClass: string = 'theme-light-blue';;
  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;

  //login
  public isLogin: boolean;
  public isToken: boolean = false;
  public isProject: boolean = false;
  public isNotFoundPage: boolean = false;
  projectName: string = localStorage.getItem('projectId');
  projectSidebarToggleVal: boolean = false;
  projectId: number;
  projectColor: string = 'orange';
  SidebarToggleVal: boolean = false;
  @Output() settings1 = new EventEmitter();
  toastMessages: any[] = [];
  lifeTime: number = 50000000;
  objToaster: Message[];
  //msgs: any[] = [];
  showToast: boolean = false;
  subscription: Subscription;

  /*project_data = [];*/

  previous_route = '';
  private studio_polling;
  private project_polling = {};

  projectNotiCount = {};
  role = localStorage.getItem('role');
  clientCode = localStorage.getItem('access_code');
  userId = localStorage.getItem('userId');
  subscribeisUserTasksList;
  location: Location;
  tokenVar;
  dashboard: boolean = false;
  enteresURL: any;
  firstEnteresURL: any;
  firstTime: boolean = false;
  message;
  changeProjectSub;
  releaseList: any;
  display: boolean = false;

  visiblePhases: any[] = [];
  phasesExist: any = {};

  isTableFullScreenView: boolean = false;
  hideMainHeader:boolean = true;

  constructor(public router: Router,
    location: Location,
    public commonJs: CommonJs,
    public appConfigs: AppConfigs,
    private messageService: MessageService,
    private dataService: DataService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public notificationsService: NotificationsService,
    private gridService: GridService,
  ) {
    router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        if (!this.firstTime) {
          this.firstEnteresURL = event.url;
          this.firstTime = true;
        }
        this.enteresURL = event.url;
        if (this.role === 'superadmin' && this.enteresURL === '/dashboard') {
          this.adminLogoutncheck();
        }
      });
    this.location = location;
    this.objToaster = [];
    localStorage.setItem('devMode', JSON.stringify(environment.production));

    dataService.fullScreenViewObservable$.subscribe((isFullScreen)=>{

      isFullScreen ? (this.isTableFullScreenView = true) : (this.isTableFullScreenView = false) ;
      isFullScreen ? (this.hideMainHeader = true):(this.hideMainHeader = false);

    });

  }



  ngOnInit() {
    this.router.events.pipe(pairwise()).subscribe((e) => {
      if (e[0] instanceof NavigationEnd) {
        this.previous_route = e[0]['url'];
      }
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLogin = (event.url.indexOf('login') !== -1 || event.url.indexOf('') !== -1);
        this.isNotFoundPage = event.url.indexOf('page-not-found') !== -1;
        this.tokenVar = (function () {
          var result;
          try {
            let result = localStorage.getItem('token');
            return result;
          } catch (exception) {
          }
        }());
        if (this.tokenVar != null && this.tokenVar != '' && this.tokenVar != undefined) {
          this.isToken = true;
        } else {
          this.isToken = false;
        }
        if (!this.isLogin && this.isLogin != undefined) {
          this.commonJs.initiateJS();
          ///this.commonJs.getSetting();
        }
        this.isProject = _.includes(this.location.path(), 'projects/');
        let $sValue = JSON.parse(localStorage.getItem('settings'));
        if (this.isToken && !this.isProject && this.role != 'admin') {
          this.commonJs.getPermissions();
        }
        if ($sValue) {
          if (this.isProject) {
            this.activatedRoute.params.subscribe((params: Params) => {
              this.projectId = this.activatedRoute.snapshot.children[0]['_urlSegment']['segments'][4].path;
            });
            this.endStudioPolling();
            if (this.role != 'admin' && this.role != 'superadmin' && environment.prodType == 'offline') {
              // this.startProjectPolling(this.projectId);
            }
            // if(_.includes(this.location.path(), 'dashboard/')){
            //     this.getProjectSetting();
            // }

            // this.getProjectSetting();
          } else {
            if (_.includes(this.previous_route, 'projects/')) {
              let urlParam = this.previous_route.split("/");
              this.endProjectPolling(urlParam[5]);
            }
            //temp stopped for report
            // if (this.isToken && !(_.includes(this.location.path(), 'reports/'))) {
            if (this.role != 'admin' && this.role != 'superadmin' && environment.prodType == 'offline') {
              // this.startStudioPolling();
            }
            // }
          }

        }

        let isLoginPage = localStorage.getItem('token');

            if(isLoginPage && this.role != 'superadmin'){
              // Getting hidden phases filtered out
           this.dataService.pagePreferanceGet('phasesExistence').then(
            (res: any) => {
              // console.log('response',res.data);
              if(res.data.departmentPhaseList){
                this.visiblePhases = res.data.departmentPhaseList;

                this.phasesExist = {};
                this.phasesExist = this.visiblePhases.reduce((obj, item) => {
                  if (item.name!="SUPPORT" && (item.isHidden == 0 || item.isHidden == false)) {

                    if(item.subtype==null){
                      obj[item.type.toLowerCase()] = item.isHidden ? 0 : 1;
                    }else{
                      obj[item.subtype.toLowerCase()] = item.isHidden ? 0 : 1;
                    }
                  }
                  return obj;
                }, {});

                this.commonJs.phasesExist = this.phasesExist;

                // console.log('Visible phases',this.phasesExist);
              }else{
                // console.log('No phases found');
                this.visiblePhases = [];
                this.phasesExist = { pre:1, character:1, set:1, prop:1, shot:1, post:1 };
                this.commonJs.phasesExist = this.phasesExist;
              }
              localStorage.setItem('phasesExist',JSON.stringify(this.phasesExist));
              // console.log('================================================',this.phasesExist);
            },
            (error)=>{
              this.visiblePhases = []
              this.phasesExist = { pre:1, character:1, set:1, prop:1, shot:1, post:1 };
              this.commonJs.phasesExist = this.phasesExist;
              localStorage.setItem('phasesExist',JSON.stringify(this.phasesExist));
              // console.log('error',error);
            });
            }


        if (localStorage.getItem("token")) {
          this.commonJs.getAllSettings(this.isLogin);
        }

        //if(!this.isLogin && this.role != 'admin' && this.role != 'superadmin' && this.role != 'client'){
        //if(this.isToken && this.role != 'admin' && this.role != 'superadmin' && this.role != 'client' && this.role != 'vendor'){
        if (this.isToken && (this.role == 'artist' || this.role == 'lead')) {
          // this.getRunningTask();
        }

        if (this.isToken && this.notificationsService.permission != 'granted') {
          setTimeout(() => {
            this.notificationsService.requestPermission();
          }, 3000);
        }
        //this.getRunningTask();
        //this.commonJs.getInitValue();
      }
    });
    if (localStorage.getItem('user') && this.role != 'superadmin') {
      this.projectListByUser();
      this.getStatusList();
      this.getReleaseUpdateList();
    }
    console.log('test')
  }

  getReleaseUpdateList() {
    let userData: any = JSON.parse(localStorage.getItem('user'));
    if (userData.news_popup == 1) {
      this.dataService.getListData('updateHistoryList', { type: 'studio' }).subscribe({
        next: (response: any) => {
          if (response) {
            this.releaseList = response.data;
            this.display = true;
          }
        },
        error: (err: any) => { },
      })
    }

  }

  getStatusList() {
    return new Promise((resolve, rejected) => {
      this.dataService.getOtherData('getStatusList', {})
        .subscribe((success) => {
          this.dataService.allStatusList = success.data;
          resolve(200);
        }, (rejected) => {

        });
    });
  }

  // getProjectSetting() {
  //   this.dataService.getListData('getProjectSetting', { 'project_id': this.projectId, 'type': 'project_color' })
  //     .subscribe((success) => {
  //       this.setThemeStyleApp(success.data);
  //     }, (rejected) => {
  //     });
  // }

  startStudioPolling() {
    this.studio_polling = interval(10000).subscribe(x => {
      //this.getReviewTaskList();
      this.dataService.getOtherData('getStudioNotiCount', { 'env': environment.prodType, 'role': this.role })
        .subscribe((success) => {
          localStorage.setItem('studio_notification_count', success.notification);
          if (success.pushNotification.length > 0) {
            let notify = success.pushNotification[0];
            this.notificationsService.sendPushMessage(notify.description, notify.messge, 'app');
          }
        }, (rejected) => {
        });
    });
  }

  endStudioPolling() {
    if (this.studio_polling != undefined) {
      this.studio_polling.unsubscribe();
      //this.studio_polling = '';
    }
  }

  startProjectPolling(project_id) {
    if (this.role != 'admin' && this.role != 'superadmin' && environment.prodType == 'offline') {
      if (this.project_polling['polling_' + project_id] == undefined || this.project_polling['polling_' + project_id] == '') {
        this.project_polling['polling_' + project_id] = interval(10000).subscribe(x => {
          this.project_polling['polling_' + project_id] = this.dataService.getOtherData('getProjectNotiCount', { 'project_id': project_id, 'env': environment.prodType })
            .subscribe((success) => {
              if (localStorage.getItem('project_notification_count') != null) {
                this.projectNotiCount = JSON.parse(localStorage.getItem('project_notification_count'));
              } else {
                this.projectNotiCount = {};
              }
              this.projectNotiCount['project_' + project_id] = success.notification;
              localStorage.setItem('project_notification_count', JSON.stringify(this.projectNotiCount));

              if (success.pushNotification.length > 0) {
                let notify = success.pushNotification[0];
                this.notificationsService.sendPushMessage(notify.description, notify.messge, 'app');
              }

            }, (rejected) => {
            });
        });
      }
    }
  }

  endProjectPolling(project_id) {
    if (this.project_polling['polling_' + project_id] != undefined && this.project_polling['polling_' + project_id] != '') {
      this.project_polling['polling_' + project_id].unsubscribe();
      this.project_polling['polling_' + project_id] = '';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscribeisUserTasksList.unsubscribe();
  }

  ngAfterViewInit() {
    this.subscribeToToastr();
  }

  subscribeToToastr() {
    this.toastrService.toasterStatus.subscribe((val: Message) => {
      if (val) {
        this.messageService.add(val);
        // //console.log(val)
        // this.objToaster = [];
        // this.objToaster.push(val);
      }
    });
  }

  projectSidebarToggle(e) {
    this.projectSidebarToggleVal = !this.projectSidebarToggleVal;
  }

  SidebarToggle(e) {
    this.SidebarToggleVal = !this.SidebarToggleVal;
  }

  logout(fromAuth = false, type = "", message = "") {
    this.endStudioPolling();
    let that = this;
    _.forEach(this.project_polling, function (value, key) {
      if (value != '') {
        that.project_polling[key].unsubscribe();
        that.project_polling[key] = '';
      }
    });

    localStorage.setItem('userId', '');
    localStorage.setItem('user', '');
    localStorage.removeItem('currentTask');
    localStorage.removeItem('projectRoles');
    localStorage.removeItem('projectRoleType');
    localStorage.removeItem('projectPermissions');
    localStorage.removeItem('ocalStorageProjectData');
    localStorage.removeItem('project_notification_count');
    this.dataService.getProjectListByUser = [];
    if (!fromAuth) {
      that.router.navigate([this.commonJs.getClientCodePrefix()]);
    }

    localStorage.setItem('access_code', '');
    localStorage.setItem('roles', JSON.stringify({}));

    localStorage.setItem('role', this.role);
    //localStorage.setItem('client_code', '');


    //localStorage.setItem('project_notification_count', JSON.stringify({"project_1": 0}));

    localStorage.setItem('standalone', undefined);

    this.router.navigate([this.commonJs.getClientCodePrefix()]);

    if (type == 'account-deleted') {
      this.toastrService.notify('warn', '', 'It seems your account is deleted. Please contact support.');
    } else if (type == 'Unauthenticated') {
      this.toastrService.notify('warn', '', 'It seems you dont have access to this account. Please contact support.');
    }
    this.dataService.logout('logout', '')
      .subscribe((success) => {
        this.dataService.unsubscribeNotifications();
        if (success.success) {
          localStorage.removeItem('token');
          //this.ngOnInit();
        }
      }, (error) => {
      });

  }

  getRunningTask() {
    let userData = {};
    userData['userId'] = localStorage.getItem('userId');
    userData['role'] = this.role;
    this.dataService.getListData('getRunningTask', { 'userData': userData })
      .subscribe((success) => {
        localStorage.removeItem('currentTask');
        if (success.data != null) {
          localStorage.setItem('currentTask', JSON.stringify(success.data[0]));
          this.dataService.getCurrentRunningTask(success.data[0]);
        } else {
          this.dataService.getCurrentRunningTask([]);
        }
        //this.taskDetails = success.data[0];

        //this.onSubject$.next({ key: 'currentTask', value: JSON.stringify(success.data[0])})
      }, (rejected) => {
      });
  }

  // checkAccountActivation(){
  //   this.dataService.getOtherData('account-activation', {})
  //   .subscribe((success) => {
  //     if(success.status != 'success'){
  //       let client_code = localStorage.getItem('client_code');
  //       localStorage.removeItem('token');
  //       this.logout(true);
  //       // this.router.navigate(['/ac/'+client_code]);
  //       this.toastrService.notify('warn','',success.message);
  //     }
  //   }, (error) => {
  //   });
  // }

  adminLogoutncheck(fromAuth = false, type = "", message = "") {
    this.endStudioPolling();
    let that = this;
    _.forEach(this.project_polling, function (value, key) {
      if (value != '') {
        that.project_polling[key].unsubscribe();
        that.project_polling[key] = '';
      }
    });

    localStorage.removeItem('token');
    localStorage.setItem('userId', '');
    localStorage.setItem('user', '');
    localStorage.removeItem('currentTask');
    localStorage.removeItem('projectRoles');
    localStorage.removeItem('projectRoleType');
    localStorage.removeItem('projectPermissions');
    localStorage.removeItem('ocalStorageProjectData');
    localStorage.removeItem('project_notification_count');
    if (!fromAuth) {
      that.router.navigate([this.firstEnteresURL]);
    }

    localStorage.setItem('access_code', '');
    localStorage.setItem('roles', JSON.stringify({}));

    localStorage.setItem('role', this.role);
    //localStorage.setItem('client_code', '');


    //localStorage.setItem('project_notification_count', JSON.stringify({"project_1": 0}));

    localStorage.setItem('standalone', undefined);

    this.router.navigate([this.firstEnteresURL]);

    if (type == 'account-deleted') {
      this.toastrService.notify('warn', '', 'It seems your account is deleted. Please contact support.');
    } else if (type == 'Unauthenticated') {
      this.toastrService.notify('warn', '', 'It seems you dont have access to this account. Please contact support.');
    }
    this.dataService.logout('logout', '')
      .subscribe((success) => {
        this.dataService.unsubscribeNotifications();
        if (success.success) {
          //this.ngOnInit();
        }
      }, (error) => {
      });
  }


  /** Get project list by user */
  projectListByUser() {
    this.dataService.getListTotal('projectListByUser')
      .subscribe((success) => {
        this.dataService.getProjectListByUser = success.data;
        this.dataService.getAllProjectList();
      }, (rejected) => {
        // //console.log(rejected);
      });
  }


  /** Action Button */
  actionClick() {
    const payload = {
      id: this.userId,
    }
    this.gridService.postData('users/releasePopupUpdate', payload)
      .subscribe((success) => {
        // Retrieve data from localStorage
        let userData: any = JSON.parse(localStorage.getItem('user'));
        if (userData) {
          // Check if 'userData' is not null or undefined
          // Update the 'news_popup' value
          userData.news_popup = 0; // Replace 'new_value' with the value you want to set
          // Store the updated data back in localStorage
          localStorage.setItem('user', JSON.stringify(userData));
        } else {
        }
      }, (rejected) => {

      });
  }


  viewer = 'google';
  selectedType = 'pptx'; //'docx';
  // doc = 'https://file-examples.com/wp-content/uploads/2017/02/file-sample_100kB.docx';
  // doc = 'https://files.fm/down.php?i=axwasezb&n=SSaD.docx';
  doc = 'https://files.fm/down.php?i=sdymh2y6';

}
