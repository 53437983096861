import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class VideoService {

  private stateBe$: BehaviorSubject<string>;
  initialState: any='';

  constructor() {
    this.stateBe$ = new BehaviorSubject(this.initialState);
  }

  get getVideoAnnotation(): any {
    return this.stateBe$.getValue();
  }


  setVideoAnnotation = (nextState: string) => {
    this.stateBe$.next(nextState);
  }


  videoPlaypause() {
    return true;
  }

}
