import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
declare var $: any;
import { CommonJs } from '../../../common/common-js';
//  import { NgProgressService } from 'ngx-progressbar';
@Component({
	selector: 'change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

	entityName = 'Change Password';
	apiRoutePrefix = 'users';
	dirName = 'users';
	myform: any;
	errors: any;
	focused: string = '';
	f1Focused: string = '';
	SaveButtonText: string = 'SAVE';
	PasswordSaveButtonText: string = 'SAVE';
	userId = JSON.parse(localStorage.getItem('userId'));
	userProfile: { [index: string]: any };
	current_password: string = '';
	password: string = '';
	confirm_password: string = '';

	settings: any = JSON.parse(localStorage.getItem('settings'));

	@Output() closeSettings = new EventEmitter();
	constructor(private titleService: Title, private dataService: DataService,
		private toastrService: ToastrService,

		public commonJs: CommonJs) { }

	ngOnInit() {
		this.userProfile = {
			id: this.userId,
			password: '',
		}
		$.AdminBSB.input.activate();
		this.subngOnInit();
		this.titleService.setTitle(this.entityName);
	}

	subngOnInit() {
		this.focused = "focused";
		this.dataService.getDataById(this.apiRoutePrefix + '/' + this.userId + '/getProfile')
			.subscribe((success) => {
				this.userProfile.password = '';
			}, (error) => {
				if (error.statusText == "Unauthorized") {
					this.toastrService.notify('warn', '', 'It seems your session is expired. Please login again.');
				}
			});
	}

	savePassword(f1: NgForm) {
		this.PasswordSaveButtonText = 'SAVING';
		this.errors = {};
		this.dataService.updateData(this.apiRoutePrefix + '/' + this.userId + '/changePassword', f1.value)
			.subscribe((success) => {
				// Save effect within button
				this.PasswordSaveButtonText = 'SAVED';
				f1.reset();
				this.f1Focused = 'focus';
				setTimeout(() => {
					this.PasswordSaveButtonText = 'SAVE';
				}, 2);
				this.toastrService.notify('success', '', 'Password changed successfully.');

			}, (error) => {
				if (error.error) {
					var err = error.error;
					this.errors = err.error;
					if (this.errors.current_password) {
						this.toastrService.notify('warn', '', err.message);
					}
					if (this.errors.confirm_password) {
						this.toastrService.notify('warn', '', err.message);
					}
					this.PasswordSaveButtonText = "SAVE";

				}
			});
	}

	close() {
		this.userProfile.current_password = this.userProfile.password = this.userProfile.confirm_password = '';
		// this.closeSettings.emit();
	}

}

