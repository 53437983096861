import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { columns } from '../table-column';

@Component({
  selector: 'app-bid',
  templateUrl: './bid.component.html',
  styleUrls: ['./bid.component.css']
})
export class BidComponent implements OnInit, AfterViewInit {
  @Input() shotAssetDepartments: any;
  @Input() totalBidDays: any;
  @Input() totalActualSpentDays: any;
  @Input() isLoading: boolean = true;
  public dataTableColumns: TableColumn[] = [];
  @ViewChild('bidDays', { static: false }) public bidDays: TemplateRef<any>;
  @ViewChild('actualdays', { static: false }) public actualdays: TemplateRef<any>;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.createColumns();
  }


  /** Create Column */
  private createColumns(): void {
    let cellTemplate: any = {
      bidDays: this.bidDays,
      actualdays: this.actualdays,
    };
    setTimeout(() => {
      this.dataTableColumns = columns('bidTable', cellTemplate);
      this.cd.markForCheck();
    });
  }
}
