import { Component, OnInit, Input, ElementRef, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgForm } from '@angular/forms';
import { DataService } from '../../../services/data.service';
import { ToastrService } from '../../../services/toastr.service';
import { AppConfigs } from '../../../common/app-configs';
//import { Activitylog } from '../../../common/activitylog';
import swal from 'sweetalert2';
import { DatatableService } from '../../../services/datatable/datatable.service';
declare var $: any;
import * as moment from 'moment';
import { CommonJs } from '../../../common/common-js';
import { DatePipe } from "@angular/common";
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
	selector: 'app-work-calender',
	templateUrl: './work-calender.component.html',
	styleUrls: ['./work-calender.component.css']
})
export class WorkCalenderComponent implements OnInit {

	/*	@ViewChild('CloseOtherWindow') CloseOtherWindow:ElementRef;*/
	@ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;

	@Output() closeSettings = new EventEmitter();

	//for week
	myform: any;
	myform1: any;
	myform2: any;
	headerTitle = 'Weekly Off Day';
	entityNameSingularweek = 'off days';
	apiRoutePrefixweek = 'week';
	weeks: Weeks;
	week: any;
	totalweek: number;
	weekid: number;
	monthselected = [];
	monthchecked = [];
	weekofmonth = [{ id: "1st", name: "1st", checked: false }, { id: "2nd", name: "2nd", checked: false }, { id: "3rd", name: "3rd", checked: false }, { id: "4th", name: "4th", checked: false }, { id: "5th", name: "5th", checked: false }];
	durations: Array<any> = [{ "id": 'Half day', "name": "Half day" }, { "id": 'Full day', "name": "Full day" }];
	days: Array<any> = [{ "id": 1, "name": "Sunday" }, { "id": 2, "name": "Monday" }, { "id": 3, "name": "Tuesday" }, { "id": 4, "name": "Wednesday" }, { "id": 5, "name": "Thursday" }, { "id": 6, "name": "Friday" }, { "id": 7, "name": "Saturday" }];
	weekday: Array<any> = [];
	DateFormat: any;
	public dates = {
		singleDatePicker: true,
		autoUpdateInput: true,
		showDropdowns: true,
		autoApply: true,
		drops: 'down',
		"maxDate": "31 Dec 2030",
		locale: {
			format: 'YYYY-MM-DD'
		},
	}


	//for Holiday
	titleholiday = 'Holiday';
	entityNameSingularholiday = 'holiday';
	apiRoutePrefixholiday = 'holiday';
	holidays: Holidays;
	holiday: any;
	holidayid: number;
	focusedholiday: string = '';
	totalholiday: number;

	//for Minhour
	apiRoutePrefixMinHour = 'studio-settings';
	focused: string = 'focused';

	//for All Usage
	errors: { [index: string]: any } = {};
	entityName = 'Work Calendar';
	SaveButtonTextWHrs: string = 'SAVE';
	SaveButtonTextOffDays: string = 'SAVE';
	SaveButtonTextHld: string = 'SAVE';
	mode: string;
	settings: any = JSON.parse(localStorage.getItem('settings'));
	settingsAll: any = JSON.parse(localStorage.getItem('settings'));
	isWeekSubmitted: boolean = false;

	isWeekendIncludeOrNot: any;
	bsModalRef: BsModalRef;
	modalData: any;
	modalActionType: string = '';
	workCalendarNavTab = 'minimum working hours';

	constructor(
		private dataService: DataService,
		private titleService: Title,
		public commonJs: CommonJs,
		//public Activitylog:Activitylog,
		private datatableService: DatatableService,
		public appConfigs: AppConfigs,
		private toastrService: ToastrService,
		public datepipe: DatePipe,
		private modalService: BsModalService,
	) { }

	// ! NgOnINit
	ngOnInit() {
		//this.commonJs.initiateJS();
		$.AdminBSB.input.activate();
		this.DateFormat = this.settingsAll.date_format;
		this.dates.locale.format = this.DateFormat;
		//for list of week
		this.weekdata();

		//for list of holiday
		this.holidaydata();

		this.mode = 'create';

		this.settings = {
			half_day_hrs: this.settingsAll.half_day_hrs,
			full_day_hrs: this.settingsAll.full_day_hrs,
		};
		this.weeks = {
			duration: '',
			day: '',
			week: []
		}
		this.holidays = {
			holiday_name: '',
			date: null,
		}
		this.titleService.setTitle(this.entityName);
	}

	// ! SubNgOninit
	subngOnInit() { }

	// ! Weekdata
	weekdata() {
		this.dataService.getListTotal(this.apiRoutePrefixweek + 'List').subscribe((success) => {
			this.week = success.data;
			this.totalweek = success.totalElements;
		}, (error) => { });

	}

	// ! hollidayData
	holidaydata() {
		this.dataService.getListTotal(this.apiRoutePrefixholiday + 'List').subscribe((success) => {
			this.holiday = success.data;

			this.totalholiday = success.totalElements;
		}, (error) => { });
	}

	// ! OnSubmitHoliday
	isHolidaySubmitted = false
	onSubmitHoliday(form: NgForm) {
		this.isHolidaySubmitted = true;
		if ($('#date').val() != '' && $('#date').val() != undefined) {
			this.errors = {};
			form.value.date = moment($('#date').val()).format('YYYY-MM-DD');

		} else {
			this.errors.date = 'Required';
			form.value.date = null;
			return false;
		}
		this.SaveButtonTextHld = 'SAVING';
		if (this.mode == 'editHoliday') {
			this.dataService.updateData(this.apiRoutePrefixholiday + '/' + this.holidayid, form.value)
				.subscribe((success) => {
					setTimeout(() => {
						this.SaveButtonTextHld = 'SAVE';
						this.isHolidaySubmitted = false;
            this.bsModalRef.hide();
					}, 2);
					this.mode = 'create';
					form.reset();
					this.focusedholiday = '';
					this.holidaydata();
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
						this.isHolidaySubmitted = false;
					}
				});
		}
		else {
			this.dataService.storeData(this.apiRoutePrefixholiday, form.value)
				.subscribe((success) => {
					setTimeout(() => {
						this.SaveButtonTextHld = 'SAVE';
						this.isHolidaySubmitted = false;
            this.bsModalRef.hide();
					}, 2);
					this.errors = {};
					form.reset();
					this.focusedholiday = '';
					this.holidaydata();
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
						this.isHolidaySubmitted = false;
					}
				});
		}
	}

	// ! OnSubmitMinHour
	onSubmitMinHour(form: NgForm) {
		this.SaveButtonTextWHrs = 'SAVING';
		form.value.currency = this.settingsAll.currency;
		form.value.billing_unit = this.settingsAll.billing_unit;
		this.dataService.updateData(this.apiRoutePrefixMinHour, form.value)
			.subscribe((success) => {
				setTimeout(() => {
					this.SaveButtonTextWHrs = 'SAVE';
					this.toastrService.notify('success', '', 'Updated Successfully');
					this.commonJs.getAllSettings();
					let getSettings = localStorage.getItem("settings");
					if (!getSettings) {
						this.commonJs.getAllSettings();
					}
				}, 2);
			}, (error) => {
				if (error.error) {
					var err = error.error;
					this.errors = err.error;
				}
			});
	}

	// ! OnSubmitWeek
	onSubmitWeek(form: NgForm) {
		this.isWeekSubmitted = true;
		if (this.monthchecked.length > 0) {
			this.errors = {};
			var chk = this.monthchecked.join(',' + ' ');
			form.value.week = chk;
		}
		else {
			this.errors.day = this.weeks.day == '' ? 'Required' : '';
			this.errors.duration = this.weeks.duration == '' ? 'Required' : '';
			this.errors.week = this.monthchecked.length == 0 ? 'Required' : '';
			return false;
		}
		this.SaveButtonTextOffDays = 'SAVING';
		if (this.mode == 'editWeek') {
			this.dataService.updateData(this.apiRoutePrefixweek + '/' + this.weekid, form.value)
				.subscribe((success) => {
					setTimeout(() => {
						this.SaveButtonTextOffDays = 'SAVE';
					}, 2);
					/*this.close();*/
					this.mode = 'create';
					this.monthselected = [];
					this.monthchecked = [];
					form.reset();
          this.bsModalRef.hide();
					this.weekdata();
					setTimeout(() => {
						this.isWeekSubmitted = false;
					}, 2000);
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
					}
					this.isWeekSubmitted = false;
					this.SaveButtonTextOffDays = 'SAVE';
				});
		}
		else {
			this.dataService.storeData(this.apiRoutePrefixweek, form.value)
				.subscribe((success) => {
					setTimeout(() => {
						this.SaveButtonTextOffDays = 'SAVE';
					}, 2);
					this.monthselected = [];
					this.monthchecked = [];
					form.reset();
					this.weekdata();
          this.bsModalRef.hide();
					setTimeout(() => {
						this.isWeekSubmitted = false;
					}, 2000);
					/*this.close();
					*/
				}, (error) => {
					if (error.error) {
						var err = error.error;
						this.errors = err.error;
						this.isWeekSubmitted = false;
						this.SaveButtonTextOffDays = 'SAVE';
					}
				});
		}
	}

	// ! Get Week by ID
	getWeekById(id) {
		this.dataService.getDataById(this.apiRoutePrefixweek + '/' + id + '/edit')
			.subscribe((success) => {
				this.weekid = success.data[0].id;
				if (success.data[0].week) {
					this.monthchecked = [];
					this.monthselected = [];
					let weekArr = success.data[0].week.split(',' + ' ');
					let i;
					for (i = 0; i < weekArr.length; i++) {
						this.monthselected[weekArr[i]] = true;
						this.monthchecked.push(weekArr[i]);
					}
				}
				this.weeks = success.data[0];
				this.mode = 'editWeek';
			}, (rejected) => {
			});
	}

	// ! Get holidat By Id
	getHolidayById(id) {
		this.dataService.getDataById(this.apiRoutePrefixholiday + '/' + id + '/edit')
			.subscribe((success) => {
				this.holidayid = success.data[0].id;
				this.holidays = success.data[0];
				this.mode = 'editHoliday';
				this.focusedholiday = 'focused';

			}, (rejected) => {
			});
	}


	// ! Week Click
	WeekClick(form: NgForm) {
		this.monthchecked = [];
		this.monthselected = [];
		form.reset();
		this.mode = 'create';
	}


	// ! HolidayClick
	HolidayClick(form: NgForm) {
		form.reset();
		this.mode = 'create';
		this.focusedholiday = '';
	}

	// !DeleteWeek
	deleteWeek(id) {

		this.dataService.deleteData(this.apiRoutePrefixweek + 'Destroy', id)
			.subscribe((success) => {
				this.weekdata();
			}, (rejected) => {
			});

		this.bsModalRef.hide();

	}

	// ! DeleteHoliday
	deleteHoliday(id) {
		if (id) {
			this.dataService.deleteData(this.apiRoutePrefixholiday + 'Destroy', id)
				.subscribe((success) => {
					this.holidaydata();
				}, (rejected) => {
				});
		}
		this.bsModalRef.hide();
	}


	// ! Close
	close() {
		this.closeSettings.emit();
	}

	// ! Search
	search(query, column) {
		if (column == 'day') {
			this.weekday = this.days.map(v => { return v });
		}
	}

	// ! On change
	onChange(weekname, flag) {
		var tempCheck = [];
		if (flag) {
			this.monthchecked.push(weekname);
			this.monthchecked.sort();
		} else {
			const index: number = this.monthchecked.indexOf(weekname);
			this.monthchecked.splice(index, 1);
		}
		this.isWeekSubmitted = false;
	}


	/** On change weekend is include or not in start date end date and bid days */
	onChangeWeekendIncludeEvent(setting, flag) {
		//console.log("setting", setting)
		//console.log("flag", flag)
		if (setting == 'isWeekendIncludeOrNot') {
			if (flag) {
				this.isWeekendIncludeOrNot = 1;
			} else {
				this.isWeekendIncludeOrNot = 0;
			}
		}
	}

	// To open modal
	openModal(template: TemplateRef<any>, type, data) {
		this.modalActionType = type; // deleteWeek / deleteHoliday
		this.entityNameSingularweek = type == 'deleteWeek' ? 'off days' : 'holidays';
		this.headerTitle = type == 'deleteWeek' ? 'Weekly Off Day' : 'Holidays';
		this.bsModalRef = this.modalService.show(template);
		this.modalData = data;
	}

	// To open modal of add/edit weekly off day
	addWeeklyOffday(template: TemplateRef<any>, type){
		this.bsModalRef = this.modalService.show(template);
		if(type=='add'){
			this.weeks.day = this.weeks.duration = '';
			this.monthselected = [];
		}
	}

	// To open modal of add/edit holiday
	addHoliday(template: TemplateRef<any>, type){
		this.bsModalRef = this.modalService.show(template, {
      ignoreBackdropClick: true
    });
		if(type=='add'){
			this.holidays.holiday_name = this.holidays.date = '';
		}
	}

}

interface Weeks {
	duration: string,
	day: string,
	week: string[]
}


interface Holidays {
	holiday_name: string,
	date: any
}

/*interface Settings{
	half_day_hrs :number,
	full_day_hrs : number
}
*/
