import { Injectable } from '@angular/core';
import { DataService } from '../data.service';
import { map } from 'rxjs/operators';
import * as _ from "lodash";


@Injectable()
export class DatatableService {

  public rows: Array<any> = [];
  public columns: Array<any> = [];
  public FilterColumns: Array<any> = [];
  public page: number = 1;
  public itemsPerPage: number = 50;
  public maxSize: number = 5;
  public numPages: number = 1;
  public length: number = 0;
  public config: any = {};
  public serverUrl: any = '';
  public publishingServerUrl: any = '';

  private data: Array<any> = [];
  public sortDataTemp: any = {};

  constructor(private dataService: DataService) {
  }

  public setParam(componentData, key) {
    this[key] = componentData;
  }

  public changePage(page: any, data: Array<any> = this.data): Array<any> {
    let start = (page.page - 1) * page.itemsPerPage;
    let end = page.itemsPerPage > -1 ? (start + page.itemsPerPage) : data.length;
    return data.slice(start, end);
  }

  public changeSort(data: any, config: any): any {
    if (!config.sorting) {
      return data;
    }

    let columns = this.config.sorting.columns || [];
    let columnName: string = void 0;
    let sort: string = void 0;

    for (let i = 0; i < columns.length; i++) {
      if (columns[i].sort !== '' && columns[i].sort !== false) {
        columnName = columns[i].name;
        sort = columns[i].sort;
      }
    }
    if (!columnName) {
      return data;
    }
  }

  public changeFilter(data: any, config: any): any {
    let tempFilteredData: { [index: string]: any } = {};
    if (this.serverUrl == 'shotsList' || this.serverUrl == 'seasonsList') {
      return tempFilteredData = this.config.FilterByColumns;
    } else {
      let filteredData: Array<any> = data;
      this.columns.forEach((column: any) => {
        if (column.filtering) {
          tempFilteredData[column.name] = column.filtering.filterString;
        }
      });
      if (!config.filtering) {
        return filteredData;
      }
      return tempFilteredData;
    }

  }

  public onChangeTable(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }, column: any = ''): any {
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }
    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }
    let filteredData = this.changeFilter(this.data, this.config);
    let sortedData = this.changeSort(filteredData, this.config);
    if (page && config.paging) {
      if (page.page == 1) {
        let sortData = {};
        if (column && column.sort != '') {
          sortData[column.name] = column.sort;
        }
        _.forEach(this.config.sorting.columns, function (v, k) {
          if (v['sort'] != undefined && v['sort'] != false && column.name != v['name']) {
            sortData[v['name']] = v['sort'];
          }
        });
        this.sortDataTemp = sortData;
      }
      let start
      if (this.serverUrl == 'shotsList' || this.serverUrl == 'sequencesList' || this.serverUrl == 'seasonsList') {
        start = page.page;
      } else {
        start = (page.page - 1) * page.itemsPerPage;
      }
      return this.dataService.getListData(this.serverUrl, {
        'start': start,
        'itemsPerPage': page.itemsPerPage,
        'columnSort' : this.config.columnSort,
        'sort': this.config.sorting.columns[0],
        'sortNew': this.sortDataTemp,
        'FilterColumns': this.FilterColumns,
        'filtering': this.config.filtering,
        'FilterByColumns': filteredData,
        'where': this.config.where,
        'columns': this.config.columns,
        'export': this.config.export,
        'isLoadingFirstTime': this.config.isLoadingFirstTime,
        'isClearFilter': this.config.isClearFilter,
        'isAdvanceSearchApplied': this.config.isAdvanceSearchApplied,
      }).pipe(map(res => res));
      /*}*/

    } else {
      return sortedData;
    }
  }

  public onChangeTablePublishing(config: any, page: any = { page: this.page, itemsPerPage: this.itemsPerPage }, column: any = ''): any {
    if (config.filtering) {
      Object.assign(this.config.filtering, config.filtering);
    }

    if (config.sorting) {
      Object.assign(this.config.sorting, config.sorting);
    }


    let filteredData = this.changeFilter(this.data, this.config);
    let sortedData = this.changeSort(filteredData, this.config);
    if (page && config.paging) {
      if (page.page == 1) {
        let sortData = {};
        if (column && column.sort != '') {
          sortData[column.name] = column.sort;
        }
        _.forEach(this.config.sorting.columns, function (v, k) {
          if (v['sort'] != undefined && v['sort'] != false && column.name != v['name']) {
            sortData[v['name']] = v['sort'];
          }
        });
        this.sortDataTemp = sortData;
      }
      let start = (page.page - 1) * page.itemsPerPage;
      return this.dataService.getListDataPub(this.serverUrl, {
        'start': start,
        'itemsPerPage': page.itemsPerPage,
        'sort': this.config.sorting.columns[0],
        'sortNew': this.sortDataTemp,
        'FilterColumns': this.FilterColumns,
        'filtering': this.config.filtering,
        'FilterByColumns': filteredData,
        'where': this.config.where,
        'columns': this.config.columns,
        'export': this.config.export
      }).pipe(map(res => res));
      /*}*/

    } else {
      return sortedData;
    }
  }
}
