import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonJs } from 'src/app/common/common-js';
import { DataService } from 'src/app/services/data.service';
import { ToastrService } from 'src/app/services/toastr.service';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-manage-asset-columns',
  templateUrl: './manage-asset-columns.component.html',
  styleUrls: ['./manage-asset-columns.component.css']
})
export class ManageAssetColumnsComponent implements OnInit, OnDestroy {

  entityName = 'Columns';
  entityNameSingular = 'Column';
  apiRoutePrefix = 'task';
  skeleton: boolean = true;

  @Input('attr.data') dataHtml: any;
  @Input('departmentColumnsData') departmentColumns: any[];

  @ViewChild('CloseOtherWindow', { static: false }) CloseOtherWindow: ElementRef;
  @ViewChild('SaveButton', { static: false }) SaveButton: ElementRef;
  @ViewChild("roleInput", { static: false }) roleInput: ElementRef;
  @Output() closeManageCol = new EventEmitter();

  data: any;
  column_data: any;
  public temp_columns = [];
  checkId = [];
  SaveButtonText: string = 'SAVE';
  errors: { [index: string]: any } = {};
  subs: Subscription[] = [];
  actionColumn: any = {};
  dragulaOptions: any = {
    moves: function (el, container, handle) {
      return handle.className === 'dragula-handle material-icons';
    }
  }

  constructor(private dataService: DataService,
    public commonJs: CommonJs,
    private toastrService: ToastrService,
    private dragulaService: DragulaService,
  ) {
    this.dragulaService.createGroup('another-bag', {
      moves: (el, container, handle) => handle.className === ''
    });

    // this.dragulaService.createGroup('another-dept-bag', {
    //   moves: (el, container, handle) => handle.className === ''
    // });
  }

  ngOnInit() {
    // this.subs.push(
    //   this.dragulaService.drop().subscribe(({ name, el, source, target, sibling }) => {
    //     // Find the source department and its phase
    //     const sourceDepartmentId = +(<HTMLElement>el).getAttribute('data-id');
    //     const sourcePhase = this.data.column.find(phase => phase.id === sourceDepartmentId);

    //     // Find the target department and its phase
    //     const targetDepartmentId = +(<HTMLElement>sibling).getAttribute('data-id');
    //     const targetPhase = this.data.column.find(phase => phase.id === targetDepartmentId);

    //     if (sourcePhase && targetPhase) {
    //       // Find the source and target department indices
    //       const sourceIndex = sourcePhase.department.findIndex(dept => dept.id === sourceDepartmentId);
    //       const targetIndex = targetPhase.department.findIndex(dept => dept.id === targetDepartmentId);

    //       // Move the department from the source to the target phase
    //       const movedDepartment = sourcePhase.department.splice(sourceIndex, 1)[0];
    //       targetPhase.department.splice(targetIndex, 0, movedDepartment);

    //       this.dataService.getListData(this.apiRoutePrefix + 'Order', { 'depatmentPhaseList': this.data.column })
    //         .subscribe((success) => {
    //           this.toastrService.notify('success', '', success.msg ? success.msg : 'success');
    //         }, (rejected) => {
    //           console.log(rejected)
    //         });
    //     }
    //   })
    // );
    if (this.departmentColumns) {
      console.log(this.departmentColumns);
    }

    if (this.dataHtml != undefined) {
      this.data = this.dataHtml;
      // Filter out the object with name "Actions"
      console.log(this.data);
      this.actionColumn = this.data.column.filter(item => item.name == "Actions")[0];
    }
    $.AdminBSB.input.activate();
    this.subngOnInit();
    this.subs.push(
      this.dragulaService.drop().subscribe(({ name, el, source, target, sibling }) => {
        const draggedColumn = this.temp_columns.find(col => col.name === el.textContent.trim());
        const currentIndex = this.temp_columns.indexOf(draggedColumn);
        this.temp_columns.splice(currentIndex, 1);

        let siblingColumn = '';
        let newIndex = 0;

        if (sibling) {
          siblingColumn = this.temp_columns.find(col => col.name === sibling.textContent.trim());
          newIndex = this.temp_columns.indexOf(siblingColumn);
        } else {      // If the dragged element been put on last index
          newIndex = this.temp_columns.length;
        }

        // const siblingColumn = this.temp_columns.find(col => col.name === sibling.textContent.trim());
        // const newIndex = this.temp_columns.indexOf(siblingColumn);

        this.temp_columns.splice(newIndex, 0, draggedColumn);
      })
    );
  }

  ngOnDestroy() {
    this.dragulaService.destroy('another-bag');
    // this.dragulaService.destroy('another-dept-bag');
  }

  subngOnInit() {
    // console.log(this.data);
    this.dataService.getListData(this.apiRoutePrefix + '/' + this.data.key + '/preferences', { 'projectId': this.data.projectId })
      .subscribe((success) => {
        if (success.status == 'success' && success.data.length != 0) {
          this.temp_columns = success.data;
          const reorderedColumns = [];
          // Add matched columns in the order of 'this.temp_columns'
          for (const tempColumn of this.temp_columns) {
            const matchingColumn = this.data.column.find(column => column.prop === tempColumn.prop);
            if (matchingColumn) {
              reorderedColumns.push(matchingColumn);
            }
          }
          // Add any remaining columns from 'this.data.column'
          for (const column of this.data.column) {
            if (!reorderedColumns.some(matchedColumn => matchedColumn.prop === column.prop)) {
              reorderedColumns.push(column);
            }
          }
          // Update 'this.data.column' with the reordered columns
          this.data.column = reorderedColumns;
          // this.data.column = success.data;
          this.skeleton = false;
        } else {
          // Keep 'this.temp_columns' as it is
          this.temp_columns = this.data.column;
          // Assuming this.temp_columns is an array of column objects
          let columnsToShow = [];
          if (this.data.key == 'All') {
            columnsToShow = ['image', 'code', 'name', 'duration_days', 'episodeSequence', 'assetStatus', 'id'];
          } else {
            columnsToShow = ['image', 'code', 'name', 'duration_days', 'id'];
          }
          this.temp_columns = this.temp_columns.map(column => {
            if (columnsToShow.includes(column.prop) || column.idDepartment) {
              return { ...column, show: true };
            } else {
              return { ...column, show: false }; // Set show to false for other columns
            }
          });
          this.skeleton = false;
          console.log(this.temp_columns, this.data.key);
        }
      }, (rejected) => {
        this.skeleton = false;
      });
  }


  toggle(col, type?) {
    const isChecked = type ? this.isChecked(col, type) : this.isChecked(col);
    if (type) {
      if (isChecked) {
        this.temp_columns = this.temp_columns.filter(c => {
          return c.name !== col.name;
        });
      } else {
        this.temp_columns = [...this.temp_columns, col];
      }
    } else {
      if (isChecked) {
        this.temp_columns = this.temp_columns.filter(c => {
          return c.name !== col.name;
        });
      } else {
        this.temp_columns = [...this.temp_columns, col];
      }
    }
  }

  isChecked(col, type?) {

    // For department columns
    if (type == 'deptColumns') {

      return (
        this.departmentColumns.find(c => {
          return c.name === col.name;
        }) !== undefined
      );

    } else {

      return (
        this.temp_columns.find(c => {
          if (c) {
            return c.name === col.name;
          } else {
            return false;
          }
        }) !== undefined
      );

    }

  }

  saveReOrder() {
    this.temp_columns.filter((ele) => (ele.name == "Actions")).length == 0 ? this.temp_columns.push(this.actionColumn) : '';
    this.SaveButtonText = 'SAVING';
    this.dataService.updateData(this.apiRoutePrefix + '/' + this.data.key + '/preferences', { 'value': this.temp_columns, 'projectId': this.data.projectId })
      .subscribe((success) => {
        this.SaveButtonText = 'SAVED';
        setTimeout(() => {
          this.CloseOtherWindow.nativeElement.click();
          this.SaveButtonText = 'SAVE';
        }, 2);
        this.toastrService.notify('success', '', 'Asset columns filtered successfully.');
        this.close(true);
      }, (error) => {
        if (error.error) {
          var err = error.error;
          this.errors = err.error;
          this.SaveButtonText = "SAVE";
          this.toastrService.notify('warn', '', err.error);
        }
      });
  }

  close(reload) {
    this.closeManageCol.emit({ column: this.temp_columns, reload: reload });
  }

  closeBtn() {
    this.CloseOtherWindow.nativeElement.click();
  }

}
