import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './common/unauthorized/unauthorized.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SuperadminDashboardComponent } from './components/superadmin-dashboard/superadmin-dashboard.component';
import { HeadcountComponent } from './components/headcount/headcount.component';
let pathValue = 'ac/:access_code';

const appRoutes: Routes = [
  // Super admin routes
  {
    path: 'superadmin',
    children: [
      {
        path: '',
        component: LoginComponent,
        data: { roles: ['superadmin'] }
      },
      {
        path: 'dashboard',
        component: SuperadminDashboardComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'accounts',
        loadChildren: () => import('../app/components/accounts/accounts.module').then(m => m.AccountsModule),
        canActivate: [AuthGuardService],
        data: { roles: ['superadmin'] }
      },
      {
        path: 'suggestions',
        loadChildren: () => import('../app/components/suggestions/suggestions.module').then(m => m.SuggestionsModule),
        canActivate: [AuthGuardService],
        data: { roles: ['superadmin'] }
      },
      {
        path: 'release',
        loadChildren: () => import('../app/components/release/release.module').then(m => m.ReleaseModule),
        canActivate: [AuthGuardService],
        data: { roles: ['superadmin'] }
      },
      {
        path: 'phases',
        loadChildren: () => import('../app/components/phases/phases.module').then(m => m.PhasesModule),
        canActivate: [AuthGuardService],
        data: { baiPass: ['superadmin'], permissions: { phases: ['view'] } }
        // allowRoles: ['admin'],
      },
      {
        path: 'employees',
        loadChildren: () => import('../app/components/users/users.module').then(m => m.UsersModule),
        data: { roles: ['superadmin'] }
      },
      {
        path: 'settings',
        loadChildren: () => import('../app/components/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuardService],
        data: { roles: ['superadmin'], baiPass: ['superadmin'] }
        /*data: { roles: ['admin'] }*/
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent
      },
      {
        path: 'page-not-found',
        component: PageNotFoundComponent
      },
      { path: '**', redirectTo: 'page-not-found' },
    ]
  },

  // admin routes
  {
    path: 'ac/:access_code',
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuardService]
      },

      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'phases',
        loadChildren: () => import('../app/components/phases/phases.module').then(m => m.PhasesModule),
        canActivate: [AuthGuardService],
        data: { baiPass: ['admin'] }
        //, permissions: { phases: ['view'] }
      },
      {
        path: 'outsourced-tasks',
        loadChildren: () => import('../app/components/outsourced-tasks/outsourced-tasks.module').then(m => m.OutsourcedTasksModule),
        canActivate: [AuthGuardService],
        data: { baiPass: ['admin'], permissions: { outsourced_tasks: ['view'] } }
        //, permissions: { phases: ['view'] }
      },
      {
        path: 'employees',
        loadChildren: () => import('../app/components/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuardService],
        data: { baiPass: ['admin'], permissions: { employees: ['view'] } }
        // allowRoles: ['admin'],
      },
      {
        path: 'vendors',
        loadChildren: () => import('../app/components/vendors/vendors.module').then(m => m.VendorsModule),
        canActivate: [AuthGuardService],
        data: { baiPass: ['admin'], permissions: { vendors: ['view'] } }
        // allowRoles: ['admin'],
      },
      {
        path: 'projects',
        loadChildren: () => import('../app/components/projects/projects.module').then(m => m.ProjectsModule),
        canActivate: [AuthGuardService],
        data: { baiPass: ['admin'], permissions: { project_screens: ['view'] } }
        // allowRoles: ['admin'],
      },

      {
        path: 'projects',
        loadChildren: () => import('../app/components/projects/tasks/tasks.module').then(m => m.TasksModule),
        canActivate: [AuthGuardService],
        data: { baiPass: ['admin'], permissions: { project_screens: ['view'] } }
        // allowRoles: ['admin'],
      },

      {
        path: 'clients',
        loadChildren: () => import('../app/components/clients/clients.module').then(m => m.ClientsModule),
        canActivate: [AuthGuardService],
        data: { baiPass: ['admin'], permissions: { clients: ['view'] } }
        // allowRoles: ['admin'],
      },
      {
        path: 'settings',
        loadChildren: () => import('../app/components/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuardService]
        /*data: { roles: ['admin'] }*/
      },
      {
        path: 'headcount',
        component: HeadcountComponent,
        canActivate: [AuthGuardService],
        data: { baiPass: ['admin'], permissions: { headcount_report: ['view'] } }
        // allowRoles: ['admin'],
      },
      {
        path: 'reports',
        loadChildren: () => import('../app/components/reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'management-dashboard',
        loadChildren: () => import('../app/components/management-dashboard/management-dashboard.module').then(m => m.ManagementDashboardModule),
        canActivate: [AuthGuardService],
        data: { baiPass: ['admin'], permissions: { management_dashboard: ['view'] } }
      },
      {
        path: 'crew-planning',
        loadChildren: () => import('../app/components/crew-planning/crew-planning.module').then(m => m.CrewPlanningModule),
        canActivate: [AuthGuardService],
        data: { baiPass: ['admin'], permissions: { crew_planning: ['view'] } }
      },

      {
        path: 'team-managment',
        loadChildren: () => import('../app/components/team-managment/team-managment.module').then(m => m.TeamManagmentnModule),
        canActivate: [AuthGuardService],
        data: { baiPass: ['admin'], permissions: { team_management: ['view'] } }
      },

      {
        path: 'notifications',
        loadChildren: () => import('../app/components/notifications/notification/notification.module').then(m => m.NotificationModule),
        data: { roles: ['superadmin'] }
      },

      {
        path: 'my-tasks',
        loadChildren: () => import('../app/components/my-tasks/my-tasks.module').then(m => m.MyTasksModule),
      },
      {
        path: 'unauthorized',
        component: UnauthorizedComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'page-not-found',
        component: PageNotFoundComponent
      },
      { path: '**', redirectTo: 'page-not-found' },
    ]
  },

  // page not found component
  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },
  // unauthorized component
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
  // redirect to page not found screen
  { path: '**', redirectTo: 'page-not-found' },
];


@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
    })
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule { }
